import InterviewAPI from '@/api/Interview'
import AdminAPI from '@/api/Admin'

const TAKE = 25

const adminModule = {
  namespaced: true,
  name: 'admin',
  state: {
    admin: null,
    interviewsList: [],
    currentInterview: {},
    accountVerified: false
  },
  getters: {
    getAdminInterviews: (state) => {
      return state.interviewsList
    },
    getCurrentInterview: (state) => {
      return state.currentInterview
    }
  },
  actions: {
    async setInterviewMarker({ rootState }, payload) {
      const { id, name, metadata } = payload
      if (!id || !name || !metadata) return false
      try {
        const res = await rootState.apolloClient.mutate({
          ...AdminAPI.SetInterviewMarker(id, name, metadata),
          fetchPolicy: 'no-cache'
        })
        if (res && res?.data) {
          return res?.data?.addInterviewMarker
        }
      } catch (e) {
        return false
      }
    },
    async fetchInterviewMarkers({ rootState }, payload) {
      try {
        const { id } = payload
        if (!id) return null
        const res = await rootState.apolloClient.query({
          ...AdminAPI.FetchInterviewMarkers(id),
          fetchPolicy: 'no-cache'
        })
        if (res && res?.data) {
          const markerData = res?.data?.getInterviewMarkers.find((d) => d.name === 'last-step-completed')
          if (!markerData) return null
          const metadata = markerData.metadata
          if (!metadata) return null
          const data = metadata.find((m) => m.key === 'step')
          if (data) {
            return data?.value
          }
        }
        return null
      } catch (e) {
        return null
      }
    },
    async addManualIDVerificationFlag({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.AddManualIDVerificationFlag(payload.id),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data) {
        return res.data
      }
      return null
    },
    async resolveSpousalSignature({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.ResolveSpousalSignature(payload.id),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data) {
        return res.data
      }
      return null
    },
    async claretoSearch({ rootState }, payload) {
      try {
        const res = await rootState.apolloClient.mutate({
          ...AdminAPI.ClaretoSearch(payload.id),
          fetchPolicy: 'no-cache'
        })
        if (res && res?.data) {
          return res.data
        }
        return null
      } catch (err) {
        return {
          error: err
        }
      }
    },
    async expireIrixOrder({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.ExpireIrixOrder(payload.id),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data) {
        return res.data
      }
      return null
    },
    async regeneratePolicyPacket({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.RegeneratePolicyPacket(payload.id)
      })
      if (res && res?.data) {
        return res.data
      }
      return null
    },
    async expireInstantIDOrder({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.ExpireInstantIDOrder(payload.id),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data) {
        return res.data
      }
      return null
    },
    async expireMVROrder({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.ExpireMVROrder(payload.id),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data) {
        return res.data
      }
      return null
    },
    async getAuthFormDownloadURL({ rootState }, interviewID) {
      const res = await rootState.apolloClient.query({
        ...AdminAPI.GetSignedAuthorizationForm(interviewID),
        fetchPolicy: 'no-cache'
      })
      if (res && res.data) {
        return res.data.authorizationDownloadURL
      }
      return null
    },
    async getAgent({ rootState, commit }, payload) {
      const res = await rootState.apolloClient.query({
        ...AdminAPI.GetAgent(payload.id),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data && res?.data?.agent) {
        return res.data.agent
      }
      return null
    },

    async hidePolicy({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.FlagIncorrectPolicyPacket(payload.id, payload.interviewID),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data && res?.data?.flagIncorrectPacket) {
        return res.data.flagIncorrectPacket
      }
      return null
    },

    async unhidePolicy({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.UnflagPolicyPacket(payload.id, payload.interviewID),
        fetchPolicy: 'no-cache'
      })
      if (res && res?.data && res?.data?.unflagPacket) {
        return res.data.unflagPacket
      }
      return null
    },

    async quoteOverride({ rootState }, params) {
      let res
      try {
        res = await rootState.apolloClient.query({
          ...AdminAPI.QuoteOverride(params.id, params.override),
          fetchPolicy: 'no-cache'
        })
        if (res?.data?.quote) {
          return res.data.quote
        }
      } catch (e) {
        return e
      }
      return null
    },
    async setShowQuote({ rootState, commit }, { id, showQuote }) {
      let res
      try {
        res = await rootState.apolloClient.mutate({
          ...AdminAPI.Interview(),
          variables: {
            id: id,
            update: { showQuote }
          }
        })
      } catch (err) {
        return {
          error: err,
          interview: null
        }
      }
      if (res && res.data) {
        return res
      }
    },
    async setQuoteDisplayMode({ rootState, commit }, { id, quoteDisplayMode }) {
      let res
      try {
        res = await rootState.apolloClient.mutate({
          ...AdminAPI.Interview(),
          variables: {
            id: id,
            update: { quoteDisplayMode }
          }
        })
      } catch (err) {
        return {
          error: err,
          interview: null
        }
      }
      if (res && res.data) {
        return res
      }
    },
    async getPDFDownloadURL({ rootState }, id) {
      const res = await rootState.apolloClient.query({
        ...AdminAPI.GetPDFLink(id),
        fetchPolicy: 'no-cache'
      })
      if (res && res.data) {
        return res.data.orderReportDownloadURL
      }
      return null
    },
    async fetchClaretoSearchRecords({ rootState }, id) {
      const res = await rootState.apolloClient.query({
        ...AdminAPI.GetClaretoSearchRecords(id),
        fetchPolicy: 'no-cache'
      })
      if (res && res.data) {
        return res.data.claretoSearchRecords
      }
      return null
    },
    async getNextInterviews({ commit, rootState }, { offset, statuses, hasAccount, query, clear = false }) {
      const res = await rootState.apolloClient.query({
        ...InterviewAPI.getNextInterviews(),
        variables: {
          skip: TAKE * (offset + 1),
          ...(statuses && { statuses: statuses }),
          ...(hasAccount !== undefined && { hasAccount: hasAccount }),
          ...(query && { query: query })
        }
      })

      if (res && res.data) {
        const interviews = res.data.interviewSummaries
        return interviews
      }
      return null
    },
    async getInterviews({ commit, rootState }, { offset, statuses, hasAccount, query, clear = false }) {
      const res = await rootState.apolloClient.query({
        ...InterviewAPI.getInterviews(),
        variables: {
          skip: TAKE * offset,
          skipNext: TAKE * (offset + 1),
          ...(statuses && { statuses: statuses }),
          ...(hasAccount !== undefined && { hasAccount: hasAccount }),
          ...(query && { query: query })
        }
      })
      if (res && res.data) {
        const interviews = res.data.interviewSummaries
        if (clear) {
          commit('CLEAR_INTERVIEWS')
        }
        commit('SET_INTERVIEWS', interviews)
        return interviews
      }
      return null
    },
    async setRisk({ rootState, commit }, { data, id }) {
      let res
      try {
        res = await rootState.apolloClient.mutate({
          ...AdminAPI.Interview(),
          variables: {
            id: id,
            update: { riskScore: data }
          }
        })
      } catch (err) {
        return {
          error: err,
          interview: null
        }
      }
      if (res && res.data) {
        const interview = res.data.interview.interview

        commit('SET_STATUS', { id, status: res.data.interview.interview.status })
        return { error: null, interview }
      }
      return null
    },
    async changeIssuer({ rootState, commit }, { id, vendor }) {
      let res
      try {
        res = await rootState.apolloClient.mutate({
          ...AdminAPI.ChangeIssuer(),
          variables: {
            id: id,
            product: {
              variant: 'ICC',
              vendor: vendor
            }
          }
        })
      } catch (err) {
        return {
          error: err,
          interview: null
        }
      }
      if (res && res.data) {
        const { copyInterviewChangeIssuer } = res.data
        const { status } = copyInterviewChangeIssuer
        const newID = copyInterviewChangeIssuer.id
        commit('SET_STATUS', { id: newID, status: status })
        return {
          error: null,
          interview: { ...copyInterviewChangeIssuer }
        }
      }
    },
    async getInterviewDetail({ rootState, commit, dispatch }, { id }) {
      const res = await rootState.apolloClient.query({
        ...AdminAPI.getAllQuestions(),
        fetchPolicy: 'no-cache',
        variables: {
          id: id
        }
      })
      if (res && res.data) {
        const interviewQuestions = res.data
        await dispatch('interview/extractQuestionAnswerMaps', res.data.interview.questions, { root: true })
        commit('SET_CURRENT_INTERVIEW', res.data)
        return interviewQuestions
      }
      return null
    },
    async approvePolicy({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.PolicyApproval(),
        variables: {
          id: payload.id,
          approve: payload.approve
        }
      })
      return res
    },
    async declinePolicy({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.PolicyDenial(),
        variables: {
          id: payload.id,
          approve: payload.approve,
          interviewId: payload.interviewId,
          riskScore: payload.riskScore
        }
      })
      return res
    },
    async resolveDuplicates({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...AdminAPI.ResolveDuplicates(),
        variables: {
          id: payload.id
        }
      })
      return res
    },
    async checkVerified({ rootState, commit }, payload) {
      if (payload.id) {
        const res = await rootState.apolloClient.query({
          ...AdminAPI.CheckUserValidated(),
          variables: {
            id: payload.id
          }
        })
        if (res.data?.account?.identities.length > 0) {
          commit('SET_CURRENT_VERIFIED', true)
        } else {
          commit('SET_CURRENT_VERIFIED', false)
        }
      } else {
        commit('SET_CURRENT_VERIFIED', false)
      }
    },
    async querySupportedStates({ rootState }) {
      const { data } = await rootState.apolloClient.query({ ...AdminAPI.querySupportedStates() })
      return data.supportedStates
    },
    async updateEmail({ rootState }, payload) {
      const { id, firstName, lastName, phoneNumber, email } = payload
      try {
        const { data } = await rootState.apolloClient.mutate({
          ...AdminAPI.mutateUpdateAccount(id, firstName, lastName, phoneNumber, email)
        })
        return data
      } catch (e) {
        // eslint-disable-next-line
        console.error('Error:', { error: e.message.split(':')[1].trim() })
        return { error: e.message.split(':')[1].trim() }
      }
    }
  },

  mutations: {
    SET_AGENT(state, agent) {
      state.agent = agent
    },
    SET_USER_ACCOUNT_DETAILS(state, account) {
      state.userAccountDetails = account
    },
    SET_CURRENT_VERIFIED(state, verified) {
      state.accountVerified = verified
    },
    SET_CURRENT_INTERVIEW(state, interview) {
      state.currentInterview = interview
    },
    CLEAR_INTERVIEWS(state) {
      state.interviewsList = []
    },
    SET_INTERVIEWS(state, interviews) {
      state.interviewsList = state.interviewsList.concat(interviews)
    },
    SET_STATUS(state, { id, status }) {
      let items = state.interviewsList
      items.forEach((item) => {
        if (item.id === id) {
          item.status = status
        }
      })

      state.interviewsList = items
    }
  }
}

export default adminModule
