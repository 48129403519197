<template>
  <div class="incoming__categories">
    <router-link
      v-for="category in categories"
      :key="category"
      :to="categoryUrl(category)"
      :class="{'active': activeCategory === category}"
    >
      <span>{{ category.toUpperCase() }}</span>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'incoming-categories',

  props: {
    categories: {
      type: Array,
      required: true
    },
    activeCategory: {
      type: String,
      required: false,
      default: null
    }
  },

  methods: {
    categoryUrl (category) {
      const url = `/incoming/c/${category}`
      if (window.location.search.includes('draft')) {
        return `${url}?draft`
      } else {
        return url
      }
    }
  }
}
</script>

<style scoped lang="scss">

@import 'src/assets/styles/colors';
@import 'src/assets/styles/flow';
@import 'src/assets/styles/global';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/media-queries';

.incoming__categories {
  font-size: 0.8rem;
  @include md {
    font-size: 1.1rem;
  }
  a {
    margin-right: 1rem;
    text-decoration: none;
  }
  a.active {
    text-decoration: underline;

  }

  :hover {
    text-decoration: underline !important;
  }
}

</style>
