<template>
  <div>
    <div v-if="hasFullScreen" class="video-display-wrapper" ref="fullScreen">
      <div :class="modalDesktop && !thumbnail ? '' : 'video-display-wrapper__container'">
        <a @click="openFullscreen" class="video-display-wrapper__button" v-if="thumbnail && showThumbnail">
          <div class="video-display-wrapper__image">
            <img :src="`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`">
          </div>
          <div class="video-display-wrapper__overlay">
            <div class="video-display-wrapper__overlay-icon">
              <img src="@/assets/images/ic_youtube.svg" height="60px">
            </div>
          </div>
        </a>
        <div class="video-display-wrapper__embed" v-if="!modalDesktop">
          <VideoIframe :videoId="videoId" :autoplay="autoplay" ref="videoPlayerEmbedWrapper" :standalone="false"/>
        </div>
      </div>
      <div class="video-display-wrapper__button-wrapper" v-if="!thumbnail">
        <div @click="toggleModal" style="cursor:pointer;">
          <slot name="triggerContent">
            <div :class='triggerTextStyle'>{{ triggerText }}</div>
          </slot>
        </div>
      </div>
      <Modal
        :title="modalTitle"
        :showActions="false"
        v-model="isShowing"
        @close="onClose"
        persistent
        v-if="modalDesktop && $gtSm"
      >
        <template v-slot:content>
          <div class="video-overlay">
            <VideoIframe :autoplay="true" v-if="isShowing" :videoId="videoId"/>
          </div>
        </template>
      </Modal>
      <template v-if="modalDesktop && !$gtSm">
        <div class="video-display-wrapper__mobile-fullscreen">
          <VideoIframe :videoId="videoId" ref="videoPlayerEmbedWrapper"/>
        </div>
      </template>
    </div>
    <div v-else class="video-display-wrapper">
      <div class="video-display-wrapper__button-wrapper" v-if="!thumbnail">
        <div @click="toggleVideo" style="cursor:pointer;">
          <slot name="triggerContent">
            <div :class='triggerTextStyle'>{{ triggerText }}</div>
          </slot>
        </div>
        <Modal
          :title="modalTitle"
          :showActions="false"
          v-model="showNonFullscreenVideo"
          @close="onClose"
        >
          <template v-slot:content>
            <div class="video-overlay">
              <VideoIframe v-if="showNonFullscreenVideo" :videoId="videoId"/>
            </div>
          </template>
        </Modal>
      </div>
      <template v-if="thumbnail">
        <VideoIframe :videoId="videoId" ref="videoPlayerEmbedWrapper"
                     :standalone="false"/>
      </template>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import VideoIframe from '@/components/content/VideoIframe.vue'
import { EventBus } from '@/bus/eventbus'
import { openFullScreenAPI } from '@/utils/helpers'

export default {
  name: 'video-display-wrapper',
  components: {
    Modal,
    VideoIframe
  },
  props: {
    modalDesktop: {
      type: Boolean,
      default: false
    },
    videoId: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    thumbnail: {
      type: Boolean,
      default: true
    },
    thumbnailDesktop: {
      type: Boolean,
      default: false
    },
    triggerText: {
      type: String,
      default: 'Open'
    },
    triggerTextStyle: {
      type: String,
      default: 'video-display-wrapper__modal-button'
    },
    modalTitle: {
      type: String,
      default: ''
    },
    playInline: {
      type: Boolean,
      default: false
    },
    classes: undefined,
    styles: undefined
  },
  data () {
    return {
      isShowing: this.show,
      overlayVideoId: '',
      isShowingOverlay: false,
      hasFullScreen: true,
      showNonFullscreenVideo: false
    }
  },
  watch: {
    show (newVal) {
      this.isShowing = newVal
      if (newVal) {
        this.overlayVideoId = this.videoId
      }
    }
  },
  computed: {
    showThumbnail () {
      if (!this.thumbnailDesktop && !this.$gtSm) {
        return true
      } else if (this.thumbnailDesktop) {
        return true
      }
      return false
    }
  },
  methods: {
    toggleModal () {
      if (this.$gtSm) {
        this.isShowing = true
      } else {
        this.openFullscreenModal()
        this.isShowing = false
      }
    },
    toggleVideo () {
      this.showNonFullscreenVideo = true
    },
    openFullscreenModal () {
      EventBus.$emit(`fullEmbed${this.videoId}`)
    },
    openFullscreen () {
      if (this.modalDesktop && this.$gtSm) {
        this.toggleModal()
      } else {
        this.thumbHidden = true
        this.openFull(this.mobileEmbed)
        this.bindClose(this.mobileEmbed)
        EventBus.$emit(`playEmbed${this.videoId}`, this.videoId)
      }
    },
    resetPlayer () {
      this.thumbHidden = false
      EventBus.$emit(`stopEmbed${this.videoId}`, this.videoId)
      this.removeListners()
    },
    openFull (elem) {
      openFullScreenAPI(elem)
    },
    bindClose (elem) {
      if (elem.requestFullscreen) {
        this.fsEvent = 'fullscreenchange'
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        this.fsEvent = 'mozfullscreenchange'
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        this.fsEvent = 'webkitfullscreenchange'
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        this.fsEvent = 'msfullscreenchange'
      }

      document.addEventListener(this.fsEvent, () => {
        if (!document.fullscreenElement) {
          this.resetPlayer()
        }
      }, false)
    },
    checkFullScreen () {
      const { fullScreen } = this.$refs
      const requestFullScreen =
        fullScreen.requestFullScreen ||
        fullScreen.mozRequestFullScreen ||
        fullScreen.webkitRequestFullScreen ||
        fullScreen.msRequestFullscreen

      if (requestFullScreen) {
        return true
      }
      return false
    },
    removeListners () {
      document.removeEventListener(this.fsEvent, () => {
        if (!document.fullscreenElement) {
          this.resetPlayer()
        }
      })
    },
    onClose () {
      this.removeListners()
      this.hideOverlay()
    },
    hideOverlay () {
      this.overlayVideoId = ''
      this.isShowing = false
    },
    onResize (evt) {
      if (this.originalViewport !== this.$gtSm) {
        this.isShowing = false
        if (this.playInline) {
          EventBus.$emit(`stopEmbed${this.videoId}`, this.videoId)
        }
        this.originalViewport = this.$gtSm
      }
    }
  },
  mounted () {
    if ('videoPlayerEmbedWrapper' in this.$refs) {
      this.mobileEmbed = this.$refs.videoPlayerEmbedWrapper.$refs.videoPlayerEmbed
    }
    window.addEventListener('resize', this.onResize)
    this.hasFullScreen = this.checkFullScreen()
    this.originalViewport = this.$gtSm
  },
  beforeDestroy () {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
    this.removeListners()
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';

.video-display-wrapper {
  overflow: hidden;

  &__container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
  }

  &__image {
    width: 100%;
    height: 100%;

    img {
      margin-bottom: -5px;
      width: 100%
    }
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding-bottom: 56.25%;
  }

  &__link {
    color: $c-red;

    &:hover {
      text-decoration: underline;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($c-primary, 0.0);
    z-index: 9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      .video-display-wrapper__overlay-icon {
        opacity: 1.0;
      }
    }

    &-icon {
      opacity: 0.6;
      transition: opacity 0.1s;
    }
  }

  &__modal-button {
    text-align: center;
    font-size: 14px;
    margin: 2em 0;
    color: $c-red;
  }

  &__mobile-fullscreen {
    position: absolute;
    z-index: -1000;
  }

  &__embed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

</style>
