<template>
  <div class="contentful-rich-text" :class="classes" :style="styles">
    <ContentfulContentRenderer :content="textParsed"/>
  </div>
</template>

<script>
import { processRichText } from '@/api/Content.js'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-rich-text',
  components: { ContentfulContentRenderer },
  props: {
    text: undefined,
    classes: undefined,
    styles: undefined,
    renderOptions: undefined
  },
  data: function () {
    return {
      textParsed: undefined
    }
  },
  created () {
    if (this.text) {
      this.textParsed = processRichText(this, this.text)
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/colors";

::v-deep p {
  color: $c-primary;
  font-weight: 300;
  font-size: 1.1em;
  &:not(:first-child) {
    margin-top: 0.8rem;
  }
}

b {
  font-weight: bold;
}
strong {
  font-weight: bold;
}

::v-deep b {
  font-weight: bold;
}

::v-deep strong {
  font-weight: bold;
}

</style>
