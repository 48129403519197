import { RouteName } from '@/router/routes/constants'
import { datadogRum } from '@datadog/browser-rum'

export const crash = async (that, tags = {}, error = undefined, push = RouteName.ERROR) => {
  that = that ?? {}
  tags = tags ?? {}
  error = error ?? new Error('user was sent to contact us page')
  push = push ?? push !== '' ? push : RouteName.ERROR
  tags['sent-to'] = tags['sent-to'] ?? push === RouteName.ERROR ? 'contact' : push
  tags.from = tags.from ?? that.$route?.name ?? window?.location?.href
  tags.hash = tags.hash ?? window?.location?.hash
  datadogRum.addError(error)
  await that.$router.push({ name: push })
}
