<template>
  <div class="interview-payment">
    <FullScreenLoader
      v-if="isLoading"
      :header-text="loadingHeaderText"
      :progress="loadingProgress"
      subtitle="One moment please ..."
    />
    <FlowWrapper
      :show-actions="showInterviewFlowElements"
      :show-buttons="showInterviewFlowElements"
      :progress="showInterviewFlowElements ? 0 : 1"
      :can-move-to-next="hasValidFormData"
      :on-next="onNextPressed"
      :show-back="showBack"
    >
      <template v-slot:header-text v-if="!!headerText">
        {{ headerText }}
      </template>

      <template v-slot:content-header v-if="!isLoading">
        <div class="df-content-header-text">
          {{ title }}
        </div>
      </template>

      <template v-slot:inline-error v-if="hasStripeInitError">
        <ErrorBanner title="Something went wrong, sorry.">
          <p>We had an issue processing your payment. Don't worry, all of your information is secure.</p>
          <p>
            Please try reloading the page and if you still are having issues, send us an email at
            <a href="mailto:support@dayforward.com">support@dayforward.com</a>.
          </p>
        </ErrorBanner>
      </template>

      <template v-slot:content>
        <div class="mq-grid">
          <!-- <div class="cols-10" v-if="isLoading">
            <div class="payment-check-loader">
              <v-progress-circular :color="Colors.red" width="2" size="20" indeterminate />
              <div class="payment-check-loader__text">Checking payment methods ...</div>
            </div>
          </div> -->
          <div class="cols-10 cc-list" v-show="!isLoading && hasExistingCreditCards">
            <div class="cc-list-item">
              <div class="cc-number">Credit card</div>
              <div class="cc-exp">Expires</div>
            </div>
            <div v-for="cc in creditCards" :key="cc.id" class="cc-list-item">
              <div class="cc-number">
                <span class="cc-brand">{{ cc.brand }}</span> ending in
                {{ cc.last4 }}
              </div>
              <div class="cc-exp">{{ cc.expMonth }}/{{ cc.expYear }}</div>
              <div class="cc-actions">
                <a @click="removeCard(cc.id)" class="cc-remove">Replace</a>
              </div>
            </div>
          </div>
          <div v-show="showCardElement" class="cols-10">{{ getSubtitle }}</div>
          <LabeledHolder v-show="showCardElement" label="Credit card" class="cols-10 cols-md-5 tm-1r">
            <div ref="card" class="payment-card"></div>
          </LabeledHolder>
          <div class="cols-5" v-if="$gtSm"></div>
          <div class="cols-10 mt-3r">
            <div v-if="error" class="payment-error">
              {{ error }}
            </div>
          </div>
          <div class="cols-10 mt-4r">
            <span> Want to pay directly from a bank account? </span>
            <a @click="openChat" class="interview-payment__link">Contact us</a>
          </div>
          <!-- <slot name="footer"></slot> -->
          <div class="cols-10 mt-4r">
            <DFButton
              v-if="!showInterviewFlowElements"
              elevation="0"
              @click="onNextPressed"
              :loading="isPaymentUpdating"
              >SUBMIT
            </DFButton>
          </div>
        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
/* global Stripe */
import Colors from '@/assets/styles/_colors.scss'
import BillingAPI from '@/api/BillingAccount'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import LabeledHolder from '@/components/inputs/LabeledHolder'
import DFButton from '@/components/DFButton'
import { mapActions, mapGetters } from 'vuex'
import { stripePubKey } from '@/config/app.config'
import theme from '@/config/theme.config'
import ErrorBanner from '@/components/errors/ErrorBanner.vue'
import FullScreenLoader from '@/components/loading/FullScreenLoader.vue'
import { getFlowStepInfo } from '@/api/StepInfo'
import { apolloClient } from '@/config/apollo.config'
import { InterviewFlagCause as ORF, SectionID } from '@/api/Interview'
import { InteractionType, SectionDisplayOption } from '@/api/Interview/constants'

export default {
  name: 'InterviewPayment',
  components: {
    ErrorBanner,
    FlowWrapper,
    LabeledHolder,
    FullScreenLoader,
    DFButton
  },

  props: {
    headerText: {
      type: String
    },
    title: {
      type: String,
      default: 'Your payment information.'
    },
    subtitle: {
      type: String,
      default: "Don't worry, we won't charge you until you confirm the purchase."
    },
    // forceCapture, if true, will ignore the saved cards state and will force you to
    // re-enter a credit card
    forceCapture: {
      type: Boolean,
      default: false
    },
    onNext: {
      type: Function,
      default: null
    },
    showInterviewFlowElements: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      Colors,
      ORF: ORF,
      billingAccount: null,
      card: null,
      creditCards: null,
      elements: null,
      error: null,
      hasEmptyCardInfo: true,
      hasStripeInitError: false,
      hasValidCard: false,
      hasValidCardInfo: false,
      isEstimate: false,
      isLoading: true,
      isPaymentUpdating: false,
      showBack: true,
      stripe: null
    }
  },

  computed: {
    ...mapGetters('interview', ['interview', 'interviewID']),
    getSubtitle() {
      if (this.isEstimate) {
        return 'Don’t worry, you won’t be charged until we review your application and you are approved for this policy.'
      } else {
        return "Don't worry, we won't charge you until you confirm the purchase."
      }
    },
    showCardElement() {
      return !this.isLoading && (!this.hasExistingCreditCards || this.forceCapture)
    },

    loadingHeaderText() {
      const { title } = getFlowStepInfo(this.$route)
      return title || 'Checkout'
    },

    loadingProgress() {
      const { num, totalSteps } = getFlowStepInfo(this.$route)
      return num / totalSteps
    },

    hasExistingCreditCards() {
      return this.creditCards ? this.creditCards.length > 0 : false
    },

    hasValidFormData() {
      return this.hasValidCardInfo || this.hasExistingCreditCards
    }
  },

  methods: {
    ...mapActions('interview', ['recordInteraction', 'fetchInterviewSection']),

    async openChat() {
      await this.$router.push({ name: 'help' })
    },

    // get the users' billing account information. this will also return whether a user
    // has a linked stripe ID, etc. `getOrCreateBillingAccount` will create or retrieve the
    // users' account.
    async getBillingAccount() {
      const res = await apolloClient.mutate({
        ...BillingAPI.getOrCreateBillingAccount(),
        fetchPolicy: 'no-cache'
      })
      return res.data.getOrCreateBillingAccount
    },

    // get a list of credit cards that have been saved
    async listCreditCards() {
      const res = await apolloClient.query({
        ...BillingAPI.creditCards(),
        fetchPolicy: 'no-cache'
      })
      return res.data.creditCards
    },

    async removeCard(cardId) {
      const res = await apolloClient.mutate({
        ...BillingAPI.detachCreditCard(cardId)
      })
      if (res && res.data) {
        const cards = this.creditCards
        const cardIndex = cards.findIndex((item) => item.id === cardId)
        cards.splice(cardIndex, 1)
        this.creditCards = cards
      }

      return false
    },

    async getCreditCardToken() {
      const result = await this.stripe.createToken(this.card)
      if (result) {
        if (result.error) {
          const code = result.error.code
          if (code.indexOf('invalid') > -1 || code.indexOf('incorrect') > -1 || code === 'expired_card') {
            /*
            incorrect_address, incorrect_cvc, incorrect_number, incorrect_zip
            invalid_card_type, invalid_cvc, invalid_expiry_month, invalid_expiry_year, invalid_number
            */
            this.error = result.error.message
            this.hasValidCard = false
          } else {
            throw new Error('unknown stripe error')
          }
          return null
        }
        return result.token
      }
      throw new Error('unknown stripe error')
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    async loadStripeElements() {
      if (window.Stripe) {
        this.stripeCallback()
        return
      }
      const script = document.createElement('script')
      script.src = 'https://js.stripe.com/v3/'
      script.async = false
      document.head.appendChild(script)
      script.addEventListener('load', this.stripeCallback)
    },

    stripeCallback() {
      const options = {
        hidePostalCode: true,
        iconStyle: 'solid', // solid or default
        hideIcon: false,
        style: {
          base: {
            iconColor: theme.colors.primary,
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 400,
            fontFamily: 'Graphik, sans-serif',
            fontSize: '16px',
            // lineHeight: '20px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#fce883'
            }
          },
          invalid: {
            iconColor: theme.colors.error,
            color: theme.colors.error
          }
        }
      }

      this.hasStripeInitError = false
      this.stripe = Stripe(stripePubKey)
      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', options)
      this.card.mount(this.$refs.card)

      this.isLoading = false

      this.card.on('change', (e) => {
        if (!e.error) {
          this.error = null
        }
        this.hasEmptyCardInfo = false
        if (e.complete) {
          this.hasValidCardInfo = true
        } else if (e.error) {
          this.hasValidCardInfo = false
          this.error = e.error.message
        } else if (e.empty) {
          this.hasValidCardInfo = false
          this.hasEmptyCardInfo = true
        }
      })
    },
    async recordPaymentEntered() {
      try {
        await this.recordInteraction({
          interviewID: this.interviewID,
          actionType: InteractionType.PAYMENT_INFO_ENTERED
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    // events
    async onNextPressed() {
      if (!this.forceCapture && this.hasExistingCreditCards) {
        return true
      }

      this.isPaymentUpdating = true

      let token = null
      let attempt = 0
      let maxWait = 10

      do {
        attempt++
        try {
          token = await this.getCreditCardToken()
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
        // if we get a token, or an error that marks our card as invalid, break the auto-retry loop
        if (token || !this.hasValidCard) {
          break
        }
        await this.sleep(Math.min((1 << attempt) / 2, maxWait) * 1000)
      } while (attempt < 3)

      if (!this.forceCapture && !token) {
        this.isPaymentUpdating = false
        // if we haven't gotten in invalid card state, we have an unknown stripe error so allow the user to continue
        // if we dont have a valid card at this point, abort to allow the user to fix
        if (this.hasValidCard) {
          await this.recordPaymentEntered()
        }
        return this.hasValidCard
      }
      let res
      try {
        res = await apolloClient.mutate({
          ...BillingAPI.attachCreditCard(token.id, true)
        })
      } catch (err) {
        let { graphQLErrors } = err
        if (!graphQLErrors || graphQLErrors.length === 0) throw err

        this.error = 'Please enter a valid credit card number'

        this.isPaymentUpdating = false
        return { navigate: false }
      }
      await this.recordPaymentEntered()
      this.$analytics.idempotentTrack({
        key: this.interviewID,
        event: 'app_epi'
      })

      if (res && res.data) {
        const attachresult = res.data.attachCreditCard
        if (attachresult) {
          const excludeId = attachresult.id
          const cards = await this.listCreditCards()
          if (cards.length > 1) {
            for (const c in cards) {
              if (c && c.hasOwnProperty('id') && c.id !== excludeId) {
                await this.removeCard(c.id)
              }
            }
          }

          if (typeof this.onNext === 'function') {
            const nextRetVal = await this.onNext()
            this.isPaymentUpdating = false
            return nextRetVal
          }
          this.isPaymentUpdating = false
          return true
        }
      }
    },
    async fetchDisplayOptions() {
      const { displayOptions } = await this.fetchInterviewSection({
        id: this.interviewID,
        sectionId: SectionID.PAYMENT
      })
      this.displayOptions = displayOptions || []
      this.showBack = !this.displayOptions?.includes?.(SectionDisplayOption.HIDE_BACK_BUTTON)
    }
  },
  async created() {
    await Promise.all([this.fetchDisplayOptions()])
  },
  async mounted() {
    this.isEstimate = this.interview?.quote?.isEstimate || false
    this.$nextTick(async function () {
      this.billingAccount = await this.getBillingAccount()
      if (this.billingAccount !== null) {
        if (!this.forceCapture) {
          this.creditCards = await this.listCreditCards()
        }
        await this.loadStripeElements()
      }
    })
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';
@import '~vuetify/src/styles/main.sass';
@import 'src/assets/styles/colors';

.cc-list {
  .cc-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
  }

  .cc-number {
    flex: 0 1 50%;

    .cc-brand {
      text-transform: capitalize;
    }
  }

  .cc-exp {
    flex: 0 1 120px;
  }

  .cc-actions {
    flex: 1 0 auto;

    .cc-remove {
      min-height: 16px !important;
      min-width: 50px !important;
    }
  }
}

.payment-card {
  padding: 8px 0;

  &.StripeElement {
    position: relative;
    display: block;
    border-bottom: 1px solid $c-primary;
    font-size: 16px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      border-bottom: 1px solid $c-primary;
      transition: 300ms cubic-bezier(0.25, 0.8, 0.5, 1) transform;
      transform: scaleX(0);
    }

    &--focus {
      &:after {
        transform: scaleX(1);
      }
    }

    &--invalid {
      border-color: $c-error;

      &:after {
        border-color: $c-error;
      }
    }
  }
}

.payment-error {
  color: $c-error;
}

.payment-check-loader {
  display: flex;
  flex-direction: row;

  &__text {
    margin-left: 10px;
  }
}

.interview-payment {
  &__link {
    color: $c-action !important;
    text-decoration: underline !important;

    &:hover {
      text-decoration: none !important;
    }
  }
}
</style>
