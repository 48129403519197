<template>
  <div v-if="$gtMd || showOnMobile" class="contentful-two-column-text mq-container" :style="styles">
    <div class="contentful-two-column-text__wrapper mq-grid">
      <template v-if="!title2">
        <div class="cols-10" :class="titleClassesOrDefault">{{ title }}</div>
        <div class="contentful-two-column-text__body cols-10 cols-lg-4" v-html="columnOneHTML"></div>
        <template v-if="$gtMd">
          <div class="cols-lg-1"></div>
        </template>
        <div class="contentful-two-column-text__body cols-10 cols-lg-4" v-html="columnTwoHTML"></div>
      </template>

      <template v-else>
        <template v-if="$gtMd">
          <div class="cols-10 cols-md-5 cols-lg-4" :class="titleClassesOrDefault">{{ title }}</div>
          <div class="cols-lg-1"></div>
          <div class="cols-10 cols-md-5 cols-lg-4" :class="titleClassesOrDefault">{{ title2 }}</div>
          <div class="contentful-two-column-text__body cols-10 cols-lg-4">
            <ContentfulContentRenderer :content="columnOneHTML"/>
          </div>
          <div class="cols-lg-1"></div>
          <div class="contentful-two-column-text__body cols-10 cols-lg-4">
            <ContentfulContentRenderer :content="columnTwoHTML"/>
          </div>
        </template>
        <template v-else>
          <div v-if="$ltLg" class="cols-10" :class="categoryClassesOrDefault">{{ category1 }}</div>
          <div class="cols-10 cols-md-5 cols-lg-4" :class="titleClassesOrDefault">{{ title }}</div>
          <div class="contentful-two-column-text__body cols-10 cols-lg-4">
            <ContentfulContentRenderer :content="columnOneHTML"/>
          </div>
          <div v-if="$ltLg" class="cols-10" :class="categoryClassesOrDefault">{{ category2 }}</div>
          <div class="cols-10 cols-md-5 cols-lg-4" :class="titleClassesOrDefault">{{ title2 }}</div>
          <div class="contentful-two-column-text__body cols-10 cols-lg-4">
            <ContentfulContentRenderer :content="columnTwoHTML"/>
          </div>
        </template>
      </template>

    </div>
  </div>
</template>

<script>

import { processRichText } from '@/api/Content'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-two-column-text',
  components: { ContentfulContentRenderer },
  props: {
    title: undefined,
    title2: undefined,
    column1Text: undefined,
    column2Text: undefined,
    styles: undefined,
    titleClasses: undefined,
    showOnMobile: undefined,
    category1: undefined,
    category2: undefined,
    categoryClasses: undefined
  },
  computed: {
    columnOneHTML () {
      return processRichText(this, this.column1Text)
    },

    columnTwoHTML () {
      return processRichText(this, this.column2Text)
    },

    titleClassesOrDefault () {
      if (!this.titleClasses) {
        return 'df-title df-title--red'
      }
      return this.titleClasses
    },

    categoryClassesOrDefault () {
      if (!this.categoryClasses) {
        return 'df-subtitle'
      }
      return this.categoryClasses
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.contentful-two-column-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-top: 4rem;
  &__wrapper {
    width: 100%;
  }
  &__body {
    color: $c-primary;
    font-weight: 300;
    font-size: 1.1em;
  }
}

.contentful-two-column-text__body {
  ::v-deep p:not(:last-of-type) {
    margin-top: 0px;
    margin-bottom: 10px;
    @include md {
      margin-top: 10px;
    }
  }
}
</style>
