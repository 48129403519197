<template>
  <ContentfulDocument
    content-id="5l1BzHLoJW089jOpZilFnF"
    :progress="0"
    header-variant="red"
  >
    <template v-slot:content="{document}">
      <div class="document-content">
        <ContentfulContentRenderer :content="document.bodyParsed" />
      </div>
    </template>
  </ContentfulDocument>
</template>

<script>
import ContentfulDocument from '@/views/contentful/ContentfulDocument.vue'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'
export default {
  name: 'privacy-policy',
  components: { ContentfulContentRenderer, ContentfulDocument }
}
</script>

<style scoped lang="scss">
.document-content {
  padding-bottom: 5rem;
}
</style>
