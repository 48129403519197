<template>
  <div :id="componentId" class="contentful-headline-card" :style="extraStyles">
    <div class="contentful-headline-card__inner mq-container" v-observe-visibility="onVisible">
      <template v-if="animated">
        <transition name="headline-fade">
          <div v-if="isVisible" class="contentful-headline-card__text">{{text}}</div>
        </transition>
      </template>
      <template v-else>
        <div class="contentful-headline-card__text">{{text}}</div>
      </template>
    </div>
    <div v-if="showScrollArrow" class="contentful-headline-card__scroll-arrow">
      <transition name="headline-fade">
        <div v-if="isVisible">
          <div v-if="arrowLeadText" class="contentful-headline-card__scroll-text">
            {{arrowLeadText}}
          </div>
          <img src="~@/assets/images/acc_arrow_small.svg">
        </div>
      </transition>
    </div>
  </div>
</template>

<script>

import { ObserveVisibility } from 'vue-observe-visibility'
import Colors from '@/assets/styles/_colors.scss'

export default {
  name: 'contentful-headline-card',
  directives: {
    'observe-visibility': ObserveVisibility
  },
  data: function () {
    return {
      isVisible: !this.animated,
      Colors
    }
  },
  props: {
    componentId: {
      type: String
    },
    text: {
      type: String
    },
    backgroundColor: {
      type: String,
      default: Colors.red
    },
    textColor: {
      type: String,
      default: Colors.eggshell
    },
    arrowLeadText: {
      type: String
    },
    showScrollArrow: {
      type: Boolean,
      default: true
    },
    scrollArrowVariant: {
      type: String
    },
    animated: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    extraStyles () {
      const styles = {}
      if (this.backgroundColor) {
        styles['background-color'] = this.backgroundColor
      }
      if (this.textColor) {
        styles['color'] = this.textColor
      }
      return styles
    }
  },
  methods: {
    onVisible (isVisible) {
      if (!this.isVisible && isVisible) {
        this.isVisible = true
      }
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/transitions";
@import "src/assets/styles/media-queries";

.contentful-headline-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 600px;
  height: 75vh;
  &__inner {
    font-family: 'Graphik', sans-serif;
    font-weight: 600;
    font-size: 3em;
    line-height: 0.98em;
    letter-spacing: -0.02em;
    text-align: left;
    padding: 80px 0;
    @include md {
      font-size: 4em;
      letter-spacing: -0.02em;
    }
    @include lg {
      font-size: 5em;
      padding: 0;
    }
  }
  &__text {
    margin-top: -40px;
    width: 95%;
    letter-spacing: -0.05em;
  }
  &__scroll-arrow {
    text-align: center;
    position: absolute;
    padding: 0;
    margin: 0;
    bottom: 20px;
    width: 200px;
    left: calc(50vw - 100px);
    z-index: 99;
  }
  &__scroll-text {
    margin-bottom: 10px;
  }
}

.headline-fade-enter-active, .headline-fade-leave-active {
  transition: all .7s cubic-bezier(0.32, 1, 0.36, 1);
}

.headline-fade-enter-active {
  transition-delay: .12s;
}

.headline-fade-enter, .headline-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

</style>
