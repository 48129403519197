<template>
  <span class="benefit-amount-select">
    <v-select
      class="df-quote-input quote-benefit-dropdown"
      v-model="selectedValue"
      hide-details
      prefix="$"
      @change="onChange($event)"
      @keydown="onType($event)"
      @input.native="onType($event)"
      :items="benefitItems"
      :style="selectStyles"
    >
      <template v-slot:selection>
        <span class="quote-benefit-dropdown__selected">
          {{ selectedValue.toLocaleString() }}
        </span>
      </template>
    </v-select>
  </span>
</template>

<script>
export default {
  name: 'BenefitAmountSelect',

  props: {
    value: undefined,
    benefitAmountOptions: {
      type: Array,
      required: true
    },
    defaultValue: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      selectedValue: undefined
    }
  },

  computed: {
    selectStyles () {
      const styles = {}
      if (this.selectedValue >= 10000) {
        styles['max-width'] = this.smElse('140px', '200px')
      } else {
        styles['max-width'] = this.smElse('130px', '180px')
      }
      return styles
    },

    benefitItems () {
      return this.benefitAmountOptions.map(item => {
        return { text: `$${item.toLocaleString()}`, value: item }
      })
    }
  },

  methods: {
    onChange (evt) {
      const val = typeof evt === 'object' ? evt.value : evt
      this.$emit('change', val)
      this.selectedValue = val
    },
    onType (evt) {
      this.$emit('change', evt.srcElement.value)
      this.selectedValue = evt.srcElement.value
    }
  },
  updated () {
    if (this.value) {
      this.selectedValue = this.value
    }
  },
  mounted () {
    if (this.value) {
      this.selectedValue = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/media-queries";
@import "src/assets/styles/colors";

span {
  word-wrap: break-word;
}

.benefit-amount-select {
  display: inline-block;
}

.quote-benefit-dropdown__selected {
  color: $c-red;
  font-size: 1.5rem;
  @include md {
    font-size: 2.4rem;
  }
}

.df-quote-input {
  font-weight: bold;
  display: inline-block;
  font-size: 1.75rem;
  color: $c-red;

  ::v-deep {
    .v-text-field__prefix {
      font-size: 1.5rem;
      @include md {
        font-size: 2.3rem;
      }
      line-height: 1;
      color: $c-red;
    }
    .v-input__icon {
      margin-top: 4px;
      margin-bottom: 8px;
      @include md() {
        margin-top: 10px;
      }
    }
  }
}

</style>
