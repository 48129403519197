<template>
  <div :id="id" class="contentful-hero" :style="heroContainerStyles">
    <div class="contentful-hero__wrapper mq-container">
      <div class="contentful-hero__content">
        <div class="contentful-hero__headline" :class="headlineClass">
          <div class="contentful-hero__headline-inner">
            <ContentfulContentRenderer :content="htmlText"/>
          </div>
          <div v-if="byline" class="contentful-hero__byline">{{this.byline}}</div>
          <div v-if="showVideoButton">
            <VideoDisplayWrapper
              videoId="gadBbfqBAO4"
              :thumbnail="false"
              :modalDesktop="true"
              :autoplay="true"
            >
              <template v-slot:triggerContent>
                <div class="contentful-hero__video-button" role="button" aria-pressed="false" tabindex="0">
                  <img src="@/assets/images/ic_play.svg" height="60" width="60">
                  <div class="contentful-hero__video-button-text">
                    Watch video
                  </div>
                </div>
              </template>
            </VideoDisplayWrapper>

          </div>
        </div>
      </div>
      <div v-if="primaryButtonGroup" class="contentful-hero__button">
        <ContentfulButtonGroup v-bind="primaryButtonGroup.fields"/>
      </div>
      <div class="contentful-hero__bottom-section">
        <div v-if="showScrollArrow" class="contentful-hero__scroll-arrow">
          <ScrollArrow :variant="scrollArrowVariant" :line-color="scrollArrowLineColor" :arrow-color="scrollArrowEndColor" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Colors from '@/assets/styles/_colors.scss'
import ContentfulButtonGroup from '@/components/contentful/ContentfulButtonGroup'
import ScrollArrow from '@/components/accessories/ScrollArrow.vue'
import { processRichText } from '@/api/Content'
import VideoDisplayWrapper from '@/components/content/VideoDisplayWrapper.vue'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-hero',
  components: { ContentfulContentRenderer, VideoDisplayWrapper, ScrollArrow, ContentfulButtonGroup },
  props: {
    id: undefined,
    text: undefined,
    headline: undefined,
    headlineSuperscript: undefined,
    byline: undefined,
    headlineClass: undefined,
    primaryButton: undefined,
    primaryButtonGroup: undefined,
    backgroundImage: undefined,
    backgroundColor: undefined,
    showScrollArrow: {
      type: Boolean,
      default: false
    },
    scrollArrowVariant: undefined,
    scrollArrowLineColor: undefined,
    scrollArrowEndColor: undefined
  },
  data: function () {
    return {
      Colors
    }
  },
  computed: {
    showVideoButton () {
      return this.id === 'home-hero'
    },
    htmlText () {
      return processRichText(this, this.text)
    },
    heroContainerStyles () {
      const styles = {}
      if (this.backgroundImage) {
        styles['background-image'] = `url(${this.backgroundImage.fields.file.url})`
      }
      if (this.backgroundColor) {
        styles['background-color'] = this.backgroundColor
      }
      return styles
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/styles/colors';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/media-queries';

$fs: calc(36px + (74 - 36) * ((100vw - 320px) / (1000 - 320)));

.contentful-hero {
  background-size: cover;
  background-position-x: 70%;
  overflow: visible;
  min-height: 680px;
  height: calc(100vh + #{40px});
  @include md {
    min-height: 800px;
  }
  @include lg {
    min-height: 940px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    overflow: visible;
    flex: 1;
  }
  &__headline {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    width: 85%;
    color: $c-red;
    margin-bottom: 80px;
    @include md {
      justify-content: center;
      width: 70%;
      margin-bottom: 100px;
    }
    @include lg {
      flex: 1;
      width: 72%;
    }
    &-inner {
      padding-top: 80px;
      font-family: 'Graphik', sans-serif;
      font-weight: 600;
      ::v-deep sup {
        font-size: 1rem;
        top: -22px;
        @include md {
          top: calc(min(calc(0.5 * #{$fs}), 30px) * -1);
        }
        @include lg {
          top: calc(min(calc(0.5 * #{$fs}), 54px) * -1);
        }
        @include xl {
          top: -58px;
        }
      }
    }

    ::v-deep p {
      letter-spacing: -0.05em;
      font-size: $fs;
      @include xl {
        font-size: 100px;
      }
      line-height: 0.94em;
      text-shadow: 4px 4px 4px rgba(#000, 0.35);
    }
  }
  &__byline {
    font-family: 'Graphik', sans-serif;
    font-weight: 600;
    letter-spacing: -0.05em;
    font-size: 1.1rem;
    margin-top: 1.1rem;
    text-shadow: 4px 4px 4px rgba(#000, 0.2);
    @include md() {
      font-size: 1.2rem;
    }
    @include lg {
      font-size: 1.3rem;
    }
  }

  &__bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__button {
    width: 100%;
    text-align: center;
    margin: 0 0 40px;
    @include md {
      margin: 0 0 70px 0;
      width: auto;
    }
    @include lg {
      margin: 0 0 100px 0;
    }
    ::v-deep button {
      width: 100%;
      @include md {
        width: auto;
      }
    }
  }
  &__scroll-arrow {
    height: 80px;
    width: 10px;
    margin-bottom: -8px;
    z-index: 99;
  }
}

.contentful-hero__video-button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  user-select: none;
  padding-right: 8px;
  margin-left: -6px;
  &-text {
    font-size: 1.4em;
    letter-spacing: -0.02em;
  }
}

</style>
