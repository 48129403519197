<template>
  <div class="interview-thanks">
    <FlowWrapper
      :show-buttons="false"
      header-variant="red"
    >
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
         Your family is protected!
        </div>
      </template>
      <template v-slot:content>
      <div class="mq-grid mq-neg-margin-top">
        <p class="cols-10">
          Your life insurance policy has been issued. You can view, download
          or manage your policy at any time by signing in to your account at Dayforward.com.
        </p>
        <div class="cols-10 tm-3r">
          <DFButton elevation="0" @click="onButtonPressed">View Policy</DFButton>
        </div>
      </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import DFButton from '@/components/DFButton'
import { RouteName } from '@/router/routes/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'InterviewConfirmation',
  components: { FlowWrapper, DFButton },
  methods: {
    ...mapGetters('interview', ['interviewID']),

    async onButtonPressed () {
      await this.$router.push({ name: RouteName.DASHBOARD })
    }
  },

  created () {
    this.$analytics.idempotentTrack({ key: this.interviewID, event: 'app_newPolicyIssued' })
  }
}
</script>

<style scoped lang="scss"></style>
