export const keyFromPath = (key) => {
  return key.replace(/-/g, '_').toUpperCase()
}

export const keyToPath = (key) => {
  return key.toLowerCase().replace(/_/g, '-')
}

export const openFullScreenAPI = (elem) => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen()
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen()
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen()
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen()
  }
}

export const removeUnderscores = (value) => {
  if (value !== null && value !== undefined) {
    return value.toString().replace(/_/g, ' ').toLowerCase()
  }
  return ''
}

export const sentenceCase = (value) => {
  let sentence = removeUnderscores(value).toLowerCase().split(' ')
  if (sentence?.length === 0) {
    return ''
  }
  if (sentence[0].length > 0) {
    sentence[0] = sentence[0][0].toUpperCase() + sentence[0].slice(1)
  }
  return sentence.join(' ')
}

export const lowerCase = (value) => {
  return removeUnderscores(value).toLowerCase()
}

export const titleCase = (value) => {
  let sentence = lowerCase(value).split(' ')
  if (sentence?.length === 0) {
    return ''
  }
  for (let i = 0; i < sentence.length; i++) {
    switch (sentence[i]) {
      case 'and':
      case 'or':
      case 'the':
      case 'to':
        break
      default:
        if (sentence[i].length > 0) {
          sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
        }
    }
  }
  return sentence.join(' ')
}

export const getCookie = (cname) => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
