<template>
  <div class="redirect"></div>
</template>

<script>
const redirectBaseURL = process.env.VUE_APP_REDIRECT_BASE_URL || 'https://app.dayforward.com'

export default {
  name: 'redirect',
  created () {
    window.location = `${redirectBaseURL}${this.$route.path}`
  }
}
</script>
