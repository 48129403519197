<template>
  <div class="interview--inline-options">
    <template v-if="outlined">
      <template v-if="multiple">
        <v-checkbox
          v-for="o in options"
          v-bind="attrs"
          v-model="value"
          :key="o.value"
          :value="o.value"
          :label="o.text"
          hide-details
          @change="onChanged"
          class="interview-question__checkbox"
          :readonly="readonly"
        />
      </template>

      <template v-else>
        <v-radio-group
          v-bind="attrs"
          v-model="value"
          :row="inline"
          @change="onChanged"
          class="interview-question__radio-group"
          :readonly="readonly"
        >
          <v-radio
            v-for="o in options"
            :key="o.value"
            :value="o.value"
            :label="o.text"
            hide-details
            class="interview-question__radio"

          />
        </v-radio-group>
      </template>
    </template>

    <template v-else>
      <div v-for="o in options" :key="o.value" class="interview-question__option">
        <input
          v-if="multiple"
          v-bind="attrs"
          type="checkbox"
          :id="fieldId(o.value)"
          :checked="checked(o.value)"
          :value="o.value"
          @click="toggle(o.value)"
          :readonly="readonly"
        />

        <input
          v-else
          v-bind="attrs"
          type="radio"
          :id="fieldId(o.value)"
          :checked="checked(o.value)"
          :name="name"
          :value="o.value"
          @click="toggle(o.value)"
          :readonly="readonly"
        />
        <label :for="fieldId(o.value)">{{ o.text }}</label>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OptionsInput',
  model: {
    prop: 'selected',
    event: 'input'
  },
  props: {
    options: {
      type: Array,
      default: function () {
        return [
          { value: 'true', text: 'Yes' },
          { value: 'false', text: 'No' }
        ]
      }
    },
    selected: [String, Array],
    multiple: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      value: this.multiple ? [] : null,
      oldValue: undefined
    }
  },
  computed: {
    name () {
      return `iio_${this._uid}`
    },
    outlined () {
      return typeof this.$attrs.outlined === 'boolean' ? this.$attrs.outlined : true
    },
    attrs () {
      const attrs = {}
      const vuetifyProps = ['hide-details']
      vuetifyProps.forEach((a) => {
        if (this.$attrs.hasOwnProperty(a)) {
          attrs[a] = this.$attrs[a]
        }
      })
      return attrs
    }
  },
  watch: {
    selected (val) {
      this.value = this.selected
      this.$emit('changed', this.value, this.oldValue)
      this.oldValue = this.value
    }
  },
  methods: {
    fieldId (v) {
      return `${this.name}_${v}`
    },
    onChanged (val) {
      this.$emit('input', val)
    },
    checked (val) {
      if (this.multiple) {
        return this.selected.indexOf(val) >= 0
      } else {
        return this.selected === val
      }
    },
    toggle (val) {
      if (this.multiple) {
        let selected = this.selected
        if (selected.indexOf(val) > -1) {
          selected.splice(selected.indexOf(val), 1)
        } else {
          selected.push(val)
        }
        this.$emit('input', selected)
      } else {
        this.$emit('input', val)
      }
    }
  },
  created () {
    this.value = this.selected
    this.oldValue = this.selected
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';
.interview-question__option {
  display: inline-block;

  input {
    display: inline-block;
    position: relative;
    appearance: none;
    width: 14px;
    height: 20px;

    &:after {
      content: '\2610';
      position: absolute;
      top: 0;
      left: 0;
      font-size: 20px;
      color: black;
    }

    &:checked:after {
      content: '\2612';
    }
  }

  label {
    display: inline-block;
    margin: 0 8px 0 4px;
    font-size: 14px;
  }
}

.interview-question__checkbox {
  min-height: 34px;
  margin-top: 4px;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.interview-question__radio-group {
  margin: 0;
  ::v-deep .v-input--radio-group__input {
    flex-direction: row;
    align-items: flex-start;
  }

  ::v-deep .v-input__slot {
    margin: 0 !important;
  }
}

.interview-question__radio {
  min-height: 34px;
  padding-right: 45px;
}
</style>
