// content parsing methods

import ContentfulAccordion from '@/components/contentful/ContentfulAccordion.vue'
import ContentfulButtonGroup from '@/components/contentful/ContentfulButtonGroup.vue'
import ContentfulHeadlineCard from '@/components/contentful/ContentfulHeadlineCard.vue'
import ContentfulEmphasizedHeadline from '@/components/contentful/ContentfulEmphasizedHeadline.vue'
import ContentfulHero from '@/components/contentful/ContentfulHero.vue'
import ContentfulImage from '@/components/contentful/ContentfulImage.vue'
import ContentfulStepSections from '@/components/contentful/ContentfulStepSections.vue'
import ContentfulTwoColumnImageCard from '@/components/contentful/ContentfulTwoColumnImageCard.vue'
import ContentfulTitleAndDescription from '@/components/contentful/ContentfulTitleAndDescription.vue'
import { BLOCKS, helpers, INLINES, MARKS } from '@contentful/rich-text-types'
import ContentfulTitle from '@/components/contentful/ContentfulTitle.vue'
import ContentfulRichText from '@/components/contentful/ContentfulRichText.vue'
import ContentfulPricingCalculator from '@/components/contentful/ContentfulPricingCalculator.vue'
import ContentfulTwoColumnText from '@/components/contentful/ContentfulTwoColumnText.vue'
import ContentfulMultiColumnContainer from '@/components/contentful/ContentfulMultiColumnContainer.vue'
import ContentfulInlineVideo from '@/components/contentful/ContentfulInlineVideo.vue'
import ContentfulTwoColumnVideoCard from '@/components/contentful/ContentfulTwoColumnVideoCard.vue'
import ContentfulButton from '@/components/contentful/ContentfulButton'
import ContentfulDefinitionList from '@/components/contentful/ContentfulDefinitionList'
import ContentfulCard from '@/components/contentful/ContentfulCard'
import ContentfulVideoCard from '@/components/contentful/ContentfulVideoCard'
import ContentfulLegal from '@/components/contentful/ContentfulLegal'
import ContentfulPressRelease from '@/components/contentful/ContentfulPressRelease'
import QuoteTool from '@/components/tools/QuoteTool'
import ContentfulCTA from '@/components/contentful/ContentfulCTA.vue'

// contentful component type keys
export const cflCTypeAccordion = 'accordion'
export const cflCTypeButton = 'button'
export const cflCTypeButtonGroup = 'buttonGroup'
export const cflCTypeCTA = 'cta'
export const cflCTypeEmphasizedHeadline = 'emphasizedHeadline'
export const cflCTypeHeadlineCard = 'headlineCard'
export const cflCTypeHomeHero = 'homePageHero'
export const cflCTypeImage = 'image'
export const cflCTypeInlineVideo = 'inlineVideo'
export const cflCTypeMultiColumn = 'multiColumnContainer'
export const cflCTypeRichText = 'richText'
export const cflCTypeStepSections = 'numericStepSections'
export const cflCTypeTitle = 'title'
export const cflCTypePricingCalculator = 'pricingCalculator'
export const cflCTypeTitleAndDescription = 'titleAndDescription'
export const cflCTypeTwoColumnImageCard = 'twoColumnImageCard'
export const cflCTypeTwoColumnVideoCard = 'twoColumnVideoCard'
export const cflCTypeTwoColumnText = 'titleAndTwoColumnText'
export const cflCDefinitionList = 'definitionList'
export const cflCCard = 'card'
export const cflCVideoCard = 'videoCard'
export const cflCLegal = 'legal'
export const cflCTypePressRelease = 'pressRelease'
export const cflCQuoteTool = 'quickQuote'

const contentfulComponents = {
  ContentfulAccordion,
  ContentfulButton,
  ContentfulButtonGroup,
  ContentfulCTA,
  ContentfulEmphasizedHeadline,
  ContentfulHeadlineCard,
  ContentfulHero,
  ContentfulImage,
  ContentfulInlineVideo,
  ContentfulMultiColumnContainer,
  ContentfulRichText,
  ContentfulStepSections,
  ContentfulTitle,
  ContentfulPricingCalculator,
  ContentfulTitleAndDescription,
  ContentfulTwoColumnImageCard,
  ContentfulTwoColumnVideoCard,
  ContentfulTwoColumnText,
  ContentfulDefinitionList,
  ContentfulCard,
  ContentfulVideoCard,
  ContentfulLegal,
  ContentfulPressRelease,
  QuoteTool
}

const defaultMarkRenderers = {
  [MARKS.BOLD]: (children, key, h) => h('strong', { key }, children),
  [MARKS.ITALIC]: (children, key, h) => h('em', { key }, children),
  [MARKS.UNDERLINE]: (children, key, h) => h('u', { key }, children),
  [MARKS.CODE]: (children, key, h) => h('code', { key }, children)
}

// When defining a new function, be careful of newlines in the strings. They get converted to br tags later.
export const defaultNodeRenderers = {
  [BLOCKS.PARAGRAPH]: (node, key, h, next) => {
    return h('p', { key }, next(node.content, key, h, next))
  },
  [BLOCKS.HEADING_1]: (node, key, h, next) =>
    h('h1', { key }, next(node.content, key, h, next)),
  [BLOCKS.HEADING_2]: (node, key, h, next) =>
    h('h2', { key }, next(node.content, key, h, next)),
  [BLOCKS.HEADING_3]: (node, key, h, next) =>
    h('h3', { key }, next(node.content, key, h, next)),
  [BLOCKS.HEADING_4]: (node, key, h, next) =>
    h('h4', { key }, next(node.content, key, h, next)),
  [BLOCKS.HEADING_5]: (node, key, h, next) =>
    h('h5', { key }, next(node.content, key, h, next)),
  [BLOCKS.HEADING_6]: (node, key, h, next) =>
    h('h6', { key }, next(node.content, key, h, next)),
  [BLOCKS.OL_LIST]: (node, key, h, next) =>
    h('ol', { key }, next(node.content, key, h, next)),
  [BLOCKS.UL_LIST]: (node, key, h, next) =>
    h('ul', { key }, next(node.content, key, h, next)),
  [BLOCKS.LIST_ITEM]: (node, key, h, next) =>
    h('li', { key }, next(node.content, key, h, next)),
  [BLOCKS.QUOTE]: (node, key, h, next) =>
    h('blockquote', { key }, next(node.content, key, h, next)),
  [BLOCKS.EMBEDDED_ASSET]: (node, key, h, next) => {
    const file = node.data.target.fields.file
    if (file && file.contentType.startsWith('image')) {
      return h('img', {
        key,
        class: 'df-contentful-img',
        attrs: { src: node.data.target.fields.file.url }
      })
    }
    // eslint-disable-next-line no-console
    console.error(
      `unknown embedded asset [type: ${node.data.target.sys.contentType.sys.id}, id: ${node.data.target.sys.id}]`
    )
    return undefined
  },
  [BLOCKS.EMBEDDED_ENTRY]: (node, key, h, next) => {
    if (node.data.target.fields.videoId) {
      return h('div', { class: 'df-contentful-video-embed' }, [
        h(
          'iframe',
          {
            attrs: {
              ref: 'videoPlayerEmbed',
              src: `https://www.youtube.com/embed/${node.data.target.fields.videoId}?enablejsapi=1`,
              allow: 'autoplay',
              allowfullscreen: 'allowfullscreen',
              mozallowfullscreen: 'mozallowfullscreen',
              msallowfullscreen: 'msallowfullscreen',
              oallowfullscreen: 'oallowfullscreen',
              webkitallowfullscreen: 'webkitallowfullscreen',
              frameborder: '0'
            }
          },
          {}
        )
      ])
    }
    const nodeType = node.data.target.sys.contentType.sys.id
    if (nodeType === 'cta') {
      return h('contentful-cta', { key, attrs: node.data.target.fields }, {})
    }
    // eslint-disable-next-line no-console
    console.error(
      `unknown embedded entry [type: ${node.data.target.sys.contentType.sys.id}, id: ${node.data.target.sys.id}]`
    )
    return undefined
  },

  [BLOCKS.HR]: (node, key, h, next) =>
    h(
      'hr',
      {
        style: {
          height: '1px',
          'border-width': '0',
          'background-color': 'rgba(26, 17, 60, 0.25)',
          'margin-top': '15px',
          'margin-bottom': '15px'
        }
      },
      {}
    ),

  [INLINES.HYPERLINK]: (node, key, h, next) => {
    if (node.data.uri.startsWith('/')) {
      return h(
        'router-link',
        { key, attrs: { to: node.data.uri } },
        next(node.content, key, h, next)
      )
    } else {
      return h(
        'a',
        { key, attrs: { href: node.data.uri, target: '_external' } },
        next(node.content, key, h, next)
      )
    }
  },
  [INLINES.EMBEDDED_ENTRY]: (node, key, h, next) => {
    if (
      node.data.target.sys.contentType &&
      node.data.target.sys.contentType.sys.id === 'styledText'
    ) {
      return h('sup', {}, node.data.target.fields.text)
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `unknown embedded entry [type: ${node.data.target.sys.contentType.sys.id}, id: ${node.data.target.sys.id}]`
      )
      return next(node)
    }
  },
  text: ({ marks, value }, key, h) => {
    if (!marks.length) {
      return value
    }
    const marksReversed = [...marks].reverse()
    return marksReversed.reduce(
      (aggregate, mark, i) =>
        defaultMarkRenderers[mark.type]([aggregate], `${key}-${i}`, h),
      value
    )
  }
}

function componentForContentfulType(type, customComponents) {
  if (customComponents && customComponents[type]) {
    return customComponents[type]
  }
  const componentMap = {}
  componentMap[cflCTypeAccordion] = 'ContentfulAccordion'
  componentMap[cflCTypeButton] = 'ContentfulButton'
  componentMap[cflCTypeButtonGroup] = 'ContentfulButtonGroup'
  componentMap[cflCTypeCTA] = 'ContentfulCTA'
  componentMap[cflCTypeEmphasizedHeadline] = 'ContentfulEmphasizedHeadline'
  componentMap[cflCTypeHeadlineCard] = 'ContentfulHeadlineCard'
  componentMap[cflCTypeHomeHero] = 'ContentfulHero'
  componentMap[cflCTypeImage] = 'ContentfulImage'
  componentMap[cflCTypeInlineVideo] = 'ContentfulInlineVideo'
  componentMap[cflCTypeMultiColumn] = 'ContentfulMultiColumnContainer'
  componentMap[cflCTypeRichText] = 'ContentfulRichText'
  componentMap[cflCTypeStepSections] = 'ContentfulStepSections'
  componentMap[cflCTypeTitle] = 'ContentfulTitle'
  componentMap[cflCTypePricingCalculator] = 'ContentfulPricingCalculator'
  componentMap[cflCTypeTitleAndDescription] = 'ContentfulTitleAndDescription'
  componentMap[cflCTypeTwoColumnImageCard] = 'ContentfulTwoColumnImageCard'
  componentMap[cflCTypeTwoColumnVideoCard] = 'ContentfulTwoColumnVideoCard'
  componentMap[cflCTypeTwoColumnText] = 'ContentfulTwoColumnText'
  componentMap[cflCDefinitionList] = 'ContentfulDefinitionList'
  componentMap[cflCLegal] = 'ContentfulLegal'
  componentMap[cflCCard] = 'ContentfulCard'
  componentMap[cflCVideoCard] = 'ContentfulVideoCard'
  componentMap[cflCTypePressRelease] = 'ContentfulPressRelease'
  componentMap[cflCQuoteTool] = 'QuoteTool'
  return componentMap[type]
}

function processContentfulDocument(vnode, entry) {
  if (!entry) {
    return {}
  }
  const keys = Object.keys(entry.fields)
  const document = {}
  keys.forEach((key, idx) => {
    // name is descriptive only so we can ignore it
    if (key !== 'name') {
      const obj = entry.fields[key]
      if (key === 'body') {
        document[key] = obj
        document[`${key}Parsed`] = processRichText(vnode, obj)
      } else if (key === 'contentTitle') {
        const comp = processContentfulObject(obj, 'emT')
        if (comp) {
          document[key] = comp
        }
      } else {
        document[key] = obj
      }
    }
  })
  return document
}

function processContentfulStructuredDoc(entry, options) {
  if (!entry) {
    return {}
  }
  const keys = Object.keys(entry.fields)
  const document = {}
  keys.forEach((key, idx) => {
    // name is descriptive only so we can ignore it
    if (key !== 'name') {
      const obj = entry.fields[key]
      if (key === 'elements') {
        const components = []
        obj.forEach((arrObj) => {
          const compType = getEntityType(arrObj)
          let compRenderOpts
          if (options.render) {
            compRenderOpts = options.render[compType]
          }
          const comp = processContentfulObject(arrObj, idx, compRenderOpts)
          if (comp) {
            components.push(comp)
          }
        })
        document[key] = components
      } else {
        document[key] = obj
      }
    }
  })
  return document
}

function processContentfulEntry(entry, renderOptions) {
  if (!entry) {
    return []
  }
  const keys = Object.keys(entry.fields)
  const contentComponents = []
  keys.forEach((key, idx) => {
    // name is descriptive only so we can ignore it
    if (key !== 'name') {
      const obj = entry.fields[key]
      if (obj && Array.isArray(obj)) {
        obj.forEach((arrObj) => {
          const comp = processContentfulObject(arrObj, idx, renderOptions)
          if (comp) {
            contentComponents.push(comp)
          }
        })
      } else {
        const comp = processContentfulObject(obj, idx, renderOptions)
        if (comp) {
          contentComponents.push(comp)
        }
      }
    }
  })
  return contentComponents
}

function processContentfulObject(obj, key, options) {
  if (obj.sys && obj.sys.type !== 'Asset' && obj.fields) {
    return {
      id: obj.sys.id,
      key: `${obj.sys.id}-${key}`,
      type: getEntityType(obj),
      data: {
        ...obj.fields,
        renderOptions: options
      }
    }
  }
  return null
}

function processRichText(vnode, text) {
  return new RichTextRenderer(vnode).render(text)
}

function getEntityType(entity) {
  if (entity.sys.contentType) {
    return entity.sys.contentType.sys.id
  }
  return null
}

export class RichTextRenderer {
  constructor(vnode) {
    this.h = vnode.$createElement
  }

  _renderNodeList = (nodes, key) =>
    nodes.map((node, i) => this._renderNode(node, `${key}-${i}`))

  _renderNode = (node, key) => {
    if (helpers.isText(node)) {
      return defaultNodeRenderers.text(node, key, this.h)
    } else {
      const nextFunc = (nodes) => this._renderNodeList(nodes, key)
      const nodeRenderer = defaultNodeRenderers[node.nodeType]
      if (!node.nodeType || !nodeRenderer) {
        // eslint-disable-next-line no-console
        console.error(
          'Unrecognized node type or no renderer) ' + (node.nodeType || 'empty')
        )
        return null
      }
      return nodeRenderer(node, key, this.h, nextFunc)
    }
  }

  render(richText, key) {
    if (!richText) {
      return undefined
    }
    const parentKey = key || `rich-text`
    return this._renderNodeList(richText.content, parentKey)
  }
}

export {
  componentForContentfulType,
  contentfulComponents,
  getEntityType,
  processContentfulDocument,
  processContentfulEntry,
  processContentfulObject,
  processContentfulStructuredDoc,
  processRichText
}
