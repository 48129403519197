<template>
  <div>
    <div class="error-page">
      <header class="df-header df-header__p-top df-header--red">
        <MainNavigationHeader color="#F2EDE0" class="error-page__nav mq-container" />
        <div class="df-header-text mq-container">Let's talk.</div>
      </header>
      <div class="mq-container">
        <div class="df-content-header-text bm-2r">Please contact us.</div>
        <div>
          <p>It looks like we need to talk to you to complete your application — we're ready to chat when you are!</p>
          <p>
            Call us at 1-888-434-0228 or click the button below to chat 7 days a week from 9am-9pm CT. After hours?
            We'll get back to you the next business day.
          </p>
          <div class="tm-3r">
            <DFButton @click="handleClick">Contact Us</DFButton>
          </div>
        </div>
      </div>
    </div>
    <MainFooter />
  </div>
</template>

<script>
import DFButton from '@/components/DFButton.vue'
import MainFooter from '@/components/navigation/MainFooter'
import MainNavigationHeader from '@/components/navigation/MainNavigationHeader.vue'

export default {
  name: 'error-page',
  components: { MainNavigationHeader, DFButton, MainFooter },
  props: {
    error: [Object, String, Error],
    reason: {
      type: String
    },
    hideNavigation: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    async handleClick() {
      await this.$router.push({ name: 'help' })
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';

.df-header-text {
  min-height: 90px;
}

.df-content-header-text {
  margin-top: 2rem;
}

.error-page {
  min-height: 100vh;
  p {
    margin-bottom: 1.2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__nav {
    margin-bottom: 1.25rem;
    @include md() {
      margin-bottom: 2.5rem;
    }
  }
}
</style>
