<template>
  <div>
    <p v-if="id"><strong>Order ID:</strong> {{ id }}</p>
    <p v-if="created"><strong>Created:</strong> {{  moment(created).format("L LTS -Z") }}</p>
    <p v-if="status"><strong>Status:</strong> {{ status.toLowerCase() }}</p>
    <p v-if="updated"><strong>Updated:</strong> {{  moment(updated).format("L LTS -Z") }}</p>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'vendor-detail',
  props: {
    id: {
      type: String,
      default: undefined
    },
    created: {
      type: String,
      default: undefined
    },
    status: {
      type: String,
      default: undefined
    },
    updated: {
      type: String,
      default: undefined
    }

  },
  data () {
    return {}
  },
  methods: {
    moment
  }
}
</script>

<style scoped>
</style>
