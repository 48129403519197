<template>
  <div class="interview-beneficiaries">
    <FlowWrapper
      :progress="8 / 8"
      :can-move-to-next="hasValidFormData"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      :on-back="goBack"
    >
      <template v-slot:content-header>
        <div class="df-content-header-text">Your beneficiaries.</div>
      </template>

      <template v-slot:content>
        <v-form v-if="!isLoading">
          <div class="mq-grid">
            <label class="cols-10"
              >If you pass away, who in your family should get your income? (Don't worry, you can change this any
              time.*)</label
            >
            <Question
              v-model="values[QK.BENEFICIARY_TYPE]"
              :question="questions[QK.BENEFICIARY_TYPE]"
              :error="errors[QK.BENEFICIARY_TYPE]"
              class="cols-10"
              type="select"
              :hide-label="true"
              :input-width="smElse('100%', '320px')"
              @input="beneficiaryTypeChanged"
            />
            <template v-if="hasAllChildren">
              <div class="cols-10 cols-md-6">
                Protects current and future children. Once you have a policy, we'll work with you to make sure your
                children, guardian or custodian can be identified and contacted if you pass away.
              </div>
            </template>
            <template v-if="hasSomeoneElse">
              <Question
                v-model="values[QK.BENEFICIARY_RELATIONSHIP]"
                :question="questions[QK.BENEFICIARY_RELATIONSHIP]"
                :error="errors[QK.BENEFICIARY_RELATIONSHIP]"
                class="cols-10"
                type="select"
                :input-width="smElse('100%', '320px')"
                :key="getComponentKey(QK.BENEFICIARY_RELATIONSHIP)"
                @input="beneficiaryRelationshipChanged"
              />
            </template>
            <template v-if="hasIndividual">
              <Question
                v-model="values[QK.BENEFICIARY_FIRST_NAME]"
                :question="questions[QK.BENEFICIARY_FIRST_NAME]"
                :error="errors[QK.BENEFICIARY_FIRST_NAME]"
                class="cols-10 cols-md-5 cols-lg-3"
                :key="getComponentKey(QK.BENEFICIARY_FIRST_NAME)"
                maxlength="50"
              />
              <Question
                v-model="values[QK.BENEFICIARY_LAST_NAME]"
                :question="questions[QK.BENEFICIARY_LAST_NAME]"
                :error="errors[QK.BENEFICIARY_LAST_NAME]"
                class="cols-10 cols-md-5 cols-lg-4"
                :key="getComponentKey(QK.BENEFICIARY_LAST_NAME)"
                maxlength="50"
              />
              <div class="cols-3" v-if="$gtMd"></div>
              <div class="cols-10">
                <Question
                  v-model="values[QK.BENEFICIARY_BIRTH_DATE]"
                  :question="questions[QK.BENEFICIARY_BIRTH_DATE]"
                  :error="errors[QK.BENEFICIARY_BIRTH_DATE]"
                  :input-width="smElse('100%', '200px')"
                  @input="validateDate(QK.BENEFICIARY_BIRTH_DATE)"
                  :key="getComponentKey(QK.BENEFICIARY_BIRTH_DATE)"
                  :validate-on-blur="false"
                />
              </div>
              <div class="cols-5" v-if="$gtSm"></div>

              <div class="confirm-checkboxes cols-10">
                <Question
                  v-if="showFinancialImpact"
                  v-model="values[QK.BENEFICIARY_HAS_FINANCIAL_IMPACT]"
                  :question="questions[QK.BENEFICIARY_HAS_FINANCIAL_IMPACT]"
                  :error="errors[QK.BENEFICIARY_HAS_FINANCIAL_IMPACT]"
                  type="ack"
                  :key="getComponentKey(QK.BENEFICIARY_HAS_FINANCIAL_IMPACT)"
                />
              </div>
            </template>

            <template v-if="hasTrust">
              <Question
                v-model="values[QK.BENEFICIARY_TRUST_TYPE]"
                :question="questions[QK.BENEFICIARY_TRUST_TYPE]"
                :error="errors[QK.BENEFICIARY_TRUST_TYPE]"
                class="cols-10 cols-md-5"
                type="select"
                :key="getComponentKey(QK.BENEFICIARY_TRUST_TYPE)"
              />
              <div class="cols-5" v-if="$gtSm"></div>

              <template v-if="hasTrustOther">
                <div class="cols-10">
                  <Question
                    v-model="values[QK.BENEFICIARY_TRUST_FORMATION_DATE]"
                    :question="questions[QK.BENEFICIARY_TRUST_FORMATION_DATE]"
                    :error="errors[QK.BENEFICIARY_TRUST_FORMATION_DATE]"
                    :input-width="smElse('100%', '200px')"
                    @input="validateDate(QK.BENEFICIARY_TRUST_FORMATION_DATE)"
                    :key="getComponentKey(QK.BENEFICIARY_TRUST_FORMATION_DATE)"
                    :validate-on-blur="false"
                  />
                </div>
                <Question
                  v-model="values[QK.BENEFICIARY_TRUST_NAME]"
                  :question="questions[QK.BENEFICIARY_TRUST_NAME]"
                  :error="errors[QK.BENEFICIARY_TRUST_NAME]"
                  class="cols-10 cols-md-5"
                  :key="getComponentKey(QK.BENEFICIARY_TRUST_NAME)"
                  maxlength="50"
                />
                <div class="cols-5" v-if="$gtSm"></div>
              </template>
            </template>
            <p class="cols-10">
              *Please <a @click="onLinkClick">contact us</a> if you'd like to make an irrevocable beneficiary
              designation.
            </p>
            <p class="cols-10" v-if="variant === 'arizona'">
              Please note that we will require spousal signature if you select a beneficiary other than spouse, child,
              grandchild, parent, or sibling. We will contact you with further details once your application has been
              submitted.
            </p>
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { QuestionKey as QK, BeneficiaryRelationship, BeneficiaryRelationshipIndividual } from '@/api/Interview'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'
import moment from 'moment'
import { RouteName } from '@/router/routes/constants'

export default {
  name: 'InterviewBeneficiary',
  components: { FlowWrapper, Question },

  data() {
    return {
      STEP_NAME: 'confirm-identity',
      QK: QK,
      questions: {},
      values: {},
      errors: {},
      isLoading: true,
      dateIsValid: false,
      componentKeys: {},
      variant: false
    }
  },

  computed: {
    ...mapGetters('interview', [
      'interviewID',
      'interview',
      'answerForKey',
      'mapToAnswers',
      'parseBool',
      'validateKeys',
      'hasDiagnosisAnswers',
      'allHealthFollowups'
    ]),

    questionKeys() {
      return [
        QK.BENEFICIARY_TYPE,
        QK.BENEFICIARY_RELATIONSHIP,
        QK.BENEFICIARY_FIRST_NAME,
        QK.BENEFICIARY_LAST_NAME,
        QK.BENEFICIARY_BIRTH_DATE,
        QK.BENEFICIARY_HAS_FINANCIAL_IMPACT,
        QK.BENEFICIARY_TRUST_TYPE,
        QK.BENEFICIARY_TRUST_FORMATION_DATE,
        QK.BENEFICIARY_TRUST_NAME
      ]
    },

    answerValues() {
      return this.values
    },

    hasIndividual() {
      const bt = this.values[QK.BENEFICIARY_TYPE]
      const br = this.values[QK.BENEFICIARY_RELATIONSHIP]
      return bt === BeneficiaryRelationship.SOMEONE_ELSE
        ? Object.values(BeneficiaryRelationshipIndividual).indexOf(br) !== -1
        : Object.values(BeneficiaryRelationshipIndividual).indexOf(bt) !== -1
    },

    hasAllChildren() {
      return this.values[QK.BENEFICIARY_TYPE] === BeneficiaryRelationship.ALL_CHILDREN
    },

    showFinancialImpact() {
      const br = this.values[QK.BENEFICIARY_RELATIONSHIP]
      const individuals = Object.values(BeneficiaryRelationshipIndividual)
      return individuals.indexOf(br) !== -1 && br !== BeneficiaryRelationship.ONE_CHILD
    },

    hasSomeoneElse() {
      return this.values[QK.BENEFICIARY_TYPE] === BeneficiaryRelationship.SOMEONE_ELSE
    },

    hasOtherRelationship() {
      if (!this.hasSomeoneElse) {
        return false
      }
      return this.values[QK.BENEFICIARY_RELATIONSHIP] === BeneficiaryRelationship.OTHER
    },

    hasNonOtherRelationship() {
      return (
        !this.hasSomeoneElse ||
        (!!this.values[QK.BENEFICIARY_RELATIONSHIP] &&
          this.values[QK.BENEFICIARY_RELATIONSHIP] !== BeneficiaryRelationship.OTHER)
      )
    },

    hasTrust() {
      return this.values[QK.BENEFICIARY_TYPE] === BeneficiaryRelationship.TRUST
    },

    hasTrustOther() {
      return this.values[QK.BENEFICIARY_TRUST_TYPE] === 'OTHER'
    },

    activeKeys() {
      if (Object.keys(this.questions).length === 0) return []

      let activeKeys = [QK.BENEFICIARY_TYPE]

      if (this.hasIndividual) {
        activeKeys = activeKeys.concat([QK.BENEFICIARY_FIRST_NAME, QK.BENEFICIARY_LAST_NAME, QK.BENEFICIARY_BIRTH_DATE])

        if (this.hasSomeoneElse) {
          activeKeys = activeKeys.concat(QK.BENEFICIARY_RELATIONSHIP)
        }
        if (this.showFinancialImpact) {
          activeKeys = activeKeys.concat(QK.BENEFICIARY_HAS_FINANCIAL_IMPACT)
        }
      } else if (this.hasTrust) {
        activeKeys = activeKeys.concat(QK.BENEFICIARY_TRUST_TYPE)
        if (this.hasTrustOther) {
          activeKeys = activeKeys.concat([QK.BENEFICIARY_TRUST_FORMATION_DATE, QK.BENEFICIARY_TRUST_NAME])
        }
      }
      return activeKeys
    },

    hasValidFormData() {
      const activeKeys = this.activeKeys
      if (
        activeKeys.indexOf(QK.BENEFICIARY_HAS_FINANCIAL_IMPACT) > -1 &&
        !this.parseBool(this.values[QK.BENEFICIARY_HAS_FINANCIAL_IMPACT])
      ) {
        return false
      }
      if (this.hasIndividual || this.hasTrustOther) {
        if (!this.dateIsValid) return false
      }
      return this.validateKeys(activeKeys, this.values)
    }
  },

  methods: {
    ...mapActions('interview', ['interviewQuestionAnswerMaps', 'updateInterviewAnswers', 'validateInterviewAnswers']),

    onLinkClick() {
      this.$router.push('/contact')
    },

    clearValues(skipKeys) {
      this.questionKeys.forEach((key) => {
        if (skipKeys.indexOf(key) === -1) {
          this.$delete(this.values, key)
        }
        this.incrementComponentKey(key)
      })
    },

    beneficiaryTypeChanged() {
      this.clearValues([QK.BENEFICIARY_TYPE])
    },

    beneficiaryRelationshipChanged() {
      this.clearValues([QK.BENEFICIARY_TYPE, QK.BENEFICIARY_RELATIONSHIP])
    },

    getComponentKey(key) {
      if (this.componentKeys[key]) {
        return this.componentKeys[key]
      } else {
        this.componentKeys[key] = `${key}+0`
        return this.componentKeys[key]
      }
    },

    incrementComponentKey(key) {
      if (this.componentKeys[key]) {
        const componentKey = this.componentKeys[key]
        const keyNumber = /\d+$/g.exec(componentKey)
        if (keyNumber) {
          this.componentKeys[key] = `${key}+${parseInt(keyNumber[0]) + 1}`
        }
      } else {
        this.componentKeys[key] = `${key}+0`
      }
    },

    async validateDate(key) {
      this.$delete(this.errors, key)
      if (!moment(this.values[key], 'YYYY-MM-DD').isValid()) {
        this.dateIsValid = false
        return
      }
      const { success } = await this.validateAnswer(key, this.values[key])
      this.dateIsValid = success
    },

    async validateAnswer(key, value) {
      if (!value) {
        return { success: false, result: undefined }
      }

      const answer = {
        questionKey: key,
        values: [value]
      }

      const genRequestId = Math.random().toString(36).substr(2)
      const { results } = await this.validateInterviewAnswers({
        id: this.interviewID,
        answers: [answer],
        context: { requestId: genRequestId }
      })

      if (results && results.length > 0) {
        const result = results[0]
        if (!result.success) {
          this.$set(this.errors, key, result.message)
        } else {
          this.$delete(this.errors, key)
        }
        return { success: result.success, result }
      }
      return { success: false, result: undefined }
    },

    async save() {
      const result = await this.updateInterviewAnswers({
        id: this.interviewID,
        answers: this.mapToAnswers(this.values, this.activeKeys)
      })

      if (result.errors) {
        this.errors = result.errors
        return false
      }

      return true
    },

    async fetchExistingInterviewAnswers() {
      const { questions, answers } = await this.interviewQuestionAnswerMaps({
        id: this.interviewID,
        keys: this.questionKeys
      })

      this.questions = questions
      this.values = answers
      this.isLoading = false
    },

    async goBack() {
      // TODO: do we use the confirm info pages anymore?
      // if (this.hasIdentityFollowup) {
      //   await this.$router.push({ name: RouteName.INTERVIEW_CONFIRM_INFO_POSTQUOTE })
      // }
      if (this.hasDiagnosisAnswers) {
        const lastHealthFollowupIndex = this.allHealthFollowups.length - 1
        const lastHealthDetailsIndex =
          this.allHealthFollowups[lastHealthFollowupIndex].answerDetails.answerValues.length - 1
        await this.$router.push({
          name: RouteName.FINALIZE_HEALTH_DIAGNOSIS,
          params: {
            followupIndex: lastHealthFollowupIndex,
            detailIndex: lastHealthDetailsIndex
          }
        })
      } else {
        await this.$router.push({
          name: this.$route.meta.interview.previousStep
        })
      }
      return { navigate: false }
    }
  },

  async created() {
    if (this.interview) {
      await this.fetchExistingInterviewAnswers()
      this.dateIsValid = true
    }
    if (this.$flagsmith?.hasFeature('arizona_release')) {
      this.variant = 'arizona'
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/global';
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/transitions';
</style>
