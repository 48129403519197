
export const noNativeRouting = {
  preventNativeRouting: true
}

export function withDefaultMeta (defaultMeta, routes) {
  return routes.map((route) => {
    return {
      ...route,
      meta: {
        ...defaultMeta,
        ...route.meta
      }
    }
  })
}
