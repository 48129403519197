import gql from 'graphql-tag'

export const PolicyStatus = {
  UNSPECIFIED_POLICY_STATUS: 'UNSPECIFIED_POLICY_STATUS',
  PENDING: 'PENDING',
  IN_GOOD_STANDING: 'IN_GOOD_STANDING',
  GRACE: 'GRACE',
  LAPSED: 'LAPSED',
  TERMINATED: 'TERMINATED',
  CANCELLED: 'CANCELLED',
  RESCINDED: 'RESCINDED',
  ERROR: 'ERROR',
  CLAIM_PENDING: 'CLAIM_PENDING',
  PAYOUT_PERIOD: 'PAYOUT_PERIOD',
  PAYOUT_COMPLETE: 'PAYOUT_COMPLETE',
  MATURED: 'MATURED'
}

export const PolicyFlag = {
  UNDEFINED_FLAG: 'UNDEFINED_FLAG',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PACKET_GENERATION_FAILED: 'PACKET_GENERATION_FAILED',
  NEEDS_APPROVAL: 'NEEDS_APPROVAL',
  INCORRECT_POLICY_PACKET: 'INCORRECT_POLICY_PACKET'
}

export default {
  getPolicy: (id) => ({
    query: gql`
      query GetPolicy($id: String!) {
        policy(id: $id) {
          id
          interviewID
          status
          flags
          number
        }
      }
    `,
    variables: {
      id: id
    }
  }),

  getPolicyByInterviewID: (id) => ({
    query: gql`
      query GetPolicyByInterviewId($id: String!) {
        policies(where: {interviewID: $id}) {
          id
          interviewID
          status
          flags
          invoices {
            id
            paid
          }
          startDate
          endDate
          semiMonthlyBenefit
          monthlyPremium
          coveredIncome
          holder {
            firstName
            lastName
            emailAddress
            accountID
            dateOfBirth
            gender
            address {
              line1
              line2
              city
              state
              postalCode
              countryCode
            }
          }
          beneficiaries {
            relationship
            trust {
              name
              formationDate
            }
            person {
              firstName
              lastName
              emailAddress
              accountID
              dateOfBirth
              gender
              address {
                line1
                line2
                city
                state
                postalCode
                countryCode
              }
            }
          }
        }
      }
    `,
    variables: {
      id: id
    }
  }),

  getAccountPolicies: (id) => ({
    query: gql`
      query FindPolicies($id: String!) {
        policies(where: { accountID: $id }) {
          id
          interviewID
          status
          flags
          invoices {
            id
            paid
          }
          startDate
          endDate
          semiMonthlyBenefit
          monthlyPremium
          coveredIncome
          holder {
            firstName
            lastName
            emailAddress
            accountID
            dateOfBirth
            gender
            address {
              line1
              line2
              city
              state
              postalCode
              countryCode
            }
          }
          beneficiaries {
            relationship
            trust {
              name
              formationDate
            }
            person {
              firstName
              lastName
              emailAddress
              accountID
              dateOfBirth
              gender
              address {
                line1
                line2
                city
                state
                postalCode
                countryCode
              }
            }
          }
        }
      }
    `,
    variables: {
      id: id
    }
  }),

  policyDownloadURL: (id) => ({
    query: gql`
      query PolicyDownloadURL($id: String!) {
        policyDownloadURL(id: $id)
      }
    `,
    variables: {
      id: id
    }
  }),

  payInvoice: (id) => ({
    mutation: gql`
      mutation PayInvoice($id: String!) {
        payInvoice(invoiceID: $id) {
          id
          total
          paid
        }
      }
    `,
    variables: {
      id: id
    }
  })
}
