import { geocoderAPIKey } from '@/config/app.config'
import axios from 'axios'

const geocoderApi = {

  checkIsConfigured () {
    if (!geocoderAPIKey) {
      throw new Error('You must specify a value for \'process.env.VUE_APP_API_KEY_GEOCODER\'!')
    }
  },

  findLocationsForAddress (address) {
    this.checkIsConfigured()
    const url = 'https://maps.googleapis.com/maps/api/geocode'
    let client = axios.create({
      baseURL: url,
      timeout: 30000
    })
    return client.get('/json', {
      params: {
        address: address.trim(), // axios handles encoding this way
        key: geocoderAPIKey
      }
    })
  },

  addressComponentsToAddress (components) {
    if (components === undefined) return { isValid: false, address: undefined }

    let address = {}

    components.forEach(c => {
      c.types.forEach(t => {
        if (t === 'street_number') {
          address.streetNumber = c['long_name']
          if (address.streetName) {
            address.line1 = `${address.streetNumber} ${address.streetName}`.trim()
          } else {
            address.line1 = address.streetNumber
          }
          return false
        } else if (t === 'route') {
          address.streetName = c['short_name']
          if (address.streetNumber) {
            address.line1 = `${address.streetNumber} ${address.streetName}`.trim()
          } else {
            address.line1 = address.streetName
          }
          return false
        } else if (t === 'locality') {
          address.city = c['long_name']
          return false
        } else if (t === 'sublocality' && address.city === undefined) {
          address.city = c['long_name']
          return false
        } else if (t === 'administrative_area_level_1') {
          address.state = c['short_name']
          return false
        } else if (t === 'country') {
          address.country = c['short_name']
          return false
        } else if (t === 'postal_code') {
          address.zip = c['long_name']
          return false
        }
      })
    })
    if (address.country !== 'US') {
      return { isValid: false, address: undefined }
    }
    let isValid = address.streetNumber !== undefined && address.streetName !== undefined &&
      address.city !== undefined && address.state !== undefined && address.zip !== undefined
    if (!isValid) {
      return { isValid: false, address: undefined }
    }
    return { isValid: true, address: address }
  }
}

export default geocoderApi
