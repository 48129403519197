<template>
  <div class="interview-income">
    <FlowWrapper
      :progress="3/8"
      :can-move-to-next="hasValidFormData"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      @result="onResult"
    >
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          What income are you protecting?
        </div>
      </template>
      <template v-slot:content>
        <v-form ref="incomeForm" v-if="!isLoading">
          <div class="mq-grid">
            <Question
              class="cols-10"
              v-model="values[QK.CURRENTLY_WORKING]"
              :question="questionsMap[QK.CURRENTLY_WORKING]"
              :error="errors[QK.CURRENTLY_WORKING]"
              @input="onEmploymentFlagChanged"
            />
            <template v-if="isCurrentlyWorking">
              <Question
                class="cols-10"
                v-model="values[QK.OCCUPATION]"
                :question="questionsMap[QK.OCCUPATION]"
                :error="errors[QK.OCCUPATION]"
                no-data-text="Please select from the occupations we suggest."
                hide-details
                :input-width="smElse('100%', '400px')"
              />
              <div class="income__dollar-entries cols-10">
                <Question
                  class="income__dollar-entries-first"
                  v-model="values[QK.ANNUAL_INCOME]"
                  @input="onAnnualIncomeEntered"
                  :question="questionsMap[QK.ANNUAL_INCOME]"
                  :error="errors[QK.ANNUAL_INCOME]"
                  prefix="$"
                  hide-details
                  :loading="checkingIncome"
                  :input-width="smElse('100%', '180px')"
                  :key="getComponentKey(QK.ANNUAL_INCOME)"
                />
                <Question
                  v-model="values[QK.ANNUAL_HOUSEHOLD_INCOME]"
                  :question="questionsMap[QK.ANNUAL_HOUSEHOLD_INCOME]"
                  :error="errors[QK.ANNUAL_HOUSEHOLD_INCOME]"
                  prefix="$"
                  :input-width="smElse('100%', '180px')"
                  :key="getComponentKey(QK.ANNUAL_HOUSEHOLD_INCOME)"
                  @input="onHouseholdIncomeEntered"
                  :validate-on-blur="false"
                />
              </div>

            </template>
            <template v-else>
              <Question
                class="cols-10"
                v-model="nonWorkingReasons"
                :question="questionsMap[QK.NOT_CURRENTLY_WORKING_REASON]"
                :error="errors[QK.NOT_CURRENTLY_WORKING_REASON]"
                @changed="onUnemployedReasonsChanged"
                hide-details
              />
              <Question
                v-if="isRecentlyUnemployed"
                class="cols-10"
                v-model="values[QK.PREVIOUS_OCCUPATION]"
                :question="questionsMap[QK.PREVIOUS_OCCUPATION]"
                :error="errors[QK.PREVIOUS_OCCUPATION]"
                no-data-text="Please select from the occupations we suggest."
                hide-details
                :input-width="smElse('100%', '400px')"
              />
              <div class="income__dollar-entries cols-10">
                <Question
                  v-if="askForPreviousIncome"
                  class="income__dollar-entries-first"
                  v-model="values[QK.PREVIOUS_ANNUAL_INCOME]"
                  @input="onPreviousIncomeEntered"
                  :question="questionsMap[QK.PREVIOUS_ANNUAL_INCOME]"
                  :error="errors[QK.PREVIOUS_ANNUAL_INCOME]"
                  prefix="$"
                  hide-details
                  :loading="checkingIncome"
                  :input-width="smElse('100%', '180px')"
                />
                <Question
                  v-if="askForHouseholdIncome"
                  v-model="values[QK.ANNUAL_HOUSEHOLD_INCOME]"
                  :question="questionsMap[QK.ANNUAL_HOUSEHOLD_INCOME]"
                  :error="errors[QK.ANNUAL_HOUSEHOLD_INCOME]"
                  prefix="$"
                  :input-width="smElse('100%', '180px')"
                  :key="getComponentKey(QK.ANNUAL_HOUSEHOLD_INCOME)"
                  @input="onHouseholdIncomeEntered"
                  :validate-on-blur="false"
                />
              </div>
            </template>
            <div class="income__footnote cols-10">
              <p v-if="isCurrentlyWorking || isRecentlyUnemployed">If you make less than $20,000, please also include your household income.</p>
              <p v-if="isStayAtHomeParent">As a stay at home parent, many people can get 50% of their household
                income.</p>
            </div>
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { InterviewFlagCause, QuestionKey as QK } from '@/api/Interview'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'

export default {
  name: 'InterviewIncomeStep',
  components: { FlowWrapper, Question },

  data () {
    return {
      STEP_NAME: 'income',
      QK: QK,
      ORF: InterviewFlagCause,
      values: {},
      errors: {},
      isLoading: false,
      askForAnnualIncome: true,
      askForPreviousIncome: false,
      askForHouseholdIncome: false,
      nonWorkingReasons: undefined,
      checkingIncome: false,
      componentKeys: {}
    }
  },

  computed: {
    ...mapGetters('interview', ['answersForKeys', 'questionsMap', 'interviewID', 'parseBool', 'validateKeys']),
    questionKeys () {
      return [
        QK.CURRENTLY_WORKING,
        QK.OCCUPATION,
        QK.ANNUAL_INCOME,
        QK.ANNUAL_HOUSEHOLD_INCOME,
        QK.NOT_CURRENTLY_WORKING_REASON,
        QK.PREVIOUS_OCCUPATION,
        QK.PREVIOUS_ANNUAL_INCOME
      ]
    },

    answerValues () {
      return this.values
    },

    isStayAtHomeParent () {
      return this.values[QK.NOT_CURRENTLY_WORKING_REASON].indexOf('STAY_AT_HOME_PARENT_OR_HOMEMAKER') !== -1
    },

    isCurrentlyWorking () {
      return this.parseBool(this.values[QK.CURRENTLY_WORKING])
    },

    isRecentlyUnemployed () {
      if (this.isCurrentlyWorking) return false
      if (!this.values || !this.values[QK.NOT_CURRENTLY_WORKING_REASON]) return false
      return this.values[QK.NOT_CURRENTLY_WORKING_REASON].indexOf('UNEMPLOYED_LESS_THAN_ONE_YEAR') !== -1
    },

    isLongTermUnemployed () {
      return !this.isCurrentlyWorking && !this.isRecentlyUnemployed
    },

    activeKeys () {
      let activeKeys = [QK.CURRENTLY_WORKING]
      if (this.isCurrentlyWorking) {
        activeKeys = activeKeys.concat([QK.OCCUPATION, QK.ANNUAL_INCOME])
      } else {
        activeKeys = activeKeys.concat(QK.NOT_CURRENTLY_WORKING_REASON)
        if (this.isRecentlyUnemployed) {
          activeKeys = activeKeys.concat([QK.PREVIOUS_OCCUPATION, QK.PREVIOUS_ANNUAL_INCOME])
        }
      }
      if (this.askForHouseholdIncome || this.isLongTermUnemployed) {
        activeKeys = activeKeys.concat(QK.ANNUAL_HOUSEHOLD_INCOME)
      }
      return activeKeys
    },

    hasValidFormData () {
      if (this.isLoading) {
        return false
      }

      if (!this.isIncomeValid()) return false

      for (let key of this.activeKeys) {
        if (this.errors[key]) {
          return false
        }
      }

      return this.validateKeys(this.activeKeys, this.values) && !this.checkingIncome
    }
  },

  methods: {
    ...mapActions('interview', ['interviewQuestions', 'updateInterviewAnswers', 'validateInterviewAnswers']),

    getComponentKey (key) {
      if (this.componentKeys[key]) {
        return this.componentKeys[key]
      } else {
        this.componentKeys[key] = `${key}+0`
        return this.componentKeys[key]
      }
    },

    incrementComponentKey (key) {
      if (this.componentKeys[key]) {
        const componentKey = this.componentKeys[key]
        const keyNumber = /\d+$/g.exec(componentKey)
        if (keyNumber) {
          this.componentKeys[key] = `${key}+${parseInt(keyNumber[0]) + 1}`
        }
      } else {
        this.componentKeys[key] = `${key}+0`
      }
    },

    onAnnualIncomeEntered () {
      this.normalizeAnnualIncome()

      this.$delete(this.errors, QK.ANNUAL_INCOME)
      this.$delete(this.errors, QK.ANNUAL_HOUSEHOLD_INCOME)
    },

    onPreviousIncomeEntered () {
      this.normalizeAnnualIncome()

      this.$delete(this.errors, QK.PREVIOUS_ANNUAL_INCOME)
      this.$delete(this.errors, QK.ANNUAL_HOUSEHOLD_INCOME)
    },

    onHouseholdIncomeEntered () {
      this.normalizeAnnualIncome()

      this.$delete(this.errors, QK.ANNUAL_INCOME)
      this.$delete(this.errors, QK.PREVIOUS_ANNUAL_INCOME)
      this.$delete(this.errors, QK.ANNUAL_HOUSEHOLD_INCOME)
    },

    onUnemployedReasonsChanged (newVal, oldVal) {
      if (oldVal) {
        if (oldVal.indexOf('UNEMPLOYED_LESS_THAN_ONE_YEAR') !== -1 && newVal.indexOf('UNEMPLOYED_MORE_THAN_ONE_YEAR') !== -1) {
          newVal.splice(newVal.indexOf('UNEMPLOYED_LESS_THAN_ONE_YEAR'), 1)
        } else if (oldVal.indexOf('UNEMPLOYED_MORE_THAN_ONE_YEAR') !== -1 && newVal.indexOf('UNEMPLOYED_LESS_THAN_ONE_YEAR') !== -1) {
          newVal.splice(newVal.indexOf('UNEMPLOYED_MORE_THAN_ONE_YEAR'), 1)
        }
      }
      this.$set(this.values, QK.NOT_CURRENTLY_WORKING_REASON, newVal)
      this.askForPreviousIncome = this.isRecentlyUnemployed
      if (this.isRecentlyUnemployed) {
        this.askForHouseholdIncome = true
        this.$set(this.values, QK.ANNUAL_HOUSEHOLD_INCOME, undefined)
      } else {
        this.askForHouseholdIncome = true
        this.$set(this.values, QK.PREVIOUS_OCCUPATION, undefined)
        this.$set(this.values, QK.PREVIOUS_ANNUAL_INCOME, undefined)
      }
    },

    onEmploymentFlagChanged (val) {
      const boolVal = this.parseBool(val)
      this.askForHouseholdIncome = !boolVal
      this.askForAnnualIncome = false
      this.askForPreviousIncome = false
      this.$set(this.values, QK.ANNUAL_INCOME, undefined)
      this.$set(this.values, QK.ANNUAL_HOUSEHOLD_INCOME, undefined)
      this.$set(this.values, QK.PREVIOUS_ANNUAL_INCOME, undefined)
      this.$set(this.values, QK.NOT_CURRENTLY_WORKING_REASON, [])
      this.nonWorkingReasons = []
      this.$set(this.values, QK.PREVIOUS_OCCUPATION, undefined)
      this.$set(this.values, QK.OCCUPATION, undefined)
      if (this.askForAnnualIncome === true) this.incrementComponentKey(QK.ANNUAL_INCOME)
      if (this.askForHouseholdIncome === true) this.incrementComponentKey(QK.ANNUAL_HOUSEHOLD_INCOME)

      this.$refs.incomeForm.reset()
      this.normalizeAnnualIncome()
    },

    isIncomeValid () {
      if (this.isCurrentlyWorking) {
        if (!this.values[QK.ANNUAL_INCOME] && !this.values[QK.ANNUAL_HOUSEHOLD_INCOME]) {
          return false
        }
      } else {
        if (this.isRecentlyUnemployed) {
          if (!this.values[QK.PREVIOUS_ANNUAL_INCOME] && !this.values[QK.ANNUAL_HOUSEHOLD_INCOME]) {
            return false
          }
        } else {
          if (!this.values[QK.ANNUAL_HOUSEHOLD_INCOME]) {
            return false
          }
        }
      }

      return true
    },

    isIncomeKey (key) {
      // so we don't allow certain answers to NOT_CURRENTLY_WORKING_REASON to unset requireHouseholdIncome
      return [QK.ANNUAL_INCOME, QK.PREVIOUS_ANNUAL_INCOME].indexOf(key) !== -1
    },

    onResult ({ errors }) {
      if (!errors) {
        return
      }
      Object.keys(errors).forEach((k) => {
        this.$set(this.errors, k, errors[k])
      })
    },

    normalizeAnnualIncome () {
      // we need to set this field to "" instead of [] in order for the BE to validate correctly
      this.values[QK.ANNUAL_HOUSEHOLD_INCOME] = this.values[QK.ANNUAL_HOUSEHOLD_INCOME] ?? ''
    }
  },

  async created () {
    this.isLoading = true
    this.values = this.answersForKeys(this.questionKeys) || {}
    // load the interview questions
    const questions = await this.interviewQuestions({
      id: this.interviewID,
      keys: this.questionKeys
    })
    // determine which income capture fields are active by default
    const householdIncomeQuestion = questions.filter((q) => q.key === QK.ANNUAL_HOUSEHOLD_INCOME)[0]
    this.askForHouseholdIncome = householdIncomeQuestion.active
    // we directly manipulate / watch this value so we set the value at the end again and use a local copy
    this.nonWorkingReasons = this.values[QK.NOT_CURRENTLY_WORKING_REASON]

    this.askForPreviousIncome = this.isRecentlyUnemployed
    this.askForAnnualIncome = this.isCurrentlyWorking

    this.normalizeAnnualIncome()
    this.isIncomeValid()
    this.isLoading = false
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/metrics';

.income__dollar-entries {
  display: flex;
  flex-direction: column;

  &-first {
    margin-bottom: $mq-vertical-margin-m;
    @include md {
      margin-right: $mq-horizontal-margin-d;
      margin-bottom: unset;
    }
  }

  @include md {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  * {
    width: auto;
  }
}

.income__footnote {
  font-size: 0.8rem;

  p:not(:first-of-type) {
    margin-top: 1rem;
  }
}
</style>
