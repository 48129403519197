/**
 * `v-kesAllowed` directive
 *
 * This works by blocking keys during onKeyDown by using a whitelist regex and
 * testing `event.key`
 *
 * What this does NOT do: Validate text structure after input. This only tests
 * one character at a time before input
 */

/**
 * these keys should be allowed in all cases because they handle traversing and a11y
 */
const baseAllowedRegex = new RegExp('Backspace|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Tab|Delete')

/**
 * allow only numbers
 */
const numbersRegex = new RegExp(`[0-9]`)

/**
 * Similar to numbers but also allows commas, not including any currency symbols
 * because we handle that via input prefix
 */
const currencyRegex = new RegExp(`[0-9,]`)

/**
 * Regex to be used for any part of a person's full name with full support for
 * international language character
 *
 * u flag for 'unicode'
 * https://www.regular-expressions.info/unicode.html
 *
 * \p{L} - any kind of letter from any language. case insensitive
 *
 * \p{Zs} - any unicode space character that takes up visible space
 *
 * \p{Pd} - any kind of hyphen or dash.
 *
 * periods - for things like:  Jr. Sr.
 *
 * apostrophe (aka single quote) - for names like O'Malley
 *
 * This should cover _most_ names in the US, Europe, and probably the world
 *
 */
let namesRegex
try {
  namesRegex = new RegExp(`[\\p{L}\\p{Zs}\\p{Pd}\\.\\\\']`, 'u')
} catch (e) {
  // FireFox, and older browsers, do not support Unicode Property Escapee
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
  // it will be available in FF 78, currently we're at 76: https://bugzilla.mozilla.org/show_bug.cgi?id=1361876
  // so if it's not supported we fallback to Latin Extended
  namesRegex = new RegExp(`[a-z\u00C0-\u024F\u1E00-\u1EFF\\s-']`, 'i')
}

/**
 * Just letters from any language, like in Middle initials
 */
let lettersRegex
try {
  lettersRegex = new RegExp('\\p{L}', 'u')
} catch (e) {
  lettersRegex = new RegExp(`[a-z\u00C0-\u024F\u1E00-\u1EFF]`, 'i')
}

function onKeyDown (re) {
  return function handler (e) {
    if (e.metaKey) {
      // allow shortcuts like Ctrl/Command + A
      return true
    }

    if (re.test(e.key) || baseAllowedRegex.test(e.key)) {
      return true
    }

    e.preventDefault()
    return false
  }
}

export const AllowedTypes = {
  CURRENCY: 'currency',
  NAMES: 'names',
  NUMBERS: 'numbers',
  LETTERS: 'letters'
}

/**
 * Directive that whitelists keys allowed to be typed inside an input and
 * prevents anything else from being typed
 *
 * <Component v-keysAllowed="AllowedTypes.CURRENCY" />
 */

export default {
  bind (el, binding, vnode) {
    switch (binding.value) {
      case AllowedTypes.CURRENCY:
        self.handler = onKeyDown(currencyRegex)
        break
      case AllowedTypes.NAMES:
        self.handler = onKeyDown(namesRegex)
        break
      case AllowedTypes.NUMBERS:
        self.handler = onKeyDown(numbersRegex)
        break
      case AllowedTypes.LETTERS:
        self.handler = onKeyDown(lettersRegex)
        break
      default:
        self.handler = null
    }

    if (self.handler) {
      el.addEventListener('keydown', self.handler)
    }
  },

  unbind (el, binding, vnode) {
    if (self.handler) {
      el.removeEventListener('keydown', self.handler)
    }
  }
}
