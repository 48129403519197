import AccountAPI from '@/api/Account'
import Result from '@/api/Result'

const accountModule = {
  namespaced: true,

  state: {
    account: null,
    interviews: []
  },

  getters: {
    cachedInterviews: (state) => {
      return state.interviews
    },
    currentAccount: (state) => {
      return state.account
    }
  },

  actions: {
    updateAccount ({ commit }, account) {
      commit('UPDATE_ACCOUNT', account)
    },
    async createUnverifiedAccount ({ commit, rootState }, payload) {
      const { email } = payload
      let res
      if (email !== undefined && email !== null) {
        try {
          res = await rootState.apolloClient.mutate({
            ...AccountAPI.create(null, email)
          })
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          this.error = e
          throw e
        }
        if (res && res.data) {
          const account = res.data.createAccount
          return account
        }
      }
      return null
    },
    async sendAdminMagicLink ({ commit, rootState }, payload) {
      const { email } = payload
      if (email === undefined || email === null) {
        const e = new Error(`invalid email parameter in sendMagicLink payload`)
        // eslint-disable-next-line no-console
        console.error(e)
        this.error = e
        throw e
      }
      try {
        let rsp = await rootState.apolloClient.mutate({
          ...AccountAPI.SendAdminMagicLink(email)
        })
        let res = Result.New(rsp?.data?.sendAdminMagicLink)
        return res
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        this.error = e
        throw e
      }
    },
    async getAccount ({ commit, state, rootState }) {
      if (state.account) {
        return state.account
      }

      const accessToken = rootState.auth.accessToken

      if (accessToken === null) {
        return null
      }

      let res
      try {
        res = await rootState.apolloClient.mutate({
          ...AccountAPI.resolve(accessToken, null)
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        this.error = e
        return null
      }
      if (res && res.data) {
        const account = res.data.resolveAccount
        commit('UPDATE_ACCOUNT', account)
        return account
      }
      return null
    },

    async accountInfo ({ rootState }, payload) {
      const { email } = payload
      if (email) {
        try {
          const res = await rootState.apolloClient.query({
            ...AccountAPI.accountInfo(email),
            fetchPolicy: 'no-cache'
          })
          if (res && res?.data) {
            return res?.data?.accountInfo?.exists
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
          return false
        }
      }
      return false
    },

    async getAccountWithEmail ({ rootState }, payload) {
      const { email } = payload
      if (!email) {
        const e = new Error(`No email given`)
        // eslint-disable-next-line no-console
        console.error(e)
        this.error = e
        throw e
      }

      let res
      try {
        res = await rootState.apolloClient.query({
          ...AccountAPI.get(null, null, email)
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        this.error = e
        return null
      }
      if (res && res.data) {
        const account = res.data.account
        return account
      }
      return null
    },

    async getInterviews ({ commit, dispatch, rootState }) {
      await dispatch('getAccount')
      const result = await rootState.apolloClient.query({
        ...AccountAPI.interviews(),
        fetchPolicy: 'no-cache'
      })

      const data = result.data
      if (data && data.accountInterviews) {
        const interviews = data.accountInterviews.filter(e => e.status !== 'INACTIVE')
        commit('UPDATE_INTERVIEWS', interviews)
        return interviews
      } else {
        return undefined
      }
    },

    onSignOut ({ commit }) {
      commit('CLEAR_STATE')
    }
  },

  mutations: {
    UPDATE_INTERVIEWS (state, interviews) {
      state.interviews = interviews
    },

    UPDATE_ACCOUNT (state, account) {
      state.account = account
    },
    ACCOUNT_VERIFICATION_STATUS (state, val) {
      state.accountVerificationStatus = val
    },
    CLEAR_STATE (state) {
      state.interviews = undefined
      state.account = null
      state.imo = undefined
    }
  }
}

export default accountModule
