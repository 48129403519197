<template>
  <div>
    <div class="interview-quote-customizable mq-container">
      <header class="df-header df-header__p-top">
        <MainNavigationHeader />
        <div class="df-header-text">Personalize your policy.</div>
        <p>Choose from the coverage below or <a @click.stop="customizeQuote">customize a policy</a>.</p>
      </header>
      <div class="interview-quote-customizable__loader" v-if="isLoading">
        <DotsSpinner class="tm-3r bm-1r" />
      </div>
      <div class="interview-quote-customizable__quote-cards" v-else>
        <InterviewQuoteCard
          v-for="(item, index) in quotes"
          :quote-result="item"
          :eyebrow="eyebrows[index]"
          :key="index"
          :index="index"
        />
      </div>
      <div class="interview-quote-customizable__controls">
        <a rel="noopener" href="#" @click.prevent="showModal('checkout')"> Am I really approved for this policy? </a>
        <div class="interview-quote-customizable__button-container">
          <h3 class="interview-quote-customizable__secondary-cta">Or, customize by monthly cost:</h3>
          <DFButton class="interview-quote-customizable__btn" @click.stop="customizeQuote"
            >Name Your Own Price
          </DFButton>
        </div>
        <template>
          <CheckoutNowModal :show="isShowingModal('checkout')" @close="hideModal('checkout')" />
        </template>
      </div>
    </div>
    <MainFooter />
  </div>
</template>

<script>
import InterviewQuoteCard from '@/views/interview/InterviewQuoteCard.vue'
import DFButton from '@/components/DFButton.vue'
import MainFooter from '@/components/navigation/MainFooter'
import MainNavigationHeader from '@/components/navigation/MainNavigationHeader'
import DotsSpinner from '@/components/DotsSpinner'
import { gotoRedirect } from '@/utils/router'
import { mapGetters, mapActions } from 'vuex'
import CheckoutNowModal from '@/components/modals/CheckoutNowModal.vue'
import { RouteName } from '@/router/routes/constants'
import { InteractionType } from '@/api/Interview/constants'

export default {
  name: 'interview-quote-customizable',
  components: {
    InterviewQuoteCard,
    DFButton,
    MainFooter,
    MainNavigationHeader,
    CheckoutNowModal,
    DotsSpinner
  },
  data() {
    return {
      isLoading: true,
      eyebrows: ['standard', 'popular', 'more coverage'],
      quote: undefined,
      quotes: undefined,
      visibleModalKey: undefined
    }
  },
  computed: {
    ...mapGetters('interview', ['interviewID'])
  },
  methods: {
    ...mapActions('interview', ['getQuote', 'recordInteraction']),
    customizeQuote() {
      this.$router.push({ name: RouteName.CUSTOMIZE_QUOTE })
    },
    gotoRedirect,
    isShowingModal(key) {
      return this.visibleModalKey === key
    },
    showModal(key) {
      this.visibleModalKey = key
    },
    hideModal(key) {
      if (this.visibleModalKey === key) {
        this.visibleModalKey = undefined
      }
    },
    async recordReceivedQuote() {
      try {
        await this.recordInteraction({
          interviewID: this.interviewID,
          actionType: InteractionType.RECEIVED_FINALIZED_QUOTE
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    },
    trackQuote() {
      const properties = {
        type: 'final',
        benefit_amount: this.quote.quote.benefitAmount,
        premium: this.quote.quote.premium,
        length: this.quote.termMonths
      }
      this.$analytics.idempotentTrack({
        key: this.interviewID,
        event: 'app_firstQuote',
        properties
      })
      this.$analytics.track({
        event: 'app_getsQuote',
        properties
      })
    }
  },
  async mounted() {
    await this.recordReceivedQuote()
    this.trackQuote()
  },
  async beforeMount() {
    this.isLoading = true
    this.quote = await this.getQuote({ id: this.interviewID, spread: true })
    const { quoteMedium, quoteLow } = this.quote
    this.quotes = [quoteLow, quoteMedium]
    this.isLoading = false
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/titles';
@import 'src/assets/styles/media-queries';

.interview-quote-customizable {
  &__loader {
    min-height: 500px;
  }
  &__button-container {
    text-align: center;
  }
  &__quote-cards {
    margin-top: 2rem;
    display: grid;
    grid-row-gap: 2rem;
    grid-template-columns: 1fr;
    @include md {
      margin-top: 0;
      grid-template-rows: 1fr 1fr 1fr;
      width: 80%;
    }
    @include lg {
      margin-top: 4rem;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 2rem;
      width: auto;
    }
  }

  &__secondary-cta {
    margin-top: 2rem;
    text-align: center;
  }

  &__btn {
    margin-top: 2rem;
    width: auto;
    justify-self: center;
  }
}

.df-header-text {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}
a {
  margin-top: 1rem;
}
</style>
