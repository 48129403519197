<template>
  <div class="interview-health-conditions">
    <FlowWrapper
      :progress="7/8"
      :can-move-to-next="hasValidFormData"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      :on-next="goNext"
      :before-next="beforeNext"
      @result="onSaveResult"
    >
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          Your health conditions.
        </div>
      </template>
      <template v-slot:content>
        <div>
          What conditions have you been treated for, or diagnosed by a physician with, in the
          <b>last ten years</b>?
        </div>
        <v-form>
          <div class="fields mq-grid">
            <div class="cols-10">
              <Question
                v-model="values[QK.HEALTH_CONDITIONS]"
                :question="questionsMap[QK.HEALTH_CONDITIONS]"
                :error="errors[QK.HEALTH_CONDITIONS]"
                @input="onConditionChecked"
                hide-details
                hide-label
              />
            </div>
            <div class="cols-10">
              <Question
                v-model="values[QK.HEALTH_CONDITIONS_ACKNOWLEDGED]"
                :question="questionsMap[QK.HEALTH_CONDITIONS_ACKNOWLEDGED]"
                :error="errors[QK.HEALTH_CONDITIONS_ACKNOWLEDGED]"
                type="ack"
                :text="acknowledgement"
              />
            </div>
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'
import { InterviewFlagCause, QuestionKey as QK, QuestionCategory, DiagnosisCategories } from '@/api/Interview'
import { RouteName } from '@/router/routes/constants.js'

export default {
  name: 'InterviewHealthConditions',
  components: { FlowWrapper, Question },

  data () {
    return {
      STEP_NAME: 'health',
      QK: QK,
      ORF: InterviewFlagCause,
      values: {},
      errors: {},
      followupsActivated: []
    }
  },

  computed: {
    ...mapGetters('interview',
      [
        'answers',
        'answersForKeys',
        'questionsMap',
        'interviewID',
        'interview',
        'mapToAnswers',
        'parseBool',
        'hasIdentityFollowup',
        'hasHealthConditions'
      ]),

    hasValidFormData () {
      return this.parseBool(this.values[QK.HEALTH_CONDITIONS_ACKNOWLEDGED])
    },

    acknowledgement () {
      return this.values[QK.HEALTH_CONDITIONS].indexOf('') !== -1 ? 'None of the above conditions apply to me'
        : 'No other conditions apply to me'
    },

    questionKeys () {
      return [
        QK.HEALTH_CONDITIONS,
        QK.HEALTH_CONDITIONS_ACKNOWLEDGED
      ]
    },

    answerValues () {
      return this.values
    }
  },

  methods: {
    ...mapActions('interview', [
      'getStatus',
      'updateInterviewAnswers',
      'setAllHealthFollowups',
      'checkFollowupSectionsActivated'
    ]),

    async beforeNext () {
      const result = await this.checkFollowupSectionsActivated({
        id: this.interviewID
      })

      return result
    },

    async onSaveResult (res) {
      const reflexives = res?.data?.putInterviewAnswers?.find(item => item.questionKey === this.QK.HEALTH_CONDITIONS)?.reflexives
      if (reflexives) {
        reflexives.forEach(r => {
          if (r.category === QuestionCategory.HEALTH_FOLLOWUP && r.isSatisfied === true) {
            const followup = DiagnosisCategories[r.targetKey]
            this.followupsActivated.push(followup)
          }
        })
        await this.setAllHealthFollowups({ allHealthFollowups: this.followupsActivated })
      }
    },
    async goNext () {
      await this.setAllHealthFollowups({ allHealthFollowups: this.followupsActivated })

      if (this.hasHealthConditions) {
        await this.$router.push({
          name: RouteName.FINALIZE_HEALTH_FOLLOWUP,
          params: { index: 0 }
        })
        return { navigate: false }
      } else {
        return { navigate: true }
      }
    },
    onConditionChecked () {
      this.values[QK.HEALTH_CONDITIONS_ACKNOWLEDGED] = 'false'
    }

  },

  created () {
    this.values = this.answersForKeys(this.questionKeys) || {}
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/media-queries";
@import "src/assets/styles/metrics";
@import "src/assets/styles/transitions";

.fields {
  row-gap: 15px;
}

.df-title {
  font-size: 2.2em;
  margin-bottom: 2.5rem;
}
</style>
