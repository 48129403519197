<template>
  <div class="interview-basic-info">
    <FlowWrapper :can-move-to-next="hasValidFormData">
      <template v-slot:content>
        <div>
          <div v-if="isLoading" class="submission-loader__loading">
            <!--          <div class="submission-loader-contents">-->
            <!--            <div class="submission-loader-progress">-->
            <!--              <v-progress-linear indeterminate height="2" />-->
            <!--            </div>-->
            <!--            <div>Processing application...</div>-->
            <!--          </div>-->
          </div>
          <div v-else-if="error !== null" class="interview__error">
            {{ error }}
          </div>
          <div v-else-if="needsReview" class="in-review">Your application requires manual review.</div>
        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import { InterviewStatus } from '@/api/Interview'
import { pollAsync } from '@/utils/async'
import { RouteName } from '@/router/routes/constants.js'
import { gotoRedirect } from '@/utils/router'

const pollTimeout = 30 * 1000 // milliseconds
const pollInterval = 1 * 1000 // milliseconds

export default {
  name: 'InterviewSubmission',
  components: { FlowWrapper },

  data() {
    return {
      isLoading: true,
      needsReview: false,
      statusResult: null,
      error: null
    }
  },

  computed: {
    ...mapGetters('interview', ['interviewID', 'interview']),

    title() {
      return this.isLoading ? '' : 'Finalizing Application'
    },

    hasValidFormData() {
      return true
    }
  },

  methods: {
    ...mapActions('interview', ['getStatus']),
    gotoRedirect,
    doPrevious() {
      return true
    },

    processStatusResult() {
      const status = this.statusResult.status
      let route
      switch (status) {
        case InterviewStatus.READY_TO_SIGN:
        case InterviewStatus.READY_TO_SUBMIT:
        case InterviewStatus.AWAITING_SIGNATURE_COMPLETION:
        case InterviewStatus.AWAITING_SIGNATURE_CONFIRMATION:
          route = RouteName.CHECKOUT_SIGN
          break
        case InterviewStatus.REJECTED:
          route = RouteName.CHECKOUT_SIGN
          break
        case InterviewStatus.INELIGIBLE:
          route = RouteName.OR_INELIGIBLE
          break
        case InterviewStatus.NEEDS_REVIEW:
          route = RouteName.INTERVIEW_REVIEW_PENDING
          break
        case InterviewStatus.INCOMPLETE:
          throw new Error('interview was submitted but it is still incomplete')
        default:
          throw new Error('interview was in an invalid status and could not be submitted')
      }
      this.$router.replace({ name: route })
    }
  },

  created() {
    if (!this.interview) {
      this.$router.replace({ name: 'me' })
    }

    pollAsync(
      async () => {
        this.statusResult = await this.getStatus({ id: this.interviewID })
        return this.statusResult
      },
      pollTimeout,
      pollInterval
    )
      .then(() => {
        this.isLoading = false
        this.processStatusResult()
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        this.isLoading = false
        this.processStatusResult()
      })
  }
}
</script>

<style scoped lang="scss">
.submission-loader {
  display: grid;
  align-items: center;
  justify-items: center;
  margin-top: 100px;
}

.submission-loader-contents {
  display: grid;
  justify-content: center;
  justify-items: center;
  grid-auto-flow: row;
  font-size: 0.75em;
  font-weight: 600;
  color: #444;
}

.submission-loader-progress {
  width: 200px;
  margin-bottom: 20px;
}

.in-review {
  text-align: center;
}
</style>
