import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import theme from '@/config/theme.config'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: theme.colors.background,
        primary: theme.colors.primary,
        error: theme.colors.error
      }
    },
    options: {
      customProperties: true
    }
  }
})
