import PolicyAPI from '@/api/Policy'

const policyModule = {
  namespaced: true,

  state: {
    policy: null
  },

  getters: {
    currentPolicy: (state) => state.policy
  },

  actions: {
    async getPolicy ({ commit, rootState }, id) {
      let res
      try {
        res = await rootState.apolloClient.query({
          ...PolicyAPI.getPolicy(id),
          fetchPolicy: 'no-cache'
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      if (res && res.data) {
        const policy = res.data.policy
        commit('SET_POLICY', policy)
        return policy
      }
      return null
    },

    async getPolicyByInterviewID ({ commit, rootState }, interviewID) {
      const res = await rootState.apolloClient.query({
        ...PolicyAPI.getPolicyByInterviewID(interviewID),
        fetchPolicy: 'no-cache'
      })
      if (res && res.data && res.data.policies.length === 1) {
        const policy = res.data.policies[0]
        commit('SET_POLICY', policy)
        return policy
      }
      return null
    },

    async getAccountPolicies ({ rootState }, id) {
      const res = await rootState.apolloClient.query({
        ...PolicyAPI.getAccountPolicies(id)
      })
      if (res && res.data) {
        return res.data.policies
      }
      return null
    },

    async getPolicyDownloadURL ({ rootState }, id) {
      const res = await rootState.apolloClient.query({
        ...PolicyAPI.policyDownloadURL(id),
        fetchPolicy: 'no-cache'
      })
      if (res && res.data) {
        return res.data.policyDownloadURL
      }
      return null
    }
  },

  mutations: {
    SET_POLICY (state, policy) {
      state.policy = policy
    },

    SET_ERROR (state, error) {
      state.error = error
    }
  }
}

export default policyModule
