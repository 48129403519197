<template>
  <div class="interview-attestations">
     <FlowWrapper
       :progress="8/8"
       :can-move-to-next="hasValidFormData"
       :question-keys="activeKeys"
       :answer-values="answerValues"
       :on-next='onNext'
    >
      <template v-slot:content>
        <v-form v-if="!isLoading">
          <div class="mq-grid">
            <div class="cols-10 df-input-label attestation-label">Your doctor</div>
            <Question
              v-model="values[QK.HAS_PRIMARY_CARE_PHYSICIAN]"
              :question="questions[QK.HAS_PRIMARY_CARE_PHYSICIAN]"
              :error="errors[QK.HAS_PRIMARY_CARE_PHYSICIAN]"
              class="cols-10 mq-neg-margin-top"
              :input-width="smElse('100%', '250px')"
            />

            <template v-if="hasPCP">
              <Question
                v-model="values[QK.PCP_NAME]"
                :question="questions[QK.PCP_NAME]"
                :error="errors[QK.PCP_NAME]"
                class="cols-10 cols-md-5 mq-neg-margin-top"
              />
              <div class="cols-5" v-if="$gtSm"></div>
              <Question
                v-model="values[QK.PCP_CITY]"
                :question="questions[QK.PCP_CITY]"
                :error="errors[QK.PCP_CITY]"
                class="cols-8 cols-md-4"
              />
              <Question
                v-model="values[QK.PCP_STATE]"
                :question="questions[QK.PCP_STATE]"
                :error="errors[QK.PCP_STATE]"
                class="cols-2 cols-md-1"
              />
              <div class="cols-5" v-if="$gtSm"></div>
            </template>

            <div class="cols-10 df-input-label attestation-label">
              {{ questions[QK.HAS_FELONY_OR_USED_ILLEGAL_DRUGS].label }}
            </div>
            <Question
              v-model="values[QK.HAS_FELONY_OR_USED_ILLEGAL_DRUGS]"
              :question="questions[QK.HAS_FELONY_OR_USED_ILLEGAL_DRUGS]"
              :error="errors[QK.HAS_FELONY_OR_USED_ILLEGAL_DRUGS]"
              class="cols-10 mq-neg-margin-top"
              :input-width="smElse('100%', '250px')"
            />

            <div class="cols-10 df-input-label attestation-label">
              {{ questions[QK.HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY].label }}
            </div>
            <Question
              v-model="values[QK.HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY]"
              :question="questions[QK.HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY]"
              :error="errors[QK.HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY]"
              class="cols-10 mq-neg-margin-top"
              :input-width="smElse('100%', '250px')"
            />

            <div class="cols-10 df-input-label attestation-label">
              {{ questions[QK.HAS_AIDS_ARC_HIV_DIAGNOSIS].label }}
            </div>
            <Question
              v-model="values[QK.HAS_AIDS_ARC_HIV_DIAGNOSIS]"
              :question="questions[QK.HAS_AIDS_ARC_HIV_DIAGNOSIS]"
              :error="errors[QK.HAS_AIDS_ARC_HIV_DIAGNOSIS]"
              class="cols-10 mq-neg-margin-top"
              :input-width="smElse('100%', '250px')"
            />

            <div class="cols-10 df-input-label attestation-label">
              {{ questions[QK.HAS_BEEN_DECLINED].label }}
            </div>
            <Question
              v-model="values[QK.HAS_BEEN_DECLINED]"
              :question="questions[QK.HAS_BEEN_DECLINED]"
              :error="errors[QK.HAS_BEEN_DECLINED]"
              class="cols-10 mq-neg-margin-top"
              :input-width="smElse('100%', '250px')"
            />

            <div class="cols-10 df-input-label attestation-label">
              {{ questions[QK.HAS_BEEN_INDUCED].label }}
            </div>
            <Question
              v-model="values[QK.HAS_BEEN_INDUCED]"
              :question="questions[QK.HAS_BEEN_INDUCED]"
              :error="errors[QK.HAS_BEEN_INDUCED]"
              class="cols-10 mq-neg-margin-top"
              :input-width="smElse('100%', '250px')"
            />

            <div class="cols-10 df-input-label attestation-label">
              {{ questions[QK.HAS_EXISTING_COVERAGE].label }}
            </div>
            <Question
              v-model="values[QK.HAS_EXISTING_COVERAGE]"
              :question="questions[QK.HAS_EXISTING_COVERAGE]"
              :error="errors[QK.HAS_EXISTING_COVERAGE]"
              class="cols-10 mq-neg-margin-top"
              :input-width="smElse('100%', '250px')"
            />

            <template v-if="hasExistingCoverage">
              <Question
                v-model="values[QK.EXISTING_COVERAGE_AMOUNT]"
                :question="questions[QK.EXISTING_COVERAGE_AMOUNT]"
                :error="errors[QK.EXISTING_COVERAGE_AMOUNT]"
                prefix="$"
                class="cols-10"
                :input-width="smElse('100%', '250px')"
              />
              <Question
                v-model="values[QK.IS_REPLACEMENT_POLICY]"
                :question="questions[QK.IS_REPLACEMENT_POLICY]"
                :error="errors[QK.IS_REPLACEMENT_POLICY]"
                class="cols-10"
                :input-width="smElse('100%', '250px')"
              />
            </template>

            <template v-if="showMilitaryDisclosure">
              <v-checkbox
                class="cols-10"
                v-model="acknowledgedDisclosure"
                @change="onChanged"
              >
                <template v-slot:label>
                  <div>
                    I acknowledge that I have received and reviewed the
                    <a href="#" @click.prevent="showModal($event)">Military Sales Disclosure</a>
                  </div>
                </template>
              </v-checkbox>
            </template>

          </div>

          <Modal
            title="Military Disclosure"
            :showActions="false"
            v-model="showDisclosureModal"
            @close="showDisclosureModal = false"
            :show-print-link="true"
            :pdf-url="url"
          >
            <template v-slot:content>
              <MilitaryDisclosure />
            </template>
          </Modal>

        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'
import { QuestionKey as QK, InterviewFlagCause as ORF, InterviewStatus, InterviewFlagType } from '@/api/Interview'
import Modal from '@/components/Modal'
import { MilitaryDisclosure } from '@/components/modals'
import { RouteName } from '@/router/routes/constants'

export default {
  name: 'InterviewAttestations',
  components: { FlowWrapper, Question, Modal, MilitaryDisclosure },

  data () {
    return {
      STEP_NAME: 'attestations',
      QK,
      ORF,
      error: null,
      questions: {},
      values: {},
      errors: {},
      isLoading: true,
      showDisclosureModal: false,
      acknowledgedDisclosure: false,
      url: ''
    }
  },

  computed: {
    ...mapGetters('interview', ['interviewID', 'interview', 'mapToAnswers', 'parseBool', 'validateKeys', 'hasIdentityFollowup']),
    questionKeys () {
      return [
        QK.HAS_PRIMARY_CARE_PHYSICIAN,
        QK.PCP_NAME,
        QK.PCP_CITY,
        QK.PCP_STATE,
        QK.HAS_FELONY_OR_USED_ILLEGAL_DRUGS,
        QK.HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY,
        QK.HAS_AIDS_ARC_HIV_DIAGNOSIS,
        QK.HAS_BEEN_INDUCED,
        QK.HAS_BEEN_DECLINED,
        QK.HAS_EXISTING_COVERAGE,
        QK.EXISTING_COVERAGE_AMOUNT,
        QK.IS_REPLACEMENT_POLICY,
        QK.HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE
      ]
    },

    showMilitaryDisclosure () {
      const question = this.questions[QK.HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE]
      if (question !== undefined) {
        return question.active
      }
      return false
    },

    answerValues () {
      return this.values
    },

    hasPCP () {
      return this.parseBool(this.values[QK.HAS_PRIMARY_CARE_PHYSICIAN])
    },

    hasExistingCoverage () {
      return this.parseBool(this.values[QK.HAS_EXISTING_COVERAGE])
    },

    activeKeys () {
      let activeKeys = [
        QK.HAS_PRIMARY_CARE_PHYSICIAN,
        QK.HAS_FELONY_OR_USED_ILLEGAL_DRUGS,
        QK.HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY,
        QK.HAS_AIDS_ARC_HIV_DIAGNOSIS,
        QK.HAS_BEEN_INDUCED,
        QK.HAS_BEEN_DECLINED,
        QK.HAS_EXISTING_COVERAGE
      ]

      if (this.hasPCP) {
        activeKeys = activeKeys.concat(QK.PCP_NAME, QK.PCP_CITY, QK.PCP_STATE)
      }

      if (this.hasExistingCoverage) {
        activeKeys = activeKeys.concat(QK.EXISTING_COVERAGE_AMOUNT, QK.IS_REPLACEMENT_POLICY)
      }

      if (this.showMilitaryDisclosure) {
        activeKeys = activeKeys.concat(QK.HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE)
      }

      return activeKeys
    },

    hasValidFormData () {
      if (this.showMilitaryDisclosure && !this.acknowledgedDisclosure) {
        return false
      }
      return this.validateKeys(this.activeKeys, this.values)
    }
  },

  methods: {
    ...mapActions('interview', ['interviewQuestionAnswerMaps', 'updateInterviewAnswers', 'getStatus']),
    ...mapActions('content', ['fetchContentfulAsset']),

    showModal (e) {
      e.stopPropagation()
      this.showDisclosureModal = true
    },

    onChanged (v) {
      this.acknowledgedDisclosure = v
      this.$set(this.values, QK.HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE, v.toString())
    },

    async save () {
      const result = await this.updateInterviewAnswers({
        id: this.interviewID,
        answers: this.mapToAnswers(this.values, this.questionKeys)
      })

      if (result.errors) {
        this.errors = result.errors
        return false
      }

      return true
    },

    async fetchExistingInterviewAnswers () {
      const { questions, answers } = await this.interviewQuestionAnswerMaps({
        id: this.interviewID,
        keys: this.questionKeys
      })

      this.questions = questions
      this.values = answers
      this.acknowledgedDisclosure = this.parseBool(this.values[QK.HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE]) || false
      this.isLoading = false
    },

    async onNext () {
      const statusResult = await this.getStatus({ id: this.interviewID })
      const ineligibleFlags = statusResult?.flags.filter((f) => f.type === InterviewFlagType.INELIGIBLE)
      const isIneligible = statusResult?.status === InterviewStatus.INELIGIBLE || (ineligibleFlags && ineligibleFlags.length > 0)
      if (isIneligible) {
        await this.$router.push({ name: RouteName.OR_INELIGIBLE })
      } else if (this.hasIdentityFollowup) {
        await this.$router.push({ name: RouteName.INTERVIEW_CONFIRM_INFO_PREQUOTE })
        return { navigate: false }
      } else {
        await this.$router.push({ name: RouteName.INTERVIEW_FINAL_QUOTE })
        return { navigate: false }
      }
    }
  },

  async created () {
    if (this.interview) {
      await this.fetchExistingInterviewAnswers()
      if (this.showMilitaryDisclosure) {
        const url = await this.fetchContentfulAsset({
          id: '1qnIF6l21c0IeG7GYkS4Y7'
        })
        if (url) {
          this.url = 'http:' + url
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/global';
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/transitions';

.attestation-label {
  padding-bottom: 6px;
  font-weight: 600;
}
</style>
