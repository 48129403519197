import gql from 'graphql-tag'

export default {
  getOrCreateBillingAccount: () => ({
    mutation: gql`
      mutation GetOrCreateBillingAccount {
        getOrCreateBillingAccount {
          id
        }
      }
    `
  }),

  attachCreditCard: (token, setDefault) => ({
    mutation: gql`
      mutation AttachCreditCard($token: String!, $setDefault: Boolean) {
        attachCreditCard(creditCardToken: $token, setDefault: $setDefault) {
          id
          last4
        }
      }
    `,
    variables: {
      token: token,
      setDefault: setDefault
    }
  }),

  detachCreditCard: (id) => ({
    mutation: gql`
      mutation DetachCreditCard($id: String!) {
        detachCreditCard(creditCardId: $id)
      }
    `,
    variables: {
      id: id
    }
  }),

  creditCards: () => ({
    query: gql`
      query {
        creditCards {
          id
          brand
          last4
          expMonth
          expYear
        }
      }
    `
  })
}
