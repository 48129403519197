export const gqlEndpointURL = process.env.VUE_APP_GRAPHQL_HTTP
export const geocoderAPIKey = process.env.VUE_APP_API_KEY_GEOCODER

// insurews-dev SPA client id: gcN5UylmtHmapmFY87TTUYa4LkkbU5TB , insurews-dev M2M: i6ItdGAF5ZjjlDeeWlueVnwJIr9tH2MP, prod SPA: iUia33exdiKCWcXWEeXfmkB78p7PidN8,
// insurews (prod) domain: 'auth.dayforward.com', insurews-dev: 'authdev.dayforward.com'
// 'link' or 'code' -- but we only support 'code' for prod and 'link' for dev currently
// 'sms' or 'email' -- but we only support 'email' currently
export const adminSendMagicLinkEnabled = (process.env.VUE_APP_ADMIN_SEND_MAGIC_LINK_ENABLED === 'true')
export const adminInterviewFlagsShowSourceField = (process.env.VUE_APP_ADMIN_FLAGS_SHOW_SOURCE !== 'false')
// session keys
export const ssKeyInterviewID = 'interviewID'
export const ssKeyContentItems = 'contentItems'
export const ssAuthTokenKey = '--df-token'

// kill override
export const ssKeyKillOverride = '--df-killOveride'

// stripe keys
export const stripePubKey = process.env.VUE_APP_STRIPE_PUB_KEY

// youtube keys
export const youtubeApiKey = process.env.VUE_APP_YOUTUBE_API_KEY

export default {
  gqlEndpointURL,
  geocoderAPIKey
}
