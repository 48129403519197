<template>
  <div class="off-ramp-ineligible">
     <FlowWrapper
      :show-buttons="false"
      :showActions="false"
      :show-progress="true"
      header-variant="red"
    >
      <template v-slot:header-text>
          We're sorry.
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          {{ title }}
        </div>
      </template>
      <template v-slot:content>
      <div class="off-ramp-ineligible__wrapper">
        <div class="off-ramp-ineligible__body">
          <template v-if="body">{{ body }}</template>
          <template v-else>
            <p>
              We're a new company and after reviewing all your answers, we aren't able to offer you
              coverage at this time. If you have questions, we're happy to chat. Please call us at 1-888-434-0228.
            </p>
          </template>
        </div>
        <div class="off-ramp-ineligible__actions">
          <DFButton @click="onHomeClick">HOME PAGE</DFButton>
        </div>
      </div>
      </template>
     </FlowWrapper>
  </div>
</template>

<script>
import DFButton from '@/components/DFButton'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import { mapActions, mapGetters } from 'vuex'
import { InteractionType } from '@/api/Interview/constants'
import { gotoRedirect } from '@/utils/router'

export default {
  name: 'off-ramp-ineligible',
  components: { FlowWrapper, DFButton },
  props: {
    title: {
      type: String,
      default: 'Thank you for your interest in Dayforward.'
    },
    body: {
      type: String
    }
  },
  methods: {
    ...mapActions('interview', ['recordInteraction']),
    ...mapGetters('interview', ['interviewID']),
    gotoRedirect,
    async onHomeClick () {
      location.href = this.gotoRedirect('')
    }
  },
  created () {
    try {
      this.recordInteraction({ interviewID: this.interviewID(), actionType: InteractionType.NOTIFIED_INELIGIBLE })
      this.$analytics.idempotentTrack({ key: this.interviewID, event: 'app_ilg' })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/media-queries";

.off-ramp-ineligible {
  min-height: 100vh;
  &__wrapper {
    min-height: 40vh;
  }
  &__actions {
    margin-top: 3rem;
  }
  &__headline {
    color: #fff;
    margin-bottom: 40px;
    font-size: 4.4em;
    font-weight: 600;
  }
}

p:not(:first-of-type) {
  margin-top: 0.8rem;
}

</style>
