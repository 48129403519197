<template>
  <div>
    <FlowWrapper
      :back-action="save"
      :next-action="save"
      :on-back="goBack"
      :on-next="goNext"
      :can-move-to-next="hasValidFormData"
      :question-keys="activeKeys"
      :answer-values="values"
    >
      <template v-slot:content-header>
        <h2 class="df-content-header-text">{{ `Your ${title}.` }}</h2>
      </template>
      <template v-slot:content>
        <v-form v-if="!isLoading">
          <div class="mq-grid">
            <div class="cols-10">
              <Question
                v-model="values[keyForConcern(DC.DIAGNOSED_WHEN)]"
                :question="questions[keyForConcern(DC.DIAGNOSED_WHEN)]"
                :error="errors[keyForConcern(DC.DIAGNOSED_WHEN)]"
                hide-details
                :approximate="true"
                :input-width="smElse('100%', '250px')"
                @input="diagnosisWhenCheck"
              />
            </div>
            <template v-if="showA1c">
              <div class="cols-10">
                <Question
                  v-model="values[keyForConcern(DC.A1C)]"
                  :question="questions[keyForConcern(DC.A1C)]"
                  :error="errors[keyForConcern(DC.A1C)]"
                  hide-details
                />
              </div>
            </template>
            <template v-if="showFamilyHistory">
              <div class="cols-10">
                <Question
                  v-model="values[keyForConcern(DC.FAMILY_HISTORY)]"
                  :question="questions[keyForConcern(DC.FAMILY_HISTORY)]"
                  :error="errors[keyForConcern(DC.FAMILY_HISTORY)]"
                  hide-details
                />
              </div>
            </template>
            <div class="cols-10">
              <Question
                v-model="values[keyForConcern(DC.TREATMENT, [DC.MEDICATION, DC.WHEN_SURGERY])]"
                :question="questions[keyForConcern(DC.TREATMENT, [DC.MEDICATION, DC.WHEN_SURGERY])]"
                :error="errors[keyForConcern(DC.TREATMENT, [DC.MEDICATION, DC.WHEN_SURGERY])]"
                hide-details
                :show-none-option="true"
              />
              <template v-if="hasMedication">
                <div class="cols-10">
                  <Question
                    v-model="values[keyForConcern(DC.MEDICATION)]"
                    :question="questions[keyForConcern(DC.MEDICATION)]"
                    :error="errors[keyForConcern(DC.MEDICATION)]"
                    :input-width="smElse('100%', '80%')"
                    hide-details
                  />
                </div>
              </template>
              <template v-if="hasSurgery">
                <div class="cols-10">
                  <Question
                    v-model="values[keyForConcern(DC.WHEN_SURGERY)]"
                    :question="questions[keyForConcern(DC.WHEN_SURGERY)]"
                    :error="errors[keyForConcern(DC.WHEN_SURGERY)]"
                    hide-details
                    :input-width="smElse('100%', '250px')"
                    @input="surgeryWhenCheck"
                  />
                </div>
              </template>
              <template v-if="showExplication">
                <div class="cols-10">
                  <Question
                    v-model="values[keyForConcern(DC.TREATMENT_SURGERY_EXPLICATION)]"
                    :question="questions[keyForConcern(DC.TREATMENT_SURGERY_EXPLICATION)]"
                    :error="errors[keyForConcern(DC.TREATMENT_SURGERY_EXPLICATION)]"
                    hide-details
                    :input-width="smElse('100%', '250px')"
                  />
                </div>
              </template>
            </div>
            <div class="cols-10">
              <Question
                v-model="values[keyForConcern(DC.SYMPTOM_WHEN)]"
                :question="questions[keyForConcern(DC.SYMPTOM_WHEN)]"
                :error="errors[keyForConcern(DC.SYMPTOM_WHEN)]"
                hide-details
                :approximate="true"
                :input-width="smElse('100%', '250px')"
                @input="symptomWhenCheck"
              />
            </div>
            <div class="cols-10">
              <Question
                v-model="values[keyForConcern(DC.COMPLICATIONS)]"
                :question="questions[keyForConcern(DC.COMPLICATIONS)]"
                :error="errors[keyForConcern(DC.COMPLICATIONS)]"
                hide-details
                :show-none-option="true"
              />
            </div>
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper'
import Question from '@/components/interview/Question'
import {
  QuestionCategory as QC,
  DiagnosisConcerns as DC,
  DiagnosisCategories,
  Surgeries,
  SpecialDiagnoses,
  InterviewFlagCause
} from '@/api/Interview/constants'
import { RouteName } from '@/router/routes/constants.js'
import moment from 'moment'

export default {
  name: 'InterviewHealthDiagnosis',

  components: { FlowWrapper, Question },

  data() {
    return {
      isLoading: true,
      questions: {},
      values: {},
      errors: {},
      questionKeys: [],
      sourceKey: null,
      answerValue: null,
      DC: DC,
      QC: QC,
      ORF: InterviewFlagCause,
      followupIndex: undefined,
      detailIndex: undefined,
      diagnosisWhenValid: false,
      symptomWhenValid: false,
      surgeryWhenValid: false
    }
  },

  computed: {
    ...mapGetters('interview', [
      'interview',
      'interviewID',
      'mapToAnswers',
      'allHealthFollowups',
      'validateKeys',
      'hasDiagnosisAnswers',
      'hasIdentityFollowup'
    ]),

    hasErrors() {
      let hasErrors = false
      for (let key of this.activeKeys) {
        if (this.errors[key] && this.errors[key] !== null) {
          hasErrors = true
          break
        }
      }
      return hasErrors
    },

    hasValidFormData() {
      if (this.hasSurgery) {
        if (!this.surgeryWhenValid) return false
      }
      if (!this.diagnosisWhenValid || !this.symptomWhenValid) return false
      return !this.hasErrors && this.validateKeys(this.activeKeys, this.values)
    },

    title() {
      let name = 'diagnosis'
      if (this.sourceKey) {
        const currentHealthFollowupObj = this.allHealthFollowups.find((followup) => {
          return followup.answerDetails.sourceKey === this.sourceKey
        })
        const diagnosisMap = currentHealthFollowupObj.answerDetails.diagnosisMap
        name = diagnosisMap[this.answerValue]
      }
      return name
    },

    hasMedication() {
      const treatmentKey = this.keyForConcern(DC.TREATMENT, [DC.MEDICATION, DC.WHEN_SURGERY])
      if (treatmentKey) {
        return (
          this.values[treatmentKey].indexOf('MEDICATION') > -1 ||
          this.values[treatmentKey].indexOf('ORAL_MEDICATION') > -1
        )
      }
      return false
    },

    hasSurgery() {
      const treatmentKey = this.keyForConcern(DC.TREATMENT, [DC.MEDICATION, DC.WHEN_SURGERY])
      if (treatmentKey) {
        const intersection = this.values[treatmentKey].filter((t) => Surgeries.hasOwnProperty(t))
        return intersection.length > 0
      }
      return false
    },

    showA1c() {
      // special case question for four diagnoses:
      return (
        this.answerValue === SpecialDiagnoses.INSULIN ||
        this.answerValue === SpecialDiagnoses.NONINSULIN ||
        this.answerValue === SpecialDiagnoses.GLUCOSE ||
        this.answerValue === SpecialDiagnoses.GESTATION
      )
    },

    showFamilyHistory() {
      // special case question for diagnosis of dysplastic nevus syndrome
      return this.answerValue === SpecialDiagnoses.DYSPLASTIC_NEVUS_SYNDROME
    },

    showExplication() {
      const treatmentKey = this.keyForConcern(DC.TREATMENT, [DC.MEDICATION, DC.WHEN_SURGERY])
      if (treatmentKey) {
        return this.values[treatmentKey].indexOf(Surgeries.OTHER_SURGERY) > -1
      }
      return false
    },

    activeKeys() {
      let activeKeys = [this.keyForConcern(DC.DIAGNOSED_WHEN), this.keyForConcern(DC.SYMPTOM_WHEN)]
      if (this.hasMedication) {
        activeKeys = activeKeys.concat([this.keyForConcern(DC.MEDICATION)])
      }
      if (this.hasSurgery) {
        activeKeys = activeKeys.concat([this.keyForConcern(DC.WHEN_SURGERY)])
      }
      if (this.showA1c) {
        activeKeys = activeKeys.concat([this.keyForConcern(DC.A1C)])
      }
      if (this.showFamilyHistory) {
        activeKeys = activeKeys.concat([this.keyForConcern(DC.FAMILY_HISTORY)])
      }
      if (this.showExplication) {
        activeKeys = activeKeys.concat(this.keyForConcern(DC.TREATMENT_SURGERY_EXPLICATION))
      }
      return activeKeys
    }
  },

  methods: {
    ...mapActions('interview', {
      interviewReflexives: 'interviewReflexives',
      questionAnswerMaps: 'interviewQuestionAnswerMaps',
      updateInterviewAnswers: 'updateInterviewAnswers',
      interviewQuestions: 'interviewQuestions',
      getInterviewStatus: 'getStatus',
      validateInterviewAnswers: 'validateInterviewAnswers'
    }),
    diagnosisWhen() {
      return this.values[this.keyForConcern(DC.DIAGNOSED_WHEN)]
    },

    symptomWhen() {
      return this.values[this.keyForConcern(DC.SYMPTOM_WHEN)]
    },

    surgeryWhen() {
      return this.values[this.keyForConcern(DC.WHEN_SURGERY)]
    },
    async diagnosisWhenCheck(val, key) {
      this.isDirty(val, key)
      const success = await this.dateValidate(val, DC.DIAGNOSED_WHEN)
      this.diagnosisWhenValid = success
    },
    async symptomWhenCheck(val, key) {
      this.isDirty(val, key)
      const success = await this.dateValidate(val, DC.SYMPTOM_WHEN)
      this.symptomWhenValid = success
    },
    async surgeryWhenCheck(val, key) {
      this.isDirty(val, key)
      const success = await this.dateValidate(val, DC.WHEN_SURGERY)
      this.surgeryWhenValid = success
    },
    async dateValidate(val, key) {
      if (moment(val, 'YYYY-MM').isValid()) {
        const { success } = await this.validateAnswer(this.keyForConcern(key), val)
        return success
      }
      return false
    },

    async validateAnswer(key, value) {
      this.$delete(this.errors, key)
      const answer = {
        questionKey: key,
        values: [value]
      }
      const genRequestId = Math.random().toString(36).substr(2)
      const { results } = await this.validateInterviewAnswers({
        id: this.interviewID,
        answers: [answer],
        context: { requestId: genRequestId }
      })
      if (results && results.length > 0) {
        const result = results[0]
        if (!result.success) {
          this.$set(this.errors, key, result.message)
        } else {
          return { success: result.success, result }
        }
      }
      return { success: false, result: undefined }
    },

    isDirty(val, key) {
      if (key && this.errors[key]) this.errors[key] = null
    },

    appropriateInputWidth(question) {
      const valueType = question.valueType
      if (question.options.length > 0) {
        return '100%'
      } else if (
        valueType === 'DATE' ||
        valueType === 'APPROXIMATE_DATE' ||
        valueType === 'PAST_DATE' ||
        valueType === 'FUTURE_DATE' ||
        valueType === 'PAST_APPROXIMATE_DATE' ||
        valueType === 'FUTURE_APPROXIMATE_DATE'
      ) {
        return '200px'
      }
      return '80%'
    },

    keyForConcern(concern, exclude = []) {
      // exclude is a hack unless/until we refine the concerns more on the back end
      let questionKey
      for (let [key, value] of Object.entries(this.questions)) {
        const intersection = exclude.filter((item) => value.concerns.includes(item))
        if (!intersection.length && value.concerns.includes(concern)) {
          questionKey = key
          break
        }
      }
      return questionKey
    },

    async goBack() {
      // don't await save when navigating back
      this.save()
      if (this.detailIndex === 0) {
        await this.$router.push({
          name: RouteName.FINALIZE_HEALTH_FOLLOWUP,
          params: {
            index: this.followupIndex
          }
        })
        return { navigate: false }
      } else {
        if (this.detailIndex - 1 === -1) {
          await this.$router.push({
            name: RouteName.INTERVIEW_HEALTH_CONDITIONS
          })
        } else {
          await this.$router.push({
            name: RouteName.FINALIZE_HEALTH_DIAGNOSIS,
            params: {
              followupIndex: this.followupIndex,
              detailIndex: this.detailIndex - 1
            }
          })
        }

        return { navigate: false }
      }
    },

    async goNext() {
      const success = await this.save()
      if (!success) {
        return { navigate: false }
      }
      // do navigation to next step
      const currentAnswerValues = this.allHealthFollowups[this.followupIndex].answerDetails.answerValues
      if (this.detailIndex < currentAnswerValues.length - 1) {
        // go to next health details for current health followup
        await this.$router.push({
          name: RouteName.FINALIZE_HEALTH_DIAGNOSIS,
          params: {
            followupIndex: this.followupIndex,
            detailIndex: this.detailIndex + 1
          }
        })
      } else if (this.followupIndex < this.allHealthFollowups.length - 1) {
        // go to next health followup
        await this.$router.push({
          name: RouteName.FINALIZE_HEALTH_FOLLOWUP,
          params: {
            index: this.followupIndex + 1
          }
        })
      } else {
        // go to step 8
        await this.$router.push({
          name: RouteName.INTERVIEW_BENEFICIARY
        })
      }

      return { navigate: false }
    },

    async save() {
      const activeKeys = this.activeKeys
      // add COMPLICATIONS and TREATMENT keys, which allow none:
      const treatmentKey = this.keyForConcern(DC.TREATMENT, [DC.MEDICATION, DC.WHEN_SURGERY])
      const complicationsKey = this.keyForConcern(DC.COMPLICATIONS)
      if (!activeKeys.hasOwnProperty(treatmentKey)) {
        activeKeys.push(treatmentKey)
      }
      if (!activeKeys.hasOwnProperty(complicationsKey)) {
        activeKeys.push(complicationsKey)
      }

      const result = await this.updateInterviewAnswers({
        id: this.interviewID,
        answers: this.mapToAnswers(this.values, activeKeys)
      })
      if (result.errors) {
        this.errors = result.errors
        return false
      }
      return result
    },
    async validateOnMount() {
      const diagnosis = this.diagnosisWhen()
      const surgery = this.surgeryWhen()
      const symptoms = this.symptomWhen()
      if (diagnosis) {
        const success = await this.dateValidate(diagnosis, DC.DIAGNOSED_WHEN)
        this.diagnosisWhenValid = success
      }
      if (surgery) {
        const success = await this.dateValidate(surgery, DC.WHEN_SURGERY)
        this.surgeryWhenValid = success
      }
      if (symptoms) {
        const success = await this.dateValidate(symptoms, DC.SYMPTOM_WHEN)
        this.symptomWhenValid = success
      }
    },
    async fetchExistingInterviewAnswers() {
      this.isLoading = true
      this.answerValue = this.answerValue === undefined ? 0 : this.answerValue

      const { questions, answers } = await this.questionAnswerMaps({
        id: this.interviewID,
        concerns: [this.answerValue, DiagnosisCategories[this.sourceKey]],
        categories: [QC.HEALTH_DIAGNOSIS]
      })
      this.values = answers

      this.questions = questions
      this.questionKeys = Object.keys(this.questions)
      this.validateOnMount()

      this.isLoading = false
    }
  },

  async mounted() {
    if (this.interview) {
      this.followupIndex = parseInt(this.$route.params.followupIndex)
      this.detailIndex = parseInt(this.$route.params.detailIndex)
      const answerDetails = this.allHealthFollowups[this.followupIndex].answerDetails
      this.sourceKey = answerDetails.sourceKey
      this.answerValue = answerDetails.answerValues[this.detailIndex]
      await this.fetchExistingInterviewAnswers()
    }
  },

  watch: {
    '$route.path': function (val, oldVal) {
      this.followupIndex = this.$route.params.followupIndex
      this.detailIndex = this.$route.params.detailIndex
      const answerDetails = this.allHealthFollowups[this.followupIndex].answerDetails
      this.sourceKey = answerDetails.sourceKey
      this.answerValue = answerDetails.answerValues[this.detailIndex]
      this.fetchExistingInterviewAnswers()
    }
  }
}
</script>
