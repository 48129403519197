<template>
  <div class="contentful-title-and-description" :ref="createId" :id="createId" :class="classes" :style="styles">
    <div class="contentful-title-and-description__title" :class="titleClassesOrDefault">{{title}}</div>
    <div class="contentful-title-and-description__body">
      <ContentfulContentRenderer :content="descriptionHTML"/>
    </div>
  </div>
</template>

<script>

import { processRichText } from '@/api/Content'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-title-and-description',
  components: { ContentfulContentRenderer },
  props: {
    title: undefined,
    titleClasses: undefined,
    description: undefined,
    styles: undefined,
    classes: undefined
  },
  computed: {
    createId () {
      return this.title.replace(/[^\w\s-]|_/g, '').replace(/\s$/g, '').replace(/\s+/g, ' ').replace(/\s/g, '-').toLowerCase()
    },
    descriptionHTML () {
      return processRichText(this, this.description)
    },
    titleClassesOrDefault () {
      if (!this.titleClasses) {
        return 'df-title'
      }
      return this.titleClasses
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.contentful-title-and-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-top: 2rem;
  &__title {
    margin-bottom: 1.4rem;
  }
  &__body {
    color: $c-primary;
    font-weight: 300;
    font-size: 1.1em;
    ::v-deep {
      p {
        margin: 0;
      }
      p:not(:first-child) {
        margin-top: 1.2rem;
      }
      b {
        font-weight: bold;
      }
      strong {
        font-weight: bold;
      }
    }
  }
}

</style>
