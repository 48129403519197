<template>
  <figure class="lazy-image" v-observe-visibility="onVisible">
    <transition name="slow-fade">
      <img v-show="isVisible" ref="image" key="image" class="lazy-image__image"
           :style="extraStyles"
           :data-url="src"
           :alt="alt">
    </transition>
  </figure>
</template>

<script>

import { ObserveVisibility } from 'vue-observe-visibility'

export default {
  name: 'lazy-image',
  directives: {
    'observe-visibility': ObserveVisibility
  },
  props: {
    alt: {
      type: String
    },
    fit: {
      type: String
    },
    src: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      isVisible: false
    }
  },
  computed: {
    extraStyles () {
      const s = {}
      if (this.fit) {
        s['object-fit'] = this.fit
      }
      return s
    }
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.$refs.image.src = this.$refs.image.dataset.url
      }
    }
  },
  methods: {
    onVisible (isVisible, entry) {
      if (!this.isVisible && isVisible) {
        this.isVisible = true
      }
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/transitions";

.lazy-image {
  overflow: hidden;
  height: 100%;
  &__image {
    width: 100%;
    height: 100%;
  }
  &__placeholder {
    width: 100%;
    height: 100%;
    background: red;
  }
}
</style>
