<template>
  <div class="customer-detail__questions-wrapper">
    <div class="customer-detail__section">
      <div
        class="customer-detail__section-wrapper"
        v-for="(item, index) in filteredSections"
        :data-section="parseTitle(item.title)"
        :key="item.category + index"
      >
        <div
          class="customer-detail__section-header edit-detail__heading-container"
        >
          <h2>{{formatCurrent(item.id)}}{{ formatTitle(item.title) }}</h2>
          <div class="edit-detail__edit-buttons-wrapper">
            <span class="edit-detail__completed-checkmark" v-if="editSection !== item.title">{{ formatStatus(item) }}</span>
            <button
              v-if="editSection !== item.title && !isReadOnly && item.hasQuestions"
              @click="startEdit(item.title)"
              class="edit-detail__edit-button"
            >
              Edit
            </button>
            <DFButton
              v-else-if="editSection === item.title && !isReadOnly"
              elevation="0"
              @click="saveInterview(true)"
              :loading="loading"
              variant="small"
              class="edit-detail__save-button"
            >
              Save
            </DFButton>
          </div>
        </div>
        <div
          class="edit-detail__list-view"
          v-if="editSection !== item.title"
        >
          <div
            v-for="question in item.questions"
            :key="question.key + index"
            class="edit-detail__item"
            :class="getCols(question)"
          >
            <p v-if="question.active">
              <strong>{{ removeUnderscores(question.key) }}:</strong>
              {{ displayAnswers(question.answers) }}
            </p>

            <div
              class="customer-detail__reflexive-wrapper"
              v-if="question.reflexives.length > 0"
            >
              <div
                v-for="reflexive in question.reflexives"
                :key="reflexive.target.key"
                class="edit-detail__item"
              >
                <div v-if="reflexive.isSatisfied && reflexive.target.key === 'FAMILY_HISTORY'">
                  <div class="customer-detail__family-history" v-for="(item, index) in reflexive.target.answer.familyHistory" :key="index + item.relationship">
                    <p class="customer-detail__family-history-header"><strong>Family history {{ index + 1 }}</strong></p>
                    <p><strong>Relationship:</strong> {{ removeUnderscores(item.relationship) }}</p>
                    <p><strong>Severity:</strong> {{ removeUnderscores(item.severity) }}</p>
                    <p><strong>Diagnosis:</strong> {{ removeUnderscores(item.diagnoses.join(", "))}}</p>
                  </div>
                </div>
                <div v-else-if="reflexive.target.key === 'FAMILY_HISTORY_0_RELATIONSHIP' || reflexive.target.key === 'FAMILY_HISTORY_0_DIAGNOSIS' || reflexive.target.key === 'FAMILY_HISTORY_0_SEVERITY'"></div>
                <p v-else-if="reflexive.isSatisfied && reflexive.target.key !== 'OCCUPATION'">
                  <strong
                    >{{ removeUnderscores(reflexive.target.key) }}: ref</strong
                  >
                  {{ displayAnswers(reflexive.target.answers) }}
                </p>
                <p v-else-if="reflexive.isSatisfied && reflexive.target.key === 'OCCUPATION' && occupation">
                  <strong>Occupation: </strong>
                  {{ occupation.text }}
                </p>
                <div
                  class="customer-detail__section-reflexive"
                  v-for="reflexive in reflexive.target.reflexives"
                  :key="reflexive.target.key"
                >
                  <p v-if="reflexive.isSatisfied" class="edit-detail__item">
                    <strong
                      >{{ removeUnderscores(reflexive.target.key) }}:
                      ref</strong
                    >
                    {{ displayAnswers(reflexive.target.answers) }}
                  </p>
                  <template v-if="reflexive.target.reflexives.length">
                    <div
                      class="customer-detail__section-reflexive"
                      v-for="r in reflexive.target.reflexives"
                      :key="r.target.key"
                    >
                      <p v-if="r.isSatisfied" class="edit-detail__item">
                      <strong
                        >{{ removeUnderscores(r.target.key) }}:
                        ref</strong
                      >
                      {{ displayAnswers(r.target.answers) }}
                    </p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="customer-detail__section-questions"
          v-if="editSection === item.title"
        >
          <v-form class="mq-grid">
            <div
              class="customer-detail__section-question"
              :class="getCols(question)"
              v-for="question in item.questions"
              :key="question.key"
            >
              <Question
                v-if="question.active"
                v-model="question.answers"
                :question="question"
                :error="errors[QK[question.key]]"
                hide-details
                :type="questionType(question, item.category)"
                :ref="question.key"
                @input="setKeysAndValues"
                @validateInput="setErrors"
                :text="question.key === QK.HEALTH_CONDITIONS_ACKNOWLEDGED ? changeText :''"
                :show-none-option="question.allowsNone"
              />
              <div
                class="customer-detail__reflexive-wrapper"
                v-if="question.reflexives.length > 0"
              >
                <div
                  class="customer-detail__section-reflexive"
                  v-for="reflexive in question.reflexives"
                  :key="reflexive.target.key"
                >
                  <div v-if="reflexive.target.key === 'FAMILY_HISTORY_0_RELATIONSHIP' || reflexive.target.key === 'FAMILY_HISTORY_0_DIAGNOSIS' || reflexive.target.key === 'FAMILY_HISTORY_0_SEVERITY'"></div>
                  <div v-else-if="reflexive.target.key === 'FAMILY_HISTORY'">
                    <div v-if="reflexive.isSatisfied && reflexive.target.key === 'FAMILY_HISTORY'">
                      <InterviewFamilyHealth
                        @removeFamilyMember="clearItem"
                        @memberUpdated="updateParentValues"
                        :isAdmin="true"
                        :vals="reflexive.target.answer.familyHistory !== null ? reflexive.target.answer.familyHistory[index] : undefined"
                        v-for="(component, index) in components"
                        :key="component.id"
                        :index="index"
                        :is="component.comp"
                        :id="component.id"
                        :last="index === components.length -1 ? 'last' : ''"
                      />
                    </div>
                  </div>
                  <Question
                    v-else-if="reflexive.isSatisfied"
                    v-model="reflexive.target.answers"
                    :question="reflexive.target"
                    :error="errors[QK[reflexive.target.key]]"
                    hide-details
                    :type="questionType(reflexive.target)"
                    :ref="reflexive.target.key"
                    @input="setKeysAndValues"
                    @validateInput="setErrors"
                  />
                  <div
                    class="customer-detail__section-reflexive"
                    v-for="reflexive in reflexive.target.reflexives"
                    :key="reflexive.target.key"
                  >
                    <div v-if="reflexive.target.key === 'FAMILY_HISTORY_0_RELATIONSHIP' || reflexive.target.key === 'FAMILY_HISTORY_0_DIAGNOSIS' || reflexive.target.key === 'FAMILY_HISTORY_0_SEVERITY' || reflexive.target.key === 'FAMILY_HISTORY'"></div>
                    <Question
                      v-else-if="reflexive.isSatisfied"
                      v-model="reflexive.target.answers"
                      :question="reflexive.target"
                      :error="errors[QK[reflexive.target.key]]"
                      hide-details
                      :type="questionType(reflexive.target)"
                      :ref="reflexive.target.key"
                      @input="setKeysAndValues"
                      @validateInput="setErrors"
                    />
                    <template v-if="reflexive.target.reflexives.length">
                      <div
                        class="customer-detail__section-reflexive"
                        v-for="r in reflexive.target.reflexives"
                        :key="r.target.key"
                      >
                        <div v-if="reflexive.isSatisfied && r.isSatisfied">
                          <Question
                            v-model="r.target.answers"
                            :question="r.target"
                            :error="errors[QK[r.target.key]]"
                            hide-details
                            :type="questionType(r.target)"
                            :ref="r.target.key"
                            @input="setKeysAndValues"
                            @validateInput="setErrors"
                          />
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </div>
      </div>
      <div class="panel panel--warning" v-if="unansweredSections && unansweredSections.length">
        <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#E33031"><path d="M24 34q.7 0 1.175-.475.475-.475.475-1.175 0-.7-.475-1.175Q24.7 30.7 24 30.7q-.7 0-1.175.475-.475.475-.475 1.175 0 .7.475 1.175Q23.3 34 24 34Zm-1.35-7.65h3V13.7h-3ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z"/></svg>
        <div>
          <h2 class="panel__title">Missing questions.</h2>
          <p class="panel__content">Looks like some information is missing that is required. Review the missing information below.</p>
          <div class="section" v-for="({ title, questions }, i) in unansweredSections" :key="i">
            <h3 class="section__title">{{ title }}</h3>
            <ul class="section__list">
              <li v-for="({ key }) in questions" :key="key">
                <a class="section__question"
                   :href="`#${parseTitle(title)}`"
                   @click="scrollTo($event)"
                >
                  {{ removeUnderscores(key) }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Question from '@/components/interview/Question'
import DFButton from '@/components/DFButton'
import InterviewFamilyHealth from '@/components/interview/InterviewFamilyHealth'
import { QuestionKey as QK, SectionDisplayOption } from '@/api/Interview'
import { removeUnderscores, titleCase, lowerCase } from '@/utils/helpers'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'admin-edit',
  components: {
    Question,
    DFButton,
    InterviewFamilyHealth
  },
  props: {
    interview: {
      type: Object,
      default: () => {
        return {}
      }
    },
    unanswered: {
      type: Array,
      default: () => ([])
    },
    sections: {
      type: Array,
      default: undefined
    },
    id: {
      type: String,
      default: undefined
    },
    status: {
      type: String,
      default: undefined
    },
    policyId: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      occupation: undefined,
      occupationCode: undefined,
      QK: QK,
      errors: {},
      localErrors: {},
      editMode: false,
      editSection: undefined,
      loading: false,
      values: [],
      hasError: false,
      hideEdit: [
        'SUBMITTED',
        'ACCEPTED',
        'REJECTED',
        'ERROR',
        'EXPIRED',
        'INACTIVE'
      ],
      healthValues: undefined,
      healthValuesArr: [],
      components: [
        { comp: InterviewFamilyHealth, id: 0 },
        { comp: InterviewFamilyHealth, id: 1 },
        { comp: InterviewFamilyHealth, id: 2 },
        { comp: InterviewFamilyHealth, id: 3 },
        { comp: InterviewFamilyHealth, id: 4 },
        { comp: InterviewFamilyHealth, id: 5 },
        { comp: InterviewFamilyHealth, id: 6 },
        { comp: InterviewFamilyHealth, id: 7 }

      ]
    }
  },
  computed: {
    // console: () => console,
    ...mapGetters('interview', ['answersForKeys', 'questionsMap']),
    changeText () {
      return 'Applicant has acknowledged that all health condition answers are correct or none apply '
    },
    unansweredSections () {
      const sections = this.filteredSections.reduce((collection, { title, questions }) => {
        const keys = this.allQuestionKeys(questions)
        const unanswered = this.unanswered.filter(({ key }) => keys.includes(key))
        if (unanswered.length) collection.push({ title: title.toLowerCase(), questions: unanswered })
        return collection
      }, [])
      return sections
    },
    filteredSections () {
      const activeSections = this.sections.filter(item => this.handleActiveSections(item))
      return activeSections
    },
    questionKeys () {
      let questions = []
      for (const item in QK) {
        questions.push(item)
      }
      return questions
    },
    isReadOnly () {
      return !!(this.hideEdit.includes(this.status) || this.policyId)
    }
  },
  methods: {
    ...mapActions('interview', ['updateInterviewAnswers', 'findOccupationWithKey']),
    removeUnderscores,
    parseTitle (value) {
      return value.toLowerCase().replace(/ /g, "_")
    },
    handleActiveSections (section) {
      const { active, category, id } = section

      const diabetesFollowUp = this.sections.find(s => s.id === "HEALTH_FOLLOWUP_DIABETES_ENDOCRINE")
      if (active) {
        if (category === "INCOME") {
          // Remove "ANNUAL_HOUSEHOLD_PRE_TAX_INCOME" from questions for income
          section.questions = section.questions.filter(q => q.key !== "ANNUAL_HOUSEHOLD_PRE_TAX_INCOME")
        }

        // @PATCHED This is a patch to temporary fix diabetes reflexive sections
        // Find the section for diabetes follow up
        if (diabetesFollowUp && id.includes("HEALTH_DIAGNOSIS_DIABETES")) {
          // Get the answers from the follow up questions
          const { answers } = diabetesFollowUp.questions.at(0);
          // If has answers filter out any diabetes section not in answers
          if (answers) {
            const words = id.split("_")
            // Check if section id includes answer
            if (answers.includes(words.at(-1))) return section
            else return;
          }
        }
        return section
      }
    },
    allQuestionKeys (questions) {
      const collection = []
      return questions.reduce((items, source) => {
        const { target } = source
        const question = target ?? source
        const { key, reflexives } = question
        items = [...items, key]
        if (reflexives && reflexives.length) items = [...items, ...this.allQuestionKeys(reflexives)]
        return items
      }, collection)
    },
    clearItem (ind, id) {
      this.$delete(this.healthValuesArr, ind)
    },
    updateParentValues (val, id) {
      if (val.diagnoses !== undefined && val.severity !== undefined & val.relationship !== undefined) {
        if (this.healthValues === undefined) {
          this.healthValuesArr.splice(id, 0, val)
          this.healthValues = {
            questionKey: QK.FAMILY_HISTORY,
            familyHistory: this.healthValuesArr
          }
        } else {
          this.healthValuesArr.splice(id, 1, val)
          this.healthValues.familyHistory = this.healthValuesArr
        }
      }
    },
    setErrors (questionKey, error) {
      this.localErrors[questionKey] = error
      this.hasError = Object.keys(this.localErrors).some(
        (k) => this.localErrors[k]
      )
    },
    checkHealthConditions () {
      let ind = this.values.findIndex((item) => item.questionKey === QK.HEALTH_CONDITIONS)
      const val = this.values[ind]
      if (ind === -1) {
        this.values.push({
          questionKey: QK.HEALTH_CONDITIONS,
          values: ['']
        })
      } else {
        if (val.values && val.values.length === 0) {
          this.values[ind] = {
            questionKey: QK.HEALTH_CONDITIONS,
            values: ['']
          }
        }
      }
    },
    setKeysAndValues (val, key, inputType) {
      if (val !== 'OTHER') {
        let ind = this.values.findIndex((item) => item.questionKey === key)
        if (val !== undefined && val !== null && key !== undefined) {
          const valArr = this.formattedValue(val)
          if (key === 'OCCUPATION') this.occupationCode = val;
          if (ind === -1) {
            this.values.push({
              questionKey: key,
              values: valArr
            })
            if (key === QK.HEALTH_CONDITIONS_ACKNOWLEDGED || key === QK.HEALTH_CONDITIONS) {
              this.checkHealthConditions()
            }
          } else {
            this.values[ind] = {
              questionKey: key,
              values: valArr
            }
            if (key === QK.HEALTH_CONDITIONS_ACKNOWLEDGED || key === QK.HEALTH_CONDITIONS) {
              this.checkHealthConditions()
            }
          }
          this.hasError = false
          if (inputType === 'yesno' || inputType === 'options') {
            this.saveInterview(false)
          }
        } else {
          this.hasError = true
        }
      }
    },
    displayAnswers (answer) {
      if (Array.isArray(answer)) {
        return answer.join(' ')
      }
    },
    formattedValue (value) {
      return typeof value === 'string'
        ? value === ''
          ? []
          : [value]
        : Array.from(value)
    },
    saveInterview (closeSection = false) {
      if (!this.hasError) {
        if (this.values.length > 0 || this.healthValues !== undefined) {
          this.saveProgress()
        }
        if (closeSection) {
          this.editSection = undefined
        }
      }
    },
    removeTypename () {
      if (this.healthValuesArr) {
        this.healthValuesArr.forEach((item, index) => {
          delete item.__typename
        })
      }
    },
    async saveProgress () {
      this.loading = true
      const val = this.values.find(o => o.questionKey === QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE)
      if (val && val.values[0] === 'false') {
        this.healthValues = undefined
      }

      if (this.healthValues !== undefined & this.editSection === 'HEALTH') {
        this.removeTypename()
        this.values.push(this.healthValues)
      }

      const payload = {
        id: this.id,
        answers: this.values
      }
      await this.updateInterviewAnswers(payload)

      this.loading = false
      this.$emit('loading')
      this.hasError = false
      this.localErrors = {}
      await this.getOccupation()
    },
    startEdit (cat) {
      this.values = []
      this.editSection = cat
    },
    formatCurrent (id) {
      if (id === this.interview?.currentSection?.id) {
        return '➡ '
      }
      return ''
    },
    formatStatus (sec) {
      let parts = []
      sec = sec ?? {}
      if (!sec.incomplete) {
        parts.push('✔ Completed')
      }
      if (!sec.hasQuestions) {
        parts.push('no interview questions')
      }
      const opts = sec.displayOptions ?? []
      opts.forEach((opt) => {
        switch (opt) {
          case SectionDisplayOption.HIDE_BACK_BUTTON:
            parts.push('back button hidden')
            break
          case SectionDisplayOption.HIDE_EDIT_LINK:
            parts.push('edit links hidden')
            break
          default:
            parts.push(lowerCase(opt))
        }
      })
      if (parts.length > 0) {
        parts = [`${parts.join(', ')}`]
      }
      if (parts.length > 0 && sec.hasQuestions) {
        parts.push('')
      }
      return parts.join(' |')
    },
    formatTitle (title) {
      return titleCase(title)
    },
    getCols ({ key }) {
      if (
        key === QK.HEALTH_CONDITIONS_ACKNOWLEDGED ||
        key === QK.ACCEPTED_NOTICES ||
        key === QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE
      ) {
        return 'cols-10'
      } else {
        return 'cols-5'
      }
    },
    questionType (question, cat) {
      const { key, options } = question
      if (cat === 'HEALTH_CONDITION' ||
        cat === 'HEALTH_FOLLOWUP' ||
        cat === 'HEALTH_DIAGNOSIS') {
        if (key === QK.HEALTH_CONDITIONS) return 'healthConditions'
        if (question.allowsNone) return 'allowsNone'
        if (key === QK.HEALTH_CONDITIONS_ACKNOWLEDGED) return 'ack'
        if (key.includes('_DATE') || key.includes('_WHEN')) return 'date'
        return 'options'
      } else if (key === QK.ACCEPTED_NOTICES) {
        return 'ack'
      } else if (options && options.length > 0) {
        return 'select'
      }
      return undefined
    },
    findAnswerInInterview (interviewSections, key) {
      for (let i = 0; i < interviewSections.length; i++) {
        if (interviewSections[i].active) {
          for (let j = 0; j < interviewSections[i].questions.length; j++) {
            if (interviewSections[i].questions[j].key === key) {
              return interviewSections[i].questions[j].answers[0]
            } else {
              if (interviewSections[i].questions[j].reflexives.length > 0) {
                for (let k = 0; k < interviewSections[i].questions[j].reflexives.length; k++) {
                  if (interviewSections[i].questions[j].reflexives[k].target.key === key) {
                    return interviewSections[i].questions[j].reflexives[k].target.answers[0]
                  }
                }
              }
            }
          }
        }
      }
      return undefined
    },
    async getOccupation () {
      const code = this.findAnswerInInterview(this.interview.sections, 'OCCUPATION')
      // Patches updating occupation during section save
      this.occupationCode = this.occupationCode === undefined ? code : this.occupationCode;
      if (code !== undefined) {
        this.occupation = await this.findOccupationWithKey(this.occupationCode)
      }
    },
    scrollTo (e) {
      e.preventDefault()
      const id = e.target.hash.substring(1)
      const section = document.querySelector(`[data-section="${id}"]`)
      if (section) window.scrollTo({ top: section.offsetTop - 110, behavior: "smooth" });
    }
  },
  mounted () {
    this.getOccupation()
  }
}
</script>

<style scoped lang="scss">
.edit-detail {
  &__completed-checkmark {
    padding-right: .5em;
  }
  &__edit-buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    min-height: 50px;
  }
  &__edit-button {
    text-decoration: underline;
    padding: 5px 0;
    &:focus {
      outline: none;
    }
  }
  &__save-button {
    color: $c-eggshell;
    background-color: $c-red;
    padding: 5px 0;
    min-width: 100px;
    &:focus {
      outline: none;
    }
  }
  &__heading-container {
    display: flex;
    align-items: center;
    h2 {
      flex: 1;
    }
  }
  &__list-view {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    strong {
      text-transform: capitalize;
    }
  }
  &__item {
    flex: 1 50%;
    margin: 5px 0;
    &:nth-child(odd) {
      padding-right: 10px;
    }

  }
}
.customer-detail {
  &__family-history {
    margin-bottom: 20px;
  }
  &__family-history-header {
    margin-bottom: 10px;
  }
}

.panel {
  display: flex;
  gap: 12px;
  padding: 12px;
  border-radius: 12px;
  background: #F4F0E6;
  & svg {
    min-width: 48px;
  }
}

.panel__title {
  font-size: 18px;
}

.panel__content {
  font-size: 16px;
  opacity: .8;
}

.section__title,
.section__question {
  font-size: 14px;
  text-transform: capitalize;
}

.section__title {
  border: 0;
  margin: 0;
  color: currentColor;
}

.section__list {
  list-style: none;
  padding: 0;
}

.section__list li {
  list-style-type: none;
}

.section__question {
  color: #E33031;
  width: fit-content;
}

</style>
