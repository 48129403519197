<template>
  <div :class="containerClass">
    <LazyImage :src="image.fields.file.url" :style="imageStyles"></LazyImage>
  </div>
</template>

<script>
import LazyImage from '@/components/content/LazyImage.vue'
export default {
  name: 'contentful-image',
  components: { LazyImage },
  props: {
    image: undefined,
    alignment: undefined,
    width: undefined,
    height: undefined
  },
  computed: {
    containerClass () {
      var classNames = 'contentful-image'
      if (this.alignment) {
        classNames += ` ${this.alignment}`
      }
      return classNames
    },
    imageStyles () {
      var styles = ''
      if (this.width) {
        styles += `max-width: ${this.width};`
      }
      if (this.height) {
        styles += `max-width: ${this.height};`
      }
      return styles
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/styles/media-queries';

.contentful-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  margin: 2rem 0;
  @include md {
    margin: 0;
  }
  > img {
    width: 100%;
    padding: 0 35px;
    @include md {
      padding: 0;
    }
  }
  &.left {
    justify-content: flex-start;
  }
  &.center {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
}
</style>
