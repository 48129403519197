<template>
  <div class="flags" v-if="interview && 'flags' in interview && interview.flags.length > 0">
    <h3>Flags:</h3>
    <div
      class="customer-detail__flags-grid"
    >
      <div
        v-for="(item, index) in interview.flags"
        :key="item + index"
        class="flag-wrapper"
      >
        <img
          src="@/assets/images/icon_flag.svg"
          aria-label="flag"
          alt="flag"
          class="flag"
        />
        <p><strong>Type:</strong> {{ sentenceCase(item.type) }}</p>
        <p><strong>Cause:</strong> {{ sentenceCase(item.cause) }}</p>
        <p v-if="item.description"><strong>Description:</strong> {{ item.description }}</p>
        <p v-if="showSource && item.source"><strong>Source:</strong> {{ sentenceCase(item.source) }}</p>
        <p v-if="item.cause == 'SPOUSAL_SIGNATURE_REQUIRED'">
          <a @click="resolveSpousalSignatureFlag">Mark spousal signature collected</a>
          <span v-if="resolveSignatureError != ''">{{ resolveSignatureError }}</span>
        </p>
        <p>
          <strong>Created:</strong>
          {{ moment(item.created).format("L LTS -Z") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { sentenceCase } from '@/utils/helpers'
import { adminInterviewFlagsShowSourceField } from '@/config/app.config'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'admin-interview-flags',
  props: {
    interview: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      hasFlags: true,
      resolveSignatureError: undefined,
      showSource: adminInterviewFlagsShowSourceField
    }
  },
  methods: {
    ...mapActions('admin', ['resolveSpousalSignature']),
    moment,
    sentenceCase,
    async resolveSpousalSignatureFlag () {
      this.resolveSignatureError = undefined
      this.$emit('loading')
      const { problem, interview } = await this.resolveSpousalSignature({ id: this.interview.id })
      if (problem?.message) {
        this.resolveSignatureError = problem.message
      } else {
        this.interview = interview
      }
    }
  }
}
</script>

<style scoped>
.flag {
  position: absolute;
  top: 5px;
  right: 5px;
}
.flag-wrapper {
  padding: 10px;
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
</style>
