<template>
  <div class="interview-thanks">
    <FlowWrapper header-variant="red" :show-buttons="false">
      <template v-slot:header-text>{{ $route.meta.header.title }}</template>
      <template v-slot:content-header>
        <div class="df-content-header-text" v-if="!isEstimate">
          Sit back &amp; relax while we review your information.
        </div>
        <div class="df-content-header-text" v-else>We’re now reviewing your application.</div>
      </template>
      <template v-slot:content>
        <div v-if="!isEstimate">
          <div>
            <p>
              Thank you for considering Dayforward to protect your family’s future! We’re just crossing our t’s and
              dotting our i’s to make sure everything is in order for your customized policy. As we review, we may reach
              out with follow up questions. And, if all looks good, we’ll send your completed policy and your coverage
              will begin as soon as we have charged your first payment.
            </p>
            <p class="tm-2r">
              If you have any questions before then, we’re happy to help! Just hit the chat button below or give us a
              ring at
              <a href="tel:+18884340228">1-888-434-0228</a> 7 days a week from 9am-9pm CT.
            </p>
          </div>
        </div>
        <div v-else>
          <p>
            In most cases, we’ll finish our review in 1 day. Once we’re done, we’ll let you know if you’re approved and
            we’ve accepted your offer. We’ll then charge your first payment to begin your coverage and send you your
            policy documents right away!
          </p>

          <p class="tm-2r">
            If we see any issues or your quote increases, don’t worry. We’ll get back to you ASAP to discuss your
            application, and then you can decide if you will accept our counteroffer for a new price and still want to
            buy a Dayforward policy.
          </p>
        </div>
        <DFButton class="tm-3r" elevation="0" @click="onButtonPressed">Contact Us</DFButton>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import FlowWrapper from '@/views/flow/FlowWrapper'
import DFButton from '@/components/DFButton'
import { getCookie } from '@/utils/helpers'

export default {
  name: 'InteriewPolicyPending',
  components: { FlowWrapper, DFButton },
  computed: {
    isEstimate() {
      return this.$store.state.interview?.interview?.quote?.isEstimate || false
    }
  },
  methods: {
    getCookie,
    async onButtonPressed() {
      await this.$router.push({ name: 'help' })
    }
  }
}
</script>

<style scoped lang="scss"></style>
