<template>
  <Modal
    title="Your family's protection."
    :showActions="false"
    v-model="isShowing"
    @close="onClose"
    :scrollOnUpdate="true"
    persistent
    :show-overlay="isShowingOverlay"
  >
    <template v-slot:overlay-content>
      <div class="video-overlay">
        <VideoIframe
          :videoId="overlayVideoId"
          :autoplay="true"
        />
        <div class="close">
          <a href="#" rel="noopener" @click="hideOverlay">Close</a>
        </div>
      </div>
    </template>
    <template v-slot:content>

      <div class="family-protection" v-if="showPage === 0">
        <div>
          <div>
            <b>Protecting ${{ annualBenefit }} in {{ incomeType }} income.</b>
          </div>
          <p>
            {{ standardOfLivingText }}
            <span class="family-protection__link" @click="showOverlay('990xzg6AvrU')">Watch video.</span>
          </p>
        </div>
        <TextTwoColumn class="modal-text tm-2r">
          <template v-slot:title>
            <b>Payments to your family:</b>
          </template>
          <template v-slot:column2>
            <p>${{ benefitAmount }} twice a month</p>
          </template>
        </TextTwoColumn>
        <TextTwoColumn class="modal-text tm-1r">
          <template v-slot:title>
            <b>Payments until:</b>
          </template>
          <template v-slot:column2>
             <div>{{ termEndDate }}</div>
             <div>{{ untilTermEndText }}</div>
          </template>
        </TextTwoColumn>
        <TextTwoColumn class="modal-text tm-1r">
          <template v-slot:title>
            <b>Coverage until:</b>
          </template>
          <template v-slot:column2>
             <div>{{ termEndDate }}</div>
             <div>{{ untilTermEndText }}</div>
          </template>
        </TextTwoColumn>
        <TextTwoColumn class="modal-text tm-1r">
          <template v-slot:title>
            <b>One-time payment:</b>
          </template>
          <template v-slot:column2>
             <div>$10,000 for funeral expenses</div>
          </template>
        </TextTwoColumn>
        <TextTwoColumn class="modal-text tm-1r">
          <template v-slot:title>
            <b>Total money for your family:</b>
          </template>
          <template v-slot:column2>
             <div class="family-protection__link" @click="showNext">See how much</div>
          </template>
        </TextTwoColumn>
        <TextTwoColumn class="modal-text tm-1r">
          <template v-slot:title>
            <b>Your price:</b>
          </template>
          <template v-slot:column2>
             <div>${{ premium }} per month</div>
          </template>
        </TextTwoColumn>
        <TextTwoColumn class="modal-text tm-1r">
          <template v-slot:title>
            <b>{{ increaseProtectionType }}:</b>
          </template>
          <template v-slot:column2>
             <div>{{ increaseProtectionText }} See how below.</div>
          </template>
        </TextTwoColumn>
        <TextTwoColumn class="modal-text tm-1r" v-if="childExtensionAvailable">
          <template v-slot:title>
            <b>If you have {{ hasChild ? 'another' : 'a' }} child:</b>
          </template>
          <template v-slot:column2>
             <div>
               Extend your policy for {{ premiumPer1YearExtension }}/month
               per additional year for each $1,000 of annual income. See how below.
             </div>
          </template>
        </TextTwoColumn>

        <div class="tm-2r">
          <p>
            <b>How to change your policy instantly at the price shown above</b>
          </p>
          <p>
            You can instantly change your protection through these add-on features
            (a.k.a. riders) as described below. If you would like to change your
            protection at other times or in larger amounts, contact us to request
            a change. But, we may require more information from you and price changes may apply.
          </p>
          <ul class="tm-1r">
            <li>
              <span class="fine-print">Income increase rider:</span>
              {{ increaseCoverageFinePrint }}

            </li>
            <li v-if="childExtensionAvailable">
              <span class="fine-print">Child extension rider:</span>
              {{ extendCoverageFinePrint }}
            </li>
          </ul>
          <p>
            Want to customize your policy to an amount or length not seen here? Longer
            policy lengths may be available. Contact us at 1-888-434-0228 M-F 9am-5pm CT.
          </p>
        </div>
      </div>

      <div class="family-protection" v-if="showPage === 1">
        <div class="family-protection__content">
          <p>
            <b>Your family gets a steady stream of income</b>
          </p>
          <p v-if="isIncomeReplacement">
            You make an income to support your {{ familyType }}. If you die, your policy guarantees
            that they continue to receive your income {{ termReasonText }}, plus $10,000 to help
            cover funeral expenses. Let's do the math to calculate exactly how much they get.
          </p>
          <p v-else>
            You support your family in many ways. If you die, your policy guarantees your
            {{ familyType }} additional income to help them during the loss of your household
            contributions {{ termReasonText }}.
          </p>
        </div>
        <div class="family-protection__content tm-2r">
          <p>
            <b v-if="termMonthsRemainder === 0" >If you die shortly after the policy is issued.</b>
            <b v-else>If you die {{ termMonthsRemainder }} {{ termMonthsRemainder === 1 ? 'month' : 'months' }} after the policy is issued.</b>
          </p>
          <p>
            {{ yourPolicyText }}
          </p>
          <Timeline
            :value="1/termYears"
            :start="timelineStart"
            :end="timelineEnd"
            :title="timelineTitle"
            :is-min="true"
            subtitle="shortly after policy is issued"
            :show-age="showAgeOnTimeline"
            :age-text="timelineAgeText"
          />
          <div class="bm-2r">
            <div>
              <div class="family-protection__calc">
                ${{ annualBenefit}} x {{ termYears }} years + $10,000 = ${{ totalBenefitAmount }}
              </div>
            </div>
          </div>
        </div>
        <div class="family-protection__content">
          <p>
            <b>If you die {{ whenYouDieText }}.</b>
          </p>
          <p>
            In this case, your {{ familyType }} would only need about {{ remainingYears }} years of
            {{ isIncomeReplacement ? 'your income' : 'that income' }}<span v-if="untilTermEndText != ''"> {{ untilTermEndText }}</span>. {{ midwayReplacementText }}
            ${{ payoutAmount}} because {{ hasChild ? 'your family' : 'they' }} would need fewer years of income
            replaced.
          </p>
          <Timeline
            :value="(termYears - remainingYears)/termYears"
            :start="timelineStart"
            :end="timelineEnd"
            :title="timelineTitle"
            :is-mid="true"
            :subtitle="whenYouDieText"
            :show-age="showAgeOnTimeline"
            :age-text="timelineAgeText"
          />
          <div class="bm-1r">
            <div>
              <div class="family-protection__calc">
                ${{ annualBenefit}} x {{ remainingYears }} years + $10,000 = ${{ payoutAmount }}
              </div>
            </div>
          </div>
        </div>
        <div class="family-protection__content">
          <p>
            <b>If you die towards the end of your policy.</b>
          </p>
          <p>
            In this scenario, {{ endOfPolicyText }} your {{ familyType }} {{ hasChild ? 'doesn\'t' : 'don\'t' }} need as
            much income to protect their future. Regardless, if there's still time
            left on your policy, we guarantee that they get at least 3 years
            of {{ incomeString }}.
          </p>
          <Timeline
            :value="(termYears - 3)/termYears"
            :start="timelineStart"
            :end="timelineEnd"
            :title="timelineTitle"
            :is-max="true"
            subtitle="near end of policy"
            :show-age="showAgeOnTimeline"
            :age-text="timelineAgeText"
          />
          <div class="bm-1r">
            <div>
              <div class="family-protection__calc">
                ${{ annualBenefit}} x 3 years + $10,000 = ${{ threeYearPayout }}
              </div>
            </div>
          </div>
        </div>
        <BackForwardButtons
          :modal-navigation="true"
          :next-action="showNext"
          :back-action="showPrev"
          :show-next="false"
        />
      </div>

    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
import TextTwoColumn from '@/components/content/TextTwoColumn.vue'
import { BenefitType, BenefitTermCode, QuestionKey as QK } from '@/api/Interview'
import BackForwardButtons from '@/components/navigation/BackForwardButtons.vue'
import moment from 'moment'
import Timeline from '@/components/Timeline'
import VideoIframe from '@/components/content/VideoIframe'
import { mapGetters } from 'vuex'

export default {
  name: 'family-protection-multipage-modal',
  components: { VideoIframe, TextTwoColumn, Modal, BackForwardButtons, Timeline },
  data () {
    return {
      isShowing: this.show,
      overlayVideoId: undefined,
      isShowingOverlay: false,
      showPage: 0,
      QK
    }
  },
  props: {
    show: Boolean,
    quote: Object
  },

  computed: {
    ...mapGetters('interview', ['answers']),

    showAgeOnTimeline () {
      if (this.quote) {
        return this.quote.termReason.code === BenefitTermCode.CHILD_AGE ||
          this.quote.termReason.code === BenefitTermCode.INSURED_AGE
      }
      return false
    },

    timelineAgeText () {
      if (this.quote) {
        if (this.quote.termReason.code === BenefitTermCode.CHILD_AGE) {
          return this.childAge() <= 1 ? ['year old', 'years old'] : ['years old', 'years old']
        } else if (this.quote.termReason.code === BenefitTermCode.INSURED_AGE) {
          return ['years old', 'years old']
        }
      }
      return ['', '']
    },

    timelineStart () {
      let start = ''
      if (this.quote) {
        switch (this.quote.termReason.code) {
          case BenefitTermCode.CHILD_AGE:
            start = this.childAge() >= 1 ? this.childAge() : '<1'
            break
          case BenefitTermCode.INSURED_AGE:
            start = this.insuredAge()
            break
          default:
            start = 'Now'
        }
      }
      return start
    },

    timelineEnd () {
      let end = ''
      if (this.quote) {
        switch (this.quote.termReason.code) {
          case BenefitTermCode.CHILD_AGE:
            end = this.childAge(this.quote.termRecommended)
            break
          case BenefitTermCode.INSURED_AGE:
            end = this.insuredAge(this.quote.termRecommended)
            break
          default:
            end = this.termYears
        }
      }
      return end
    },

    timelineTitle () {
      let title = ''
      if (this.quote) {
        switch (this.quote.termReason.code) {
          case BenefitTermCode.CHILD_AGE:
            title = 'Youngest child\'s age'
            break
          case BenefitTermCode.INSURED_AGE:
            title = 'Your age'
            break
          default:
            title = 'Length of policy'
        }
      }
      return title
    },

    childExtensionAvailable () {
      if (this.quote && this.quote.childExtensionAvailable !== undefined) {
        return this.quote.childExtensionAvailable
      }
      return false
    },

    termEndDate () {
      return this.quote ? moment(this.quote.termEndDate).format('MMMM D, YYYY') : ''
    },

    benefitAmount () {
      return this.quote ? this.quote.benefitAmount.toLocaleString() : ''
    },

    annualBenefit () {
      return this.quote ? this.quote.annualBenefit.toLocaleString() : ''
    },

    maxAnnualIncome () {
      return this.quote ? this.quote.incomeRiderMaxAnnualIncome.toLocaleString() : ''
    },

    maxTotalBenefit () {
      return this.quote ? this.quote.incomeRiderMaxTotalBenefit.toLocaleString() : ''
    },

    totalBenefitAmount () {
      return this.totalBenefitCalculation(this.termYears)
    },

    payoutAmount () {
      return this.totalBenefitCalculation(this.remainingYears)
    },

    threeYearPayout () {
      return this.totalBenefitCalculation(3)
    },

    isIncomeReplacement () {
      if (this.quote && this.quote.benefitType !== BenefitType.INCOME_REPLACEMENT) {
        return false
      } else {
        return true
      }
    },

    incomeType () {
      if (this.isIncomeReplacement) {
        return 'annual'
      } else {
        return 'household'
      }
    },

    familyType () {
      if (this.hasChild) {
        return 'family'
      } else {
        return 'loved ones'
      }
    },

    standardOfLivingText () {
      let text = ''
      if (this.isIncomeReplacement) {
        text = `If you die while your policy is active, your ${this.familyType} will continue
          to recieve your twice-a-month paychecks so their standard of living can remain the same.`
      } else {
        text = `If you die while your policy is active, our policy guarantees
          that your ${this.familyType} receives additional income to help them during the
          loss of your household contributions.`
      }
      return text
    },

    hasChild () {
      if (this.quote) return this.quote.hasChild
      return false
    },

    termYears () {
      if (this.quote && this.quote.termRecommended) {
        return Math.floor(parseInt(this.quote.termRecommended / 12))
      }
      return 0
    },

    termMonthsRemainder () {
      if (this.quote && this.quote.termRecommended) {
        return parseInt(this.quote.termRecommended) % 12
      }
      return 0
    },

    coveragePeriod () {
      let text = ''
      if (this.termMonthsRemainder === 0) {
        text = `${this.termYears} years`
      } else {
        text = `${this.termYears} years and ${this.termMonthsRemainder} ${this.termMonthsRemainder === 1 ? 'month' : 'months'}`
      }
      return text
    },

    yourPolicyText () {
      let text = ''
      switch (this.termReason) {
        case BenefitTermCode.CHILD_AGE:
          const childAge = this.childAge(this.termMonthsRemainder)
          text += `Since your youngest child would be ${childAge < 1 ? 'less than' : ''} ${Math.max(childAge, 1)} ${childAge <= 1 ? 'year' : 'years'} old
          and your policy protects your $${this.annualBenefit} ${this.incomeType} income until
          ${this.childAge(this.termMonthsRemainder) < 0 ? 'your youngest child turns' : 'they turn'} ${this.childAge(this.quote.termRecommended)},
          your family will get ${this.incomeString} for the next ${this.termYears} years.
          That's $${this.totalBenefitAmount} because your family would need many years of coverage.`
          break
        case BenefitTermCode.INSURED_AGE:
          text += `Your policy protects your $${this.annualBenefit} ${this.incomeType} income for ${this.termYears} years.
          That's $${this.totalBenefitAmount} because your ${this.familyType} would need many years of coverage until you
          would have reached retirement.`
          break
        default:
          text += `Your policy protects ${this.incomeAmountString} for ${this.termYears} years.
          That's $${this.totalBenefitAmount} because your ${this.familyType} would need your
          ${this.isIncomeReplacement ? '' : 'household'} income replaced for many more years.`
      }
      return text
    },

    untilRetirementAgeText () {
      return this.isIncomeReplacement
        ? `until your retirement at age ${this.insuredAge(this.quote.termRecommended)}`
        : `for ${this.termYears} years`
    },

    defaultUntilText () {
      return `for ${this.termYears} years`
    },

    untilChildAgeText () {
      return `until your youngest child turns ${this.childAge(this.quote.termRecommended)}`
    },

    childAgeMax () {
      if (this.childExtensionAvailable && this.quote && this.quote.childExtensionMaxAge !== undefined) {
        return this.quote.childExtensionMaxAge
      } else {
        return ''
      }
    },

    untilTermEndText () {
      if (this.termReason === BenefitTermCode.CHILD_AGE) {
        return this.untilChildAgeText
      } else {
        return ''
      }
    },

    increaseProtectionType () {
      if (this.isIncomeReplacement) {
        return 'If you get a raise'
      } else {
        return 'Inflation Protection'
      }
    },

    increaseProtectionText () {
      if (this.isIncomeReplacement) {
        return `Increase protection for ${this.premiumPer1kIncome}/month
               for each $1,000 of additional annual income.`
      } else {
        return `Each year you'll have the option to increase your protection
                by 2% for ${this.premiumPer1kIncome}/month for each $1,000 added.`
      }
    },

    increaseCoverageFinePrint () {
      if (this.isIncomeReplacement) {
        return `If you get a raise, simply provide proof of your income each year
              to increase your protection. You can increase your coverage instantly by
              the same percentage as your raise, as long as it doesn't exceed
              $${this.maxAnnualIncome} in income per year, and does not result in an
              income level where the sum of all future payments your family can get is
              over $${this.maxTotalBenefit}.`
      } else {
        return `Increase your protection by a 2% flat rate every year to help protect
                against rising costs due to annual inflation. You can increase your
                coverage up to $${this.maxAnnualIncome} per year but not more than $${this.maxTotalBenefit} in total
                benefits.`
      }
    },

    extendCoverageFinePrint () {
      return `If you have ${this.hasChild ? 'another' : 'a'} child within 5 years
              of getting a policy, you can instantly change your policy by providing
              proof of birth or adoption within 6 months. Based on your selection, you
              can extend your policy length so that coverage continues until whichever
              happens first: your youngest child is ${this.childAgeMax} years old, you
              reach the retirement age of 67, or the number of years left on your policy
              is ${this.maxYearsRemaining} years. If you would like to extend until your new child is a different
              age, please contact us.`
    },

    maxYearsRemaining () {
      return this.quote?.childExtensionMaxYearsRemaining
    },

    termReason () {
      if (this.quote && this.quote.termReason) {
        return this.quote.termReason.code
      }
      return BenefitTermCode.DEFAULT
    },

    termReasonText () {
      let text = ` until ${this.coveragePeriod} from your purchase`
      if (this.termReason) {
        switch (this.termReason) {
          case BenefitTermCode.CHILD_AGE:
            text = ` until your child turns ${this.childAge(this.quote.termRecommended)}`
            break
          case BenefitTermCode.INSURED_AGE:
            if (this.isIncomeReplacement) {
              text = ` until your retirement age of ${this.insuredAge(this.quote.termRecommended)}`
            }
            break
        }
      }
      return text
    },

    incomeString () {
      return this.isIncomeReplacement
        ? 'your income'
        : 'income'
    },

    incomeAmountString () {
      return this.isIncomeReplacement
        ? `your $${this.annualBenefit} income`
        : `$${this.annualBenefit}`
    },

    monthsUntilDeath () {
      return Math.floor(this.quote.termRecommended / 2)
    },

    yearsUntilDeath () {
      return Math.floor(this.monthsUntilDeath / 12)
    },

    whenYouDieAgeCalc () {
      let age = 0
      switch (this.termReason) {
        case BenefitTermCode.CHILD_AGE:
          age = this.childAge(this.monthsUntilDeath)
          break
        case BenefitTermCode.INSURED_AGE:
          age = this.insuredAge(this.monthsUntilDeath)
          break
        default:
          age = this.yearsUntilDeath
      }
      return age
    },

    whenYouDieText () {
      let text = ''
      switch (this.termReason) {
        case BenefitTermCode.CHILD_AGE:
          text = `when your child turns ${this.whenYouDieAgeCalc}`
          break
        default:
          text = 'about halfway through your policy'
      }
      return text
    },

    remainingYears () {
      return this.termYears - this.yearsUntilDeath
    },

    midwayReplacementText () {
      if (this.termReason === BenefitTermCode.CHILD_AGE) {
        return `Since your child is older, that's `
      } else {
        return `That's`
      }
    },

    endOfPolicyText () {
      let text = this.isIncomeReplacement
        ? ' you have been earning income for many years, so'
        : ''
      switch (this.termReason) {
        case BenefitTermCode.CHILD_AGE:
          text = ' your youngest child is almost at your chosen age of financial independence, so'
          break
        case BenefitTermCode.INSURED_AGE:
          if (this.isIncomeReplacement) {
            text = ' you are almost at your retirement, so'
          }
      }
      return text
    },

    ensureText () {
      const beneficiary = this.hasChild ? ' your children\'s ' : ' their'
      return `to ensure ${beneficiary} secure future`
    },

    premiumPer1kIncome () {
      return this.quote
        ? `$${(Math.ceil(this.quote.premiumPer1kIncome * 100) / 100)}`
        : ''
    },

    premiumPer1YearExtension () {
      return this.quote
        ? `$${(Math.ceil(this.quote.premiumPer1YearExtension * 100) / 100)}`
        : ''
    },

    premium () {
      return this.quote ? this.quote.premium : ''
    }

  },

  watch: {
    show (newVal) {
      this.isShowing = newVal
    }
  },

  methods: {
    onClose () {
      this.hideOverlay()
      this.$emit('close')
      this.showPage = 0
    },
    showOverlay (videoId) {
      this.isShowingOverlay = true
      this.overlayVideoId = videoId
    },

    hideOverlay () {
      this.overlayVideoId = undefined
      this.isShowingOverlay = false
    },

    showNext () {
      if (this.showPage <= 3) {
        this.showPage += 1
      }
    },

    showPrev () {
      if (this.showPage >= 1) {
        this.showPage -= 1
      }
    },

    totalBenefitCalculation (years = null) {
      const totalYears = parseInt(years) || parseInt(this.termYears)
      let total = 0
      if (this.quote && this.quote.annualBenefit) {
        total += (parseInt(this.quote.annualBenefit) * parseInt(totalYears)) + 10000
      }
      return total.toLocaleString()
    },

    childAge (inNumMonths = 0) {
      if (this.quote && this.quote.hasChild) {
        const childAgeInMonths = moment().diff(moment(this.answers[QK.YOUNGEST_CHILDS_BIRTHDAY]), 'months')
        return Math.floor((childAgeInMonths + inNumMonths) / 12)
      }
      return 0
    },

    insuredAge (inNumMonths = 0) {
      if (this.quote) {
        const insuredAgeInMonths = moment().diff(moment(this.answers[QK.DATE_OF_BIRTH]), 'months')
        return Math.floor((insuredAgeInMonths + inNumMonths) / 12)
      }
      return 0
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/media-queries";

.fine-print {
  text-decoration: underline;
  li {
    margin: 0.2rem auto;
  }
}

.modal-text {
  min-height: auto;
}

.family-protection {
  height: 90%;
  @include md {
    margin-top: 2rem;
  }

  &__link {
    color: $c-action;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__content {
    // min-height: 400px;
    margin-bottom: 1rem;
  }

  &__timeline {
    display: block;
    margin: 0 auto;
  }

  &__calc {
    text-align: center;
    font-weight: 600;
  }
}

.video-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 40px 0 40px;
  .close {
    margin-top: 15px;
    align-self: flex-end;
    margin-right: 5px;
    a {
      color: #fff !important;
    }
  }
}
</style>
