<template>
  <div :class="this.itemClass">
    <div :style="contentStyle" class="navigation-item__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation-item',
  props: {
    noOp: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String
    },
    semiBold: {
      type: Boolean
    }
  },
  computed: {
    itemClass () {
      var classNames = 'navigation-item'
      if (this.noOp) {
        classNames += ' navigation-item__no-op'
      }
      if (this.variant) {
        classNames += ` navigation-item__${this.variant}`
      }
      if (this.semiBold) {
        classNames += ` navigation-item__semi-bold`
      }
      return classNames
    },
    contentStyle () {
      const styles = {}
      if (this.$gtSm) {
        styles['min-width'] = '80px'
        styles['margin'] = '0 10px'
      }
      return styles
    }
  }
}
</script>

<style lang="scss">

@import 'src/assets/styles/colors';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/media-queries';

.navigation-item {
  height: 100%;
  color: $c-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include md {
    &:hover {
      color: $c-lightPurple;
    }
  }
  &__semi-bold {
    font-weight: 600;
  }
  &__no-op {
    cursor: unset;
    color: #333;
    &:hover {
      color: unset;
    }
  }
  &__primary {
    color: $c-action;
    font-weight: 600;
    @include md {
      margin-right: -10px;
      &:hover {
        color: $c-action;
      }
    }
  }
  &__white {
    color: #fff;
    @include md {
      &:hover {
        color: #ddd;
      }
    }
  }
}

.navigation-item__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  & > a {
    display: block;
    text-decoration: none;
    color: unset;
    &:hover {
      color: unset;
    }
  }
}

</style>
