const searchModule = {
  namespaced: true,
  state: {
    requestIdCache: {}
  },
  getters: {
    requestIdCache: (state) => state.requestIdCache,
    lastRequestIdForKey: (state) => (key) => state.requestIdCache[key]
  },

  actions: {
    updateRequestIdForKey ({ commit }, payload) {
      commit('UPDATE_REQUEST_ID_FOR_KEY', payload)
    }
  },
  mutations: {
    UPDATE_REQUEST_ID_FOR_KEY (state, payload) {
      state.requestIdCache[payload.key] = payload.requestId
    }
  }
}

export default searchModule
