<template>
  <div class="interview-health-history">
    <FlowWrapper
      :progress="6/8"
      :can-move-to-next="isValid"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      :on-next="onNext"
      :save-on-back="isValid"
    >
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          Now, let's talk about your health.
        </div>
      </template>
      <template v-slot:content>
      <v-form>
        <div class="mq-grid">
          <div class="cols-10">
            <Question
              @input="handleChange"
              v-model="values[QK.ANNUAL_CHECKUP]"
              :question="questionsMap[QK.ANNUAL_CHECKUP]"
              :error="errors[QK.ANNUAL_CHECKUP]"
              hide-details
              type="yesno"
            />
          </div>
          <div class="cols-10">
            <Question
              @input="handleInput"
              v-model="values[QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE]"
              :question="questionsMap[QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE]"
              :error="errors[QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE]"
              hide-details
              type="yesno"
            />

          </div>
          <div class="cols-10" v-if="hasFamilyHealth">
            <InterviewFamilyHealth
              :vals="getValues(index)"
              @memberUpdated="updateParentValues"
              @removeFamilyMember="removeFamilyMember"
              v-for="(component, index) in components"
              :key="component.id"
              :index="index"
              :is="component.comp"
              :id="component.id"
              :last="index === components.length -1 ? 'last' : ''"
            />
            <div class="cols-10 add-button" v-if="components.length <= 7">
              <button type="button" @click="addFamilyMember">Add member +</button>
            </div>
          </div>

          <div class="cols-10">
            <Question
              v-model="values[QK.PENDING_TEST_OR_PROCEDURE]"
              :question="questionsMap[QK.PENDING_TEST_OR_PROCEDURE]"
              :error="errors[QK.PENDING_TEST_OR_PROCEDURE]"
              hide-details
              type="yesno"
              @input="onPendingTestChanged"
            />
          </div>

          <template v-if="hasPendingTest">
            <div class="cols-10">
              <Question
                v-model="values[QK.PENDING_TEST_SERIOUS_OR_ROUTINE]"
                :question="questionsMap[QK.PENDING_TEST_SERIOUS_OR_ROUTINE]"
                :error="errors[QK.PENDING_TEST_SERIOUS_OR_ROUTINE]"
                hide-details
                @input="onPendingTestSeriousChanged"
              />
            </div>

            <template v-if="pendingTestSerious">
              <div class="cols-10">
                <Question
                  v-model="values[QK.WHEN_WILL_YOU_HAVE_THE_RESULTS]"
                  :question="questionsMap[QK.WHEN_WILL_YOU_HAVE_THE_RESULTS]"
                  :error="errors[QK.WHEN_WILL_YOU_HAVE_THE_RESULTS]"
                  hide-details
                  :input-width="smElse('100%', '180px')"
                  @input="validateDate(QK.WHEN_WILL_YOU_HAVE_THE_RESULTS)"
                  :validate-on-blur="false"
                />
              </div>
            </template>
          </template>
        </div>
      </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'
import { QuestionKey as QK } from '@/api/Interview'
import moment from 'moment'
import InterviewFamilyHealth from '@/components/interview/InterviewFamilyHealth'
import DFButton from '@/components/DFButton'

export default {
  name: 'InterviewHealthHistory',
  components: { DFButton, InterviewFamilyHealth, FlowWrapper, Question },

  data () {
    return {
      pendingTestSerious: false,
      isValid: false,
      STEP_NAME: 'health',
      QK: QK,
      values: {},
      errors: {},
      dateIsValid: false,
      components: [],
      componentArr: [
        { comp: InterviewFamilyHealth, id: 0 },
        { comp: InterviewFamilyHealth, id: 1 },
        { comp: InterviewFamilyHealth, id: 2 },
        { comp: InterviewFamilyHealth, id: 3 },
        { comp: InterviewFamilyHealth, id: 4 },
        { comp: InterviewFamilyHealth, id: 5 },
        { comp: InterviewFamilyHealth, id: 6 },
        { comp: InterviewFamilyHealth, id: 7 }

      ],
      activeQuestions: []
    }
  },

  computed: {
    ...mapGetters('interview', [
      'questionsMap',
      'interviewID',
      'interview',
      'mapToAnswers',
      'parseBool',
      'validateKeys',
      'answersForKeys'
    ]),
    answers () {
      return this.$store.state.interview.answers
    },
    questionKeys () {
      return [
        QK.ANNUAL_CHECKUP,
        QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE,
        QK.PENDING_TEST_OR_PROCEDURE,
        QK.PENDING_TEST_SERIOUS_OR_ROUTINE,
        QK.WHEN_WILL_YOU_HAVE_THE_RESULTS,
        QK.FAMILY_HISTORY
      ]
    },

    answerValues () {
      return this.values
    },
    hasPendingTest () {
      return this.parseBool(this.values[QK.PENDING_TEST_OR_PROCEDURE])
    },

    hasFamilyHealth () {
      const question = this.questionsMap[QK.FAMILY_HISTORY]
      if (!question) {
        return false
      }
      return question?.required && this.parseBool(this.values[QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE])
    }
  },

  methods: {
    ...mapActions('interview', ['updateInterviewAnswers', 'checkFollowupSectionsActivated', 'validateInterviewAnswers']),
    hasErrors () {
      let hasErrors = false
      for (let key of this.activeKeys) {
        if (this.errors[key] && this.errors[key] !== null) {
          hasErrors = true
          break
        }
      }
      return hasErrors
    },
    getActiveKeys () {
      let activeKeys = [
        QK.ANNUAL_CHECKUP,
        QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE,
        QK.PENDING_TEST_OR_PROCEDURE
      ]

      if (this.hasFamilyHealth) {
        activeKeys = activeKeys.concat([QK.FAMILY_HISTORY])
      }
      if (this.hasPendingTest) {
        activeKeys = activeKeys.concat([QK.PENDING_TEST_SERIOUS_OR_ROUTINE])
      }

      if (this.hasPendingTest && this.pendingTestSerious) activeKeys = activeKeys.concat([QK.WHEN_WILL_YOU_HAVE_THE_RESULTS])
      this.activeKeys = activeKeys
    },
    hasValidFormData () {
      this.getActiveKeys()
      let isValidHistory = true
      let isValidDate = true
      let isValidData = true

      if (this.values[QK.FAMILY_HISTORY] && this.values[QK.FAMILY_HISTORY].length > 0) {
        this.values[QK.FAMILY_HISTORY].forEach((item) => {
          if (item.diagnoses === undefined || item.relationship === undefined || item.severity === undefined) {
            isValidHistory = false
          }
        })
      }
      if (this.pendingTestSerious) {
        if (!this.dateIsValid) isValidDate = false
      }
      isValidData = !this.hasErrors() && this.validateKeys(this.activeKeys, this.values)
      return isValidData && isValidDate && isValidHistory
    },
    getValues (index) {
      if (this.values[QK.FAMILY_HISTORY]) {
        return this.values[QK.FAMILY_HISTORY].length > 0 && this.values[QK.FAMILY_HISTORY][index] !== undefined && this.values[QK.FAMILY_HISTORY][index] !== '' ? this.values[QK.FAMILY_HISTORY][index] : {}
      } else {
        this.values[QK.FAMILY_HISTORY] = []
      }
    },
    createComponentArray () {
      if (this.values[QK.FAMILY_HISTORY].length > 0) {
        this.values[QK.FAMILY_HISTORY].forEach((item, index) => {
          this.components.push(this.componentArr[index])
        })
      }
    },
    updateParentValues (val, id) {
      this.activeId = id
      this.values[QK.FAMILY_HISTORY][id] = val
      this.isValid = this.hasValidFormData()
    },
    handleInput () {
      if (this.parseBool(this.values[QK.PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE])) {
        this.addFamilyMember()
        this.activeId = 0
      } else {
        this.components = []
        this.$set(this.values, QK.FAMILY_HISTORY, [])
      }
      this.isValid = this.hasValidFormData()
    },
    handleChange () {
      this.isValid = this.hasValidFormData()
    },
    addFamilyMember () {
      if (this.components.length <= 7) {
        for (let i = 0; i < this.componentArr.length; i++) {
          if (!this.itemExists(this.componentArr[i])) {
            this.components.push(this.componentArr[i])
            this.$set(this.components, i, this.componentArr[i])
            break
          }
        }
      }
    },
    itemExists (item) {
      return this.components.some(function (el) {
        return el.id === item.id
      })
    },
    removeFamilyMember (index, id) {
      const ind = this.components.findIndex(f => f.id === id)
      this.components.splice(ind, 1)
      this.$delete(this.values[QK.FAMILY_HISTORY], ind)
      this.isValid = this.hasValidFormData()
    },
    onNext () {
      this.checkFollowupSectionsActivated({ id: this.interviewID })
    },

    onPendingTestChanged () {
      this.$set(this.values, QK.PENDING_TEST_SERIOUS_OR_ROUTINE, undefined)
      this.$set(this.values, QK.WHEN_WILL_YOU_HAVE_THE_RESULTS, undefined)
      this.setPendingTestSerious()
      this.handleChange()
    },

    onPendingTestSeriousChanged () {
      this.setPendingTestSerious()
      if (!this.pendingTestSerious) {
        this.$set(this.values, QK.WHEN_WILL_YOU_HAVE_THE_RESULTS, undefined)
      }
      this.handleChange()
    },

    async validateDate (key) {
      if (this.values[key] === undefined) {
        return
      }
      this.$delete(this.errors, key)
      if (!moment(this.values[key], 'YYYY-MM').isValid()) {
        this.dateIsValid = false
        return
      }
      const { success } = await this.validateAnswer(key, this.values[key])
      this.dateIsValid = success
      this.handleChange()
    },

    async validateAnswer (key, value) {
      if (!value) {
        return { success: false, result: undefined }
      }

      const answer = {
        questionKey: key,
        values: [value]
      }

      const genRequestId = Math.random().toString(36).substr(2)
      const { results } = await this.validateInterviewAnswers({
        id: this.interviewID,
        answers: [answer],
        context: { requestId: genRequestId }
      })

      if (results && results.length > 0) {
        const result = results[0]
        if (!result.success) {
          this.$set(this.errors, key, result.message)
        } else {
          this.$delete(this.errors, key)
        }
        return { success: result.success, result }
      }
      return { success: false, result: undefined }
    },
    removeTypename () {
      if (this.values[QK.FAMILY_HISTORY]) {
        this.values[QK.FAMILY_HISTORY].forEach((item, index) => {
          delete item.__typename
        })
      }
    },
    isDirty (val, key) {
      if (key && this.errors[key]) this.errors[key] = null
    },
    setPendingTestSerious () {
      this.pendingTestSerious = this.values[QK.PENDING_TEST_SERIOUS_OR_ROUTINE] === 'NEW' || this.values[QK.PENDING_TEST_SERIOUS_OR_ROUTINE] === 'EXISTING'
    },
    setupValues () {
      this.values[QK.FAMILY_HISTORY] = this.questionsMap[QK.FAMILY_HISTORY].active === true ? this.questionsMap[QK.FAMILY_HISTORY].answer.familyHistory : []
      if (this.answers[QK.PENDING_TEST_SERIOUS_OR_ROUTINE]) {
        this.values[QK.PENDING_TEST_SERIOUS_OR_ROUTINE] = this.answers[QK.PENDING_TEST_SERIOUS_OR_ROUTINE]
        if (this.answers[QK.WHEN_WILL_YOU_HAVE_THE_RESULTS]) {
          this.values[QK.WHEN_WILL_YOU_HAVE_THE_RESULTS] = this.answers[QK.WHEN_WILL_YOU_HAVE_THE_RESULTS]
        }
      }
      this.setPendingTestSerious()
    }
  },
  created () {
    this.getActiveKeys()
    this.values = this.answersForKeys(this.activeKeys) || {}
    this.setupValues()
    this.isValid = this.hasValidFormData()
    this.validateDate(QK.WHEN_WILL_YOU_HAVE_THE_RESULTS)
    if (this.values[QK.FAMILY_HISTORY] && this.values[QK.FAMILY_HISTORY].length > 0) {
      this.removeTypename()
      this.createComponentArray()
    }
  }
}
</script>
<style lang="scss" scoped>

@import "src/assets/styles/colors";
.add-button {
  margin: 0 0 10px 0;
  button {
    color: $c-red;
  }
}
</style>
