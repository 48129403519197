<template>
  <v-app light>
    <template v-if="hasKillMode && !hasKillOverride">
      <PreLaunch />
    </template>
    <template v-else>
      <router-view :key="$route.path" />
      <v-bottom-sheet v-model="isShowingDebug" v-if="isDebugEnabled" v-hotkey="keymap" persistent>
        <DebuggerView v-model="isShowingDebug" />
      </v-bottom-sheet>
    </template>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { defaultMeta } from '@/config/meta.config'
import DebuggerView from '@/components/debug/DebuggerView.vue'
import PreLaunch from '@/views/kill/PreLaunch.vue'
import { ssKeyKillOverride } from '@/config/app.config.js'

export default {
  name: 'App',
  components: { PreLaunch, DebuggerView },
  metaInfo: {
    ...defaultMeta
  },
  data() {
    return {
      isShowingDebug: false,
      authPoll: null
    }
  },
  computed: {
    ...mapGetters('interview', ['interviewID', 'answersForKeys', 'accountID']),
    isDebugEnabled() {
      return process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENABLE_DEV_TOOLS
    },
    hasKillMode() {
      return process.env.VUE_APP_KILL_MODE === 'preview'
    },
    hasKillOverride() {
      const overrideValue = window.sessionStorage.getItem(ssKeyKillOverride)
      return overrideValue && overrideValue === 'yes'
    },
    killMode() {
      return process.env.VUE_APP_KILL_MODE
    },
    keymap() {
      if (!this.isDebugEnabled) {
        return {}
      }
      return {
        'ctrl+1': this.toggleDebug,
        'ctrl+/': this.toggleDebug,
        'ctrl+shift+/': this.toggleDebug,
        'ctrl+\\': this.toggleDebug,
        'ctrl+shift+\\': this.toggleDebug
      }
    }
  },
  methods: {
    toggleDebug() {
      this.isShowingDebug = !this.isShowingDebug
    }
  },

  beforeDestroy() {
    clearInterval(this.authPoll)
  },

  beforeCreate() {
    this.$store.dispatch('content/reloadContentCache')
  },

  created() {
    if (!window.youTubeRegistered) {
      const scriptTag = document.createElement('script')
      scriptTag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag)
      window.youTubeRegistered = true
    }
  },
  mounted() {
    if (process.env.VUE_APP_SEGMENT_KEY) {
      let analyticsScript = document.createElement('script')
      analyticsScript.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://prod-segment-cdn.dayforward.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.VUE_APP_SEGMENT_KEY}";analytics.SNIPPET_VERSION="4.13.2";
        analytics.load("${process.env.VUE_APP_SEGMENT_KEY}");
      }}();`

      document.head.appendChild(analyticsScript)
    }

    if (process.env.VUE_APP_TABOOLA_ID) {
      window._tfa = window._tfa || []

      const taboolaScript = document.createElement('script')

      taboolaScript.async = true
      taboolaScript.src = `//cdn.taboola.com/libtrc/unip/${process.env.VUE_APP_TABOOLA_ID}/tfa.js`
      taboolaScript.id = 'tb_tfa_script'

      document.head.appendChild(taboolaScript)
    }

    window.addEventListener('click', async (event) => {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode
      // handle only links that do not reference external resources
      if (
        target &&
        target.matches("a:not([href*='://'])") &&
        target.matches("a:not([href*='tel:'])") &&
        target.matches("a:not([href*='mailto:'])") &&
        target.href
      ) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        // don't handle same page links/anchors
        const url = new URL(target.href)
        const to = url.pathname

        if (target.href.includes('#chat')) {
          event.preventDefault()
          return
        }

        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault()
          await this.$router.push({
            path: to,
            hash: url.hash
          })
        }
      }
    })
  }
}
</script>

<style lang="scss">
@import 'src/assets/styles/colors';

#app {
  color: $c-primary;
  background-color: var(--v-background-base);
}

#launch-chat {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  height: 60px;
  width: 60px;
  background-color: $c-red;
  border-radius: 30px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  transition: 0.3s ease;
}

#launch-chat:hover {
  transform: scale(1.1);
}

.v-application {
  p {
    margin: 0;
  }
}
</style>
