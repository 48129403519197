import Colors from '@/assets/styles/_colors.scss'
import InterviewInitialStep from '@/views/interview/InterviewInitialStep'
import InterviewBasicInfoStep from '@/views/interview/InterviewBasicInfoStep'
import InterviewIncomeStep from '@/views/interview/InterviewIncomeStep'
import InterviewDrivingHistory from '@/views/interview/InterviewDrivingHistory'
import InterviewHealthHistory from '@/views/interview/InterviewHealthHistory'
import InterviewHealthConditions from '@/views/interview/InterviewHealthConditions'
import InterviewSmoking from '@/views/interview/InterviewSmoking'
import InterviewSubmission from '@/views/interview/InterviewSubmission'
import InterviewConfirmIdentity from '@/views/interview/InterviewConfirmIdentity'
import InterviewCanWeTalk from '@/views/interview/InterviewCanWeTalk'
import InterviewPayment from '@/views/interview/InterviewPayment'
import InterviewPaymentUpdate from '@/views/interview/InterviewPaymentUpdate'
import InterviewBeneficiary from '@/views/interview/InterviewBeneficiary'
import InterviewAttestations from '@/views/interview/InterviewAttestations'
import InterviewEsign from '@/views/interview/InterviewEsign'
import InterviewReviewPending from '@/views/interview/InterviewReviewPending'
import InterviewConfirmInformation from '@/views/interview/InterviewConfirmInformation'
import InterviewCongrats from '@/views/interview/InterviewCongrats'
import { RouteCategory, RouteName } from '@/router/routes/constants.js'
import { noNativeRouting, withDefaultMeta } from '@/router/routes/meta'
import Interview from '@/views/Interview'
import InterviewLocation from '@/views/interview/InterviewLocation.vue'
import Redirect from '@/components/navigation/Redirect.vue'
import InterviewQuote from '@/views/interview/InterviewQuote'
import InterviewCustomizePolicy from '@/views/interview/InterviewCustomizePolicy'

export default [
  {
    path: '/get-started',
    name: RouteName.GET_STARTED,
    component: Redirect,
    meta: {
      requiresAuth: false,
      doNotTrack: true
    }
  },
  {
    path: '/interview*',
    component: Interview,
    children: withDefaultMeta(noNativeRouting, [
      {
        path: '',
        name: RouteName.INTERVIEW_INITIAL,
        component: InterviewInitialStep,
        meta: {
          category: RouteCategory.INITIAL,
          canEdit: true,
          editName: 'Name, Phone & Email',
          header: {
            title: 'Apply for coverage in minutes!',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 1,
            previousStep: RouteName.GET_STARTED,
            nextStep: RouteName.INTERVIEW_LOCATION_INFO
          }
        }
      },
      {
        path: 'location-info',
        name: RouteName.INTERVIEW_LOCATION_INFO,
        component: InterviewLocation,
        meta: {
          category: RouteCategory.STEP_TWO_AUTH,
          canEdit: true,
          editName: 'Location',
          header: {
            title: 'Step: 1 of 8',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 2,
            previousStep: RouteName.INTERVIEW_INITIAL,
            nextStep: RouteName.INTERVIEW_BASIC_INFO
          }
        }
      },
      {
        path: 'basic-info',
        name: RouteName.INTERVIEW_BASIC_INFO,
        component: InterviewBasicInfoStep,
        meta: {
          category: RouteCategory.INITIAL,
          canEdit: true,
          editName: 'Basic info',
          header: {
            title: 'Step: 2 of 8',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 3,
            previousStep: RouteName.INTERVIEW_LOCATION_INFO,
            nextStep: RouteName.INTERVIEW_INCOME
          }
        }
      },
      {
        path: 'income',
        name: RouteName.INTERVIEW_INCOME,
        component: InterviewIncomeStep,
        meta: {
          category: RouteCategory.INITIAL,
          canEdit: true,
          editName: 'Income',
          header: {
            title: 'Step: 3 of 8',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 4,
            previousStep: RouteName.INTERVIEW_BASIC_INFO,
            nextStep: RouteName.INTERVIEW_DRIVING_HISTORY
          }
        }
      },
      {
        path: 'driving-history',
        name: RouteName.INTERVIEW_DRIVING_HISTORY,
        component: InterviewDrivingHistory,
        meta: {
          category: RouteCategory.INITIAL,
          canEdit: true,
          editName: 'Driving history',
          header: {
            title: 'Step: 4 of 8',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 5,
            previousStep: RouteName.INTERVIEW_INCOME,
            nextStep: RouteName.INTERVIEW_SMOKING
          }
        }
      },
      {
        path: 'smoking',
        name: RouteName.INTERVIEW_SMOKING,
        component: InterviewSmoking,
        meta: {
          category: RouteCategory.INITIAL,
          canEdit: true,
          editName: 'Smoking',
          header: {
            title: 'Step: 5 of 8',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 6,
            previousStep: RouteName.INTERVIEW_DRIVING_HISTORY,
            nextStep: RouteName.INTERVIEW_HEALTH_HISTORY
          }
        }
      },
      {
        path: 'health/history',
        name: RouteName.INTERVIEW_HEALTH_HISTORY,
        component: InterviewHealthHistory,
        meta: {
          category: RouteCategory.INITIAL,
          canEdit: true,
          editName: 'Health history',
          header: {
            title: 'Step: 6 of 8',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 7,
            previousStep: RouteName.INTERVIEW_SMOKING,
            nextStep: RouteName.INTERVIEW_HEALTH_CONDITIONS
          }
        }
      },
      {
        path: 'health/conditions',
        name: RouteName.INTERVIEW_HEALTH_CONDITIONS,
        component: InterviewHealthConditions,
        meta: {
          category: RouteCategory.INITIAL,
          canEdit: true,
          editName: 'Health conditions',
          header: {
            title: 'Step: 7 of 8',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 8,
            previousStep: RouteName.INTERVIEW_HEALTH_HISTORY,
            nextStep: RouteName.INTERVIEW_BENEFICIARY
          }
        }
      },
      {
        path: 'beneficiary',
        name: RouteName.INTERVIEW_BENEFICIARY,
        component: InterviewBeneficiary,
        meta: {
          category: RouteCategory.INITIAL,
          header: {
            title: 'Step 8 of 8',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 9,
            previousStep: RouteName.INTERVIEW_HEALTH_CONDITIONS,
            nextStep: RouteName.INTERVIEW_ATTESTATIONS
          }
        }
      },
      {
        path: 'attestations',
        name: RouteName.INTERVIEW_ATTESTATIONS,
        component: InterviewAttestations,
        meta: {
          category: RouteCategory.INITIAL,
          header: {
            title: 'Final Questions',
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 10,
            previousStep: RouteName.INTERVIEW_BENEFICIARY,
            nextStep: RouteName.INTERVIEW_FINAL_QUOTE
          }
        }
      },
      {
        path: 'confirm-info-prequote',
        name: RouteName.INTERVIEW_CONFIRM_INFO_PREQUOTE,
        component: InterviewConfirmInformation,
        props: true,
        meta: {
          category: RouteCategory.INITIAL,
          requiresAuth: false,
          header: {
            backgroundColor: Colors.red,
            title: 'Final Questions'
          },
          content: {
            title: 'We’re having trouble verifying your identity.',
            subtitle: 'Please make sure all of the information below is correct.'
          },
          interview: {
            previousStep: RouteName.INTERVIEW_ATTESTATIONS,
            nextStep: RouteName.INTERVIEW_FINAL_QUOTE
          }
        }
      },
      {
        path: 'can-we-talk',
        name: RouteName.INTERVIEW_CAN_WE_TALK,
        component: InterviewCanWeTalk,
        props: true,
        meta: {
          requiresAuth: false,
          header: {
            backgroundColor: Colors.red,
            title: 'Finalize price.'
          },
          content: {
            title: "Let's hop on a quick call!",
            subtitle: `Looks like we need to talk to you to complete your application—we're ready to chat when you are!
            Give us a call at 1-888-424-0228 or hit the question mark below to chat M-F 9am-5pm CT. If we don't hear from
            you first, we'll be sure to reach out in the next 24 hours.`
          }
        }
      },
      {
        path: 'lets-chat',
        name: RouteName.INTERVIEW_LETS_CHAT,
        component: InterviewCanWeTalk,
        props: true,
        meta: {
          requiresAuth: false,
          header: {
            backgroundColor: Colors.red,
            title: 'Let’s talk.'
          },
          content: {
            title: 'You may already have an account with us.',
            subtitle: `Looks like we need to talk to you to complete your
            application—we're ready to chat when you are! Call 1-888-434-0228
            or hit the chat button below M-F 9am-5pm CT. After hours?
            Contact us and we'll get back to you the next business day.`
          }
        }
      },
      {
        path: '/customize-quote',
        name: RouteName.CUSTOMIZE_QUOTE,
        component: InterviewCustomizePolicy,
        meta: {
          requiresAuth: false
        }
      },
      {
        path: 'final-quote',
        name: RouteName.INTERVIEW_FINAL_QUOTE,
        component: InterviewQuote,
        props: true,
        meta: {
          category: RouteCategory.QUOTE,
          requiresAuth: false,
          header: {
            backgroundColor: Colors.red,
            textColor: '#fff',
            hideProgress: true,
            hideNavigation: true
          },
          interview: {
            step: 11,
            previousStep: RouteName.INTERVIEW_ATTESTATIONS,
            nextStep: RouteName.INTERVIEW_CONFIRM_IDENTITY
          }
        }
      },

      {
        path: 'confirm-identity',
        name: RouteName.INTERVIEW_CONFIRM_IDENTITY,
        component: InterviewConfirmIdentity,
        meta: {
          category: RouteCategory.CHECKOUT,
          header: {
            hideNavigation: true
          },
          requiresAuth: false,
          interview: {
            step: 12,
            previousStep: RouteName.INTERVIEW_FINAL_QUOTE,
            nextStep: RouteName.INTERVIEW_PAYMENT
          }
        }
      },
      {
        path: 'payment',
        name: RouteName.INTERVIEW_PAYMENT,
        component: InterviewPayment,
        meta: {
          category: RouteCategory.CHECKOUT,
          header: {
            hideNavigation: true
          },
          requiresAuth: true,
          interview: {
            step: 13,
            previousStep: RouteName.INTERVIEW_CONFIRM_IDENTITY,
            nextStep: RouteName.INTERVIEW_SUBMISSION
          }
        }
      },
      {
        path: 'submission',
        name: RouteName.INTERVIEW_SUBMISSION,
        component: InterviewSubmission,
        meta: {
          category: RouteCategory.CHECKOUT,
          header: {
            hideNavigation: true,
            hideSave: true
          },
          requiresAuth: true,
          interview: {
            step: 14,
            previousStep: RouteName.INTERVIEW_PAYMENT,
            nextStep: RouteName.INTERVIEW_SIGN
          }
        }
      },
      {
        path: 'sign',
        name: RouteName.INTERVIEW_SIGN,
        component: InterviewEsign,
        meta: {
          category: RouteCategory.CHECKOUT,
          canEdit: true,
          editName: 'Sign',
          header: {
            hideNavigation: true
          },
          requiresAuth: true,
          interview: {
            step: 15,
            previousStep: RouteName.INTERVIEW_CONFIRM_IDENTITY,
            nextStep: RouteName.INTERVIEW_REVIEW_PENDING
          }
        }
      },
      {
        path: 'update-payment',
        name: RouteName.INTERVIEW_UPDATE_PAYMENT,
        component: InterviewPaymentUpdate,
        meta: {
          category: RouteCategory.CHECKOUT,
          header: {
            backgroundColor: Colors.red,
            textColor: '#fff',
            hideSave: true
          },
          requiresAuth: true,
          interview: {
            step: 16,
            previousStep: RouteName.INTERVIEW_ATTESTATIONS,
            nextStep: RouteName.INTERVIEW_REVIEW_PENDING
          }
        }
      },
      {
        path: 'thanks',
        name: RouteName.INTERVIEW_REVIEW_PENDING,
        component: InterviewReviewPending,
        meta: {
          header: {
            title: 'Thank you!',
            backgroundColor: Colors.red,
            textColor: '#fff',
            hideProgress: true,
            hideNavigation: true,
            hideSave: true
          },
          requiresAuth: true,
          interview: {
            step: 17,
            previousStep: RouteName.INTERVIEW_SUBMISSION,
            nextStep: ''
          }
        }
      },
      {
        path: 'congrats',
        name: RouteName.INTERVIEW_CONGRATS,
        component: InterviewCongrats,
        meta: {
          header: {
            title: 'Congratulations!',
            backgroundColor: Colors.red,
            textColor: '#fff',
            hideProgress: true,
            hideNavigation: true,
            hideSave: true
          },
          requiresAuth: true,
          interview: {
            step: 17,
            previousStep: RouteName.INTERVIEW_SUBMISSION,
            nextStep: ''
          }
        }
      }
    ])
  }
]
