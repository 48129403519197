<template>
  <div>
    <div class="bm-1r">
      <DFButton elevation="0" @click="searchClareto" variant="small"> Search Clareto </DFButton>
      <span class="admin-vendors__clareto"><span class="label">Clareto ID: </span>{{ claretoSearchID }}</span>
    </div>
    <div class="bm-1r">
      <p class="admin-vendors__error">{{ claretoError }}</p>
    </div>
    <div class="interview-wrapper admin-vendors" v-if="orders && !isEmpty">
      <div class="bm-1r">
        <DFButton elevation="0" @click="downloadAuth" variant="small"> Download Authorization </DFButton>
      </div>

      <div class="admin-vendors__intergrations-wrapper" v-if="instantId !== null || instantIdError !== undefined">
        <div class="admin-vendors__integration">
          <h3>Instant Id</h3>
          <div v-if="instantId !== null">
            <AdminVendorDetail
              :id="instantId.orderId"
              :created="instantId.created"
              :status="instantId.status"
              :updated="instantId.updated"
            />
            <p v-if="'cvi' in instantId"><strong>CVI:</strong> {{ instantId.cvi }}</p>
            <p v-if="'birthDate' in instantId"><strong>DOB:</strong> {{ instantId.birthDate }}</p>
            <p v-if="'quoteback' in instantId"><strong>Quoteback:</strong> {{ instantId.quoteback }}</p>
            <a v-if="'reportURN' in instantId && instantId.reportURN !== ''" @click="downloadPDF(instantId.reportURN)"
              >Download PDF</a
            >
          </div>
          <p><a @click="refreshInstantID">Refresh IID</a></p>
          <p class="admin-vendors__error" v-if="instantIdError !== ''">{{ instantIdError }}</p>
          <div>
            <span>
              <span><a @click="addManualIDFlag">Set Manually Verified ID </a></span>
              <div v-if="flagSetBy">
                <span class="admin-vendors__inset"> Verified by: </span><span>{{ flagSetBy }}</span>
              </div>
              <div v-if="flagCreated">
                <span class="admin-vendors__inset"> ID Verified: </span
                ><span>{{ moment(flagCreated).format('L LTS') }}</span>
              </div>
            </span>
            <p class="admin-vendors__error" v-if="manualIDFlagError !== ''">{{ manualIDFlagError }}</p>
          </div>
        </div>
        <div class="admin-vendors__integration" v-if="mvr !== null || mvrError !== undefined">
          <h3>MVR</h3>
          <div v-if="mvr !== null">
            <AdminVendorDetail :id="mvr.orderId" :created="mvr.created" :status="mvr.status" :updated="mvr.updated" />
            <p v-if="'isHit' in mvr"><strong>Is Hit:</strong> {{ mvr.isHit }}</p>
            <p v-if="'quoteback' in mvr"><strong>Quoteback:</strong> {{ mvr.quoteback }}</p>
            <a v-if="'reportURN' in mvr && mvr.reportURN !== ''" @click="downloadPDF(mvr.reportURN)">Download PDF</a>
          </div>
          <p><a @click="refreshMVR">Refresh MVR</a></p>
          <p class="admin-vendors__error" v-if="mvrError">{{ mvrError }}</p>
        </div>
        <div class="admin-vendors__integration" v-if="healthPiqture !== null">
          <h3>Healthpiqture</h3>
          <AdminVendorDetail
            :id="orders.healthPiqture.orderId"
            :created="orders.healthPiqture.created"
            :status="orders.healthPiqture.status"
            :updated="orders.healthPiqture.updated"
          />
          <p v-if="'riskScore' in orders.healthPiqture">
            <strong>Riskscore:</strong> {{ orders.healthPiqture.riskScore }}
          </p>
          <p v-if="'riskScoreStatus' in orders.healthPiqture">
            <strong>Risk Score Status:</strong> {{ orders.healthPiqture.riskScoreStatus }}
          </p>
          <!-- <a :href="`${ orders.mvr.downloadURL }`">Download PDF</a> -->
        </div>
        <div class="admin-vendors__integration full" v-if="irix !== null">
          <h3>Irix</h3>
          <div class="integration__detail">
            <AdminVendorDetail
              :id="orders.irix.orderId"
              :created="orders.irix.created"
              :status="orders.irix.status"
              :updated="orders.irix.updated"
            />
            <p v-if="'riskScore' in orders.irix"><strong>Riskscore:</strong> {{ orders.irix.riskScore }}</p>
            <p v-if="'riskScoreRx' in orders.irix"><strong>RiskScoreRx:</strong> {{ orders.irix.riskScoreRx }}</p>
            <p v-if="'orderId' in orders.irix"><strong>Result Status:</strong> {{ orders.irix.resultStatus }}</p>
            <p v-if="'trackingId' in orders.irix"><strong>Tracking ID:</strong> {{ orders.irix.trackingId }}</p>

            <p><a @click="refreshIrix">Refresh Irix</a></p>
            <!-- <a :href="`${ orders.mvr.downloadURL }`">Download PDF</a> -->
          </div>
          <div class="integration__detail integration__detail-list">
            <div class="integrations__list" v-for="(item, index) in orders.irix.ruleHits" :key="item.ident + index">
              <p><strong>Group Ident: </strong> {{ item.groupIdent }}</p>
              <p><strong>Ident: </strong> {{ item.ident }}</p>
              <p><strong>Modifier: </strong> {{ item.modifier }}</p>
              <p><strong>Name: </strong> {{ item.name }}</p>
              <p><strong>Subgroup ident: </strong> {{ item.subGroupIdent }}</p>
            </div>
          </div>
          <h4>Class Results:</h4>
          <div class="integration__detail integration__detail-list" v-if="orders && 'irix' in orders">
            <div
              class="integrations__list"
              v-for="(item, index) in getSortedClassResults(orders.irix.classResults)"
              :key="item.identifier + index"
            >
              <p><strong>Identifier: </strong> {{ item.identifier }}</p>
              <p><strong>Index: </strong> {{ item.index }}</p>
              <p><strong>Level: </strong> {{ item.level }}</p>
            </div>
          </div>
          <h4>Request Inputs:</h4>
          <div class="integration__detail integration__detail-list">
            <div class="integrations__list" v-if="'applicant' in orders.irix && orders.irix.applicant !== null">
              <p><strong>First Name:</strong> {{ orders.irix.applicant.firstName }}</p>
              <p><strong>Middle Name:</strong> {{ orders.irix.applicant.middleName }}</p>
              <p><strong>Last Name:</strong> {{ orders.irix.applicant.lastName }}</p>
              <p><strong>Gender:</strong> {{ orders.irix.applicant.gender }}</p>
              <p><strong>DOB:</strong> {{ orders.irix.applicant.dob }}</p>
              <p><strong>SSN:</strong> {{ orders.irix.applicant.ssn }}</p>
              <p><strong>State:</strong> {{ orders.irix.applicant.state }}</p>
              <p><strong>Zip Code:</strong> {{ orders.irix.applicant.zipCode }}</p>
              <p><strong>Relationship:</strong> {{ orders.irix.applicant.relationship }}</p>
              <p><strong>Signature Type:</strong> {{ orders.irix.applicant.signatureType }}</p>
              <div v-for="a in orders.irix.attributes" :key="a.name">
                <p>
                  <strong>{{ a.name }}:</strong> {{ a.value }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="admin-vendors__integration full" v-if="prefill !== null">
          <h3>Prefill</h3>
          <div class="integration__detail">
            <p v-if="'firstName' in orders.prefill">
              <strong>Name:</strong> {{ orders.prefill.firstName }} {{ orders.prefill.lastName }}
            </p>
            <p v-if="'ssn' in orders.prefill"><strong>Social:</strong> {{ orders.prefill.ssn }}</p>
            <p v-if="'gender' in orders.prefill"><strong>Gender:</strong> {{ orders.prefill.gender }}</p>
            <p v-if="'licenseNumber' in orders.prefill">
              <strong>License Number:</strong> {{ orders.prefill.licenseState }} {{ orders.prefill.licenseNumber }}
            </p>
            <p v-if="'birthDate' in orders.prefill">
              <strong>Birthday:</strong> {{ moment(orders.prefill.birthDate).format('L LTS -Z') }}
            </p>
            <p v-if="'address' in orders.prefill && orders.prefill.address !== null">
              <strong>Address:</strong>
              {{ orders.prefill.address.line1 }}
              {{ orders.prefill.address.line2 ? orders.prefill.address.line2 : '' }}
              {{ orders.prefill.address.city }}, {{ orders.prefill.address.state }}
              {{ orders.prefill.address.postalCode }}
            </p>
            <p v-if="'emailAddresses' in orders.prefill && orders.prefill.emailAddresses !== null">
              <strong>Email:</strong> {{ orders.prefill.emailAddresses[0] }}
            </p>
            <p v-if="'phoneNumbers' in orders.prefill && orders.prefill.phoneNumbers !== null">
              <strong>Phone:</strong> {{ orders.prefill.phoneNumbers[0] }}
            </p>
            <!-- <a :href="`${ orders.mvr.downloadURL }`">Download PDF</a> -->
          </div>
          <div class="integration__detail">
            <AdminVendorDetail
              :id="orders.prefill.orderId"
              :created="orders.prefill.created"
              :status="orders.prefill.status"
              :updated="orders.prefill.updated"
            />
            <p v-if="'riskScore' in orders.prefill"><strong>Riskscore:</strong> {{ orders.prefill.riskScore }}</p>
            <p v-if="'trackingId' in orders.prefill"><strong>Tracking Id:</strong> {{ orders.prefill.trackingId }}</p>
            <p v-if="'quoteback' in orders.prefill"><strong>Quoteback:</strong> {{ orders.prefill.quoteback }}</p>
            <p v-if="'inputs' in orders.prefill && orders.prefill.inputs !== null">
              <strong>Request Inputs:</strong> {{ orders.prefill.inputs }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-data">
      <h1>No vendors</h1>
    </div>
  </div>
</template>

<script>
import AdminVendorDetail from '@/components/admin/AdminVendorDetail.vue'
import moment from 'moment'
import { mapActions } from 'vuex'
import DFButton from '@/components/DFButton'
import { InterviewFlagType, InterviewFlagSource } from '@/api/Interview'

export default {
  name: 'admin-vendors',
  components: {
    AdminVendorDetail,
    DFButton
  },
  props: {
    orders: {
      type: Object,
      default: undefined
    },
    interviewID: {
      type: String,
      default: ''
    },
    interview: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      mvr: null,
      instantId: null,
      healthPiqture: null,
      irix: null,
      prefill: null,
      isEmpty: true,
      instantIdError: undefined,
      mvrError: undefined,
      irixError: undefined,
      claretoError: undefined,
      manualIDFlagError: undefined,
      flagSetBy: undefined,
      flagCreated: undefined,
      claretoSearchID: undefined
    }
  },
  methods: {
    ...mapActions('admin', [
      'getPDFDownloadURL',
      'getAuthFormDownloadURL',
      'expireInstantIDOrder',
      'expireMVROrder',
      'expireIrixOrder',
      'addManualIDVerificationFlag',
      'claretoSearch',
      'fetchClaretoSearchRecords'
    ]),
    moment,

    // FIXME: this crappy code is only necessary because milliman screwed up the class results reordering (which they might fix someday)
    getSortedClassResults(classResults) {
      if (!classResults) {
        return
      }
      const numerics = classResults.filter((cr) => !isNaN(Number(cr.identifier)))
      const alphas = classResults.filter((cr) => isNaN(Number(cr.identifier)))
      return [...numerics.sort(), ...alphas]
    },

    async addManualIDFlag() {
      this.manualIDFlagError = undefined
      this.$emit('loading')
      const { addManualIDVerificationFlag, interview } = await this.addManualIDVerificationFlag({
        id: this.interviewID
      })
      if (!addManualIDVerificationFlag || !addManualIDVerificationFlag?.success) {
        const msg = addManualIDVerificationFlag?.error?.message || 'error adding manual ID verification flag'
        this.manualIDFlagError = msg
      } else {
        if (interview) {
          this.getManualIDFlagDetails(interview)
        } else {
          this.manualIDFlagError = 'error adding manual ID verification flag'
        }
      }
    },

    getManualIDFlagDetails(interview) {
      const flags = interview?.flags
      if (flags !== null && flags !== undefined) {
        flags.forEach((f) => {
          if (
            f.type === InterviewFlagType.MANUAL_ID_VERIFICATION ||
            f.cause === InterviewFlagSource.ADMIN_MANUAL_OVERRIDE_INSTANTID
          ) {
            this.flagCreated = f.created
            this.flagSetBy = f.description
          }
        })
      }
    },

    async refreshIrix() {
      this.irixError = undefined
      this.$emit('loading')
      const { expireIrixOrder, interview } = await this.expireIrixOrder({ id: this.interviewID })
      if (!expireIrixOrder || !expireIrixOrder?.success) {
        const msg = expireIrixOrder?.error?.message || 'error refreshing Irix order'
        this.irixError = msg
      } else {
        if (interview) {
          const irixOrder = interview.interview?.orders?.irix
          if (irixOrder !== null) {
            this.irix = irixOrder
          } else {
            this.instantIdError = 'error refreshing Irix order'
          }
        }
      }
    },

    async refreshInstantID() {
      this.instantIdError = undefined
      this.$emit('loading')
      const { expireInstantIDOrder, interview } = await this.expireInstantIDOrder({ id: this.interviewID })
      if (!expireInstantIDOrder || !expireInstantIDOrder?.success) {
        const msg = expireInstantIDOrder?.error?.message || 'error refreshing instant ID'
        this.instantIdError = msg
      } else {
        if (interview) {
          const instantId = interview.interview?.orders?.instantId
          if (instantId !== null) {
            this.instantId = instantId
          } else {
            this.instantIdError = 'error refreshing instant id order'
          }
        }
      }
    },

    async refreshMVR() {
      this.mvrError = undefined
      this.$emit('loading')
      const { expireMVROrder, interview } = await this.expireMVROrder({ id: this.interviewID })
      if (!expireMVROrder || !expireMVROrder?.success) {
        const msg = expireMVROrder?.error?.message || 'error refreshing mvr'
        this.mvrError = msg
      } else {
        if (interview) {
          const mvr = interview.interview?.orders?.mvr
          if (mvr !== null) {
            this.mvr = mvr
          } else {
            this.mvrError = 'error refreshing mvr order'
          }
        }
      }
    },
    async downloadAuth() {
      const url = await this.getAuthFormDownloadURL(this.interviewID)
      if (url) {
        window.location.href = url
      }
    },
    async searchClareto() {
      const data = await this.claretoSearch({ id: this.interviewID })
      if (data.error !== null) {
        this.claretoError = data.error
        return
      }
      if (data.claretoSearch.message === '') {
        // eslint-disable-next-line
        this.claretoSearchID = data.claretoSearch.searchId
      } else {
        const msg = data.claretoSearch.message
        this.claretoError = msg
      }
    },
    async getClaretoSearchRecords() {
      const { searchRecords } = await this.fetchClaretoSearchRecords(this.interviewID)
      if (searchRecords !== null) {
        this.claretoSearchID = searchRecords.shift(0).searchId
      }
    },
    async downloadPDF(uri) {
      const url = await this.getPDFDownloadURL(uri)
      if (url) {
        window.location.href = url
      }
    },
    setup() {
      if (this.orders) {
        this.mvr = this.orders.mvr
        this.instantId = this.orders.instantId
        this.healthPiqture = this.orders.healthPiqture
        this.irix = this.orders.irix
        this.prefill = this.orders.prefill
        this.isEmpty =
          this.mvr === null &&
          this.instantId === null &&
          this.healthPiqture === null &&
          this.irix === null &&
          this.prefill === null
      }
      if (this.interview) {
        this.getManualIDFlagDetails(this.interview)
      }
    }
  },
  async beforeMount() {
    await this.getClaretoSearchRecords()
  },
  mounted() {
    // this will help remove many ifs
    if (this.orders) {
      this.setup()
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';
.admin-vendors {
  display: flex;
  flex-direction: column;
  border: thin solid rgba(0, 0, 0, 0.12);
  padding: 20px;
  font-size: 14px;
  &__error {
    font-weight: bold;
    color: $c-error;
  }
  &__inset {
    padding-left: 1rem;
    font-weight: bold;
  }
  &__intergrations-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 2em;
    flex-wrap: wrap;
  }
  &__integration {
    flex: 0 49%;
    font-size: 14px;
    margin-bottom: 2rem;
    &.full {
      flex: 0 100%;
      .integration__detail {
        flex: 0 49%;
      }
    }
  }
  &__detail-container {
    display: flex;
    flex-direction: column;
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 20px;
    font-size: 14px;
  }
  h4 {
    margin: 1rem 0;
  }
}
.admin-vendors__clareto {
  font-size: 14px;
  padding: 20px;
  .label {
    color: $c-primary;
    font-weight: 700;
  }
}
.integration__detail-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  &:last-child {
    border-bottom: none;
  }
}

.integration__detail {
  margin-bottom: 2rem;
}

.integrations__list {
  flex: 0 24%;
  margin-bottom: 2rem;
}
</style>
