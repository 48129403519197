<template>
  <div class="basic-info" v-if="interview">
    <v-snackbar
      v-model="snackbar.show"
      :centered="true"
      :color="theme.colors.red"
      :timeout="3000"
      content-class="snackbar-content"
    >
      <div style="width: 100%; text-align: center">
        <b>{{ snackbar.text }}</b>
      </div>
    </v-snackbar>
    <div class="basic-info__name-status">
      <p class="basic-info__full-name">{{ interview.account.firstName }} {{ interview.account.lastName }}</p>
      <!--
      <p class="basic-info__status" v-if="'status' in interview">
        Status: {{ titleCase(interview.status) }}
      </p>
      -->
    </div>
    <div class="basic-info__columns">
      <div class="basic-info__left" v-if="'account' in interview">
        <p v-if="interview && interview.product !== null"><strong>Issuer: </strong>{{ interview.product.vendor }}</p>
        <p v-if="interview && interview.product !== null"><strong>Variant: </strong>{{ interview.product.variant }}</p>
        <p v-if="interview.applicant && 'age' in applicant && applicant.age !== null">
          <strong>Age:</strong> {{ interview.applicant.age }}
        </p>
        <p v-if="interview.applicant && 'dateOfBirth' in applicant && applicant.dateOfBirth !== null">
          <strong>Date of Birth:</strong> {{ interview.applicant.dateOfBirth }}
        </p>
        <p v-if="interview.applicant && 'bmi' in applicant && applicant.bmi !== null">
          <strong>BMI:</strong> {{ Math.round(interview.applicant.bmi * 10) / 10 }}
        </p>
        <div class="line" v-if="email">
          <b class="label">Account Email:</b>
          <div>
            <div class="js-pillar pillar">
              <a :href="`mailto:${email}`" v-if="!editEmail">{{ email }}</a>
              <span ref="editable" class="editable" contenteditable @keydown="keydownEffect" v-show="editEmail">{{
                email
              }}</span>
              <div>
                <button
                  class="btn ml--sm"
                  :class="[{ 'btn--edit': !editEmail }, { 'btn--save': editEmail }, { 'btn--pending': loading }]"
                  type="button"
                  @click="editEffect"
                >
                  {{ !editEmail ? 'Edit' : 'Save' }}
                </button>
              </div>
            </div>
            <span class="message message--error" v-if="emailError.length">{{ emailError }}</span>
          </div>
        </div>
        <!-- <p
          v-if="
            applicant &&
            'emailAddress' in applicant &&
            applicant.emailAddress !== null
          "
        >
          <strong>Email (Interview Answer): </strong
          ><a :href="`mailto:${applicant.emailAddress}`">
            {{ applicant.emailAddress }}</a
          >
          <span v-if="magicLinkEnabled">
          |
            <span v-if="sendingMagicLink">
              <v-progress-circular :color="theme.colors.red" width="2" size="20" indeterminate/>
            </span>
            <span v-else>
              <button class="magicLink" @click="magicLink">send magic link</button>
            </span>
          </span>
        </p> -->
        <p v-if="applicant && 'phoneNumbers' in applicant && applicant.phoneNumbers.length > 0">
          <strong>Phone:</strong> {{ applicant.phoneNumbers[0].number }}
        </p>
        <p
          v-if="
            interview &&
            interview.applicant !== null &&
            interview.applicant.address &&
            interview.applicant.address.state
          "
        >
          <strong>State:</strong> {{ interview.applicant.address.state }}
        </p>
        <p><strong>Interview ID: </strong>{{ id }}</p>
        <p><strong>Policy ID: </strong>{{ formatPolicyId() }}</p>
        <p><strong>Account ID: </strong>{{ formatAccountId() }}</p>
        <p>
          <strong>Account Verified: </strong>
          <span class="is-verified" v-if="accountVerified">True <span>&#10003;</span></span>
          <span class="not-verified" v-else>False <span>&#x2715;</span></span>
        </p>
        <p><strong>Agent ID: </strong>{{ agent.id || 'N/A' }}</p>
        <p><strong>Agent Email: </strong>{{ agent.agentEmail || 'N/A' }}</p>
      </div>
      <div class="basic-info__right" v-if="interview">
        <p v-if="'status' in interview">
          <strong>Interview Status:</strong> {{ interviewStatusName(interview.status) }}
        </p>
        <!--<p v-if="'currentSection' in interview & interview.currentSection != null ">
          <strong>Interview Section:</strong> {{ titleCase(interview.currentSection.title) }}
        </p>-->
        <p><strong>Interview Marker:</strong> {{ marker }}</p>
        <div class="basic-info__score-fields" v-if="'riskScore' in interview && interview.riskScore !== null">
          <p v-if="'riskScore' in interview && interview.riskScore !== null && 'class' in interview.riskScore">
            <strong>Risk Score Class:</strong>
            {{ titleCase(interview.riskScore.class) }}
          </p>
          <p v-if="'riskScore' in interview && interview.riskScore !== null && 'origin' in interview.riskScore">
            <strong>Risk Score Origin:</strong>
            {{ titleCase(interview.riskScore.origin) }}
          </p>
          <p v-if="'riskScore' in interview && interview.riskScore !== null && 'reasons' in interview.riskScore">
            <strong>Risk Score Reasons:</strong>
          </p>
          <ul class="score-reasons">
            <li v-for="(reason, index) in interview.riskScore.reasons" :key="index">{{ reason }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="interview && 'riskScore' in interview && interview.riskScore !== null && 'flags' in interview.riskScore">
      <div v-if="interview.riskScore.flags !== null && interview.riskScore.flags.length > 0">
        <strong>Risk Score Flags:</strong>
        <p
          v-for="(item, index) in interview.riskScore.flags"
          :key="typeof item === 'object' ? item.type + index : item + index"
        >
          <img src="@/assets/images/icon_flag.svg" aria-label="flag" alt="flag" />
          {{ titleCase(typeof item === 'object' ? item.type : item) }}
        </p>
      </div>
    </div>
    <!--    Subscores should show only info, class, flags, and reason fields.-->
    <div v-if="interview && 'riskScore' in interview && interview.riskScore !== null" class="basic-info__scores-table">
      <button class="scores" @click="toggleScores" v-if="interview.riskScore.subscores.length > 0">
        Toggle Subscores
      </button>
      <div class="scores-list" v-if="showRisk">
        <div class="basic-info__scores-item-row">
          <div class="basic-info__scores-item">
            <p><strong>Cause</strong></p>
          </div>
          <div class="basic-info__scores-item">
            <p><strong>Class</strong></p>
          </div>
          <div class="basic-info__scores-item">
            <p><strong>Flags</strong></p>
          </div>
          <div class="basic-info__scores-item">
            <p><strong>Reasons</strong></p>
          </div>
        </div>
        <div class="basic-info__score-wrapper" v-for="(item, index) in interview.riskScore.subscores" :key="index">
          <div class="basic-info__scores-item-row">
            <div class="basic-info__scores-item">
              <p>{{ item.info || 'N/A' }}</p>
            </div>
            <div class="basic-info__scores-item">
              <p>{{ item.class ? titleCase(item.class) : 'N/A' }}</p>
            </div>
            <div class="basic-info__scores-item">
              <p>{{ item.flags && item.flags.length > 0 ? formatFlags(item.flags) : '' }}</p>
            </div>
            <div class="basic-info__scores-item">
              <ul class="history-reasons" v-if="item.reasons && item.reasons.length > 0">
                <li v-for="(reason, index) in item.reasons" :key="index">{{ reason }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="interview && 'riskScoreHistory' in interview && interview.riskScoreHistory !== null"
      class="basic-info__history-table"
    >
      <button class="history" @click="toggleHistory" v-if="interview.riskScoreHistory.length > 0">
        Toggle History
      </button>
      <div class="history-list" v-if="showHistory">
        <div class="basic-info__score-wrapper" v-for="(item, index) in interview.riskScoreHistory" :key="index">
          <div class="basic-info__scores-container" v-if="item">
            <div class="basic-info__scores-item-row">
              <div class="basic-info__scores-item">
                <p><strong>Time:</strong> {{ item.time ? moment(item.time).format('L LTS -Z') : 'N/A' }}</p>
              </div>
              <div class="basic-info__scores-item" v-if="item.score">
                <p><strong>Cause:</strong> {{ item.score.info || 'N/A' }}</p>
              </div>
              <div class="basic-info__scores-item" v-if="item.score">
                <p><strong>Class:</strong> {{ item.score.class || 'N/A' }}</p>
              </div>
              <div class="basic-info__scores-item" v-if="item.score">
                <p>
                  <strong>Flags:</strong>
                  {{ item.score.flags && item.score.flags.length > 0 ? formatFlags(item.score.flags) : '' }}
                </p>
              </div>
              <div class="basic-info__scores-item" v-else>
                <p><strong>Score Removed</strong></p>
              </div>
            </div>
            <div class="basic-info__scores-item-row-full">
              <div class="basic-info__scores-item">
                <strong>Reasons:</strong>
                <ul class="history-reasons" v-if="item.score && item.score.reasons && item.score.reasons.length > 0">
                  <li v-for="(reason, index) in item.score.reasons" :key="index">{{ reason }}</li>
                </ul>
              </div>
            </div>
            <button
              class="history"
              @click="toggleHistorySub(index)"
              v-if="item.score && item.score.subscores && item.score.subscores.length > 0"
            >
              Toggle subscores
            </button>
            <div
              :class="[{ opened: isOpen.includes(index) }, 'basic-info__inline-history']"
              v-if="item.score && item.score.subscores && item.score.subscores.length > 0"
            >
              <div class="basic-info__scores-item-row">
                <div class="basic-info__scores-item">
                  <p><strong>Cause</strong></p>
                </div>
                <div class="basic-info__scores-item">
                  <p><strong>Class</strong></p>
                </div>
                <div class="basic-info__scores-item">
                  <p><strong>Flags</strong></p>
                </div>
                <div class="basic-info__scores-item">
                  <p><strong>Reasons</strong></p>
                </div>
              </div>
              <div class="basic-info__scores-item-row" v-for="(item, index) in item.score.subscores" :key="index">
                <div class="basic-info__scores-item">
                  <p>{{ item.info || 'N/A' }}</p>
                </div>
                <div class="basic-info__scores-item">
                  <p>{{ item.class ? titleCase(item.class) : 'N/A' }}</p>
                </div>
                <div class="basic-info__scores-item">
                  <p>{{ item.flags && item.flags.length > 0 ? formatFlags(item.flags) : '' }}</p>
                </div>
                <div class="basic-info__scores-item">
                  <ul class="history-reasons" v-if="item.reasons && item.reasons.length > 0">
                    <li v-for="(reason, index) in item.reasons" :key="index">{{ reason }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="basic-info__scores-item-row" v-else>
            <p>Score cleared</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import theme from '@/config/theme.config'
import moment from 'moment'
import { adminSendMagicLinkEnabled } from '@/config/app.config'
import { removeUnderscores, sentenceCase, titleCase } from '@/utils/helpers'
import { interviewStatusName } from '@/api/Interview/constants'
import { PATTERN_EMAIL } from '@/rules'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'basic-info',
  props: {
    name: {
      type: String,
      default: ''
    },
    accountId: {
      type: String,
      default: ''
    },
    accountEmail: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    applicant: {
      type: Object,
      default: () => {
        return {}
      }
    },
    agent: {
      type: Object,
      default: () => {
        return {}
      }
    },
    marker: {
      type: String,
      default: ''
    },
    updateEmail: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: [],
      showHistory: false,
      showRisk: false,
      searchHistory: undefined,
      search: undefined,
      theme: theme,
      sendingMagicLink: false,
      snackbar: { show: false, text: undefined },
      magicLinkEnabled: adminSendMagicLinkEnabled,
      email: '',
      editEmail: false,
      emailError: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters('admin', ['getCurrentInterview']),
    interview() {
      return this.$store.state.admin.currentInterview.interview
    },
    accountVerified() {
      return this.$store.state.admin.accountVerified
    }
  },
  mounted() {
    if (this.accountEmail) this.email = this.accountEmail
    document.addEventListener('click', this.cancelEffect, false)
  },
  unmounted() {
    document.removeEventListener('click', this.cancelEffect, false)
  },
  watch: {
    accountEmail: function (newValue) {
      this.email = newValue
    }
  },
  methods: {
    ...mapActions('account', ['sendAdminMagicLink']),
    moment,
    removeUnderscores,
    titleCase,
    sentenceCase,
    interviewStatusName,
    resetEmailError() {
      this.emailError = ''
    },
    async saveEmail() {
      if (this.editEmail) {
        const content = this.$refs.editable.textContent.trim()
        // If email doesn't change and save is clicked
        if (content === this.accountEmail) return { success: true }

        // If email has changed validate new email
        const valid = this.validateEmail(content)

        if (!valid) {
          if (!content.length) this.emailError = 'A valid email is required.'
          else this.emailError = 'Please provide a valid email.'
          return { success: false }
        }
        // If valid email and email has errors clear error
        if (valid && this.emailError) this.resetEmailError()

        // Sumbit new email
        this.loading = true

        const { email, error } = await this.updateEmail(
          this.interview.account.firstName,
          this.interview.account.lastName,
          this.applicant.phoneNumbers[0].number,
          content
        )
        if (error) {
          this.emailError = error
          this.loading = false
          return { success: false }
        }
        this.email = email
        return { success: true }
      }
      return { success: true }
    },
    keydownEffect(e) {
      if (e.key === 'Enter') e.preventDefault()
    },
    cancelEffect(e) {
      const target = e.target.closest('.js-pillar')
      if (this.loading) return
      if (target) return
      if (this.$refs.editable) this.$refs.editable.textContent = this.accountEmail
      if (this.emailError.length) this.resetEmailError()
      this.editEmail = false
    },
    async editEffect() {
      if (this.loading) return
      const { success } = await this.saveEmail()
      if (!success) return
      if (this.loading) this.loading = false
      this.editEmail = !this.editEmail
      if (this.editEmail) {
        const timer = setTimeout(() => {
          this.$refs.editable.focus()
          clearTimeout(timer)
        }, 100)
      }
    },
    validateEmail(/** String */ value) {
      if (!value || !PATTERN_EMAIL.test(value)) return false
      return true
    },
    toggleHistorySub(index) {
      if (this.isOpen.includes(index)) {
        this.isOpen = this.isOpen.filter((i) => i !== index)
        return
      }
      this.isOpen.push(index)
    },
    formatClass(cls) {
      if (!cls || cls === 'UNDEFINED') {
        return ''
      }
      return titleCase(cls)
    },
    formatAccountId() {
      let accountID = this?.applicant?.accountID
      accountID = accountID && accountID !== '' ? accountID : 'N/A'
      return accountID
    },
    formatPolicyId() {
      const policyId = this?.interview?.policyId
      return policyId && policyId !== '' ? policyId : 'N/A'
    },
    toggleHistory() {
      this.showHistory = !this.showHistory
    },
    toggleScores() {
      this.showRisk = !this.showRisk
    },
    async magicLink() {
      this.sendingMagicLink = true
      try {
        let payload = { email: this?.applicant?.emailAddress }
        const result = await this.sendAdminMagicLink(payload)
        this.snackbar.text = result?.message
      } catch (e) {
        this.snackbar.text = `error: ${e}`
      }
      this.sendingMagicLink = false
      this.snackbar.show = true
    },
    formatFlags(flags) {
      let fl = []
      if (flags && flags?.length > 0) {
        flags.forEach((item) => {
          if (typeof item === 'object') {
            fl.push(item.type)
          } else {
            fl.push(item)
          }
        })
        return sentenceCase(fl.join(', '))
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/colors';

.basic-info {
  flex: 1;
  .is-verified {
    span {
      color: green;
    }
  }
  .not-verified {
    span {
      color: red;
    }
  }
  &__history-table,
  &__scores-table {
    .scores {
      margin-bottom: 20px;
    }
    ::v-deep .theme--light.v-data-table {
      width: 100% !important;
      max-width: 100% !important;
      background-color: $c-eggshell;

      .v-select.table-input {
        font-size: 0.875rem !important;
        width: 120px;
      }
    }
  }
  &__status,
  &__section,
  &__full-name {
    font-size: 20px;
    font-weight: bold;
    flex: 1;
  }
  &__full-name {
    text-transform: capitalize;
  }
  &__section,
  &__status {
    text-transform: capitalize;
    text-align: right;
  }
  &__name-status {
    display: flex;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding-bottom: 5px;
    margin-bottom: 20px;
  }
  &__columns {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }
  &__right,
  &__left {
    flex: 1 50%;
    max-width: 50%;
  }
  &__score-wrapper {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  &__scores-item-row {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-column-gap: 20px;
    strong {
      display: block;
    }
  }
  &__scores-item-row-full {
    width: 100%;
    margin-top: 30px;
    ul {
      max-width: inherit;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fill, 186px);
    }
  }
  &__inline-history {
    display: none;
    &.opened {
      display: block;
    }
  }
}
.magicLink,
.history,
.scores {
  color: $c-red;
  text-decoration: underline;
}

.history {
  margin-bottom: 20px;
}
.history-origin {
  min-width: 70px;
}

.score-reasons {
  li {
    padding-left: 0px;
    list-style-type: square;
  }
}

.history-reasons {
  max-width: 400px;
  list-style-position: inside;
  list-style-type: none;
  ul {
    padding: 5px 0;
  }
  li {
    list-style-type: circle;
  }
}

.history-list,
.scores-list {
  flex: 1;
  &__item {
    word-wrap: break-word;
    margin: 10px 0;
    padding: 10px;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  ul {
    padding: 5px 0;
  }
  li {
    list-style-type: none;
    margin-bottom: 10px;
  }
}
</style>

<style>
.line,
.pillar {
  display: flex;
  align-items: flex-start;
}

.line {
  gap: 6px;
}

.editable {
  outline: none;
  border-bottom: solid #000 1px;
  min-width: 158px;
}

.btn--edit,
.btn--save {
  position: relative;
  padding: 0 6px;
  border-radius: 4px;
  transition: background-color 230ms ease-in-out;
}

.btn--edit:hover {
  background: rgba(26, 17, 60, 0.1);
}

.btn--save {
  position: relative;
  background: #1aaa55;
  color: white;
  padding: 0 6px;
  border-radius: 4px;
}

.btn--pending {
  color: transparent;
}

.btn--pending:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: spin 1000ms ease infinite;
}

@keyframes spin {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.ml--sm {
  margin-left: 6px;
}

.message--error {
  color: #e33031;
}
</style>
