<template>
  <div class="contentful-multi-column-container" :style="cssVars" :class="containerClasses">
    <div class="contentful-multi-column-container__element" v-for="c in components" :key="c.key">
      <slot :name="c.type" :data="c.data">
        <component
          :is="componentForContentfulType(c.type, undefined)"
          v-bind="c.data"
        />
      </slot>
    </div>

  </div>
</template>

<script>
import {
  componentForContentfulType,
  contentfulComponents,
  processContentfulObject
} from '@/api/Content.js'

export default {
  name: 'contentful-multi-column-container',
  props: {
    desktopColumns: undefined,
    tabletColumns: undefined,
    mobileColumns: undefined,
    elements: undefined,
    classes: undefined,
    styles: undefined,
    order: {
      type: String,
      default: 'ltr'
    }
  },
  data: function () {
    return {
      components: []
    }
  },
  computed: {
    containerClasses () {
      const classes = []
      if (this.order === 'ttb') {
        classes.push('contentful-multi-column-container--ttb')
      }
      return classes
    },
    cssVars () {
      return {
        '--cols-d-count': this.desktopColumns,
        '--cols-m-count': this.mobileColumns || 1,
        '--cols-t-count': this.tabletColumns || this.desktopColumns
      }
    }
  },
  methods: {
    componentForContentfulType, processContentfulObject
  },
  beforeCreate () {
    // we need to register the components here as this component can create cyclical dependencies
    this.$options.components = contentfulComponents
  },

  created () {
    if (this.elements) {
      this.elements.forEach((e) => {
        const comp = this.processContentfulObject(e)
        if (comp) {
          this.components.push(comp)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/media-queries";

.contentful-multi-column-container {
  display: grid;
  grid-template-columns: repeat(var(--cols-m-count), 1fr);
  grid-auto-flow: row;
  grid-row-gap: .8em;
  @include md() {
    grid-column-gap: 20px;
    grid-template-columns: repeat(var(--cols-t-count), 1fr);
  }
  @include lg() {
    grid-column-gap: 40px;
    grid-template-columns: repeat(var(--cols-d-count), 1fr);
  }
  &__element {
    ::v-deep p {
      &:not(:first-child) {
        margin-top: .5em;
      }

    }
  }
}

.contentful-multi-column-container--ttb {
  .contentful-multi-column-container__element {
    &:nth-child(1n) {
      order: 1;
    }
    &:nth-child(2n) {
      order: 2;
      @include md() {
        order: 1;
      }
    }
  }
}

</style>
