<template>
  <v-autocomplete
    :id="accessibilityId"
    ref="autocomplete"
    class="interview-question__autocomplete"
    v-bind="attrs"
    v-model="values"
    :items="searchResult"
    :search-input.sync="searchText"
    :clearable="false"
    :multiple="isMultiple"
    :chips="isMultiple"
    :deletable-chips="isMultiple"
    :cache-items="isMultiple"
    :hide-selected="isMultiple"
    :loading="isLoading"
    :filter="autocompleteFilter"
    :placeholder="placeholder"
    :hide-no-data="isLoading || !searchText || searchText.length < 2"
    no-data-text="No results found, please try again."
    :error="hasError"
    @input="setDirty"
    @blur="validateAndSaveAutocomplete"
    :readonly="readonly"
  >
    <template v-slot:no-data v-if="attrs['no-data-text']">
      <v-list-item>
        <v-list-item-title
          class="interview-question__autocomplete--no-data"
        >{{ attrs['no-data-text'] }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'

export default {
  name: 'Autocomplete',

  props: {
    value: {
      type: [String, Array]
    },
    question: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    accessibilityId: {
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      isLoading: false,
      searchText: '',
      searchResult: [],
      hasError: !!this.error,
      values: []
    }
  },

  computed: {
    attrs () {
      const attrs = {
        inline: this.inlineOptions
      }

      if (!attrs.hasOwnProperty('hide-details')) {
        attrs['hide-details'] = true
      }
      return attrs
    },

    isMultiple () {
      return this.question.multipleChoice
    }
  },

  methods: {
    ...mapActions('interview', [
      'findOccupations',
      'findOccupationWithKey',
      'findMedicationsSettingValueFromKey',
      'findMedicationsSettingValueFromActiveName',
      'findMedicationsWithKeys',
      'findMedicationsWithNames',
      'findDiagnoses',
      'findDiagnosesWithCodes'
    ]),

    setDirty (val) {
      this.values = val
      this.isDirty = true
      this.errorMessage = null

      // clear the autocomplete text once an option is selected
      if (this.isMultiple) {
        this.searchText = ''
        this.searchResult = []
      }

      this.$emit('input', this.values, this.question.key)
    },

    async updateValues (values) {
      if (this.isMultiple) {
        if (typeof values === 'undefined' || values === null) {
          values = []
        }
        this.values = Array.isArray(values) ? values : [values]
      } else {
        this.values = Array.isArray(values) ? values[0] : values
      }

      const vt = this.question.valueType

      if (this.values) {
        if (vt === 'OCCUPATION_KEY') {
          const occupation = await this.findOccupationWithKey(this.values)
          if (occupation) {
            this.searchText = occupation.text || ''
            this.searchResult = [occupation]
          }
        } else if (vt === 'MEDICATION_NAME') {
          const medications = await this.findMedicationsWithNames(this.values)
          if (medications) {
            this.searchResult = medications
          }
        } else if (vt === 'DIAGNOSIS_CODE') {
          const diagnosis = await this.findDiagnosesWithCodes(this.values)
          if (diagnosis) {
            this.searchText = diagnosis.text
            this.searchResult = [diagnosis]
          }
        }
      }
    },

    autocompleteFilter (item, queryText, itemText) {
      if (!queryText) {
        return false
      }
      const it = itemText.trim().toLowerCase()
      const qt = queryText.trim().toLowerCase()
      return it.indexOf(qt) > -1 // contains
    },

    autocompleteSearch: debounce(async function (query) {
      // dont show autocomplete for less than 2 characters
      if (!query || query.length < 2) {
        this.searchResult = []
        this.isLoading = false
        return
      }

      const vt = this.question.valueType
      if (vt === 'OCCUPATION_KEY') {
        this.searchResult = await this.findOccupations(query)
      } else if (vt === 'MEDICATION_KEY') {
        this.searchResult = await this.findMedicationsSettingValueFromKey(query)
      } else if (vt === 'MEDICATION_NAME') {
        this.searchResult = await this.findMedicationsSettingValueFromActiveName(
          query
        )
      } else if (vt === 'DIAGNOSIS_CODE') {
        this.searchResult = await this.findDiagnoses(query)
      }
      this.isLoading = false
    }, 250),

    validateAndSaveAutocomplete () {
      // on blur, if we dont have a value but we have an autocomplete match, set the value (at aaron's request)
      if (!this.isMultiple && !this.values && this.searchResult.length > 0) {
        this.setDirty(this.searchResult[0].value)
      }
      this.$emit('input', this.values, this.question.key)
    }
  },

  watch: {
    searchText (val) {
      this.isLoading = true
      this.autocompleteSearch(val)
    }
  },

  created () {
    this.isDirty = false
    this.updateValues(this.value)
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.interview-question__autocomplete--no-data {
  opacity: 0.85;
}
</style>
