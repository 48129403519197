<template>
  <IncomingContainer :show-popular-section="showPopularSection" :hide-categories="hideCategories" :article="article">
    <template v-if="article" v-slot:title>
      <IncomingCategories
        v-if="article.data.categories"
        :categories="article.data.categories"
        class="incoming__categories--big tm-2r mb-3"
      />
      <div class="incoming__title-content">
        {{ title }}<span v-if="titleHasDot(article.data.title)" class="incoming__dot">.</span>
      </div>
    </template>
    <template v-slot:content>
      <div class="incoming__article">
        <div v-if="article">
          <div class="incoming__article-image">
            <img :src="article.data.heroImage.fields.file.url" />
          </div>
          <div class="incoming__article-author">
            <router-link v-if="article.data.authorLink" :to="article.data.authorLink">{{
              article.data.author
            }}</router-link>
            <span v-else>{{ article.data.author }}</span>
          </div>
          <div class="incoming__article-date">
            {{ formattedDate }}
          </div>
          <div class="incoming__body">
            <ContentfulContentRenderer :content="body" />
          </div>
          <div class="incoming__share">
            <div class="incoming__share-title">Share This<span class="incoming__dot">.</span></div>
            <a target="_blank" :href="facebookShareUrl()">
              <img src="@/assets/images/icon_facebook_dark.svg" height="50" aria-label="facebook" alt="Facebook" />
            </a>
            <a target="_blank" :href="twitterShareUrl()">
              <img src="@/assets/images/icon_twitter_dark.svg" height="50" aria-label="twitter" alt="Twitter" />
            </a>
            <a target="_blank" :href="linkedInShareUrl()">
              <img src="@/assets/images/icon_linkedin_dark.svg" height="50" aria-label="linkedin" alt="LinkedIn" />
            </a>
          </div>
          <div class="incoming__article-related" v-if="relatedArticles.length">
            <div class="related-articles__title">Recent Articles<span class="incoming__dot">.</span></div>
            <div class="related-articles__articles">
              <router-link
                :to="{
                  name: 'incoming-article',
                  params: { slug: relatedArticle.data.slug }
                }"
                class="related-article"
                v-for="relatedArticle in relatedArticles"
                :key="relatedArticle.key"
              >
                <div class="related-article__image">
                  <img :src="relatedArticle.data.feedImage.fields.file.url" />
                </div>
                <div class="related-article__title">
                  {{ relatedArticle.data.title }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <v-progress-circular v-else height="100" :color="progressColor" indeterminate />
      </div>
    </template>
  </IncomingContainer>
</template>

<script>
import { mapActions } from 'vuex'
import Colors from '@/assets/styles/_colors.scss'
import { componentForContentfulType, processContentfulObject, RichTextRenderer } from '@/api/Content'
import IncomingContainer from '@/views/content/incoming/IncomingContainer'
import moment from 'moment'
import IncomingCategories from '@/views/content/incoming/IncomingCategories'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'
import { RouteName } from '@/router/routes/constants'
import { datadogRum } from '@datadog/browser-rum'

export default {
  name: 'incoming-article',
  data() {
    return {
      article: null,
      relatedArticles: [],
      body: null,
      title: '',
      eggshell: Colors.eggshell,
      progressColor: Colors.red,
      meta: []
    }
  },

  metaInfo() {
    let metaInfo = { meta: this.meta }
    if (this.article && !!this.article.data.socialTitle) {
      return {
        ...metaInfo,
        title: this.article.data.socialTitle
      }
    } else {
      return metaInfo
    }
  },

  components: {
    ContentfulContentRenderer,
    IncomingContainer,
    IncomingCategories
  },

  methods: {
    ...mapActions('content', ['fetchContentfulEntryOrCache', 'fetchContentfulEntryBySlug']),
    componentForContentfulType,

    titleHasDot(title) {
      return title.slice(-1) === '.'
    },

    async loadArticle() {
      this.article = null
      this.body = null
      this.relatedArticles = []
      const slug = this.$route.params.slug.includes(':')
        ? this.$route.params.slug.split(':')[1]
        : this.$route.params.slug
      const entry = (
        await this.fetchContentfulEntryBySlug({
          slug,
          contentType: 'article'
        })
      ).items[0]
      return entry
    },

    renderArticle(entry) {
      this.article = processContentfulObject(entry)
      const renderer = new RichTextRenderer(this)
      this.body = renderer.render(this.article.data.body, 'incoming-body')
      this.relatedArticles = entry.fields.relatedArticles?.map((a) => processContentfulObject(a)) || []
      this.title = this.titleHasDot(this.article.data.title)
        ? this.article.data.title.substring(0, this.article.data.title.length - 1)
        : this.article.data.title
    },

    processMetadata() {
      let image = this.article.data.socialImage ? `https:${this.article.data.socialImage.fields.file.url}` : null
      this.meta = [
        { vmid: 'og:url', property: 'og:url', content: window.location.href },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: this.article.data.socialTitle
        },
        {
          vmid: 'description',
          name: 'description',
          content: this.article.data.socialDescription
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: this.article.data.socialTitle
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: this.article.data.socialDescription
        },
        { vmid: 'twitter:image', name: 'twitter:image', content: image },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: this.article.data.socialDescription
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: image
        }
      ].filter((m) => m.content !== null && m.content !== undefined)
    },

    cta() {
      return 'Tell%20your%20friends%20why%20you%E2%80%99d%20like%20to%20share%20this%20article%20with%20them%21'
    },

    facebookShareUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&t=${this.cta()}`
    },

    twitterShareUrl() {
      return `https://twitter.com/intent/tweet?text=${this.cta()}&url=${window.location.href}`
    },

    linkedInShareUrl() {
      return `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(window.location.href)}&title=${this.cta()}`
    }
  },

  computed: {
    formattedDate() {
      return moment(this.article.data.date).format('MMMM Do, YYYY')
    },
    isTaboolaArticle() {
      return this.article && this.article.data && this.article.data.template === 'taboola'
    },
    showPopularSection() {
      return !this.isTaboolaArticle
    },
    hideCategories() {
      return this.isTaboolaArticle
    }
  },

  watch: {
    async $route() {
      await this.loadArticle()
    }
  },

  async created() {
    const entry = await this.loadArticle()
    if (entry) {
      this.renderArticle(entry)
      this.processMetadata()
    } else {
      // eslint-disable-next-line no-console
      console.error('article does not exist', this.$route.path)
      datadogRum.addError(new Error('user was redirected to /incoming from non-existent article url'))
      await this.$router.push({ name: RouteName.INCOMING })
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/flow';
@import 'src/assets/styles/global';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/media-queries';

.incoming__article {
  ::v-deep {
    p {
      margin-bottom: 1rem;
    }

    a {
      display: inline;
    }

    p {
      margin-bottom: 1rem;
    }

    .df-contentful-img {
      width: 100%;
    }

    .df-contentful-video-embed {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    blockquote {
      font-size: 2rem;
      line-height: 2.25rem;
      letter-spacing: -0.03em;
      max-width: 85%;
      margin: 2rem 0 2rem 1.5rem;
      font-weight: 600;
      @include md {
        margin: 2rem 0 2rem 4rem;
        max-width: 75%;
        font-size: 3rem;
        line-height: 3.25rem;
      }

      ::before {
        content: open-quote;
        margin-left: -1rem;
        @include md {
          margin-left: -1.5rem;
        }
      }

      ::after {
        content: close-quote;
      }
    }
  }
}

.incoming__article-image {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;

  > img {
    width: 100%;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }
}

.incoming__article-author {
  color: $c-red;
  margin-left: 0.3rem;
  font-size: 1.2rem;
  letter-spacing: -0.02em;
}

.incoming__article-date {
  margin-left: 0.3rem;
  margin-bottom: 2rem;
}

.incoming__share {
  margin-top: 5rem;

  .incoming__share-title {
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bolder;
    letter-spacing: -0.05em;
  }
}

.incoming__article-related {
  margin-top: 5rem;

  .related-articles__title {
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bolder;
    letter-spacing: -0.05em;
  }

  .related-articles__articles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .related-article {
    width: 30%;
    margin-right: 3rem;
    display: flex;
    flex-direction: column;
    height: auto;

    &:last-child {
      margin: 0;
    }

    color: $c-primary !important;
    text-decoration: none;

    &:hover {
      .related-article__title {
        text-decoration: underline;
      }
    }

    &__title {
      font-weight: bolder;
    }

    &__image {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 100%;

      > img {
        width: 100%;
      }

      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }
}
</style>
