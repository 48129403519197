<template>
  <div class="contentful-cta">
    <div class="contentful-cta__holder">
      <div class="contentful-cta__title" v-if="title">{{title}}</div>
      <div class="contentful-cta__subtitle" v-if="subtitle">{{subtitle}}</div>
      <div class="contentful-cta__content" v-if="content">
        <ContentfulContentRenderer :content="contentNodes" />
      </div>
      <div class="contentful-cta__actions" v-if="buttonText">
        <DFButton @click="onButtonClicked" variant="blue">{{buttonText}}</DFButton>
      </div>
    </div>
  </div>
</template>

<script>
import DFButton from '@/components/DFButton.vue'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'
import { processRichText } from '@/api/Content.js'
export default {
  name: 'contentful-cta',
  components: { ContentfulContentRenderer, DFButton },
  props: {
    name: undefined,
    title: undefined,
    subtitle: undefined,
    content: undefined,
    buttonText: undefined,
    buttonUrl: undefined
  },

  computed: {
    contentNodes () {
      return processRichText(this, this.content)
    }
  },

  methods: {
    async onButtonClicked () {
      if (this.buttonUrl) {
        let routeOpts = this.buttonUrl
        if (!this.buttonUrl.startsWith('/')) {
          routeOpts = { name: this.buttonUrl }
        }
        await this.$router.push(routeOpts)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/media-queries";

.contentful-cta {
  margin: 40px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  &__holder {
    width: 100%;
    background-color: rgba(#fff, 0.2);
    border: 1px solid rgba(#2C6DF3, 0.5);
    padding: 50px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
  }

  &__title {
    font-family: "Graphik", sans-serif;
    font-size: 2.1em;
    color: $c-primary;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 1em;
    margin-bottom: 15px;
    margin-top: -10px;
    text-align: center;
    @include md {
      font-size: 2.1rem;
    }
    @include lg {
      font-size: 2.4em;
    }
  }

  &__subtitle {
    font-family: "Graphik", sans-serif;
    font-size: 1.2em;
    color: $c-primary;
    letter-spacing: -0.01em;
    line-height: 1em;
    margin-bottom: 15px;
    text-align: center;
    @include md {
      font-size: 1.2rem;
    }
    @include lg {
      font-size: 1.25em;
    }
  }
  &__content {
    margin-top: 10px;
    font-size: 0.95rem;
    text-align: center;
  }
  &__actions {
    margin-top: 20px;
  }
}

</style>
