<template>
  <div class="signin">
    <FlowWrapper
      :progress="1"
      :show-buttons="false"
      :showActions="false"
      hide-sign-in
    >
      <template v-slot:header-text>{{ contextualContent.header }}</template>
      <template v-slot:inline-error v-if="hasErrors">
        <div class="sign-in__errors mq-container">
          <div class="sign-in__errors-icon">
            <i class="fa-light fa-circle-xmark"></i>
          </div>
          <div class="sign-in__errors-content">
            <template v-if="hasErrorKey('no_account')">
              We couldn't find an account for the email <b>{{ email }}</b>. Check your entry and try again.
            </template>
            <template v-if="hasErrorKey('general') || hasErrorKey('no_email')">
              We couldn't log you in. Please try again. If you continue to have issues, please
              <router-link to="/contact">contact us</router-link>.
            </template>
          </div>
        </div>
      </template>

      <template v-slot:content>
        <div class="sign-in__content">
          <div class="sign-in__main-content">
            <div class="df-content-header-text">{{ contextualContent.subtitle }}</div>
            <div class="sign-in__initial-text">
              <template v-if="contextualContent.mode === 'user'">
                <template v-if="localAuthReason === 'SIGN_IN_REQUIRED'">
                  It looks like you already have an account with us. Please sign in to retrieve your
                  previous interview and pick up where you left off.
                </template>
                <template v-else>
                  If you’ve previously started an application or already have a policy with us, enter your
                  <b>email</b> to pick up where you left off.
                </template>
              </template>
              <template v-else>
                <p>
                Please enter the email address associated with your <b>{{ contextualContent.entity }} account</b> to gain access to
                the {{ contextualContent.entity }} dashboard.
                </p>
                <p>
                  If you feel like you got here by accident, please <router-link to="signin">click here</router-link>
                  to sign in regularly.
                </p>
              </template>
            </div>
            <LabeledHolder label="Email" class="sign-in__email-entry" v-model="email">
              <VTextField
                v-model="email" placeholder="Email address" type="email" :error-messages="inlineErrors" validate-on-blur
                @input="validateEmailEntry" @blur="validateEmailEntry" />
            </LabeledHolder>
            <DFButton
              class="sign-in__signin-button"
              @click="onSignInClicked"
              :disabled="hasInlineErrors"
              :is-loading="isLoading"
            >
              Sign in
            </DFButton>
          </div>
          <div class="sign-in__extra-content" v-if="contextualContent.mode === 'user' && localAuthReason !== 'SIGN_IN_REQUIRED'">
            <div class="df-content-header-text">New to Dayforward?</div>
            <div class="sign-in__initial-text">
              If you’ve never started an application, click below to see how affordable it is to secure your family’s
              future.
            </div>
            <DFButton
              class="sign-in__quick-price-button"
              variant="light"
              outlined
              @click="onQuickPriceClicked">
              Get Quick Price
            </DFButton>
          </div>
        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { apolloClient } from '@/config/apollo.config'
import { isRequired, isEmail, PATTERN_EMAIL } from '@/rules'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import { RouteName } from '@/router/routes/constants.js'
import DFButton from '@/components/DFButton.vue'
import { gotoRedirect } from '@/utils/router.js'
import LabeledHolder from '@/components/inputs/LabeledHolder.vue'
import { mapActions } from 'vuex'

// graphql queries
const validateQuery = gql`
  query AccountInfo($email: String) {
    accountInfo(email: $email) {
      exists
    }
  }
`
const magicLinkMutation = gql`
  mutation SendAdminMagicLink($email: String, $params: String) {
    sendAdminMagicLink(email: $email, queryParams: $params) {
      success
    }
  }
`

export default {
  name: 'signin',
  components: { LabeledHolder, DFButton, FlowWrapper },
  data () {
    return {
      isRequired,
      isEmail,
      inlineErrors: undefined,
      errors: undefined,
      email: window.localStorage.getItem('auth.email'),
      areEntriesValid: true,
      localAuthReason: undefined,
      isLoading: false
    }
  },
  computed: {
    contextualContent () {
      switch (this.localAuthReason) {
        case 'ADMIN_SIGNIN':
          return { header: 'Admin.', subtitle: 'Sign in.', mode: 'admin', entity: 'administrator' }
        case 'IMO_SIGNIN':
          return { header: 'Agent.', subtitle: 'Sign in.', mode: 'imo', entity: 'agent' }
        case 'SIGN_IN_REQUIRED':
          return { header: 'Sign in.', subtitle: 'Jump back in?', mode: 'user', entity: 'user' }
        default:
          return { header: 'Sign in.', subtitle: 'Returning visitor?', mode: 'user', entity: 'user' }
      }
    },
    hasInlineErrors () {
      return this.inlineErrors !== undefined || !this.email || this.email?.trim().length === 0
    },
    hasErrors () {
      return this.errors !== undefined
    }
  },
  methods: {
    ...mapActions('account', ['onSignOut']),
    hasErrorKey (key) {
      return this.errors && this.errors?.indexOf(key) !== -1
    },
    validateEmailEntry () {
      this.errors = undefined
      this.inlineErrors = undefined
      if (!this.email || this.email?.trim().length === 0) {
        this.inlineErrors = ['Please enter your email']
      } else if (!PATTERN_EMAIL.test(this.email)) {
        this.inlineErrors = ['Please enter a valid email']
      }
    },

    async onSignInClicked () {
      this.isLoading = true
      if (!this.email) {
        // TODO - no email provided
      }

      try {
        const validateRes = await apolloClient.query({
          query: validateQuery,
          variables: {
            email: this.email,
            params: ''
          }
        })
        if (!validateRes?.data?.accountInfo?.exists) {
          this.isLoading = false
          this.errors = [ 'no_account' ]
          return
        }

        // eslint-disable-next-line no-console
        console.log('Sending magic link ⭐')
        const linkRes = await apolloClient.mutate({
          mutation: magicLinkMutation,
          variables: {
            email: this.email
          }
        })
        if (!linkRes?.data?.sendAdminMagicLink?.success) {
          this.isLoading = false
          this.errors = [ 'no_email' ]
          return
        }

        // successful sign in - track identity
        const trackingAuthReason = this.localAuthReason ? '_' + this.localAuthReason.toLowerCase() : ''
        this.$analytics.identify({
          email: this.email.toLowerCase(),
          source: `signin${trackingAuthReason}`
        })
        window.localStorage.setItem('auth.email', this.email)
        window.localStorage.setItem('auth.action', 'sign-in')
        this.isLoading = false
        await this.$router.push({ name: 'sign-in-confirm' })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        this.isLoading = false
        this.errors = [ 'general' ]
      }
    },
    onQuickPriceClicked () {
      window.location = gotoRedirect('quick-price')
    }
  },
  created () {
    this.localAuthReason = window.sessionStorage.getItem('lastCategory')
    if (this.$route.name === RouteName.ADMIN_SIGNIN) {
      window.localStorage.setItem('auth.redirect', '/admin/dashboard')
      this.localAuthReason = 'ADMIN_SIGNIN'
    } else if (this.$route.name === RouteName.IMO_SIGNIN || this.$route.name === RouteName.IMO) {
      if (this.$flagsmith?.hasFeature('imo_led_interview')) {
        const redirectBaseURL = process.env.VUE_APP_REDIRECT_BASE_URL || 'https://dayforward.com'
        window.location = `${redirectBaseURL}${window.location.pathname}`
        return;
      }
      window.localStorage.setItem('auth.redirect', '/imo/dashboard')
      this.localAuthReason = 'IMO_SIGNIN'
    } else {
      // Remove from local storage if not on admin or imo
      window.localStorage.removeItem('auth.redirect')
    }
  }

}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/colors';

.sign-in {
  margin-top: 1rem;
  @include lg {
    margin-top: 2rem;
  }

  &__content {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    @include md {
      grid-template-columns: 1fr 300px;
      grid-column-gap: 60px;
    }
    @include lg {
      grid-column-gap: 100px;
      max-width: 960px;
    }
    p:not(:first-of-type) {
      margin-top: 1rem;
    }
  }

  &__initial-text {
    margin-top: 1rem;
  }

  &__quick-price-button {
    margin-top: 1rem;
    width: 100%;
    @include md {
      width: auto;
    }
  }

  &__email-entry {
    margin-top: 2rem;
    width: 100%;
    @include md {
      margin-top: 3rem;
      width: 360px;
    }
  }

  &__signin-button {
    margin-top: 1.5rem;
  }

  &__errors {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    column-gap: 20px;
    margin-top: 2rem;
    padding: 1rem;
    background: rgba($c-red-rgb, 0.05);
    border: 1px solid rgba($c-red-rgb, 0.25);
    border-radius: 6px;
    color: $c-red-rgb;
    &-icon {
      align-self: start;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
  &__input-phone {
    width: 100%;
    @include md {
      width: 180px;
    }
  }
  &__input-email {
    width: 100%;
    @include md {
      width: 380px;
    }
  }
  &__recaptcha-container {
    display: none;
  }
}

</style>
