<template>
  <div class="signout--centered">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
    <div>Signing out...</div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { gotoRedirect } from '@/utils/router'
import { getAuth, signOut } from 'firebase/auth'
import { ssAuthTokenKey } from '@/config/app.config'

export default {
  name: 'signout',

  methods: {
    ...mapActions('auth', ['signOut']),
    gotoRedirect
  },

  async created () {
    const auth = getAuth()
    await signOut(auth)
    sessionStorage.removeItem(ssAuthTokenKey)
    sessionStorage.removeItem('auth.redirect')
    this.signOut()
    window.location = this.gotoRedirect('/')
  }
}
</script>

<style scoped lang="scss">
.signout--centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > div {
    margin-top: 10px;
  }
}
</style>
