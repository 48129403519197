<template>
  <div class="incoming">
    <div class="incoming__wrapper">
      <div class="incoming__header mq-container">
        <MainNavigationHeader class="incoming__header--nav-main" />
      </div>
      <div class="incoming__main mq-container">
        <IncomingCategories
          :categories="categories"
          :activeCategory="category"
          v-if="!hideCategories"
        />
        <div class="incoming__title">
          <slot name="title"></slot>
        </div>
        <div class="incoming__content">
          <main>
            <slot name="content"></slot>
          </main>
          <aside>
            <slot name="sidebar">
              <div class="incoming__sidebar-section">
                <div class="incoming__sidebar-title">
                  <span v-if="article && article.data.sidebarTitle">{{
                    article.data.sidebarTitle
                  }}</span>
                  <span v-else>About Dayforward</span>
                  <span class="incoming__dot">.</span>
                </div>
                <div class="incoming__sidebar-content">
                  <ContentfulContentRenderer
                    v-if="article && article.data.sidebarContent"
                    :content="sidebarContentHTML"
                  />
                  <span v-else>
                    Dayforward is a new type of life insurance that can give
                    your family the financial security they deserve.
                    <a :href="gotoRedirect('why-dayforward')">Learn more</a>
                    about Income Protection or get
                    <a :href="gotoRedirect('help')">help</a> here.
                  </span>
                </div>
              </div>
              <div class="incoming__sidebar-section">
                <div class="incoming__sidebar-title">
                  Subscribe today<span class="incoming__dot">.</span>
                </div>
                <div v-if="canSubscribe" class="incoming__sidebar-content">
                  Sign up and receive our articles directly in your inbox.
                </div>
                <div v-if="canSubscribe" class="incoming__sidebar-content">
                  <v-text-field
                    v-model="email"
                    type="text"
                    placeholder="Email"
                    @focus="signUpVariant = null"
                    @blur="signUpVariant = 'light'"
                    @keydown.enter="subscribeToIncoming"
                  />
                  <DFButton
                    :variant="signUpVariant"
                    @click="subscribeToIncoming"
                    :loading="submitting"
                  >
                    Sign Up
                  </DFButton>
                  <div class="invalid" v-if="invalidEmail">
                    Please provide a valid email address.
                  </div>
                </div>
                <div v-else class="incoming__sidebar-content">
                  Thanks for signing up. We will meet you in your inbox soon.
                </div>
              </div>
              <div
                class="incoming__sidebar-section"
                v-if="mostPopularArticle && showPopularSection"
              >
                <div class="incoming__sidebar-title">
                  Most popular<span class="incoming__dot">.</span>
                </div>
                <router-link
                  :to="{
                    name: 'incoming-article',
                    params: { slug: mostPopularArticle.data.slug },
                  }"
                  class="incoming__sidebar-article"
                >
                  <div class="incoming__sidebar-image">
                    <img
                      :src="mostPopularArticle.data.feedImage.fields.file.url"
                    />
                  </div>
                  <div class="incoming__sidebar-content">
                    <strong>{{ mostPopularArticle.data.title }}</strong>
                  </div>
                </router-link>
              </div>
            </slot>
          </aside>
        </div>
      </div>
      <MainFooter />
    </div>
  </div>
</template>

<script>
import MainFooter from "@/components/navigation/MainFooter";
import Colors from "@/assets/styles/_colors.scss";
import IncomingCategories from "@/views/content/incoming/IncomingCategories";
import { processContentfulObject, processRichText } from "@/api/Content";
import { mapActions } from "vuex";
import DFButton from "@/components/DFButton";
import { PATTERN_EMAIL } from "@/rules";
import ContentfulContentRenderer from "@/components/contentful/ContentfulContentRenderer.js";
import MainNavigationHeader from "@/components/navigation/MainNavigationHeader.vue";
import { gotoRedirect } from "@/utils/router";
export default {
  name: "incoming-container",

  props: {
    category: {
      type: String,
      required: false,
      default: null,
    },
    article: undefined,
    showPopularSection: {
      type: Boolean,
      default: true,
    },
    hideCategories: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      eggshell: Colors.eggshell,
      categories: ["finance", "lifestyle", "wellness", "parenting"],
      mostPopularArticle: null,
      signUpVariant: "light",
      canSubscribe: true,
      email: "",
      invalidEmail: false,
      submitting: false,
    };
  },

  components: {
    MainNavigationHeader,
    ContentfulContentRenderer,
    MainFooter,
    IncomingCategories,
    DFButton,
  },

  computed: {
    sidebarContentHTML() {
      if (
        this.article &&
        this.article.data &&
        this.article.data.sidebarContent
      ) {
        return processRichText(this, this.article.data.sidebarContent);
      }
      return undefined;
    },
    isTaboolaArticle() {
      return (
        this.article &&
        this.article.data &&
        this.article.data.template === "taboola"
      );
    },
  },

  methods: {
    ...mapActions("content", ["fetchContentfulEntries"]),
    gotoRedirect,
    async getMostPopular() {
      const response = await this.fetchContentfulEntries({
        type: "article",
        query: {
          "fields.mostPopular": true,
          order: "-fields.date",
          limit: 1,
        },
      });

      let item = response.items[0];
      if (item) {
        this.mostPopularArticle = processContentfulObject(item);
      }
    },

    async subscribeToIncoming() {
      if (PATTERN_EMAIL.test(this.email)) {
        this.$analytics.identify({ email: this.email, source: "blog_signup" });
        this.$analytics.idempotentTrack({ event: "blog_signup" });
        this.canSubscribe = false;
        this.email = "";
        this.invalidEmail = false;
      } else {
        this.invalidEmail = true;
      }
    },
  },

  async created() {
    await this.getMostPopular();
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/flow";
@import "src/assets/styles/global";
@import "src/assets/styles/metrics";
@import "src/assets/styles/media-queries";

.incoming {
  background: $c-eggshell;
}

.invalid {
  color: $c-red;
}

.incoming__header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  height: 64px;
  z-index: 99999;

  &--nav-main {
    flex: 1;
  }
}

.incoming__dot {
  color: $c-red;
}

.incoming__title-content {
  font-size: 2.25rem;
  line-height: 2.25rem;
  font-weight: bolder;
  margin-bottom: 2rem;
  letter-spacing: -0.05em;
  @include md {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.incoming__main {
  margin-top: 3rem;

  > .incoming__categories {
    margin-bottom: 2rem;
  }
}

.incoming__content {
  display: flex;
  flex-direction: column;
  min-height: 50vh;

  @include md {
    flex-direction: row;
  }

  main {
    flex: 3;

    @include md {
      margin-right: 2rem;
    }
  }

  aside {
    flex: 1;
    margin-top: 60px;
    @include md {
      margin-top: 0;
    }

    button {
      width: 100%;
    }

    .incoming__sidebar-title {
      margin-bottom: 1rem;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: bolder;
      letter-spacing: -0.05em;
    }

    .incoming__sidebar-section {
      margin-bottom: 5rem;
    }

    a.incoming__sidebar-article {
      display: block;
      height: auto;
      text-decoration: none;
      color: $c-primary !important;

      &:hover {
        text-decoration: underline !important;
      }
    }

    .incoming__sidebar-content {
      font-size: 1rem;
      margin-bottom: 1rem;

      ::v-deep p {
        margin-top: 8px;
      }

      a.important {
        display: block;
        height: auto;
        margin-bottom: 0.25rem;
        text-decoration: none;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }

    .incoming__sidebar-image {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 100%;

      > img {
        width: 100%;
      }

      &.left {
        justify-content: flex-start;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }
    }
  }
}

::v-deep {
  .incoming-title {
    font-size: 1.4em;
    margin-bottom: 0.5rem;
  }

  .incoming-summary-title {
    @extend .df-subtitle;
    @extend .df-subtitle--small;
    @extend .df-subtitle--red;
    margin-bottom: 0.6rem;
  }
}

footer {
  margin-top: 2rem;
  @include md {
    margin-top: 5rem;
  }
}
</style>
