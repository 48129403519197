<template>
  <div class="contentful-button">
    <DFButton
      elevation="0"
      :style="buttonStyle"
      @click="onButtonPressed"
      :loading="showLoader || isRouting"
      >
      {{title}}
    </DFButton>
  </div>
</template>

<script>
import DFButton from '@/components/DFButton'

export default {
  name: 'contentful-button',
  components: { DFButton },
  props: {
    title: undefined,
    textColor: undefined,
    buttonColor: undefined,
    url: undefined,
    showLoader: undefined,
    event: undefined,
    properties: undefined
  },
  data () {
    return {
      isRouting: false
    }
  },
  computed: {
    buttonStyle () {
      const styles = {}
      if (this.textColor) {
        styles['color'] = this.textColor
      }
      if (this.buttonColor) {
        styles['background-color'] = `${this.buttonColor} !important`
      }
      return styles
    }
  },
  methods: {
    async onButtonPressed () {
      if (this.event !== undefined) {
        this.$analytics.track({
          event: this.event,
          properties: { ...this.properties, url: this.$route.path }
        })
      }
      if (this.url) {
        let routeOpts = this.url
        if (!this.url.startsWith('/')) {
          routeOpts = { name: this.url }
        }
        this.isRouting = true
        await this.$router.push(routeOpts)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/styles/colors';

.contentful-button {
  &__button {
    min-height: 64px !important;
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  &__text {
    font-size: 0.95em;
    font-weight: 400;
  }
}

</style>
