<template>
  <div class="debug-sheet">
    <div class="debug-sheet__top">
      <div class="debug-sheet__title">The Debuggeridoo&trade;</div>
      <div class="debug-sheet__copied-text">
        <transition name="copied-animation" v-on:after-enter="onCopiedTextEntered">
          <div v-show="showCopiedMessage">Copied to clipboard</div>
        </transition>
      </div>
    </div>
    <div class="debug-sheet__content">
      <div class="debug-sheet__info">
        <div class="debug-sheet__info-heading">
          Session
        </div>
        <div class="debug-sheet__info-title">FE Host</div>
        <div>{{ host }}</div>
        <div class="debug-sheet__info-title">BE Host</div>
        <div>{{ apiHost }}</div>
        <div class="debug-sheet__info-title">Path</div>
        <div>{{ $route.fullPath }}</div>
        <div class="debug-sheet__info-title tm-1r">Route (name)</div>
        <div class="tm-1r">{{ $route.name }}</div>
        <div class="debug-sheet__info-title">Route (meta)</div>
        <div class="debug-sheet__info-content">{{ $route.meta ? $route.meta : 'no meta' }}</div>
        <div class="debug-sheet__info-title tm-1r">Browser</div>
        <div class="tm-1r">{{ browserString }}</div>
        <div class="debug-sheet__info-title">Screen Size</div>
        <div>{{ screenSize }}</div>
      </div>
      <div class="debug-sheet__info">
        <div class="debug-sheet__info-heading">
          Interview
        </div>
        <div class="debug-sheet__info-title">ID</div>
        <div>{{ interviewID || 'no interview' }}</div>
        <div class="debug-sheet__info-title">Status</div>
        <div>{{ interviewStatus }}</div>
        <div class="debug-sheet__info-title">Flags</div>
        <div>{{ interviewFlags }}</div>

        <div class="debug-sheet__info-heading tm-2r">
          Build
        </div>
        <div class="debug-sheet__info-title">Git Hash</div>
        <div>{{ gitHash }}</div>

        <div class="debug-sheet__info-heading tm-2r">
          Auth
        </div>
        <div class="debug-sheet__info-title">User ID</div>
        <div>{{ accountID }}</div>
        <div class="debug-sheet__info-title">Token</div>
        <div>{{ userAuthToken }}</div>

      </div>
      <div class="debug-sheet__actions">
        <button @click.prevent="onCopyDetails">Copy Details</button>
        <button @click.prevent="onCopyCurl">Copy cURL</button>
        <button @click.prevent="onCopyQuery">Copy Query</button>
        <button class="close tm-2r" @click.prevent="onHideClicked">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'debugger-view',
  data () {
    return {
      showCopiedMessage: false,
      animTimeout: undefined,
      windowInnerSize: undefined,
      windowOuterSize: undefined
    }
  },
  computed: {
    ...mapGetters('account', ['currentAccount']),
    ...mapGetters('interview', ['interviewID', 'interview', 'lastStatus']),
    ...mapGetters('auth', ['accessToken']),
    host () {
      return window.location.host
    },
    apiHost () {
      return new URL(process.env.VUE_APP_GRAPHQL_HTTP).host
    },
    gitHash () {
      return process.env.VUE_APP_GIT_HASH || 'UNKNOWN'
    },
    interviewStatus () {
      if (this.interview) {
        return this.interview.status
      }
      return 'no status'
    },
    interviewFlags () {
      if (this.interview && this.interview.flags) {
        return this.interview.flags.map((f) => {
          const desc = {}
          if (f.type) desc.type = f.type
          if (f.cause) desc.cause = f.cause
          if (f.source) desc.type = f.source
          if (f.name) desc.name = f.name
          if (f.description) desc.description = f.description
          return desc
        })
      }
      return 'no flags'
    },
    accountID () {
      if (this.interview) {
        return this.interview.accountId
      }
      return 'no account'
    },
    browserString () {
      return navigator.userAgent
    },
    queryCommand () {
      return `query {
  interview(id: "${this.interviewID}") {
    accountId
    personId
    status
    flags {
      type
      cause
      source
      name
      description
      resolved
    }
    questions {
      key
      answers
    }
    created
    updated
    statusHistory {
      status
      time
    }
  }
}`
    },
    curlCommand () {
      const cleanQuery = this.queryCommand.replace(/\t/gm, ' ').replace(/(\r\n|\n|\r)/gm, '\\n ').replace(/"/gm, '\\"')
      return `curl 'https://api.insurews.io/graphql' -H 'Accept-Encoding: gzip, deflate, br' -H 'Content-Type: application/json' -H 'Accept: application/json' -H 'Connection: keep-alive' -H 'DNT: 1' -H 'Origin: https://api.insurews.io' --data-binary '{ "query" : "${cleanQuery}" }' --compressed`
    },
    screenSize () {
      if (!this.windowInnerSize || !this.windowOuterSize) {
        return 'undefined'
      }
      return `${this.windowInnerSize.w}x${this.windowInnerSize.h} (${this.windowOuterSize.w}x${this.windowOuterSize.h})`
    },
    userAuthToken () {
      return this.accessToken || 'Not logged in'
    },

    fullDetailsString () {
      const flags = this.interviewFlags
      let str = ''
      str += '## session\n'
      str += `- fe host: ${this.host}\n`
      str += `- be host: ${this.apiHost}\n`
      str += `- path: ${this.$route.fullPath}\n`
      str += `- route (name): ${this.$route.name}\n`
      str += `- route (meta): ${JSON.stringify(this.$route.meta)}\n`
      str += `- browser: ${this.browserString}\n`
      str += `- screen size: ${this.screenSize}\n\n`
      str += '## interview:\n'
      str += `- id: ${this.interviewID || 'no interview'}\n`
      str += `- status: ${this.interviewStatus}\n`
      str += `- flags:`
      if (Array.isArray(flags)) {
        str += '\n'
        flags.forEach(f => {
          str += `  - ${JSON.stringify(f)}\n`
        })
      } else {
        str += `${flags}\n`
      }
      str += '\n## build:\n'
      str += `- git hash: ${this.gitHash}\n\n`
      str += '# auth:\n'
      str += `- user id: ${this.accountID}\n`
      str += `- auth token: ${this.userAuthToken}\n\n`
      return str
    }
  },
  methods: {
    onHideClicked () {
      this.$emit('input', false)
    },
    onCopyDetails () {
      this.copyToClipboard(this.fullDetailsString)
      this.showCopiedMessage = true
    },
    onCopyQuery () {
      this.copyToClipboard(this.queryCommand)
      this.showCopiedMessage = true
    },
    onCopyCurl () {
      this.copyToClipboard(this.curlCommand)
      this.showCopiedMessage = true
    },
    copyToClipboard (str) {
      if (navigator.clipboard !== undefined) {
        navigator.clipboard.writeText(str).then(() => {
        }, (err) => {
          // eslint-disable-next-line no-console
          console.error('could not write to clipboard')
          // eslint-disable-next-line no-console
          console.error(err)
        })
      } else if (window.clipboardData) {
        window.clipboardData.setData('Text', str)
      }
    },
    onCopiedTextEntered () {
      if (this.animTimeout) {
        clearTimeout(this.animTimeout)
      }
      this.animTimeout = setTimeout(() => {
        this.showCopiedMessage = false
      }, 2000)
    },
    onResizeEvent () {
      this.windowInnerSize = { w: window.innerWidth, h: window.innerHeight }
      this.windowOuterSize = { w: window.outerWidth, h: window.outerHeight }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.windowInnerSize = { w: window.innerWidth, h: window.innerHeight }
      this.windowOuterSize = { w: window.outerWidth, h: window.outerHeight }
      window.addEventListener('resize', this.onResizeEvent)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResizeEvent)
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/metrics";
@import "src/assets/styles/transitions";

.debug-sheet {
  background-color: rgba($c-primary, 0.95);
  min-height: 300px;
  padding: 30px 30px 50px;
  color: #fff;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.8em;
  align-items: flex-start;
  z-index: 99999;
  &__top {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 20px;
  }
  &__title {
    font-size: 1.3em;
    margin-bottom: 20px;
  }
  &__copied-text {
    justify-self: end;
    color: #d8ff6d;
  }
  &__content {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    column-gap: 20px;
  }
  &__info {
    display: grid;
    align-content: start;
    grid-template-columns: 150px 1fr;
  }
  &__info-heading {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-bottom: 5px;
    text-decoration: underline;
    color: #c1f8ff;
    text-decoration-color: rgba(#c1f8ff, 0.3);
  }
  &__info-title {
    font-weight: 700;
    margin-right: 15px;
  }
  &__info-content {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: auto;
    max-height: 80px;
    padding-right: 5px;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    button {
      background-color: rgba(#fff, 0.08);
      min-width: 120px;
      margin-bottom: 10px;
      padding: 8px 10px;
      border-radius: 5px;
      &:hover {
        background-color: rgba(#fff, 0.15);
      }
      &.close {
        background-color: #fff;
        color: $c-primary;
        &:hover {
          background-color: rgba(#fff, 0.85);
        }
      }
    }
  }
}

.copied-animation-enter-active {
  transition: all .25s ease;
}
.copied-animation-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.copied-animation-enter, .copied-animation-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>
