<template>
  <div class="dot-spinner-stage">
    <div aria-label="Loading" class="dot-flashing">
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
</template>

<script>
export default {
  name: 'dots-spinner'
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 * src: https://github.com/nzbin/three-dots/blob/master/sass/_dot-flashing.scss
 */

.dot-spinner-stage {
  position: relative;
  overflow: hidden;
}

$dot-width: 10px !default;
$dot-height: 10px !default;
$dot-radius: $dot-width/2 !default;
$dot-spacing: $dot-width + $dot-width/2 !default;

$dot1-color: $c-yellow !default;
$dot2-color: $c-red !default;
$dot3-color: $c-yellow !default;
$dot4-color: $c-red !default;

$dot-bg-color: $c-yellow !default;

@mixin dot(
  $width: $dot-width,
  $height: $dot-height,
  $radius: $dot-radius,
  $bg-color: $dot-bg-color,
) {
  width: $width;
  height: $height;
  border-radius: $radius;
  background-color: $bg-color;

  &::before {
    content: ' ';
    display: block;
    width: $width;
    height: $height;
    border-radius: $radius;
    top: 0;
    left: 0;
    background-color: $bg-color;
  }
}

.dot-flashing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: $dot-spacing * 4;
  height: $dot-height;

  span {
    @include dot;
    display: inline-block;
    animation: dot-flashing 1s infinite linear;
    animation-delay: 0s;
    position: relative;
  }

  span:nth-child(2) {
    @include dot($bg-color: $dot2-color);
    animation-delay: 0.25s;
  }

  span:nth-child(3) {
    @include dot($bg-color: $dot3-color);
    animation-delay: 0.5s;
  }

  span:last-child {
    @include dot($bg-color: $dot4-color);
    animation-delay: 0.75s;
  }
}

@keyframes dot-flashing {
  0% {
    opacity: 1
  }

  50%,
  75%,
  100% {
    opacity: 0.5;
  }
}
</style>
