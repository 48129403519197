import Colors from '@/assets/styles/_colors.scss'

const theme = {
  // NOTE:
  // if you make changes to this file, please make a change in the
  // src/styles/_media-queries.scss file as well.
  breakpoints: {
    md: 600,
    lg: 900,
    xl: 1440
  },

  // NOTE:
  // Colors are exported from '@/assets/styles/_colors.scss'
  colors: Colors
}

export default theme
