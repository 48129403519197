import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client'
import { gqlEndpointURL, ssAuthTokenKey } from '@/config/app.config'
import { getAuth } from 'firebase/auth'

const requestIDLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext()
  if (typeof context.headers !== 'object') {
    context.headers = {}
  }
  // set directly so we dont overwrite existing/important headers (e.g. Authorization)
  context.headers['X-Request-ID'] = context.requestId ?? Math.random().toString(36).substr(2)
  operation.setContext(context)
  return forward(operation).map(response => {
    const headers = operation.getContext().headers
    const res = response
    if (headers['X-Request-ID']) {
      res.requestId = headers['X-Request-ID']
    }
    return res
  })
})

// Config
const defaultOptions = {
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,
  // Custom starting link
  // we will add per-request headers (adding them to httpLinkOptions is global/static)
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, extensions }) => {
          // eslint-disable-next-line no-console
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          if (extensions) {
            // eslint-disable-next-line no-console
            console.error(`  Extensions: ${extensions}`)
          }
        })
      }

      if (networkError) {
        // eslint-disable-next-line no-console
        console.log(`[Network error]: ${networkError}`)
      }
    }),
    requestIDLink
  ]),

  // auth token key
  tokenName: ssAuthTokenKey,
  // Bearer / Auth token getter
  getAuth: (tokenName) => {
    const auth = getAuth()
    const token = auth?.currentUser?.accessToken
    if (token) {
      return `bearer ${token}`
    }
    return undefined
  }
}

const { apolloClient } = createApolloClient({
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint: gqlEndpointURL,
  ...defaultOptions
})

export { apolloClient }

// Manually call this when user log in
export async function onLogin (apolloClient, token, isAdmin) {
  if (typeof window.sessionStorage !== 'undefined' && token) {
    // eslint-disable-next-line no-console
    window.sessionStorage.setItem(ssAuthTokenKey, token)
  }
  await apolloClient.resetStore()
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof window.sessionStorage !== 'undefined') {
    // eslint-disable-next-line no-console
    window.sessionStorage.removeItem(ssAuthTokenKey)
  }
  await apolloClient.resetStore()
}
