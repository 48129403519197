<template>
  <div class="flags">
    <h3>Change Issuer:</h3>
    <div class="mq-grid">
      <div class="cols-6">
        <v-select
          item-value="value"
          item-text="label"
          :items="products"
          v-model="selected"
        ></v-select>
      </div>

      <DFButton :loading="changeVendorLoading" @click="changeVendor">Change Issuer</DFButton>
      <span class="change-vendor-error">{{ changeVendorError }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DFButton from '@/components/DFButton.vue'
import { RouteName } from '@/router/routes/constants'

export default {
  name: 'admin-copy-interview-change-vendor',
  components: { DFButton },
  props: {
    interview: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      changeVendorError: null,
      selected: null,
      changeVendorLoading: false,
      products: [
        { label: 'CTL', value: 'CTL' },
        { label: 'DAYFORWARD', value: 'DAYFORWARD' }
      ]
    }
  },
  methods: {
    ...mapActions('admin', ['resolveSpousalSignature', 'changeIssuer']),
    async changeVendor () {
      this.changeVendorLoading = true
      const { error, interview } = await this.changeIssuer({ id: this.interview.id, vendor: this.selected })
      if (error) {
        this.error = error
      }
      const { id, accountId } = interview
      await this.$router.push({ name: RouteName.ADMIN_DETAIL_ACCOUNT, params: { accountId, id } })
      this.changeVendorLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
.change-vendor-error {
  color: $c-error;
  font-size: small;
}
</style>
