<template>
  <ContentfulDocument
    content-id="6lNWdMQ3qcl7HwnpEr3mun"
    :progress="0"
    header-variant="red"
  >
    <template v-slot:content="{document}">
      <div class="document-content">
        <ContentfulContentRenderer :content="document.bodyParsed" />
      </div>
    </template>
  </ContentfulDocument>
</template>

<script>
import ContentfulDocument from '@/views/contentful/ContentfulDocument.vue'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'
export default {
  name: 'terms-and-conditions',
  components: { ContentfulContentRenderer, ContentfulDocument }
}
</script>

<style scoped lang="scss">
::v-deep {
  .flow__content {
    p {
      margin-bottom: 1.2rem;
    }
    li {
      margin-bottom: 1rem;
    }
  }
}
.document-content {
  padding-bottom: 5rem;
}
</style>
