<template>
  <div class="contentful-button-group" :style="styles">
    <component v-for="b in keyedButtons" :key="b.key" :is="buttonType" v-bind="b.obj.fields" />
  </div>
</template>

<script>

import ContentfulButton from '@/components/contentful/ContentfulButton'

export default {
  name: 'contentful-button-group',
  components: { ContentfulButton },
  props: {
    buttons: undefined,
    styles: undefined,
    event: undefined,
    properties: undefined
  },
  data () {
    return {
      buttonCount: 0
    }
  },
  computed: {
    buttonType: () => 'ContentfulButton',
    keyedButtons () {
      return this.buttons.map((b) => {
        if (this.event !== undefined) b.fields.event = this.event
        if (this.properties !== undefined) b.fields.properties = { ...this.properties }
        return { key: this.nextButtonKey(), obj: b }
      })
    }
  },
  methods: {
    nextButtonKey () {
      return `button-${this.buttonCount++}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/media-queries";

.contentful-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  margin: 0 30px;
  @include lg {
    padding-top: 60px;
    margin: 0;
  }
  ::v-deep .contentful-button {
    width: 100%;
    @include md {
      width: auto;
    }
    &:not(:last-child) {
      margin-top: 25px;
      @include md {
        margin-top: 0;
        margin-right: 1rem;
      }
    }
  }
}
</style>
