<template>
  <div class="padding">
    <footer>
      <div class="mq-container">
        <div class="footer__wrapper mq-grid">
          <div class="cols-10 cols-md-3 cols-lg-2 logo-column">
            <a :href="gotoRedirect(RedirectRouteNames.HOME)">
              <Logo class="logo" size="26" :color="Colors.eggshell" />
            </a>
          </div>
          <div class="cols-10 cols-md-7 cols-lg-8 mq-grid">
            <div class="footer__links cols-10 cols-md-5 cols-lg-3">
              <a :href="gotoRedirect(RedirectRouteNames.WHY_DAYFORWARD)"
                >Why Dayforward</a
              >
              <a :href="gotoRedirect(RedirectRouteNames.HELP)">Help</a>
              <router-link to="incoming">Blog</router-link>
              <a
                v-if="showReviews"
                :href="gotoRedirect(RedirectRouteNames.REVIEWS)"
                >Reviews</a
              >
            </div>
            <div class="footer__links cols-10 cols-md-5 cols-lg-3">
              <a :href="gotoRedirect(RedirectRouteNames.ABOUT)">About</a>
              <a :href="gotoRedirect(RedirectRouteNames.CAREERS)">Careers</a>
              <router-link v-if="isAuthenticated" to="/signout"
                >Sign out</router-link
              >
              <router-link v-else to="/signin">Sign in</router-link>
            </div>
            <div class="footer__links cols-10 cols-md-5 cols-lg-3">
              <DFButton
                v-if="showHelpButton"
                class="cta__button"
                elevation="0"
                min-height="46px"
                v-on:click.native="openChat"
                >Contact us</DFButton
              >
              <DFButton
                v-else
                class="cta__button"
                elevation="0"
                min-height="46px"
                v-on:click.native="onLinkPressedRedirect('get-started')"
                button-location="main-footer"
                >Get Started</DFButton
              >
            </div>
          </div>
          <div class="cols-10 cols-md-3 cols-lg-2 social-column">
            <p>Follow us</p>
            <a href="https://www.instagram.com/dayfwrd/" target="_blank">
              <img
                src="@/assets/images/icon_instagram.svg"
                height="30"
                aria-label="instagram"
                alt="Instagram"
              />
            </a>
            <a href="https://www.facebook.com/dayfwrd" target="_blank">
              <img
                src="@/assets/images/icon_facebook.svg"
                height="30"
                aria-label="facebook"
                alt="Facebook"
              />
            </a>
            <a href="https://twitter.com/dayfwrd" target="_blank">
              <img
                src="@/assets/images/icon_twitter.svg"
                height="30"
                aria-label="twitter"
                alt="Twitter"
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCqE-G0NYGPHPaHYEhNm9vbg/featured?view_as=subscriber"
              target="_blank"
            >
              <img
                src="@/assets/images/icon_youTube.svg"
                height="30"
                aria-label="youtube"
                alt="YouTube"
              />
            </a>
            <a href="https://linkedin.com/company/dayfwrd" target="_blank">
              <img
                src="@/assets/images/icon_linkedin.svg"
                height="30"
                aria-label="linkedin"
                alt="Linkedin"
              />
            </a>
          </div>
          <div class="footer__legal cols-10 cols-md-9 cols-lg-7">
            <div class="footer__link-list">
              <div>
                <a :href="gotoRedirect(RedirectRouteNames.TERMS)"
                  >Terms of Use</a
                >
              </div>
              <div>
                <a :href="gotoRedirect(RedirectRouteNames.PRIVACY)"
                  >Privacy Policy</a
                >
              </div>
            </div>
            <div
              class="main-footer__legal-copy"
              v-if="variant === 'baseline_with_arizona'"
            >
              Life insurance provided by Dayforward Life Insurance Company Inc.
              ("DLIC"), Austin, Texas, which is an insurance company currently
              licensed with available policies for approved applicants in Texas
              (DLI-TRMPOL-001), Utah (DLI-TRMPOL-001UT) and Arizona
              (DLI-TRMPOL-002AZ) only. Life insurance is subject to certain
              conditions, limitations, and exclusions. DLIC sells policies
              through Dayforward Insurance Agency Inc. ("DIA"), which is a
              licensed insurance agency in all states and acts as the agent of
              DLIC in selling approved DLIC insurance policies in the states
              where both are duly licensed. DIA receives compensation based on
              the premiums for the policies it sells for DLIC and other
              insurance companies. Additional information available upon
              request.
            </div>
            <div class="main-footer__legal-copy" v-else>
              Life insurance provided by Dayforward Life Insurance Company Inc.
              ("DLIC"), Austin, Texas, which is an insurance company currently
              licensed with an approved policy only in Texas (DLI-TRMPOL-001)
              and Utah (DLI-TRMPOL-001UT). Life insurance is subject to certain
              conditions, limitations, and exclusions. DLIC sells policies
              through Dayforward Insurance Agency Inc. ("DIA"), which is a
              licensed insurance agency in all states and acts as the agent of
              DLIC in selling approved DLIC insurance policies in the states
              where both are duly licensed. DIA receives compensation based on
              the premiums for the policies it sells for DLIC and other
              insurance companies. Additional information available upon
              request.
            </div>
            <div class="footer__legal-copy">
              &copy; {{ year }} Dayforward Inc. All Rights Reserved. Dayforward
              is a trademark of Dayforward Inc.
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'
import Logo from '../accessories/Logo.vue'
import { mapGetters } from 'vuex'
import DFButton from '@/components/DFButton.vue'
import { gotoRedirect } from '@/utils/router'
import { RedirectRouteNames } from '@/router/routes/constants'

export default {
  name: 'MainFooter',
  components: { DFButton, Logo },
  data: function () {
    return {
      Colors: Colors,
      RedirectRouteNames,
      variant: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    showHelpButton() {
      return this.isAuthenticated || this.$route.path.startsWith('/interview')
    },
    year() {
      return new Date().getFullYear()
    },
    showReviews() {
      return this.$flagsmith?.hasFeature('reviews_page')
    }
  },
  methods: {
    gotoRedirect,
    async openChat() {
      await this.$router.push({ name: 'help' })
    },
    onLinkPressedRedirect() {
      this.$analytics.track({
        event: 'app_clickGetStarted',
        properties: {
          button_location: 'main_footer',
          url: this.$route.path
        }
      })
      window.location = gotoRedirect(RedirectRouteNames.GET_STARTED)
    }
  },
  async created() {
    this.variant = 'baseline'
    if (this.$flagsmith?.hasFeature('arizona_release')) {
      this.variant = 'baseline_with_arizona'
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/metrics';

.padding {
  padding-top: 5rem;
}

footer {
  color: $c-eggshell;
  background: $c-primary;
  width: 100%;
}

.footer {
  &__wrapper {
    padding: 6rem 0 0;
    @include lg {
      padding: 5.5rem 0 5rem;
      margin: 0;
    }
  }
  &__link-list,
  &__links {
    display: flex;
    flex-direction: column;
    a {
      color: $c-eggshell !important;
      display: inline-block;
      height: 34px;
      text-decoration: none;
      transition: all 0.3s linear;
      &:hover {
        opacity: 0.8;
      }
    }

    ::v-deep button.cta__button {
      background-color: transparent !important;
      border: 1px solid rgba(242, 237, 224, 0.5) !important;
      border-radius: 6px;
      color: $c-eggshell !important;
      margin-top: 1.25rem;
      margin-bottom: 1.5rem;
      width: 100% !important;
      font-size: 16px;
      padding: 0 20px !important;
      max-width: 170px;
      letter-spacing: 0;
      @include md {
        margin-top: 0;
        margin-bottom: 0;
        width: 80% !important;
      }
      .v-btn__content {
        color: $c-eggshell !important;
      }
    }
  }
  &__link-list {
    display: flex;
    flex-direction: row;
    a {
      font-size: 1.17em;
      flex: 1;
      margin-right: 20px;
    }
  }
  &__legal {
    margin-top: 0;
    @include lg {
      margin-top: 5rem;
    }
    font-size: 0.75em;
    font-weight: 300;
    opacity: 0.9;
  }
  &__legal-copy {
    padding-top: 8px;
  }
}

.social-column {
  margin-top: 1rem;
  @include lg {
    margin-top: 5rem;
  }

  p {
    margin-bottom: 1rem;
  }

  a:first-of-type {
    margin-left: -3px;
  }

  svg {
    fill: $c-eggshell;
  }
}

.logo-column {
  a {
    text-decoration: none;
  }
}

.logo {
  margin-top: 0.3em;
}

p {
  margin-top: 0 !important;
}
</style>
