import gql from 'graphql-tag'

export default {
  accountInfo: (email) => ({
    query: gql`
      query AccountInfo($email: String) {
        accountInfo(email: $email) {
          exists
        }
      }
    `,
    variables: {
      email
    }
  }),
  create: (token, email) => ({
    mutation: gql`
      mutation CreateAccount($token: String, $email: String) {
        createAccount(token: $token, email: $email) {
          id
          email
          customToken
        }
      }
    `,
    variables: {
      token: token,
      email: email
    }
  }),
  resolve: (token, email) => ({
    mutation: gql`
      mutation ResolveAccount($token: String, $email: String) {
        resolveAccount(token: $token, email: $email) {
          id
          isUnderwriter
          isCustomerService
          email
          userRole
          userRole
          interviews {
            id
          }
        }
      }
    `,
    variables: {
      token: token,
      email: email
    }
  }),
  SendAdminMagicLink: (email) => ({
    mutation: gql`
      mutation SendAdminMagicLink($email: String!) {
        sendAdminMagicLink(email: $email) {
          success,
          message
        }
      }
    `,
    variables: {
      email: email
    }
  }),
  get: (id, token, email) => ({
    query: gql`
      query GetAccount($id: String, $token: String, $email: String) {
        account(id: $id, token: $token, email: $email) {
          id
          isUnderwriter
          isCustomerService
          email
        }
      }
    `,
    variables: {
      token: token,
      id: id,
      email: email
    }
  }),
  interviews: () => ({
    query: gql`
      query {
        accountInterviews: accountInterviewSummaries {
          id
          status
          flags {
            type
            cause
            source
            name
            created
            resolved
            cause
          }
          currentSectionId
          firstName
        }
      }
    `
  })
}
