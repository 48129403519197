<template>
  <div class="text-two-column">
    <div class="text-column" :style="flexStyle">
      <slot name="title"></slot>
      <slot name="column1"></slot>
    </div>
    <div class="text-column">
      <slot name="column2"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'text-two-column',

  props: {
    isCalculation: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    flexStyle () {
      const s = {}
      s['justify-content'] = this.isCalculation === true ? 'center' : 'flex-start'
      return s
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/_media-queries.scss";
@import "src/assets/styles/global";

.text-two-column {
  display: grid;
  grid-template-rows: auto 1fr;
  // grid-row-gap: 20px;
  grid-row-gap: 5px;
  @include md() {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
  // min-height: 300px;
}

.text-column {
  display: flex;
  flex-direction: column;
}

</style>
