import { ssKeyContentItems } from '@/config/app.config'
import { contentful, contentfulPreview, contentIDForKey } from '@/config/contentful.config'

const getClient = () => {
  return window.location.search.includes('draft') ? contentfulPreview : contentful
}

const contentModule = {
  namespaced: true,
  name: 'ContentModule',
  state: {
    contentItems: {}
  },
  getters: {
    contentItems: (state) => state.contentItems,
    contentItemForID: (state) => (id) => state.contentItems[id]
  },
  actions: {
    // content loading methods
    async fetchContentfulEntryOrCache ({ commit, dispatch, getters }, payload) {
      let id = payload.id
      if (!id) {
        if (!payload.key) {
          return null
        }
        id = contentIDForKey(payload.key)
        if (!id) {
          return null
        }
      }
      payload.id = id
      return dispatch('fetchContentfulEntryByID', payload)
    },

    async fetchContentfulEntries (_, { type, query = {} }) {
      return getClient().getEntries({
        'content_type': type,
        ...query
      })
    },

    async fetchContentfulAsset ({ commit }, payload) {
      const asset = await getClient().getAsset(payload.id)

      if (asset) {
        const { fields: { file: { url } } } = asset
        if (url) {
          return url
        }
      }
    },

    async fetchContentfulEntryBySlug ({ commit }, { slug, contentType }) {
      const entries = await getClient().getEntries({
        'content_type': contentType || 'structuredDocument',
        'fields.slug[in]': slug
      })

      if (entries) {
        return entries
      }
    },

    async fetchContentfulEntryByID ({ commit }, payload) {
      let query = payload.query
      if (!query) {
        query = {
          include: 3
        }
      }
      const entry = await getClient().getEntry(payload.id, query)
      if (entry && !payload.skipCache) {
        commit('ADD_CONTENT_ITEM', {
          id: payload.id, item: entry
        })
      }
      return entry
    },

    reloadContentCache ({ commit }) {
      const contentItems = JSON.parse(window.sessionStorage.getItem(ssKeyContentItems))
      if (contentItems) {
        commit('UPDATE_CONTENT_ITEMS', contentItems)
      }
    }
  },
  mutations: {
    ADD_CONTENT_ITEM (state, payload) {
      state.contentItems[payload.id] = payload.item
      window.sessionStorage.setItem(ssKeyContentItems, JSON.stringify(state.contentItems))
    },
    UPDATE_CONTENT_ITEMS (state, payload) {
      state.contentItems = payload
      window.sessionStorage.setItem(ssKeyContentItems, JSON.stringify(state.contentItems))
    }

  }
}

export default contentModule
