<template>
  <div class="flow">
    <div class="flow__content-wrapper" :class="wrapperClasses">
      <header class="df-header df-header__p-top flow__header" :class="extraClasses">
        <slot name="navigation" v-if="showNavigation">
          <MainNavigationHeader
            :hideMenu="hideMenu"
            :hide-sign-in="hideSignIn"
            :color="navigationColor"
            class="flow__navigation-header mq-container"
          />
        </slot>
        <slot name="header">
          <div class="flow__header-text df-header-text mq-container" :class="headerClasses">
            <slot name="header-text">
              <div v-if="headerText">
                {{ headerText }}
              </div>
            </slot>
          </div>
          <div class="flow-sub-content mq-container">
            <slot name="sub-content"></slot>
          </div>
        </slot>
      </header>
      <!-- Inline error view -->
      <div class="flow__error-inline">
        <slot name="inline-error"></slot>
      </div>
      <div class="flow__content-header mq-container" :class="contentHeaderClasses">
        <slot name="content-header"></slot>
      </div>
      <div class="mq-container" :class="!fullWidth ? 'flow__content' : 'flow__content-full-width'">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="flow__buttons" v-if="showButtons">
      <div class="flow__buttons-wrapper mq-container">
        <BackForwardButtons
          class="flow__buttons-component"
          :next-action="onNextPressed"
          :back-action="onBackPressed"
          :next-disabled="canMoveToNext === false"
          :next-title="nextTitle"
          :back-title="backTitle"
          :showNext="showNext"
          :showBack="showBack"
        />
      </div>
    </div>
    <MainFooter/>
  </div>
</template>

<script>
import _get from 'lodash/get'
import BackForwardButtons from '@/components/navigation/BackForwardButtons.vue'
import Colors from '@/assets/styles/_colors.scss'
import MainFooter from '@/components/navigation/MainFooter.vue'
import MainNavigationHeader from '../../components/navigation/MainNavigationHeader.vue'
import { mapActions, mapGetters } from 'vuex'
import { InterviewFlagCause, InterviewStatus } from '@/api/Interview'
import { getFlowStepInfo } from '@/api/StepInfo'
import { RouteName } from '@/router/routes/constants'

export default {
  // FlowWrapper is a component that handles the majority of the standardized infterview step
  // workflow. It is configurable to meet the common use case and visual needs for each step.
  // FlowWrapper will automatically save the current interview values when next + back are pressed
  // and will emit a `result` callback when the save process completes.

  name: 'flow-wrapper',
  components: {
    BackForwardButtons,
    MainNavigationHeader,
    MainFooter
  },
  props: {
    showNext: {
      type: Boolean,
      default: true
    },
    showBack: {
      type: Boolean,
      default: true
    },
    // visual options
    headerVariant: {
      type: String
    },
    progress: {
      type: Number,
      default: 0
    },
    showNavigation: {
      type: Boolean,
      default: true
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showProgress: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    backTitle: {
      type: String
    },
    // state from the parent flow component
    canMoveToNext: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },

    // saving
    questionKeys: {
      type: Array
    },
    answerValues: {
      type: Object
    },

    // onSave
    onSave: {
      type: Function,
      required: false
    },

    // lifecycle

    // the 'before' actions allows you to both execute arbitrary code before the default action and to act as a 'gate'
    // to the default functionality. the 'before' actions will be executed prior to ANY default functionality. the
    // function must return an object that looks like `{ proceed: true|false }`. If no value is returned then the
    // chain will be halted and the default action will not be executed.

    // beforeBack allows you to execute arbitrary code before the default back action.
    beforeBack: {
      type: Function,
      required: false
    },

    // beforeNext allows you to execute arbitrary code before the default next action.
    beforeNext: {
      type: Function,
      required: false
    },

    // the following functions, onBack/onNext, will execute AFTER the logic for saving the interview and
    // evaluating the offramps but before navigation happens. This allows you to capture the output of the
    // default actions and do custom processing if you prefer. If you wish to execute custom logic AND still
    // perform the default navigation, you can do that also.
    //
    // these actions should return an object that looks like `{ navigate: true|false }`. if the value is true,
    // the default navigation logic will be executed. If no value is returned, or the value is false, then the
    // the default navigation logic will not be executed.

    // onBack allows to execute arbitrary code prior to the navigation step and to halt default navigation if you
    // need to.
    onBack: {
      type: Function
    },

    // onNext allows to execute arbitrary code prior to the navigation step and to halt default navigation if you
    // need to.
    onNext: {
      type: Function
    },
    nextTitle: {
      type: String,
      required: false,
      default: 'NEXT'
    },
    hideMenu: {
      type: Boolean,
      default: false
    },
    hideSignIn: {
      type: Boolean,
      default: false
    },
    saveOnBack: {
      type: Boolean,
      default: true
    },
    offRampFlag: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      Colors,
      headerText: undefined,
      progressOverride: undefined
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('interview', [
      'hasSavedInterview',
      'interviewID',
      'mapToAnswers'
    ]),

    isSaveableRoute () {
      let keys, values
      try {
        keys = this.questionKeys
        values = this.answerValues
      } catch (e) {
        return false
      }
      return !!keys && !!values
    },

    getProgress () {
      if (this.progressOverride !== undefined) {
        return this.progressOverride
      } else if (this.progress) {
        return this.progress
      }
      return undefined
    },

    isProgressVisible () {
      return this.showProgress && !this.headerVariant
    },

    shouldShowActions () {
      return this.showActions
    },

    navigationColor () {
      let color = Colors.red
      if (this.headerVariant) {
        if (this.headerVariant === 'red') {
          color = Colors.eggshell
        }
      }
      return color
    },

    logoVariant () {
      let variant = 'red'
      if (this.headerVariant) {
        if (this.headerVariant === 'red') {
          variant = 'oat'
        }
      }
      return variant
    },

    extraClasses () {
      const extraClasses = []
      if (this.headerVariant) {
        extraClasses.push(`flow__header--${this.headerVariant}`)
      }
      return extraClasses
    },

    wrapperClasses () {
      const wrapperClasses = []
      if (!this.showButtons) {
        wrapperClasses.push('flow__content-wrapper--no-buttons')
      }
      return wrapperClasses
    },

    headerClasses () {
      const headerClasses = []
      if (this.showNavigation) {
        headerClasses.push('flow__header-text--taller')
      }
      return headerClasses
    },

    contentHeaderClasses () {
      const headerClasses = []
      if (this.fullWidth) {
        headerClasses.push('flow__content-full-width')
      } else {
        headerClasses.push('flow__content')
      }
      return headerClasses
    },

    showSaveMessage () {
      return this.isAuthenticated
    }
  },
  methods: {
    ...mapActions('interview', [
      'getStatus',
      'updateInterviewAnswers'
    ]),

    // save is  the default save routine when we click the next or back button
    async save () {
      // added this try catch because save was failing if no current answers
      // were selected on the current page. This catches that the current
      // question is not being saved and warns in the console
      const result = await this.updateInterviewAnswers({
        id: this.interviewID,
        answers: this.mapToAnswers(this.answerValues, this.questionKeys)
      })
      return result
    },
    async defaultNextNavigation () {
      // do navigation to next step
      if (this.$route.meta && this.$route.meta.interview) {
        const nextStep = this.$route.meta.interview.nextStep
        if (nextStep) {
          let routeObj
          if (typeof nextStep === 'string') {
            routeObj = { name: nextStep }
          } else if (typeof nextStep === 'object') {
            routeObj = nextStep
          }
          await this.$router.push(routeObj)
        }
      }
    },
    async defaultBackNavigation () {
      // do navigation to previous step
      if (this.$route.meta && this.$route.meta.interview) {
        const previousName = this.$route.meta.interview.previousStep
        if (previousName) {
          await this.$router.push({ name: previousName })
        } else {
          await this.$router.go(-1)
        }
      } else {
        await this.$router.go(-1)
      }
    },

    // our only off-ramp currently is location
    offRampRouteForFlag (checkFlag) {
      switch (checkFlag) {
        case InterviewFlagCause.LOCATION:
          return RouteName.OR_WAITLIST
      }
    },

    async getFlags () {
      const statusResult = await this.getStatus({ id: this.interviewID })
      return statusResult.flags.filter((f) => f.type === InterviewStatus.INELIGIBLE)
    },

    async onNextPressed () {
      let result
      if (this.beforeNext) {
        // run the before action for the component implementing the FlowWrapper first. If the the action
        // returns `false` or `undefined` then we stop and don't execute any of the default logic
        result = await this.beforeNext()
        if (!result || (result && !result.proceed)) {
          return
        }
      }
      // save answers and check for errors
      if (this.questionKeys && this.answerValues && this.questionKeys.length > 0) {
        const { data, reflexives, errors } = await this.save()
        this.$emit('result', { data, reflexives, errors, action: 'next' })
        if (errors) {
          return
        }
      }

      // offramp
      if (this.offRampFlag !== undefined) {
        const flags = await this.getFlags()
        if (flags.length > 0) {
          const offrampRoute = this.offRampRouteForFlag(this.offRampFlag)
          await this.$router.push({ name: offrampRoute })
          return
        }
      }

      this.$emit('next') // notify that next executed the default steps

      let shouldNavigate = true
      if (this.onNext) {
        const result = await this.onNext()
        if (result && result.navigate !== undefined) {
          shouldNavigate = result.navigate
        }
      }

      if (shouldNavigate) {
        await this.defaultNextNavigation()
      }
    },

    async onBackPressed () {
      let result
      if (this.beforeBack) {
        // run the before action for the component implementing the FlowWrapper first. If the the action
        // returns `false` or `undefined` then we stop and don't execute any of the default logic
        result = await this.beforeBack()
        if (!result || (result && !result.proceed)) {
          return
        }
      }
      // save answers and check for errors
      if (this.saveOnBack && this.questionKeys && this.answerValues && this.questionKeys.length > 0) {
        this.save().then(({ data, reflexives, errors }) => {
          this.$emit('result', { data, reflexives, errors, action: 'back' })
        })
        // NOTE: we can't halt navigation if it errors otherwise you will need to fill out all required
        // information before pressing back
        // if (errors) {
        //   return
        // }
      }
      this.$emit('back') // notify that next executed the default steps

      let shouldNavigate = true
      if (this.onBack) {
        const result = await this.onBack()
        if (result && result.navigate !== undefined) {
          shouldNavigate = result.navigate
        }
      }

      if (shouldNavigate) {
        await this.defaultBackNavigation()
      }
    },

    afterEnter () {
      // force focus onto the first element in the slow after tranisiton is completed
      const firstItem = _get(this.$slots, 'default[0].elm[0]')
      if (firstItem) firstItem.focus()
    }
  },
  created () {
    const { title, step, totalSteps } = getFlowStepInfo(this.$route)
    if (title) this.headerText = title
    if (step !== undefined && totalSteps !== undefined) {
      this.progressOverride = step / totalSteps
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/media-queries";
@import "src/assets/styles/metrics";
@import "src/assets/styles/colors";
@import "src/assets/styles/transitions";

$button-section-p-top: 80px;
$button-section-p-bottom: 0px;
$button-section-height: 100px;
$md-content-max-width: 85%;
$lg-content-max-width: 80%;

.flow {

  &__content-wrapper {
    min-height: calc(100vh - 40px - (#{$button-section-height} + #{$button-section-p-top} + #{$button-section-p-bottom}));
    &--no-buttons {
      min-height: 100vh;
    }
  }

  &__header {
    &--red {
      background-color: $c-red;

      .flow__header-text {
        color: $c-eggshell;
      }
    }
  }

  .flow__header-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
  }

  &__progress {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 1rem;
    height: 15px;

    &-bar {
      height: 5px !important;
    }
  }

  &__save-progress-button {
    margin-top: 15px;
    min-height: 36px;
    padding-top: 0;
    padding-bottom: 0;
    border-color: rgba($c-red, 0.4);
    color: $c-red;
  }

  &__save-progress-confirmation {
    font-size: 0.95rem;
    color: #333;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    min-height: 30px;
    margin-top: 0.5em;
    margin-bottom: 2.5em;

    &-content {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__content-full-width {
    * {
      max-width: 100% !important;
    }
  }

  &__content-header {
    margin-bottom: 1.5rem;
    @include md {
      margin-bottom: 2.5rem;
      * {
        max-width: $md-content-max-width;
      }
    }
    @include lg {
      * {
        max-width: $lg-content-max-width;
      }
    }
  }

  &__error-default {
    margin-bottom: 3rem;
  }

  &__content {
    @include md {
      > * {
        max-width: $md-content-max-width;
      }
    }
    @include lg {
      > * {
        max-width: $lg-content-max-width;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    //position: sticky;
    background-color: $c-eggshell;
    bottom: 0;
    left: 0;
    padding-top: $button-section-p-top;
    padding-bottom: $button-section-p-bottom;

    &-component {
      @include md {
        max-width: $md-content-max-width;
      }
      @include lg {
        max-width: $lg-content-max-width;
      }
    }
  }

  &__error-inline {
    margin-bottom: 2rem;
  }
}

</style>
