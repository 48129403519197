<template>
  <div>
    <p>I authorize any licensed physician, medical practitioner, health care professional or provider, hospital, clinic, other medical or medically-related facility, laboratory, pharmacy, pharmacy benefit manager, clearinghouse, data warehouse, pharmacy data search companies or other organization that aggregates and maintains pharmacy data, health information exchanges, health and wellness services and devices, insurance or reinsurance company, MIB, Inc., Department of Motor Vehicles, credit agencies, consumer reporting agencies, current or former employers, other governmental agencies, and other organizations having any information, data or records about me that are in any way relevant to the issuance or administration of this policy, to provide Dayforward Life Insurance Company Inc. (&ldquo;Company&rdquo;) and its reinsurers, and, excluding MIB, Inc. only, to provide also to the Company&rsquo;s affiliated service companies, agents, employees, and representatives, all information pertaining to my physical or mental health or condition (excluding psychotherapy notes), my/their entire medical record and history as they relate to me, medications prescribed, and any other protected health information concerning me, including testing, diagnosis, prognosis, treatment for physical and mental illness, treatment of alcohol or drug abuse, communicable or non-communicable diseases, Acquired Immune Deficiency Syndrome, HIV testing, Hepatitis A, B, C, and sickle cell anemia; or any other medical or personal information about me. Such other information could include items such as: other insurance information; personal finances including credit, financial, and earnings history; habits; hazardous avocations; motor vehicle records; court records; employment history; or foreign travel.</p>
    <p>I acknowledge that any agreements I have made to restrict protected health information do not apply to this Authorization and I instruct any of my providers and other organizations, entities or persons referred to above to release and disclose, without restriction, my/their entire medical record and history as they relate to me. I also authorize the Company and its reinsurers to make a brief report of my personal health information to MIB, Inc.</p>
    <p><strong>Purpose for Obtaining and Disclosing Information&nbsp;</strong></p>
    <p>The information disclosed under this Authorization will be used by the Company to: 1) determine eligibility for accelerated underwriting; 2) underwrite my application for coverage and make a policy issuance determination; 3) obtain reinsurance; 4) service any reinstatement requests; 5) administer the policy, claims and coverage, such as changes in benefits; 6) use for underwriting and actuarial research purposes; 7) conduct other legally permissible activities that relate to any coverage I have or have applied for with the Company. I acknowledge that any information used or disclosed pursuant to this Authorization may be re-disclosed and no longer covered by federal rules governing privacy and confidentiality of health information. I understand that this information will be used by the Company to determine eligibility for insurance or for underwriting or risk rating purpose and, should coverage be issued, the information may be used to contest a claim for benefits during the contestability provision provided in the policy.</p>
    <p><strong>Revocation of Authorization</strong></p>
    <p>I understand and acknowledge that this Authorization is voluntary and that I may revoke this Authorization at any time upon a written request directed to the Company, except to the extent that the Company has taken action in reliance on this Authorization or to the extent that the Company has a legal right to contest a claim under an insurance policy or to contest the policy itself. My written request to revoke this Authorization must be sent to Dayforward Life Insurance Company Inc. at 1133 Broadway, Suite 525 New York, NY 10010.</p>
    <p>I also understand that Dayforward Life Insurance Company is conditioning the issuance or reinstatement of coverage on my provision of this Authorization, and that, while I have the right to refuse to sign this Authorization, my refusal to do so could result in coverage not being issued or reinstated.</p>
    <p><strong>Validity of Authorization</strong></p>
    <p>Unless otherwise revoked, this Authorization shall remain in effect for the earlier of the date the Company notifies me of its declination of my application for coverage and, if a policy is issued and remains in force, for 24 months following the date of my signature below except to the extent that state law imposes a shorter duration. All documents and information submitted to, or acquired by, the Company become property of the Company.&nbsp;</p>
    <p>I agree that a photocopy facsimile or electronic copy of this Authorization may be relied upon as if it were an original. I understand that this Authorization will be attached to and made a part of my application for individual level term life insurance. I further understand that I or my authorized representative may request a copy of this Authorization at any time.</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'authorizations-modal',
  data () {
    return {}
  },

  methods: {
    ...mapActions('content', ['fetchContentfulAsset'])
  },

  async created () {
    const url = await this.fetchContentfulAsset({
      id: '2e1FI8pabwXyUrqSi0GrvZ'
    })
    if (url) {
      this.$emit('pdfURLGenerated', url)
    }
  }
}
</script>

<style scoped>
</style>
