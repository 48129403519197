<template>
  <div v-if="!isLoading">
    <FlowWrapper
      :show-buttons="false"
      :show-navigation="true"
      :show-actions="true"
      :hide-menu="true"
      :show-progress="false"
    >
      <template v-slot:header-text>
        {{ headerText }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          {{ contentHeader }}
        </div>
      </template>
      <template v-slot:content>
        <div class="account-interstitial__wrapper">
          <div class="df-narrow-wrap">
            {{ mainText }}
            <a class="account-interstitial__link" v-if="linkText" @click="onLinkClick">{{ linkText }}</a>
          </div>
          <div>
            <div class="account-interstitial__actions">
              <DFButton @click="doNextAction" class="account-interstitial__button">
                {{ buttonTitle }}
              </DFButton>
            </div>
          </div>
        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { crash } from '@/utils/crash.js'
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import {
  FinalizePriceInterviewSections,
  InitialInterviewSections,
  InterviewStatus,
  QuestionKey as QK
} from '@/api/Interview'
import DFButton from '@/components/DFButton'
import { RouteName, SectionIDRouteName } from '@/router/routes/constants'
import { SectionID } from '@/api/Interview/constants'
// state codes more usable for this interstitial:

const STATE = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  PAYMENT: 'PAYMENT',
  ESTIMATED_PRICE: 'ESTIMATED_PRICE',
  FINALIZED_PRICE: 'FINALIZED_PRICE',
  READY_TO_SIGN: 'READY_TO_SIGN',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
  OFFRAMP: 'OFFRAMP',
  INTERVIEW_ERROR: 'INTERVIEW_ERROR',
  LEGACY_MUST_COMPLETE_ATTESTATIONS: 'LEGACY_MUST_COMPLETE_ATTESTATIONS',
  QUOTE_LOCKED: 'LOCKED_QUOTE'
}

export default {
  name: 'AccountInterstitial',
  components: {
    FlowWrapper,
    DFButton
  },
  metaInfo: {
    title: 'Hello!'
  },
  props: {
    notStarted: {
      type: Boolean
    }
  },
  data() {
    return {
      state: undefined,
      isLoading: true,
      interview: undefined,
      sections: undefined,
      quote: undefined,
      status: undefined,
      currentSectionID: undefined,
      nextAction: {
        name: undefined,
        query: undefined,
        waitForClick: true
      }
    }
  },
  computed: {
    ...mapGetters('interview', {
      interviewID: 'interviewID',
      interviewInState: 'interview',
      answersForKeys: 'answersForKeys'
    }),
    isEstimate() {
      return this.$store.state.interview?.interview?.quote?.isEstimate || false
    },
    headerText() {
      if (this.state && this.state === STATE.INTERVIEW_ERROR) {
        return "We're sorry."
      }
      const { FIRST_NAME } = this.answersForKeys([QK.FIRST_NAME])
      return FIRST_NAME ? `Hi ${FIRST_NAME}!` : 'Hello!'
    },
    contentHeader() {
      let text
      if (!this.quote?.locked) {
        switch (this.state) {
          case STATE.NOT_STARTED:
          case STATE.IN_PROGRESS:
          case STATE.ESTIMATED_PRICE:
            text = 'Ready to get life insurance?'
            break
          case STATE.NEEDS_REVIEW:
          case STATE.COMPLETE:
            text = 'Stay tuned!'
            break
          case STATE.READY_TO_SIGN:
            text = 'Your policy is almost ready!'
            break
          case STATE.EXPIRED:
            text = 'We need to update your information.'
            break
          case STATE.INTERVIEW_ERROR:
            text = 'Something went wrong with your interview.'
            break
          case STATE.LEGACY_MUST_COMPLETE_ATTESTATIONS:
            text = `You're almost done with your interview!`
            break
          default:
            text = 'Ready to get life insurance?'
        }
      } else {
        text = 'Your application is ready for submission!'
      }

      return text
    },

    mainText() {
      let text
      if (!this.quote?.locked) {
        switch (this.state) {
          case STATE.NOT_STARTED:
            text = 'Answer a few questions and we will try to get you covered in ten minutes.'
            break
          case STATE.ESTIMATED_PRICE:
          case STATE.IN_PROGRESS:
            text = `You were in the middle of signing up for life insurance. We’ve saved your
          progress so you can pick up where you’ve left off.`
            break
          case STATE.COMPLETE:
            text = `We are reviewing your life insurance policy to make sure everything is
          ready to go. We’ll get back to you shortly, or feel free to contact us.`
            break
          case STATE.NEEDS_REVIEW:
            text = `The information you submitted for your life insurance policy is now
          being reviewed. We’ll get back to you shortly, or feel free to contact us.`
            break
          case STATE.FINALIZED_PRICE:
          case STATE.PAYMENT:
          case STATE.READY_TO_SIGN:
            text = 'Click "Continue"  to review your personalized life insurance policy and check out.'
            break
          case STATE.EXPIRED:
            text = `Your previous interview is expired. You can start over by clicking below, or get
           in touch with us if you have any questions.`
            break
          case STATE.INTERVIEW_ERROR:
            text = `Sorry, something went wrong with the interview you were working on. You can start
          over by clicking below, or get in touch with us if you have any questions.`
            break
          case STATE.LEGACY_MUST_COMPLETE_ATTESTATIONS:
            text = `Answer just a few more quick questions.`
            break
          default:
            text = `Answer a few questions and we will try to get you covered in ten minutes.`
        }
      } else {
        text = 'Click "Continue" to review and submit your application.'
      }
      return text
    },

    linkText() {
      let linkText
      switch (this.state) {
        case STATE.EXPIRED:
        case STATE.INTERVIEW_ERROR:
          linkText = 'Contact us.'
          break
        default:
          linkText = ''
      }
      return linkText
    },

    buttonTitle() {
      let title
      if (!this.quote?.locked) {
        switch (this.state) {
          case STATE.NOT_STARTED:
            title = 'Get Started'
            break
          case STATE.EXPIRED:
          case STATE.INTERVIEW_ERROR:
            title = 'Start Over'
            break
          case STATE.IN_PROGRESS:
          case STATE.ESTIMATED_PRICE:
          case STATE.FINALIZED_PRICE:
          case STATE.PAYMENT:
          case STATE.READY_TO_SIGN:
          case STATE.LEGACY_MUST_COMPLETE_ATTESTATIONS:
            title = 'Continue'
            break
          case STATE.COMPLETE:
          case STATE.NEEDS_REVIEW:
            title = 'Contact us'
            break
          default:
            title = 'Get Started'
        }
      } else {
        title = 'Continue'
      }
      return title
    }
  },

  methods: {
    ...mapActions('account', ['getInterviews']),
    ...mapActions('interview', [
      'getStatus',
      'fetchInterviewQuote',
      'updateHasDrivingFollowup',
      'updateHasIdentityFollowup',
      'setAllHealthFollowups',
      'fetchInterviewQuoteAndSections'
    ]),
    logNextAction(where = undefined) {
      where = where ? String(where) + ' - ' : ''
      // eslint-disable-next-line no-console
      console.log(
        where +
          `{state: ${this.state}, currentSectionId: ${this.currentSectionID}, status: ${
            this.status
          }, nextAction: ${JSON.stringify(this.nextAction)}, quoteLocked: ${this.quote?.locked}}`
      )
    },

    async handleCrash(tags = {}) {
      tags = tags ?? {}
      tags.status = tags.status ?? this.status
      tags.currentSectionId = tags.currentSectionId ?? this.currentSectionID
      tags.from = tags.from ?? 'account-interstitial'
      tags.nextActionRouteName = tags.nextActionRouteName ?? this.nextAction?.name
      tags.nextActionRouteQuery = tags.nextActionRouteQuery ?? this.nextAction?.query
      this.nextActionWaitForClick = tags.nextActionWaitForClick ?? this.nextAction?.waitForClick
      await crash(this, tags)
    },
    async onLinkClick() {
      switch (this.state) {
        case STATE.EXPIRED:
          this.nextAction = { name: 'help' }
          await this.doNextAction()
          break
        default:
          await this.handleCrash()
      }
    },

    // FIXME: this should no longer be needed now that the sign in landing page is chosen via SectionIDRouteName[currentSectionID]
    async newPrequoteSectionsOnlyIncomplete() {
      await this.ensureSections()
      const newPrequoteIds = [InitialInterviewSections.ATTESTATION, InitialInterviewSections.BENEFICIARY]
      if (!this.sections) {
        return false
      }
      let hasIncompleteNewPrequote = false
      for (let s of this.sections) {
        if (s.id === SectionID.QUOTE) {
          break
        }
        if (newPrequoteIds.includes(s.id)) {
          if (s.active === true && s.incomplete === true) {
            hasIncompleteNewPrequote = true
          }
        } else {
          if (s.active === true && s.incomplete === true) {
            return false
          }
        }
      }
      return hasIncompleteNewPrequote
    },

    hasCompletedInitialSections() {
      let someIncomplete = false
      if (this.sections) {
        someIncomplete = this.sections.some((s) => {
          return InitialInterviewSections.hasOwnProperty(s.category) && s.active === true && s.incomplete === true
        })
      } else {
        return false
      }
      return !someIncomplete
    },
    async ensureSections() {
      if (this.sections) {
        return
      }
      let { sections } = await this.fetchInterviewQuoteAndSections({
        id: this.interviewID
      })
      this.sections = sections
    },
    resetReflexiveState() {
      // determine which reflexive sections are incomplete and reset state accordingly:
      let hasIncomplete = false
      if (this.sections) {
        hasIncomplete = this.sections.some((s) => {
          if (FinalizePriceInterviewSections.hasOwnProperty(s.category) && s.active === true && s.incomplete === true) {
            if (s.category === FinalizePriceInterviewSections.IDENTITY_FOLLOWUP) {
              this.updateHasIdentityFollowup({ hasIdentityFollowup: true })
            }
            if (s.category === FinalizePriceInterviewSections.DRIVING_FOLLOWUP) {
              this.updateHasDrivingFollowup({ hasDrivingFollowup: true })
            }
            if (
              s.category === FinalizePriceInterviewSections.HEALTH_FOLLOWUP ||
              s.category === FinalizePriceInterviewSections.HEALTH_DIAGNOSIS
            ) {
              const conditions = this.answersForKeys([QK.HEALTH_CONDITIONS])
              this.setAllHealthFollowups({
                allHealthFollowups: conditions[QK.HEALTH_CONDITIONS]
              })
            }
          }
        })
      }
      return hasIncomplete
    },
    async doNextAction() {
      const action = this.nextAction ?? {}
      if (!action.name) {
        await this.handleCrash({ reason: 'action route name not defined' })
        return
      }
      await this.$router.push({ name: action.name, query: action.query })
    },
    async updateQuote() {
      const { quote } = await this.fetchInterviewQuote({
        id: this.interviewID
      })
      this.quote = quote
    },
    async updateStatusAndCurrentSection() {
      const { status, currentSectionId } = await this.getStatus({
        id: this.interview?.id
      })
      this.status = status ?? this.interview?.status ?? InterviewStatus.ERROR
      this.currentSectionID = currentSectionId ?? SectionID.UNDEFINED
    },
    async updateState() {
      if (this.notStarted) {
        this.currentSectionID = SectionID.UNDEFINED
        this.state = STATE.NOT_STARTED
        return
      }
      await Promise.all([this.updateStatusAndCurrentSection(), this.updateQuote()])
      if (this.quote?.locked) {
        this.state = STATE.QUOTE_LOCKED
        return
      }
      // start to give current section ID priority in routing over interview status
      // (fixes https://dayforward.atlassian.net/browse/IN-3744)
      switch (this.currentSectionID) {
        case SectionID.PAYMENT:
          this.state = STATE.PAYMENT
          return
        case SectionID.SIGNATURE:
          this.state = STATE.READY_TO_SIGN
          return
        case SectionID.COMPLETE:
          this.state = STATE.COMPLETE
          return
        case SectionID.NEEDS_REVIEW:
        case SectionID.CHAT_REQUIRED:
          this.state = STATE.NEEDS_REVIEW
          return
        case SectionID.ERROR:
          this.state = STATE.ERROR
          return
      }
      switch (this.status) {
        case InterviewStatus.NEEDS_REVIEW:
          this.state = STATE.NEEDS_REVIEW
          return
        case InterviewStatus.READY_TO_SIGN:
        case InterviewStatus.AWAITING_SIGNATURE_COMPLETION:
          this.state = STATE.READY_TO_SIGN
          return
        case InterviewStatus.READY_TO_SUBMIT:
          this.state = STATE.FINALIZED_PRICE
          return
        case InterviewStatus.ACCEPTED:
        case InterviewStatus.SUBMITTED:
        case InterviewStatus.AWAITING_SIGNATURE_CONFIRMATION:
          this.state = STATE.COMPLETE
          return
        case InterviewStatus.EXPIRED:
        case InterviewStatus.INACTIVE:
          this.state = STATE.EXPIRED
          return
        case InterviewStatus.INELIGIBLE:
        case InterviewStatus.REJECTED:
          this.state = STATE.OFFRAMP
          return
        case InterviewStatus.NEW:
        case InterviewStatus.INCOMPLETE:
          if (this.hasCompletedInitialSections() && this.quote) {
            if (this.quote.isEstimate) {
              this.state = STATE.ESTIMATED_PRICE
              this.resetReflexiveState()
            } else {
              this.state = STATE.FINALIZED_PRICE
            }
          } else {
            let newPrequoteOnlyIncomplete = await this.newPrequoteSectionsOnlyIncomplete()
            if (newPrequoteOnlyIncomplete) {
              this.state = STATE.LEGACY_MUST_COMPLETE_ATTESTATIONS
            } else {
              this.state = STATE.IN_PROGRESS
            }
          }
          return
        case InterviewStatus.ERROR:
        default:
          this.state = STATE.ERROR
      }
    },
    async updateNextAction() {
      await this.updateState()
      let action = {
        name: undefined,
        query: undefined,
        waitForClick: true
      }
      switch (this.state) {
        // https://dayforward.atlassian.net/browse/IN-3581 -- start routing based on section id
        // instead of interview status / client
        case STATE.QUOTE_LOCKED:
          action.name = SectionIDRouteName[this.currentSectionID] ?? RouteName.ERROR
          break
        case STATE.IN_PROGRESS:
        case STATE.ESTIMATED_PRICE:
          action.name = SectionIDRouteName[this.currentSectionID] ?? RouteName.INTERVIEW_INITIAL
          break
        case STATE.NOT_STARTED:
        case STATE.EXPIRED:
        case STATE.INTERVIEW_ERROR:
          action.name = RouteName.INTERVIEW_INITIAL
          break
        case STATE.FINALIZED_PRICE:
          action.name = RouteName.INTERVIEW_FINAL_QUOTE
          action.query = { l: 't' }
          break
        case STATE.PAYMENT:
          action.name = RouteName.INTERVIEW_PAYMENT
          break
        case STATE.READY_TO_SIGN:
          action.name = RouteName.INTERVIEW_SIGN
          break
        case STATE.ERROR:
          action.name = RouteName.ERROR
          action.waitForClick = false
          break
        case STATE.COMPLETE:
        case STATE.NEEDS_REVIEW:
          action.waitForClick = false
          break
        case STATE.LEGACY_MUST_COMPLETE_ATTESTATIONS:
          action.name = RouteName.INTERVIEW_BENEFICIARY
          break
        default:
          action.name = SectionIDRouteName[this.currentSectionID] || RouteName.ERROR
      }
      if (action.name === RouteName.ERROR) {
        action.waitForClick = false
      }
      this.nextAction = action
      this.logNextAction('updateNextAction()')
    },
    async update() {
      await this.updateNextAction()
      this.logNextAction('update()')
    }
  },
  async created() {
    this.isLoading = true
    this.interview = this.interviewInState
    if (!this.interview) {
      this.nextAction = {
        name: RouteName.INTERVIEW_INITIAL,
        query: undefined,
        waitForClick: true
      }
      this.isLoading = false
      return
    }
    try {
      await this.update()
      if (this.state === STATE.OFFRAMP) {
        await this.$router.replace({ name: RouteName.OR_INELIGIBLE })
      }
    } catch (e) {
      await this.handleCrash({ errors: e })
      return
    }
    this.isLoading = false
    if (!this.nextAction?.waitForClick) {
      await this.doNextAction(this.nextAction)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/colors';
@import 'src/assets/styles/global';

.account-interstitial {
  &__actions {
    padding-bottom: 4rem;
  }

  &__actions {
    @extend .tm-3r;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.account-interstitial__narrow-wrap {
  max-width: 60%;
}
</style>
