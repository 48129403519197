<template>
  <div class="contentful-emphasized-headline" :class="classes" :style="styles">
    <ContentfulContentRenderer :content="textHTML"/>
  </div>
</template>

<script>

import { processRichText } from '@/api/Content'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-emphasized-headline',
  components: { ContentfulContentRenderer },
  props: {
    text: undefined,
    classes: undefined,
    styles: undefined
  },
  computed: {
    textHTML () {
      return processRichText(this, this.text)
    }
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.contentful-emphasized-headline {
  line-height: 1.1em;
  font-size: 2.2rem;
  letter-spacing: -0.05em;
  font-family: 'Graphik', sans-serif;
  font-weight: 600;
  color: $c-red;
  @include md {
    font-size: 3rem;
  }
}

::v-deep p {
  margin: 0 !important;
  padding: 0 !important;
}

::v-deep i {
  color: $c-primary;
  font-style: unset;
}

</style>
