import gql from 'graphql-tag'

export const LeadSource = {
  DEFAULT: 'DEFAULT',
  QUOTE360: 'QUOTE360',
  LEAD_GEN: 'LEAD_GEN',
  EQUOTO: 'EQUOTO',
  IMO: 'IMO'
}

export default {
  track: (trackingEvent) => ({
    mutation: gql`
      mutation Track($trackingEvent: TrackingEvent!) {
        track(event: $trackingEvent)
      }
    `,
    variables: {
      trackingEvent
    }
  }),

  page: (pageView) => ({
    mutation: gql`
      mutation Page($pageView: PageView!) {
        page(page: $pageView)
      }
    `,
    variables: {
      pageView
    }
  }),

  identify: (identity) => ({
    mutation: gql`
      mutation Identify($identity: Identity!) {
        identify(identity: $identity)
      }
    `,
    variables: {
      identity
    }
  })
}
