import utm from '@segment/utm-params'
import store from '@/store'
import { datadogRum } from '@datadog/browser-rum'

export default {
  page({ path, fullPath }) {
    const url = `${window.location.origin}${fullPath}`
    safeSegment((segment) => {
      segment.page(
        path,
        {
          path,
          url
        },
        getContext()
      )
    })

    store
      .dispatch('backendAnalytics/page', {
        properties: {
          path,
          url,
          title: `${document.title}`,
          referrer: `${document.referrer}`,
          search: `${location.search}`
        }
      })
      .catch(handleError)

    safeTaboola((taboola) => {
      taboola.push({
        notify: 'event',
        name: 'page_view',
        'item-url': url
      })
    })
  },

  identify(opts) {
    safeSegment((segment) => {
      let traits = {
        firstName: opts.firstName,
        lastName: opts.lastName,
        email: opts.email,
        phone: opts.phone,
        source: opts.source
      }
      let accountID = opts.accountID
      let interviewIDs = opts.interviewIDs
      if (interviewIDs) {
        let ids = JSON.parse(localStorage.getItem('interviewIDs')) || []
        ids = [...new Set(interviewIDs.concat(ids))]
        localStorage.setItem('interviewIDs', JSON.stringify(ids))
        traits.interviewIDs = ids.join(', ')
      }
      if (accountID) {
        segment.identify(accountID, traits || {})
      } else if (traits) {
        segment.identify(traits)
      }
    })
    store
      .dispatch('backendAnalytics/identify', {
        traits: { ...opts }
      })
      .catch(handleError)
  },

  track({ event, properties = {} }) {
    safeTrack(event, properties)
  },

  idempotentTrack({ key, event, properties = {} }) {
    const compositeKey = `analytics:${key}-${event}`

    if (!localStorage.getItem(compositeKey)) {
      safeTrack(event, properties)
      localStorage.setItem(compositeKey, 'used')
    }
  },

  disableFullstory() {
    if (window.FS) {
      window.FS.shutdown()
    }
  },

  triggerOptimizeEvent(event) {
    safe(
      'optimizeEvent',
      () => window.google_optimize && window.dataLayer,
      (dataLayer) => {
        dataLayer.push({ event: event })
      }
    )
  },

  trackOptimizeExperiments() {
    safe(
      `optimizeExperiment`,
      () => window.google_optimize && window.dataLayer,
      (dataLayer) => {
        // The dayaLayer.push must be wrapped in this dummy function to work.
        // I have no idea why.
        // If you try to inline this function, it will not work!
        function gtag() {
          dataLayer.push(arguments)
        }

        gtag('event', 'optimize.callback', {
          callback: (combination, experimentId, containerId) => {
            this.idempotentTrack({
              key: experimentId,
              event: 'abtest',
              properties: {
                experimentID: experimentId,
                variant: combination
              }
            })
          }
        })
      }
    )
  },

  getOptimizeVariant(experimentId, onVariant) {
    safe(
      `optimizeVariant:${experimentId}`,
      () => window.google_optimize && window.dataLayer,
      (dataLayer) => {
        // The dayaLayer.push must be wrapped in this dummy function to work.
        // I have no idea why.
        // If you try to inline this function, it will not work!
        function gtag() {
          dataLayer.push(arguments)
        }

        gtag('event', 'optimize.callback', {
          name: experimentId,
          callback: (variant) => {
            if (variant !== null && variant !== undefined) {
              onVariant(variant)
            }
          }
        })
      }
    )
  },

  getCampaign() {
    return { ...getContext() }
  }
}

const safeSegment = (func) => {
  safe('segment', () => window.analytics, func)
}

const safeTaboola = (func) => {
  safe('taboola', () => window._tfa, func)
}

const handleError = (e) => {
  // We don't want to break the user's experience if analytics is failing.
  // eslint-disable-next-line no-console
  console.error(e)
  datadogRum.addError(e)
}

const safe = (
  analyticsLibraryName,
  getAnalyticsLibrary,
  func,
  attempts = 0
) => {
  if (attempts > 120) {
    if (process.env.VUE_APP_SEGMENT_KEY) {
      const msg = `analytics failed to load ${analyticsLibraryName}`
      // eslint-disable-next-line no-console
      console.error(msg)
      datadogRum.addError(msg)
    }

    return
  }

  const analyticsLibrary = getAnalyticsLibrary()
  if (analyticsLibrary) {
    try {
      func(analyticsLibrary)
    } catch (e) {
      handleError(e)
    }
  } else {
    setTimeout(
      () => safe(analyticsLibraryName, getAnalyticsLibrary, func, attempts + 1),
      200
    )
  }
}

const parseAnalyticsCookie = (key, defaultValue) => {
  let cookieValue
  const cookie = document.cookie
  if (cookie) {
    const cookieSplit = cookie.split(`;`)
    const found = cookieSplit.filter((u) => u.trim().startsWith(key))
    if (found.length > 0) {
      const foundSplit = found[0]?.trim().split('=')
      if (foundSplit && foundSplit.length === 2) {
        cookieValue = foundSplit[1]
      }
    }
  }
  return cookieValue || defaultValue
}

const getContext = () => {
  let domain = 'dayforward.com'
  let extraFlags = '; secure'
  if (process.env.VUE_APP_RELEASE_STAGE === 'local') {
    domain = 'localhost'
    extraFlags = ''
  }

  let context = utm(window.location.search) || {}
  if (context?.source) {
    document.cookie = `analytics:utm=${JSON.stringify(
      context
    )}; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=${domain}; samesite=strict${extraFlags}`
  } else {
    const cookieValue = parseAnalyticsCookie('analytics:utm')
    context = {}
    if (cookieValue) {
      context = JSON.parse(cookieValue) || {}
    }
  }
  return {
    campaign: context
  }
}

const safeTrack = (event, properties) => {
  let customerProps = {}
  const customerId = store.dispatch('backendAnalytics/getOrCreateUserId')
  if (customerId) {
    customerProps['customerId'] = customerId
  }
  safeSegment((segment) => {
    var sUsrAg = navigator.userAgent
    if (sUsrAg.indexOf('HeadlessChrome') === -1) {
      segment.track(
        event,
        { ...properties, ...customerProps },
        {
          ...getContext(),
          page: {
            path: window.location.pathname,
            url: window.location.href
          }
        }
      )
    }
  })
  store
    .dispatch('backendAnalytics/track', {
      event,
      properties
    })
    .catch(handleError)

  safeTaboola((taboola) => {
    taboola.push({
      notify: 'event',
      name: event,
      'item-url': window.location.href,
      ...properties
    })
  })
}
