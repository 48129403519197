<template>
  <NavigationItem :variant="variant">
    <div role="button" :class="buttonClasses" @click="$emit('click')">
      <slot></slot>
    </div>
  </NavigationItem>
</template>

<script>
import NavigationItem from '@/components/navigation/items/NavigationItem'

export default {
  name: 'navigation-button-item',
  components: { NavigationItem },
  props: {
    variant: {
      type: String
    },
    contentAlignment: {
      type: String
    }
  },
  computed: {
    buttonClasses () {
      const classes = { 'navigation-item__button': true }
      if (this.contentAlignment) {
        classes[`navigation-item__button--${this.contentAlignment}`] = true
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-item__button {
  width: 100%;
  height: 100%;
  &--left {
    text-align: left;
  }
  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
}

</style>
