import { InterviewFlagCause, InterviewFlagType, InterviewStatus, SectionID } from '@/api/Interview/constants'
import InterviewAPI from '@/api/Interview/index'
import analyticsApi from '@/api/Analytics'

export class Snapshot {
  constructor (commit, rootState, id) {
    this.commit = commit
    this.rootState = rootState
    this.id = id
    this.status = undefined
    this.flags = []
    this.currentSectionId = undefined
    this.quoteLocked = undefined
  }
  async init (callback = undefined) {
    await this.update()
    if (typeof callback !== 'function') {
      return
    }
    callback.bind(this)()
  }
  needsReviewOrFrozen () {
    return this.needsCustomerServiceChat() || this.needsManualUnderwriting()
  }
  needsManualUnderwriting () {
    const needsMU = this.currentSectionId === SectionID.NEEDS_REVIEW || this.hasFrozenTypeFlagsNotCausedByNeedsReview()
    if (needsMU) {
      analyticsApi.idempotentTrack({ key: this.id, event: 'app_emu' })
    }
    return this.currentSectionId === SectionID.NEEDS_REVIEW || this.hasFrozenTypeFlagsNotCausedByNeedsReview()
  }
  needsCustomerServiceChat () {
    return this.currentSectionId === SectionID.CHAT_REQUIRED
  }
  submissionProcessing () {
    return this.currentSectionId === SectionID.SUBMIT || this.status === InterviewStatus.SUBMITTED
  }
  frozenTypeFlags () {
    return this.flags.filter((f) => f.type === InterviewFlagType.FROZEN)
  }
  frozen () {
    return this.frozenTypeFlags().length > 0
  }
  hasFrozenTypeFlagsNotCausedByNeedsReview () {
    return this.frozenTypeFlags().filter((f) => f.cause !== InterviewFlagCause.FROZEN_NEEDS_REVIEW).length > 0
  }
  ineligible () {
    return this.currentSectionId === SectionID.INELIGIBLE || this.status === InterviewStatus.INELIGIBLE
  }
  description () {
    return `{id:${this.id}, status:${this.status}, currentSectionId:${this.currentSectionId}, ` +
      `ineligible:${this.ineligible()}, needsManualUnderwriting:${this.needsManualUnderwriting()}, needsCustomerServiceChat:${this.needsCustomerServiceChat()}, frozen:${this.frozen()}}`
  }
  async update (callback = undefined) {
    const result = await this.rootState.apolloClient.query({
      ...InterviewAPI.getStatus(this.id),
      fetchPolicy: 'no-cache'
    })
    const { status, currentSectionId, flags, quoteLocked } = result?.data?.interview ?? {}
    this.status = status
    this.flags = flags ?? []
    this.currentSectionId = currentSectionId
    this.quoteLocked = !!quoteLocked
    if (typeof callback !== 'function') {
      return
    }
    callback.bind(this)()
  }
}
