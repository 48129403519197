<template>
  <div class="markers">
    <div class="marker__detail">
      <h3>Interview marker</h3>
      <p class="marker__step">
        <strong>Current step: </strong
        >{{ marker.label }}
      </p>
      <p class="marker__step">
        <strong>Marker name: </strong
        >{{ marker.value }}
      </p>
    </div>
    <div>
      <label>Set interview marker</label>
      <v-select
        item-value="value"
        item-text="label"
        :items="stepNames"
        @change="setMarker($event)"
      ></v-select>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'admin-marker',
  props: {
    id: {
      type: String,
      required: true
    },
    currentMarker: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      marker: {
        label: '',
        value: ''
      },
      // TODO: when we port admin over to the web repo, we can use the config directly
      stepNames: [
        { label: 'Step 1: Let\'s get started!', value: 'initial-step' },
        { label: 'Step 2: Tell us about yourself.', value: 'basic-info' },
        { label: 'Step 3: Where you live.', value: 'location-info' },
        { label: 'Step 4: Let\'s talk income.', value: 'income' },
        { label: 'Step 5: About your family.', value: 'family' },
        { label: 'Step 6: Behind the wheel.', value: 'driving' },
        { label: 'Step 7: How you live.', value: 'habits' },
        { label: 'Step 8: About your health.', value: 'health' },
        { label: 'Step 9: Health Conditions.', value: 'health-conditions' },
        { label: 'Step 10: Who you\'ll protect.', value: 'beneficiaries' },
        { label: 'Step 11: Insurance history.', value: 'insurance-history' },
        { label: 'Quote', value: 'quote' },
        { label: 'Payment', value: 'payment' },
        { label: 'Esign', value: 'esign' }
      ]
    }
  },
  methods: {
    ...mapActions('admin', ['fetchInterviewMarkers', 'setInterviewMarker']),
    displayMarker (marker) {
      if (!marker) this.marker = { label: '', value: '' }
      const findMarker = this.stepNames.find(s => {
        return s.value === marker
      })
      if (findMarker) this.marker = findMarker
    },
    async setMarker (e) {
      const name = 'last-step-completed'
      const metadata = {
        key: 'step',
        value: e
      }
      const isSet = await this.setInterviewMarker({
        id: this.id,
        name,
        metadata: [metadata]
      })
      if (isSet) {
        const newMarker = await this.fetchInterviewMarkers({ id: this.id })
        this.displayMarker(newMarker)
        this.$emit("newMarker", newMarker)
      }
    }
  },
  mounted () {
    this.displayMarker(this.currentMarker)
  }
}
</script>

<style scoped lang="scss">
.marker {
  &__detail {
    font-size: 14px;
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 20px;
    margin-bottom: 2rem;
  }
}
</style>
