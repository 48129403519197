<template>
  <div class="error-banner">
    <div class="mq-container">
      <div class="error-banner__title">{{title}}</div>
      <div class="error-banner__body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'error-banner',
  props: {
    title: {
      type: String,
      default: 'Oops! Something went wrong.'
    }
  }
}
</script>

<style scoped lang="scss">

@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/colors';

.error-banner {
  font-family: 'Graphik', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-height: 50px;
  padding: 1.5rem 0;
  background: #fff;
  color: #222;
  @include md {
    padding: 1.6rem 0;
  }
  p {
    margin-top: 0rem;
    &:last-of-type {
      margin-top: 1rem;
    }
  }

  &__title {
    font-size: 1.5em;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-decoration: underline rgba(#fff, 0.7);
    margin-bottom: 0.5rem;
    color: $c-red;
    @include md {
      margin-bottom: 1rem;
    }
  }
  &__body {
    line-height: 1.2em;
  }
}

</style>
