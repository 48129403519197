export function poll (fn, timeout, interval) {
  timeout = timeout || 1000
  interval = interval || 100

  const endTime = Number(new Date()) + timeout
  const checkCondition = function (resolve, reject) {
    const result = fn()
    if (result) {
      resolve(result)
    } else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, interval, resolve, reject)
    } else {
      reject(new Error('timed out for ' + fn + ': ' + arguments))
    }
  }

  return new Promise(checkCondition)
}

export function pollAsync (fn, timeout, interval) {
  timeout = timeout || 1000
  interval = interval || 100

  const endTime = Number(new Date()) + timeout
  const checkCondition = async function (resolve, reject) {
    const result = await fn()
    if (result) {
      resolve(result)
    } else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, interval, resolve, reject)
    } else {
      reject(new Error('timed out for ' + fn + ': ' + arguments))
    }
  }

  return new Promise(checkCondition)
}
