<template>
  <div v-if="occupation">
    <p v-if="type === 'history'">
      {{ occupation.text }}
    </p>
    <p v-else><strong>Occupation: </strong>{{ occupation.text }}</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import { removeUnderscores } from '@/utils/helpers'

export default {
  name: 'occupation-node',
  data () {
    return {
      occupation: {
        text: ''
      }
    }
  },
  props: {
    type: {
      type: String,
      default: 'history'
    },
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },
    occupationCode: {
      type: String,
      default: undefined
    }
  },
  methods: {
    ...mapActions('interview', ['findOccupationWithKey']),
    moment,
    removeUnderscores,
    getHistoryValues (value) {
      if (value !== null) {
        return value.toString().replace(/_/g, ' ').toLowerCase()
      }
      return ''
    },
    displayAnswers (answer) {
      if (Array.isArray(answer)) {
        return answer.join(' ')
      }
    }
  },
  async mounted () {
    this.occupation = await this.findOccupationWithKey(this.occupationCode)
  }
}
</script>

<style scoped>
</style>
