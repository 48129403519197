
export default {
  New: (r) => {
    let valid = true
    switch (true) {
      case r === undefined:
      case r === null:
      case r?.success === undefined:
      case r?.message === undefined:
        valid = false
    }
    if (!valid) {
      throw new Error(`invalid API result: ${r}`)
    }
    let msg = r?.message
    if (msg === '') {
      msg = 'Success'
    }
    return { success: r?.success, message: msg }
  }
}
