<template>
  <div class="admin-overview">
    <div class="admin-overview__detail-container">
      <div class="interview-wrapper">
        <AdminBasicInfo
          :name="applicant.fullName || 'No name given'"
          :accountId="accountId"
          :accountEmail="accountEmail"
          :id="id"
          :applicant="applicant"
          :interview="interview"
          :agent="agent"
          :marker="marker"
          :updateEmail="updateEmail"
        />
        <AdminCopyInterviewChangeVendor v-if="hasStateMismatchFlag" :interview="interview" />
        <AdminInterviewFlags :interview="interview" />
        <AdminRelated :interview="interview" :accountId="accountId" />
      </div>
    </div>
  </div>
</template>

<script>
import AdminBasicInfo from '@/components/admin/AdminBasicInfo.vue'
import AdminInterviewFlags from '@/components/admin/AdminInterviewFlags.vue'
import AdminRelated from '@/components/admin/AdminRelated.vue'
import AdminCopyInterviewChangeVendor from '@/components/admin/AdminCopyInterviewChangeVendor'

export default {
  name: 'admin-overview',
  props: {
    name: {
      type: String,
      default: ''
    },
    accountId: {
      type: String,
      default: ''
    },
    accountEmail: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    applicant: {
      type: Object,
      default: () => {
        return {}
      }
    },
    interview: {
      type: Object,
      default: () => {
        return {}
      }
    },
    agent: {
      type: Object,
      default: () => {
        return {}
      }
    },
    marker: {
      type: String,
      default: ''
    },
    updateEmail: {
      type: Function,
      default: () => {}
    },
  },
  components: {
    AdminCopyInterviewChangeVendor,
    AdminBasicInfo,
    AdminInterviewFlags,
    //    AdminInterviewInteractions,
    AdminRelated
  },
  computed: {
    hasStateMismatchFlag () {
      let hasFlag = false
      if (this.interview && this.interview?.riskScore) {
        const subscores = this.interview?.riskScore?.subscores
        for (const sub of subscores) {
          const flags = sub.flags
          if (flags !== null && flags.indexOf("STATE_MISMATCH") !== -1) {
            hasFlag = true
          }
        }
      }
      return hasFlag
    }
  },
  data () {
    return {
      related: undefined,
      fingerprint: undefined
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";

.admin-overview {
  &__detail-container {
    display: flex;
    flex-direction: column;
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 20px;
    font-size: 14px;

    h4 {
      margin-bottom: 1rem;
    }
  }
  &__subhead {
    color: $c-red;
  }
}
</style>
