<template>
  <div class="policy-detail">
    <div class="policy-detail__approve-policy" v-if="policy.id">
      <div class="policy-detail__item">
        <p v-if="policy.id">
          <strong>Policy Id:</strong>
          {{ policy.id }}
        </p>
        <p v-if="policy.number !== ''">
          <strong>Policy Number:</strong>
          {{ policy.number }}
        </p>
        <p v-if="'flags' in policy && policy.flags !== null">
          <strong>Policy flags:</strong>
          {{ policy.flags.toString() }}
        </p>
        <p v-if="policy.status">
          <strong>Policy status:</strong>
          {{ removeUnderscores(policy.status) }}
        </p>
        <p>
          <a class="tm-2r policy-detail__hide" @click="toggleHidePolicy">
            {{ hidePolicyText }}
          </a>
        </p>
        <p>
          <a class="tm-1r policy-detail__hide" @click="regeneratePacket">Regenerate Policy Packet</a>
          <span class='"policy-detail__error' v-if="regenerationError">{{ regenerationError }}</span>
        </p>
      </div>
      <div class="policy-detail__item policy-detail__button-wrapper">
        <div class="policy-detail__download-policy-button">
          <DFButton elevation="0" @click="downloadPolicy" variant="small">
            Download Policy
          </DFButton>
        </div>
      </div>
    </div>
    <div v-else class="no-data">
      <h1>No policy issued</h1>
    </div>
    <div class="policy-detail__loading-overlay" v-if="regeneratingPacket">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { removeUnderscores } from '@/utils/helpers'
import DFButton from '@/components/DFButton'
import { mapActions } from 'vuex'
import { PolicyFlag } from '@/api/Policy'

export default {
  name: 'admin-policy',
  components: {
    DFButton
  },
  props: {
    policy: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      currentPolicy: undefined,
      regeneratingPacket: false,
      regenerationError: undefined
    }
  },
  computed: {
    hidePolicyText () {
      return this.flaggedIncorrectPacket ? 'Show policy details on signin' : 'Hide policy details on signin'
    },

    flaggedIncorrectPacket () {
      if (this.currentPolicy) {
        const { flags } = this.currentPolicy
        if (flags?.length > 0 && flags.indexOf(PolicyFlag.INCORRECT_POLICY_PACKET) !== -1) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions('policy', ['getPolicyDownloadURL']),
    ...mapActions('admin', ['hidePolicy', 'unhidePolicy', 'regeneratePolicyPacket']),
    removeUnderscores,
    async regeneratePacket () {
      this.regeneratingPacket = true
      const rsp = await this.regeneratePolicyPacket({ id: this.currentPolicy?.id })
      if (rsp && rsp?.regeneratePacket) {
        const data = rsp.regeneratePacket
        if (data.message) {
          this.regenerationError = data.message
          this.regeneratingPacket = false
        }
        if (data.success) {
          this.regeneratingPacket = false
        }
      } else {
        this.regenerationError = 'Error regenerating policy packet'
        this.regeneratingPacket = false
      }
    },
    async downloadPolicy () {
      const url = await this.getPolicyDownloadURL(this.policy.id)
      if (url) {
        window.location.href = url
      }
    },

    async toggleHidePolicy () {
      let newPolicy
      if (this.flaggedIncorrectPacket) {
        newPolicy = await this.unhidePolicy({ id: this.currentPolicy?.id })
      } else {
        newPolicy = await this.hidePolicy({ id: this.currentPolicy?.id })
      }
      this.currentPolicy = newPolicy
    }
  },
  mounted () {
    this.currentPolicy = this.policy
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
.policy-detail {
  &__approve-policy {
    display: flex;
    font-size: 14px;
    column-gap: 10px;
    row-gap: 10px;
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 20px;
  }
  &__button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item {
    flex: 1 50%;
  }
  &__hide {
    font-size: 0.9rem;
    text-decoration: underline;
  }
  &__error {
    font-size: 0.8rem;
    font-weight: bold;
    color: $c-error;
  }

  &__loading-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
