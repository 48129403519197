<template>
  <div class="labeled-holder">
    <template v-if="tooltip">
      <label
        v-if="!hideLabel"
        class="labeled-holder__label df-input-label"
        :style="this.labelStyle"
        :for="inputId(label)"
        :content="tooltip"
        tabindex="-1"
        v-tippy="vtippyOptions"
      >
        <span class="labeled-holder__marked" v-html="mdLabel" />
        <span class="labeled-holder__tooltip--icon">?</span>
      </label>
    </template>
    <template v-else>
      <label
        v-if="!hideLabel"
        class="labeled-holder__label df-input-label"
        :style="this.labelStyle"
        :for="inputId(label)"
      >
        <span v-html="mdLabel"></span>
      </label>
    </template>
    <div class="labeled-holder__child" :style="childHolderStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { inputId } from '@/utils/inputs'
import marked from 'marked'

export default {
  name: 'LabeledHolder',
  props: {
    label: {
      type: String,
      required: false
    },
    emptyLabel: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    childWidth: {
      type: String,
      required: false,
      default: '100%'
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    vtippyOptions () {
      return this.isMobile
        ? {
          maxWidth: 250,
          delay: [0, 1000],
          trigger: 'mouseenter focus click',
          hideOnClick: false,
          interactive: true,
          interactiveBorder: 8
        }
        : { maxWidth: 250 }
    },

    mdLabel () {
      return marked(this.label)
    },

    labelStyle () {
      const style = {}
      if (this.emptyLabel || !this.label) {
        style.height = '20px'
      }
      if (this.large) {
        style.fontSize = '16px'
      }
      return style
    },
    childHolderStyle () {
      return {
        'max-width': this.childWidth !== undefined ? this.childWidth : '100%'
      }
    }
  },
  methods: {
    inputId
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/colors";
@import "src/assets/styles/global";
@import "src/assets/styles/media-queries";

.labeled-holder__tooltip {
  color: $c-primary;
  font-family: "Graphik", sans-serif;
  font-size: small;
  background-color: #fff;

  &--icon {
    font-family: "Graphik", sans-serif;
    font-size: smaller;
    color: $c-action;
    font-weight: bold;
    position: relative;
    top: -4px;
  }
}

.labeled-holder__child {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  * {
    width: 100%;
  }
}

.labeled-holder__label {
  display: inline-block;
}

.labeled-holder__marked {
  display: inline-block;
}

.labeled-holder__tooltip--icon {
  display: inline-block;
  margin-left: 3px
}
</style>
