<template>
  <VideoTextTwoColumn class="contentful-two-column-video-card" :video-id="videoId">
    <template v-slot:title>
      <div class="video-item-title df-subtitle-text">{{ title }}</div>
    </template>
    <template v-slot:text>
      <div class="video-item-text">
        <ContentfulContentRenderer :content="renderedText"/>
      </div>
    </template>
  </VideoTextTwoColumn>

</template>

<script>
import VideoTextTwoColumn from '@/components/content/VideoTextTwoColumn.vue'
import { processRichText } from '@/api/Content'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-two-column-video-card',
  components: { ContentfulContentRenderer, VideoTextTwoColumn },
  props: {
    title: undefined,
    text: undefined,
    videoId: undefined
  },
  computed: {
    renderedText () {
      return processRichText(this, this.text)
    }
  }
}
</script>

<style scoped lang="scss">
.video-item-title {
  margin-bottom: 0.6rem;
}
</style>
