<template>
  <div class="video-player__iframe-container" :class="extraClasses">
    <div class="video-player__embed">
      <iframe
        ref="videoPlayerEmbed"
        allow="autoplay"
        allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen"
        msallowfullscreen="msallowfullscreen"
        oallowfullscreen="oallowfullscreen"
        webkitallowfullscreen="webkitallowfullscreen"
        :id="`video-player-embed-${videoId}`"
        :src="`https://www.youtube.com/embed/${videoId}?rel=0&enablejsapi=1`"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/bus/eventbus'
import { openFullScreenAPI } from '@/utils/helpers'
import { youtubeApiKey } from '@/config/app.config'
import { datadogRum } from '@datadog/browser-rum'

export default {
  name: 'video-iframe',
  props: {
    videoId: String,
    autoplay: {
      type: Boolean,
      default: false
    },
    standalone: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      fullScreen: false,
      player: undefined
    }
  },
  computed: {
    extraClasses() {
      if (!this.standalone) {
        return ['embedded']
      }
      return []
    }
  },
  methods: {
    stopVideo() {
      if (this.player) {
        this.player.stopVideo()
      }
    },
    playVideo() {
      if (this.player) {
        this.player.playVideo()
        this.videoAnalytics()
      }
    },
    async videoAnalytics() {
      await fetch(
        `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${this.videoId}&key=${youtubeApiKey}`
      )
        .then((response) => response.json())
        .then((data) => {
          const videoTitle = data.items[0].snippet.title
          this.$analytics.track({
            event: 'plays video',
            properties: {
              title: videoTitle
            }
          })
        })
        .catch((err) => datadogRum.addError(err))
    },
    openFull(elem) {
      openFullScreenAPI(elem)
      this.fullScreen = true
    },
    onPlayerReady(evt) {
      // Here we are responding to the event bus. If the system that is in place now is not
      // optimal we can probably pass the player + the way to open the video and render that way

      EventBus.$on(`playEmbed${this.videoId}`, (payload) => {
        // TODO: I hate this but it was the only way to get it to autoplay in safari
        setTimeout(() => {
          this.playVideo()
        }, 1000)
      })

      EventBus.$on(`stopEmbed${this.videoId}`, (payload) => {
        this.stopVideo()
      })

      EventBus.$on(`fullEmbed${this.videoId}`, (payload) => {
        if (this.$refs.videoPlayerEmbed && this.player) {
          this.openFull(this.$refs.videoPlayerEmbed)
          this.bindClose(this.$refs.videoPlayerEmbed)
          // TODO: I hate this but it was the only way to get it to autoplay in safari
          setTimeout(() => {
            this.playVideo()
          }, 1000)
        }
      })

      if (this.autoplay) {
        setTimeout(() => {
          this.playVideo()
        }, 1000)
      }
    },
    bindClose(elem) {
      if (elem.requestFullscreen) {
        this.fsEvent = 'fullscreenchange'
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        this.fsEvent = 'mozfullscreenchange'
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.fsEvent = 'webkitfullscreenchange'
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        this.fsEvent = 'msfullscreenchange'
      }
      document.addEventListener(
        this.fsEvent,
        () => {
          if (!document.fullscreenElement) {
            this.resetPlayer()
          }
        },
        false
      )
    },
    resetPlayer() {
      if (this.player) {
        this.stopVideo()
        this.fullScreen = false
      }

      document.removeEventListener(this.fsEvent, () => {})
    },
    createPlayer(containerId) {
      this.player = new this.yt.Player(containerId, {
        host: 'https://www.youtube.com',
        modestbranding: true,
        events: {
          onReady: this.onPlayerReady.bind(this)
        }
      })
    },
    loadYT() {
      // eslint-disable-next-line no-undef
      this.yt = window.YT
      this.playerState = this.yt.PlayerState
      this.createPlayer(`video-player-embed-${this.videoId}`)
      this.currentViewport = this.$gtsm
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (window.YT && window.YT.Player) {
        this.loadYT()
      } else {
        window.onYouTubeIframeAPIReady = function () {
          this.loadYT()
        }.bind(this)
      }
    })
  },
  beforeDestroy() {
    if (this.player && this.player.destroy) {
      this.player.destroy()
    }
    delete this.player
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';

.video-player {
  &__iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    background-color: $c-eggshell;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      z-index: 10;
      background-color: transparent;
      border: $c-eggshell 2px solid;
      pointer-events: none;
    }
    &.embedded {
      padding-bottom: 0;
      height: 100%;
    }
  }

  &__embed {
    width: 100%;
    height: 100%;

    iframe {
      width: 100%;
      height: 100%;
    }

    &.fullscreen {
      iframe {
        width: 100vw;
        height: 100vh;
      }
    }
  }
}

.video-overlay {
  .video-player__embed {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
