<template>
  <div class="interview-can-we-talk">
    <FlowWrapper
      :progress="1"
      :next-title="nextButtonTitle"
      :canMoveToNext="true"
      :on-next="onNext"
      :showButtons="showButtons"
    >
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          {{ $route.meta.content.title }}
        </div>
        <p class="df-content-header-subtitle">
          {{ $route.meta.content.subtitle }}
        </p>
      </template>
      <template v-slot:content>
        <div class="interview-can-we-talk__chat-button" v-if="!showButtons">
          <DFButton @click="onNext"> Contact Us </DFButton>
        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import DFButton from '@/components/DFButton.vue'
import { mapActions, mapGetters } from 'vuex'
import { InteractionType } from '@/api/Interview/constants.js'

export default {
  name: 'interview-can-we-talk',
  components: {
    FlowWrapper,
    DFButton
  },
  data() {
    return {
      nextButtonTitle: 'Contact Us'
    }
  },
  computed: {
    ...mapGetters('interview', ['interviewID', 'interview']),
    showButtons() {
      return this.$route.name !== 'lets-chat'
    }
  },
  methods: {
    ...mapActions('interview', ['recordInteraction']),

    async onNext() {
      await this.$router.push({ name: 'help' })
      return { navigate: false }
    }
  },
  async created() {
    if (this.interview) {
      try {
        await this.recordInteraction({
          interviewID: this.interviewID,
          actionType: InteractionType.NOTIFIED_LETS_CHAT
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.df-content-header-subtitle {
  margin-top: 1rem;
}
</style>
