<template>
  <div class="address-picker">
    <div class="address-picker__input">
      <LabeledHolder :label="LABELS.ADDRESS">
        <v-combobox
          v-model="selectedValue"
          :search-input.sync="enteredValue"
          :items="searchResults"
          placeholder="Begin typing your address"
          hide-details
          eager
          hide-no-data
          no-filter
          @blur="onBlur"
          @input="onInput"
          @focus="onFocus"
          :error="!!errorMessage"
          :id="inputId(LABELS.ADDRESS)"
        />
      </LabeledHolder>
      <div v-if="$gtSm && showLine2 === true">
        <LabeledHolder :label="LABELS.LINE2">
          <v-text-field type="text" hide-details v-model="line2Value" :id="inputId(LABELS.LINE2)" maxlength="10" />
        </LabeledHolder>
      </div>
    </div>
    <div class="address-picker__line2" v-if="$ltMd && showLine2 === true">
      <LabeledHolder :label="LABELS.LINE2" child-width="50%">
        <v-text-field type="text" hide-details v-model="line2Value" :id="inputId(LABELS.LINE2)" maxlength="10" />
      </LabeledHolder>
    </div>
    <div class="address-picker__messaging">
      <div class="address-picker__loading" v-if="isSearching && !errorMessage">
        <v-progress-circular :indeterminate="true" width="1" size="12" color="#E33031" />
        <div class="address-picker__loading-message">Looking for address. Please wait.</div>
      </div>
      <div class="address-picker__error-message" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import trimStart from 'lodash/trimStart'
import geocoderApi from '../../api/Geocoder'
import LabeledHolder from '@/components/inputs/LabeledHolder'
import { inputId } from '@/utils/inputs'

const LABELS = {
  ADDRESS: 'Address',
  LINE2: 'Apt. / Floor'
}

export default {
  name: 'AddressPicker',
  components: {
    LabeledHolder
  },
  props: {
    value: {
      default: undefined
    },
    showLine2: {
      type: Boolean,
      default: true
    },
    isAddressRequired: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    isSearching: false,
    enteredValue: '',
    selectedValue: undefined,
    searchResults: [],
    errorMessage: undefined,
    line2Value: undefined,
    hasFocus: false,
    initialFocus: false,
    searchRequestId: undefined,
    skipSearchWatch: undefined,
    LABELS
  }),
  watch: {
    async enteredValue(val) {
      if (this.skipSearchWatch === true) {
        return
      }
      await this.resetSearchState(true)
      const isEmptyValue = !val || val.length === 0
      if (isEmptyValue) {
        return // nothing to do. return
      }
      this.doAddressLookup(val)
    },

    line2Value: function () {
      let hasAddress = !!(this.selectedValue && this.selectedValue.value)
      let address = {}
      if (hasAddress) {
        address = this.selectedValue.value
      }
      if (this.line2Value) {
        address.line2 = this.line2Value
      } else {
        delete address.line2
      }
      this.$emit('input', {
        text: hasAddress ? this.textFromAddress(address) : undefined,
        address,
        isValid: hasAddress
      })
    }
  },
  methods: {
    inputId,

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    async resetSearchState(clearResults = true) {
      this.isSearching = false
      this.errorMessage = undefined
      if (clearResults) {
        this.skipSearchWatch = true
        this.searchResults = undefined
        this.skipSearchWatch = false
      }
    },

    textFromAddress(address) {
      if (!address) return ''
      return `${address.line1}, ${address.city}, ${address.state} ${address.zip}`
    },
    shouldStartSearching(val) {
      // Start searching when there's at least 2 groups of characters. I did not
      // specifically make the first group match numbers because addresses are
      // not formaly codified to be a standard format so at least this just
      // starts searching when there are 2 groups of chars separated by a space
      return /^.+\s./i.test(trimStart(val))
    },

    /**
     * Called when the search input value has changed
     * @param val
     * @returns {Promise<void>}
     */
    doAddressLookup: debounce(async function (val) {
      const searchRequestId = Math.random().toString(36).substr(2)
      this.searchRequestId = searchRequestId
      this.isSearching = true
      let findResult
      try {
        findResult = await geocoderApi.findLocationsForAddress(val)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        this.errorMessage = 'Oops! Something went wrong. Please try again.'
        this.isSearching = false
      }
      // don't process the result because another request overrode this one
      if (searchRequestId !== this.searchRequestId) {
        return
      }

      let results = findResult.data.results
      if (results.length === 0) {
        this.errorMessage = "We couldn't find that address, sorry."
        this.$emit('input', {
          text: this.enteredValue,
          address: undefined,
          isValid: false
        })
        return
      }
      this.searchResults = results.reduce((filtered, addr) => {
        let convResult = geocoderApi.addressComponentsToAddress(addr.address_components)
        if (convResult.isValid) {
          const address = convResult.address
          filtered.push({
            text: this.textFromAddress(address),
            value: address
          })
        }
        return filtered
      }, [])
      this.isSearching = false
    }, 400),

    onBlur: async function () {
      this.hasFocus = false
      this.skipSearchWatch = true
      await this.sleep(0)
      if ((!this.selectedValue || !this.selectedValue.value) && this.searchResults && this.searchResults.length > 0) {
        this.enteredValue = this.textFromAddress(this.selectedValue)
        await this.sleep(0)
        this.selectedValue = this.searchResults[0]
        await this.sleep(0)
        this.onInput()
      } else if (!this.searchResults || this.searchResults.length === 0) {
        if (!this.errorMessage) {
          if (this.enteredValue) {
            this.errorMessage = "We couldn't find that address, sorry."
          } else {
            this.errorMessage = 'Please enter a value.'
          }
        }
        this.$emit('input', {
          text: this.enteredValue,
          address: undefined,
          isValid: false
        })
      }
      this.skipSearchWatch = false
    },

    onFocus() {
      this.initialFocus = true
      this.hasFocus = true
    },

    async onInput() {
      if (this.selectedValue && this.selectedValue.value) {
        let isValid = true
        let address = this.selectedValue.value
        address.line2 = this.line2Value
        if (!address) {
          isValid = false
          address = {}
        }
        this.errorMessage = undefined
        this.skipSearchWatch = true
        await this.sleep(0)
        this.$emit('input', {
          text: this.selectedValue,
          address: address,
          isValid
        })
        await this.sleep(0)
        this.skipSearchWatch = false
        await this.sleep(0)
      }
    }
  },
  async mounted() {
    if (this.value && this.value.address) {
      this.$nextTick(function () {
        this.skipSearchWatch = true
      })
      const val = {
        text: this.textFromAddress(this.value.address),
        value: this.value.address,
        line2: this.value.address.line2
      }
      this.line2Value = this.value.address.line2
      this.enteredValue = val.text
      this.selectedValue = val
      this.searchResults = [val]
      this.onInput()
      await this.sleep(0)
      this.skipSearchWatch = false
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.address-picker {
  display: grid;
  grid-template-columns: 1fr;
}

.address-picker__input {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  margin-bottom: 0.2rem;
  @include md {
    grid-template-columns: 4fr 1fr;
  }
  @include lg {
    grid-template-columns: 4fr 1fr;
  }
}

.address-picker__messaging {
  display: grid;
  align-items: center;
  height: 30px;
  font-size: 0.75rem;
  letter-spacing: 0.04em;
}

.address-picker__error-message {
  color: $c-error;
  font-size: 1em;
  letter-spacing: 0;
}

.address-picker__loading {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  .address-picker__loading-message {
    color: rgba($c-primary, 0.8);
    font-size: 1em;
  }
}

.address-picker__line2 {
  display: grid;
  margin-top: 40px;
  @include md {
    grid-template-columns: 1fr 3fr;
  }
}
</style>
