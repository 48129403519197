import { SectionID } from '@/api/Interview/constants'

export const RouteCategory = {
  INITIAL: 'INITIAL',
  QUOTE: 'QUOTE',
  STEP_TWO_AUTH: 'STEP_TWO_AUTH',
  FINALIZE: 'FINALIZE',
  CHECKOUT: 'CHECKOUT',
  AUTH: 'AUTH',
  MANUAL_UNDERWRITING: 'MANUAL_UNDERWRITING',
  HOME: 'HOME',
  CONFIRM_IDENTITY: 'CONFIRM_IDENTITY'
}

export const ssKeyLastCategory = 'lastCategory'

export const InterviewRouteIds = {
  NAME_AND_ADDRESS: 'NAME_AND_ADDRESS',
  BASIC_INFO: 'BASIC_INFO',
  INCOME: 'INCOME',
  DRIVING: 'DRIVING',
  SUBSTANCE_USE: 'SUBSTANCE_USE',
  HEALTH: 'HEALTH',
  IDENTITY_FOLLOWUP: 'IDENTITY_FOLLOWUP',
  HEALTH_CONDITION: 'HEALTH_CONDITION'
}

export const checkoutRouteIds = {
  IDENTITY_CONFIRMATION: 'IDENTITY_CONFIRMATION',
  BENEFICIARY: 'BENEFICIARY',
  ATTESTATIONS: 'ATTESTATIONS'
}

export const InterviewRouteNames = {
  CUSTOMIZE_QUOTE: 'customize-quote',
  DASHBOARD: 'dashboard',
  FINALIZE_HEALTH_DIAGNOSIS: 'finalize-price-health-diagnosis',
  FINALIZE_HEALTH_FOLLOWUP: 'finalize-price-health-followup',
  GET_STARTED: 'get-started',
  INTERVIEW_ATTESTATIONS: 'interview-attestations',
  INTERVIEW_BASIC_INFO: 'interview-basic-info',
  INTERVIEW_BENEFICIARY: 'interview-beneficiary',
  INTERVIEW_CAN_WE_TALK: 'can-we-talk',
  INTERVIEW_CONFIRM_IDENTITY: 'interview-confirm-identity',
  INTERVIEW_CONFIRM_INFO_PREQUOTE: 'interview-confirm-info-prequote',
  INTERVIEW_CONGRATS: 'interview-congrats',
  INTERVIEW_CONTACT_METHOD: 'interview-contact-method',
  INTERVIEW_CREATING: 'interview-creating',
  INTERVIEW_DRIVING_HISTORY: 'interview-driving-history',
  INTERVIEW_FINAL_QUOTE: 'interview-final-quote',
  INTERVIEW_HEALTH_CONDITIONS: 'interview-health-conditions',
  INTERVIEW_HEALTH_HISTORY: 'interview-health-history',
  INTERVIEW_INCOME: 'interview-income',
  INTERVIEW_INITIAL: 'interview-initial',
  INTERVIEW_LETS_CHAT: 'lets-chat',
  INTERVIEW_LOCATION_INFO: 'interview-location-info',
  INTERVIEW_NEEDS_REVIEW: 'interview-needs-review',
  INTERVIEW_PAYMENT: 'interview-payment',
  INTERVIEW_REVIEW_PENDING: 'interview-review-pending',
  INTERVIEW_SIGN: 'interview-sign',
  INTERVIEW_SMOKING: 'interview-smoking',
  INTERVIEW_SUBMISSION: 'interview-submission',
  INTERVIEW_THANKS: 'interview-thanks',
  INTERVIEW_UPDATE_PAYMENT: 'interview-update-payment',
  OR_INELIGIBLE: 'offramp-ineligible',
  OR_REPLACEMENT: 'offramp-replacement',
  OR_WAITLIST: 'offramp-waitlist',
}

export const RouteName = {
  ABOUT: 'about',
  ADMIN_DASHBOARD: 'admin-dashboard',
  ADMIN_DETAIL_ACCOUNT: 'admin-detail-account',
  ADMIN_DETAIL_NO_ADDOUNT: 'admin-detail-no-account',
  ADMIN_SIGNIN: 'admin-signin',
  CAREERS: 'careers',
  CHECKOUT_ATTESTATIONS: 'interview-attestations',
  CHECKOUT_BENEFICIARY: 'interview-beneficiary',
  CHECKOUT_CONFIRM_IDENTITY: 'interview-confirm-identity',
  CHECKOUT_PAYMENT: 'interview-payment',
  CHECKOUT_SIGN: 'interview-sign',
  CHECKOUT_SIGNIN: 'signin',
  CUSTOMIZE_QUOTE: 'customize-quote',
  DASHBOARD: 'dashboard',
  ERROR: 'error',
  FAQ: 'faq',
  FINALIZE_HEALTH_DIAGNOSIS: 'finalize-price-health-diagnosis',
  FINALIZE_HEALTH_FOLLOWUP: 'finalize-price-health-followup',
  GET_STARTED: 'get-started',
  HELP: 'help',
  HOME: 'home',
  IMO_DASHBOARD: 'imo-dashboard',
  IMO: 'imo',
  IMO_SIGNIN: 'imo-signin',
  HOW_IT_WORKS: 'how-it-works',
  INCOMING: 'incoming',
  INTERVIEW_ATTESTATIONS: 'interview-attestations',
  INTERVIEW_BASIC_INFO: 'interview-basic-info',
  INTERVIEW_BENEFICIARY: 'interview-beneficiary',
  INTERVIEW_CAN_WE_TALK: 'can-we-talk',
  INTERVIEW_CONFIRM_IDENTITY: 'interview-confirm-identity',
  INTERVIEW_CONFIRM_INFO_PREQUOTE: 'interview-confirm-info-prequote',
  INTERVIEW_CONGRATS: 'interview-congrats',
  INTERVIEW_CONTACT_METHOD: 'interview-contact-method',
  INTERVIEW_CREATING: 'interview-creating',
  INTERVIEW_DRIVING_HISTORY: 'interview-driving-history',
  INTERVIEW_FINAL_QUOTE: 'interview-final-quote',
  INTERVIEW_HEALTH_CONDITIONS: 'interview-health-conditions',
  INTERVIEW_HEALTH_HISTORY: 'interview-health-history',
  INTERVIEW_INCOME: 'interview-income',
  INTERVIEW_INITIAL: 'interview-initial',
  INTERVIEW_LETS_CHAT: 'lets-chat',
  INTERVIEW_LOCATION_INFO: 'interview-location-info',
  INTERVIEW_NEEDS_REVIEW: 'interview-needs-review',
  INTERVIEW_PAYMENT: 'interview-payment',
  INTERVIEW_REVIEW_PENDING: 'interview-review-pending',
  INTERVIEW_SIGN: 'interview-sign',
  INTERVIEW_SMOKING: 'interview-smoking',
  INTERVIEW_SUBMISSION: 'interview-submission',
  INTERVIEW_THANKS: 'interview-thanks',
  INTERVIEW_UPDATE_PAYMENT: 'interview-update-payment',
  OR_INELIGIBLE: 'offramp-ineligible',
  OR_REPLACEMENT: 'offramp-replacement',
  OR_WAITLIST: 'offramp-waitlist',
  PRICING: 'pricing',
  QUICK_QUOTE: 'quick-quote',
  SAVE_PROGRESS: 'save-progress',
  SIGN_IN: 'signin',
  SIGN_IN_CALLBACK: 'signin-callback',
  SIGN_OUT: 'signout',
  UNDEFINED: '',
  WHY_DAYFORWARD: 'why-dayforward'
}

export const RedirectRouteNames = {
  HOME: '',
  LEARN_MORE: 'learn-more',
  WHY_DAYFORWARD: 'why-dayforward',
  HELP: 'help',
  ABOUT: 'about',
  CAREERS: 'careers',
  TERMS: 'terms',
  PRIVACY: 'privacy',
  GET_STARTED: 'get-started',
  MORE_INFO_NEEDED: 'more-info-needed',
  QUICK_PRICE: 'quick-price',
  REVIEWS: 'reviews'
}

export const SectionIDRouteName = {
  [SectionID.NAME_AND_ADDRESS]: RouteName.INTERVIEW_INITIAL,
  [SectionID.CONTACT_INFO]: RouteName.INTERVIEW_CONTACT_METHOD,
  [SectionID.BASIC_INFO]: RouteName.INTERVIEW_BASIC_INFO,
  [SectionID.INCOME]: RouteName.INTERVIEW_INCOME,
  [SectionID.DRIVING]: RouteName.INTERVIEW_DRIVING_HISTORY,
  [SectionID.SUBSTANCE_USE]: RouteName.INTERVIEW_SMOKING,
  [SectionID.HEALTH]: RouteName.INTERVIEW_HEALTH_HISTORY,
  [SectionID.IDENTITY_FOLLOWUP]: RouteName.INTERVIEW_CONFIRM_IDENTITY,
  [SectionID.HEALTH_CONDITION]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.DRIVING_FOLLOWUP]: RouteName.INTERVIEW_CONFIRM_INFO_PREQUOTE,
  [SectionID.HEALTH_FOLLOWUP_MUSCULOSKELETAL_SKIN]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_FOLLOWUP_TUMOR_BLOOD]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_FOLLOWUP_DIABETES_ENDOCRINE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_FOLLOWUP_URINARY_REPRODUCTIVE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_FOLLOWUP_GASTROINTESTINAL]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_FOLLOWUP_CARDIOVASCULAR]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_FOLLOWUP_MENTAL_HEALTH]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_FOLLOWUP_NERVOUS_SYSTEM]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_FOLLOWUP_RESPIRATORY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_CROHNS_DISEASE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_CIRRHOSIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEMOCHROM]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEPATITIS_B]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEPATITIS_C]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_LIVER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_PANCREATITIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_PRIMARY_SCLEROSING_CHOLANGITIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_REFLUX]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_THROAT]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_ULCERATIVE_COLITIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_GASTROINTESTINAL_ULCER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_ADHD]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_ANXIETY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_BIPOLAR]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_EATING]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_OCD]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_PSYCHOTIC]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_PTSD]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_ALS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_AUTOIMMUNE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_CHRONICPAIN]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_DIAGNOSIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_DYSPLASTIC_NEVUS_SYNDROME]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_GOUT]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_MARFANS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_MYOPATHY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_OSTEO]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_RHEUMATOID_ARTHRITIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_CHRONICPAIN]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_CONCUSSION]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_STROKE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_EPILEPSY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_MIGRAINE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_MULTIPLE_SCLEROSIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_PARKINSONS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_TRAUMATIC_BRAIN_INJURY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_TRANSIENT_ISCHEMIC_ATTACK]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_MENTAL_HEALTH_DEPRESSION]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_ARRHYTHMIA]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_CORONARY_ARTERY_DISEASE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_CARDIOMYOPATHY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_CHOLESTEROL]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_CONGENITAL]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_MURMUR]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_PRESSURE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_CARDIOVASCULAR_VALVE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_ADRENAL]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_GESTATION]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_GLUCOSE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_HYPERTHYROID]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_INSULIN]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_NONINSULIN]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_PITUITARY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_TRANSPLANT]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_PREGNANCY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_APNEA]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_ASTHMA]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_CYSTIC_FIBROSIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_COPD]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_COVID_19]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_PULMONARY_FIBROSIS]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_SARCO]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_RESPIRATORY_VAPE]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_TUMOR_BLOOD_ANEMIA]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_TUMOR_BLOOD_BENIGN]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_TUMOR_BLOOD_CLOTTING]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_TUMOR_BLOOD_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_TUMOR_BLOOD_SICKLECELL]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_HPV]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_INSUFFICIENCY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_KIDNEYFAIL]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_NEPHROPATHY]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_OTHER]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_PROTEIN]: RouteName.INTERVIEW_HEALTH_CONDITIONS,
  [SectionID.ERROR]: RouteName.ERROR,
  [SectionID.INELIGIBLE]: RouteName.OR_INELIGIBLE,
  [SectionID.NEEDS_REVIEW]: RouteName.INTERVIEW_NEEDS_REVIEW,
  [SectionID.CHAT_REQUIRED]: RouteName.INTERVIEW_LETS_CHAT,
  [SectionID.QUOTE]: RouteName.INTERVIEW_FINAL_QUOTE,
  [SectionID.IDENTITY_CONFIRMATION]: RouteName.CHECKOUT_CONFIRM_IDENTITY,
  [SectionID.PAYMENT]: RouteName.CHECKOUT_PAYMENT,
  [SectionID.BENEFICIARY]: RouteName.INTERVIEW_BENEFICIARY,
  [SectionID.ATTESTATION]: RouteName.INTERVIEW_ATTESTATIONS,
  [SectionID.SIGNATURE]: RouteName.INTERVIEW_SIGN,
  [SectionID.SUBMIT]: RouteName.INTERVIEW_SUBMISSION,
  [SectionID.COMPLETE]: RouteName.INTERVIEW_THANKS
}

export const RouteAuthReasons = {
  SIGN_IN: 'SIGN_IN',
  SAVE_PROGRESS: 'SAVE_PROGRESS',
  CHECKOUT: 'CHECKOUT',
  MANUAL_UNDERWRITING: 'MANUAL_UNDERWRITING',
  QUOTE: 'QUOTE',
  STEP_TWO_AUTH: 'STEP_TWO_AUTH'
}

export const AdminPathPrefix = '/admin'
export const IMOPathPrefix = '/imo'
