<template>
  <table :id="elementId" class="contentful__list">
    <tbody>
    <tr :key="item.name" v-for="item in items">
      <td class="contentful__list-item-title">{{ item.name }}</td>
      <td class="contentful__list-item-value">
        <ContentfulContentRenderer :content="item.value" />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { processRichText } from '@/api/Content'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-definition-list',
  components: { ContentfulContentRenderer },
  data () {
    return {
      items: []
    }
  },
  props: {
    data: undefined,
    elementId: undefined
  },

  created () {
    this.items = this.data.map((item) => {
      return {
        name: item.fields.name,
        value: processRichText(this, item.fields.value)
      }
    })
  }
}
</script>

<style scoped lang="scss">
.contentful__list {
  display: flex;
  flex-direction: row;

  border-spacing: 0 1rem;

  td {
    vertical-align: top;
    padding-right: 1rem;
  }

  .contentful__list-item-title {
    margin-right: 2rem;
    margin-bottom: 2rem;
    font-weight: bolder;
  }
}

::v-deep {
  .contentful__list-item-value {
    p {
      margin: 0 !important;
    }
  }
}
</style>
