<template>
  <div class="family-health" :class="last" :key="index + id">
    <p class="family-health__header">Family Member {{ index + 1 }}
      <button v-if="index !== 0" type="button" @click="removeFamilyMember(index)" class="remove-button__small">
        <img src="@/assets/images/ic-trash.svg" width="25" height="25">
      </button>
    </p>
    <div class="cols-10 family-health__container">
      <div class="family-health__item">
        <label>Relationship</label>
        <div class="family-health__input-wrapper">
          <Question
            @inputBlur="checkErrors"
            @input="returnValues"
            v-model="values.relationship"
            :question="questionsMap[`FAMILY_HISTORY_0_RELATIONSHIP`]"
            :error="errors.relationship"
            hide-details
            type="combobox"
            :hide-label="true"
          />
        </div>
      </div>
      <div class="family-health__item">
        <label>Diagnoses</label>
        <div class="family-health__input-wrapper">
          <Question
            :validateOnBlur="true"
            @inputBlur="checkErrors"
            @input="returnValues"
            v-model="values.diagnoses"
            :question="questionsMap[`FAMILY_HISTORY_0_DIAGNOSIS`]"
            :error="errors.diagnoses"
            hide-details
            type="select"
            :hide-label="true"
            :truncate="true"
          />
        </div>
      </div>
      <div class="family-health__item">
        <label>Severity</label>
        <div class="family-health__input-wrapper">
          <Question
            @inputBlur="checkErrors"
            @input="returnValues"
            v-model="values.severity"
            :question="questionsMap[`FAMILY_HISTORY_0_SEVERITY`]"
            :error="errors.severity"
            hide-details
            type="select"
            :hide-label="true"
          />
        </div>
      </div>
      <div class="family-health__item family-health__item-remove">
        <button v-if="index !== 0" type="button" @click="removeFamilyMember(index)" class="remove-button__large">
          <img src="@/assets/images/ic-trash.svg" width="25" height="25">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Question from '@/components/interview/Question'

export default {
  name: 'InterviewFamilyHealth.vue',
  components: { Question },
  props: {
    index: {
      type: Number
    },
    id: {
      type: Number
    },
    item: {
      type: Number
    },
    vals: {
      type: Object,
      default: () => {}
    },
    last: {
      type: String,
      default: ''
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      errors: {},
      values: {
        relationship: undefined,
        severity: undefined,
        diagnoses: undefined
      }
    }
  },
  computed: {
    ...mapGetters('interview', [
      'questionsMap'
    ])
  },
  methods: {
    removeFamilyMember (ind) {
      this.$emit('removeFamilyMember', ind, this.id)
      if (this.isAdmin === true) {
        this.values = {
          relationship: undefined,
          severity: undefined,
          diagnoses: undefined
        }
      }
    },
    returnValues () {
      const errors = this.checkErrors()
      this.$emit('memberUpdated', this.values, this.id, errors)
    },
    checkErrors () {
      for (const property in this.values) {
        if (this.values[property] === undefined || this.values[property] === '') {
          this.errors = true
          break
        } else {
          this.errors = false
        }
      }
      return this.errors
    }
  },
  mounted () {
    if (this.vals !== undefined) {
      this.values = { ...this.values, ...this.vals }
    } else {
      this.values = this.values
    }
    this.returnValues()
  }
}
</script>

<style lang="scss" scoped>
  @import 'src/assets/styles/media-queries';
  .family-health {
    margin-bottom: 2rem;
    &.last {
      margin-bottom: 1rem;
    }
    &:last-child {
      margin-bottom: 1rem;
    }
    &__header {
      margin-bottom: 10px;
      font-weight: bold;
    }
    &__container {
      display: grid;

      @include md {
        column-gap: 20px;
        grid-template-columns: 1fr 2fr 1fr 1fr;
        margin-bottom: 20px;
      }
    }

    &__item {
      display: grid;
      grid-template-rows: auto 1fr;
      row-gap: 10px;
      label {
        display: block;
      }
    }
    &__item-remove {
      display: flex;
      align-items: center;
    }
    &__input-wrapper {
      display: flex;
      align-items: flex-end;
    }
  }
  .remove-button {
    margin-top: 1rem;
    &__large {
      display: none;
      @include md {
        display: block;
      }
    }
    &__small {
      //display: block;
      //height: 50px !important;
      //margin-bottom: 22px;
      //margin-top: 1rem;
      float: right;
      position: relative;
      top: -2px;
      @include md {
        display: none;

      }
    }
  }
</style>
