<template>
  <div class="admin-related">
    <div class="admin-related__group" v-if="'fingerprintGroup' in interview && interview.fingerprintGroup !== null">
      <h3>Fingerprint Group</h3>
      <p><strong>Fingerprint: </strong>{{ interview.fingerprintGroup.fingerprint }}</p>
      <div class="admin-related__wrapper">
        <div
          class="admin-related__item"
          v-for="(item, index) in interview.fingerprintGroup.interviewSummaries"
          :key="item + index">
          <p><strong>Name: </strong>{{ ( item.applicant !== null ? item.applicant.fullName : '' ) }}</p>
          <p><strong>Id: </strong>{{ item.interviewID }}</p>
          <p><strong>Status: </strong>{{ item.status }}</p>
          <p><strong>Created: </strong>{{  moment(item.created).format("L LTS -Z") }}</p>
          <p><strong>Updated: </strong>{{  moment(item.updated).format("L LTS -Z") }}</p>
          <router-link
            :to="{
              name: `admin-detail-account`,
              params: {id: item.interviewID, ...(accountId !== null && {accountId: accountId}),
              name: `${ (item.applicant !== null ? item.applicant.fullName : '' )}` } }"
          >
            <span class="admin-dashboard__id-link-text">{{item.interviewID}}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="admin-related__group" v-if="'account' in interview && interview.account !== null  && interview.account.interviews !== null && interview.account.interviews.length > 1">
      <h3>Related Interviews</h3>
       <div class="admin-related__wrapper">
        <div
          class="admin-related__item"
          v-for="(item, index) in interview.account.interviews"
          :key="item + index">
            <p><strong>Name: </strong>{{ item.fullName }}</p>
            <p><strong>Id: </strong>{{ item.id }}</p>
            <p><strong>Status: </strong>{{ item.status }}</p>
            <p><strong>Created: </strong>{{  moment(item.created).format("L LTS -Z") }}</p>
            <p><strong>Updated: </strong>{{  moment(item.updated).format("L LTS -Z") }}</p>
            <router-link
              :to="{
                name: `admin-detail-account`,
                params: {id: item.id, ...(accountId !== null && {accountId: accountId}),
                name: `${item.firstName} ${item.lastName}` } }"
            >
              <span class="admin-dashboard__id-link-text">{{item.id}}</span>
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'admin-related',
  props: {
    interview: {
      type: Object,
      default: () => {
        return {}
      }
    },
    accountId: {
      type: String,
      default: null
    }
  },
  data () {
    return {}
  },
  methods: {
    moment
  }
}
</script>

<style scoped lang="scss">
.admin-related {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    flex: 1 50%;
    padding: 10px 0;
  }
}
</style>
