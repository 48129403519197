<template>
  <div class="interview-basic-info" >
    <FlowWrapper
      :progress="2/8"
      :can-move-to-next="hasValidFormData"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      @result="onResult">
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">Tell us more about you.</div>
      </template>
      <template v-slot:content>
        <v-form ref="infoForm">
          <div class="mq-grid">
            <div class="cols-10 cols-md-7">
              <Question
                class="cols-10 cols-md-7"
                v-model="values[QK.DATE_OF_BIRTH]"
                :question="questionsMap[QK.DATE_OF_BIRTH]"
                :error="errors[QK.DATE_OF_BIRTH]"
                :input-width="smElse('100%', '180px')"
                @input="validateBirthday"
                :validate-on-blur="checkBirthdayOnBlur"
              />
              <span v-if="isFiftyYearsOld" class="fine-print">
                We currently are not able to issue policies that begin after you turn 51,
                so please be sure to complete your purchase before your 51st birthday.
              </span>
            </div>
            <Question
              class="cols-10"
              v-model="values[QK.GENDER]"
              :question="questionsMap[QK.GENDER]"
              :error="errors[QK.GENDER]"
              type="toggle"
            />
            <Question
              class="cols-10 cols-md-3"
              v-model="values[QK.HEIGHT_INCHES]"
              :question="questionsMap[QK.HEIGHT_INCHES]"
              :error="errors[QK.HEIGHT_INCHES]"
            />
            <Question
              class="cols-5 cols-md-3"
              v-model="values[QK.WEIGHT_POUNDS]"
              :question="questionsMap[QK.WEIGHT_POUNDS]"
              :error="errors[QK.WEIGHT_POUNDS]"
              :tooltip="values[QK.GENDER] === 'FEMALE' ?
                'If you are expecting, please enter your pre-pregnancy weight.' : ''"
              inputmode="numeric"
              :input-width="smElse('100%', '120px')"
              @input="validateWeight"
              :validate-on-blur="checkWeightOnBlur"
              input-autocomplete="off"
            />
            <Question
              class="cols-10"
              v-model="values[QK.HAVE_CHILDREN]"
              :question="questionsMap[QK.HAVE_CHILDREN]"
              :error="errors[QK.HAVE_CHILDREN]"
              :options="{ true: 'Yes', false: 'No' }"
              type="yesno"
              @input="checkChildFlag"
            />
            <Question
              ref="childBdayEntry"
              class="cols-10"
              v-if="values[QK.HAVE_CHILDREN] === 'true'"
              v-model="values[QK.YOUNGEST_CHILDS_BIRTHDAY]"
              :question="questionsMap[QK.YOUNGEST_CHILDS_BIRTHDAY]"
              :error="errors[QK.YOUNGEST_CHILDS_BIRTHDAY]"
              :input-width="smElse('100%', '180px')"
              @input="validateChildBirthday"
              :validate-on-blur="checkChildBirthdayOnBlur"
            />
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { QuestionKey as QK } from '@/api/Interview'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'
import moment from 'moment'
import debounce from 'lodash/debounce'

export default {
  name: 'InterviewBasicInfoStep',
  components: { FlowWrapper, Question },
  data () {
    return {
      QK,
      values: {},
      errors: {},
      hasValidBirthday: false,
      hasValidChildBirthday: false,
      hasValidWeight: false,
      checkBirthdayOnBlur: false,
      checkChildBirthdayOnBlur: false,
      checkWeightOnBlur: false,
      weightCheckTimeout: undefined,
      isFiftyYearsOld: false
    }
  },

  computed: {
    ...mapGetters('interview', [
      'answers', 'answersForKeys', 'interviewID', 'interview', 'mapToAnswers', 'questionsMap',
      'parseBool', 'validateKeys'
    ]),

    isKidsBdayRequired () {
      return this.parseBool(this.values[QK.HAVE_CHILDREN])
    },

    hasValidFormData () {
      let isChildBdayValid = this.hasValidChildBirthday
      if (!this.values[QK.YOUNGEST_CHILDS_BIRTHDAY]) {
        isChildBdayValid = true
      }
      return !this.hasErrors && this.validateKeys(this.activeKeys, this.values) &&
        this.hasValidBirthday && isChildBdayValid && this.hasValidWeight
    },

    questionKeys () {
      return [
        QK.DATE_OF_BIRTH, QK.HEIGHT_INCHES, QK.WEIGHT_POUNDS, QK.GENDER, QK.HAVE_CHILDREN,
        QK.YOUNGEST_CHILDS_BIRTHDAY
      ]
    },
    answerValues () {
      return this.values
    },

    activeKeys () {
      const activeKeys = [
        QK.DATE_OF_BIRTH, QK.HEIGHT_INCHES, QK.WEIGHT_POUNDS, QK.GENDER, QK.HAVE_CHILDREN
      ]
      if (this.isKidsBdayRequired) activeKeys.push(QK.YOUNGEST_CHILDS_BIRTHDAY)
      return activeKeys
    },

    hasErrors () {
      let hasErrors = false
      for (let key of this.activeKeys) {
        if (this.errors[key] && this.errors[key] !== null) {
          hasErrors = true
          break
        }
      }
      return hasErrors
    }

  },

  methods: {
    ...mapActions('interview', ['updateInterviewAnswers', 'validateInterviewAnswers']),
    ...mapActions('search', ['updateRequestIdForKey']),
    ...mapGetters('auth', [ 'isAuthenticated' ]),
    checkOverFifty (val) {
      if (moment(val).isValid()) {
        const age = moment().diff(moment(val), 'years')
        this.isFiftyYearsOld = age === 50
      }
    },

    async validateBirthday (val) {
      this.checkOverFifty(val)
      this.$delete(this.errors, QK.DATE_OF_BIRTH)
      if (!moment(val, 'YYYY-MM-DD').isValid()) {
        this.hasValidBirthday = false
        this.checkBirthdayOnBlur = true
        return
      }
      const { success, result } = await this.confirmAnswer(QK.DATE_OF_BIRTH, val, true)

      // revalidate childs birthday if we change our birthday
      const childsBday = this.values[QK.YOUNGEST_CHILDS_BIRTHDAY]
      if (childsBday) {
        await this.validateChildBirthday(childsBday)
      }

      this.hasValidBirthday = success
      this.checkBirthdayOnBlur = !result
    },

    async validateChildBirthday (val) {
      this.$delete(this.errors, QK.YOUNGEST_CHILDS_BIRTHDAY)
      if (!moment(val, 'YYYY-MM').isValid()) {
        this.hasValidChildBirthday = false
        this.checkChildBirthdayOnBlur = true
        return
      }
      const { result, success } = await this.confirmAnswer(QK.YOUNGEST_CHILDS_BIRTHDAY, val, true)

      this.hasValidChildBirthday = success
      this.checkChildBirthdayOnBlur = !result
    },

    async validateWeight (val) {
      this.$delete(this.errors, QK.WEIGHT_POUNDS)
      if (this.weightCheckTimeout) {
        this.weightCheckTimeout.cancel()
      }
      this.weightCheckTimeout = debounce(async function (val) {
        const { result } = await this.confirmAnswer(
          QK.WEIGHT_POUNDS, val, true, 'weight-search'
        )
        if (result !== undefined) {
          this.hasValidWeight = result.success
          this.checkWeightOnBlur = !result
        }
      }.bind(this), 500)
      await this.weightCheckTimeout(val)
    },

    async confirmAnswer (key, value, validateOnly, searchKey) {
      if (!value) {
        return { success: false, result: undefined }
      }
      const answer = {
        questionKey: key,
        values: [value]
      }

      const genRequestId = Math.random().toString(36).substr(2)
      this.updateRequestIdForKey({ key: searchKey, requestId: genRequestId })
      let success, results
      if (!validateOnly) {
        ({ success, results } = await this.updateInterviewAnswers({
          id: this.interviewID,
          answers: [answer],
          context: { requestId: genRequestId },
          searchKey
        }))
      } else {
        ({ success, results } = await this.validateInterviewAnswers({
          id: this.interviewID,
          answers: [answer],
          context: { requestId: genRequestId },
          searchKey
        }))
      }

      if (results && results.length > 0) {
        const result = results[0]
        if (!result.success) {
          this.$set(this.errors, key, result.message)
        } else {
          this.$delete(this.errors, key)
        }
        return { success, result }
      }
      return { success: false, result: undefined }
    },

    checkChildFlag (val) {
      if (val === 'false') {
        this.$delete(this.values, QK.YOUNGEST_CHILDS_BIRTHDAY)
        this.hasValidChildBirthday = true
      }
    },

    onResult ({ errors }) {
      if (!errors) {
        return
      }
      Object.keys(errors).forEach((k) => {
        this.$set(this.errors, k, errors[k])
      })
    }

  },

  async created () {
    this.values = this.answersForKeys(this.questionKeys) || {}
    this.hasValidBirthday = !!this.values[QK.DATE_OF_BIRTH]
    this.hasValidChildBirthday = !!this.values[QK.YOUNGEST_CHILDS_BIRTHDAY]
    this.hasValidWeight = !!this.values[QK.WEIGHT_POUNDS]
  }

}
</script>

<style lang="scss" scoped>
 .fine-print {
   font-size: 0.8rem;
 }
</style>
