<template>
  <div class="main-menu">
    <div class="main-menu__content" :style="contentStyles">
      <div class="main-menu__top-row">
        <div class="main-menu__top-row-content">
          <Logo class="main-menu__logo" :color="Colors.eggshell" size="22" />
          <div class="spacer"></div>
          <button
            class="main-menu__close-button df-btn--light"
            @click="onCloseClicked"
          >
            <img
              alt="Menu"
              src="~@/assets/images/ic_close_oat.svg"
              width="18"
              height="18"
            />
          </button>
        </div>
      </div>
      <div class="main-menu__items">
        <div class="main-menu__item">
          <a
            href="#"
            @click.prevent="onItemClicked"
            :data-link-to="RedirectRouteNames.WHY_DAYFORWARD"
            >Why Dayforward</a
          >
        </div>
        <div class="main-menu__item">
          <a
            href="#"
            @click.prevent="onItemClicked"
            :data-link-to="RedirectRouteNames.ABOUT"
            >About</a
          >
        </div>
        <div class="main-menu__item">
          <router-link to="incoming">Blog</router-link>
        </div>
        <div class="main-menu__item">
          <a
            href="#"
            @click.prevent="onItemClicked"
            :data-link-to="RedirectRouteNames.HELP"
            >Help</a
          >
        </div>
        <div
          class="main-menu__item main-menu__item--smaller"
          style="margin-top: 40px"
        >
          <a
            href="#"
            @click.prevent="onItemClicked"
            :data-link-to="RedirectRouteNames.GET_STARTED"
            >Get started</a
          >
        </div>
        <div class="main-menu__item main-menu__item--smaller">
          <router-link to="signin">Sign In</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/accessories/Logo.vue";
import Colors from "@/assets/styles/_colors.scss";
import { gotoRedirect } from "@/utils/router";
import { RedirectRouteNames } from "@/router/routes/constants";

export default {
  name: "main-menu",
  components: { Logo },
  data: function () {
    return {
      Colors,
      RedirectRouteNames,
    };
  },
  computed: {
    contentStyles() {
      return {};
    },
  },
  methods: {
    gotoRedirect,
    onCloseClicked() {
      this.$emit("input", false);
    },
    onItemClicked(evt) {
      const linkTo = evt.target.getAttribute("data-link-to");
      if (linkTo) {
        if (linkTo === "get-started") {
          this.$analytics.track({
            event: "app_clickGetStarted",
            properties: {
              button_location: "main_menu",
              url: this.$route.path,
            },
          });
        }

        window.location = this.gotoRedirect(linkTo);
      }
      this.$emit("input", false);
    },
    onKeyDown(evt) {
      if (["Escape"].indexOf(evt.code) !== -1) {
        this.$emit("input", false);
      }
    },
    onBackPressed() {
      this.$emit("input", false);
    },
  },
  created() {
    document.addEventListener("keydown", this.onKeyDown, { once: true });
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/media-queries";

.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #e33031;
  width: 100%;
  height: 100%;
  color: #f2ede0;
  z-index: 9999;
  &__top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 64px;
    &-content {
      background-color: #e33031;
      flex: 1;
      display: grid;
      grid-template-columns: 200px 1fr auto;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__logo {
    margin-top: 2px;
  }
  &__close-button {
    margin-top: 8px;
    min-height: 0;
    width: 46px;
    height: 46px;
    margin-right: -12px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    @include lg {
      padding: 0 60px;
    }
  }
  &__items {
    margin-top: 2rem;
    @include md {
      margin-top: 5rem;
    }
  }
  &__item {
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -0.04em;
    a {
      color: #f2ede0 !important;
      border-bottom: 1px solid transparent;
      text-decoration: none;
      &:hover {
        color: #f2ede0;
        border-color: rgba(#f2ede0, 0.5);
      }
    }
    &--smaller {
      font-size: 28px;
      font-weight: 400;
    }
  }
}
</style>
