<template>
  <div class="mq-grid">
    <div class="cols-10" v-if="isLoading && !forceCapture">
      <div class="payment-check-loader">
        <v-progress-circular :color="Colors.red" width="2" size="20" indeterminate />
        <div class="payment-check-loader__text">Checking payment methods ...</div>
      </div>
    </div>
    <div class="cols-10 cc-list" v-if="!isLoading && hasExistingCreditCards && !forceCapture">
      <div class="cc-list-item">
        <div class="cc-number">Credit Card</div>
        <div class="cc-exp">Expires</div>
      </div>
      <div v-for="cc in creditCards" :key="cc.id" class="cc-list-item">
        <div class="cc-number">
          <span class="cc-brand">{{ cc.brand }}</span>
          ending in {{ cc.last4 }}
        </div>
        <div class="cc-exp">{{ cc.expMonth }}/{{ cc.expYear }}</div>
        <div class="cc-actions">
          <a @click="updateCreditCard(cc.id)" class="cc-remove">{{ editCardActionText }}</a>
        </div>
      </div>
    </div>
    <template v-else-if="!isLoading && (!hasExistingCreditCards || forceCapture)">
      <div class="credit-entry cols-10 cols-md-6 tm-1r">
        <LabeledHolder label="Credit Card" :hide-label="hideTitle">
          <div ref="card" class="payment-card"></div>
        </LabeledHolder>
      </div>
    </template>
    <div class="cols-10 cc-actions" v-if="!isLoading">
      <a v-if="showSave" @click="onSavePressed" class="cc-save">{{ saveText }}</a>
      <a v-if="!isSaving" @click="cancelUpdate" class="cc-save">Cancel</a>
    </div>
    <div class="cols-5" v-if="$gtSm"></div>
    <div class="cols-10 mt-3r" v-if="error">
      <div class="payment-error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
/* global Stripe */
import Colors from '@/assets/styles/_colors.scss'
import theme from '@/config/theme.config'
import { stripePubKey } from '@/config/app.config'
import { mapActions } from 'vuex'
import LabeledHolder from '@/components/inputs/LabeledHolder'
import { datadogRum } from '@datadog/browser-rum'

export default {
  name: 'CreditCardInput',

  components: { LabeledHolder },

  props: {
    forceCapture: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    existingCreditCards: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      Colors,
      isLoading: !this.forceCapture,
      error: null,
      stripe: null,
      elements: null,
      card: null,
      hasEmptyCardInfo: true,
      hasValidCardInfo: false,
      hasValidCard: false,
      billingAccount: null,
      hasStripeInitError: false,
      creditCards: null,
      isSaving: false,
      previousCards: null,
      removeIds: []
    }
  },

  computed: {
    hasExistingCreditCards() {
      return this.creditCards ? this.creditCards.length > 0 : false
    },

    saveText() {
      return this.isSaving ? 'Saving...' : 'Save'
    },

    showSave() {
      return this.forceCapture || this.removeIds.length > 0
    },

    editCardActionText() {
      return this.creditCards && this.creditCards.length > 1 ? 'Remove' : 'Replace'
    }
  },

  methods: {
    ...mapActions('billing', ['removeCreditCard', 'attachCreditCard', 'getBillingAccount', 'getCreditCards']),

    async loadStripeElements() {
      if (window.Stripe) {
        this.stripeCallback()
        return
      }
      const script = document.createElement('script')
      script.src = 'https://js.stripe.com/v3/'
      script.async = false
      document.head.appendChild(script)
      script.addEventListener('load', this.stripeCallback)
    },

    stripeCallback() {
      const options = {
        hidePostalCode: true,
        iconStyle: 'solid', // solid or default
        hideIcon: false,
        style: {
          base: {
            iconColor: theme.colors.primary,
            color: 'rgba(0,0,0,0.6)',
            fontWeight: 400,
            fontFamily: 'Graphik, sans-serif',
            fontSize: '16px',
            // lineHeight: '20px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#fce883'
            }
          },
          invalid: {
            iconColor: theme.colors.error,
            color: theme.colors.error
          }
        }
      }

      this.hasStripeInitError = false
      this.stripe = Stripe(stripePubKey)
      if (!this.stripe) {
        // eslint-disable-next-line no-console
        console.error('could not initialize Stripe')
        this.hasStripeInitError = true
        return
      }
      this.elements = this.stripe.elements()
      this.card = this.elements.create('card', options)
      if (!this.card) {
        // eslint-disable-next-line no-console
        console.error('could not initialize Card')
        this.hasStripeInitError = true
        return
      }
      this.card.mount(this.$refs.card)

      this.card.on('change', (e) => {
        if (!e.error) {
          this.error = null
        }
        this.hasEmptyCardInfo = false
        if (e.complete) {
          this.hasValidCardInfo = true
        } else if (e.error) {
          this.hasValidCardInfo = false
          this.error = e.error.message
        } else if (e.empty) {
          this.hasValidCardInfo = false
          this.hasEmptyCardInfo = true
        }
      })
    },

    async getCreditCardToken() {
      const result = await this.stripe.createToken(this.card)
      if (result) {
        if (result.error) {
          const code = result.error.code
          if (code.indexOf('invalid') > -1 || code.indexOf('incorrect') > -1 || code === 'expired_card') {
            /*
            incorrect_address, incorrect_cvc, incorrect_number, incorrect_zip
            invalid_card_type, invalid_cvc, invalid_expiry_month, invalid_expiry_year, invalid_number
            */
            this.error = result.error.message
            this.hasValidCard = false
          } else {
            throw new Error('unknown stripe error')
          }
          return null
        }
        return result.token
      }
      throw new Error('unknown stripe error')
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },

    async updateCreditCard(id) {
      this.removeIds.push(id)
      this.previousCards = this.creditCards
      this.creditCards = this.creditCards.filter((item) => item.id !== id)
    },

    cancelUpdate(close = false) {
      this.removeIds = null
      this.creditCards = this.previousCards
      this.previousCards = null
      if (close) this.$emit('canceled')
    },

    async deleteCards() {
      if (this.removeIds && this.removeIds.length) {
        for (let id of this.removeIds) {
          await this.removeCreditCard({ cardId: id })
        }
      }
    },

    async fetchTokenRetryLoop(maxWait) {
      let token = null
      let attempt = 0

      do {
        attempt++
        try {
          token = await this.getCreditCardToken()
        } catch (err) {
          datadogRum.addError(err)
        }
        // if we get a token, or an error that marks our card as invalid, break the auto-retry loop
        if (token || !this.hasValidCard) {
          break
        }
        await this.sleep(Math.min((1 << attempt) / 2, maxWait) * 1000)
      } while (attempt < 3)
      return token
    },

    async onSavePressed() {
      if (!this.forceCapture && this.hasExistingCreditCards) {
        if (this.removeIds && this.removeIds.length > 0) {
          try {
            await this.deleteCards()
            this.$emit('deleted')
            return true
          } catch (err) {
            this.$emit('failed')
            return false
          }
        } else {
          return true
        }
      }

      this.saving = true
      this.$emit('saving')
      const token = await this.fetchTokenRetryLoop(10)
      if (!token) {
        this.$emit('failed')
        this.saving = false
        return this.hasValidCard
      }
      // attach the credit card token to the billing account
      try {
        const { last4 } = await this.attachCreditCard({ tokenId: token.id })
        await this.deleteCards()
        this.$emit('succeeded', { last4 })
        this.saving = false
      } catch (err) {
        this.error = 'Invalid credit card number'
        this.$emit('failed')
        // this.saving = false
        return this.hasValidCard
      }
    }
  },

  async mounted() {
    this.$nextTick(async function () {
      this.billingAccount = await this.getBillingAccount()
      if (this.billingAccount !== null && !this.forceCapture) {
        if (this.existingCreditCards && this.existingCreditCards.length) {
          this.creditCards = this.existingCreditCards
        } else {
          this.creditCards = await this.getCreditCards()
        }
      }
      await this.loadStripeElements()
      setTimeout(
        function () {
          this.isLoading = false
        }.bind(this),
        300
      )
    })
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';
@import '~vuetify/src/styles/main.sass';
@import 'src/assets/styles/colors';

.cc-list {
  .cc-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 40px;
  }

  .cc-number {
    flex: 0 1 50%;

    .cc-brand {
      text-transform: capitalize;
    }
  }

  .cc-exp {
    flex: 0 1 120px;
  }

  .cc-actions {
    flex: 1 0 auto;

    .cc-remove {
      min-height: 16px !important;
      min-width: 50px !important;
    }
  }
}

.cc-save {
  min-width: 50px;
}

.payment-card {
  padding: 8px 0;

  &.StripeElement {
    position: relative;
    display: block;
    border-bottom: 1px solid $c-primary;
    font-size: 16px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      border-bottom: 1px solid $c-primary;
      transition: 300ms cubic-bezier(0.25, 0.8, 0.5, 1) transform;
      transform: scaleX(0);
    }

    &--focus {
      &:after {
        transform: scaleX(1);
      }
    }

    &--invalid {
      border-color: $c-error;

      &:after {
        border-color: $c-error;
      }
    }
  }
}

.payment-error {
  color: $c-error;
}

.credit-entry {
  min-height: 40px;
}

.payment-check-loader {
  display: flex;
  flex-direction: row;

  &__text {
    margin-left: 10px;
  }
}
</style>
