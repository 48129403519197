<template>
  <div>
    <p>Your consent is needed before you may apply for and purchase a Dayforward policy online on our website using an
      electronic signature and receive written communications from us electronically. We are required by law to provide
      you with certain information related to this transaction “in writing”, which means you have a right to receive
      that information on paper. However, with your consent, we may provide this information to you electronically instead.
      To continue with this electronic life insurance transaction process, including but not limited to applying your
      electronic signature to the transaction form(s) and accepting electronic delivery of any notices and required disclosure
      documents including your life insurance policy (if applicable), you must provide your consent to the terms and conditions
      listed below the following disclosure.&nbsp;</p>
    <p><strong>Hardware and Software Requirements</strong></p>
    <p>To access and retain a signed copy of all documentation for this insurance product, including the application,
      policy (if one is issued) and all related disclosures and documents, you must:</p>
    <ul>
      <li>have access to a computer or mobile device, such as a tablet, smartphone or similar device, capable of running
        a current Internet browser;
      </li>
      <li>have access to Internet or cellular service depending on your device;&nbsp;</li>
      <li>have an email account and be able to send and receive emails with hyperlinks to websites and attached files;&nbsp;</li>
      <li>have a monitor or screen and Portable Document Format (PDF) reader software installed on your computer or
        mobile device, to be able to view disclosures/documents sent in PDF. If you do not have such software, Adobe
        Reader or similar software is available online to be downloaded to your computer or mobile device to view PDF
        documents. You can find applications that support printing and saving for most mobile devices through your
        mobile device&rsquo;s app store. If your mobile device does not have this functionality, you must be able to
        access our website through alternate means that provide you with the ability to print and save the
        Communications;
      </li>
      <li>have the ability to download and save electronic documents to your computer, or print them using a connected
        printer; and
      </li>
      <li>have a mobile device with the ability to receive SMS text messages, if you would like to receive
        Communications via SMS text message, and with the ability to download apps to receive, access, view, and print
        documents, if you would like to receive Communications in that manner once we make that option available.&nbsp;
      </li>
    </ul>
    <p>If you do not have the required software and/or hardware or if you do not wish to use electronic signatures or
      receive documents electronically for any reason, you will be unable to apply for and purchase this product.</p>
    <p><strong>Terms and Conditions</strong></p>
    <p>By giving your consent, you agree to all the following terms and conditions, including:</p>
    <ul>
      <li>to conduct business with us electronically;</li>
      <li>to complete the application, required authorizations and any other documents requiring your signature using an
        electronic signature that, through your action, direction or authorization, will be applied to these documents;
      </li>
      <li>to receive electronic delivery of your application and insurance policy (if one is issued), as well as
        notices, disclosures, authorizations, forms, and any other documents and written communications that are
        permitted by law to be sent electronically, whether before or after we may issue you a policy (collectively
        &ldquo;Communications&rdquo;);
      </li>
      <li>to receive all electronic Communications by or through one or more of the following delivery methods as
        permitted by law: email, SMS text message, push notification from an application you download on your mobile
        device, and/or posting on our website or other services from us;
      </li>
      <li>that your consent will be valid until withdrawn and will apply to any business you do with us now or in the
        future, whether involving products or services currently available or that may be available in the future;
      </li>
      <li>that the electronic signature you cause to be applied to any document that requires your signature will have
        the same force and effect as if you provided your signature on a paper application by hand;
      </li>
      <li>that you have the ability to access, view, store, download and print Communications and hyperlinks we deliver
        or make available to you electronically through your computer or on your mobile device, in accordance with our
        hardware and software requirements disclosed above.&nbsp;
      </li>
      <li>that you are giving us permission to contact you at any email address, address or phone number provided to us,
        or known to us, about your Dayforward account, and that your consent allows us to use text messaging and voice
        messages for all purposes; and
      </li>
      <li>that you will notify us in writing of any changes to your email address and other contact information at
        <a href="mailto:help@dayforward.com">help@dayforward.com</a>.
      </li>
    </ul>
    <p>By giving your consent, you also acknowledge that you understand that:</p>
    <ul>
      <li>your consent is voluntary and you may withdraw your consent to conduct business electronically, to use an
        electronic signature, and/or to receive Communications electronically at any time by contacting us at
        <a href="tel:+18884340228">1-888-434-0228</a> or <a href="mailto:help@dayforward.com">help@dayforward.com</a>;
      </li>
      <li>If you withdraw your consent to receive Communications electronically, we will process your consent withdrawal
        request within a reasonable time after receiving it and you will continue to receive Communications
        electronically until we process your request, after which you will receive copies in paper sent to your physical
        address of record;
      </li>
      <li>if you withdraw your consent to do business electronically or use an electronic signature, you will be unable
        to apply for products and services through our website, and we will be unable to continue to process any pending
        applications for products and services, including insurance products.&nbsp;
      </li>
      <li>your consent also applies to all Communications and information that we receive from you.&nbsp;</li>
      <li>you have the option to receive a paper copy of any Communication, at no cost to you by contacting us at
        <a href="tel:+18884340228">1-888-434-0228</a> or <a href="mailto:help@dayforward.com">help@dayforward.com</a>, which on a single or limited periodic request basis will not require or
        constitute a withdrawal of your consent to receive Communications electronically.
      </li>
      <li>you are considered to have received Communications from us when we post Communications to your online account
        with us or, if required or at our option, we send you an email and, therefore, you should check your online
        account with us regularly.
      </li>
      <li>although we may from time to time contact you to inform you that a Communication has been posted to your
        online account with us, we do not agree or commit to do that each and every time we post a Communication, and we
        may post important Communications to your online account, without communicating further with you, that require
        your prompt attention.&nbsp;
      </li>
      <li>that you may contact us at any time to change your preferences for how we contact you; and</li>
      <li>while we do not charge a fee for providing electronic Communications, you may incur internet access charges,
        phone charges, message or data rate charges, and other third-party charges when receiving electronic documents
        or SMS text messages or downloading required software. Your ability to access or transact business through this
        electronic process may be limited due to circumstances beyond our control, such as system outages.
      </li>
    </ul>
    <p><strong>Statement of Consent</strong></p>
    <p>By checking/clicking the appropriate box in the online application, you agree to the terms and conditions in this
      Consent and confirm that you have the necessary hardware and software as disclosed in the Hardware and Software
      Requirements section above.</p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'electronic-consent',
  data () {
    return {}
  },

  methods: {
    ...mapActions('content', ['fetchContentfulAsset'])
  },

  async created () {
    const url = await this.fetchContentfulAsset({
      id: '2r6PlwmxW5fBcLlQXuyOEL'
    })
    if (url) {
      this.$emit('pdfURLGenerated', url)
    }
  }
}
</script>

<style scoped>
</style>
