<template>
  <div class="contentful__card">
    <div class="contentful__card-image">
      <img :src="image.fields.file.url"/>
    </div>

    <div class="contentful__card-title">
      {{ title }}
    </div>

    <div v-html="processedContent" class="contentful__card-content">
    </div>

    <div class="contentful__card-emphasized">
      {{ emphasizedFooter }}
    </div>
  </div>
</template>

<script>

import { processRichText } from '@/api/Content'

export default {
  name: 'contentful-card',

  props: {
    title: undefined,
    image: undefined,
    content: undefined,
    emphasizedFooter: undefined
  },

  computed: {
    processedContent () {
      return processRichText(this, this.content)
    }
  }
}
</script>

<style scoped lang="scss">

.contentful__card {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  img {
    width: 100%;
  }
}

.contentful__card-title {
  font-weight: bolder;
}

.contentful__card-emphasized {
  font-size: larger;
  font-weight: bolder;
  color: $c-red;
}

::v-deep {
  p {
    margin: 0 !important;
  }
}
</style>
