<template>
  <div class="interview-customize-policy" v-if="newQuote">
    <div class="mq-container">
      <header class="df-header df-header__p-top">
        <MainNavigationHeader />
        <div class="df-header-text">Personalize your policy.</div>
        <p>Name your own monthly payment and we will build a custom policy for you.</p>
      </header>
      <div class="interview-customize-policy__wrapper">
        <div class="interview-customize-policy__form">
        <div class="interview-customize-policy__premium-wrapper">
          <label class="interview-customize-policy__label">
            <h4>How much do you want to pay monthly?</h4>
          </label>
          <div class="interview-customize-policy__premium">
            <v-text-field
              prefix="$"
              class="interview-customize-policy__premium"
              type="number"
              @input="clearErrors"
              v-model="editPremium"
            />
          </div>
        </div>
        <div>
          <label class="interview-customize-policy__label">
            <h4>How long should your policy last?</h4>
          </label>
          <div class="interview-customize-policy__length">
            <p><span class="interview-customize-policy__child" v-if="termReasonChildAge">Until my child is </span>
            <span class="interview-customize-policy__child" v-if="termReasonInsuredAge">Until I plan to retire at </span>
            <QuoteTermSelect
              v-if="benefitRange"
              styleVariant="unstyled"
              :termReason="termReason"
              :text="termEndReason"
              :editEnabled="true"
              :hasChild="newQuote.hasChild"
              :minMax="benefitRange"
              v-model="editTermLength"
              @change="onQuoteSelectChange"
              ref="ageSelect"
              class="cols-md-2"
            />
            </p>
          </div>
        </div>
        <DfButton
          @click="submitValues"
          :is-loading="isFetchingQuote"
        >
          Calculate
        </DfButton>
        <div class="interview-customize-policy__error" v-if="inlineError">
          <p class="error-text">{{ inlineError }}</p>
        </div>
      </div>
        <div class="interview-customize-policy__card-container">
          <InterviewQuoteCard
            :quote-result="newQuote"
            eyebrow="Your Policy"
            :index="0"
          />
        </div>
      </div>
      <a rel="noopener" class="interview-customize-policy__link" href="#" @click.prevent="showModal('checkout')">
        Am I really approved for this policy?
      </a>
    </div>
    <CheckoutNowModal
      :show="isShowingModal('checkout')"
      @close="hideModal('checkout')"
    />
    <MainFooter />
  </div>
</template>

<script>
import MainFooter from '@/components/navigation/MainFooter'
import MainNavigationHeader from '@/components/navigation/MainNavigationHeader'
import QuoteTermSelect from '@/components/interview/QuoteTermSelect'
import InterviewQuoteCard from '@/views/interview/InterviewQuoteCard'
import CheckoutNowModal from '@/components/modals/CheckoutNowModal'

import { mapActions, mapGetters } from 'vuex'
import DfButton from '@/components/DFButton'
import { BenefitTermCode } from '@/api/Interview'

export default {
  name: 'interview-customize-policy',
  components: {
    DfButton,
    InterviewQuoteCard,
    MainFooter,
    MainNavigationHeader,
    QuoteTermSelect,
    CheckoutNowModal
  },
  data () {
    return {
      benefitRange: undefined,
      editPremium: undefined,
      editTermLength: undefined,
      inlineError: undefined,
      isFetchingQuote: false,
      monthlyPayment: '',
      monthlyPaymentError: false,
      newQuote: undefined,
      term: '',
      visibleModalKey: undefined
    }
  },
  computed: {
    ...mapGetters('interview', ['interviewID']),
    quote () {
      return this.$store.state.interview.interview.quote
    },
    termReason () {
      if (this.quote && this.newQuote.termReason) {
        return this.newQuote.termReason.code
      }
      return null
    },
    termEndReason () {
      if (!this.newQuote) { return '' }
      const desc = this.newQuote.termReason.description
      return `${desc.charAt(0).toLowerCase()}${desc.slice(1)}`
    },
    termReasonChildAge () {
      if (this.newQuote?.termRecommended || this.quote.termRecommended !== null) {
        return this.termReason === BenefitTermCode.CHILD_AGE
      }
      return false
    },
    termReasonInsuredAge () {
      if (this.termReason !== null) {
        return this.termReason === BenefitTermCode.INSURED_AGE
      }
      return false
    }
  },
  methods: {
    ...mapActions('interview', ['getQuote']),
    ...mapActions('admin', ['quoteOverride']),
    clearErrors () {
      this.inlineError = undefined
    },
    submitValues () {
      this.fetchOverride()
    },
    async fetchOverride () {
      this.isFetchingQuote = true
      this.$analytics.triggerOptimizeEvent('get_customized_quote', {
        premium: this.editPremium,
        editTermLength: this.editTermLength
      })
      let override
      if (this.termReason === BenefitTermCode.CHILD_AGE) {
        override = { untilChildAge: this.editTermLength }
      } else if (this.termReason === BenefitTermCode.INSURED_AGE) {
        override = { untilInsuredAge: this.editTermLength }
      } else {
        override = { termYears: this.editTermLength }
      }

      const res = await this.quoteOverride({ id: this.interviewID,
        override: {
          ...(this.editPremium && { premium: this.editPremium }),
          ...(this.editTermLength && override)

        }
      })

      if (res.quote && res.quote !== null) {
        this.newQuote = res.quote
        this.setupNewQuote()
        this.inlineError = undefined
      } else {
        this.inlineError = res.reason
      }
      if (res?.message) {
        this.inlineError = res.message
      }

      this.isFetchingQuote = false
    },
    quoteOverrideChange () {
      this.resetEmptyValues()
      this.fetchOverride()
    },
    resetEmptyValues () {
      if (this.overrideTermYears === '') {
        this.overrideTermYears = undefined
      } else if (this.editPremium === '') {
        this.editPremium = undefined
      }
    },
    onQuoteSelectChange (val) {
      this.editTermLength = val
      this.setTermValues()
    },
    setTermValues () {
      this.untilChildAge = this.termReasonChildAge ? this.editTermLength : null
      this.untilInsuredAge = this.termReasonInsuredAge ? this.editTermLength : null
      this.termYears = !(this.termReasonChildAge || this.termReasonInsuredAge) ? this.editTermLength : null
    },

    setTermRange () {
      if (this.termReasonChildAge && this.newQuote.youngestChildAgeLimit !== undefined) {
        this.benefitRange = [
          this.newQuote.youngestChildAgeLimit.minimum,
          this.newQuote.youngestChildAgeLimit.maximum
        ]
      } else if (this.termReasonInsuredAge && this.newQuote.insuredAgeLimit !== undefined) {
        this.benefitRange = [
          this.newQuote.insuredAgeLimit.minimum,
          this.newQuote.insuredAgeLimit.maximum
        ]
      } else {
        this.benefitRange = [
          Math.floor(parseInt(this.newQuote.termLimit.minimum) / 12),
          Math.floor(parseInt(this.newQuote.termLimit.maximum) / 12)
        ]
      }
    },
    setupNewQuote () {
      this.editPremium = this.newQuote.premium
      this.defaultBenefitAmount = this.newQuote.benefitAmount
      this.editBenefitAmount = this.newQuote.benefitAmount
      this.setEditTermLength()
      this.setBenefitRange()
      this.setTermRange()
      this.untilChildAge = this.termReasonChildAge ? this.editTermLength : null
      this.untilInsuredAge = this.termReasonInsuredAge ? this.editTermLength : null
    },
    setEditTermLength () {
      if (this.termReasonChildAge) {
        this.editTermLength = this.newQuote.termReason.untilChildAge
      } else if (this.termReasonInsuredAge) {
        this.editTermLength = this.newQuote.termReason.untilInsuredAge
      } else {
        this.editTermLength = this.newQuote.termRecommended / 12
      }
    },
    setBenefitRange () {
      if (this.newQuote.benefitLimit && this.newQuote.benefitAmount) {
        this.defaultBenefitAmount = this.newQuote.benefitAmount
        this.minBenefit = this.newQuote.benefitLimit.minimum
        this.maxBenefit = this.newQuote.benefitLimit.maximum
      }
    },
    isShowingModal (key) {
      return this.visibleModalKey === key
    },
    showModal (key) {
      this.visibleModalKey = key
    },
    hideModal (key) {
      if (this.visibleModalKey === key) {
        this.visibleModalKey = undefined
      }
    }
  },
  async beforeMount () {
    this.newQuote = await this.getQuote({ id: this.interviewID })
    this.setupNewQuote()
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/titles";
@import "src/assets/styles/media-queries";

.df-header-text {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

.interview-customize-policy {
  &__link {
    margin-top: 2rem;
    @include lg {
      text-align: right;
      width: 100%;
    }
  }
  &__error {
    color: $c-red
  }
  &__wrapper {
    display: grid;
    row-gap: 3rem;
    @include lg {
      grid-template-columns: 360px auto;
      column-gap: 30px;
      margin-top: 3rem;
    }
  }

  &__form {
    margin-top: 2rem;
    display: grid;
    grid-auto-rows: auto auto 1fr;
    row-gap: 30px;
  }

  &__label {
    margin-bottom: 1rem;
    display: block;
  }
  &__length {
    ::v-deep .v-input {
      max-width: 80px !important;
    }
  }
  &__child {
    margin-right: 5px;
  }
  &__premium {
    display: grid;
    grid-template-columns: auto 2fr;
    column-gap: 10px;
    .v-input {
      max-width: 80px;
      display: inline-block;
    }
     span {
       display: inline-block;
       margin-top: 8px;
     }
  }

  &__button-wrapper {
    margin: 2rem 0;
    text-align: center;
    @include md {
      text-align: left;
    }
  }

  ::v-deep input[type='number'] {
    -moz-appearance:textfield;
  }

  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::v-deep input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

</style>
