<template>
  <div class="interview-confirm-information">
    <FlowWrapper
      :progress="1"
      :can-move-to-next="hasValidFormData"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      :off-ramp-flag="ORF.LOCATION"
      :on-next="onNext"
      @result="onSaveResult"
    >
      <!-- <template v-slot:header-text>{{ $route.meta.header.title }}</template> -->
      <template v-slot:content-header>
        <div class="df-content-header-text">
          {{ $route.meta.content.title }}
        </div>
        <p class="df-content-header-subtitle">
          {{ $route.meta.content.subtitle }}
        </p>
      </template>
      <template v-slot:content>
        <v-form ref="basicForm">
          <div class="mq-grid">
            <LabeledHolder :label="LABELS.FIRST_NAME" class="cols-8 cols-md-3">
              <Question
                v-model="values[QK.FIRST_NAME]"
                :question="questionsMap[QK.FIRST_NAME]"
                :error="errors[QK.FIRST_NAME]"
                hide-label
                :id="inputId(LABELS.FIRST_NAME)"
                maxlength="50"
                :keysAllowed="AllowedTypes.NAMES"
              />
            </LabeledHolder>
            <LabeledHolder :label="LABELS.MIDDLE_INITIAL" class="cols-2 cols-md-1" tooltip="Middle Initial">
              <Question
                v-model="values[QK.MIDDLE_INITIAL]"
                :question="questionsMap[QK.MIDDLE_INITIAL]"
                :error="errors[QK.MIDDLE_INITIAL]"
                hide-label
                maxlength="1"
                :id="inputId(LABELS.MIDDLE_INITIAL)"
                :keysAllowed="AllowedTypes.LETTERS"
              />
            </LabeledHolder>
            <LabeledHolder :label="LABELS.LAST_NAME" class="cols-10 cols-md-4">
              <Question
                v-model="values[QK.LAST_NAME]"
                :question="questionsMap[QK.LAST_NAME]"
                :error="errors[QK.LAST_NAME]"
                hide-label
                :id="inputId(LABELS.LAST_NAME)"
                maxlength="50"
                :keysAllowed="AllowedTypes.NAMES"
              />
            </LabeledHolder>
            <AddressPicker v-model="selectedAddress" class="cols-10 cols-md-8 bm-10" />
            <Question
              class="cols-10 cols-md-7"
              v-model="values[QK.DATE_OF_BIRTH]"
              :question="questionsMap[QK.DATE_OF_BIRTH]"
              :error="errors[QK.DATE_OF_BIRTH]"
              :input-width="smElse('100%', '180px')"
              @input="isDirty"
            />
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'
import { mapActions, mapGetters, mapState } from 'vuex'
import AddressPicker from '@/components/inputs/AddressPicker'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import LabeledHolder from '@/components/inputs/LabeledHolder'
import Question from '@/components/interview/Question'
import { InterviewFlagCause, QuestionKey as QK } from '@/api/Interview'
import { inputId } from '@/utils/inputs'
import { AllowedTypes } from '@/utils/keysAllowed-directive.js'
import { RouteName } from '@/router/routes/constants.js'

const LABELS = {
  FIRST_NAME: 'First name',
  MIDDLE_INITIAL: 'M.',
  LAST_NAME: 'Last name'
}

export default {
  name: 'InterviewConfirmInformation',
  components: {
    AddressPicker,
    FlowWrapper,
    LabeledHolder,
    Question
  },
  computed: {
    ...mapState({
      intId: (state) => state.interview.interview.id,
      accId: (state) => state.interview.accountId
    }),
    ...mapGetters('interview', [
      'answers',
      'answersForKeys',
      'questionsMap',
      'interviewID',
      'interview',
      'mapToAnswers',
      'validatedAddress',
      'parseBool',
      'interviewID',
      'hasIdentityFollowup'
    ]),
    questionKeys() {
      return [
        QK.FIRST_NAME,
        QK.MIDDLE_INITIAL,
        QK.LAST_NAME,
        QK.ADDRESS_LINE_1,
        QK.ADDRESS_LINE_2,
        QK.ADDRESS_CITY,
        QK.ADDRESS_STATE,
        QK.ADDRESS_ZIP,
        QK.DATE_OF_BIRTH
      ]
    },
    answerValues() {
      let result = this.values
      if (this.selectedAddress && this.selectedAddress.address) {
        const addressAnswers = this.addressToAnswers(this.selectedAddress)
        for (const [k, v] of Object.entries(addressAnswers)) {
          result[k] = v
        }
      }
      return result
    },
    hasValidFormData() {
      return (
        !!this.values[QK.DATE_OF_BIRTH] &&
        !!this.values[QK.FIRST_NAME] &&
        !!this.values[QK.LAST_NAME] &&
        !!this.selectedAddress &&
        this.selectedAddress.isValid
      )
    }
  },
  data() {
    return {
      AllowedTypes,
      Colors,
      LABELS,
      QK,
      ORF: InterviewFlagCause,
      STEP_NAME: 'initial',
      acceptedNotices: undefined,
      contentfulEntry: { title: '', content: '' },
      errors: {},
      selectedAddress: undefined,
      values: {}
    }
  },
  methods: {
    ...mapActions('interview', ['checkFollowupSectionsActivated', 'getStatus']),
    isDirty(val, key) {
      if (key && this.errors[key]) this.errors[key] = null
    },

    async onNext() {
      await this.$router.push({ name: RouteName.INTERVIEW_FINAL_QUOTE })
      return { navigate: false }
    },

    onSaveResult(res) {
      this.checkFollowupSectionsActivated({ id: this.interviewID })
    },
    pickerValueFromAddress(address) {
      return {
        address: address,
        isValid: this.validatedAddress,
        text: address && `${address.line1}, ${address.city}, ${address.state} ${address.zip}`
      }
    },
    addressFromAnswers(answers) {
      const line1 = answers[QK.ADDRESS_LINE_1]
      const line2 = answers[QK.ADDRESS_LINE_2]
      const city = answers[QK.ADDRESS_CITY]
      const state = answers[QK.ADDRESS_STATE]
      const zip = answers[QK.ADDRESS_ZIP]
      if (line1 && city && state && zip) {
        const address = { line1, city, state, zip }
        if (line2) {
          address.line2 = line2
        }
        return address
      }
      return undefined
    },
    addressToAnswers(addressResult) {
      const answersMap = {}
      const address = addressResult.address
      answersMap[QK.ADDRESS_LINE_1] = address.line1
      answersMap[QK.ADDRESS_LINE_2] = address.line2
      answersMap[QK.ADDRESS_CITY] = address.city
      answersMap[QK.ADDRESS_STATE] = address.state
      answersMap[QK.ADDRESS_ZIP] = address.zip
      return answersMap
    },

    updateIdentityShown() {
      sessionStorage.setItem('identityConfirmationShown', 'postQuote')
    },

    inputId
  },
  async created() {
    this.values = this.answersForKeys(this.questionKeys) || {}
    this.updateIdentityShown()
    // prepare the interview data
    if (this.interview) {
      const address = this.addressFromAnswers(this.answers)
      this.selectedAddress = this.pickerValueFromAddress(address)
      this.acceptedNotices = this.parseBool(this.values[QK.ACCEPTED_NOTICES])
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/interview';
@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/transitions';

.df-content-header-subtitle {
  margin-top: 1rem;
}

.legal-container {
  padding: 25px 0;
}

.interview__terms {
  margin-top: -20px;
}

::v-deep .v-input__slot {
  align-items: flex-start;

  label {
    padding-top: 3px;
  }
}
</style>
