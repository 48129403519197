<template>
  <div class="policy-item mq-grid">
    <div class="cols-10">
      <div class="mq-grid policy-item__title">
        <div :class="value ? 'cols-5' : 'cols-10'">{{ item }}</div>
        <div v-if="value" class="policy-item__title--right cols-5">
          <span>{{ value }}</span>
        </div>
      </div>
    </div>
    <div v-if="Array.isArray(description)" class="policy-item__description cols-10">
      <div class="mq-grid" v-for="(d, i) in description" :key="i">
        <div :class="valueLine2 ? 'cols-6 cols-md-8' : 'cols-10'">
          <div>{{ d.line1 }}</div>
          <div v-if="d.line2">{{ d.line2 }}</div>
        </div>
        <div v-if="valueLine2" class="policy-item__title--right cols-4 cols-md-2">
          <span>{{ valueLine2 }}</span>
        </div>
      </div>
    </div>
    <div v-else class="policy-item__description cols-10">
      <div class="mq-grid">
        <div :class="valueLine2 ? 'cols-6 cols-md-8' : 'cols-10'">
          <div>{{ description }}</div>
          <div v-if="descriptionLine2">{{ descriptionLine2 }}</div>
        </div>
        <div v-if="valueLine2" class="policy-item__title--right cols-4 cols-md-2">
          <span :style="extraStyle">{{ valueLine2 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'

export default {
  name: 'PolicyItem',

  props: {
    item: {
      type: String,
      required: true
    },
    description: {
      type: [String, Array],
      required: false
    },
    value: {
      type: String,
      required: false
    },
    descriptionLine2: {
      type: String,
      required: false
    },
    valueLine2: {
      type: String,
      required: false
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    extraStyle () {
      if (this.hasError) {
        return { 'color': Colors.red }
      }
      return {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/colors";
@import "src/assets/styles/titles";
@import "src/assets/styles/media-queries";
@import "src/assets/styles/metrics";

.policy-item {
  margin-bottom: 2rem;
  &__title {
    line-height: 1rem;
    font-size: 1.1rem;
    font-weight: bold;
    @include md {
      font-size: 1.5rem;
    }

    &--right {
      text-align: right;
    }
  }
  &__list-item {
    margin-bottom: 1.2rem;
  }

  &__description {
    margin-top: -10px;
  }
}
</style>
