<template>
  <div class="video-text-two-column">
    <div class="text-column">
      <slot name="title"></slot>
      <slot name="text"></slot>
    </div>
    <div class="video-column">
      <VideoDisplayWrapper
        :videoId="videoId"
        :thubmail="true"
        :thumbnailDesktop="true"
        :modalDesktop="true"
      />
    </div>
  </div>
</template>

<script>
import VideoDisplayWrapper from '@/components/content/VideoDisplayWrapper.vue'
export default {
  name: 'video-text-two-column',
  components: { VideoDisplayWrapper },
  props: {
    videoId: undefined,
    showThumbnail: {
      type: Boolean,
      default: false
    },
    onThumbClicked: undefined
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/_media-queries.scss";
@import "src/assets/styles/global";

.video-text-two-column {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-row-gap: 30px;
  @include md() {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
  min-height: 300px;
}

.text-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

</style>
