import AppRoutes from './app.routes'
import AuthRoutes from './auth.routes'
import InterviewRoutes from './interview.routes'
import FinalizeRoutes from './finalize.routes'
import AdminRoutes from './admin.routes'

export default [
  ...AppRoutes,
  ...AuthRoutes,
  ...InterviewRoutes,
  ...FinalizeRoutes,
  ...AdminRoutes
]
