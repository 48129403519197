<template>
  <FlowWrapper class="sign-in-callback" hide-sign-in :show-buttons="false">
    <template v-slot:header-text>
      <template v-if="hasError">Something went wrong.</template>
      <template v-else-if="needsEmail">One quick thing.</template>
      <template v-else-if="needsVerification">Security Question.</template>
      <template v-else>Logging you in.</template>
    </template>
    <template v-slot:content>
      <template v-if="hasError">
        <template v-if="errorCode === 'auth/invalid-action-code'">
          <p>
            It looks like you're trying to sign in using an
            <b>expired or invalid email link</b>.
          </p>
          <p>
            The sign in emails only last for a short period of time so, if it has been a while since you requested it,
            you will need to
            <router-link to="/signin">sign in again</router-link>.
          </p>
        </template>
        <template v-else>
          <p>
            We're having trouble signing you in. We've logged the issue on our side and we will look into it. In the
            meantime, please try to
            <router-link to="/signin">sign in again</router-link>.
          </p>
        </template>
        <p>
          If you are still having issues, please
          <a :href="gotoRedirect('help')">contact us</a> and we will be glad to help.
        </p>
      </template>
      <template v-else-if="needsEmail">
        <p>
          For security reasons we need you to please confirm your email address. Please enter it below and then click
          the <b>Confirm</b> button.
        </p>
        <p>
          <LabeledHolder label="Email" class="sign-in-callback__email-entry" v-model="email">
            <VTextField
              v-model="confirmationEmail"
              placeholder="Email address"
              type="email"
              :error-messages="inlineErrors"
              validate-on-blur
              @input="validateEmailEntry"
              @blur="validateEmailEntry"
            />
          </LabeledHolder>
          <DFButton
            class="sign-in-callback__confirm-button"
            @click="onConfirmEmailClicked"
            :disabled="hasInlineErrors"
            :is-loading="isConfirming"
          >
            Confirm
          </DFButton>
        </p>
      </template>
      <template v-else-if="needsVerification">
        <template v-if="errorCode === 'auth/invalid-verification-answer'">
          <div class="sign-in-callback__errors">
            <div class="sign-in-callback__errors-icon">
              <i class="fa-light fa-circle-xmark"></i>
            </div>
            <div class="sign-in-callback__errors-content">Invalid answer, please try again.</div>
          </div>
        </template>
        <div class="account-verification-wrapper">
          <p class="sign-in-callback__verification">For your security, we need you to verify your identity.</p>
          <div>
            <LabeledHolder :label="LABELS[questionType]">
              <VTextField v-model="answer" class="initial-step__mobile-entry" />
            </LabeledHolder>
          </div>
          <DFButton class="sign-in-callback__confirm-button" @click="verifyAccount" :is-loading="isVerifying">
            Confirm
          </DFButton>
        </div>
      </template>
      <template v-else>
        <p><DotsSpinner /></p>
        <p>Please wait while we log you in to your account. This shouldn't take long.</p>
        <p>Having issues? Please <a :href="gotoRedirect('help')">contact us</a>.</p>
      </template>
    </template>
  </FlowWrapper>
</template>

<script>
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import { gotoRedirect } from '@/utils/router.js'
import { InterviewFlagCause, InterviewFlagType } from '@/api/Interview'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, signOut } from 'firebase/auth'
import { mapActions, mapGetters } from 'vuex'
import { RouteName } from '@/router/routes/constants.js'
import LabeledHolder from '@/components/inputs/LabeledHolder.vue'
import { PATTERN_EMAIL } from '@/rules'
import DFButton from '@/components/DFButton.vue'
import { apolloClient } from '@/config/apollo.config.js'
import { ssAuthTokenKey } from '@/config/app.config.js'
import DotsSpinner from '../../components/DotsSpinner'
import gql from 'graphql-tag'
import { datadogRum } from '@datadog/browser-rum'

const getAccountValidationType = gql`
  query getAccountValidationType($email: String!) {
    getAccountValidationType(email: $email) {
      type
      id
      needsVerification
    }
  }
`

const accountVerification = gql`
  mutation accountVerification($id: String!, $type: AccountValidationType!, $answer: String!) {
    accountVerification(id: $id, type: $type, answer: $answer) {
      verified
    }
  }
`
const LABELS = {
  PHONE: 'Please enter the last 4 digits of your phone number.',
  SSN: 'Please enter the last 4 digits of your social security number.',
  ZIP_CODE: 'Please enter your zip code.'
}

export default {
  name: 'signin-callback',
  components: { DotsSpinner, DFButton, LabeledHolder, FlowWrapper },
  data: function () {
    return {
      gotoRedirect,
      email: window.localStorage.getItem('auth.email'),
      confirmationEmail: undefined,
      inlineErrors: undefined,
      hasError: false,
      errorCode: 'general',
      needsEmail: false,
      account: undefined,
      interview: undefined,
      isConfirming: false,
      questionType: undefined,
      accountToVerify: undefined,
      needsVerification: undefined,
      LABELS,
      answer: undefined,
      verificationError: false,
      isVerifying: false
    }
  },
  computed: {
    ...mapGetters('auth', ['authReason']),
    hasInlineErrors() {
      return !this.confirmationEmail || !!this.inlineErrors
    }
  },
  methods: {
    ...mapActions('auth', ['setAccessToken']),
    ...mapActions('account', ['getAccount', 'getInterviews', 'onSignOut']),
    ...mapActions('interview', ['bindInterview', 'fetchInterviewQuote', 'recordInteraction']),

    async handleRedirect(to) {
      window.localStorage.removeItem('auth.redirectTo')
      this.resetAuthState()
      await this.$router.replace(to)
    },

    validateEmailEntry() {
      this.inlineErrors = undefined
      if (!this.confirmationEmail || this.confirmationEmail?.trim().length === 0) {
        this.inlineErrors = ['Please enter your email']
      } else if (!PATTERN_EMAIL.test(this.confirmationEmail)) {
        this.inlineErrors = ['Please enter a valid email']
      }
    },

    isInterviewFrozen(interview) {
      if (interview && interview.flags && interview.flags.length > 0) {
        let flags = interview.flags
        let isFrozen = flags.find((o) => o.type === InterviewFlagType.FROZEN)
        return (
          isFrozen !== undefined &&
          'type' in isFrozen &&
          isFrozen.type === InterviewFlagType.FROZEN &&
          'cause' in isFrozen &&
          isFrozen.cause !== InterviewFlagCause.FROZEN_NEEDS_REVIEW
        )
      }
      return false
    },

    async completeSignIn(auth, email) {
      // sign the user out
      await signOut(auth)
      // reset the global cache / state
      window.sessionStorage.removeItem(ssAuthTokenKey)
      this.onSignOut() // clear the account module
      await apolloClient.resetStore()

      try {
        const signInResult = await signInWithEmailLink(auth, email, window.location.href)
        // get the user
        const user = signInResult.user
        // store the access token
        const accessToken = user.accessToken
        this.setAccessToken(accessToken)
        // load account data
        this.account = await this.getAccount()
        // clear authentication state
        this.resetAuthState()
        // redirect
        sessionStorage.removeItem('auth.redirect')
        await this.handleRedirect({ name: RouteName.ADMIN_DASHBOARD })
      } catch (e) {
        this.hasError = true
        this.errorCode = e?.code ?? 'general'
        // eslint-disable-next-line no-console
        console.error('sign in failed!')
        // eslint-disable-next-line no-console
        console.error(e?.code, e)
      }
    },

    resetAuthState() {
      // check that this is reseting auth local storage
      window.localStorage.removeItem('auth.action')
      window.localStorage.removeItem('auth.email')
    },

    async onConfirmEmailClicked() {
      const auth = getAuth()
      this.isConfirming = true
      await this.completeSignIn(auth, this.confirmationEmail)
      this.isConfirming = false
    },
    async accountVerification() {
      // Check if user needs to verify account
      try {
        const getAccountValidation = await apolloClient.query({
          query: getAccountValidationType,
          variables: {
            email: this.email
          }
        })
        const { type, id, needsVerification } = getAccountValidation.data.getAccountValidationType
        this.questionType = type
        this.accountToVerify = id
        this.needsVerification = needsVerification
      } catch (e) {
        this.hasError = true
        datadogRum.addError(e)
      }
    },
    async verifyAccount() {
      // Verify security question answer
      this.isVerifying = true
      try {
        const verify = await apolloClient.mutate({
          mutation: accountVerification,
          variables: {
            id: this.accountToVerify,
            type: this.questionType,
            answer: this.answer
          }
        })
        this.verificationError = !verify.data.accountVerification.verified
        if (this.verificationError) {
          this.errorCode = 'auth/invalid-verification-answer'
        } else {
          this.errorCode = undefined
          await this.signInUser()
        }
      } catch (e) {
        this.hasError = true
        datadogRum.addError(e)
      }
      this.isVerifying = false
    },
    async signInUser() {
      const authAction = window.localStorage.getItem('auth.action')
      const auth = getAuth()
      if (!authAction && auth.currentUser) {
        await this.$router.push({ name: RouteName.ADMIN_DASHBOARD })
        return
      }

      const isFromValidMagicLink = isSignInWithEmailLink(auth, window.location.href)
      if (isFromValidMagicLink) {
        if (!this.email) {
          // if you're opening the link on a different device or in a browser where you didn't initate the
          // sign in flow, we need to ask for your email address again
          this.needsEmail = true
          return
        }
        // complete the sign in flow
        await this.completeSignIn(auth, this.email)
        return
      }
      // error state
      this.hasError = true
      datadogRum.addError(new Error('User reached sign in callback without a valid link'))
    }
  },
  async created() {
    await this.accountVerification()
    if (this.needsVerification !== undefined && this.needsVerification === false) {
      await this.signInUser()
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';

.sign-in-callback {
  p:not(:first-of-type) {
    margin-top: 1rem;
  }
  &__email-entry {
    margin-top: 2rem;
    width: 100%;
    @include md {
      margin-top: 3rem;
      width: 360px;
    }
  }
  &__confirm-button {
    margin-top: 1.5rem;
  }
  &__verification {
    margin: 0 0 2rem;
  }

  &__input-container {
    max-width: 200px;
  }
  &__errors {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    column-gap: 20px;
    margin: 2rem 0;
    padding: 1rem;
    background: rgba($c-red-rgb, 0.05);
    border: 1px solid rgba($c-red-rgb, 0.25);
    border-radius: 6px;
    color: $c-red-rgb;
    &-icon {
      align-self: start;
      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
.account-verification-wrapper {
  ::v-deep {
    .labeled-holder__child {
      max-width: 200px !important;
    }
  }
}
</style>
