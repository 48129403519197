<template>
  <div class="timeline-container">
    <div class="timeline">
      <div class="timeline-title">{{ title }}</div>
      <div class="timeline-circle timeline-circle__left" :style="circleStyle">{{ start }} <template v-if="showAge">{{ ageText[0] }}</template></div>
      <div class="timeline-progress" :style="timelineStyle">
        <div :style="lineStyle"></div>
        <div :style="textStyle">If you die: <template v-if="this.$gtSm && !this.isMax"><br /></template> {{ subtitle }}</div>
        <div :style="trackStyle">
          <div class="timeline-progress__value" :style="valueStyle"></div>
        </div>
      </div>
      <div class="timeline-circle timeline-circle__right" :style="circleStyle">{{ end }} <template v-if="showAge">{{ ageText[1] }}</template></div>
    </div>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'

export default {
  name: 'Timeline',

  props: {
    value: {
      type: Number,
      required: false,
      default: 0.4
    },
    start: {
      type: [Number, String],
      required: false,
      default: 'Now'
    },
    end: {
      type: Number,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: '10px'
    },
    trackColor: {
      type: String,
      required: false,
      default: '#fff'
    },
    lineColor: {
      type: String,
      required: false,
      default: Colors.red
    },
    valueColor: {
      type: String,
      required: false,
      default: Colors.purple
    },
    rounded: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: '',
      required: false
    },
    isMin: {
      type: Boolean,
      default: false
    },
    isMax: {
      type: Boolean,
      default: false
    },
    isMid: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      required: false
    },
    showAge: {
      type: Boolean,
      default: false
    },
    ageText: {
      type: Array,
      default: () => ['', '']
    }
  },
  computed: {
    minValue () {
      return this.$ltMd ? 0.2 : 0.15
    },

    maxValue () {
      return this.$ltMd ? 0.8 : 0.8
    },

    getValue () {
      return this.isMin
        ? this.value > this.minValue ? this.value : this.minValue
        : this.value < this.maxValue ? this.value : this.maxValue
    },

    circleStyle () {
      const styles = {}
      if (this.showAge === true) {
        styles['width'] = this.$ltMd ? '50px' : '60px'
        styles['height'] = this.$ltMd ? '50px' : '60px'
      } else {
        styles['width'] = this.$ltMd ? '35px' : '50px'
        styles['height'] = this.$ltMd ? '35px' : '50px'
      }
      return styles
    },

    timelineStyle () {
      const styles = {}
      if (this.showAge === true) {
        styles['top'] = this.$ltMd ? '50px' : '70px'
      } else {
        styles['top'] = this.$ltMd ? '45px' : '65px'
      }
      return styles
    },

    textStyle () {
      return `font-size: 0.7rem; line-height: 0.7rem; position: absolute; top: ${this.isMax && this.$ltMd ? '-40px' : '-25px'}; margin-left: ${this.showAge && this.$gtSm && this.isMin ? this.getValue * 110 : this.getValue * 100}%`
    },

    lineStyle () {
      return `border-right: 2px solid ${this.lineColor}; height: ${this.$ltMd ? '25px' : '30px'}; width: ${this.showAge && this.$gtSm && this.isMin ? this.getValue * 110 : this.getValue * 100}%;`
    },

    trackStyle () {
      return `height: ${this.size}; background-color: ${this.trackColor}; border-radius: ${this.rounded ? '9999px' : '0px'}`
    },
    valueStyle () {
      return `height: ${this.size}; background-color: ${this.valueColor}; width: ${this.showAge && this.$gtSm && this.isMin ? this.getValue * 110 : this.getValue * 100}%; border-radius: ${this.rounded ? '9999px' : '0px'}`
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/media-queries";

.timeline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto 0;
  position: relative;
  @include md() {
    width: 400px;
  }
}

.timeline {
  width: 100%;
  height: 125px;
  @include md() {
    width: 400px;
    height: 140px;
    margin-top: 1rem;
  }
}

.timeline-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  @include md () {
    font-size: 0.8rem;
  }
}

.timeline-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 0.6rem;
  color: $c-white;
  text-align: center;
  background: $c-primary;
  border: 1px solid $c-primary;
  line-height: 0.8rem;
  @include md () {
    width: 50px;
    height: 50px;
    font-size: 0.8rem;
    line-height: 0.9rem;
  }

  &__left {
    position: absolute;
    z-index: 99;
    top: 56px;
    left: -1px;
    @include md() {
      top: 75px;
    }
  }

  &__right {
    position: absolute;
    top: 56px;
    right: 0;
    z-index: 99;
    @include md() {
      top: 75px;
    }
  }
}

.timeline-progress {
  width: 100%;
  position: absolute;
  top: 55px;
  @include md() {
    top: 80px;
  }
}

.timeline-progress__value {
  top: 0;
  left: 0;
}
</style>
