<template>
  <div>
    <p>
      This disclosure is to inform you of your rights and options when purchasing life
      insurance, and to ensure that the solicitation has been conducted appropriately
      and in compliance with the state and federal laws concerning sales to military service members.
    </p>
    <p>
      The Federal Government offers members of the Armed Forces subsidized group life
      insurance under the Servicemembers’ Group Life Insurance program (also referred
      to as "SGLI"), under United States Code subchapter III of chapter 19 of title 38. Visit
      <a rel="noopener" target="_" href="https://www.va.gov/life-insurance/">www.va.gov/life-insurance/</a>
      for additional information on SGLI, including coverage amounts and costs.
    </p>
    <p>
      The Dayforward life insurance product being offered/sold to you as part of this disclosure
      is not offered or provided by the Federal Government, and the Federal Government has not
      sanctioned, recommended, or encouraged the sale of this product.
    </p>
    <p>
      No person has received any referral fee or incentive compensation in connection with the sale
      or offer of this life insurance product, unless they are a licensed agent.
    </p>
    <p>
      Additional information that may be helpful to you can be located on the National Association
      of Insurance Commissioners (NAIC) web site
      <a rel="nooperner" target="_" href="https://content.naic.org/consumer/military.htm">content.naic.org/consumer/military.htm</a>.
    </p>
  </div>
</template>

<script>
export default {
  name: 'military-disclosure-modal'
}
</script>
