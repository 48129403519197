import AllRoutes from './routes'
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { InterviewFlagType } from '@/api/Interview'
import {
  InterviewRouteNames,
  RedirectRouteNames,
  RouteAuthReasons,
  RouteCategory,
  RouteName,
  AdminPathPrefix,
  ssKeyLastCategory,
  IMOPathPrefix
} from '@/router/routes/constants.js'
import analyticsApi from '@/api/Analytics'
import { gotoRedirect } from '@/utils/router'

Vue.use(VueRouter)

function scrollToAnchor(anchor, attempts = 0) {
  if (attempts > 10) {
    return
  }

  const elmnt = document.getElementById(`${window.location.hash.substring(1)}`)

  if (!elmnt) {
    setTimeout(() => {
      scrollToAnchor(anchor, attempts + 1)
    }, 200)
    return
  }

  elmnt.scrollIntoView()
}

const router = new VueRouter({
  scrollBehavior() {
    const anchor = window.location.hash.substring(1)
    if (anchor) {
      scrollToAnchor(anchor)
    } else {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0
    }
  },
  mode: 'history',
  routes: AllRoutes
})

// if to.name is in the whitelisted list, we allow user to proceed
const whitelisted = [
  RouteName.ERROR,
  RouteName.HOME,
  RouteName.HELP,
  RouteName.WHY_DAYFORWARD,
  RouteName.HOW_IT_WORKS,
  RouteName.INCOMING,
  RouteName.PRICING,
  RouteName.ABOUT,
  RouteName.CAREERS,
  RouteName.FAQ
]

const needsSnapshotCheck = [
  RouteName.INTERVIEW_FINAL_QUOTE,
  RouteName.INTERVIEW_CONFIRM_IDENTITY,
  RouteName.INTERVIEW_PAYMENT,
  RouteName.INTERVIEW_UPDATE_PAYMENT,
  RouteName.INTERVIEW_SIGN,
  RouteName.INTERVIEW_SUBMISSION,
  RouteName.INTERVIEW_NEEDS_REVIEW
]

let prevRoute = null

// add a navigation guard so we can check for authentication. by default the guard will fallback to
// requiring authentication if you have not exclusively whitelisted the route.
router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith(AdminPathPrefix) || to.path.startsWith(IMOPathPrefix)) {
    analyticsApi.disableFullstory()
  }

  let paths = JSON.parse(sessionStorage.getItem('pathHistory')) || []
  paths.unshift(to.path)
  paths = paths.slice(0, 50)
  sessionStorage.setItem('pathHistory', JSON.stringify(paths))

  if (Object.values(InterviewRouteNames).indexOf(to.name) > -1) {
    window.location = gotoRedirect('signin')
  }

  if (whitelisted.indexOf(to.name) > -1) {
    next()
    return
  }

  // disables the browser back button
  // if (from.meta.preventNativeRouting && isNativeBrowserButtons) {
  //   next(false)
  //   isNativeBrowserButtons = false
  //   return
  // }
  // Other pages allow back buttons and will set this value to true.
  // We want to clear it between navigations.
  // isNativeBrowserButtons = false

  // check the auth guards
  // the conditions for guarding the route are as follows:
  // 1. the page is publicly accessible
  // 2. the page requires auth and the user IS NOT logged in
  // 3. the page requires auth and the user IS logged in
  // 4. the page requires admin permissions and the user IS NOT logged in
  // 5. the page requires admin permissions and the user IS logged in but IS NOT an admin
  // 6. the page requires admin permissions and the user IS logged in and IS an admin
  // by default if no meta is provided we consider the page to be restricted

  const isAgentRestricted = to.meta.requiresAgent === true
  const isRestricted = to.meta.requiresAuth === undefined || to.meta.requiresAuth === true
  const isAdminRestricted = to.meta.requiresAdmin === true
  if (isAgentRestricted) {
    const acct = await store.dispatch('account/getAccount')
    if (acct) {
      // update the cached account information
      // await store.dispatch('account/updateAccount', acct)
      if (acct) {
        // update the cached account information
        await store.dispatch('account/updateAccount', acct)
      }
    }
    const isAgent = !!(acct?.userRole === 'AGENT' || acct?.userRole === 'OWNER')
    if (!acct || !isAgent) {
      next({ name: RouteName.IMO_SIGNIN, query: { redirect: to.fullPath } })
      return
    }
  }

  if (isRestricted || isAdminRestricted) {
    // if is restricted, check for follow up conditions
    // note: getAccount will return the cached account if there is one
    const acct = await store.dispatch('account/getAccount')
    if (acct) {
      // update the cached account information
      await store.dispatch('account/updateAccount', acct)
    }
    // if you're not logged in, push you to the relevant sign in page
    if (!acct) {
      if (to.meta.requiresAdmin) {
        next({ name: RouteName.ADMIN_SIGNIN, query: { redirect: to.fullPath } })
        return
      } else {
        const interview = store.state.interview.interview
        if (interview) {
          next({ name: RouteName.SIGN_IN, query: { redirect: to.fullPath } })
          return
        } else {
          // in case of direct navigation to /me but there is no
          // interview in state, redirect to signin
          next({ name: RouteName.SIGN_IN })
        }
      }
    }
    // if you are logged in, check you can access admin page if the flag is set
    if (isAdminRestricted && !(acct?.isUnderwriter || acct?.isCustomerService)) {
      next({ name: RouteName.ADMIN_SIGNIN, query: { redirect: to.fullPath } })
      return
    }
  }

  let authReason
  let lastCategory = window.sessionStorage.getItem(ssKeyLastCategory)
  prevRoute = from

  if (to.meta.category && to.name !== RouteName.SIGN_IN_CALLBACK) {
    window.sessionStorage.setItem(ssKeyLastCategory, to.meta.category)
  }

  if (lastCategory === RouteCategory.INITIAL) {
    authReason = RouteAuthReasons.SAVE_PROGRESS
  } else if (lastCategory === RouteCategory.HOME || lastCategory === RouteCategory.AUTH) {
    authReason = RouteAuthReasons.SIGN_IN
  } else if (lastCategory === InterviewFlagType.SIGN_IN_REQUIRED || lastCategory === RouteCategory.CONFIRM_IDENTITY) {
    authReason = undefined
  } else if (lastCategory === RouteCategory.STEP_TWO_AUTH) {
    authReason = RouteAuthReasons.STEP_TWO_AUTH
  } else {
    authReason = lastCategory
  }
  await store.dispatch('auth/setReason', { authReason: authReason })
  if (!store.state?.interview?.interview) {
    next()
    return
  }
  const interviewID = await store.state.interview.interview.id
  if (to.name === RouteName.INTERVIEW_FINAL_QUOTE || to.name === RouteName.INTERVIEW_SIGN) {
    await store.dispatch('interview/requireFinalQuote', {
      id: interviewID,
      requireFinalQuote: true
    })
  }
  if (!needsSnapshotCheck.includes(to.name)) {
    next()
    return
  }

  const snapshot = await store.dispatch('interview/getSnapshot', {
    id: interviewID
  })
  // if the interview has been submitted, fall through and don't check the offramps because we will
  // pushing you somewhere else if you're in the interview flow
  if (snapshot.submissionProcessing()) {
    next()
    return
  }

  if (snapshot.needsReviewOrFrozen()) {
    // IN-3852 if the quote is locked, needs review activates only after signing
    // and needs to show final review pending page ('/thanks')
    if (snapshot.quoteLocked && !snapshot.hasFrozenTypeFlagsNotCausedByNeedsReview()) {
      next({ name: RouteName.INTERVIEW_REVIEW_PENDING })
    } else {
      window.location = gotoRedirect(RedirectRouteNames.MORE_INFO_NEEDED)
    }
    return
  }

  if (snapshot.ineligible()) {
    next({ name: RouteName.OR_INELIGIBLE })
    return
  }
  next()
})

router.afterEach((to, from) => {
  analyticsApi.triggerOptimizeEvent('df.optimize.navigate')

  if (!to.path.startsWith(AdminPathPrefix) && !to.meta?.doNotTrack) {
    analyticsApi.page({
      path: to.path,
      fullPath: to.fullPath
    })
  }
})

export function previousRoute() {
  return prevRoute
}

export default router
