<template>
  <div class="interview-smoking">
    <FlowWrapper
      :progress="5/8"
      :can-move-to-next="hasValidFormData"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      :on-next="onNext"
    >
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          Tell us about your smoking and drinking.
        </div>
      </template>
      <template v-slot:content>
      <v-form>
        <div class="mq-grid">
          <Question
            class="cols-10"
            v-model="values[QK.NICOTINE_FREQUENCY]"
            :question="questionsMap[QK.NICOTINE_FREQUENCY]"
            :error="errors[QK.NICOTINE_FREQUENCY]"
            type="select"
            placeholder="Please select a value"
            :input-width="smElse('100%', '350px')"
          />
          <Question
            v-model="values[QK.MARIJUANA_FREQUENCY]"
            :question="questionsMap[QK.MARIJUANA_FREQUENCY]"
            :error="errors[QK.MARIJUANA_FREQUENCY]"
            type="select"
            class="cols-10"
            placeholder="Please select a value"
            :input-width="smElse('100%', '350px')"
          />
          <Question
            v-model="values[QK.ALCOHOL_FREQUENCY]"
            :question="questionsMap[QK.ALCOHOL_FREQUENCY]"
            :error="errors[QK.ALCOHOL_FREQUENCY]"
            type="select"
            class="cols-10"
            placeholder="Please select a value"
            :input-width="smElse('100%', '350px')"
          />
        </div>
      </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { QuestionKey as QK } from '@/api/Interview'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'

export default {
  name: 'InterviewSmoking',
  components: { FlowWrapper, Question },

  data () {
    return {
      STEP_NAME: 'smoking',
      QK: QK,
      values: {},
      errors: {},
      title1: 'Tell us about your smoking and drinking.'
    }
  },

  computed: {
    ...mapGetters('interview', ['answers', 'questionsMap', 'interviewID', 'interview', 'mapToAnswers']),

    hasValidFormData () {
      return !!this.values[QK.NICOTINE_FREQUENCY] && !!this.values[QK.MARIJUANA_FREQUENCY] && !!this.values[QK.ALCOHOL_FREQUENCY]
    },
    questionKeys () {
      return [
        QK.NICOTINE_FREQUENCY,
        QK.MARIJUANA_FREQUENCY,
        QK.ALCOHOL_FREQUENCY
      ]
    },

    answerValues () {
      return this.values
    }
  },

  methods: {
    ...mapActions('interview', ['checkFollowupSectionsActivated']),

    onNext () {
      this.checkFollowupSectionsActivated({ id: this.interviewID })
    }
  },

  created () {
    this.values = this.answers || {}
  }
}
</script>
