<template>
  <div class="customer-detail__quote-detail" v-if="quote">
    <div class="admin-container">
      <h3>Quote Controls</h3>
      <div class="customer-detail__quote-container">
        <div class="customer-detail__item">
          <p>
            <strong>Show/Hide Quote:</strong>
            <v-checkbox
              class="checkbox"
              :label="`Show quote`"
              v-model="quoteShown"
              @change="onShowQuoteToggle"
            />
          </p>
        </div>
        <div class="customer-detail__item">
          <p>
            <strong>Quote Display Mode:</strong>
            <v-select
              item-value="value"
              item-text="label"
              v-model="selectedQuoteDisplayMode"
              :items="quoteDisplayOptions"
              @change="changeQuoteDisplay($event)"
            ></v-select>
          </p>
        </div>
      </div>
    </div>
    <div class="admin-container">
      <h3>Quote Detail</h3>
      <div class="customer-detail__quote-container">
      <div class="customer-detail__item">
        <p v-if="quote.isEstimate">
          <strong>Quote is estimate:</strong>
          {{ quote.isEstimate }}
        </p>
        <p v-if="quote.annualIncome">
          <strong>Quote annual income:</strong>
          {{ formatCurrency(quote.annualIncome) }}
        </p>
        <p v-if="quote.termReason">
          <strong>Quote term reason:</strong>
          {{ quote.termReason.description }}
        </p>
        <p>
          <strong>Quote term:</strong>
          {{ coveragePeriod }}
        </p>
      </div>
      <div class="customer-detail__item">
        <p>
          <strong>Quote term end date</strong>
          {{ quote.termEndDate }}
        </p>
        <p v-if="quote.benefitAmount">
          <strong>Semi-monthly benefit amount</strong>
          {{ formatCurrency(quote.benefitAmount) }}
        </p>
        <p v-if="quote.premium">
          <strong>Price</strong>
          {{ formatCurrency(quote.premium) }}
        </p>
        <p>
          <strong>NAAR Value:</strong>
          {{ formatCurrency(quote.naarValue) }}
        </p>
        <p>
          <strong>Selected NAAR:</strong>
          {{ formatCurrency(quote.selectedNaarValue) }}
        </p>
        <p>
          <strong>NAAR Fluidless Threshold:</strong>
          {{ formatCurrency(quote.naarThreshold) }}
        </p>
        <p>
          <strong>NAAR EHR Threshold:</strong>
          {{ formatCurrency(quote.naarEHRThreshold) }}
        </p>
        <p>
          <strong>Total benefits if you die right away:</strong>
          {{ quote.dayOneCoverage ? formatCurrency(quote.dayOneCoverage) : "--" }}
        </p>
      </div>
    </div>
    </div>
    <div class="customer-detail__quote-calculator admin-container">
      <h3>Quote calculator</h3>
      <div class="customer-detail__quote-calculator-values">
        <div class="customer-detail__quote-container">
          <div class="customer-detail__item">
            <p v-if="newQuote.isEstimate">
              <strong>Quote is estimate:</strong>
              {{ newQuote.isEstimate }}
            </p>
            <p v-if="newQuote.termReason">
              <strong>Quote term reason:</strong>
              {{ newQuote.termReason.description }}
            </p>
            <p>
              <strong>Quote term:</strong>
              {{ newCoveragePeriod }}
            </p>
            <p v-if="newQuote.childExtensionMaxAge">
              <strong>Child extension max age</strong>
              {{ newQuote.childExtensionMaxAge }}
            </p>
          </div>
          <div class="customer-detail__item">
            <p>
              <strong>Quote term end date</strong>
              {{ newQuote.termEndDate }}
            </p>
            <p v-if="newQuote.benefitAmount">
              <strong>Semi-monthly benefit amount</strong>
              {{ formatCurrency(newQuote.benefitAmount) }}
            </p>
            <p v-if="newQuote.premium">
              <strong>Price</strong>
              {{ formatCurrency(newQuote.premium) }}
            </p>
            <p v-if="newQuote.annualBenefit">
              <strong>Annual Benefit Amount</strong>
              {{ formatCurrency(newQuote.annualBenefit) }}
            </p>
            <p v-if="newQuote.selectedNaarValue">
              <strong>Selected NAAR</strong>
              {{ formatCurrency(newQuote.selectedNaarValue) }}
            </p>
            <p>
              <strong>Total benefits if you die right away</strong>
              {{ newQuote.dayOneCoverage ? formatCurrency(newQuote.dayOneCoverage) : "--" }}
            </p>
          </div>
        </div>
      </div>
      <div class="quote-calculator__container">
        <div class="quote-calculator__form">
          <QuoteTermSelect
            v-if="benefitRange && overrideTermYears === undefined"
            :termReason="termReason"
            :text="termEndReason"
            :editEnabled="true"
            :hasChild="newQuote.hasChild"
            :minMax="benefitRange"
            v-model="editTermLength"
            @change="onQuoteSelectChange"
            ref="ageSelect"
          />
          <br>
          <span class="quote-calculator__amount">Semi-monthly benefit amount </span>
          <BenefitAmountSelect
            v-if="defaultBenefitAmount"
            :benefit-range="[newQuote.minBenefit, newQuote.maxBenefit]"
            v-model="editBenefitAmount"
            @change="onInputBenefit"
            :default-value="defaultBenefitAmount"
            :benefit-amount-options="newQuote.benefitAmountOptions"
          />
          <p class="quote-calculator__price">Price: <span>${{ getPremium }}</span></p>
        </div>
        <div class="quote-calculator__overrides">
          <h3>Overrides</h3>
          <label>Premium</label>
          <v-text-field
            type="number"
            v-model="overridePremium"
            @input="quoteOverrideChange($event, 'premium', 'overridePremium')"
          />
          <label>Semi-monthly Benefit Amount</label>
          <v-text-field
            type="number"
            v-model="overrideBenefitAmount"
            @input="quoteOverrideChange($event, 'benefitAmount', 'overrideBenefitAmount')"
          />
          <label>Annual Benefit</label>
          <v-text-field
            type="number"
            v-model="overrideAnnualBenefit"
            @input="quoteOverrideChange($event, 'annualBenefit', 'overrideAnnualBenefit')"
          />
          <label>Term Years</label>
          <v-text-field
            type="number"
            v-model="overrideTermYears"
            @input="quoteOverrideChange($event, 'termYears', 'overrideTermYears')"
          />
          <span class="quote-calculator__legal">Term is the only input that can be active at the same time as other overrides</span>
        </div>
      </div>
      <div class="quote-calculator__error" v-if="inlineError">
        {{inlineError}}
      </div>
      <DFButton
        elevation="0"
        @click="saveNewQuote"
        class="quote-calculator__save-button"
        :is-loading="isFetchingQuote"
      >
        Save new quote
      </DFButton>
      <v-checkbox
        :label="`Lock quote`"
        v-model="quoteLocked"
        @change="onQuoteLockedToggle"
        v-if="quoteLocked"
      />
    </div>
    <div class="customer-detail__quote-history admin-container" v-if="quoteHistory !== undefined && quoteHistory !== null">
      <h3>Quote History</h3>
      <div v-for="(item, index) in quoteHistory" class="customer-detail__quote-history-container" :key="item.time">
        <a href="#" @click.prevent="toggleDetail(index)" class="customer-detail__quote-history-link">Quote Issued -- {{ moment(item.time ).format("L LTS -Z")}}</a>
<!--        TODO: maybe turn this into a loop when you have more time-->
        <div :class="[{ 'opened': isOpen.includes(index) }, 'customer-detail__quote-history-history']" v-if="item.quote !== null && item.quote !== undefined">
          <div class="customer-detail__quote-history-item">
            <p><strong>Benefit amount:</strong> ${{ item.quote.benefitAmount }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Annual benefit amount:</strong> ${{ item.quote.annualBenefit }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Rate:</strong> {{ item.quote.rate }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Subscription fee:</strong> {{ item.quote.subscriptionFee }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Benefit limit:</strong>
              <span>Min:</span> {{ item.quote.benefitLimit.minimum }}
              <span>Max:</span> {{ item.quote.benefitLimit.maximum }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Has premium:</strong> {{ item.quote.hasPremium }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Is estimate:</strong> {{ item.quote.isEstimate }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Has override:</strong> {{ item.quote.hasOverride }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Annual income:</strong> ${{ item.quote.annualIncome }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Employment:</strong> {{ item.quote.employment.description }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Child extension available:</strong> {{ item.quote.childExtensionAvailable }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Benefit type:</strong> {{ item.quote.benefitType }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Insured age:</strong> {{ item.quote.insuredAge }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Has child:</strong> {{ item.quote.hasChild }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Youngest child age:</strong> {{ item.quote.youngestChildAge }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Insured age limit:</strong>
              <span>Min:</span> {{ item.quote.insuredAgeLimit.minimum }}
              <span>Max:</span> {{ item.quote.insuredAgeLimit.maximum }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Term recommended:</strong> {{ item.quote.termRecommended }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Term limit:</strong>
              <span>Min:</span> {{ item.quote.termLimit.minimum }}
              <span>Max:</span> {{ item.quote.termLimit.maximum }}</p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Term reason:</strong> {{ item.quote.termReason.description }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Term end date:</strong> {{ item.quote.termEndDate }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Premium:</strong> {{ item.quote.premium }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Premium per 1k income:</strong> {{ item.quote.premiumPer1kIncome }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Child extension max age:</strong> {{ item.quote.childExtensionMaxAge }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Income rider max total benefit:</strong> ${{ item.quote.incomeRiderMaxTotalBenefit }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Income rider max annual income:</strong> ${{ item.quote.incomeRiderMaxAnnualIncome }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>NAAR Value:</strong> ${{ item.quote.naarValue }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Selected NAAR:</strong> ${{ item.quote.selectedNaarValue }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>NAAR Fluidless Threshold:</strong> ${{ item.quote.naarThreshold }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>NAAR EHR Threshold:</strong> ${{ item.quote.naarEHRThreshold }} </p>
          </div>
          <div class="customer-detail__quote-history-item">
            <p><strong>Locked:</strong> {{ item.quote.locked }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="no-data">
    <h1>No quote issued</h1>
  </div>
</template>

<script>
// components
import QuoteTermSelect from '@/components/interview/QuoteTermSelect'
import BenefitAmountSelect from '@/components/interview/BenefitAmountSelect'
import DFButton from '@/components/DFButton'

// utils
import { formatCurrency } from '@/utils/number-formatter.js'
import { BenefitTermCode } from '@/api/Interview'
import { mapActions } from 'vuex'

// libs
import _debounce from 'lodash/debounce'
import moment from 'moment'

export default {
  name: 'admin-quote',
  components: {
    QuoteTermSelect,
    BenefitAmountSelect,
    DFButton
  },
  props: {
    id: {
      type: String,
      default: undefined
    },
    interview: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data () {
    return {
      quoteShown: false,
      quoteDisplayOptions: [
        { label: 'Single quote', value: 'SINGLE_VIEW' },
        { label: 'Multiple quotes', value: 'MULTI_VIEW' },
      ],
      selectedQuoteDisplayMode: undefined,
      quoteLocked: false,
      benefitRange: undefined,
      defaultBenefitAmount: undefined,
      editBenefitAmount: undefined,
      editTermLength: undefined,
      inlineError: undefined,
      isOpen: [],
      newQuote: undefined,
      overrideAnnualBenefit: undefined,
      overrideBenefitAmount: undefined,
      overridePremium: undefined,
      overrideTermYears: undefined,
      untilChildAge: null,
      untilInsuredAge: null,
      isFetchingQuote: false
    }
  },
  computed: {
    riskScoreOrigin () {
      return this.$store.state.admin.currentInterview.interview.riskScore.origin
    },
    quote () {
      return this.$store.state.admin.currentInterview.interview.quote || undefined
    },
    quoteHistory () {
      return this.$store.state.admin.currentInterview.interview.quoteHistory || undefined
    },
    coveragePeriod () {
      let term = ''
      if (this.quote) {
        const months = parseInt(this.quote.termRecommended)
        if (this.quote.termRecommended % 12 !== 0) {
          term = `${Math.floor(months / 12)} years ${months % 12} month(s)`
        } else {
          term = `${Math.floor(months / 12)} years`
        }
      }
      return term
    },
    newCoveragePeriod () {
      let term = ''
      if (this.newQuote) {
        const months = parseInt(this.newQuote.termRecommended)
        if (this.newQuote.termRecommended % 12 !== 0) {
          term = `${Math.floor(months / 12)} years ${months % 12} month(s)`
        } else {
          term = `${Math.floor(months / 12)} years`
        }
      }
      return term
    },
    termEndReason () {
      if (!this.newQuote) { return '' }
      const desc = this.newQuote.termReason.description
      return `${desc.charAt(0).toLowerCase()}${desc.slice(1)}`
    },
    termReason () {
      if (this.quote && this.newQuote.termReason) {
        return this.newQuote.termReason.code
      }
      return null
    },
    termReasonInsuredAge () {
      if (this.termReason !== null) {
        return this.termReason === BenefitTermCode.INSURED_AGE
      }
      return false
    },
    termReasonChildAge () {
      if (this.newQuote?.termRecommended || this.quote.termRecommended !== null) {
        return this.termReason === BenefitTermCode.CHILD_AGE
      }
      return false
    },
    getPremium () {
      if (this.newQuote && 'premium' in this.newQuote) {
        return this.newQuote.premium
      }
      return ''
    }
  },
  methods: {
    ...mapActions('interview', ['getQuote', 'updateQuote', 'setQuoteLocked']),
    ...mapActions('admin', ['quoteOverride', 'setShowQuote', 'setQuoteDisplayMode']),
    moment,
    formatCurrency,
    async onShowQuoteToggle (val) {
      await this.setShowQuote({ id: this.id, showQuote: val })
      this.$emit('loading')
    },
    async changeQuoteDisplay (e) {
      await this.setQuoteDisplayMode({ id: this.id, quoteDisplayMode: e })
      this.$emit('loading')
    },
    async onQuoteLockedToggle (val) {
      await this.setQuoteLocked({ interviewID: this.id, locked: val })
      this.$emit('loading')
    },
    quoteOverrideChange: _debounce(function (val, type, override) {
      if (val === '') {
        this[override] = undefined
      }
      // if (isNaN(val) || val === '') return
      this.updateOverrideInputs(type)
      this.resetEmptyValues()
      this.fetchOverride(type, val)
    }, 1000),
    resetEmptyValues () {
      if (this.overrideTermYears === '') {
        this.overrideTermYears = undefined
      } else if (this.overrideAnnualBenefit === '') {
        this.overrideAnnualBenefit = undefined
      } else if (this.overridePremium === '') {
        this.overridePremium = undefined
      } else if (this.overrideAnnualBenefit === '') {
        this.overrideAnnualBenefit = undefined
      }
    },
    updateOverrideInputs (type) {
      if (type === 'premium') {
        this.overrideBenefitAmount = undefined
        this.overrideAnnualBenefit = undefined
      } else if (type === 'annualBenefit') {
        this.overridePremium = undefined
        this.overrideBenefitAmount = undefined
      } else if (type === 'benefitAmount') {
        this.overridePremium = undefined
        this.overrideAnnualBenefit = undefined
      }
    },
    toggleDetail (index) {
      if (this.isOpen.includes(index)) {
        this.isOpen = this.isOpen.filter(i => i !== index)
        return
      }
      this.isOpen.push(index)
    },

    async fetchOverride (type, val) {
      this.isFetchingQuote = true
      const res = await this.quoteOverride({ id: this.id,
        override: {
          ...(this.overrideAnnualBenefit && { annualBenefit: this.overrideAnnualBenefit }),
          ...(this.overrideBenefitAmount && { benefitAmount: this.overrideBenefitAmount }),
          ...(this.overridePremium && { premium: this.overridePremium }),
          ...(this.overrideTermYears && { termYears: this.overrideTermYears })
        }
      })

      if (res.quote && res.quote !== null) {
        this.newQuote = res.quote
        this.setupNewQuote()
        this.inlineError = undefined
      } else {
        this.inlineError = res.reason
      }
      if (res?.message) {
        this.inlineError = res.message
      }

      this.isFetchingQuote = false
    },
    onInputBenefit: _debounce(function (val) {
      if (isNaN(val)) return
      this.editBenefitAmount = val
      this.setTermValues()
    }, 500),
    onQuoteSelectChange (val) {
      this.editTermLength = val
      this.setTermValues()
    },
    setTermValues () {
      this.untilChildAge = this.termReasonChildAge ? this.editTermLength : null
      this.untilInsuredAge = this.termReasonInsuredAge ? this.editTermLength : null
      this.termYears = !(this.termReasonChildAge || this.termReasonInsuredAge) ? this.editTermLength : null
      this.fetchQuote()
    },
    fetchQuote () {
      this.fetchUpdatedQuote({
        benefitAmount: this.editBenefitAmount,
        untilChildAge: this.untilChildAge,
        untilInsuredAge: this.untilInsuredAge,
        termYears: this.termYears
      })
    },
    async saveNewQuote () {
      this.isFetchingQuote = true
      const parameter = {
        benefitAmount: this.editBenefitAmount
      }
      if (this.overrideTermYears) {
        parameter.termYears = this.overrideTermYears
      } else {
        if (this.termReasonChildAge) {
          parameter.untilChildAge = this.editTermLength
        } else if (this.termReasonInsuredAge) {
          parameter.untilInsuredAge = this.editTermLength
        } else {
          parameter.termYears = this.editTermLength
        }
      }

      await this.updateQuote({ id: this.id, parameter })
      this.$emit('loading')
      this.error = null
      this.isFetchingQuote = false
      return true
    },
    async fetchUpdatedQuote (params = {}) {
      const { benefitAmount } = params
      if (benefitAmount < this.minBenefit) {
        this.error = 'Sorry, benefit amount is too low.'
      } else if (benefitAmount > this.maxBenefit) {
        this.error = 'Sorry, benefit amount is too high.'
      } else {
        this.newQuote = await this.getQuote({ id: this.id, parameter: { ...params } })
        this.setupNewQuote()
        this.setEditTermLength()
        this.error = null
      }
      this.edited = true
    },
    setEditTermLength () {
      if (this.termReasonChildAge) {
        this.editTermLength = this.newQuote.termReason.untilChildAge
      } else if (this.termReasonInsuredAge) {
        this.editTermLength = this.newQuote.termReason.untilInsuredAge
      } else {
        this.editTermLength = this.newQuote.termRecommended / 12
      }
    },
    setBenefitRange () {
      if (this.newQuote.benefitLimit && this.newQuote.benefitAmount) {
        this.defaultBenefitAmount = this.newQuote.benefitAmount
        this.minBenefit = this.newQuote.benefitLimit.minimum
        this.maxBenefit = this.newQuote.benefitLimit.maximum
      }
    },
    setTermRange () {
      if (this.termReasonChildAge && this.newQuote.youngestChildAgeLimit !== undefined) {
        this.benefitRange = [
          this.newQuote.youngestChildAgeLimit.minimum,
          this.newQuote.youngestChildAgeLimit.maximum
        ]
      } else if (this.termReasonInsuredAge && this.newQuote.insuredAgeLimit !== undefined) {
        this.benefitRange = [
          this.newQuote.insuredAgeLimit.minimum,
          this.newQuote.insuredAgeLimit.maximum
        ]
      } else {
        this.benefitRange = [
          Math.floor(parseInt(this.newQuote.termLimit.minimum) / 12),
          Math.floor(parseInt(this.newQuote.termLimit.maximum) / 12)
        ]
      }
    },
    setupNewQuote () {
      this.quoteShown = this.interview?.showQuote || false
      this.selectedQuoteDisplayMode = this.interview?.quoteDisplayMode || null
      this.quoteLocked = this.quote?.locked || false
      this.defaultBenefitAmount = this.newQuote.benefitAmount
      this.editBenefitAmount = this.newQuote.benefitAmount
      this.setEditTermLength()
      this.setBenefitRange()
      this.setTermRange()
      this.untilChildAge = this.termReasonChildAge ? this.editTermLength : null
      this.untilInsuredAge = this.termReasonInsuredAge ? this.editTermLength : null
    }
  },
  created () {
    if (this.quote) {
      this.newQuote = this.quote
      this.setupNewQuote()
    }
  }
}

</script>

<style scoped lang="scss">
@import "src/assets/styles/media-queries";
@import "src/assets/styles/global";

.quote-calculator {
  &__legal {
    font-size: 12px;
    font-style: italic;
  }

  &__container {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 20px;
  }
  &__overrides {
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 20px;
    border-radius: 6px;
  }
  &__save-button {
    display: inline-block;
    margin-top: 2rem;
  }
  &__term {
    margin-right: 20px;
  }
  &__price {
    margin-top: 1rem;
    span {
      font-size: 2.4rem;
      color: #E33031;
      font-weight: bold;
    }
  }
  &__error {
    margin: 20px 0;
    background: transparent;
    color: $c-red;
  }
}
.customer-detail {
  &__quote-calculator-values {
    margin: 40px 0 20px;
    padding-bottom: 20px;
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  &__quote-history-link {
    margin: 20px 0;
  }
  &__quote-history-history {
    display: none;
    span {
      font-weight: bold;
    }
    strong {
      display: block;
    }
    &.opened {
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 2fr;
      grid-column-gap: 10px;
      grid-row-gap: 20px;
    }
  }
  &__quote-container {
    display: flex;
    @include md {
      flex-direction: row;
    }
  }
  &__item {
    flex: 1;
  }
}

.checkbox {
  margin-top: 0;
}
</style>
