<template>
  <div class="off-ramp-waitlist-thanks">
    <FlowWrapper
      :show-buttons="false"
      :showActions="false"
      header-variant="red"
    >
      <template v-slot:header-text>
          Coming soon.
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          Dayforward is coming to your state soon!
        </div>
      </template>
      <template v-slot:content>
        <div class="off-ramp-waitlist-thanks__wrapper">
          <div class="off-ramp-waitlist-thanks__body">
            <p>
              We're working hard to reach everyone as soon as possible.
            </p>
            <p>
              Until then check out <router-link :to="{name: `incoming`}">Incoming Magazine</router-link>
              to find expert saving tips, modern money advice, and resource round-ups tailored to your life.
            </p>
            <p>
              Thank you for your interest in Dayforward!
            </p>
          </div>
          <div class="off-ramp-waitlist-thanks__actions tm-3r">
            <DFButton @click="onHomeClick">Home page</DFButton>
          </div>
      </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DFButton from '@/components/DFButton'
import { States } from '../../utils/states'
import { QuestionKey as QK } from '../../api/Interview'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import { gotoRedirect } from '@/utils/router'

export default {
  name: 'off-ramp-waitlist-thanks',
  components: { DFButton, FlowWrapper },
  data () {
    return {
      state: undefined,
      textColor: 'green'
    }
  },
  computed: {
    ...mapGetters('interview', ['answers', 'interview'])
  },
  methods: {
    gotoRedirect,
    async onHomeClick () {
      window.location = this.gotoRedirect('')
    }
  },
  created () {
    this.state = States[this.answers[QK.ADDRESS_STATE]]
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/media-queries";
@import "src/assets/styles/metrics";

.off-ramp-waitlist-thanks {
  min-height: 100vh;
  &__wrapper {
    min-height: 40vh;
  }
  &__headline {
    color: #fff;
    font-size: 4.4em;
    font-weight: 600;
  }
  &__body {
    p:not(:first-of-type) {
      margin-top: 0.8rem;
    }
  }
}

</style>
