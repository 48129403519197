<template>
  <div class="pre-launch">
    <div class="content">
      <Logo :color="Colors.eggshell" />
      <div class="contact">
        <div>Contact us</div>
        <div>
          <a class="df-link" href="mailto:hello@dayforward.com">hello@dayforward.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'
import Logo from '@/components/accessories/Logo.vue'
export default {
  name: 'pre-launch',
  components: { Logo },
  data: function () {
    return {
      Colors
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/media-queries";
.pre-launch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $c-red;
  width: 100vw;
  height: 100vh;
  .contact {
    color: $c-eggshell;
    text-align: center;
    margin-top: 5.5rem;
    a {
      color: $c-eggshell !important;
    }
  }
}
</style>
