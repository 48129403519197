<template>
  <div class="interview-initial">
    <FlowWrapper
      :progress="1/8"
      :can-move-to-next="hasValidFormData"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      :save-on-back="false"
      :off-ramp-flag="ORF.LOCATION"
      @result="onSaveResult">
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          Tell us where you live.
        </div>
      </template>
      <template v-slot:content>
        <v-form ref="basicForm">
          <div class="mq-grid">
            <AddressPicker
              v-model="selectedAddress"
              class="cols-10 cols-md-8 bm-10"
              />

            <div class="confirm-identity__citizenship cols-6">
              <div class="citizenship-link__holder">
                <Question
                  type="options"
                  :multiple="false"
                  v-model="values[QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT]"
                  :question="questionsMap[QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT]"
                  :error="errors[QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT]"
                />
              </div>
            </div>
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddressPicker from '@/components/inputs/AddressPicker'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'
import { InterviewFlagCause, QuestionKey as QK } from '@/api/Interview'
import { SupportedStates } from '@/api/Interview/constants'

export default {
  name: 'InterviewLocation',
  components: {
    AddressPicker,
    FlowWrapper,
    Question
  },
  computed: {
    ...mapGetters('interview', [
      'answers',
      'answersForKeys',
      'questionsMap',
      'interview',
      'validatedAddress',
      'validateKeys',
      'parseBool'
    ]),
    questionKeys () {
      return [
        QK.ADDRESS_LINE_1, QK.ADDRESS_LINE_2, QK.ADDRESS_CITY, QK.ADDRESS_STATE, QK.ADDRESS_ZIP, QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT
      ]
    },
    answerValues () {
      let result = this.values
      if (this.selectedAddress && this.selectedAddress.address) {
        const addressAnswers = this.addressToAnswers(this.selectedAddress)
        for (const [k, v] of Object.entries(addressAnswers)) {
          result[k] = v
        }
      }
      return result
    },
    hasValidFormData () {
      return !!this.selectedAddress && this.selectedAddress.isValid && !!this.values[QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT]
    }
  },
  data () {
    return {
      QK,
      ORF: InterviewFlagCause,
      STEP_NAME: 'location-info',
      errors: {},
      selectedAddress: undefined,
      values: {}
    }
  },
  methods: {
    ...mapActions('interview', ['updateValidatedAddress']),
    async checkValidState () {
      let isSupported = false
      if (this.selectedAddress && this.selectedAddress.address !== undefined) {
        const { state } = this.selectedAddress.address
        if (state !== undefined) {
          isSupported = SupportedStates.indexOf(state) !== -1
        }
      }
      await this.updateValidatedAddress(isSupported)
    },
    onSaveResult (res) {
      this.errors = res.errors || {}
      this.identify()
    },
    pickerValueFromAddress (address) {
      return {
        address: address,
        isValid: this.validatedAddress,
        text: address && `${address.line1}, ${address.city}, ${address.state} ${address.zip}`
      }
    },
    addressFromAnswers (answers) {
      const line1 = answers[QK.ADDRESS_LINE_1]
      const line2 = answers[QK.ADDRESS_LINE_2]
      const city = answers[QK.ADDRESS_CITY]
      const state = answers[QK.ADDRESS_STATE]
      const zip = answers[QK.ADDRESS_ZIP]
      if (line1 && city && state && zip) {
        const address = { line1, city, state, zip }
        if (line2) {
          address.line2 = line2
        }
        return address
      }
      return undefined
    },
    addressToAnswers (addressResult) {
      const answersMap = {}
      const address = addressResult.address
      answersMap[QK.ADDRESS_LINE_1] = address.line1
      if (address.line2) {
        answersMap[QK.ADDRESS_LINE_2] = address.line2
      } else {
        if (this.values[QK.ADDRESS_LINE_2]) {
          delete this.values[QK.ADDRESS_LINE_2]
        }
      }
      answersMap[QK.ADDRESS_CITY] = address.city
      answersMap[QK.ADDRESS_STATE] = address.state
      answersMap[QK.ADDRESS_ZIP] = address.zip
      return answersMap
    },
    identify () {
      const line1 = this.values[QK.ADDRESS_LINE_1]
      const line2 = this.values[QK.ADDRESS_LINE_2]
      const city = this.values[QK.ADDRESS_CITY]
      const state = this.values[QK.ADDRESS_STATE]
      const zip = this.values[QK.ADDRESS_ZIP]
      this.$analytics.identify({
        source: 'app_locationSaved',
        address: `${line1},${line2 !== undefined ? ' ' + line2 + ',' : ''} ${city}, ${state} ${zip}`
      })
    },
    toggleCitizenship () {
      const val = this.values[QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT]
      if (!val) {
        this.$set(this.values, QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT, 'true')
      } else {
        const newVal = val === 'false' ? 'true' : 'false'
        this.$set(this.values, QK.IS_US_CITIZEN_OR_LEGAL_RESIDENT, newVal.toString())
      }
    }
  },
  async created () {
    this.values = this.answersForKeys(this.questionKeys) || {}
    // prepare the interview data
    if (this.interview) {
      const address = this.addressFromAnswers(this.answers)
      this.selectedAddress = this.pickerValueFromAddress(address)
    }
  }
}
</script>

<style scoped lang="scss">
.initial-notices {
  font-size: 1em;
  word-wrap: break-word;
  word-break: break-word;
  a {
    display: inline;
  }
}

.legal-container {
  padding: 25px 0;
}

.interview__terms {
  margin-top: -20px;
}

::v-deep .v-input__slot {
  align-items: flex-start;
  label {
    padding-top: 3px;
  }
}
</style>
