<template>
  <p v-if="!editEnabled">{{ copy }}</p>
  <span v-else>
    <v-select
      :class="styleVariant === 'styled' ? 'inline-select' : 'custom-select'"
      hide-details
      :items="items"
      v-model="selectedValue"
      @change="$emit('change', $event)"
    />
    {{ postSelectText }}
    <template v-if="tooltip">
      <label
        class="term-select__tooltip--label"
        :content="tooltip"
        v-tippy="{ maxWidth: 250 }"
        tabindex="-1"
      >
        <span class="term-select__tooltip--icon" v-if="tooltip" tabindex="-1">?</span>
      </label>
    </template>
  </span>
</template>

<script>
import { BenefitTermCode } from '@/api/Interview'

export default {
  props: {
    value: undefined,
    text: {
      type: String,
      required: true
    },
    hasChild: {
      type: Boolean,
      required: true
    },
    editEnabled: {
      type: Boolean,
      default: false
    },
    termReason: {
      type: String,
      required: true
    },
    /**
     * Array of [{number} min, {number} max]
     * length of 2 only
     */
    minMax: {
      type: Array,
      required: true,
      validator: function (val) {
        return val.length === 2 && val.every(v => typeof v === 'number')
      }
    },
    tooltip: {
      type: String,
      required: false
    },
    styleVariant: {
      type: String,
      required: false,
      default: 'styled'
    }
  },
  data () {
    return {
      copy: this.text,
      preSelect: '',
      postSelect: '',
      items: [],
      selectedValue: undefined
    }
  },
  computed: {
    postSelectText () {
      return this.termReason === BenefitTermCode.CHILD_AGE ||
        this.termReason === BenefitTermCode.INSURED_AGE
        ? 'years old.'
        : 'years'
    }
  },
  watch: {
    text (newText) {
      this.copy = newText
    },
    value () {
      this.selectedValue = this.value
    },
    minMax: {
      handler: 'fillItems',
      immediate: true
    }
  },
  methods: {

    fillItems () {
      const [min, max] = this.minMax
      let diff = max - min
      this.items = Array(++diff).fill(min).map((v, i) => v + i).reverse()
    },

    reset () {
      this.$emit('input', this.selectedValue)
    }
  },
  mounted () {
    if (this.value) {
      this.selectedValue = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/colors";
@import "src/assets/styles/media-queries";

.custom-select {
  display: inline-block;
}

.inline-select {
  display: inline-block;
  width: 65px;
  @include md {
    width: 75px;
  }
  font-weight: bold;
  margin-right: 5px;
  display: inline-block;
  font-size: 1.75rem;
  p {
    display: inline;
  }

  ::v-deep .v-select__selections input {
    width: 0;
  }
  ::v-deep .v-select__selection--comma {
    position: relative;
    overflow: visible;
    color: $c-red !important;
    font-size: 1.5rem;
    @include md {
      font-size: 2.4rem;
    }
    padding-bottom: 6px;
  }

}

.term-select {
  &__suffix {
    font-size: 1.5em;
  }
  &__tooltip {
    color: $c-primary;
    font-family: "Graphik", sans-serif;
    font-size: small;
    background-color: #fff;

    &--label {
      display: inline-block;
    }

    &--icon {
      font-family: "Graphik", sans-serif;
      font-size: small;
      color: $c-action;
      font-weight: bold;
      position: relative;
      top: -12px;
    }
  }
}

</style>
