<template>
  <div>
    <template>
      <v-checkbox
        v-for="o in options"
        v-model="values"
        :key="o.value"
        :value="o.value"
        :label="o.text"
        hide-details
        @change="changed(o.value)"
        :readonly="readonly"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'HealthConditionsInput',
  model: {
    prop: 'selected',
    event: 'input'
  },

  props: {
    options: Array,
    selected: [String, Array],
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      values: []
    }
  },

  watch: {
    selected (val) {
      this.values = this.selected
    }
  },

  methods: {
    changed (val) {
      if (val !== '') {
        this.values = this.values.filter(v => v !== '')
      }
      if (!this.values.length) {
        this.values = ['']
      }
      this.$emit('input', this.values)
    }
  },

  created () {
    if (typeof this.selected === 'string') {
      this.values = [this.selected]
    } else {
      this.values = this.selected
    }
    if (this.values.length === 0) {
      this.values = ['']
      this.$emit('input', this.values)
    }
  }
}
</script>
