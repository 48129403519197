/**
 * Formats a number into a currency with string
 * @param {string|number} val
 * @param {boolean} dropCents whether to drop the float/cents part
 * @returns {string} pretty formatted number 1234.56 -> '$1,234.56'
 */
export function formatCurrency (val, dropCents = false) {
  let num = val
  if (typeof val === 'string') {
    num = extractNumber(val)
    if (!num || isNaN(num)) return ''
  }

  const opts = {
    style: 'currency',
    currency: 'USD'
  }
  if (dropCents) {
    num = Math.floor(num) // because the Intl formatter actually uses Math.round
    opts.minimumFractionDigits = 0
    opts.maximumFractionDigits = 0
  }
  const currencyFormatter = new Intl.NumberFormat('en-US', opts)
  return currencyFormatter.format(num)
}

/**
 * extract number from a string using regex. If string contains more than 1
 * group of numbers it will only return the first matching group so make sure
 * your string only has 1 group of numbers.
 * @param {string} val
 * @returns {number|undefined} if string did not contain a number it will return
 * undefined
 */
export function extractNumber (val) {
  const matches = val.replace(/[,]+/g, '').match(/([\d]+(\.\d{1,2})?)/g)
  if (!matches) return
  const num = matches[0]
  if (num.includes('.')) return parseFloat(num)
  return parseInt(num, 10)
}
