import mqMixin from './mixins/media-query'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueHotkey from 'v-hotkey'
import VueTippy, { TippyComponent } from 'vue-tippy'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import '@/assets/styles/global.scss'
import geocoderApi from './api/Geocoder'
import analyticsApi from './api/Analytics'
import { RouteName } from '@/router/routes/constants'
import { NavigationFailureType } from 'vue-router/src/util/errors'
import optimizeConfig from '@/config/optimize.config'
import FlagsmithPlugin, { FlagsmithFlagger } from '@/plugins/flagsmith.js'
import FirebasePlugin from '@/plugins/firebase.js'
import { ssAuthTokenKey } from '@/config/app.config.js'
import { datadogRum } from '@datadog/browser-rum'

let isAuthInitialized = false
async function verifyAuth(callback) {
  const auth = getAuth()
  onAuthStateChanged(auth, async (user) => {
    if (!isAuthInitialized) {
      isAuthInitialized = true
      if (user) {
        sessionStorage.setItem(ssAuthTokenKey, user.accessToken)
      }
      callback(user)
    }
  })
}

async function startApp() {
  // meta handling
  Vue.use(VueMeta)

  // hotkeys
  // for now, we disable the hotkeys totally unless we're on development
  if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_ENABLE_DEV_TOOLS) {
    Vue.use(VueHotkey)
  }

  let featuresOptions = { provider: 'noop' }
  const areFeatureFlagsDisabled =
    process.env.VUE_APP_FEATURES_DISABLED && process.env.VUE_APP_FEATURES_DISABLED === 'true'
  if (process.env.VUE_APP_FLAGSMITH_API_KEY && !areFeatureFlagsDisabled) {
    const flagsmithClient = new FlagsmithFlagger({
      settings: {
        key: process.env.VUE_APP_FLAGSMITH_API_KEY
      }
    })
    await flagsmithClient.init()
    featuresOptions = {
      provider: 'flagsmith',
      client: flagsmithClient
    }
  }
  Vue.use(FlagsmithPlugin, featuresOptions)

  // intercom initialization
  if (process.env.VUE_APP_INTERCOM_APP_ID) {
    window.intercomSettings = {
      app_id: process.env.VUE_APP_INTERCOM_APP_ID,
      custom_launcher_selector: '#launch-chat'
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('intercom is not configured')
  }

  let datadogDisabled = true
  function safeErrorPage(err) {
    try {
      if (err && err.type && err.type === NavigationFailureType.duplicated) {
        // eslint-disable-next-line no-console
        console.info('DUPLICATE ROUTE', err)
        return
      }

      // This case is expected since we redirect from navigation guards.
      if (err && err.type && err.type === NavigationFailureType.aborted) {
        // eslint-disable-next-line no-console
        console.info('ABORTED ROUTE', err.message)
        return
      }

      // This case is expected since we redirect from navigation guards.
      if (err && err.type && err.type === NavigationFailureType.redirected) {
        // eslint-disable-next-line no-console
        console.info('REDIRECTED ROUTE', err.message)
        return
      }

      // This case is expected since we redirect from navigation guards.
      if (err && err.type && err.type === NavigationFailureType.cancelled) {
        // eslint-disable-next-line no-console
        console.info('CANCELLED ROUTE', err.message)
        return
      }

      if (!datadogDisabled) {
        datadogRum.addError(err, { tag: 'sent-to', tagValue: 'contact' })
      }
      router.push({ name: RouteName.ERROR }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error('FAILED TO NAVIGATE TO ERROR PAGE', e)
        datadogRum.addError(e)
      })
    } catch (e) {
      datadogRum.addError(e)
      // eslint-disable-next-line no-console
      console.error('FAILED TO NAVIGATE TO ERROR PAGE', e)
    }
  }

  Vue.config.errorHandler = function (err) {
    safeErrorPage(err)
  }

  window.onerror = function (message, source, lineno, colno, error) {
    const msg = `${message} | ${source}:${lineno}:${colno}`
    // eslint-disable-next-line no-console
    console.error('UNKNOWN ERROR', msg, error)
    datadogRum.addError(msg)
  }

  // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/93.0.4577.82 Safari/537.36"
  // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
  var sUsrAg = navigator.userAgent

  if (
    process.env.VUE_APP_DATADOG_APPLICATION_ID !== undefined &&
    sUsrAg.indexOf('HeadlessChrome') === -1 &&
    process.env.VUE_APP_ENVIRONMENT !== 'localhost' &&
    process.env.VUE_APP_ENVIRONMENT !== ''
  ) {
    // datadog initialization
    datadogRum.init({
      applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: process.env.VUE_APP_DATADOG_SERVICE,
      env: process.env.VUE_APP_ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      sessionReplaySampleRate: 0,
      trackInteractions: false,
      defaultPrivacyLevel: 'mask-user-input'
    })
    datadogDisabled = false
    // eslint-disable-next-line no-console
    console.log('datadog initialized')
  } else {
    // eslint-disable-next-line no-console
    console.log('datadog is not configured')
  }

  Vue.config.productionTip = false

  Vue.use(FirebasePlugin)

  geocoderApi.checkIsConfigured()
  Vue.prototype.$analytics = analyticsApi

  if (process.env.VUE_APP_OPTIMIZE_ENV) {
    const experimentId = optimizeConfig['get_started_button_ordering'][process.env.VUE_APP_OPTIMIZE_ENV]
    analyticsApi.getOptimizeVariant(experimentId, (variant) => {
      Vue.prototype.$gsboVariant = variant
      analyticsApi.idempotentTrack({
        key: experimentId,
        event: 'get_started_button_ordering',
        properties: {
          variant: variant,
          experimentID: experimentId
        }
      })
    })
    // eslint-disable-next-line no-console
    // analyticsApi.trackOptimizeExperiments()
  }

  Vue.use(VueTippy, {
    theme: 'df',
    arrow: true,
    trigger: 'mouseenter click'
  })
  Vue.component('tippy', TippyComponent)
  Vue.component('contentful-cta', require('./components/contentful/ContentfulCTA.vue').default)
}

startApp().then(() => {
  verifyAuth(() => {
    new Vue({
      router,
      store,
      mqMixin,
      vuetify,
      render: (h) => h(App)
    }).$mount('#app')
  })
})
