<template>
  <div class="full-screen-loader">
    <FlowWrapper
      :show-buttons="false" :show-actions="false"
      :show-progress="showProgress" :header-variant="headerVariant"
      :progress="progress"
    >

      <template v-slot:header-text>
        {{ headerText }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">{{title}}</div>
      </template>
      <template v-slot:content>
        <div class="full-screen-loader__dots">
          <DotsSpinner class="tm-3r bm-1r"/>
          <div class="full-screen-loader__subtitle">{{subtitle}}</div>
        </div>
      </template>

    </FlowWrapper>
  </div>
</template>

<script>
import DotsSpinner from '@/components/DotsSpinner.vue'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'

export default {
  name: 'full-screen-loader',
  components: { FlowWrapper, DotsSpinner },
  props: {
    headerText: {
      type: String
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    headerVariant: {
      type: String
    },
    showProgress: {
      type: Boolean,
      default: true
    },
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>
