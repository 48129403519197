<template>
  <div class="sign-in-magic-link">
    <FlowWrapper hide-sign-in :show-buttons="false">
      <template v-slot:header-text>
        Check your email.
      </template>
      <template v-slot:content>
        <div class="magic-link">
          <p>
            We have sent an email to <b>{{ email }}</b>. This email will allow you to log in.
          </p>
          <p>
            If you can’t find the email, please check your spam folder.
          </p>
          <p>
            Entered the wrong email, or need to resend your email?  <router-link :to="getRoute()">Click here to re-enter</router-link>.
          </p>
          <p class="magic-link__exp-top">
            <b>Once you've clicked the link in your email, you can safely close this window.</b>
          </p>

        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
export default {
  name: 'sign-in-magic-link',
  components: { FlowWrapper },
  data: function () {
    return {
      email: window.localStorage.getItem('auth.email')
    }
  },
  methods: {
    getRoute () {
      let redirect = window.localStorage.getItem('auth.redirect')
      let route = '/signin'
      if (redirect === '/admin/dashboard') {
        route = '/admin'
      }
      return route
    }
  }
}
</script>

<style scoped lang="scss">
.magic-link {
  p:not(:first-of-type) {
    margin-top: 1rem;
  }
  &__exp-top {
    padding-top: 1.5rem;
  }
}
</style>
