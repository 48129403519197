<template>
  <v-dialog v-model="isShowing" :content-class="cssClass" :persistent="persistent" :scrollable="scrollable">
    <div class="df-modal__wrapper">
      <div class="df-modal__header">
        <div class="df-modal__header-title">
          <slot name="header">
            <div v-if="title">{{ title }}</div>
            <slot v-else name="title"></slot>
          </slot>
        </div>
        <a role="button" class="df-modal__close" elevation="0" @click="onCloseClicked">
          <div class="close-image"></div>
        </a>
      </div>
      <div class="df-modal__content">
        <div class="df-modal__content-inner" ref="contentScroll">
          <div v-if="showPrintLink" class="df-modal__print-link">
            <a :href="pdfUrl" target="_blank" download>PRINT THIS</a>
          </div>
          <div class="df-modal__subtitle-container">
            <p v-if="subtitle" v-html="subtitle" class="df-modal__header-subtitle" />
            <slot v-else name="subtitle"></slot>
          </div>
          <slot name="last-updated">
            <p v-if="lastUpdated" class="df-modal__last-update">Last Update: <span>{{ lastUpdated }}</span></p>
          </slot>
          <slot name="content"></slot>
        </div>
        <div class="df-modal__content-overlay" v-if="showOverlay">
          <slot name="overlay-content"></slot>
        </div>
      </div>
      <div class="df-modal__actions" v-if="showActions">
        <slot name="actions">
          <a role="button" class="df-modal__cta df-modal__cta-primary" @click="onCloseClicked">{{ ctaText }}</a>
          <a role="button" class="df-modal__cta df-modal__cta-secondary" @click="onCloseClicked">{{ ctaTextSecondary }}</a>
        </slot>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'
import { mapActions } from 'vuex'

export default {
  name: 'df-modal',
  props: {
    /**
     * Should the modal have a scrollable inside content area
     */
    scrollable: {
      type: Boolean,
      default: false
    },

    /**
     * Title of the modal, will sit on top of the scrollale area
     */
    title: {
      type: String
    },

    /**
     * Subtitle of the modal, will sit under the main title
     */
    subtitle: String,

    /**
     * Limit the height of the content area in case you want to enforce
     * scrolling. Use typical string you would put in the css height property:
     * 300px, auto, inherit, etc
     */
    contentHeight: {
      type: String,
      default: 'auto'
    },

    /**
     * If not using the action slot to provide your own actions then change the
     * text of the single CTA text here
     */
    ctaText: {
      type: String,
      default: 'Close'
    },

    ctaTextSecondary: {
      type: String,
      default: 'Accept'
    },

    lastUpdated: {
      type: String
    },

    value: {
      type: Boolean,
      default: false
    },

    contentClass: {
      type: String
    },
    showActions: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: false
    },
    showOverlay: {
      type: Boolean,
      default: false
    },
    scrollOnUpdate: {
      type: Boolean,
      default: false
    },
    showPrintLink: {
      type: Boolean,
      default: false
    },
    pdfUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      Colors,
      isShowing: false
    }
  },
  computed: {
    cssClass () {
      if (this.contentClass) {
        return `df-modal ${this.contentClass}`
      }
      return 'df-modal'
    }
  },
  watch: {
    value (newVal) {
      this.value = newVal
      this.isShowing = newVal
    },
    isShowing (newVal) {
      this.isShowing = newVal
      this.$emit('input', newVal)
    },
    pdfUrl (newVal) {
      this.pdfUrl = newVal
    }
  },
  methods: {
    ...mapActions('content', ['fetchContentfulAsset']),

    onCloseClicked () {
      this.isShowing = false
      this.$emit('input', false)
      this.$emit('close')
    }
  },
  updated () {
    if (this.scrollOnUpdate && this.$refs.contentScroll) {
      this.$refs.contentScroll.scrollTop = 0
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/_mixins";
@import "src/assets/styles/_media-queries.scss";

.df-modal {
  display: flex;
  background-color: $c-eggshell;
  border: 1px solid rgba($c-primary, 0.75);
  max-width: 100%;
  overflow: hidden;
  a {
      word-break: break-word !important;
    }
  @include md {
    max-width: 80%;
  }
  @include lg {
    max-width: 70%;
  }
  @include xl {
    max-width: 1000px;
  }

  &__wrapper {
    flex: 1 1 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    padding-bottom: 20px;
    @include md {
      padding-bottom: 30px;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    align-items: flex-start;
    margin: 25px 20px 15px 25px;
    @include md {
      margin: 30px 30px 15px 40px;
    }
    &-title {
      display: block;
      flex: 1;
      flex-wrap: wrap;
      font-weight: 600;
      font-size: 1.4em;
      color: $c-red;
      letter-spacing: -0.8px;
      line-height: 1em;
      padding-top: 8px;
      @include md {
        font-size: 1.6em;
      }
    }
  }

  &__close {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $c-eggshell !important;
    width: 36px;
    height: 36px;
    margin-left: 10px;
    .close-image {
      background-size: 16px 16px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAYAAACoPemuAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TpSoVRTuIOGSonSyIijhqFYpQodQKrTqYXPoFTRqSFBdHwbXg4Mdi1cHFWVcHV0EQ/ABxcnRSdJES/5cUWsR4cNyPd/ced+8AoV5mqtkxDqiaZaTiMTGTXRUDr+jGAPoRQUBipj6XTCbgOb7u4ePrXZRneZ/7c/QqOZMBPpF4lumGRbxBPL1p6Zz3iUOsKCnE58RjBl2Q+JHrsstvnAsOCzwzZKRT88QhYrHQxnIbs6KhEk8RhxVVo3wh47LCeYuzWq6y5j35C4M5bWWZ6zRHEMcilpCECBlVlFCGhSitGikmUrQf8/APO/4kuWRylcDIsYAKVEiOH/wPfndr5icn3KRgDOh8se2PUSCwCzRqtv19bNuNE8D/DFxpLX+lDsx8kl5raeEjoG8buLhuafIecLkDDD3pkiE5kp+mkM8D72f0TVlg8BboWXN7a+7j9AFIU1eJG+DgEIgUKHvd491d7b39e6bZ3w9GV3KVejiCPQAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAN1wAADdcBQiibeAAAAAd0SU1FB+QJEhAmGQ2ShsUAAAHZSURBVFjDxdhLThtBEAbg/68WB8hiAPkAgBRxBMA8BIQTsMgRIkU5DEgcADgBKAgGI/sICJEoexw0iOwTpouNAdsBYU9X98zS01PzuaYf1U0A6H5ayETdoYr/Nn3cvkQNV7G5POvhd0X95+yk02UPlQOYB1Co+NXUuB7qHGBDgR9OyxURdQc9FABk9Dzrbix/TIW6W2/OeegFwAYAEJhTun0R8AugNy9NOSnU1u+txfkUmSpFcwDT/T978V85nMr+BjE/63vv5KgNU6J6n3S8B1Kg/oPFxo0Tm6EBYqDehFnjqsSidUCrGIwVOPRZxvrXodlmjH5i0QVo3YmtBg0th73z7q/VSKbdnMR7pS+pzCzmPhrP4rCakCvD+nBtgJNDt/6o+KWQJUyCVmQRjVWrVYYVW6sz3j+0XskWAHyglzyk2JRA1GDnhxZ9zbIQnNigUKiUTQdZGCzTq+Nog6pxgh0FlXxJGgeVbBGvgope9oSgohWKFijz0toSZbYZiYEK3r7FRFXe8KZAjX1EkBI18qFKHahRcKK+3KsDBQDZ99bP0ukaiNuhhX9H9J/bBnCVGvV0NY7a16Jceq5KiF9STmzLVJ7fUrAGoFPH+etA5oCOPEw0s9PTm0dQxJ2NXBzawAAAAABJRU5ErkJggg==");
      width: 16px !important;
      height: 16px;
    }
    &:hover {
      background: transparent;
    }
  }

  &-subtitle {
    text-align: left;
    font-weight: normal;
    font-size: 1.2rem;
    margin: 0.5em auto;
  }

  &__content {
    display: flex;
    font-size: 1em;
    line-height: 1.35em;
    flex: 1 1 auto;
    color: $c-primary;
    overflow: hidden;
    p:not(:last-of-type) {
      margin-bottom: 1rem;
    }
    li {
      margin-bottom: 1rem;
    }
    &-inner {
      flex: 1;
      padding: 0 25px 20px;
      overflow-x: auto;
      @include md {
        padding: 0 40px 20px;
      }
    }
  }

  &.text-size-lg .df-modal__content {
    font-size: 1.2rem;
    line-height: 1.4;
  }
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px 25px 0 25px;
    @include md {
      padding: 20px 40px 0 40px;
    }
    button {
      min-width: 180px !important;
    }
  }
  &__cta {
    font-family: 'Graphik', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: $c-red;
  }
  &__cta-primary {
    flex: 1 0 auto;
  }
  &__cta-secondary {
    flex: 1 0 auto;
    justify-self: flex-end;
    text-align: right;
  }
  &__last-update {
    font-family: 'Graphik', sans-serif;
    font-weight: 600;
    margin-bottom: 1em !important;
    span {
      color: $c-red;
    }
  }
}

.v-dialog--scrollable {
  .df-modal__card .df-modal__content {
    padding: 0 20px;
  }
}

.df-modal__content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($c-primary, 0.85);
  z-index: 999999;
  overflow: auto;
}

.df-modal__print-link {
  > a {
    &:hover {
      text-decoration: underline !important;
      cursor: pointer;
  }
  }
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: right;
  color: $c-action !important;
}

</style>
