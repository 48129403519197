<template>
  <div ref="esign" class="esign">
    <FullScreenLoader
      v-if="isLoading"
      :header-text="loadingHeaderText"
      subtitle="One moment please ..."
      :progress="1"
    />
    <FlowWrapper
      v-show="!isLoading"
      :show-back="showBack"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      :full-width="$ltLg"
      :showNext="false"
    >
      <template v-slot:content-header>
        <div class="df-content-header-text">
          {{ title }}
        </div>
      </template>
      <template v-slot:content>
        <div class="mq-grid width-override" v-if="!isLoading">
          <div class="cols-10" v-if="imoAgent === true">
            <p class="signature-required">
              Please review your policy details in the application below. If everything is correct, sign the application
              and click "submit" to authorize us to process your payment after our final review. If you have any
              questions or want to make changes to your application, please reach out to your agent for assistance.
            </p>
          </div>
          <div class="cols-10" v-else>
            <p class="signature-required" v-if="!isEstimate">
              Please review your policy details in the application below. If everything is correct, sign the application
              and click "submit" to authorize us to process your payment after our final review. If you have any
              questions or want to make changes to your application, please
              <a href="#" @click="openChat">contact us</a>.
            </p>
            <p class="signature-required" v-else>
              By signing this application, you are making an offer for life insurance with Dayforward based on the terms
              of the estimated quote provided. If approved, you authorize us to process your payment at the price shown
              in the estimated quote or any lower amount that we are able to provide for the terms shown. Any questions
              or want to make changes to the application?
              <a href="#" @click="openChat">Contact us</a>.
            </p>
          </div>
        </div>
        <div class="esign-container mq-grid" style="max-width: 100%">
          <div ref="embed" class="sign-embed mt-2 cols-10" v-show="!hasReceivedMessage">
            <div class="sign-embed-container" ref="documentEmbed" :class="$isSm ? ['hidden', 'mobile'] : []">
              <div class="sign-embed-document">
                <iframe
                  v-if="hasEmbedUrl"
                  :src="embedUrl"
                  @load="loaded"
                  :class="isLoading ? 'hidden' : ''"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="sign-embed-actions" v-if="$isSm">
                <DFButton @click="onEmbedClose">Cancel</DFButton>
              </div>
            </div>

            <div class="embed-placeholder" v-if="$isSm" @click.stop="onShowMobileEmbed">
              <div class="center">
                <DFButton class="embed-button" @click="onShowMobileEmbed">Click to view</DFButton>
              </div>
              <div class="top left">{{ fullName }}<br />Policy</div>
              <div class="top right">
                <img
                  src="@/assets/images/link_out.svg"
                  class="link-out"
                  @click.stop="onShowMobileEmbed"
                  v-if="hasEmbedUrl"
                />
              </div>
              <div class="bottom right small">Document security provided by <strong>Docusign</strong></div>
            </div>
          </div>
        </div>

        <v-snackbar v-model="snackbar" :top="true" :right="true" :color="theme.colors.error" :timeout="5000">
          We’re sorry, an error has occurred.
        </v-snackbar>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import theme from '@/config/theme.config'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import FullScreenLoader from '@/components/loading/FullScreenLoader.vue'
import {
  BenefitTermCode,
  InterviewStatus,
  QuestionKey as QK,
  BenefitType,
  BeneficiaryRelationship,
  BeneficiaryRelationshipReadable,
  SectionDisplayOption,
  SectionID
} from '@/api/Interview'
import moment from 'moment'
import { getFlowStepInfo } from '@/api/StepInfo'
import DFButton from '@/components/DFButton.vue'
import { RouteName } from '@/router/routes/constants.js'
import { InteractionType } from '@/api/Interview/constants.js'
import { getCookie } from '@/utils/helpers'

export default {
  name: 'InterviewEsign',
  components: { DFButton, FlowWrapper, FullScreenLoader },

  data() {
    return {
      isSubmitted: false,
      BeneficiaryRelationship: BeneficiaryRelationship,
      embedUrl: null,
      error: null,
      hasReceivedMessage: false,
      isEstimate: false,
      isLoading: true,
      isSigned: false,
      quote: {},
      showBack: true,
      snackbar: false,
      status: undefined,
      theme: theme,
      values: {},
      imoAgent: undefined
    }
  },

  computed: {
    ...mapGetters('interview', ['interview', 'interviewID', 'answers']),

    loadingHeaderText() {
      const { title } = getFlowStepInfo(this.$route)
      return title || 'Checkout'
    },

    firstName() {
      const name = this.answers[QK.FIRST_NAME] || ''
      if (name !== undefined) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
      } else {
        return ''
      }
    },

    title() {
      if (this.isEstimate) {
        return 'Please review your application, sign and click "submit"!'
      }
      return this.firstName !== '' ? `${this.firstName}, your policy is almost ready!` : 'Your policy is almost ready!'
    },

    beneficiaryIsIndividual() {
      const bt = this.beneficiaryType
      return (
        bt === BeneficiaryRelationship.SPOUSE_OR_PARTNER ||
        bt === BeneficiaryRelationship.ONE_CHILD ||
        bt === BeneficiaryRelationship.SOMEONE_ELSE
      )
    },

    beneficiaryType() {
      return this.answers[QK.BENEFICIARY_TYPE]
    },

    beneficiaryName() {
      if (this.beneficiaryIsIndividual) {
        return [this.answers[QK.BENEFICIARY_FIRST_NAME], this.answers[QK.BENEFICIARY_LAST_NAME]].join(' ')
      } else if (this.beneficiaryType === BeneficiaryRelationship.ALL_CHILDREN) {
        return 'all your surviving children'
      } else {
        const beneficiary = BeneficiaryRelationshipReadable[this.beneficiaryType] || ''
        return `your ${beneficiary.toLowerCase()}`
      }
    },

    questionKeys() {
      return [
        QK.FIRST_NAME,
        QK.MIDDLE_INITIAL,
        QK.LAST_NAME,
        QK.BENEFICIARY_TYPE,
        QK.BENEFICIARY_FIRST_NAME,
        QK.BENEFICIARY_LAST_NAME
      ]
    },

    answerValues() {
      return this.values
    },

    hasEmbedUrl() {
      return this.embedUrl !== null
    },

    fullName() {
      return [this.answers[QK.FIRST_NAME], this.answers[QK.MIDDLE_INITIAL], this.answers[QK.LAST_NAME]].join(' ')
    },

    benefitAmount() {
      if (this.quote && this.quote.benefitAmount !== undefined) {
        return this.quote.benefitAmount.toLocaleString()
      } else {
        return ''
      }
    },

    termEndDate() {
      if (this.quote && this.quote.termEndDate !== undefined) {
        return moment(this.quote.termEndDate).format('M/D/YYYY')
      } else {
        return ''
      }
    },

    annualBenefit() {
      if (this.quote && this.quote.annualBenefit !== undefined) {
        return `${this.quote.annualBenefit.toLocaleString()}`
      } else {
        return ''
      }
    },

    benefitType() {
      let benefitType = 'annual'
      if (this.quote && this.quote.benefitType !== undefined) {
        if (this.quote.benefitType === BenefitType.EXPENSE_COVERAGE) {
          benefitType = 'household'
        }
      }
      return benefitType
    },

    termEndDateText() {
      return `until ${this.coverageEndDate}`
    },

    termReasonChildAge() {
      if (this.quote && this.quote.termReason) {
        return this.quote.termReason.code === BenefitTermCode.CHILD_AGE
      }
      return false
    },

    termYears() {
      if (this.quote && this.quote.termRecommended !== undefined) {
        return Math.floor(parseInt(this.quote.termRecommended) / 12)
      }
      return 0
    },

    termMonthsRemainder() {
      if (this.quote && this.quote.termRecommended !== undefined) {
        return parseInt(this.quote.termRecommended) % 12
      }
      return 0
    },

    coveragePeriod() {
      let text = ''
      if (this.termMonthsRemainder === 0) {
        text = `${this.termYears} years`
      } else {
        text = `${this.termYears} years and ${this.termMonthsRemainder} ${
          this.termMonthsRemainder === 1 ? 'month' : 'months'
        }`
      }
      return text
    },

    childAgeAtTermEnd() {
      if (this.termReasonChildAge) {
        return this.quote.termReason.untilChildAge
      }
      return 0
    },

    premium() {
      if (this.quote && this.quote.premium !== undefined) {
        return this.quote.premium
      }
      return ''
    }
  },

  methods: {
    getCookie,
    ...mapGetters('account', ['getAccount']),
    ...mapActions('interview', [
      'interviewQuestionAnswerMaps',
      'signatureEmbedUrl',
      'getQuote',
      'getStatus',
      'recordInteraction',
      'submitInterview',
      'fetchInterviewSection'
    ]),
    async openChat() {
      await this.$router.push({ name: 'help' })
    },

    onShowMobileEmbed() {
      this.$refs.esign.classList.add('no-scroll')
      this.$refs.documentEmbed.classList.remove('hidden')
    },

    async fetchExistingInterviewAnswers() {
      if (!this.interviewID) {
        return
      }
      const { answers } = await this.interviewQuestionAnswerMaps({
        id: this.interviewID,
        keys: this.questionKeys
      })
      this.values = answers
    },

    async fetchDisplayOptions() {
      if (!this.interviewID) {
        return
      }
      const { displayOptions } = await this.fetchInterviewSection({
        id: this.interviewID,
        sectionId: SectionID.SIGNATURE
      })
      this.displayOptions = displayOptions || []
      this.showBack = !this.displayOptions?.includes?.(SectionDisplayOption.HIDE_BACK_BUTTON)
    },

    async fetchSignatureEmbedUrl() {
      if (!this.interviewID) {
        return
      }
      try {
        this.embedUrl = await this.signatureEmbedUrl(this.interviewID)
      } catch (e) {
        this.error = e
        this.isLoading = false
      }
    },

    loaded() {
      this.isLoading = false
    },

    onResize() {
      if (this.$gtSm) {
        this.$refs.esign.classList.remove('no-scroll')
      }
    },

    onEmbedClose() {
      this.$refs.esign.classList.remove('no-scroll')
      this.$refs.documentEmbed.classList.add('hidden')
    },

    async receiveMessage(event) {
      if (!event || event.origin !== window.location.origin || !event.data || !event.data.event) {
        // eslint-disable-next-line no-console
        console.warn('DOCUSIGN: did not receive a valid DOCUSIGN event. skipping.', event)
        return
      }
      /*
      https://developers.docusign.com/esign-rest-api/reference/Envelopes/EnvelopeViews/createRecipient
      Possible event parameter values include:
          access_code_failed: Recipient used incorrect access code.
          cancel: Recipient canceled the signing operation, possibly by using the Finish Later option.
          decline: Recipient declined to sign.
          exception: A system error occurred during the signing process.
          fax_pending: Recipient has a fax pending.
          id_check_failed: Recipient failed an ID check.
          session_timeout: The session timed out. control this timeout by using the Signer Session Timeout option.
          signing_complete: The recipient completed the signing ceremony.
          ttl_expired: The TTL token for the envelope has expired. (after 5 minutes or if the envelope is voided.)
          viewing_complete: The recipient completed viewing an envelope that is in a read-only state
      */
      // eslint-disable-next-line no-console
      console.log('DOCUSIGN: Received event.')

      if (event.data.event === 'session_timeout') {
        // eslint-disable-next-line no-console
        console.warn(`DOCUSIGN: received session timeout. reloading.`)
        window.location.reload()
      }
      if (event.data.event === 'signing_complete') {
        // eslint-disable-next-line no-console
        console.log(`DOCUSIGN: Received 'signing_complete' event.`)
        // user finished signing
        this.isSigned = true
        window.sessionStorage.setItem('isSigned', 'true')

        // eslint-disable-next-line no-console
        console.log(`DOCUSIGN: Recording interaction ..`)
        this.recordInteraction({
          interviewID: this.interviewID,
          actionType: InteractionType.APPLICATION_SIGNED
        })
          .then(() => {
            if (this.isSubmitted === false) {
              // eslint-disable-next-line no-console
              console.log(`DOCUSIGN: Submitting interview ..`)
              this.isSubmitted = true
              this.submitInterview({ id: this.interviewID, async: true })
            }
          })
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(`DOCUSIGN: error while recording server interaction`)
            // eslint-disable-next-line no-console
            console.error(e)
          })

        this.$analytics.idempotentTrack({
          key: this.interviewID,
          event: 'app_sp'
        })
        this.$analytics.idempotentTrack({
          key: this.interviewID,
          event: 'app_bp'
        })
        await this.$router.push({ name: RouteName.INTERVIEW_REVIEW_PENDING })
      } else {
        // eslint-disable-next-line no-console
        console.warn(`DOCUSIGN: received an invalid docusign event[name=${JSON.stringify(event.data)}]`)
      }
    },
    async fetchQuote() {
      if (!this.interviewID) {
        return
      }
      this.quote = await this.getQuote({ id: this.interviewID })
    },
    async fetchStatus() {
      if (!this.interviewID) {
        return
      }
      const statusResult = await this.getStatus({ id: this.interviewID })
      const status = statusResult?.status
      if (!status) {
        return
      }
      this.status = status
    }
  },
  async created() {
    await Promise.all([
      this.fetchStatus(),
      this.fetchExistingInterviewAnswers(),
      this.fetchSignatureEmbedUrl(),
      this.fetchDisplayOptions(),
      this.fetchQuote()
    ])

    if (!this.interview) {
      throw new Error('The interview is not valid')
    } else {
      this.isEstimate = this.interview?.quote?.isEstimate || false
    }
    if (!this.status) {
      throw new Error('interview status could not be determined')
    }
    if (!this.quote) {
      throw new Error('The quote could not be retrieved')
    }
    if (this.status === InterviewStatus.SUBMITTED) {
      await this.$router.push({ name: RouteName.INTERVIEW_REVIEW_PENDING })
      return
    }
    // listen for a message from the webhook/Docusign callback
    window.addEventListener('message', this.receiveMessage, false)
    window.addEventListener('resize', this.onResize)
    if (this.status === InterviewStatus.READY_TO_SIGN) {
      this.isSigned = false
      window.sessionStorage.removeItem('isSigned')
    } else {
      this.isSigned = window.sessionStorage.getItem('isSigned') === 'true'
    }
    if (this.isSigned === true) {
      this.isLoading = false
    }
  },
  mounted() {
    this.imoAgent = this.getCookie('imo:agentId') !== '' ? true : undefined
  },
  destroyed() {
    // detach the listener
    window.removeEventListener('message', this.receiveMessage, false)
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/global';
@import 'src/assets/styles/media-queries';
$md-content-max-width: 85%;
$lg-content-max-width: 80%;

.df-content-header-text {
  color: $c-red;
}

.signature-required {
  margin-bottom: 3.5rem;
}

.width-override {
  @include md {
    * {
      max-width: $md-content-max-width;
    }
  }
  @include lg {
    * {
      max-width: $lg-content-max-width;
    }
  }
}
.sign-embed {
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.4);
  border: none;

  &.hidden {
    display: none;
  }

  &__title {
    width: 100%;
    display: block;
  }
}

.sign-embed-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 600px;
  .sign-embed-document {
    flex: 1;
    display: flex;
    position: relative;
    background-color: $c-eggshell;
    @include md {
      background-color: transparent;
    }

    & iframe {
      position: absolute;
      flex: 1;
      width: 100%;
      height: 88vh;
      border: 1px solid rgba($c-primary, 0.1);
      @include md {
        height: 100%;
      }
    }
  }
  &.hidden {
    visibility: hidden;
  }
  &.mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    z-index: 99999;
  }
}

.link-out {
  height: 18px;
  cursor: pointer;
}

.no-scroll {
  overflow: hidden;
}

.interview__jump-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 1rem;
  @include md {
    margin-bottom: 0;
  }
}

.embed-placeholder {
  position: relative;
  height: 260px;
  width: auto;
  border: 0;
  padding: 20px;
  font-size: 13px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .top {
    position: absolute;
    top: 20px;
  }
  .left {
    position: absolute;
    left: 20px;
  }
  .bottom {
    position: absolute;
    bottom: 20px;
  }
  .right {
    position: absolute;
    right: 20px;
  }
  .small {
    font-size: 0.75em;
  }
  .center {
    position: absolute;
    max-width: 180px !important;
    top: calc(50% -32px);
    left: calc(50% - 90px);
  }
}

dd {
  margin-top: 0.8rem;
  margin-bottom: 1.85rem;
}
</style>
