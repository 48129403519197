<template>
  <div class="risk-score-select cols-5">
    <label>Assign risk class</label>
    <v-select
      v-model='selected'
      item-value="value"
      item-text="label"
      :items="riskItems"
      @change="handleChange($event, id, accountId)"
      hide-details
    ></v-select>
    <span class="error-msg">{{ error }}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RiskScoreSelect',
  props: {
    id: {
      type: String,
      default: ''
    },
    accountId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selected: null,
      error: null,
      flags: {
        auto: { auto: true },
        moreInfo: { class: 'UNDEFINED', flags: ['NEEDS_MORE_INFORMATION'] },
        ineligableIncome: { class: 'INELIGIBLE', flags: ['INELIGIBLE_INCOME'] },
        ineligableLocation: { class: 'INELIGIBLE', flags: ['INELIGIBLE_LOCATION'] },
        ineligableAttestations: { class: 'INELIGIBLE', flags: ['INELIGIBLE_ATTESTATION'] },
        unableToServe: { class: 'INELIGIBLE', flags: ['INELIGIBLE_UNINSURABLE'] },
        closeCaseAR: { class: 'UNDEFINED', flags: ['INELIGIBLE_BY_APPLICANT_REQUEST'] },
        closeCaseNR: { class: 'UNDEFINED', flags: ['INELIGIBLE_UNRESPONSIVE'] },
        closeCaseIN: { class: 'UNDEFINED', flags: ['INELIGIBLE_INCOMPLETE'] }
      },
      flagValues: {
        NEEDS_MORE_INFORMATION: 'moreInfo',
        INELIGIBLE_INCOME: 'ineligableIncome',
        INELIGIBLE_LOCATION: 'ineligableLocation',
        INELIGIBLE_ATTESTATION: 'ineligableAttestations',
        INELIGIBLE_UNINSURABLE: 'unableToServe',
        INELIGIBLE_BY_APPLICANT_REQUEST: 'closeCaseAR',
        INELIGIBLE_UNRESPONSIVE: 'closeCaseNR',
        INELIGIBLE_INCOMPLETE: 'closeCaseIN'
      },
      riskItems: [
        { label: 'Auto', value: 'auto' },
        { label: 'Class 1', value: 'ONE' },
        { label: 'Class 2', value: 'TWO' },
        { label: 'Class 3', value: 'THREE' },
        { label: 'Class 4', value: 'FOUR' },
        { label: 'Class 5', value: 'FIVE' },
        { label: 'Class 6', value: 'SIX' },
        { label: 'Ineligible', value: 'INELIGIBLE' },
        { label: 'Needs more information', value: 'moreInfo' },
        { label: 'Ineligible income', value: 'ineligableIncome' },
        { label: 'Ineligible location', value: 'ineligableLocation' },
        { label: 'Ineligible attestations', value: 'ineligableAttestations' },
        { label: 'Unable to serve', value: 'unableToServe' },
        { label: 'Close case (applicant request)', value: 'closeCaseAR' },
        { label: 'Close case (not responsive)', value: 'closeCaseNR' },
        { label: 'Close case (incomplete)', value: 'closeCaseIN' }
      ]
    }
  },
  methods: {
    ...mapActions('admin', ['setRisk']),
    getScoreValue (value) {
      if (value !== null && 'class' in value) {
        if (value.class === 'UNDEFINED' || value.class === 'INELIGIBLE') {
          return this.flagValues[value.flags[0]]
        }
        return value.class === undefined ? 'UNDEFINED' : value.class
      }
      return 'UNDEFINED'
    },
    async handleChange (e, id, accountId) {
      this.error = null
      const flags = this.checkFlags(e)
      let data

      if (flags !== false) {
        data = flags
      } else {
        data = { class: e }
      }
      const { interview, error } = await this.setRisk({ data, id })
      if (interview !== null) {
        this.$emit('scoreChanged')
        this.selected = e
      } else {
        if (error) {
          this.error = error
          this.selected = null
        }
      }
    },
    checkFlags (val) {
      if (val in this.flags) {
        return this.flags[val]
      }
      return false
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
.risk-score-select {
  margin-bottom: 2rem;
  label {
    font-weight: bold;
  }
}
.error-msg {
  font-size: 0.75rem;
  color: $c-error;
}
</style>
