<template>
  <div class="scroll-arrow">
    <svg id="scroll-arrow-contents" data-name="Scroll down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 111">
      <polygon points="7 111 0 100 14 100 7 111" :fill="compArrowColor"/>
      <rect x="5.5" width="3" height="100" :fill="compLineColor"/>
    </svg>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'

export default {
  name: 'scroll-arrow',
  props: {
    lineColor: {
      type: String,
      default: undefined
    },
    arrowColor: {
      type: String,
      default: undefined
    },
    variant: {
      type: String,
      default: undefined
    }
  },
  computed: {
    compLineColor () {
      if (this.lineColor) {
        return this.lineColor
      }
      if (this.variant) {
        if (this.variant === 'red') {
          return Colors.eggshell
        } else {
          return Colors.red
        }
      }
      return Colors.red
    },
    compArrowColor () {
      if (this.arrowColor) {
        return this.arrowColor
      }
      if (this.variant) {
        if (this.variant === 'red') {
          return Colors.red
        } else {
          return Colors.eggshell
        }
      }
      return Colors.eggshell
    }
  }
}
</script>
