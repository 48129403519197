<template>
  <nav class="back-forward-buttons">
    <div class="back-forward-buttons__button">
      <DFButton
        v-if="showBack"
        @click="back"
        :disabled="backDisabled"
        variant="light"
        outlined
      >
        {{ this.backTitle }}
      </DFButton>
    </div>
    <div v-if="$gtSm" class="back-forward-buttons__spacer"></div>
    <div class="back-forward-buttons__button back-forward-buttons__button--right">
      <DFButton
        v-if="showNext"
        :disabled="nextDisabled"
        @click="runNextAction"
        :is-loading="showNextLoader && isNextActionRunning"
      >
        {{ this.nextTitle }}
      </DFButton>
    </div>
  </nav>
</template>

<script>

import Colors from '@/assets/styles/_colors.scss'
import DFButton from '@/components/DFButton.vue'

export default {
  name: 'BackForwardButtons',
  components: { DFButton },
  props: {
    showBack: {
      type: Boolean,
      required: false,
      default: true
    },
    backTitle: {
      type: String,
      required: false,
      default: 'BACK'
    },
    backAction: {
      type: Function,
      required: false,
      default: undefined
    },
    showNext: {
      type: Boolean,
      required: false,
      default: true
    },
    nextTitle: {
      type: String,
      required: false,
      default: 'NEXT'
    },
    nextAction: {
      type: Function,
      required: false,
      default: undefined
    },
    nextDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showNextLoader: {
      type: Boolean,
      default: true,
      required: false
    },
    modalNavigation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      Colors,
      isNextActionRunning: false,
      backDisabled: false
    }
  },
  methods: {
    async back () {
      this.backDisabled = true
      await this.backAction()
      this.backDisabled = false
    },

    async runNextAction () {
      if (this.isNextActionRunning) {
        return
      }
      this.isNextActionRunning = true
      const res = await this.nextAction()
      this.isNextActionRunning = false
      return res
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';

.back-forward-buttons {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @include md{
    flex-direction: row;
  }
  &__spacer {
    flex: 1;
  }
}

.back-forward-buttons__button {
  width: 100%;
  &:not(:first-child) {
    margin-bottom: 15px;
  }
  @include md {
    &:not(:first-child) {
      margin-bottom: 0;
    }
    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &--right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
</style>
