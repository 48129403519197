<template>
  <div class="dashboard">
    <FlowWrapper :progress="1" :showActions="true" :show-buttons="false">
      <template v-slot:actions>
        <div class="dashboard__navigation">
          <!-- <router-link class="dashboard__navigation-link" to="/">
            New Applications
          </router-link>
          <router-link class="dashboard__navigation-link" to="/">
            Policy Holders
          </router-link> -->
          <router-link class="dashboard__navigation-link" to="/signout">Logout</router-link>
        </div>
      </template>
      <template v-slot:header-text>Dashboard</template>
      <template v-slot:content-header>
      </template>
      <template v-slot:content>
        <div class="dashboard__account-list">

          <div class="dashboard__filters">
            <template v-if="showAdminCreate">
              <form>
                <fieldset class="dashboard__filters-fieldset">
                  <legend class="dashboard__filters-legend">Create interview and bind to account:</legend>
                  <div class="cols-10" v-if="interviewBinding">
                    <div>
                      <v-progress-circular :color="Colors.red" width="2" size="20" indeterminate/>
                      <div class="dashboard__binding">Creating and binding new interview...</div>
                    </div>
                  </div>
                  <div v-else class="dashboard__input-wrapper mq-grid">
                    <v-text-field
                      class="cols-4"
                      v-model="email"
                      type="email"
                      placeholder="Enter account email"
                      :error="hasError"
                      :error-messages="errorMessage"
                      @focus="onFocus"
                    />
                    <v-select
                      class="cols-2"
                      :items="supportedStates"
                      placeholder="Select state"
                      item-text="label"
                      item-value="code"
                      v-model="state"
                      :rules="[isRequired()]"
                      validate-on-blur
                      v-if="supportedStates">
                    </v-select>
                    <DFButton :loading="interviewBinding" @click="adminBindInterview" >
                      Create
                    </DFButton>
                  </div>
                  <p v-if="!interviewBinding" class="dashboard__note">Note: this will replace interview on an existing account.</p>
                </fieldset>
              </form>
            </template>
          </div>

          <div class="dashboard__filters">
            <form>
              <fieldset class="dashboard__filters-fieldset">
                <legend class="dashboard__filters-legend">Filters:</legend>
                <div class="dashboard__input-wrapper mq-grid">
                  <v-select
                    class="cols-3"
                    placeholder="Filter by status"
                    :items="statusOptions"
                    return-object
                    hide-details
                    @change="filterResults($event, 'status')"
                    :disabled="loading"
                    ref="status"
                  />
                  <v-select
                    class="cols-3"
                    placeholder="Filter by account"
                    :items="accountOptions"
                    item-text="label"
                    item-value="value"
                    return-object
                    hide-details
                    @change="filterResults($event, 'account')"
                    :disabled="loading"
                    ref="account"
                  />
                  <DFButton :loading="csvExportLoading" v-on:click.native="exportCSV" >
                    Export CSV
                  </DFButton>
                </div>
              </fieldset>
            </form>
          </div>
          <div class="dashboard__search-wrapper">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="dashboard__search"
              @input="handleSearch"
            ></v-text-field>
          </div>
          <v-data-table
            :hide-default-footer="true"
            :headers="headers"
            :items="interviews"
            :loading="loading"
            :items-per-page="-1"
            width="100%"
            class="theme--primary"
            item-key="id + accountId"
          >
            <template  v-slot:[`item.interviewID`]="{ item }">
              <router-link
                :to="{
                  name: `admin-detail-${item.accountId ? 'account' : 'no-account'}`,
                  params: {id: item.interviewID, ...(item.accountId !== null && {accountId: item.accountId}) } }"
              >
                <span class="admin-dashboard__id-link-text">{{item.interviewID}}</span>
              </router-link>
            </template>
            <template  v-slot:[`item.applicant.firstName`]="{ item }">
              <tr>
                <td class='dashboard__table-column'>{{ item.applicant !== null ? item.applicant.firstName : '' }}</td>
              </tr>
            </template>
            <template v-slot:[`item.applicant.lastName`]="{ item }">
              <tr>
                <td class='dashboard__table-column'>{{ item.applicant !== null ? item.applicant.lastName : '' }}</td>
              </tr>
            </template>
            <template v-slot:[`item.created`]="{ value }">{{ moment(value).format("L LTS -Z") }}</template>
            <template v-slot:[`item.updated`]="{ value }">{{ moment(value).format("L LTS -Z") }}</template>
            <template v-slot:[`item.status`]="{ value }">{{ interviewStatusName(value) }}</template>
            <!--<template v-slot:[`item.currentSectionId`]="{ item }">{{ getCurrentSection(item) }}</template>-->
            <template v-slot:[`item.agentId`]="{ item }">{{ hasAgentID(item) }}</template>
            <template v-slot:[`item.state`]="{ item }">{{ item.state }}</template>
            <template v-slot:footer>
              <div class="dashboard__table-footer">
                <DFButton
                  v-on:click.native="clickAction"
                  :loading="loading"
                  :disabled="!isNext"
                >{{ loadMoreText }}</DFButton>
              </div>
            </template>
          </v-data-table>
        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import DFButton from '@/components/DFButton.vue'
import moment from 'moment'
import _debounce from 'lodash/debounce'
import { interviewStatusName, InterviewStatus, SectionID, QuestionKey as QK } from '@/api/Interview/constants.js'
import { titleCase } from '@/utils/helpers'
import { saveAs } from 'file-saver'
import { unparse } from 'papaparse'
import { PATTERN_EMAIL, isRequired } from '@/rules'
import { RouteName } from '@/router/routes/constants'
import { getAuth } from 'firebase/auth'

export default {
  name: 'dashboard',
  components: { FlowWrapper, DFButton },
  data () {
    return {
      Colors,
      email: '',
      invalidEmail: false,
      search: '',
      isNext: false,
      loadMoreText: 'Load More',
      pageIndex: 0,
      loading: false,
      interviews: [],
      QK,
      values: {},
      statuses: undefined,
      hasAccount: undefined,
      csvExportLoading: false,
      interviewBinding: false,
      hasError: false,
      errorMessage: '',
      showAdminCreate: true,
      state: undefined,
      supportedStates: null,
      accountOptions: [
        { label: 'All', value: undefined },
        { label: 'Has Account', value: true },
        { label: 'Does not have Account', value: false }
      ],
      cantEdit: ['SUBMITTED', 'ACCEPTED', 'REJECTED', 'ERROR', 'EXPIRED'],
      headers: [
        {
          text: 'First Name',
          value: 'applicant.firstName'
        },
        {
          text: 'Last Name',
          value: 'applicant.lastName'
        },
        {
          text: 'Interview ID',
          value: 'interviewID'
        },
        // {
        //   text: 'Section',
        //   value: 'currentSectionId'
        // },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Issuer',
          value: 'product.vendor'
        },
        {
          text: 'State',
          value: 'applicant.address.state'
        },
        {
          text: 'Created',
          value: 'created'
        },
        {
          text: 'Last Updated',
          value: 'updated'
        },
        // {
        //   text: 'IMO Lead',
        //   value: 'agentId'
        // },
        // {
        //   text: 'Variant',
        //   value: 'productVariant'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters('account', ['currentAccount']),
    ...mapGetters('interview', ['mapToAnswers']),
    statusOptions () {
      const options = { ...{ NONE: 'NONE' }, ...InterviewStatus }
      return Object.keys(options)
    }
  },
  methods: {
    ...mapActions('admin', ['getInterviews', 'getNextInterviews', 'querySupportedStates']),
    ...mapActions('interview', ['bindInterview', 'createInterview', 'updateInterviewAnswers', 'adminReplaceAccountInterview']),
    ...mapActions('account', ['getAccountWithEmail', 'accountInfo', 'createUnverifiedAccount']),
    ...mapGetters('admin', ['getAdminInterviews']),
    interviewStatusName,
    moment,
    isRequired,
    onFocus () {
      this.errorMessage = ''
      this.hasError = false
    },
    async adminBindInterview () {
      let account, accountId, interview, interviewId
      this.interviewBinding = true
      this.errorMessage = ''
      this.hasError = false
      if (PATTERN_EMAIL.test(this.email)) {
        try {
          const hasAccount = await this.accountInfo({ email: this.email })
          if (!hasAccount) {
            account = await this.createUnverifiedAccount({ email: this.email })
          } else {
            account = await this.getAccountWithEmail({ email: this.email })
          }
          accountId = account?.id
        } catch (e) {
          this.interviewBinding = false
          this.hasError = true
          this.errorMessage = `${e}`
        }
        if (accountId !== undefined && this.state !== undefined) {
          try {
            const bind = false
            interview = await this.createInterview({
              stateValue: this.state,
              bind
            })
            interviewId = interview?.id
          } catch (e) {
            this.errorMessage = `${e}`
          }
        }
        if (interviewId) {
          try {
            const didBind = await this.adminReplaceAccountInterview({ id: interviewId, accountId })
            if (didBind) {
              this.values[QK.EMAIL_ADDRESS] = this.email
              await this.updateInterviewAnswers({
                id: interviewId,
                answers: this.mapToAnswers(this.values, [QK.EMAIL_ADDRESS])
              })
              await this.$router.push({ name: RouteName.ADMIN_DETAIL_ACCOUNT, params: { accountId, id: interviewId } })
            } else {
              this.hasError = true
              this.errorMessage = 'An error occurred while binding the interview. Please try again.'
            }
          } catch (e) {
            this.errorMessage = `${e}`
          }
        }
      } else {
        this.invalidEmail = true
        this.errorMessage = 'Please enter a valid email address'
      }
      this.interviewBinding = false
    },
    getContact (item) {
      const { applicant: { emailAddress, phoneNumbers } } = item
      if (emailAddress !== null || phoneNumbers.length > 0) {
        return 'yes'
      }
      return 'no'
    },
    getCurrentSection (item) {
      return (item.currentSectionId && item.currentSectionId !== SectionID.UNDEFINED) ? titleCase(item.currentSectionId) : 'N/A'
    },
    hasAgentID (item) {
      return 'no'
    },
    getStatus (item) {
      // if it has any  NEEDS_REVIEW type flag with cause "READY_FOR_MANUAL_UNDERWRITING",
      // indicate it was referred to underwriter.
      // If it has any NEEDS_REVIEW type flag with cause "READY_FOR_CUSTOMER_SERVICE" indicate it
      // is referred to customer service.

      let customerService = false
      let manualUnderwriting = false
      if (item && 'flags' in item) {
        for (let i = 0; i < item.flags.length; i++) {
          const entry = item.flags[i]
          if (entry.type === 'NEEDS_REVIEW' && entry.cause === 'READY_FOR_CUSTOMER_SERVICE') {
            customerService = true
          } else if (entry.type === 'NEEDS_REVIEW' && entry.cause === 'READY_FOR_MANUAL_UNDERWRITING') {
            manualUnderwriting = true
          }
        }
        if (customerService && manualUnderwriting) {
          return 'Manual Underwriting & Customer Service'
        } else if (customerService) {
          return 'Customer Service'
        } else if (manualUnderwriting) {
          return 'Manual Underwriting'
        } else if (item.policyId && item.status === 'SUBMITTED') {
          return 'Needs Approval'
        }
      }
      return ''
    },
    async filterResults (e, type) {
      this.loading = true
      this.pageIndex = 0

      if (type === 'account') {
        this.hasAccount = e.value
      } else if (type === 'status') {
        if (e === 'NONE') {
          this.statuses = undefined
        } else {
          this.statuses = [e]
        }
      }

      const options = {
        hasAccount: this.hasAccount,
        offset: this.pageIndex,
        ...(this.statuses !== undefined && { statuses: this.statuses }),
        clear: true
      }
      await this.getInterviews(options)

      const nextInterviews = await this.getNextInterviews(options)

      this.isNext = nextInterviews !== undefined && nextInterviews.length !== 0

      this.interviews = await this.getAdminInterviews()

      this.loading = false
    },
    async clickAction () {
      if (this.loading === false) {
        this.pageIndex = Number(this.pageIndex) + 1
        this.setupTable({ offset: this.pageIndex, statuses: this.statuses })
      }
    },

    handleSearch: _debounce(async function (term) {
      this.pageIndex = 0
      this.setupTable({ offset: this.pageIndex, statuses: this.statuses, query: term, clear: true })
    }, 1000),

    async setupTable (options) {
      this.loading = true
      await this.getInterviews(options)
      const nextInterviews = await this.getNextInterviews(options)
      this.isNext = nextInterviews !== undefined && nextInterviews.length !== 0
      this.interviews = await this.getAdminInterviews()
      this.loading = false
    },
    exportCSV () {
      this.csvExportLoading = true
      const csv = unparse(
        this.interviews.map(i => {
          let row = {
            'Person Created Date': i.created,
            'Person Interview ID': i.interviewID,
            'Person Premium': i.quoteMonthlyPremium,
            'Person Admin Status': i.status,
            'Person Age': i.applicant.age,
            'Person First Name': i.applicant.firstName,
            'Person Last Name': i.applicant.lastName,
            'Person Email': i.applicant.emailAddress,
            'Person Phone': i.applicant.phoneNumbers.map(pn => pn.number).join(' '),
            'Person Income': i.applicant.annualIncome,
            'Person Sex': i.applicant.gender,
            'Person BMI': i.applicant.bmi,
            'Person Final Class': '',
            'Person Address': ''
          }

          if (i.riskScore) {
            row['Person Final Class'] = i.riskScore.class
          }

          if (i.applicant) {
            if (i.applicant.address) {
              row['Person Address'] = `${i.applicant.address.line1}, ${i.applicant.address.line2}, ${i.applicant.address.city}, ${i.applicant.address.state} ${i.applicant.address.postalCode}`
            }
          }
          return row
        })
      )
      let blob = new Blob([csv], {
        type: 'application/csvcharset=utf-8'
      })
      saveAs(blob, 'export.csv')
      this.csvExportLoading = false
    }
  },
  mounted () {
    const auth = getAuth()
    auth.currentUser.getIdToken(true)
  },
  async created () {
    this.supportedStates = await this.querySupportedStates()
    if (this.getAdminInterviews().length === 0) {
      this.setupTable({ offset: this.pageIndex, statuses: this.statuses })
    } else {
      this.interviews = await this.getAdminInterviews()
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/metrics";
@import "src/assets/styles/media-queries";

.dashboard {
  &__binding {
    font-size: 0.9rem;
    font-weight: bold;
  }
  &__note {
    font-size: 0.7rem;
    color: $c-action;
  }
  &__navigation {
    display: flex;
    justify-content: flex-end;
  }
  &__navigation-link {
    margin-left: 20px;
  }
  &__table-footer {
    display: flex;
    padding: 2rem 0;
    justify-content: center;
    align-items: center;
  }
  &__search-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  &__search{
    max-width: 200px;
    margin-bottom: 20px;
    flex: 1
  }
  &__filters-legend {
    color: $c-red;
    font-weight: bold
  }
  &__filters-fieldset {
    padding: 1rem;
    margin-bottom: 1rem;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  &__table-column {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.flow__content-wrapper .dashboard {
  &__account-list {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.text-start {
  color: $c-red;
}

::v-deep .flow__content-wrapper .theme--light.v-data-table {
  width: 100% !important;
  max-width: 100% !important;
  background-color: $c-eggshell;

  .v-select.table-input {
    font-size: 0.875rem !important;
    width: 120px;
  }
}

.admin-dashboard__id-link-text {
  font-family: "JetBrains Mono", monospace;
}
</style>

<style>
.v-data-table__wrapper th,
.v-data-table__wrapper td {
  padding: 0 6px !important;
}
</style>
