import { RouteName, RouteCategory } from '@/router/routes/constants.js'

export const getFlowStepInfo = (route) => {
  let num
  let totalSteps
  let stepInfo = {}

  let category

  const { name } = route

  if (route.meta.category) category = route.meta.category

  const routeMap = {
    [RouteName.CHECKOUT_CONFIRM_IDENTITY]: 1,
    [RouteName.FINALIZE_HEALTH_FOLLOWUP]: 1,
    [RouteName.FINALIZE_HEALTH_DIAGNOSIS]: 1,
    [RouteName.CHECKOUT_SIGNIN]: 1,
    [RouteName.CHECKOUT_PAYMENT]: 2,
    [RouteName.INTERVIEW_SUBMISSION]: 3,
    [RouteName.CHECKOUT_SIGN]: 3,
    [RouteName.INTERVIEW_UPDATE_PAYMENT]: 3
  }

  if (category === RouteCategory.INITIAL) {
    const { meta: { header: { title } } } = route
    stepInfo = { ...stepInfo, title }
  }

  if (category === RouteCategory.CHECKOUT) {
    totalSteps = 3

    num = routeMap[name]

    stepInfo = {
      ...stepInfo,
      title: route.meta.header.title || `Checkout`,
      step: num,
      totalSteps
    }
  }
  if (category === RouteCategory.CONFIRM_IDENTITY) {
    stepInfo = {
      ...stepInfo,
      title: 'One final detail.'
    }
  }
  if (category === RouteCategory.FINALIZE) {
    stepInfo = {
      ...stepInfo,
      title: 'Step: 7 of 8',
      step: 1,
      totalSteps: 1
    }
  }

  return stepInfo
}
