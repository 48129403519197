<template>
  <div class="interview--inline-options">
    <template>
      <v-checkbox
        v-for="o in options"
        v-bind="attrs"
        v-model="values"
        :key="o.value"
        :value="o.value"
        :label="o.text"
        hide-details
        @change="changed(o.value)"
        class="interview-question__checkbox"
        :readonly="readonly"
      />
      <v-checkbox
        label="None of the above"
        v-model="values"
        @change="changed('')"
        v-if="showNoneOption"
        ref="allowsNoneOption"
        value=""
        :readonly="readonly"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'OptionsAllowsNone',
  model: {
    prop: 'selected',
    event: 'input'
  },
  props: {
    options: Array,
    selected: [String, Array],
    allowsNoneOption: {
      type: Boolean,
      default: false
    },
    showNoneOption: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      values: []
    }
  },
  computed: {

    attrs () {
      const attrs = {}
      const vuetifyProps = ['hide-details']
      vuetifyProps.forEach((a) => {
        if (this.$attrs.hasOwnProperty(a)) {
          attrs[a] = this.$attrs[a]
        }
      })
      return attrs
    }
  },
  watch: {
    selected (val) {
      this.values = this.selected
    }
  },
  methods: {
    changed (val) {
      if (val !== '') {
        this.values = this.values.filter(v => v !== '')
      } else {
        this.values = this.values.filter(v => v === '')
      }
      this.$emit('input', this.values)
    }
  },
  created () {
    this.values = this.selected
  }

}
</script>
