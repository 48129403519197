<template>
  <div class="off-ramp-replacement">
    <FlowWrapper :show-buttons="false" :showActions="false" :show-progress="true" header-variant="red">
      <template v-slot:header-text> Policy Replacement </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          {{ title }}
        </div>
      </template>
      <template v-slot:content>
        <div class="off-ramp-replacement__wrapper">
          <div class="off-ramp-replacement__body">
            <p>
              We have received your request to replace your current policy with a Dayforward policy. We’ll be in touch
              within 24 hours with the necessary paperwork we need from you to make this happen. In the meantime, feel
              free to chat us or reach out at
              <a href="tel:+18884340228">1-888-434-0228</a> for any questions.
            </p>
            <DFButton class="off-ramp-replacement__button" @click="onButtonClick"> Contact Us </DFButton>
          </div>
        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import { mapActions, mapGetters } from 'vuex'
import { QuestionKey as QK } from '@/api/Interview'
import DFButton from '@/components/DFButton.vue'
import { InteractionType } from '@/api/Interview/constants.js'

export default {
  name: 'off-ramp-replacement',

  components: { FlowWrapper, DFButton },

  computed: {
    ...mapGetters('interview', ['answers']),
    ...mapGetters('interview', ['interviewID', 'interview']),

    firstName() {
      return this.answers[QK.FIRST_NAME]
    },

    title() {
      return this.firstName !== undefined
        ? `Thanks ${this.firstName}, you're all set here!`
        : "Thanks, you're all set here!"
    }
  },

  methods: {
    ...mapActions('interview', ['recordInteraction']),

    async onButtonClick() {
      await this.$router.push({ name: 'help' })
    }
  },
  async created() {
    if (this.interview) {
      try {
        await this.recordInteraction({
          interviewID: this.interviewID,
          actionType: InteractionType.NOTIFIED_LETS_CHAT
        })
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.off-ramp-replacement {
  min-height: 100vh;
  &__wrapper {
    min-height: 40vh;
  }

  &__button {
    margin-top: 2rem;
  }
}
</style>
