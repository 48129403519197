<template>
  <div class="two-column-image-card" :style="extraStyles">
    <div class="two-column-image-card__image">
      <LazyImage :src="image.fields.file.url" fit="cover"/>
    </div>
    <div class="two-column-image-card__content">
      <div class="two-column-image-card__content-inner">
        <TitleAndBody :title="this.title" :body="bodyHTML" animated :body-link-text="bodyLinkText" :body-link-url="bodyLinkUrl"/>
      </div>
    </div>
  </div>
</template>

<script>
import TitleAndBody from '@/components/text/TitleAndBody'
import LazyImage from '@/components/content/LazyImage.vue'
import { processRichText } from '@/api/Content'

export default {
  name: 'two-column-image-card',
  components: { LazyImage, TitleAndBody },
  props: {
    title: undefined,
    body: undefined,
    bodyLinkText: undefined,
    bodyLinkUrl: undefined,
    image: undefined,
    imagePosition: undefined
  },
  computed: {
    bodyHTML () {
      return processRichText(this, this.body)
    },
    extraStyles () {
      const styles = {}
      if (this.imagePosition && this.imagePosition === 'right' && this.$gtSm) {
        styles['flex-direction'] = 'row-reverse'
      }
      return styles
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/colors";
@import "src/assets/styles/media-queries";

.two-column-image-card {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;
  @include md {
    flex-direction: row;
    height: 600px;
    padding-bottom: 0;
  }
  @include lg {
    height: 600px;
  }
  &__image {
    width: 100%;
    height: 100vw;
    overflow: hidden;
    @include md {
      height: 100% !important;
      width: 50%;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: left;
    padding-top: 50px;
    @include md {
      padding-top: 0;
      height: 100%;
      width: 50%;
    }
    &-inner {
      width: 85%;
      text-align: left !important;
      @include md {
        width: 80%;
      }
      @include lg {
        width: 70%;
      }
    }
  }
}

::v-deep {
  .title-and-body__body {
    font-size: 1.4em;
    line-height: 1.2em;
    letter-spacing: -0.02em;
  }
}

</style>
