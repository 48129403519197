<template>
  <div :class="classes">
    <div class="contentful-accordion__holder">
      <div class="contentful-accordion__item" v-for="item in accordionItems" :key="item.key" >
        <div class="contentful-accordion__header">
          <a @click="toggleItem(item, $event)" href="#" :class="{ 'opened' : item.active }">
            <div class="contentful-accordion__header-content" tabindex="-1">
              <div class="contentful-accordion__header-text">
                <h2>{{item.title}}</h2>
              </div>
              <div class="contentful-accordion__header-accessory">
                <img src="@/assets/images/ic_plus.svg" width="30" height="30" v-if="!item.active">
                <img src="@/assets/images/ic_minus.svg" width="30" height="30" v-if="item.active">
              </div>
            </div>
          </a>
        </div>
        <div class="contentful-accordion__body" :class="{ 'opened' : item.active }">
          <ContentfulContentRenderer :content="item.body"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { processRichText } from '@/api/Content'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-accordion',
  components: { ContentfulContentRenderer },
  props: {
    entries: undefined
  },
  data () {
    return {
      accordionItems: undefined,
      classes: []
    }
  },
  methods: {
    toggleItem (item, event) {
      event.preventDefault()
      item.active = !item.active
    }
  },
  mounted () {
    const accordionItems = []
    this.entries.forEach((entry, i) => {
      accordionItems.push({
        key: `item${i}`,
        title: entry.fields.title,
        body: processRichText(this, entry.fields.body),
        active: false
      })
    })
    this.accordionItems = accordionItems
    this.classes = this.$attrs.classes || []
    this.classes.push('contentful-accordion')
  }
}
</script>

<style lang="scss" scoped>

@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.contentful-accordion {
  &.home-faq {
    .contentful-accordion__body {
      padding: 20px 0;
      ::v-deep p {
        margin-bottom: 10px !important;
      }
      ::v-deep a {
        color: $c-red !important;
      }
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }
  &__header {
    display: flex;
    flex-direction: row;
    font-family: 'Graphik', sans-serif;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.02em;
    color: $c-primary;
    width: 100%;
    border-bottom: 1px solid rgba($c-purple, 0.3);

    a {
      width: 100%;
      height: 100%;
      cursor: pointer;
      outline: none;
      color: $c-primary !important;
      border-bottom: unset !important;
      text-decoration: none;
      padding: 0 0 1rem;
    }
    a:focus > &-content {
      outline: 0.5px dotted #666;
    }
    a:focus {
      outline: none;
    }
    &-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      padding: 0 0.3rem;
      &:focus {
        outline: none;
      }
    }
    &-text {
      flex: 1;
      pointer-events: none;
    }
    &-accessory {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 3.5rem;
      pointer-events: none;
      padding-right: 0.5rem;
    }
  }
  &__body {
    margin: 0;
    padding: 0 0.3rem 1rem;
    display: none;
    &.opened {
      display: block;
    }
    ::v-deep a {
      text-decoration: none;
      color: $c-purple !important;
    }
  }
  .opened .contentful-accordion__header-content {
    .contentful-accordion__header-text {
      color: $c-red !important
    }
  }
}

</style>
