
import {
  ssAuthTokenKey
} from '@/config/app.config'
import { apolloClient, onLogout } from '@/config/apollo.config'

export const AuthModuleReasons = {
  SIGN_IN: 0,
  SAVE_PROGRESS: 1,
  CHECKOUT: 2,
  MANUAL_UNDERWRITING: 3
}

const ssAuthEmailKey = 'signin'

const authModule = {
  namespaced: true,

  state: {
    reason: null,
    error: null,
    accessToken: sessionStorage.getItem(ssAuthTokenKey),
    user: null,
    sessionLastChecked: undefined
  },

  getters: {
    isAuthenticated: (state) => {
      return !!state.accessToken
    },

    authReason: (state) => {
      return state.reason || AuthModuleReasons.SIGN_IN
    },

    accessToken: (state) => {
      return state.accessToken
    },

    isCodeSent: () => {
      return !!sessionStorage.getItem(ssAuthEmailKey)
    },

    authError: (state) => {
      return state.error
    }
  },

  actions: {

    async resetAuthFlow ({ commit }) {
      commit('CLEAR_STATE')
    },
    setAccessToken ({ commit }, accessToken) {
      commit('UPDATE_ACCESS_TOKEN', accessToken)
    },

    signOut ({ commit, dispatch }) {
      onLogout(apolloClient)
      // cleanup our own state
      commit('CLEAR_STATE')
      // cleanup the other stores
      dispatch('interview/onSignOut', null, { root: true })
      dispatch('account/onSignOut', null, { root: true })
      window.sessionStorage.clear()
    },

    setReason ({ commit }, { authReason }) {
      commit('SET_REASON', authReason)
    }
  },

  mutations: {
    UPDATE_ACCESS_TOKEN (state, accessToken) {
      state.accessToken = accessToken
      sessionStorage.setItem(ssAuthTokenKey, accessToken)
      sessionStorage.removeItem(ssAuthEmailKey)
      state.error = null
      state.sessionLastChecked = Date.now()
    },

    SET_REASON (state, reason) {
      state.reason = reason
    },

    CLEAR_STATE (state) {
      sessionStorage.removeItem(ssAuthTokenKey)
      sessionStorage.removeItem(ssAuthEmailKey)
      state.error = null
      state.reason = null
      state.accessToken = null
      state.user = null
      state.sessionLastChecked = undefined
    }
  }
}

export default authModule
