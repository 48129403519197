<template>
  <div>Notes - coming soon</div>
</template>

<script>
export default {
  name: 'admin-notes',
  data () {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
</style>
