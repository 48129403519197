<template>
  <div class="content-modal">
    <ContentfulContentRenderer :content="bodyText" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { processContentfulDocument } from '@/api/Content.js'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'privacy-policy-modal',
  components: { ContentfulContentRenderer },
  data: function () {
    return {
      entry: undefined,
      document: undefined
    }
  },
  computed: {
    bodyText() {
      return !this.document ? undefined : this.document.bodyParsed
    }
  },
  methods: {
    ...mapActions('content', ['fetchContentfulEntryOrCache'])
  },
  async mounted() {
    try {
      this.entry = await this.fetchContentfulEntryOrCache({
        id: '6lNWdMQ3qcl7HwnpEr3mun'
      })
      this.document = processContentfulDocument(this, this.entry)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';
.content-modal {
  @include lg {
    min-height: 500px;
  }
}
</style>
