<template>
  <div class="interview">
    <router-view v-slot="{ Component }">
      <div class="interview__loading" v-if="showLoader">
        <FullScreenLoader :header-text="headerText" subtitle="One moment please ..." :progress="0" />
      </div>
      <component :is="Component" v-else />
    </router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FullScreenLoader from '@/components/loading/FullScreenLoader.vue'
import { PolicyStatus } from '@/api/Policy.js'
import { RouteName } from '@/router/routes/constants.js'
import { InterviewStatus } from '@/api/Interview'
import { getAuth } from 'firebase/auth'
import { ssAuthTokenKey } from '@/config/app.config'

export default {
  name: 'interview',
  components: { FullScreenLoader },
  data: function () {
    return {
      error: undefined,
      isCreatingInterview: false,
      isLoadingInterview: false,
      isCheckingAccount: false,
      isCheckingStatus: false,
      submitted: false,
      hasFetchedAccount: false
    }
  },
  computed: {
    ...mapGetters('account', ['currentAccount']),
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('policy', ['currentPolicy']),
    ...mapGetters('interview', ['interview', 'interviewID', 'hasSavedInterview']),
    ...mapGetters('backendAnalytics', ['userID']),
    headerText() {
      const callbackheader = sessionStorage.getItem('callbackheader')
      return callbackheader !== undefined ? callbackheader : 'Hello'
    },
    showLoader() {
      if (this.isLoadingInterview) {
        return true
      }
      // TODO: someday move this to the flow wrapper so we can use it universally
      return (
        (this.isCheckingAccount && this.$route.name !== 'interview-payment') ||
        (this.isCreatingInterview && this.$route.name !== 'get-started') ||
        this.isCheckingStatus
      )
    },
    hasError() {
      return !!this.error
    }
  },
  methods: {
    ...mapActions('account', ['getAccount', 'getInterviews']),
    ...mapActions('auth', ['setReason', 'setAccessToken']),
    ...mapActions('interview', [
      'reloadInterview',
      'updateInterviewAnswers',
      'updateEditMode',
      'setInterviewMetadata',
      'updateInterview'
    ]),
    ...mapActions('policy', ['getAccountPolicies']),
    ...mapGetters('auth', ['authReason']),

    async saveProgress() {
      const save = this.$refs.currentStep.save
      if (typeof save === 'function') {
        await save()
        if (!this.isAuthenticated) {
          await this.$router.push({ name: 'save-progress' })
        }
      }
    },
    reload() {
      this.$router.go(0)
    },
    async fetchInterview() {
      let hasValidInterview = this.hasSavedInterview && !this.hasExpired
      if (hasValidInterview) {
        // reload the interview in the background to refresh it
        const interview = await this.reloadInterview({
          id: this.interviewID,
          fetchAnswers: true
        })
        // eslint-disable-next-line no-console
        console.info(`loaded interview. ${interview.id}`)
        if (this.$route.name !== RouteName.INTERVIEW_REVIEW_PENDING && interview.status === InterviewStatus.SUBMITTED) {
          await this.$router.push({ name: RouteName.INTERVIEW_REVIEW_PENDING })
        }
      }
    },

    async checkGotoDashboard() {
      const account = await this.getAccount()
      if (!account) {
        return false
      }
      const policies = await this.getAccountPolicies(account.id)
      if (policies && policies.length > 0) {
        const policy = policies[0]
        return this.gotoDashboardIfValidPolicy(policy)
      }
      return false
    },

    async gotoDashboardIfValidPolicy(policy) {
      const policyNeedsApproval = policy.flags && policy.flags.indexOf('NEEDS_APPROVAL') !== 0
      if (
        this.$route.name !== RouteName.INTERVIEW_CONGRATS &&
        this.$route.name !== RouteName.INTERVIEW_REVIEW_PENDING &&
        (policy.status === PolicyStatus.IN_GOOD_STANDING || policyNeedsApproval)
      ) {
        await this.$router.push({ name: 'dashboard' })
        return true
      }
      return false
    },

    checkToken() {
      // check to see if there is a token in session and set it in the store if there is
      if (this.$store.state.auth.accessToken === null) {
        const accessToken = sessionStorage.getItem(ssAuthTokenKey)
        if (accessToken !== undefined) {
          this.setAccessToken(accessToken)
        }
      }
    },
    async getAccountInterview() {
      if (this.hasFetchedAccount === false) {
        const account = await this.getAccount()
        const interviews = account?.interviews
        if (interviews && interviews?.length > 0) {
          const id = interviews[0].id
          await this.reloadInterview({ id, fetchAnswers: true })
        }
        this.hasFetchedAccount = true
      }
    }
  },
  async updated() {
    if (this.isAuthenticated && this.currentPolicy) {
      await this.gotoDashboardIfValidPolicy(this.currentPolicy)
    }
  },

  async mounted() {
    const auth = await getAuth()
    this.checkToken()

    if (auth.currentUser) {
      this.isLoadingInterview = true
      await this.getAccountInterview()
      this.isLoadingInterview = false
    }

    if (this.interview) {
      this.isCheckingStatus = true
      if (
        this.$route.name !== RouteName.INTERVIEW_REVIEW_PENDING &&
        this.interview.status === InterviewStatus.SUBMITTED
      ) {
        await this.$router.push({ name: RouteName.INTERVIEW_REVIEW_PENDING })
        this.isCheckingStatus = false
        return
      }
      this.isCheckingStatus = false
    }
    if (this.isAuthenticated) {
      this.isCheckingAccount = true
      await this.checkGotoDashboard()
      this.isCheckingAccount = false
    }

    this.fetchInterview() // note: this happens in the background (don't await)
  }
}
</script>
