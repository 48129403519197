<template>
  <div :id="elementId" class="contentful-title df-title" :class="classes"
       :style="styles" v-observe-visibility="onVisible">
    <template v-if="animated">
      <transition name="title-fade">
        <div v-show="isVisible">{{ title }}</div>
      </transition>
    </template>
    <template v-else>
      <div>{{ title }}</div>
    </template>
  </div>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility'

export default {
  name: 'contentful-title',
  directives: {
    'observe-visibility': ObserveVisibility
  },
  props: {
    title: undefined,
    classes: undefined,
    styles: undefined,
    elementId: undefined,
    animated: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isVisible: !this.animated
    }
  },
  methods: {
    onVisible (isVisible) {
      if (this.animated && !this.isVisible && isVisible) {
        this.isVisible = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.contentful-title {
  margin-top: 2rem;
  margin-bottom: 1.4rem;
  letter-spacing: -0.03em;
}

.title-fade-enter-active, .title-fade-leave-active {
  transition: all .7s cubic-bezier(0.32, 1, 0.36, 1);
}

.title-fade-enter-active {
  transition-delay: .12s;
}

.title-fade-enter, .title-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

</style>
