<template>
  <div>
    <FlowWrapper :progress="1" :show-actions="false" :show-buttons="false" :hide-menu="false" header-variant="red">
      <template v-slot:header-text> Coverage </template>
      <template v-slot:content-header>
        <div class="df-subtitle-text--small policy__content-header">
          {{ policyHeader }}
        </div>
      </template>
      <template v-slot:content>
        <div class="policy">
          <section class="policy__section">
            <div class="policy__section-heading">
              <div class="policy__section-heading--text">Your family's coverage</div>
            </div>
            <PolicyItem
              item="Income protection"
              :description="termDescription"
              :value="`$${policy.semiMonthlyBenefit.toLocaleString()}`"
            />
            <PolicyItem item="Coverage term" :description="termDescription" :value="policyEndDate" />
            <PolicyItem
              item="One time payment"
              description="To pay for funeral and other end-of-life expenses."
              value="$10,000"
            />
            <PolicyAction
              :has-button="true"
              :button-action="downloadPolicy"
              button-text="Download Policy"
              :call-active="callActive"
              :chat-active="chatActive"
            />
          </section>
          <section class="policy__section">
            <div class="policy__section-heading">
              <div class="policy__section-heading--text">Your family</div>
            </div>
            <PolicyItem item="Beneficiary" :description="beneficiaryNames" />
            <PolicyItem
              item="Insured and policy owner"
              :description="policyHolderName"
              :descriptionLine2="formatAddress(policy.holder.address)"
            />
            <PolicyItem v-if="hasChildren" item="Youngest child" :description="`Born: ${youngestChildsBirthday}`" />
            <PolicyAction
              action-text="Want to change your family information?"
              :call-active="callActive"
              :chat-active="chatActive"
            />
          </section>
          <section class="policy__section">
            <div class="policy__section-heading">
              <div class="policy__section-heading--text">Subscription fees</div>
            </div>
            <PolicyItem item="Monthly fee" :value="`$${policy.monthlyPremium}`" />
            <PolicyItem item="Next payment due" :description="paymentDateText" :value="nextPaymentDate" />
            <PolicyItem
              item="Payment method"
              description="Monthly autopay"
              value="Credit card"
              :value-line2="cardString"
              :has-error="hasError"
            />
            <PolicyAction
              v-if="showEditPaymentButton && !showCreditCardInput"
              :has-button="true"
              :button-action="editPaymentMethod"
              button-text="Edit payment method"
              :show-contact="false"
            />
            <div v-if="showCreditCardInput">
              <CreditCardInput
                hide-title
                forceCapture
                :existingCreditCards="creditCards"
                v-on:succeeded="paymentUpdated"
                v-on:saving="paymentSaving"
                v-on:canceled="paymentUpdated"
                v-on:failed="paymentFailed"
                v-on:deleted="paymentUpdated"
              />
            </div>
          </section>
          <section class="policy__section">
            <div class="policy__section-heading">
              <div class="policy__section-heading--text">Contact us</div>
            </div>
            <div>We are here to help!</div>
            <div class="policy__contact">
              <div v-if="chatActive">
                <a class="policy__contact--link" @click="openChat">Live chat</a>
              </div>
              <div v-if="callActive">
                <a class="policy__contact--link" href="tel:+18884340228">1-888-434-0228</a>
              </div>
              <div class="policy__contact--item">
                <a href="mailto:help@dayforward.com">help@dayforward.com</a>
              </div>
            </div>
          </section>
        </div>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import PolicyItem from '@/components/user/PolicyItem'
import PolicyAction from '@/components/user/PolicyAction'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import { QuestionKey as QK, BeneficiaryRelationshipReadable } from '@/api/Interview'
import CreditCardInput from '@/components/inputs/CreditCardInput'

export default {
  name: 'PolicyDashboard',

  components: { PolicyItem, PolicyAction, CreditCardInput, FlowWrapper },

  data() {
    return {
      values: {},
      questionKeys: [QK.HAVE_CHILDREN, QK.YOUNGEST_CHILDS_BIRTHDAY],
      hasChildren: false,
      creditCards: [],
      showCreditCardInput: false,
      showEditPaymentButton: true,
      isSavingPayment: false,
      last4: '',
      hasError: false,
      isMobile: /Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent)
    }
  },

  props: {
    policy: {
      type: Object,
      required: true
    },
    showEditPayment: {
      type: Boolean,
      default: false
    },
    interview: {
      type: Object
    }
  },

  computed: {
    ...mapGetters('interview', ['answersForKeys', 'parseBool']),

    policyHeader() {
      const firstName = this.firstName || 'Hi there'
      const coveredIncome = `$${this.policy.coveredIncome.toLocaleString()}` || ''
      return `${firstName}, your coverage is active and your ${coveredIncome} income is protected.`
    },

    firstName() {
      let name = ''
      const { firstName } = this.policy.holder
      if (firstName) {
        name += firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase()
      }
      return name
    },

    termDescription() {
      const termMonthsRemainderText =
        this.termMonthsRemainder !== 0
          ? `and ${this.termMonthsRemainder} ${this.termMonthsRemainder === 1 ? 'month' : 'months'}`
          : ''
      return this.hasChildren
        ? `Payments twice a month for ${this.termYears}
        years ${termMonthsRemainderText}, when your youngest child is ${this.childAgeAtTermEnd}.`
        : `Payments twice a month for ${this.termYears} years ${termMonthsRemainderText}.`
    },

    policyEndDate() {
      return moment(this.policy.endDate).format('MM/DD/YYYY')
    },

    termMonths() {
      const start = moment(this.policy.startDate)
      const end = moment(this.policy.endDate)
      return end.diff(start, 'months')
    },

    termYears() {
      return Math.floor(this.termMonths / 12)
    },

    termMonthsRemainder() {
      return this.termMonths % 12
    },

    childAgeAtTermEnd() {
      const childAge = moment().diff(moment(this.values[QK.YOUNGEST_CHILDS_BIRTHDAY]), 'years')
      return childAge + this.termYears
    },

    callActive() {
      // TODO: add logic toggling this on/off with Customer Service availability
      return true
    },

    chatActive() {
      // TODO: likewise, add logic to toggle on/off with chat availability
      return true
    },

    youngestChildsBirthday() {
      const fmt = this.isMobile ? 'YYYY-MM' : 'MM/YYYY'
      const m = moment(this.values[QK.YOUNGEST_CHILDS_BIRTHDAY], fmt)
      if (m.isValid()) {
        return m.format(fmt)
      } else {
        return moment(this.values[QK.YOUNGEST_CHILDS_BIRTHDAY]).format(fmt)
      }
    },

    beneficiaryNames() {
      const { relationship, person, trust } = this.policy.beneficiaries[0]
      if (person) {
        return this.policy.beneficiaries.map((b) => {
          return {
            line1: b.person.firstName + ' ' + b.person.lastName,
            line2: this.formatAddress(b.person.address)
          }
        })
      } else if (trust) {
        return [
          {
            line1: BeneficiaryRelationshipReadable[relationship],
            line2: trust.name
          }
        ]
      } else {
        return BeneficiaryRelationshipReadable[relationship]
      }
    },

    policyHolderName() {
      return this.policy.holder.firstName + ' ' + this.policy.holder.lastName
    },

    nextPaymentDate() {
      return moment(this.policy.startDate).add(1, 'months').calendar()
    },

    paymentDateText() {
      const dateOrdinal = moment(this.policy.startDate).format('Do')
      return `Payment is due each month on the ${dateOrdinal}.`
    },

    cardString() {
      if (this.isSavingPayment) return 'Saving ...'
      if (this.creditCards && this.creditCards.length) {
        return `Card ending ${this.last4}`
      }
      return ''
    }
  },

  methods: {
    ...mapActions('policy', ['getPolicyDownloadURL']),
    ...mapActions('billing', ['getCreditCards']),
    ...mapActions('interview', ['reloadInterview']),

    async downloadPolicy() {
      const url = await this.getPolicyDownloadURL(this.policy.id)
      if (url) {
        window.location.href = url
      }
    },

    async openChat() {
      await this.$router.push({ name: 'help' })
    },

    paymentFailed() {
      this.hasError = true
    },

    paymentSaving() {
      this.isSavingPayment = true
    },

    async paymentUpdated(data = {}) {
      this.hasError = false
      this.showCreditCardInput = false
      this.creditCards = await this.getCreditCards()
      const { last4 } = data
      if (last4 !== undefined) this.last4 = last4
      this.isSavingPayment = false
      this.showEditPaymentButton = true
    },

    formatAddress(address) {
      if (!address) return ''
      return `${address.line1}, ${address.city}, ${address.state} ${address.postalCode}`
    },

    editPaymentMethod() {
      this.showCreditCardInput = true
      this.showEditPaymentButton = false
    }
  },

  async created() {
    if (!this.interview) {
      await this.reloadInterview({ id: this.policy.interviewID })
    }
    this.values = this.answersForKeys(this.questionKeys) || {}
    if (this.parseBool(this.values[QK.HAVE_CHILDREN])) {
      this.hasChildren = true
    }
    this.creditCards = (await this.getCreditCards()) || []
    if (this.creditCards && this.creditCards.length) {
      this.last4 = this.creditCards[0].last4
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/titles';
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/metrics';

.policy {
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem;

  &__content-header {
    margin-top: -2rem;
    margin-bottom: 2.5rem;
    @include md() {
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }

  &__section {
    margin-bottom: 3rem;
    @include md() {
      margin-top: 1rem;
    }
  }

  &__section-heading {
    margin-bottom: 2.25rem;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba($c-primary, 0.3);
    &--text {
      @extend .df-subtitle-text--small;
      flex: 1;
      color: $c-action;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    @include md() {
      flex-direction: row;
      align-items: center;
    }
    margin-top: 1rem;
    margin-bottom: 5rem;
    // line-height: 2.5rem;

    &--link {
      color: $c-action;
      text-decoration: underline;
      margin-right: 20px;
    }

    &--item {
      color: $c-action;
    }
  }
}
</style>
