<template>
  <div class="contentful__video-card">
    <div class="contentful__video-card-video">
      <VideoDisplayWrapper
        :videoId="videoId"
        :thubmail="true"
        :thumbnailDesktop="true"
        :modalDesktop="true"
      />
    </div>
    <div class="contentful__video-card-description">
      <ContentfulContentRenderer :content="processedDescription" />
    </div>
  </div>
</template>

<script>

import { processRichText } from '@/api/Content'
import VideoDisplayWrapper from '@/components/content/VideoDisplayWrapper'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-video-card',

  components: { ContentfulContentRenderer, VideoDisplayWrapper },

  props: {
    title: undefined,
    videoId: undefined,
    description: undefined
  },

  computed: {
    processedDescription () {
      return processRichText(this, this.description)
    }
  }
}
</script>

<style scoped lang="scss">

.contentful__video-card {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .contentful__video-card-description {
    margin: 1rem 0 2rem 0;
  }
}

.contentful__video-card-title {
  font-weight: bolder;
}

::v-deep {
  p {
    margin: 0 !important;
  }
}
</style>
