<template>
  <div class="history">
    <div class="history__policy-history-wrapper">
      <div class="history__answer-policy" v-if="statusHistory && statusHistory.length > 0">
        <h3>Status History</h3>
        <div class="history__answer-history">
        <v-data-table
          :hide-default-footer="true"
          :headers="statusHeaders"
          :items="statusHistory"
          :items-per-page="-1"
          :search="statusSearch"
          width="100%"
          class="theme--primary"
          item-key="item.time + index"
        >
          <template v-slot:top>
            <v-text-field
              v-model="statusSearch"
              label="Search"
              class="history__search"
            />
          </template>
          <template v-slot:[`item.time`]="{ value }">{{ moment(value).format("L LTS -Z") }}</template>
          <template v-slot:[`item.status`]="{ value }">
            {{ interviewStatusName(value) }}
          </template>
        </v-data-table>
        </div>
      </div>
     <div class="history__answer-policy" v-if="interactions && interactions.length > 0">
      <h3>Interaction History</h3>
       <div class="history__answer-history">
        <v-data-table
          :hide-default-footer="true"
          :headers="interactionHeaders"
          :items="interactions"
          :items-per-page="-1"
          :search="interactionSearch"
          width="100%"
          class="theme--primary"
          item-key="item.time + index"
        >
          <template v-slot:top>
            <v-text-field
              v-model="interactionSearch"
              label="Search"
              class="history__search"
            />
          </template>
          <template v-slot:[`item.time`]="{ value }">{{ moment(value).format("L LTS -Z") }}</template>
          <template v-slot:[`item.type`]="{ value }">
            {{ sentenceCase(value) }}
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="history__answer-policy" v-if="answerHistory && answerHistory.length > 0">
      <h3>Answer History</h3>
      <div class="history__answer-history">
        <v-data-table
            :hide-default-footer="true"
            :headers="answerHeaders"
            :items="answerHistory"
            :items-per-page="-1"
            :search="answerSearch"
            width="100%"
            class="theme--primary"
            item-key="item.time"
          >
          <template v-slot:top>
            <v-text-field
              v-model="answerSearch"
              label="Search"
              class="history__search"
            />
          </template>
          <template v-slot:[`item.time`]="{ value }">{{ moment(value).format("L LTS -Z") }}</template>
          <template v-slot:[`item.answer.key`]="{ value }">
            {{sentenceCase(value)}}
          </template>
          <template v-slot:[`item.answer.values`]="{ item }">
              <p v-if="item.answer.key !== 'OCCUPATION'">
                {{ getHistoryValues(item.answer.values) }}
              </p>
               <AdminOccupation v-else-if="item.answer.values && item.answer.values.length > 0" :item="item" :occupationCode="item.answer.values[0]" />
          </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { sentenceCase } from '@/utils/helpers'
import AdminOccupation from './AdminOccupation'
import { interviewStatusName } from '@/api/Interview/constants'
export default {
  name: 'admin-history',
  components: {
    AdminOccupation
  },
  props: {
  },
  data () {
    return {
      answerSearch: undefined,
      answerHeaders: [
        {
          text: 'Date',
          value: 'time'
        },
        {
          text: 'Author',
          value: 'answer.author.displayName'
        },
        {
          text: 'Question',
          value: 'answer.key'
        },

        {
          text: 'Answer',
          value: 'answer.values'
        }
      ],
      statusSearch: undefined,
      statusHeaders: [
        {
          text: 'Date',
          value: 'time'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ],
      interactionSearch: undefined,
      interactionHeaders: [
        {
          text: 'Date',
          value: 'time'
        },
        {
          text: 'Event',
          value: 'type'
        }
      ]
    }
  },
  computed: {
    answerHistory () {
      return this.$store.state.admin.currentInterview.interview.answerHistory || undefined
    },
    statusHistory () {
      return this.$store.state.admin.currentInterview.interview.statusHistory || undefined
    },
    interactions () {
      return this.$store.state.admin.currentInterview.interview.interactions || undefined
    }
  },
  methods: {
    moment,
    interviewStatusName,
    sentenceCase,
    getHistoryValues (value) {
      if (value !== null && value !== undefined) {
        return value.toString().replace(/_/g, ' ').toLowerCase()
      }
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
.history {
  &__search {
    margin-top: 30px;
  }
  &__answer-policy {
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 20px;
    margin-top: 2em;
  }
  &__answer-history, &__status-history {
    ::v-deep .theme--light.v-data-table {
      width: 100% !important;
      max-width: 100% !important;
      background-color: $c-eggshell;

      .v-select.table-input {
        font-size: 0.875rem !important;
        width: 120px;
      }
    }
  }
  &__status-history-container {
    display: grid;
    font-size: 14px;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    div {
      flex: 1 25%;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  &__policy-history {
    padding-bottom: 2rem;
    h1 {
      color: $c-red;
      margin-bottom: 1rem;
    }
  }
  &__policy-history-wrapper {
    /* border: thin solid rgba(0, 0, 0, 0.12); */
    border: none;
    padding: 20px;
  }
}
</style>
