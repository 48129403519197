<template>
  <div class="contentful-document">
    <slot name="loading" v-if="isLoading"> </slot>
    <FlowWrapper
      v-else
      v-on="listeners"
      v-bind="attrs"
      full-width
      :show-buttons="attrs['show-buttons'] || false"
      :show-actions="showActions"
    >
      <template v-if="showActions" v-slot:actions>
        <router-link class="back-link" :to="backTo">{{ actionText }}</router-link>
      </template>
      <template v-slot:header-text>
        <slot name="header-text" :title="title">{{ title }}</slot>
      </template>
      <template v-slot:content-header>
        <slot name="content-header">
          <ContentfulEmphasizedHeadline v-if="contentTitle" :text="contentTitle.data.text" />
        </slot>
      </template>
      <template v-slot:content>
        <slot v-if="entry" name="content" :entry="entry" :elements="elements" :document="document"></slot>
        <slot v-else name="no-content"></slot>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import { mapActions } from 'vuex'
import { getEntityType, processContentfulDocument, processContentfulStructuredDoc } from '@/api/Content.js'
import ContentfulEmphasizedHeadline from '@/components/contentful/ContentfulEmphasizedHeadline.vue'
import { RouteName } from '@/router/routes/constants'

export default {
  name: 'contentful-document',
  components: { ContentfulEmphasizedHeadline, FlowWrapper },
  props: {
    contentId: {
      type: String,
      required: false,
      default: undefined
    },
    contentEntry: {
      type: String,
      required: false,
      default: undefined
    },
    showActions: {
      type: Boolean,
      default: false
    },
    actionText: {
      type: String,
      default: ''
    },
    backTo: {
      type: String,
      default: ''
    },
    renderOptions: undefined
  },
  data: function () {
    return {
      isLoading: true,
      entry: undefined,
      title: undefined,
      contentTitle: undefined,
      elements: undefined,
      document: undefined,
      slug: undefined
    }
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs() {
      const { rows, ...attrs } = this.$attrs
      return attrs
    }
  },
  methods: {
    ...mapActions('content', ['fetchContentfulEntryOrCache', 'fetchContentfulEntryBySlug'])
  },

  async mounted() {
    this.isLoading = true
    // load the content or pull the value from the content cache
    if (this.contentId) {
      this.entry = await this.fetchContentfulEntryOrCache({
        id: this.contentId
      })
    } else if (this.contentEntry) {
      const entries = await this.fetchContentfulEntryBySlug({
        slug: this.contentEntry
      })
      this.entry = entries.items[0]
    }
    if (this.contentId || this.contentEntry) {
      const docType = getEntityType(this.entry)
      const options = {
        render: this.renderOptions
      }
      if (docType === 'document') {
        this.document = processContentfulDocument(this, this.entry)
        if (this.document) {
          this.title = this.document.title
          this.contentTitle = this.document.contentTitle
        }
      } else if (docType === 'structuredDocument') {
        this.document = processContentfulStructuredDoc(this.entry, options)
        if (this.document) {
          this.title = this.document.title
          this.elements = this.document.elements
        }
      } else if (docType === 'pressRelease') {
        this.document = processContentfulDocument(this, this.entry)
        if (this.document) {
          this.title = 'Press.'
          this.elements = [
            {
              data: { ...this.document },
              id: this.contentId,
              key: this.contentId,
              type: docType
            }
          ]
        }
      } else {
        await this.$router.push({ name: RouteName.ERROR })
      }
    } else {
      await this.$router.push({ name: RouteName.ERROR })
    }
    this.isLoading = false
  },

  updated() {
    this.$emit('content-loaded')
  }
}
</script>

<style scoped lang="scss">
::v-deep p {
  margin-top: 20px;
}

.back-link {
  text-align: right;
  display: block;
}
</style>
