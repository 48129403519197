import { createClient } from 'contentful'

const contentKeys = {
  HOME: 'home',
  GET_STARTED: 'get-started',
  INTERVIEW_INITIAL: 'interview-initial',
  ABOUT: 'about-us',
  WHY_DAYFORWARD: 'why-dayforward',
  HOW_IT_WORKS: 'how-it-works',
  PRICING: 'pricing'
}

const contentIDs = {
  [contentKeys.HOME]: 'wBp7cpwWdx0XRVbQzOHX6',
  [contentKeys.GET_STARTED]: '5oSoJFtpAM9sxh4CW6oHt4',
  [contentKeys.INTERVIEW_INITIAL]: '6lNWdMQ3qcl7HwnpEr3mun',
  [contentKeys.ABOUT]: '2L3yn433j5b4MXAclzI94s',
  [contentKeys.WHY_DAYFORWARD]: '54ynkPIkhI9jiR30T7Nb3X',
  [contentKeys.HOW_IT_WORKS]: '8Zz4tXC9buTEDoBLOep1U',
  [contentKeys.PRICING]: '2W5I75pfRTe5YPfGGBXmOU'
}

const contentfulConfig = {
  space: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
  environment: process.env.VUE_APP_CONTENTFUL_ENV,
  accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
  resolveLinks: true
}

function contentIDForKey (key) {
  return contentIDs[key]
}

const contentful = createClient(contentfulConfig)
const contentfulPreview = createClient({
  ...contentfulConfig,
  accessToken: process.env.VUE_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  host: 'preview.contentful.com'
})

export { contentful, contentfulPreview, contentKeys, contentIDForKey }
