<template>
  <v-btn
    elevation="0"
    :class="buttonClass"
    :min-width="$gtSm ? '130px' : '100%'"
    v-on="listeners"
    v-bind="attrs"
    :loading='isLoading'
    >
    <div :class="contentClass">
      <slot></slot>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: 'df-button',
  props: {
    variant: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClass () {
      if (this.variant && this.variant.length > 0) {
        return [`df-button--${this.variant}`]
      }
      return [ `df-button` ]
    },
    contentClass () {
      if (this.variant && this.variant.length > 0) {
        return [ `df-button--${this.variant}__content` ]
      }
      return [ `df-button__content` ]
    },
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    attrs () {
      const { rows, ...attrs } = this.$attrs
      return attrs
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global";

$df-button-border-radius: 6px;

.df-button {
  min-height: 64px !important;
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  font-weight: 400;
  background-color: $c-action !important;
  color: #fff !important;
  border-radius: $df-button-border-radius;
  &:hover, &:focus {
    background-color: $c-action-dark !important;
  }
  &--light {
    background-color: $c-eggshell !important;
    color: $c-action !important;
    border: 1px solid $c-action;
    border-radius: $df-button-border-radius;
    &__content {
      color: $c-red !important;
    }
  }
  &--small {
    min-height: 32px !important;
    color: $c-action !important;
    background-color: $c-eggshell !important;
    border: 1px solid $c-action;
    border-radius: $df-button-border-radius;
  }
  &--blue {
    background-color: $c-blue !important;
    color: #fff !important;
    border-radius: $df-button-border-radius;
    &__content {
      color: #fff !important;
    }
  }
  &__content {
    margin-top: 2px;
    color: #fff;
  }
}

</style>
