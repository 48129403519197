<template>
  <div class="decisions">
    <div class="decision__detail">
      <h3>Decision detail</h3>
      <p class="decision__status">
        <strong>Interview Status: </strong
        >{{ removeUnderscores(interview.status) }}
      </p>
        <p v-if="'riskScore' in interview && interview.riskScore !== null && 'class' in interview.riskScore">
          <strong>Risk Score Class:</strong>
          {{
            titleCase(interview.riskScore.class)
          }}
        </p>
        <p v-if="'riskScore' in interview && interview.riskScore !== null && 'origin' in interview.riskScore">
          <strong>Risk Score Origin:</strong>
          {{ titleCase(interview.riskScore.origin) }}
        </p>
      <p v-if="quote && 'termReason' in quote">
        <strong>Quote term:</strong>
        {{ quote.termReason.description }}
      </p>
      <p v-if="quote && 'benefitAmount' in quote">
        <strong>Semi-monthly benefit amount</strong>
        {{ formatCurrency(quote.benefitAmount) }}
      </p>
    </div>
    <div class="decision__CTA mq-grid" v-if="status !== 'ACCEPTED'">
      <div class="decision__risk-score cols-5"  v-if="showInput(riskStatuses)">
        <RiskScoreSelect
          :id="id"
          :accountId="accountId"
          @scoreChanged="getFullInterview"
        />
      </div>
      <div class="customer-detail__decline-button cols-5" v-if="status === 'SUBMITTED' || status === 'REJECTED'">
        <label
          >Refuse approval and assign risk score to copy of interview</label
        >
        <v-select
          item-value="value"
          item-text="label"
          :items="riskItems"
          :value="getScoreValue(interview.riskScore)"
          class="table-input"
          @change="handleInputChange($event)"
          hide-details
        ></v-select>
      </div>
      <div class="approve-button cols-5" v-if="status === 'SUBMITTED' || status === 'REJECTED'">
        <DFButton elevation="0" @click="onPolicyApproval" variant="small">
          Approve Policy
        </DFButton>
      </div>
      <div class="decision__resolve-button cols-5" v-if="'fingerprintGroup' in interview && interview.fingerprintGroup !== null && showInput(resolveStatuses)">
        <DFButton
          variant="small"
          elevation="0"
          @click="onResolveDuplicates"
          :loading="loading"
        >
          Resolve Duplicates
        </DFButton>
      </div>
    </div>
    <div class="no-data" v-else>
      <h1>Policy issued</h1>
    </div>
  </div>
</template>

<script>
import { removeUnderscores, titleCase } from '@/utils/helpers'
import RiskScoreSelect from '@/components/inputs/RiskScoreSelect.vue'
import DFButton from '@/components/DFButton'
import { formatCurrency } from '@/utils/number-formatter.js'

export default {
  name: 'admin-decision',
  components: {
    DFButton,
    RiskScoreSelect
  },
  props: {
    handleInputChange: {
      type: Function,
      default: () => {}
    },
    id: {
      type: String,
      default: undefined
    },
    policyId: {
      type: String,
      default: undefined
    },
    accountId: {
      type: String,
      default: undefined
    },
    getFullInterview: {
      type: Function,
      default: () => {}
    },
    onPolicyApproval: {
      type: Function,
      default: () => {}
    },
    riskItems: {
      type: Array,
      default: undefined
    },
    interview: {
      type: Object,
      default: () => {
        return {}
      }
    },
    quote: {
      type: Object,
      default: () => {
        return {}
      }
    },
    status: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      loading: false,
      resolveStatuses: ['READY_TO_SUBMIT', 'AWAITING_SIGNATURE_CONFIRMATION', 'INACTIVE'],
      riskStatuses: ['INCOMPLETE', 'INELIGIBLE', 'NEEDS_REVIEW', 'NEW', 'READY_TO_SIGN', 'EXPIRED', 'ERROR', 'AWAITING_SIGNATURE_COMPLETION', 'READY_TO_SUBMIT', 'READY_FOR_CHECKOUT']
    }
  },
  methods: {
    removeUnderscores,
    formatCurrency,
    titleCase,
    showInput (statuses) {
      return statuses.includes(this.status)
    },
    async onResolveDuplicates () {
      this.loading = true
      await this.resolveDuplicates({ id: this.id })
      this.loading = false
      this.$emit('loading')
    },
    getScoreValue (value) {
      if (value !== null && 'class' in value) {
        return value.class === undefined ? 'UNDEFINED' : value.class
      }
      return 'UNDEFINED'
    }
  }
}
</script>

<style scoped lang="scss">
.decision {
  &__status {
    text-transform: capitalize;
  }

  &__detail,
  &__CTA {
    font-size: 14px;
    border: thin solid rgba(0, 0, 0, 0.12);
    padding: 20px;
  }

  &__detail {
    margin-bottom: 2rem;
  }
}
</style>
