<template>
  <div class="interview-question" :df-key="this.question.key">
    <template v-if="inputType === InputType.ACKNOWLEDGMENT">
      <v-checkbox
        :id="a11yId"
        v-bind="attrs"
        v-model="values"
        :key="question.key"
        :error="hasError"
        :label="!hideLabel ? label : ''"
        class="interview-question__no-margin"
        @change="setDirty"
        :readonly="readonly"
      />
    </template>

    <LabeledHolder
      v-else
      :hide-label="hideLabel"
      :label="label"
      :tooltip="tooltip || question.hint"
      :child-width="inputWidth"
      :is-mobile="isMobile"
    >
      <template v-if="inputType === InputType.OPTIONS">
        <OptionsInput
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :options="defaultOptions"
          :multiple="isMultiple"
          :error="hasError"
          :rules="validationRules"
          @input="setDirtyAndSave"
          @changed="onChanged"
          :readonly="readonly"
        />
      </template>

      <template v-else-if="inputType === InputType.ALLOWS_NONE">
        <OptionsAllowsNone
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :options="question.options"
          :multiple="isMultiple"
          :error="hasError"
          @input="setDirtyAndSave"
          :allowsNoneOption="question.allowsNone"
          :readonly="readonly"
        />
      </template>

      <template v-else-if="inputType === InputType.HEALTH_CONDITIONS">
        <HealthConditionsInput
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :options="question.options"
          :error="hasError"
          @input="setDirtyAndSave"
          :readonly="readonly"
        />
      </template>

      <template v-else-if="inputType === InputType.TOGGLE">
        <v-btn-toggle
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          group
          class="interview-question__toggle"
          @change="setDirty"
          >
          <v-btn
            :disabled="readonly"
            v-for="(o, i) in question.options"
            :key="o.value"
            :value="o.value"
            :mandatory="!!values"
            :label="o.text"
            @blur="validateMultiple(i, question.options.length)"
            >{{ o.text }}</v-btn

          >
        </v-btn-toggle>
      </template>

      <template v-else-if="inputType === InputType.BOOLEAN">
        <v-btn-toggle
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :mandatory="!!values"
          group
          class="interview-question__toggle"
          @change="setDirty"
        >
          <v-btn :disabled="readonly" value="true">{{ $attrs.options ? $attrs.options.true : 'Yes' }}</v-btn>
          <v-btn :disabled="readonly" value="false" @blur="validateAndSave">{{ $attrs.options ? $attrs.options.false : 'No' }}</v-btn>
        </v-btn-toggle>
      </template>

      <!--template v-else-if="inputType === InputType.BOOLEAN">
        <BooleanInput v-bind="attrs" v-model="values" :error="hasError" @input="setDirty" />
      </template-->
      <template v-else-if="inputType === InputType.SELECT && truncate">
        <v-select
          :id="a11yId"
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :multiple="isMultiple"
          :items="filterOptions(question.options)"
          :rules="values ? validationRules : []"
          :error="hasError"
          @input="setDirty"
          @blur="validateAndSave"
          :placeholder="currentPlaceholder"
          :readonly="readonly"
          :menu-props="{ maxHeight: 350 }"
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ item.text }}</span>
            </v-chip>
            <span
              v-if="index === 1"
              class="grey--text caption"
              >
            (+{{ value.length - 1 }})
          </span>
          </template>

        </v-select>
      </template>
      <template v-else-if="inputType === InputType.SELECT">
        <v-select
          :id="a11yId"
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :multiple="isMultiple"
          :items="filterOptions(question.options)"
          :rules="values ? validationRules : []"
          :error="hasError"
          @input="setDirty"
          @blur="validateAndSave"
          :placeholder="currentPlaceholder"
          :readonly="readonly"
          :menu-props="{ maxHeight: 350 }"
        />

      </template>
      <!-- NOTE: Had to use autocomplete new-password to disable chrome autofill, this is apparently
      the only way to do it right now https://stackoverflow.com/questions/15738259/disabling-chrome-autofill -->
      <template v-else-if="inputType === InputType.COMBOBOX">
        <v-combobox
          :id="a11yId"
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :multiple="isMultiple"
          :search-input.sync="searchText"
          :items="question.options"
          :rules="values ? validationRules : []"
          :error="hasError"
          @input="setDirty"
          @blur="validateAndSave"
          :readonly="readonly"
          :placeholder="currentPlaceholder"
           autocomplete="new-password"
        />
      </template>

      <template v-else-if="inputType === InputType.AUTOCOMPLETE">
        <Autocomplete
          :accessibilityId="a11yId"
          v-model="values"
          :key="question.key"
          :question="question"
          @input="setDirty"
          :error="hasError"
          :readonly="readonly"
        />
      </template>

      <template v-else-if="inputType === InputType.DATE">
        <DateInput
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :valueType="question.valueType"
          :mobile="isMobile"
          :error="hasError"
          @blur="validateDate"
          @input="setDirty"
          :approximate="isApproximate"
          :readonly="readonly"
        />
      </template>

      <template v-else-if="inputType === InputType.HEIGHT">
        <HeightInput
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          :error="hasError"
          @input="setDirty"
          @blur="validateAndSave"
          :readonly="readonly"
        />
      </template>

      <template v-else-if="inputType === InputType.CURRENCY">
        <v-text-field
          :id="a11yId"
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          type="text"
          inputmode="numeric"
          :rules="validationRules"
          :error="hasError"
          @input="setDirty"
          @blur="validateAndSave"
          :placeholder="currentPlaceholder"
          v-keysAllowed="keysAllowed || AllowedTypes.CURRENCY"
          :autocomplete="inputAutocomplete"
          :readonly="readonly"
        />
      </template>

      <template v-else>
        <v-text-field
          v-if="mask"
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          v-mask="mask"
          type="text"
          :rules="validationRules"
          :error="hasError"
          @input="setDirty"
          @blur="validateAndSave"
          @focus="emitFocus"
          :id="a11yId"
          :placeholder="currentPlaceholder"
          v-keysAllowed="keysAllowed"
          :autocomplete="inputAutocomplete"
          :readonly="readonly"
           autocapitalize="off"
        />
        <v-text-field
          v-else
          v-bind="attrs"
          v-model="values"
          :key="question.key"
          type="text"
          :rules="validationRules"
          :error="hasError"
          @input="setDirty"
          @blur="validateAndSave"
          @focus="emitFocus"
          :id="a11yId"
          :placeholder="currentPlaceholder"
          v-keysAllowed="keysAllowed"
          :autocomplete="inputAutocomplete"
          :readonly="readonly"
           autocapitalize="off"
        />
      </template>
    </LabeledHolder>

    <div class="interview-question__error-holder">
      <div v-if="hasError" class="interview-question__error" role="alert">{{ errorMessage }}</div>
    </div>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'
import { mapGetters } from 'vuex'
import LabeledHolder from '@/components/inputs/LabeledHolder'
import DateInput from '@/components/inputs/DateInput'
import HeightInput from '@/components/inputs/HeightInput'
import OptionsInput from '@/components/inputs/OptionsInput'
import OptionsAllowsNone from '@/components/inputs/OptionsAllowsNone'
import HealthConditionsInput from '@/components/inputs/HealthConditionsInput'
import Autocomplete from '@/components/inputs/Autocomplete'
import { VueMaskDirective } from 'v-mask'
import moment from 'moment'

import { inputId } from '@/utils/inputs'
import keysAllowed, { AllowedTypes } from '@/utils/keysAllowed-directive.js'
import { QuestionKey } from '../../api/Interview'
import { RouteName } from '@/router/routes/constants.js'

const PROP_WHITELIST = [
  'inputmode',
  'maxlength',
  'disabled',
  'hide-details',
  'prefix',
  'suffix',
  'loading',
  'loader-height',
  'no-data-text',
  'mandatory',
  'show-none-option'
]

const InputType = {
  TEXT: 'text',
  DATE: 'date',
  OPTIONS: 'options',
  BOOLEAN: 'yesno',
  ACKNOWLEDGMENT: 'ack',
  CURRENCY: 'currency',
  SELECT: 'select',
  COMBOBOX: 'combobox',
  AUTOCOMPLETE: 'autocomplete',
  HEIGHT: 'height',
  PHONE_NUMBER: 'phone',
  TOGGLE: 'toggle',
  ALLOWS_NONE: 'allowsNone',
  HEALTH_CONDITIONS: 'healthConditions'
}

export default {
  name: 'Question',
  components: {
    LabeledHolder,
    DateInput,
    HeightInput,
    OptionsInput,
    OptionsAllowsNone,
    HealthConditionsInput,
    Autocomplete
  },
  directives: { mask: VueMaskDirective, keysAllowed },

  props: {
    value: {
      type: [String, Array]
    },
    question: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    'inline-options': {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      required: false
    },
    inputWidth: {
      type: String,
      required: false,
      default: '100%'
    },
    id: {
      type: String,
      default: undefined
    },
    approximate: {
      type: Boolean,
      default: null
    },
    inputAutocomplete: {
      type: String
    },
    placeholder: {
      type: String
    },
    truncate: {
      type: Boolean
    },
    /**
     * Set value of the `v-keysAllowed=""` directive which whitelists
     * which keys are allowed to be typed within in an input.
     */
    keysAllowed: {
      type: String,
      validator: function (val) {
        // validating that one of the AllowedTypes constant values is being passed in
        return Object.values(AllowedTypes).includes(val)
      }
    },
    text: {
      type: String,
      required: false
    },
    validateOnBlur: {
      type: Boolean,
      required: false,
      default: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    requiredOverride: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      Colors,
      InputType,
      AllowedTypes,
      values: [],
      searchText: '',
      searchResult: [],
      errorMessage: this.error,
      isMobile: /Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent),
      a11yId: this.id,
      isApproximate: false
    }
  },
  computed: {
    ...mapGetters('interview', ['interviewID', 'answerForKey', 'rulesForKey', 'maskforKey', 'parseBool']),
    mask () {
      return this.maskforKey(this.question.key)
    },

    defaultOptions () {
      const hasOptions = this.question.options.length > 0
      if (!hasOptions) {
        return [
          { value: 'true', text: 'Yes' },
          { value: 'false', text: 'No' }
        ]
      } else {
        return this.question.options
      }
    },

    hasError () {
      return !!this.error || !!this.errorMessage
    },

    currentPlaceholder () {
      return this.placeholder || this.question.placeholder || ''
    },

    attrs () {
      const attrs = {
        inline: this.inlineOptions
      }

      Object.getOwnPropertyNames(this.$attrs).forEach((k) => {
        if (PROP_WHITELIST.indexOf(k) > -1) {
          if (k === 'hide-details') {
            attrs[k] = typeof this.$attrs[k] === 'boolean' ? this.$attrs[k] : true
          } else if (k === 'mandatory') {
            attrs[k] = typeof this.$attrs[k] === 'boolean' ? this.$attrs[k] : true
          } else {
            attrs[k] = this.$attrs[k]
          }
        }
      })

      // forcing all vueitfy form element details to be hidden because we're
      // manually handling error messages
      if (!attrs.hasOwnProperty('hide-details')) {
        attrs['hide-details'] = true
      }

      if (attrs.hasOwnProperty('mandatory')) {
        attrs['mandatory'] = true
      }

      if (this.maxlength) {
        attrs['maxlength'] = this.maxlength
      }

      if (this.inputType === InputType.CURRENCY && !this.maxlength) {
        // setting a default maxlength for CURRENCY if none specified
        // "9,999,999" = 9 (7 figures, 2 commas)
        attrs['maxlength'] = 9
      }

      // set ID automatically based on key string
      if (!attrs.id) {
        attrs.id = inputId(this.question.key)
      }

      return attrs
    },

    label () {
      if (!this.question) return ''
      return this.text || this.question.text || this.question.label || this.question.key
    },

    inputType () {
      if (this.type !== null) {
        if (Object.values(InputType).indexOf(this.type) >= 0) {
          return this.type
        }
        throw new Error(`Unsupported type of "${this.type}" for Question component`)
      }

      const q = this.question

      switch (q.valueType) {
        case 'STRING':
          if (this.hasOptions) {
            if (q.key === QuestionKey.HEALTH_CONDITIONS) {
              return InputType.HEALTH_CONDITIONS
            }
            if (q.allowsNone) {
              return InputType.ALLOWS_NONE
            }
            return InputType.OPTIONS
          }
          break
        case 'DATE':
        case 'PAST_DATE':
        case 'FUTURE_DATE':
        case 'APPROXIMATE_DATE':
        case 'PAST_APPROXIMATE_DATE':
        case 'FUTURE_APPROXIMATE_DATE':
          return InputType.DATE
        case 'BOOL':
          return InputType.BOOLEAN
        case 'CURRENCY_US_DOLLAR':
          return InputType.CURRENCY
        case 'OCCUPATION_KEY':
        case 'MEDICATION_NAME':
        case 'DIAGNOSIS_CODE':
          return InputType.AUTOCOMPLETE
        case 'STATE_CODE':
          return InputType.SELECT
      }

      if (q.key === 'HEIGHT_INCHES') {
        return InputType.HEIGHT
      }

      return InputType.TEXT
    },

    isMultiple () {
      if (typeof this.multiple === 'boolean' && this.multiple !== null) {
        return this.multiple
      }
      if (this.question && this.question.multipleChoice) {
        return this.question.multipleChoice
      } else {
        return false
      }
    },

    /**
     * This is the format expected from the date input
     */
    dayMonthYearFormat () {
      if (this.isApproximate) {
        return this.isMobile ? 'YYYY-MM' : 'MM/YYYY'
      }
      return this.isMobile ? 'YYYY-MM-DD' : 'MM/DD/YYYY'
    },

    yearMonthDayFormat () {
      return this.isApproximate ? 'YYYY-MM' : 'YYYY-MM-DD'
    },

    realValue () {
      const values = this.values
      const it = this.inputType
      if (it === InputType.TEXT && !this.question.required && this.values === '') {
        return null
      }
      if (it === InputType.BOOLEAN || it === InputType.ACKNOWLEDGMENT) {
        return this.processValues(values, (v) => {
          return String(v)
        })
      } else if (it === InputType.DATE) {
        // convert display date format to raw format
        return this.processValues(values, (v) => {
          const m = moment(v, this.dayMonthYearFormat, true)
          if (m.isValid()) {
            return m.format(this.yearMonthDayFormat)
          }
        })
      } else if (it === InputType.CURRENCY && values) {
        return values.replace(/[^0-9]/g, '')
      } else if (this.hasOptions) {
        if (typeof values === 'object' && values !== null && values.hasOwnProperty('value')) {
          return values.value
        }
      }
      return values
    },

    hasOptions () {
      return this.question.options ? this.question.options.length > 0 : false
    },

    validationRules () {
      return this.rulesForKey(this.question.key, {
        requiredOverride: this.requiredOverride
      })
    }
  },

  methods: {
    inputId,
    filterOptions (opts) {
      if (this.$route.name === RouteName.OR_WAITLIST) {
        // TODO: this is gross, ideally we would create
        // the option to pass in the value and override the default options
        // however we needed a quick fix for launch
        return opts.filter(state => state.text !== 'TX')
      }
      return opts
    },
    onChanged (val, oldVal) {
      this.$emit('changed', val, oldVal)
    },

    setDirty (val) {
      this.values = val
      this.isDirty = true
      this.errorMessage = null
      // clear the autocomplete text once an option is selected
      if (this.inputType === InputType.AUTOCOMPLETE && this.isMultiple) {
        this.searchText = ''
      }

      const errorMessage = this.validationRules
        .map((rule) => {
          const valid = rule(val)
          return typeof valid === 'string' ? valid : ''
        })
        .filter((e) => e.length)
        .join(', ')
      this.$emit('input', this.realValue, this.question.key, this.inputType, !errorMessage, errorMessage)
    },

    processValues (values, parser) {
      if (typeof values === 'undefined') {
        return undefined
      }
      let vals = values
      if (this.isMultiple) {
        vals.forEach((val, i) => {
          if (typeof val !== 'undefined') {
            vals[i] = parser(val)
          }
        })
      } else {
        vals = parser(vals)
      }
      return vals
    },

    async updateValues (values) {
      const it = this.inputType
      const q = this.question

      let shouldEmitUpdate = false

      if (this.isMultiple) {
        if (typeof values === 'undefined' || values === null) {
          values = []
        }
        this.values = Array.isArray(values) ? values : [values]
      } else {
        this.values = Array.isArray(values) ? values[0] : values
      }

      // set default values for required questions that have undefined
      if (q.required && typeof this.values === 'undefined') {
        if (it === InputType.ACKNOWLEDGMENT) {
          this.values = this.isMultiple ? [false] : false
        } else if (q.valueType === 'STRING') {
          this.values = this.isMultiple ? [''] : ''
        }
        shouldEmitUpdate = true
      }

      if (it === InputType.ACKNOWLEDGMENT) {
        this.values = this.processValues(this.values, (v) => {
          return this.parseBool(v)
        })
      } else if (it === InputType.DATE) {
        this.values = this.processValues(this.values, (v) => {
          const dmy = moment(v, this.dayMonthYearFormat)
          if (dmy.isValid()) {
            return dmy.format(this.yearMonthDayFormat)
          } else {
            const m = moment(v)
            return m.format(this.dayMonthYearFormat)
          }
        })
      } else if (it === InputType.COMBOBOX && this.hasOptions && typeof this.values === 'string') {
        const opt = q.options.find((opt) => opt.value === this.values)
        if (opt) {
          this.values = opt
        }
      }

      if (shouldEmitUpdate) {
        this.$emit('input', this.realValue)
      }
    },

    runValidationRules (val) {
      if (typeof val === 'string') val = val.trim()

      this.errorMessage = this.validationRules
        .map((rule) => {
          const valid = rule(val)
          return typeof valid === 'string' ? valid : ''
        })
        .filter((e) => e.length)
        .join(', ')

      if ((this.inputType === InputType.TOGGLE || this.inputType === InputType.BOOLEAN) &&
        this.errorMessage === 'Please enter a value') {
        this.errorMessage = 'Please choose one'
      }
      this.$emit('validateInput', this.question.key, this.hasError)
    },

    save (val) {
      if (typeof this.$listeners.save !== 'undefined') {
        this.$emit('save', this.question.key, val || this.realValue)
      }
    },

    /**
     * Validate a group of options by only running the validation when blurring
     * off the final option (during keyboard navigation)
     */
    validateMultiple (i, len) {
      if (i === len - 1) {
        this.validateAndSave()
      }
    },
    blurOptions () {
      this.$emit('optionsBlur', this.realValue, this.question.key, this.inputType)
    },
    validateAndSave () {
      if (!this.validateOnBlur) {
        return
      }
      // front-end validation first
      this.runValidationRules(this.realValue)
      // if no front-end validation errors, try backend next
      if (this.realValue && !this.hasError) {
        this.save()
      }
      this.$emit('inputBlur', this.realValue, this.question.key, this.inputType)
    },
    emitFocus () {
      this.$emit('inputFocus', this.realValue, this.question.key, this.inputType)
    },

    validateDate (val) {
      if (!this.validateOnBlur) {
        return
      }
      this.runValidationRules(val)
      const m = moment(val, this.dayMonthYearFormat, true)
      if (!this.hasError && !m.isValid()) {
        this.errorMessage = `Your date should be in ${this.dayMonthYearFormat} format.`
      }
      if (!this.hasError) {
        const realVal = m.format(this.yearMonthDayFormat)
        this.save(realVal)
      }
      this.$emit('validateInput', this.question.key, this.hasError)
    },

    async setDirtyAndSave (val) {
      this.setDirty(val)
      return this.save()
    }
  },

  watch: {
    value (value, prevValue) {
      this.updateValues(value)
      if (this.inputType === InputType.COMBOBOX) {
        this.searchText = value || ''
      }
    },

    values (values) {
      if (typeof values === 'undefined') {
        return
      }

      if (this.inputType === InputType.CURRENCY) {
        this.values = this.processValues(values, (v) => {
          if (!v) return ''
          const parts = v.split('.')
          const raw = parts[0].replace(/[$,]/g, '')
          const i = parseInt(raw, 10)
          if (isNaN(i)) {
            return ''
          } else {
            return i.toLocaleString()
          }
        })
      }
    },

    error (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.errorMessage = newVal
      }
    }
  },

  created () {
    if (typeof this.approximate === 'boolean' && this.approximate !== null) {
      this.isApproximate = this.approximate
    } else {
      this.isApproximate = this.question.valueType.indexOf('APPROXIMATE_DATE') !== -1
    }
    this.isDirty = false
    this.updateValues(this.value)
  },

  mounted () {
    this.a11yId = this.id || this.inputId(this.question.key)
  },

  // we need to convert the underlying values if .multiple changes for this question
  beforeUpdate () {
    const valuesIsArray = Array.isArray(this.values)
    if (this.isMultiple && !valuesIsArray) {
      this.values = [this.values]
    } else if (!this.isMultiple && valuesIsArray) {
      this.values = this.values.length === 1 ? this.values[0] : undefined
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.interview-question {
  width: 100%;
  .v-input__slot {
    padding: 0;
  }

  fieldset {
    border: 0;
  }
}

// a container for the error message so that it does not make the content jump
.interview-question__error-holder {
  min-height: calc(0.75rem + 10px);
}

.interview-question__error {
  color: $c-error;
  line-height: 1;
  letter-spacing: 0.02em;
  font-size: 0.75rem;
  padding-top: 10px;
}

.interview-question__toggle {
  width: 100%;
  @include md {
    width: auto;
  }

  &.v-btn-toggle--group ::v-deep .v-btn.v-btn {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  &.v-btn-toggle ::v-deep .v-btn {
    min-height: 50px !important;
    min-width: 120px !important;
    border: 1px solid $c-action !important;
    color: $c-action;
  }

  &.v-btn-toggle ::v-deep .v-btn--active {
    background-color: $c-action !important;
    color: $c-white;
  }
  &.v-btn-toggle ::v-deep .v-btn--disabled.v-btn--active {
    background-color: $c-action !important;
    color: $c-action !important;
  }

}

.interview-question__no-margin {
  margin: 0;
}
</style>
