import BillingAPI from '@/api/BillingAccount'

const billingModule = {
  namespaced: true,

  state: {
    creditCards: null
  },

  mutations: {
    SET_CREDIT_CARDS (state, cards) {
      state.creditCards = cards
    },

    REMOVE_CARD (state, cardId) {
      const cards = state.creditCards
      const cardIndex = cards.findIndex(item => item.id === cardId)
      cards.splice(cardIndex, 1)
      state.creditCards = cards
    }
  },

  actions: {
    async getCreditCards ({ rootState, state, commit }) {
      if (Array.isArray(state.creditCards) && state.creditCards.length) return state.creditCards
      else {
        let res
        try {
          res = await rootState.apolloClient.query({
            ...BillingAPI.creditCards(),
            fetchPolicy: 'no-cache'
          })
        } catch (err) {
          return null
        }
        if (res && res.data) {
          commit('SET_CREDIT_CARDS', res.data.creditCards)
          return res.data.creditCards
        }
        return null
      }
    },

    async removeCreditCard ({ rootState, commit }, payload) {
      await rootState.apolloClient.mutate({
        ...BillingAPI.detachCreditCard(payload.cardId)
      })
      commit('REMOVE_CARD', payload.cardId)
    },

    async getBillingAccount ({ rootState }) {
      const res = await rootState.apolloClient.mutate({
        ...BillingAPI.getOrCreateBillingAccount(),
        fetchPolicy: 'no-cache'
      })
      return res.data.getOrCreateBillingAccount
    },

    async attachCreditCard ({ rootState }, payload) {
      const res = await rootState.apolloClient.mutate({
        ...BillingAPI.attachCreditCard(payload.tokenId, true)
      })
      return { last4: res.data.attachCreditCard.last4 }
    }
  }
}

export default billingModule
