const optimizeConfig = {
  'ab_verify_step_2': {
    'local': '8ItWBlXSTVGlzLsKePV5yQ',
    'test': '8ItWBlXSTVGlzLsKePV5yQ',
    'production': 'nlNCw5leTi2vRXUB5r8z1w'
  },
  'get_started_button_ordering': {
    'local': 'MfI4gHb3TmCfQkHcb27lRg',
    'dev05': 'MfI4gHb3TmCfQkHcb27lRg',
    'stage': 'MfI4gHb3TmCfQkHcb27lRg',
    'demo': 'MfI4gHb3TmCfQkHcb27lRg',
    'production': 'MfI4gHb3TmCfQkHcb27lRg'
  }
}

export default optimizeConfig
