<template>
  <div class="progress__track" :style="trackStyle">
    <div class="progress__value" :style="valueStyle"></div>
  </div>
</template>

<script>

import Colors from '@/assets/styles/_colors.scss'

export default {
  name: 'ProgressBar',
  props: {
    value: {
      type: Number,
      required: false,
      default: 0.4
    },
    size: {
      type: String,
      required: false,
      default: '20px'
    },
    trackColor: {
      type: String,
      required: false,
      default: '#fff'
    },
    valueColor: {
      type: String,
      required: false,
      default: Colors.red
    },
    rounded: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  computed: {
    trackStyle () {
      return `height: ${this.size}; background-color: ${this.trackColor}; border-radius: ${this.rounded ? '9999px' : '0px'}`
    },
    valueStyle () {
      return `height: ${this.size}; background-color: ${this.valueColor}; width: ${this.value * 100}%; border-radius: ${this.rounded ? '9999px' : '0px'}`
    }
  }
}
</script>

<style scoped lang="scss">

@import "src/assets/styles/media-queries";
.progress__value {
  position: relative;
  top: 0;
  left: 0;
}
</style>
