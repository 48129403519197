import { AdminPathPrefix } from '@/router/routes/constants'

export const hasAdminPrefix = (path) => {
  return path && path.startsWith(AdminPathPrefix)
}

export const redirectBaseURL = (function () {
  let baseURL = process.env.VUE_APP_REDIRECT_BASE_URL || 'https://www.dayforward.com'
  // trim out ending slashes that might have snuck into the config to prevent accidental double slashes in redirect urls
  baseURL = baseURL.replace(/\/+$/, '')
  return baseURL
})()

export const gotoRedirect = (path) => {
  path = path || ''
  // trim out any leading slashes from path to prevent accidental double slashes in redirect urls
  path = path.replace(/^\/+/, '')
  return `${redirectBaseURL}/${path}`
}
