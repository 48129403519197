<template>
  <div class="status--centered">
    <div>Thanks.</div>
  </div>
</template>

<script>
export default {
  name: 'DocusignWebhook',
  methods: {
    getQueryStringParams () {
      if (location.search && window.URLSearchParams) {
        const params = new URLSearchParams(location.search)
        return params.entries()
      } else {
        const entries = {}
        const hash = location.hash
        const qsPos = hash.indexOf('?')
        if (qsPos > -1 && hash.length > qsPos) {
          const qs = hash.substr(qsPos + 1)
          const params = qs.split('&')
          for (let i = 0, len = params.length; i < len; i++) {
            const p = params[i].split('=')
            if (p.length === 2) {
              entries[p[0]] = p[1]
            }
          }
        }
        return entries
      }
    }
  },
  created () {
    // eslint-disable-next-line no-console
    console.log(`DOCUSIGN: Got webhook ...`)
    const params = this.getQueryStringParams()
    // eslint-disable-next-line no-console
    console.log(`DOCUSIGN: Got webhook params ...`)
    const data = {}
    for (const [key, value] of params) {
      data[key] = value
    }
    // eslint-disable-next-line no-console
    console.log(`DOCUSIGN: posting message from callback to e-sign page ..`)
    window.parent.postMessage(data, window.location.origin)
  }
}
</script>

<style scoped lang="scss">
.status--centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  height: 100%;

  > div {
    margin-top: 10px;
  }
}
</style>
