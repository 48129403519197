<template>
  <div class="contentful-step-sections mq-container" v-observe-visibility="onVisible">
    <transition-group name="section-fade" tag="div" class="contentful-step-sections__holder"
                      v-on:before-enter="beforeEnter" v-on:enter="enter" v-bind:css="false">
      <div class="contentful-step-item" v-show="isVisible"
           v-for="(section, idx) in sections"
           v-bind:data-index="idx" :key="`contentful-step-item-${idx}`"
      >
        <template v-if="$gtMd">
          <!-- desktop -->
          <div class="contentful-step-item__image">
            <img :src="sectionImages[idx].fields.file.url" :alt="`${idx}.`">
          </div>
          <div :key="`title-${idx}`" class="contentful-step-item__title">
            {{ section.fields.title }}
          </div>
          <div :key="`body-${idx}`" class="contentful-step-item__body">
            <ContentfulContentRenderer :content="descriptionHTML(section)"/>
          </div>
        </template>
        <template v-else>
          <!-- mobile -->
          <div class="contentful-step-item__image">
            <img :src="sectionImages[idx].fields.file.url" :alt="`${idx}.`">
          </div>
          <div class="contentful-step-item__content">
            <div :key="`title-${idx}`" class="contentful-step-item__title">
              {{ section.fields.title }}
            </div>
            <div :key="`body-${idx}`" class="contentful-step-item__body">
              <ContentfulContentRenderer :content="descriptionHTML(section)"/>
            </div>
          </div>
        </template>
      </div>
    </transition-group>
  </div>
</template>

<script>

import { processRichText } from '@/api/Content'
import { ObserveVisibility } from 'vue-observe-visibility'
import Velocity from 'velocity-animate'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-step-sections',
  components: { ContentfulContentRenderer },
  directives: {
    'observe-visibility': ObserveVisibility
  },
  props: {
    sections: {
      type: Array,
      default: () => []
    },
    animated: {
      type: Boolean,
      default: true
    },
    sectionImages: undefined
  },
  data: function () {
    return {
      isVisible: !this.animated
    }
  },
  methods: {
    onVisible (isVisible) {
      if (this.animated && !this.isVisible && isVisible) {
        this.isVisible = true
      }
    },
    descriptionHTML (section) {
      return processRichText(this, section.fields.description)
    },
    beforeEnter (el) {
      el.style.opacity = 0
      el.style.transform = 'translateY(0)'
    },
    enter (el, done) {
      const delay = Math.max(el.dataset.index * 0.35, 0.25)
      setTimeout(function () {
        Velocity(
          el,
          { opacity: 1, translateY: -10 },
          { duration: 900, complete: done }
        )
      }, delay * 1600)
    }
  }
}
</script>

<style lang="scss" scoped>

$image-height-sm: 34px;
$image-height: 54px;

@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.contentful-step-sections {
  &__holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    padding: 0;
    @include lg {
      flex-direction: row;
    }
    @include xl {
      flex-direction: row;
    }
  }
}

.contentful-step-item {
  display: inline-flex;
  flex-direction: row;
  flex-basis: 100%;
  @include lg {
    flex-basis: 33%;
    flex-direction: column;
  }
  &:not(:last-of-type) {
    margin-bottom: 30px;
    @include lg {
      margin-right: 50px;
    }
    @include xl {
      margin-right: 90px;
    }
  }

  &__image {
    width: $image-height-sm;
    height: $image-height-sm;
    @include lg {
      width: $image-height;
      height: $image-height;
    }
    img {
      height: 100%;
    }
  }

  &__content {
    margin-left: 24px;
  }

  &__title {
    font-family: 'Graphik', sans-serif;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1em;
    color: $c-primary;
    margin-top: 3px;
    width: 100%;
    text-align: left;
    letter-spacing: -0.02em;
    @include md {
      font-size: 2rem;
      letter-spacing: -0.02em;
    }
    @include lg {
      letter-spacing: -0.04em;
      min-height: 80px;
      margin-top: 40px;
      margin-bottom: 0;
      font-size: 2.4rem;
      line-height: 1em;
    }
    @include xl {
      letter-spacing: -0.04em;
      min-height: 100px;
      font-size: 3rem;
      line-height: 1em;
    }
  }
  &__body {
    margin-top: 10px;
    font-size: 1.1em;
    @include md {
      margin-top: 15px;
    }
  }
}

</style>
