<template>
  <div class="date-input">
    <v-text-field
      v-if="mobile"
      :type="approximate ? 'month' : 'date'"
      v-model="dateEntry"
      :min="dateMin"
      :max="dateMax"
      :placeholder="placeholderText"
      @input="onChange"
      @change="onChange"
      @blur="changedValue"
      hide-details
      :error="error"
      :id="id"
      :readonly="readonly"
    />
    <v-text-field
      v-else
      type="text"
      v-mask="mask"
      v-model="dateEntry"
      :placeholder="placeholderText"
      @input="onChange"
      @change="onChange"
      @blur="changedValue"
      hide-details
      :error="error"
      :id="id"
      :readonly="readonly"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { VueMaskDirective } from 'v-mask'

export default {
  name: 'DateInput',
  directives: {
    mask: VueMaskDirective
  },

  props: {
    value: {
      type: String
    },
    valueType: {
      type: String
    },
    placeholder: {
      type: String
    },
    outlined: {
      type: Boolean,
      default: true
    },
    approximate: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array
    },
    error: Boolean,
    id: {
      type: String,
      default: undefined
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      dateEntry: undefined,
      placeholderText: this.placeholder ? this.placeholder : (this.approximate ? 'MM/YYYY' : 'MM/DD/YYYY')
    }
  },

  computed: {
    mask () {
      return this.approximate ? '##/####' : '##/##/####'
    },

    displayFormat () {
      return this.approximate ? 'MM/YYYY' : 'MM/DD/YYYY'
    },

    // not supported on ios yet
    dateMin () {
      if (this.valueType === 'FUTURE_DATE') {
        return moment().format('YYYY-MM-DD')
      } else if (this.valueType === 'FUTURE_APPROXIMATE_DATE') {
        return moment().startOf('month').format('YYYY-MM-DD')
      }
      return null
    },

    // not supported on ios yet
    dateMax () {
      if (this.valueType === 'PAST_DATE') {
        return moment().format('YYYY-MM-DD')
      } else if (this.valueType === 'PAST_APPROXIMATE_DATE') {
        return moment().endOf('month').format('YYYY-MM-DD')
      }
      return null
    }
  },
  methods: {
    onChange (value) {
      this.$emit('input', value)
    },
    changedValue () {
      this.$emit('blur', this.dateEntry)
    },

    formatDisplayDate (val) {
      if (!val) return ''
      let m = moment(val, 'YYYY-MM-DD', true)
      if (m.isValid()) {
        return m.format(this.displayFormat)
      } else if (this.approximate) {
        m = moment(val, 'YYYY-MM', true)
        if (m.isValid()) {
          return m.format(this.displayFormat)
        }
      }
      return val
    }
  },

  mounted () {
    if (this.value) {
      this.dateEntry = this.mobile ? this.value : this.formatDisplayDate(this.value)
    }
  }
}
</script>

<style scoped lang="scss"></style>
