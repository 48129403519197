<template>
  <div class="policy-actions mq-grid">
    <div class="cols-10" v-if="hasButton">
      <DFButton variant="light" elevation="0" @click="buttonAction">
        {{ buttonText }}
      </DFButton>
    </div>
    <template v-if="showContact">
      <div class="policy-actions__text cols-10" :class="textClass">
        {{ actionText }}&emsp;
        <a v-if="chatActive" class="policy-actions__text--link" @click="openChat"> Contact us</a>
      </div>
    </template>
  </div>
</template>

<script>
import DFButton from '@/components/DFButton'

export default {
  name: 'PolicyAction',

  components: { DFButton },

  props: {
    hasButton: {
      type: Boolean,
      default: false
    },
    buttonAction: {
      type: Function,
      required: false
    },
    buttonText: {
      type: String,
      required: false
    },
    actionText: {
      type: String,
      default: 'Want to change your policy?'
    },
    callActive: {
      type: Boolean,
      default: false
    },
    chatActive: {
      type: Boolean,
      default: false
    },
    showContact: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    textClass() {
      if (this.hasButton) return `policy-actions__text--right`
      return ''
    }
  },

  methods: {
    async openChat() {
      await this.$router.push({ name: 'help' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/titles';
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/metrics';

.policy-actions {
  margin-top: 1rem;

  &__text {
    margin-bottom: 0.75rem;
    &--link {
      text-decoration: underline;
    }
  }
}
</style>
