import SignInCallback from '@/views/auth/SignInCallback'
import SignOut from '@/views/auth/SignOut'
import { RouteCategory } from '@/router/routes/constants.js'
import SignInMagicLink from '@/views/auth/SignInMagicLink.vue'

export default [
  {
    path: '/signin', // Don't put category on sign in or it will reset the value and headers will fail
    name: 'signin',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://www.dayforward.com/signin");
    },
    meta: {
      requiresAuth: false
    }
  },
  { path: '/login', redirect: '/signin' },
  {
    path: '/signin/confirm',
    component: SignInMagicLink,
    name: 'sign-in-confirm',
    meta: {
      category: RouteCategory.AUTH,
      requiresAuth: false
    }
  },
  {
    path: '/signin/callback',
    name: 'signin-callback',
    component: SignInCallback,
    props: true,
    meta: {
      category: RouteCategory.AUTH,
      requiresAuth: false
    }
  },
  {
    path: '/signout', // here for manually forcing signout, otherwise please use dispatch("auth/signout")
    name: 'signout',
    component: SignOut,
    meta: {
      category: RouteCategory.AUTH,
      requiresAuth: false
    }
  }
]
