<template>
  <div>
    <div class="press-release">
      <div class="press-release__title">{{ title }}</div>
    </div>
    <div class="press-release">
      <div v-if="author" class="press-release__author">
        {{ author }}
      </div>
      <ContentfulContentRenderer :content="body" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { processRichText } from '@/api/Content'
import moment from 'moment'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-press-release',
  components: { ContentfulContentRenderer },
  data () {
    return {
      isLoading: true,
      body: undefined,
      date: undefined,
      author: undefined,
      title: undefined,
      id: undefined,
      slug: undefined
    }
  },

  methods: {
    ...mapActions('content', ['fetchContentfulEntryOrCache']),

    async loadPressRelease () {
      this.isLoading = true
      const entry = await this.fetchContentfulEntryOrCache({ id: this.id, slug: this.slug })
      if (entry) {
        const { title, date, author, body } = entry.fields
        this.title = title
        this.date = date !== undefined ? moment(date).format('MMMM D, YYYY') : undefined
        this.author = author !== undefined ? author : undefined
        this.body = processRichText(this, body)
      }
      this.isLoading = false
    }
  },

  async created () {
    const { id, slug } = this.$route.params
    this.id = id
    this.slug = slug
    await this.loadPressRelease()
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/media-queries';

.press-release {
  width: 100%;
  @include md {
    width: 80%;
  }
  &__back-link {
    text-align: right;
  }
  &__title {
    font-size: 1.2rem;
    font-weight: 700;
  }

  &__author {
    font-weight: 700;
  }
}
</style>
