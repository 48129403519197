<template>
  <div class="quote-tool" :class="getClass">
    <div v-if="showHeader">
      <h1 class="contentful-title df-title df-title--red">Get an estimated price, right now.</h1>
      <p class="quote-tool__subhead">
        Answer four simple questions to estimate how much it would be to protect your family.
      </p>
    </div>
    <div class="quote-tool__form">
      <label>Your age</label>
      <v-text-field
        class="quote-tool__control"
        v-model="age"
        type="text"
        inputmode="numeric"
        single-line
        :maxLength="3"
        @keydown="numbersOnly"
        @input="ageRules"
        :error="ageError"
        :error-messages="ageErrorMessage"
      />
      <div class="spacer" v-if="$gtSm"></div>
      <label>Yearly income*</label>
      <div>
        <v-text-field
          class="quote-tool__control"
          v-model="income"
          prefix="$"
          :maxLength="9"
          @input="incomeRules"
          @keydown="numbersOnly"
          @keyup="formatCurrencyValue"
          :error="incomeError"
          :error-messages="incomeErrorMessage"
        />

      </div>

      <label>Gender</label>
      <v-select
        class="quote-tool__control"
        :items="genderVals"
        v-model="gender"
        @change="track({event: 'app_quickprice_gender_entered'})"
      />
      <div class="spacer" v-if="$gtSm"></div>
      <label>Youngest child's age</label>
      <v-select
        class="quote-tool__control"
        :items="childAgeVals"
        v-model="childAge"
        @change="track({event: 'app_quickprice_childage_entered'})"
      />
    </div>
    <div class="quote-tool__footnote">
      * If you are a stay-at-home parent, use your household income. Please call us at 1-888-434-0228 if your income is over $500,000.
    </div>
    <div class="quote-tool__buttons">
      <DFButton elevation="0" :disabled="!valid" @click="submitForm" class="quote-tool__submit">
        Get Price
      </DFButton>
    </div>
  </div>
</template>

<script>
import DFButton from '@/components/DFButton'
import keysAllowed from '@/utils/keysAllowed-directive.js'
import debounce from 'lodash/debounce'

export default {
  name: 'quote-tool',
  directives: { keysAllowed },
  components: {
    DFButton
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      age: undefined,
      income: '',
      genderVals: ['Male', 'Female'],
      childAgeVals: ['No kids', 'Expecting', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12+'],
      gender: undefined,
      childAge: undefined,
      ageError: false,
      ageErrorMessage: undefined,
      incomeError: false,
      incomeErrorMessage: undefined,
      checkingAge: true,
      ageRulesHandle: undefined,
      checkingIncome: true,
      incomeRulesHandle: undefined
    }
  },
  computed: {
    getClass () {
      if (this.$route.name === 'home') {
        return 'mq-container home-container'
      }
      return false
    },
    valid () {
      return !this.checkingAge && this.ageError === false && !this.checkingIncome && this.incomeError === false &&
        this.age !== '' && this.gender !== undefined && this.childAge !== undefined
    }
  },
  methods: {
    async submitForm () {
      const trackVals = {
        gender: this.gender.toUpperCase(),
        income: this.income.replace(/\D/g, '')
      }
      const formVals = {
        age: this.age,
        childAge: this.childAge,
        gender: this.gender.toUpperCase(),
        income: this.income.replace(/\D/g, '')
      }
      this.track({ event: 'app_quickprice_getprice_submit', properties: trackVals })

      const submitVals = {
        ...formVals,
        childAge: this.getChildAge()
      }
      await this.$router.push({ name: 'quick-price', params: submitVals })
    },
    getChildAge () {
      if (this.childAge === 'No kids') {
        return undefined
      } else if (this.childAge === 'Expecting') {
        return -1
      } else if (this.childAge === '12+') {
        return 12
      } else {
        return this.childAge
      }
    },
    ageRules () {
      if (this.ageRulesHandle || !this.age) {
        this.ageRulesHandle.cancel()
        if (!this.age) {
          this.ageError = false
          this.ageErrorMessage = ''
          return
        }
      }
      this.checkingAge = true
      this.ageRulesHandle = debounce(function () {
        if (this.age < 18) {
          this.ageError = true
          this.ageErrorMessage = 'You must be 18 or older to apply.'
        } else if (this.age > 50) {
          this.ageError = true
          this.ageErrorMessage = 'You must be 50 or under to apply.'
        } else {
          this.ageError = false
          this.ageErrorMessage = undefined
        }
        this.checkingAge = false
        this.track({ event: 'app_quickprice_age_entered' })
      }, 650)
      this.ageRulesHandle()
    },
    incomeRules () {
      const hasEnteredIncome = this.income && this.income !== ''
      if (this.incomeRulesHandle || !hasEnteredIncome) {
        this.incomeRulesHandle.cancel()
        if (!hasEnteredIncome) {
          this.incomeError = false
          this.incomeErrorMessage = ''
          return
        }
      }
      this.checkingIncome = true
      this.incomeRulesHandle = debounce(function () {
        const formattedVal = this.income.replace(/\D/g, '')
        if (formattedVal < 20000) {
          this.incomeError = true
          this.incomeErrorMessage = 'You must enter an income of $20,000 or greater.'
        } else if (formattedVal > 500000) {
          this.incomeError = true
          this.incomeErrorMessage = 'You must enter an income of $500,000 or under.'
        } else {
          this.incomeError = false
          this.incomeErrorMessage = undefined
        }
        this.checkingIncome = false
      }, 650)
      this.track({ event: 'app_quickprice_income_entered' })
      this.incomeRulesHandle()
    },
    track (obj) {
      const props = obj.properties || {}
      this.$analytics.idempotentTrack({
        event: obj.event,
        properties: props
      })
    },
    numbersOnly (event) {
      const baseAllowedRegex = new RegExp('Backspace|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Tab|Delete')
      const digits = new RegExp(/^\d+$/)
      if (digits.test(event.key) || baseAllowedRegex.test(event.key)) {
        return true
      }
      event.preventDefault()
      return false
    },
    formatCurrencyValue (event) {
      this.numbersOnly(event)
      const parts = this.income.split('.')
      const raw = parts[0].replace(/[$,]/g, '')
      const i = parseInt(raw, 10)
      if (isNaN(i)) {
        this.income = ''
      } else {
        this.income = i.toLocaleString()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global";
.home-container {
  margin-top: 5em;
  margin-bottom: 5em;
}

.quote-tool {
  h1 {
    margin-bottom: 20px;
  }
  &__subhead {
    color: $c-primary;
    font-size: 1.3em;
    line-height: 1.2em;
    margin-bottom: 58px;
  }
  ::v-deep .v-input__slot {
    @include lg {
      max-width: 200px;
    }
  }
  &__buttons {
    text-align: center;
  }
  &__submit {
    margin-top: 30px;
    @include md {
      margin-top: 60px;
    }

  }
  &__footnote {
    font-size: 0.85em;
    margin-top: 30px;
  }
  &__form {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    row-gap: 10px;
    @include md {
      max-width: 95%;
      column-gap: 20px;
      row-gap: 20px;
      grid-template-columns: auto 3fr 20px auto 3fr;
    }
    @include lg {
      max-width: 960px;
      column-gap: 40px;
      grid-template-columns: auto 2fr 60px auto 3fr;
    }
    label {
      padding-bottom: 10px;
    }
  }
}
</style>
