<template>
  <div class="navigation-header" :style="extraStyles" :class="extraClasses">
    <div class="navigation-header__content">
      <div class="navigation-header__logo">
        <a :href="gotoRedirect(RedirectRouteNames.HOME)" class="df-link--noline">
            <slot name="logo">
              <Logo :color="changeColor ? alternateColor : color" :size="logoSize" />
            </slot>
        </a>
      </div>
      <FlexSpacer class="navigation-header__spacer" />
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Colors from '@/assets/styles/_colors.scss'
import FlexSpacer from '@/components/FlexSpacer'
import Logo from '@/components/accessories/Logo.vue'
import { EventBus } from '../../bus/eventbus'
import { gotoRedirect } from '@/utils/router'
import { RedirectRouteNames } from '@/router/routes/constants'

export default {
  name: 'navigation-header',
  components: { FlexSpacer, Logo },
  data: function () {
    return {
      changeColor: false,
      RedirectRouteNames
    }
  },
  props: {
    color: {
      type: String,
      default: Colors.red
    },
    alternateColor: {
      type: String,
      default: Colors.eggshell
    },
    backgroundColor: {
      type: String,
      default: undefined
    },
    componentStyles: {
      type: Array,
      default: undefined
    }
  },
  computed: {
    logoSize () {
      return this.$gtSm ? '22' : '22'
    },

    extraStyles () {
      const styles = this.componentStyles || {}
      if (this.backgroundColor) {
        styles['background-color'] = this.backgroundColor
      }
      return styles
    },
    extraClasses () {
      const classes = []
      if (this.variant) {
        classes.push(`navigation-header--${this.variant}`)
      }
      return classes
    }
  },
  methods: {
    gotoRedirect,
    handleOpen (payload) {
      this.changeColor = payload
    }
  },
  mounted () {
    EventBus.$on('menu', (payload) => {
      this.handleOpen(payload)
    })
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/media-queries';

.navigation-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex: 1;
  }
  &__logo {
    margin-top: 0.55rem;
    position: relative;
  }
  &__spacer {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
  }
}
</style>
