<template>
  <div class="interview-checkout">
    <FlowWrapper
      :can-move-to-next="hasValidFormData"
      :question-keys="activeKeys"
      :answer-values="answerValues"
      :on-back="onBack"
      :before-next="onBeforeNext"
      :on-next="onSaveResult"
      @result="onResult"
      :save-on-back="false"
      :show-back="showBack"
    >
      <template v-slot:content-header>
        <div class="df-content-header-text">Let’s make sure this is you.</div>
      </template>
      <template v-slot:content>
        <v-form v-if="!isLoading">
          <div class="mq-grid">
            <template v-if="!hasAccount">
              <Question
                v-model="values[QK.EMAIL_ADDRESS]"
                :question="questions[QK.EMAIL_ADDRESS]"
                :error="errors[QK.EMAIL_ADDRESS]"
                class="cols-10 cols-md-8 cols-lg-5"
                inputmode="email"
              />
              <div class="cols-5" v-if="$gtSm"></div>
            </template>

            <template v-if="!hasCapturedMobileNumber">
              <Question
                v-model="values[QK.MOBILE_NUMBER]"
                :question="questions[QK.MOBILE_NUMBER]"
                :error="errors[QK.MOBILE_NUMBER]"
                class="cols-10 cols-md-8 cols-lg-5"
                inputmode="tel"
              />
              <div class="cols-5" v-if="$gtSm"></div>
            </template>

            <template v-if="!hasCapturedSSN">
              <Question
                v-model="values[QK.SSN]"
                :question="questions[QK.SSN]"
                :error="errors[QK.SSN]"
                class="cols-10 cols-md-8 cols-lg-5"
                inputmode="numeric"
              />
              <div class="cols-5" v-if="$gtSm"></div>
            </template>

            <template v-if="showDriversLicenseFields">
              <div v-if="$gtSm" class="confirm-identity__license cols-10 cols-md-8 cols-lg-5">
                <Question
                  class="confirm-identity__license-number"
                  v-model="values[QK.DRIVERS_LICENSE_NUMBER]"
                  :question="questions[QK.DRIVERS_LICENSE_NUMBER]"
                  :error="errors[QK.DRIVERS_LICENSE_NUMBER]"
                  maxlength="19"
                  @inputFocus="clearError"
                />
                <Question
                  class="confirm-identity__license-state"
                  v-model="values[QK.DRIVERS_LICENSE_STATE]"
                  :question="questions[QK.DRIVERS_LICENSE_STATE]"
                  :error="errors[QK.DRIVERS_LICENSE_STATE]"
                  @inputFocus="clearError"
                />
              </div>
              <div v-else class="cols-10">
                <Question
                  class="confirm-identity__license-number"
                  v-model="values[QK.DRIVERS_LICENSE_NUMBER]"
                  :question="questions[QK.DRIVERS_LICENSE_NUMBER]"
                  :error="errors[QK.DRIVERS_LICENSE_NUMBER]"
                  maxlength="19"
                />
                <Question
                  class="confirm-identity__license-state"
                  v-model="values[QK.DRIVERS_LICENSE_STATE]"
                  :question="questions[QK.DRIVERS_LICENSE_STATE]"
                  :error="errors[QK.DRIVERS_LICENSE_STATE]"
                />
              </div>
            </template>
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { QuestionKey as QK, InterviewFlagCause as ORF, SectionID, InterviewFlagType } from '@/api/Interview'
import { AuthModuleReasons } from '@/store/modules/AuthModule'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'
import { RouteName } from '@/router/routes/constants.js'
import { SectionDisplayOption } from '@/api/Interview/constants'
import { keyToPath } from '@/utils/helpers'

export default {
  name: 'InterviewConfirmIdentity',
  components: { FlowWrapper, Question },

  data() {
    return {
      STEP_NAME: 'confirm-identity',
      QK: QK,
      ORF: ORF,
      account: null,
      questions: {},
      values: {},
      errors: {},
      isLoading: true,
      hasCapturedDriversLicenseInfo: false,
      showBack: true
    }
  },

  computed: {
    ...mapGetters('interview', [
      'interviewID',
      'interview',
      'parseBool',
      'validateKeys',
      'hasDrivingFollowup',
      'hasHealthConditions',
      'hasIdentityFollowup',
      'allHealthFollowups',
      'finalQuoteShown'
    ]),

    hasAccount() {
      return !!this.account
    },

    hasCapturedMobileNumber() {
      return false
    },

    hasCapturedSSN() {
      // TODO: once we can determine if ssn has been captured on a PREVIOUS step, enable this logic
      return false
    },

    hasDriversLicense() {
      return this.parseBool(this.values[QK.HAS_DRIVERS_LICENSE]) === true
    },

    showDriversLicenseFields() {
      return this.hasDriversLicense && !this.hasDrivingFollowup
    },

    questionKeys() {
      return [
        QK.EMAIL_ADDRESS,
        QK.MOBILE_NUMBER,
        QK.SSN,
        QK.HAS_DRIVERS_LICENSE,
        QK.DRIVERS_LICENSE_NUMBER,
        QK.DRIVERS_LICENSE_STATE
      ]
    },

    answerValues() {
      return this.values
    },

    activeKeys() {
      let activeKeys = [QK.MOBILE_NUMBER, QK.SSN]
      if (!this.hasAccount) {
        activeKeys = activeKeys.concat(QK.EMAIL_ADDRESS)
      }
      if (this.showDriversLicenseFields) {
        activeKeys = activeKeys.concat([QK.DRIVERS_LICENSE_NUMBER, QK.DRIVERS_LICENSE_STATE])
      }
      return activeKeys
    },

    hasValidFormData() {
      return this.validateKeys(this.activeKeys, this.values)
    }
  },

  methods: {
    ...mapActions('account', ['getAccount']),
    ...mapActions('auth', ['sendPasswordlessAuth']),
    ...mapActions('interview', [
      'interviewQuestionAnswerMaps',
      'checkFollowupSectionsActivated',
      'fetchInterviewSection'
    ]),
    clearError() {
      this.$delete(this.errors, QK.DRIVERS_LICENSE_NUMBER)
    },

    async onBack() {
      const confirmIdentityInfoShown = sessionStorage.getItem('identityConfirmationShown')
      if (confirmIdentityInfoShown === 'postQuote') {
        await this.$router.push({
          name: RouteName.INTERVIEW_CONFIRM_INFO_POSTQUOTE
        })
        return { navigate: false }
      } else {
        await this.$router.push({ name: RouteName.INTERVIEW_FINAL_QUOTE })
        return { navigate: false }
      }
    },

    async fetchDisplayOptions() {
      const { displayOptions } = await this.fetchInterviewSection({
        id: this.interviewID,
        sectionId: SectionID.IDENTITY_CONFIRMATION
      })
      this.displayOptions = displayOptions || []
      this.showBack = !this.displayOptions?.includes?.(SectionDisplayOption.HIDE_BACK_BUTTON)
    },

    hasChatFlag() {
      return (
        this.interview.flags.filter((f) => {
          return (
            f.type === InterviewFlagType.CHAT_REQUIRED ||
            (f.type === InterviewFlagType.NEEDS_REVIEW && f.cause === ORF.REFERRED_TO_CUSTOMER_SERVICE)
          )
        }).length > 0
      )
    },

    onBeforeNext() {
      if (this.values[QK.EMAIL_ADDRESS]) {
        this.values[QK.EMAIL_ADDRESS] = this.values[QK.EMAIL_ADDRESS].toLowerCase()
      }
      return { proceed: true }
    },

    async onSaveResult() {
      await this.checkFollowupSectionsActivated({ id: this.interviewID })
      await this.onNextIfConfirmIdentity()
    },

    async onNextIfTroubleWithIdentity() {
      // determine whether we route to chat, manual UW, finalize flow or final quote
      if (this.hasIdentityFollowup) {
        return { navigate: false }
      } else {
        // ID verification succeeded, and we might head to final quote OR finalize flow
        // first determine whether we need to bind the interview:
        if (!this.hasAccount) {
          const email = this.values[QK.EMAIL_ADDRESS]
          let success = false

          try {
            // set authReason as MANUAL_UNDERWRITING for health reflexive flow - this will not affect the
            // interview on the backend but will set the header to Finalize Price instead of Checkout
            const reason = this.hasHealthConditions ? AuthModuleReasons.MANUAL_UNDERWRITING : AuthModuleReasons.CHECKOUT
            success = await this.sendPasswordlessAuth({
              email: email,
              authReason: reason
            })
          } catch (err) {
            if (typeof err === 'object' && err.hasOwnProperty('code') && err.code === 'bad.email') {
              // TODO: this will not override the error state currently, maybe rules blocking?
              this.errors[QK.EMAIL_ADDRESS] = 'Please enter a valid email address'
            } else {
              throw err
            }
            return { navigate: false }
          }

          if (success) {
            let nextName
            let params
            if (this.hasHealthConditions) {
              nextName = RouteName.FINALIZE_HEALTH_FOLLOWUP
              params = { value: keyToPath(this.allHealthFollowups[0].value) }
            }

            const nextRoute = this.$router.resolve({ name: nextName, params })
            window.localStorage.setItem('auth.redirect', nextRoute.href)
            await this.$router.push({ name: 'signin' })
            return { navigate: false }
          } else {
            await this.$router.push({ name: RouteName.ERROR })
            return { navigate: false }
          }
        } else {
          // user has an account, but same routing, just without going through auth first
          if (!this.hasHealthConditions) {
            await this.$router.push({ name: RouteName.INTERVIEW_FINAL_QUOTE })
          } else {
            await this.$router.push({
              name: RouteName.FINALIZE_HEALTH_FOLLOWUP,
              params: { index: 0 }
            })
          }
          return { navigate: false }
        }
      }
    },

    async onNextIfConfirmIdentity() {
      // checkout flow only
      if (!this.hasAccount) {
        const email = this.values[QK.EMAIL_ADDRESS]?.toLowerCase()
        let success = false
        try {
          success = await this.sendPasswordlessAuth({
            email: email,
            authReason: AuthModuleReasons.CHECKOUT
          })
        } catch (err) {
          if (typeof err === 'object' && err.hasOwnProperty('code') && err.code === 'bad.email') {
            // TODO: this will not override the error state currently, maybe rules blocking?
            this.errors[QK.EMAIL_ADDRESS] = 'Please enter a valid email address'
          } else {
            throw err
          }
          return { navigate: false }
        }

        if (success) {
          const nextName = this.$route.meta.interview.nextStep
          const nextRoute = this.$router.resolve({ name: nextName })
          window.localStorage.setItem('auth.redirect', nextRoute.href)
          await this.$router.push({ name: 'signin' })
          return { navigate: false }
        } else {
          await this.$router.push({ name: RouteName.ERROR })
          return { navigate: false }
        }
      } else {
        return { navigate: true }
      }
    },

    async fetchExistingInterviewAnswers() {
      const { questions, answers } = await this.interviewQuestionAnswerMaps({
        id: this.interviewID,
        keys: this.questionKeys
      })

      this.questions = questions
      this.values = answers

      if (!!this.values[QK.DRIVERS_LICENSE_NUMBER] && !!this.values[QK.DRIVERS_LICENSE_STATE]) {
        this.hasCapturedDriversLicenseInfo = true
      }

      this.isLoading = false
    },
    onResult({ errors }) {
      if (!errors) {
        return
      }
      Object.keys(errors).forEach((k) => {
        this.$set(this.errors, k, errors[k])
      })
    },

    async updateAccount() {
      this.account = await this.getAccount()
    }
  },

  async created() {
    await Promise.all([this.updateAccount(), this.fetchExistingInterviewAnswers(), this.fetchDisplayOptions()])
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';
</style>
