<template>
  <div class="dashboard" v-if="!isLoading">
    <template v-if="showPolicy">
      <PolicyDashboard
        :policy="policy"
        :showEditPayment="true"
        :interview="interview"
      />
    </template>
    <template v-else-if="showUpdatePaymentInfo">
      <InterviewPaymentUpdate
        header-text="Payment"
        :policy="policy"
        :interview="interview"
      />
    </template>
    <template v-else-if="showInterstitial">
      <AccountInterstitial
        :not-started="noExistingInterview"
        :hide-policy-details='hidePolicyDetails'
      />
    </template>
  </div>
</template>

<script>
import PolicyDashboard from '@/components/user/PolicyDashboard'
import AccountInterstitial from '@/views/user/AccountInterstitial'
import InterviewPaymentUpdate from '@/views/interview/InterviewPaymentUpdate'
import { mapActions, mapGetters } from 'vuex'
import { PolicyStatus, PolicyFlag } from '@/api/Policy'

export default {
  name: 'dashboard',

  data () {
    return {
      isLoading: true,
      showPolicy: false,
      showInterstitial: false,
      showUpdatePaymentInfo: false,
      policy: undefined,
      account: undefined,
      interview: undefined,
      noExistingInterview: false,
      hidePolicyDetails: false
    }
  },

  components: {
    PolicyDashboard,
    AccountInterstitial,
    InterviewPaymentUpdate
  },

  computed: {
    ...mapGetters('interview', {
      interviewInState: 'interview'
    })

  },

  methods: {
    ...mapActions('account', ['accountForToken', 'getInterviews', 'getAccount']),
    ...mapActions('policy', ['getAccountPolicies', 'getPolicyByInterviewID']),
    ...mapActions('interview', ['reloadInterview', 'createInterview']),

    async fetchExistingAccount () {
      this.account = await this.getAccount()
    },

    async fetchExistingInterview () {
      if (this.interviewInState) {
        this.interview = this.interviewInState
      } else {
        // get interview ID:
        const interviews = await this.getInterviews()
        if (interviews && interviews.length) {
          const interviewID = interviews[0].id
          // now fetch interview and make sure it is in state:
          this.interview = await this.reloadInterview({ id: interviewID })
        } else {
          this.noExistingInterview = true
        }
      }
    },

    async fetchExistingPolicy () {
      if (this.interviewInState) {
        const interviewId = this.interviewInState.id
        const policy = await this.getPolicyByInterviewID(interviewId)
        if (policy) {
          this.policy = policy
          if (this.policy.status === PolicyStatus.IN_GOOD_STANDING) {
            const flags = this.policy.flags
            if (flags && flags.indexOf(PolicyFlag.INCORRECT_POLICY_PACKET) !== -1) {
              this.showInterstitial = true
              this.hidePolicyDetails = true
            } else {
              this.showPolicy = true
            }
          } else if (this.policy.status === PolicyStatus.PENDING && this.policy.flags.includes('PAYMENT_FAILED')) {
            this.showUpdatePaymentInfo = true
          }
        }
      } else {
        const policies = await this.getAccountPolicies(this.account.id)
        if (policies && policies.length) {
          this.policy = policies[0]
          if (this.policy.status === PolicyStatus.IN_GOOD_STANDING) {
            this.showPolicy = true
          } else if (this.policy.status === PolicyStatus.PENDING && this.policy.flags.includes('PAYMENT_FAILED')) {
            this.showUpdatePaymentInfo = true
          }
        }
      }
    }
  },

  async mounted () {
    this.isLoading = true
    await this.fetchExistingAccount()
    await this.fetchExistingPolicy()
    await this.fetchExistingInterview()
    this.isLoading = false
    if (!this.isLoading && !this.showPolicy) {
      this.showInterstitial = true
    }
  }

}
</script>
