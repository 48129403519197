<template>
  <div class="logo">
    <svg :height="size" viewBox="0 0 564 102" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Dayforward logo</title>
      <g clip-path="url(#clip0)">
        <path d="M58.69 1.22998V78.31H43.06V71.7L37.26 78C34.8343 78.9656 32.2404 79.4381 29.63 79.39C14.22 79.66 2.13 67.39 2.4 52.97C2.37682 49.0364 3.26684 45.1512 5 41.62C7.12158 37.5272 9.87542 33.7947 13.16 30.56L20.68 22.88C23.3634 21.74 26.2548 21.1713 29.17 21.21C35.17 21.21 40.17 23.36 43.08 26.58V1.22998H58.69ZM44.29 51.77C44.46 42.21 37.04 35.29 29.29 35.45C22.84 35.45 17.29 40.6 17.29 48.87C17.13 58.43 24.43 65.31 32.17 65.15C38.65 65.15 44.29 60 44.29 51.77Z" :fill="color"/>
        <path d="M110.39 40.71V78.31H94.76V73L89.87 78C87.542 78.8945 85.0636 79.3325 82.57 79.29C72.31 79.54 64 71.92 64.2 61.66C64.04 50.66 73.12 43.07 84.2 43.23C87.9094 43.1143 91.5763 44.0466 94.78 45.92V41C94.78 36.49 92.47 33.48 88.34 33.48C87.4971 33.423 86.6512 33.5333 85.851 33.8044C85.0508 34.0755 84.312 34.5021 83.6773 35.0597C83.0426 35.6173 82.5243 36.2949 82.1524 37.0535C81.7804 37.8121 81.5621 38.6367 81.51 39.48H66.13C66.46 28.42 77.13 21.17 88.53 21.27C100.72 21.11 110.5 27.71 110.39 40.71ZM95.67 60.37C95.67 56.37 92.24 53.23 87.67 53.23C83.1 53.23 79.89 56.45 79.89 60.48C79.8751 61.4447 80.0633 62.4017 80.4423 63.289C80.8213 64.1762 81.3827 64.9738 82.09 65.63C82.8348 66.332 83.7115 66.8791 84.6694 67.2396C85.6273 67.6001 86.6472 67.7668 87.67 67.73C92.24 67.73 95.67 64.5 95.67 60.37Z" :fill="color"/>
        <path d="M174.66 22.29L157.42 75.14C156.678 77.4863 155.706 79.7538 154.52 81.91C153.308 83.9305 151.865 85.803 150.22 87.49L138.94 99.42H121.6V84.7H134C137.06 84.7 139.15 83.03 140.28 79.54L142.28 72.94H130.89L111.6 22.29H129.17L144.8 64.56L157.74 22.29H174.66Z" :fill="color"/>
        <path d="M206 14.88C202.13 14.88 200.84 16.16 200.84 20.03V22.29H212.33V35.72H200.79V78.31H185.16V35.72H175.87V22.29H185.16V20.94C185.16 17.45 186.23 15.36 188.81 12.73L199.72 1.29001H212.28V14.88H206Z" :fill="color"/>
        <path d="M211.3 53.12C211.282 49.3197 212.031 45.5548 213.5 42.05C215.36 38.0783 217.925 34.4767 221.07 31.42L229.24 23.09C232.561 21.742 236.127 21.1017 239.71 21.21C255.71 20.94 268.07 33.21 267.8 47.53C267.839 51.3047 267.107 55.0477 265.65 58.53C263.724 62.5966 261.066 66.2744 257.81 69.38L250 77.45C246.625 78.824 243.002 79.4812 239.36 79.38C223.43 79.65 211 67.41 211.3 53.12ZM252.87 51.77C253.03 42.48 245.67 35.29 238.04 35.45C231.71 35.45 226.17 40.6 226.17 48.82C226.01 58.11 233.37 65.31 241 65.15C247.33 65.15 252.87 60 252.87 51.77Z" :fill="color"/>
        <path d="M305.82 22.29V38.19H297.77C291.05 38.19 287.83 41.25 287.83 48.07V78.31H272.2V22.31H287.83V31.31L296.37 22.31L305.82 22.29Z" :fill="color"/>
        <path d="M377 22.29H393.28L378.88 78.29H357.52L350.27 37.9L342.69 78.29H321.69L307.25 22.29H323.79L332.6 64.34L339.85 22.29H360.74L368.31 65L377 22.29Z" :fill="color"/>
        <path d="M438.82 40.71V78.31H423.19V73L418.3 78C415.968 78.8943 413.487 79.3323 410.99 79.29C400.73 79.45 392.46 71.83 392.62 61.57C392.46 50.57 401.54 42.98 412.62 43.14C416.333 43.0229 420.003 43.9553 423.21 45.83V41C423.21 36.49 420.9 33.48 416.76 33.48C415.918 33.4244 415.073 33.5357 414.274 33.8074C413.475 34.0791 412.737 34.506 412.104 35.0634C411.47 35.6209 410.953 36.298 410.581 37.0559C410.21 37.8138 409.992 38.6376 409.94 39.48H394.56C394.88 28.42 405.56 21.17 416.96 21.27C429.15 21.11 438.92 27.71 438.82 40.71ZM424.1 60.37C424.1 56.37 420.66 53.23 416.1 53.23C411.54 53.23 408.31 56.45 408.31 60.48C408.295 61.4447 408.483 62.4017 408.862 63.289C409.241 64.1762 409.803 64.9738 410.51 65.63C411.256 66.3328 412.135 66.8803 413.094 67.2408C414.054 67.6013 415.076 67.7676 416.1 67.73C420.66 67.73 424.1 64.5 424.1 60.37Z" :fill="color"/>
        <path d="M480.53 22.29V38.19H472.47C465.76 38.19 462.53 41.25 462.53 48.07V78.31H446.9V22.31H462.53V31.31L471.07 22.31L480.53 22.29Z" :fill="color"/>
        <path d="M536 1.22998V78.31H520.36V71.7L514.56 77.99C512.134 78.9556 509.54 79.4281 506.93 79.38C491.52 79.65 479.43 67.38 479.7 52.96C479.672 49.0314 480.555 45.1498 482.28 41.62C484.402 37.5272 487.155 33.7947 490.44 30.56L498 22.88C500.684 21.742 503.575 21.1733 506.49 21.21C512.49 21.21 517.49 23.36 520.4 26.58V1.22998H536ZM521.6 51.77C521.76 42.21 514.35 35.29 506.6 35.45C500.16 35.45 494.6 40.6 494.6 48.87C494.44 58.43 501.74 65.31 509.48 65.15C516 65.15 521.6 60 521.6 51.77Z" :fill="color"/>
        <template v-if="showTrademark">
          <path d="M547.876 2.32631H544.187V13.2222H542.681V2.32631H539V1H547.876V2.32631Z" :fill="color"/>
          <path d="M551.533 1L555.285 10.9725L559.037 1H561V13.2222H559.487V8.46261L559.628 3.32524L555.861 13.2222H554.702L550.942 3.35043L551.092 8.46261V13.2222H549.578V1H551.533Z" :fill="color"/>
        </template>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="563" height="102" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>

import Colors from '@/assets/styles/_colors.scss'

export default {
  name: 'logo',
  props: {
    color: {
      type: String,
      default: Colors.red
    },
    size: {
      type: String,
      default: '34'
    },
    showTrademark: {
      type: Boolean,
      default: false
    },
    componentStyles: {
      type: Array,
      default: undefined
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/colors";
.logo {
  display: inline-flex;
}
</style>
