<template>
  <Modal
    title="Am I approved for this policy?"
    :showActions="false"
    v-model="isShowing"
    @close="onClose"
    persistent
  >
    <template v-slot:content>
      <p>
        You're almost there! What you see is a real price; we just have a few
        outstanding questions to confirm approval for this policy. During the
        checkout process and our final review, if we find some errors, we may
        need to change your price, but we'll let you know if that's the case.
      </p>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'checkout-now-modal',
  components: { Modal },
  data () {
    return {
      isShowing: this.show
    }
  },
  props: {
    show: Boolean
  },

  watch: {
    show (newVal) {
      this.isShowing = newVal
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    }
  }
}
</script>
