<template>
  <div>
    <p>(Including MIB, Inc. Notice and Fair Credit Reporting Act Notice)</p>
    <p><strong>NOTICE OF INFORMATION PRACTICES</strong></p>
    <p>Dayforward Life Insurance Company will need to collect information about you in order to issue a life insurance
      policy. You are our most important source of information. We may also need to collect information from other
      sources. In certain circumstances as permitted or required by law, Dayforward Life Insurance Company may disclose
      this information to third parties without your authorization. You have the right to access and if necessary, to
      correct this information. You may send a written request to Dayforward Life Insurance Company at the address below
      for a more detailed explanation of our information practices, including your right to access and correct
      information.</p>
    <p>Dayforward Life Insurance Company</p>
    <p> 1133 Broadway, Suite 525<br/> New York, NY 10010</p>
    <br/>
    <p><strong>NOTICE TO UNITED STATES RESIDENTS UNDER FAIR CREDIT REPORTING ACT</strong></p>
    <p>In compliance with federal law, you are entitled to know that, as part of our routine selection procedure, we may
      request an investigative consumer report (&ldquo;report&rdquo;) concerning the insurability of each person
      proposed for coverage. This report would include information as to character, general reputation, personal
      characteristics, and mode of living obtained through personal interviews with friends, neighbors, and associates.
      It may contain information on your: past and present employment (including job duties); driving record; health
      history; use of alcohol or drugs; sports; hobbies; aviation activities; and marital status.</p>
    <p>If you write to us within a reasonable time after you receive this Notice, we will tell you whether or</p>
    <p>not a report was requested. If a report was requested, we will tell you the name, address, and telephone number
      of the agency to whom the request was made. Upon request, the agency will furnish information as to the nature and
      scope of its investigation. If you would like to inspect and to receive a copy of the report, you may do so by
      contacting the agency directly.</p>
    <p>In order to provide the best possible products on the most favorable basis, we sincerely believe that the
      consumer investigative report is an essential and proper tool to assist us in meeting these objectives. We will do
      our best to serve you both now and in the future. Please call us any time at our toll-free number: <a href="tel:+18884340228">1-888-434-0228</a>.
      You may obtain a written summary of your rights under the Fair Credit Reporting Act online at <a
        href="http://www.ftc.gov/credit" target="_blank">www.ftc.gov/credit</a> or by writing to: Consumer Response Center, Room 130-A,
      Federal Trade Commission, 600 Pennsylvania Avenue, NW, Washington, DC 20580.&nbsp;</p>
    <p><strong>West Virginia Notice: No information collected concerning the sexual orientation of the Proposed Insured
      will be used to determine his/her eligibility for insurance.</strong></p>
    <br/>
    <p><strong>MIB PRE-NOTICE</strong></p>
    <p>Information regarding your insurability will be treated as confidential. Dayforward Life Insurance Company, Inc.
      (“Dayforward”) or its reinsurers may, however, make a brief report thereon to MIB, Inc., a not-for-profit
      membership organization of insurance companies, which operates an information exchange on behalf of its Members.
      If you apply to another MIB Member company for life or health insurance coverage, or a claim for benefits is
      submitted to such a company. MIB, upon request, will supply said company with the information in its file. Upon
      receipt of the request from you MIB will arrange disclosure of any information it may have in your file. Please
      contact MIB at 866-692-6901. If you question the accuracy of information in MIB’s file, you may contact MIB and
      seek a correction in accordance with the procedures set forth in the federal Fair Credit Reporting Act. The
      address of MIB’s information office is 50 Braintree Hill Park, Suite 400 Braintree, MA 02184-8734. Dayforward, or
      its reinsurers, may also release information in its file to other insurance companies to whom you may apply for
      life or health insurance, or to whom a claim for benefits may be submitted. Information for consumers about MIB,
      Inc. may be obtained on its website at <a target="_blank" href="https://www.mib.com">https://www.mib.com</a></p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'consumer-protection-modal',

  methods: {
    ...mapActions('content', ['fetchContentfulAsset'])
  },

  async created () {
    const url = await this.fetchContentfulAsset({
      id: '7cnamiYy5gZy7gJRuetEqG'
    })
    if (url) {
      this.$emit('pdfURLGenerated', url)
    }
  }
}
</script>
