<template>
  <div class="height-input" v-if="!loading">
    <v-select
      v-model="feet"
      hide-details
      :items="feetOptions"
      placeholder="ft."
      :aria-labelledby="id"
      :rules="rules"
      :error="error"
      @change="onChangeFeet"
      @blur="onBlur"
      :readonly="readonly"
    />
    <v-select
      v-model="inches"
      hide-details
      :items="inchOptions()"
      placeholder="in."
      :aria-labelledby="id"
      :rules="rules"
      :error="error"
      @change="onChangeInches"
      @blur="onBlur"
      :readonly="readonly"
    />
  </div>
</template>

<script>
export default {
  name: 'HeightInput',
  props: {
    value: {
      type: String
    },
    rules: {
      type: Array
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    error: Boolean,
    id: String
  },
  data () {
    return {
      loading: true,
      feet: '',
      inches: '',
      feetOptions: ['4', '5', '6', '7'],
      inchOptions: () => Array.from(Array(12).keys()).map(k => k.toString())
    }
  },
  methods: {
    calc (feet, inches) {
      feet = parseInt(feet, 10) || 0
      inches = parseInt(inches, 10) || 0
      return feet * 12 + inches
    },
    onBlur (e) {
      this.$emit('blur', e)
    },
    onChangeInches (e) {
      const height = this.calc(this.feet, e)
      this.$emit('input', String(height))
    },
    onChangeFeet (e) {
      const height = this.calc(e, this.inches)
      this.$emit('input', String(height))
    }
  },
  mounted () {
    this.loading = true
    const totalInches = parseInt(this.value, 10) || 0
    if (!isNaN(totalInches) && totalInches > 0) {
      this.feet = String(Math.trunc(totalInches / 12))
      this.inches = String(totalInches % 12)
    }
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
.height-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
</style>
