import { titleCase } from '@/utils/helpers'

export const AccountStatus = {
  HAS_EXISTING_INTERVIEW: 'HAS_EXISTING_INTERVIEW',
  HAS_EXISTING_ACCOUNT: 'HAS_EXISTING_ACCOUNT',
  HAS_EXISTING_POLICY: 'HAS_EXISTING_POLICY',
  HAS_NO_EXISTING_INTERVIEW: 'HAS_NO_EXISTING_INTERVIEW'
}

export const InterviewStatus = {
  ACCEPTED: 'ACCEPTED',
  AWAITING_SIGNATURE_COMPLETION: 'AWAITING_SIGNATURE_COMPLETION',
  AWAITING_SIGNATURE_CONFIRMATION: 'AWAITING_SIGNATURE_CONFIRMATION',
  ERROR: 'ERROR',
  EXPIRED: 'EXPIRED',
  INACTIVE: 'INACTIVE',
  INCOMPLETE: 'INCOMPLETE',
  INELIGIBLE: 'INELIGIBLE',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  NEW: 'NEW',
  READY_TO_SIGN: 'READY_TO_SIGN',
  READY_TO_SUBMIT: 'READY_TO_SUBMIT',
  REJECTED: 'REJECTED',
  SUBMITTED: 'SUBMITTED',
  READY_FOR_CHECKOUT: 'READY_FOR_CHECKOUT'
}

export const InterviewStatusNames = {
  ACCEPTED: 'Accepted',
  AWAITING_SIGNATURE_CONFIRMATION: 'Signature Processing',
  AWAITING_SIGNATURE_COMPLETION: 'Signature Pending',
  ERROR: 'Error',
  EXPIRED: 'Expired',
  INACTIVE: 'Inactive',
  INCOMPLETE: 'Incomplete',
  INELIGIBLE: 'Ineligible',
  NEEDS_REVIEW: 'Needs Review',
  NEW: 'New',
  READY_TO_SIGN: 'Ready to Sign',
  READY_TO_SUBMIT: 'Ready to Submit',
  REJECTED: 'Rejected',
  SUBMITTED: 'Submitted',
  READY_FOR_CHECKOUT: "Ready for checkout"
}

export const interviewStatusName = (status) => {
  let name = InterviewStatusNames[status]
  if (!name || name !== '') {
    name = titleCase(name)
  }
  return name
}

export const InterviewFlagSource = {
  UNDEFINED_SOURCE: 'UNDEFINED_SOURCE',
  RECONCILIATION: 'RECONCILIATION',
  ID_CHECK: 'ID_CHECK',
  PRE: 'PRE',
  MVR: 'MVR',
  IRX: 'IRX',
  PQ: 'PQ',
  HEALTH_CHECK: 'HEALTH_CHECK',
  QUOTE: 'QUOTE',
  RISK_SCORE: 'RISK_SCORE',
  ESIGN: 'ESIGN',
  DEDUPE: 'DEDUPE',
  RISK_SCORE_HISTORY_CHECK: 'RISK_SCORE_HISTORY_CHECK',
  INTERACTION: 'INTERACTION',
  STATUS_CHECK: 'STATUS_CHECK',
  NEEDS_REVIEW_CHECK: 'NEEDS_REVIEW_CHECK',
  READY_FOR_MANUAL_UNDERWRITING_CHECK: 'READY_FOR_MANUAL_UNDERWRITING_CHECK',
  CANNOT_MODIFY_CHECK: 'CANNOT_MODIFY_CHECK',
  READY_FOR_CUSTOMER_SERVICE_CHECK: 'READY_FOR_CUSTOMER_SERVICE_CHECK',
  ADMIN_MANUAL_OVERRIDE_INSTANTID: 'ADMIN_MANUAL_OVERRIDE_INSTANTID'
}

export const InterviewFlagType = {
  UNDEFINED_TYPE: 'UNDEFINED_TYPE',
  INELIGIBLE: 'INELIGIBLE',
  FAILURE: 'FAILURE',
  EXCEPTION: 'EXCEPTION',
  MORE_DATA_REQUIRED: 'MORE_DATA_REQUIRED',
  ESTIMATE: 'ESTIMATE',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  INFO: 'INFO',
  FROZEN: 'FROZEN',
  CHAT_REQUIRED: 'CHAT_REQUIRED',
  SIGN_IN_REQUIRED: 'SIGN_IN_REQUIRED',
  FINAL_REVIEW: 'FINAL_REVIEW',
  MANUAL_ID_VERIFICATION: 'MANUAL_ID_VERIFICATION'
}

export const InterviewFlagCause = {
  UNDEFINED_CAUSE: 'UNDEFINED_CAUSE',
  INCOME: 'INCOME',
  LOCATION: 'LOCATION',
  OTHER: 'OTHER',
  ATTESTATION: 'ATTESTATION',
  APPLICATION_INCOMPLETE: 'APPLICATION_INCOMPLETE',
  UNINSURABLE: 'UNINSURABLE',
  APPLICANT_REQUEST: 'APPLICANT_REQUEST',
  UNRESPONSIVE_APPLICANT: 'UNRESPONSIVE_APPLICANT',
  ELIGIBILITY: 'ELIGIBILITY',
  REFERRED_TO_UNDERWRITER: 'REFERRED_TO_UNDERWRITER',
  REFERRED_TO_CUSTOMER_SERVICE: 'REFERRED_TO_CUSTOMER_SERVICE',
  NO_RISK_SCORE: 'NO_RISK_SCORE',
  ID_CHECK_INCOMPLETE: 'ID_CHECK_INCOMPLETE',
  ID_CHECK_INCONCLUSIVE: 'ID_CHECK_INCONCLUSIVE',
  PRE_INCOMPLETE: 'PRE_INCOMPLETE',
  PRE_UNSUCCESSFUL: 'PRE_UNSUCCESSFUL',
  MVR_INCOMPLETE: 'MVR_INCOMPLETE',
  MVR_UNSUCCESSFUL: 'MVR_UNSUCCESSFUL',
  IRX_INCOMPLETE: 'IRX_INCOMPLETE',
  IRX_UNSUCCESSFUL: 'IRX_UNSUCCESSFUL',
  PQ_INCOMPLETE: 'PQ_INCOMPLETE',
  PQ_UNSUCCESSFUL: 'PQ_UNSUCCESSFUL',
  PQ_DATA_CHANGED: 'PQ_DATA_CHANGED',
  NO_FINAL_QUOTE: 'NO_FINAL_QUOTE',
  PREVIOUSLY_INELIGIBLE: 'PREVIOUSLY_INELIGIBLE',
  MULTIPLE_ACCOUNTS: 'MULTIPLE_ACCOUNTS',
  EXISTING_FINGERPRINT: 'EXISTING_FINGERPRINT',
  RISK_CLASS_DECREASE_AFTER_RECEIVED_FINALIZED_QUOTE: 'RISK_CLASS_DECREASE_AFTER_RECEIVED_FINALIZED_QUOTE',
  STATUS_CHANGE_AFTER_NOTIFIED_INELIGIBLE: 'STATUS_CHANGE_AFTER_NOTIFIED_INELIGIBLE',
  STATUS_CHANGE_AFTER_NOTIFIED_NEEDS_REVIEW: 'STATUS_CHANGE_AFTER_NOTIFIED_NEEDS_REVIEW',
  RISK_SCORE_FLAGS: 'RISK_SCORE_FLAGS',
  NO_QUOTE: 'NO_QUOTE',
  NO_ACCOUNT_ID: 'NO_ACCOUNT_ID',
  POLICY_SUBMISSION_PROCESSING: 'POLICY_SUBMISSION_PROCESSING',
  FROZEN_NEEDS_REVIEW: 'FROZEN_NEEDS_REVIEW',
  READY_FOR_MANUAL_UNDERWRITING: 'READY_FOR_MANUAL_UNDERWRITING',
  AWAITING_SIGNATURE_CONFIRMATION: 'AWAITING_SIGNATURE_CONFIRMATION',
  AWAITING_SIGNATURE_COMPLETION: 'AWAITING_SIGNATURE_COMPLETION',
  SIGNATURE_CONFIRMED_READY_TO_SUBMIT: 'SIGNATURE_CONFIRMED_READY_TO_SUBMIT',
  SUBMITTED: 'SUBMITTED',
  CAN_MODIFY_ASSERT_FAILURE: 'CAN_MODIFY_ASSERT_FAILURE',
  REPLACEMENT_POLICY: 'REPLACEMENT_POLICY',
  FRAUD_DETECTION: 'FRAUD_DETECTION',
  READY_FOR_CUSTOMER_SERVICE: 'READY_FOR_CUSTOMER_SERVICE',
  ID_MISMATCH: 'ID_MISMATCH',
  SPOUSAL_SIGNATURE_REQUIRED: 'SPOUSAL_SIGNATURE_REQUIRED',
  GENERIC: 'GENERIC'
}

export const BeneficiaryRelationship = {
  UNDEFINED: 'UNDEFINED',
  SPOUSE_OR_PARTNER: 'SPOUSE_OR_PARTNER',
  EX_SPOUSE_OR_PARTNER: 'EX_SPOUSE_OR_PARTNER',
  ONE_CHILD: 'ONE_CHILD',
  ALL_CHILDREN: 'ALL_CHILDREN',
  PARENT: 'PARENT',
  SIBLING: 'SIBLING',
  NIECE_OR_NEPHEW: 'NIECE_OR_NEPHEW',
  AUNT_OR_UNCLE: 'AUNT_OR_UNCLE',
  GRANDPARENT: 'GRANDPARENT',
  FRIEND: 'FRIEND',
  TRUST: 'TRUST',
  ESTATE: 'ESTATE',
  OTHER: 'OTHER',
  SOMEONE_ELSE: 'SOMEONE_ELSE'
}

export const BeneficiaryRelationshipIndividual = {
  SPOUSE_OR_PARTNER: 'SPOUSE_OR_PARTNER',
  EX_SPOUSE_OR_PARTNER: 'EX_SPOUSE_OR_PARTNER',
  ONE_CHILD: 'ONE_CHILD',
  PARENT: 'PARENT',
  SIBLING: 'SIBLING',
  NIECE_OR_NEPHEW: 'NIECE_OR_NEPHEW',
  AUNT_OR_UNCLE: 'AUNT_OR_UNCLE',
  GRANDPARENT: 'GRANDPARENT',
  GRANDCHILD: 'GRANDCHILD',
  FRIEND: 'FRIEND',
  OTHER: 'OTHER'
}

export const BeneficiaryRelationshipReadable = {
  UNDEFINED: 'Undefined',
  SPOUSE_OR_PARTNER: 'Spouse or partner',
  EX_SPOUSE_OR_PARTNER: 'Ex-spouse or partner',
  ONE_CHILD: 'Child',
  ALL_CHILDREN: 'All your children equally',
  PARENT: 'Parent',
  SIBLING: 'Sibling',
  NIECE_OR_NEPHEW: 'Niece or nephew',
  AUNT_OR_UNCLE: 'Aunt or uncle',
  GRANDPARENT: 'Grandparent',
  GRANDCHILD: 'Grandchild',
  FRIEND: 'Friend',
  TRUST: 'Trust',
  ESTATE: 'Estate',
  OTHER: 'Someone else',
  SOMEONE_ELSE: 'Someone else'
}

export const InitialInterviewSections = {
  NAME_AND_ADDRESS: 'NAME_AND_ADDRESS',
  BASIC_INFO: 'BASIC_INFO',
  INCOME: 'INCOME',
  DRIVING: 'DRIVING',
  SUBSTANCE_USE: 'SUBSTANCE_USE',
  HEALTH: 'HEALTH',
  HEALTH_CONDITION: 'HEALTH_CONDITION',
  BENEFICIARY: 'BENEFICIARY',
  ATTESTATION: 'ATTESTATION'
}

export const FinalizePriceInterviewSections = {
  IDENTITY_FOLLOWUP: 'IDENTITY_FOLLOWUP',
  DRIVING_FOLLOWUP: 'DRIVING_FOLLOWUP',
  HEALTH_FOLLOWUP: 'HEALTH_FOLLOWUP',
  HEALTH_DIAGNOSIS: 'HEALTH_DIAGNOSIS'
}

export const QuestionCategory = {
  NAME_AND_ADDRESS: 'NAME_AND_ADDRESS',
  BASIC_INFO: 'BASIC_INFO',
  INCOME: 'INCOME',
  DRIVING: 'DRIVING',
  SUBSTANCE_USE: 'SUBSTANCE_USE',
  HEALTH: 'HEALTH',
  HEALTH_CONDITION: 'HEALTH_CONDITION',
  HEALTH_FOLLOWUP: 'HEALTH_FOLLOWUP',
  HEALTH_DIAGNOSIS: 'HEALTH_DIAGNOSIS',
  DRIVING_FOLLOWUP: 'DRIVING_FOLLOWUP',
  IDENTITY_FOLLOWUP: 'IDENTITY_FOLLOWUP',
  IDENTITY_CONFIRMATION: 'IDENTITY_CONFIRMATION',
  BENEFICIARY: 'BENEFICIARY',
  ATTESTATION: 'ATTESTATION'
}

export const QuestionKey = {
  FIRST_NAME: 'FIRST_NAME',
  MIDDLE_INITIAL: 'MIDDLE_INITIAL',
  LAST_NAME: 'LAST_NAME',
  EMAIL_ADDRESS: 'EMAIL_ADDRESS',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
  HEIGHT_INCHES: 'HEIGHT_INCHES',
  WEIGHT_POUNDS: 'WEIGHT_POUNDS',
  GENDER: 'IDENTIFY_AS_GENDER',
  ADDRESS_LINE_1: 'HOME_ADDRESS_1',
  ADDRESS_LINE_2: 'HOME_ADDRESS_2',
  ADDRESS_CITY: 'HOME_CITY',
  ADDRESS_STATE: 'HOME_STATE',
  ADDRESS_ZIP: 'HOME_ZIP',
  ADDRESS_ZIP_PLUS_4: 'HOME_ZIP_PLUS_4',
  ACCEPTED_NOTICES: 'ACCEPTED_NOTICES',
  HAVE_SPOUSE: 'HAVE_SPOUSE',
  HAVE_CHILDREN: 'HAVE_CHILDREN',
  YOUNGEST_CHILDS_BIRTHDAY: 'YOUNGEST_CHILDS_BIRTHDAY',

  CURRENTLY_WORKING: 'CURRENTLY_WORKING',
  OCCUPATION: 'OCCUPATION',
  ANNUAL_INCOME: 'ANNUAL_PRE_TAX_INCOME',
  ANNUAL_HOUSEHOLD_INCOME: 'ANNUAL_HOUSEHOLD_PRE_TAX_INCOME',
  NOT_CURRENTLY_WORKING_REASON: 'NOT_CURRENTLY_WORKING_REASON',
  PREVIOUS_OCCUPATION: 'PREVIOUS_OCCUPATION',
  PREVIOUS_ANNUAL_INCOME: 'PREVIOUS_ANNUAL_PRE_TAX_INCOME',

  // income offramp
  ANNUAL_INCOME_CAP_ACCEPTED: 'ANNUAL_INCOME_CAP_ACCEPTED',

  // smoking
  NICOTINE_FREQUENCY: 'NICOTINE_FREQUENCY',
  MARIJUANA_FREQUENCY: 'MARIJUANA_FREQUENCY',
  ALCOHOL_FREQUENCY: 'ALCOHOL_FREQUENCY',

  // driving info
  HAS_DRIVERS_LICENSE: 'HAS_DRIVERS_LICENSE',
  DRIVERS_LICENSE_NUMBER: 'DRIVERS_LICENSE_NUMBER',
  DRIVERS_LICENSE_STATE: 'DRIVERS_LICENSE_HOME_STATE',
  NO_DRIVERS_LICENSE_REASON: 'NO_DRIVERS_LICENSE_REASON',
  TRAFFIC_TICKET_COUNT: 'TRAFFIC_TICKET_COUNT',
  TRAFFIC_TICKET_REASONS: 'TRAFFIC_TICKET_REASONS',
  HAD_DUI: 'HAD_DUI',

  // health
  HEALTH_CONDITIONS: 'HEALTH_CONDITIONS',
  ANNUAL_CHECKUP: 'ANNUAL_CHECKUP',
  PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE: 'PARENT_OR_SIBLING_DIAGNOSED_WITH_CANCER_OR_HEART_DISEASE',
  PENDING_TEST_OR_PROCEDURE: 'PENDING_TEST_OR_PROCEDURE',
  PENDING_TEST_SERIOUS_OR_ROUTINE: 'PENDING_TEST_SERIOUS_OR_ROUTINE',
  WHEN_WILL_YOU_HAVE_THE_RESULTS: 'WHEN_WILL_YOU_HAVE_THE_RESULTS',
  HEALTH_CONDITIONS_ACKNOWLEDGED: 'HEALTH_CONDITIONS_ACKNOWLEDGED',

  // confirm identity
  SSN: 'SSN',
  PLACE_BORN: 'PLACE_BORN',
  MOBILE_NUMBER: 'MOBILE_PHONE_NUMBER',

  // beneficiary
  BENEFICIARY_TYPE: 'BENEFICIARY_TYPE',
  BENEFICIARY_RELATIONSHIP: 'BENEFICIARY_RELATIONSHIP',
  BENEFICIARY_FIRST_NAME: 'BENEFICIARY_FIRST_NAME',
  BENEFICIARY_LAST_NAME: 'BENEFICIARY_LAST_NAME',
  BENEFICIARY_BIRTH_DATE: 'BENEFICIARY_BIRTH_DATE',
  BENEFICIARY_EMAIL_ADDRESS: 'BENEFICIARY_EMAIL_ADDRESS',
  BENEFICIARY_MOBILE_PHONE_NUMBER: 'BENEFICIARY_MOBILE_PHONE_NUMBER',
  BENEFICIARY_HAS_FINANCIAL_IMPACT: 'BENEFICIARY_HAS_FINANCIAL_IMPACT',
  BENEFICIARY_GRANT_POLICY_ACCESS: 'BENEFICIARY_GRANT_POLICY_ACCESS',
  BENEFICIARY_TRUST_TYPE: 'BENEFICIARY_TRUST_TYPE',
  BENEFICIARY_TRUST_FORMATION_DATE: 'BENEFICIARY_TRUST_FORMATION_DATE',
  BENEFICIARY_TRUST_NAME: 'BENEFICIARY_TRUST_NAME',

  // attestations
  HAS_PRIMARY_CARE_PHYSICIAN: 'HAS_PRIMARY_CARE_PHYSICIAN',
  PCP_NAME: 'PCP_NAME',
  PCP_CITY: 'PCP_CITY',
  PCP_STATE: 'PCP_STATE',
  IS_US_CITIZEN_OR_LEGAL_RESIDENT: 'IS_US_CITIZEN_OR_LEGAL_RESIDENT',
  HAS_FELONY_OR_USED_ILLEGAL_DRUGS: 'HAS_FELONY_OR_USED_ILLEGAL_DRUGS',
  HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY: 'HAS_PARTICIPATED_IN_HAZARDOUS_ACTIVITY',
  HAS_AIDS_ARC_HIV_DIAGNOSIS: 'HAS_AIDS_ARC_HIV_DIAGNOSIS',
  HAS_BEEN_INDUCED: 'HAS_BEEN_INDUCED',
  HAS_BEEN_DECLINED: 'HAS_BEEN_DECLINED',
  HAS_EXISTING_COVERAGE: 'HAS_EXISTING_COVERAGE',
  EXISTING_COVERAGE_AMOUNT: 'EXISTING_COVERAGE_AMOUNT',
  IS_REPLACEMENT_POLICY: 'IS_REPLACEMENT_POLICY',
  HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE: 'HAS_ACCEPTED_MILITARY_SALES_DISCLOSURE',

  // Family
  FAMILY_HISTORY: 'FAMILY_HISTORY'

}

export const EmploymentCode = {
  UNSPECIFIED: 'UNSPECIFIED',
  EMPLOYED: 'EMPLOYED',
  RECENTLY_EMPLOYED: 'RECENTLY_EMPLOYED',
  UNEMPLOYED: 'UNEMPLOYED'
}

export const BenefitTermCode = {
  UNSPECIFIED: 'UNSPECIFIED',
  DEFAULT: 'DEFAULT',
  INSURED_AGE: 'INSURED_AGE',
  CHILD_AGE: 'CHILD_AGE',
  MINIMUM_POLICY: 'MINIMUM_POLICY',
  CHILD_INDEPENDENT: 'CHILD_INDEPENDENT'
}

export const BenefitType = {
  UNSPECIFIED: 'UNSPECIFIED',
  INCOME_REPLACEMENT: 'INCOME_REPLACEMENT',
  EXPENSE_COVERAGE: 'EXPENSE_COVERAGE'
}

export const DiagnosisConcerns = {
  DIAGNOSED_WHEN: 'DIAGNOSED_WHEN',
  TREATMENT: 'TREATMENT',
  SYMPTOM_WHEN: 'SYMPTOM_WHEN',
  COMPLICATIONS: 'COMPLICATIONS',
  MEDICATION: 'MEDICATION',
  WHEN_SURGERY: 'WHEN_SURGERY',
  A1C: 'A1C',
  FAMILY_HISTORY: 'FAMILY_HISTORY',
  TREATMENT_SURGERY_EXPLICATION: 'TREATMENT_SURGERY_EXPLICATION'
}

export const FollowupConcerns = {
  DIAGNOSIS: 'DIAGNOSIS',
  OTHER: 'OTHER'
}

// to help queries for details questions
// when diagnosis is 'other'
export const DiagnosisCategories = {
  NERVOUS_SYSTEM_DIAGNOSES: 'NERVOUS_SYSTEM',
  MENTAL_HEALTH_DIAGNOSES: 'MENTAL_HEALTH',
  SUBSTANCE_ABUSE_DIAGNOSES: 'SUBSTANCE_ABUSE',
  CARDIOVASCULAR_DIAGNOSES: 'CARDIOVASCULAR',
  RESPIRATORY_DIAGNOSES: 'RESPIRATORY',
  GASTROINTESTINAL_DIAGNOSES: 'GASTROINTESTINAL',
  URINARY_REPRODUCTIVE_DIAGNOSES: 'URINARY_REPRODUCTIVE',
  DIABETES_ENDOCRINE_DIAGNOSES: 'DIABETES_ENDOCRINE',
  MUSCULOSKELETAL_SKIN_DIAGNOSES: 'MUSCULOSKELETAL_SKIN',
  CANCER_DIAGNOSES: 'CANCER',
  TUMOR_BLOOD_DIAGNOSES: 'TUMOR_BLOOD'
}

export const Conditions = {
  NERVOUS_SYSTEM_DIAGNOSES: 'nervous system condition.',
  MENTAL_HEALTH_DIAGNOSES: 'emotional or mood disorder.',
  SUBSTANCE_ABUSE_DIAGNOSES: 'drug or alcohol condition.',
  CARDIOVASCULAR_DIAGNOSES: 'heart or cardiovascular condition.',
  RESPIRATORY_DIAGNOSES: 'respiratory or breathing condition.',
  GASTROINTESTINAL_DIAGNOSES: 'gastrointestinal condition.',
  URINARY_REPRODUCTIVE_DIAGNOSES: 'urinary or reproductive condition.',
  DIABETES_ENDOCRINE_DIAGNOSES: 'diabetes or endocrine condition.',
  MUSCULOSKELETAL_SKIN_DIAGNOSES: 'musculoskeletal or skin condition.',
  CANCER_DIAGNOSES: 'cancer, leukemia, or lymphoma.',
  TUMOR_BLOOD_DIAGNOSES: 'tumor or blood disorder.'
}

export const Surgeries = {
  SURGERY: 'SURGERY',
  ABLATION: 'ABLATION',
  STENT: 'STENT',
  HEART_BYPASS: 'HEART_BYPASS',
  VALVE_REPLACEMENT: 'VALVE_REPLACEMENT',
  PACEMAKER: 'PACEMAKER',
  DEFIBRILLATOR: 'DEFIBRILLATOR',
  OTHER_SURGERY: 'OTHER_SURGERY',
  WEIGHT_LOSS_SURGERY: 'WEIGHT_LOSS_SURGERY',
  WEIGHT_LOSS_PROCEDURE: 'WEIGHT_LOSS_PROCEDURE'
}

export const SpecialDiagnoses = {
  INSULIN: 'INSULIN',
  NONINSULIN: 'NONINSULIN',
  GLUCOSE: 'GLUCOSE',
  GESTATION: 'GESTATION',
  DYSPLASTIC_NEVUS_SYNDROME: 'DYSPLASTIC_NEVUS_SYNDROME'
}

export const ErrorFlags = {
  HAS_EXISTING_POLICY: 'HAS_EXISTING_POLICY',
  HAS_EXISTING_INTERVIEW: 'HAS_EXISTING_INTERVIEW'
}

export const InteractionType = {
  UNDEFINED_TYPE: 'UNDEFINED_TYPE',
  NOTIFIED_INELIGIBLE: 'NOTIFIED_INELIGIBLE',
  NOTIFIED_NEEDS_REVIEW: 'NOTIFIED_NEEDS_REVIEW',
  RECEIVED_FINALIZED_QUOTE: 'RECEIVED_FINALIZED_QUOTE',
  SIGN_IN: 'SIGN_IN',
  SUBMITTED_FOR_POLICY: 'SUBMITTED_FOR_POLICY',
  APPLICATION_SIGNED: 'APPLICATION_SIGNED',
  NOTIFIED_LETS_CHAT: 'NOTIFIED_LETS_CHAT',
  COPIED: 'COPIED',
  PAYMENT_INFO_ENTERED: 'PAYMENT_INFO_ENTERED',
  INSTANTID_CORRECTED_DOB: 'INSTANTID_CORRECTED_DOB',
  RISK_SCORE_REQUESTED: 'RISK_SCORE_REQUESTED',
  AGENT_NOTIFIED_APPLICANT_ACCEPTED_NOTICES: 'AGENT_NOTIFIED_APPLICANT_ACCEPTED_NOTICES'
}

export const SupportedStates = [
  'TX'
]

export const SectionDisplayOption = {
  UNDEFINED: 'UNDEFINED',
  HIDE_BACK_BUTTON: 'HIDE_BACK_BUTTON',
  HIDE_EDIT_LINK: 'HIDE_EDIT_LINK'
}

export const SectionID = {
  UNDEFINED: 'UNDEFINED',
  NAME_AND_ADDRESS: 'NAME_AND_ADDRESS',
  CONTACT_INFO: 'CONTACT_INFO',
  BASIC_INFO: 'BASIC_INFO',
  INCOME: 'INCOME',
  DRIVING: 'DRIVING',
  SUBSTANCE_USE: 'SUBSTANCE_USE',
  HEALTH: 'HEALTH',
  IDENTITY_FOLLOWUP: 'IDENTITY_FOLLOWUP',
  HEALTH_CONDITION: 'HEALTH_CONDITION',
  DRIVING_FOLLOWUP: 'DRIVING_FOLLOWUP',
  HEALTH_FOLLOWUP_MUSCULOSKELETAL_SKIN: 'HEALTH_FOLLOWUP_MUSCULOSKELETAL_SKIN',
  HEALTH_FOLLOWUP_TUMOR_BLOOD: 'HEALTH_FOLLOWUP_TUMOR_BLOOD',
  HEALTH_FOLLOWUP_DIABETES_ENDOCRINE: 'HEALTH_FOLLOWUP_DIABETES_ENDOCRINE',
  HEALTH_FOLLOWUP_URINARY_REPRODUCTIVE: 'HEALTH_FOLLOWUP_URINARY_REPRODUCTIVE',
  HEALTH_FOLLOWUP_GASTROINTESTINAL: 'HEALTH_FOLLOWUP_GASTROINTESTINAL',
  HEALTH_FOLLOWUP_CARDIOVASCULAR: 'HEALTH_FOLLOWUP_CARDIOVASCULAR',
  HEALTH_FOLLOWUP_MENTAL_HEALTH: 'HEALTH_FOLLOWUP_MENTAL_HEALTH',
  HEALTH_FOLLOWUP_NERVOUS_SYSTEM: 'HEALTH_FOLLOWUP_NERVOUS_SYSTEM',
  HEALTH_FOLLOWUP_RESPIRATORY: 'HEALTH_FOLLOWUP_RESPIRATORY',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_CROHNS_DISEASE: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_CROHNS_DISEASE',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_CIRRHOSIS: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_CIRRHOSIS',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEMOCHROM: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEMOCHROM',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEPATITIS_B: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEPATITIS_B',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEPATITIS_C: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_HEPATITIS_C',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_LIVER: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_LIVER',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_OTHER: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_OTHER',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_PANCREATITIS: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_PANCREATITIS',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_PRIMARY_SCLEROSING_CHOLANGITIS: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_PRIMARY_SCLEROSING_CHOLANGITIS',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_REFLUX: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_REFLUX',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_THROAT: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_THROAT',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_ULCERATIVE_COLITIS: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_ULCERATIVE_COLITIS',
  HEALTH_DIAGNOSIS_GASTROINTESTINAL_ULCER: 'HEALTH_DIAGNOSIS_GASTROINTESTINAL_ULCER',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_ADHD: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_ADHD',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_ANXIETY: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_ANXIETY',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_BIPOLAR: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_BIPOLAR',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_EATING: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_EATING',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_OCD: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_OCD',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_OTHER: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_OTHER',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_PSYCHOTIC: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_PSYCHOTIC',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_PTSD: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_PTSD',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_ALS: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_ALS',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_AUTOIMMUNE: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_AUTOIMMUNE',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_CHRONICPAIN: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_CHRONICPAIN',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_DIAGNOSIS: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_DIAGNOSIS',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_DYSPLASTIC_NEVUS_SYNDROME: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_DYSPLASTIC_NEVUS_SYNDROME',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_GOUT: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_GOUT',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_MARFANS: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_MARFANS',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_MYOPATHY: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_MYOPATHY',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_OSTEO: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_OSTEO',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_OTHER: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_OTHER',
  HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_RHEUMATOID_ARTHRITIS: 'HEALTH_DIAGNOSIS_MUSCULOSKELETAL_SKIN_RHEUMATOID_ARTHRITIS',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_CHRONICPAIN: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_CHRONICPAIN',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_CONCUSSION: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_CONCUSSION',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_STROKE: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_STROKE',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_EPILEPSY: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_EPILEPSY',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_MIGRAINE: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_MIGRAINE',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_MULTIPLE_SCLEROSIS: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_MULTIPLE_SCLEROSIS',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_OTHER: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_OTHER',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_PARKINSONS: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_PARKINSONS',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_TRAUMATIC_BRAIN_INJURY: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_TRAUMATIC_BRAIN_INJURY',
  HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_TRANSIENT_ISCHEMIC_ATTACK: 'HEALTH_DIAGNOSIS_NERVOUS_SYSTEM_TRANSIENT_ISCHEMIC_ATTACK',
  HEALTH_DIAGNOSIS_MENTAL_HEALTH_DEPRESSION: 'HEALTH_DIAGNOSIS_MENTAL_HEALTH_DEPRESSION',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_ARRHYTHMIA: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_ARRHYTHMIA',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_CORONARY_ARTERY_DISEASE: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_CORONARY_ARTERY_DISEASE',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_CARDIOMYOPATHY: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_CARDIOMYOPATHY',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_CHOLESTEROL: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_CHOLESTEROL',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_CONGENITAL: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_CONGENITAL',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_MURMUR: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_MURMUR',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_OTHER: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_OTHER',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_PRESSURE: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_PRESSURE',
  HEALTH_DIAGNOSIS_CARDIOVASCULAR_VALVE: 'HEALTH_DIAGNOSIS_CARDIOVASCULAR_VALVE',
  HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_ADRENAL: 'HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_ADRENAL',
  HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_GESTATION: 'HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_GESTATION',
  HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_GLUCOSE: 'HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_GLUCOSE',
  HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_HYPERTHYROID: 'HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_HYPERTHYROID',
  HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_INSULIN: 'HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_INSULIN',
  HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_NONINSULIN: 'HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_NONINSULIN',
  HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_OTHER: 'HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_OTHER',
  HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_PITUITARY: 'HEALTH_DIAGNOSIS_DIABETES_ENDOCRINE_PITUITARY',
  HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_TRANSPLANT: 'HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_TRANSPLANT',
  HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_PREGNANCY: 'HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_PREGNANCY',
  HEALTH_DIAGNOSIS_RESPIRATORY_APNEA: 'HEALTH_DIAGNOSIS_RESPIRATORY_APNEA',
  HEALTH_DIAGNOSIS_RESPIRATORY_ASTHMA: 'HEALTH_DIAGNOSIS_RESPIRATORY_ASTHMA',
  HEALTH_DIAGNOSIS_RESPIRATORY_CYSTIC_FIBROSIS: 'HEALTH_DIAGNOSIS_RESPIRATORY_CYSTIC_FIBROSIS',
  HEALTH_DIAGNOSIS_RESPIRATORY_COPD: 'HEALTH_DIAGNOSIS_RESPIRATORY_COPD',
  HEALTH_DIAGNOSIS_RESPIRATORY_COVID_19: 'HEALTH_DIAGNOSIS_RESPIRATORY_COVID_19',
  HEALTH_DIAGNOSIS_RESPIRATORY_OTHER: 'HEALTH_DIAGNOSIS_RESPIRATORY_OTHER',
  HEALTH_DIAGNOSIS_RESPIRATORY_PULMONARY_FIBROSIS: 'HEALTH_DIAGNOSIS_RESPIRATORY_PULMONARY_FIBROSIS',
  HEALTH_DIAGNOSIS_RESPIRATORY_SARCO: 'HEALTH_DIAGNOSIS_RESPIRATORY_SARCO',
  HEALTH_DIAGNOSIS_RESPIRATORY_VAPE: 'HEALTH_DIAGNOSIS_RESPIRATORY_VAPE',
  HEALTH_DIAGNOSIS_TUMOR_BLOOD_ANEMIA: 'HEALTH_DIAGNOSIS_TUMOR_BLOOD_ANEMIA',
  HEALTH_DIAGNOSIS_TUMOR_BLOOD_BENIGN: 'HEALTH_DIAGNOSIS_TUMOR_BLOOD_BENIGN',
  HEALTH_DIAGNOSIS_TUMOR_BLOOD_CLOTTING: 'HEALTH_DIAGNOSIS_TUMOR_BLOOD_CLOTTING',
  HEALTH_DIAGNOSIS_TUMOR_BLOOD_OTHER: 'HEALTH_DIAGNOSIS_TUMOR_BLOOD_OTHER',
  HEALTH_DIAGNOSIS_TUMOR_BLOOD_SICKLECELL: 'HEALTH_DIAGNOSIS_TUMOR_BLOOD_SICKLECELL',
  HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_HPV: 'HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_HPV',
  HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_INSUFFICIENCY: 'HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_INSUFFICIENCY',
  HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_KIDNEYFAIL: 'HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_KIDNEYFAIL',
  HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_NEPHROPATHY: 'HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_NEPHROPATHY',
  HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_OTHER: 'HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_OTHER',
  HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_PROTEIN: 'HEALTH_DIAGNOSIS_URINARY_REPRODUCTIVE_PROTEIN',
  BENEFICIARY: 'BENEFICIARY',
  ATTESTATION: 'ATTESTATION',
  QUOTE: 'QUOTE',
  IDENTITY_CONFIRMATION: 'IDENTITY_CONFIRMATION',
  PAYMENT: 'PAYMENT',
  ERROR: 'ERROR',
  NEEDS_REVIEW: 'NEEDS_REVIEW',
  CHAT_REQUIRED: 'CHAT_REQUIRED',
  INELIGIBLE: 'INELIGIBLE',
  SIGNATURE: 'SIGNATURE',
  SUBMIT: 'SUBMIT',
  COMPLETE: 'COMPLETE'
}
export const BindAuthReasons = {
  SIGN_IN: 'SIGN_IN',
  SAVE_PROGRESS: 'SAVE_PROGRESS',
  CHECKOUT: 'CHECKOUT',
  MANUAL_UNDERWRITING: 'MANUAL_UNDERWRITING'
}

export const bindAuthReason = (reason) => {
  if (isValidBindAuthReason(reason)) {
    return reason
  }
  return undefined
}

export const isValidBindAuthReason = (reason) => {
  if (!reason || reason === '') {
    return false
  }
  for (const property in BindAuthReasons) {
    if (BindAuthReasons[property] === reason) {
      return true
    }
  }
  return false
}
