import flagsmith from 'flagsmith'

export class FlagsmithFlagger {
  constructor (options) {
    this.isFlagsmithReady = false
    this.settings = options?.settings
  }
  async init () {
    await flagsmith.init({
      environmentID: this.settings?.key,
      cacheFlags: true,
      enableAnalytics: true
    })
    this.isFlagsmithReady = true
  }

  hasFeature (key) {
    return this.safeFlagsmith((f) => {
      return f.hasFeature(key)
    })
  }

  isReady () {
    return this.isFlagsmithReady
  }

  safeFlagsmith (callback) {
    if (this.isFlagsmithReady) {
      return callback(flagsmith)
    }
    return undefined
  }
}

export class NoOpFlagger {
  async init () {}

  prefetchFlags () {}

  hasFeature () {
    return false
  }

  get isReady () {
    return true
  }
}

export default class FlagsmithPlugin {}

FlagsmithPlugin.install = function (Vue, options) {
  let client = options.client
  let provider = options?.provider ?? 'noop'
  if (!client) {
    client = new NoOpFlagger()
    if (provider === 'flagsmith' && options?.settings) {
      client = new FlagsmithFlagger(options)
    }
  }
  if (!client) {
    throw new Error('Feature flagging was not configured correctly')
  }
  if (!client.isReady) {
    client.init()
  }
  // eslint-disable-next-line no-console
  console.log(`Getting feature flags from ${provider}.`)
  Vue.prototype.$flagsmith = client
}
