import camelCase from 'lodash/camelCase'

/**
 * Standardizes the format of the input IDs so that input ID and <label for="">
 * exactly match and comply to accessibility requirements
 *
 * @param {string} label
 * @returns {string} creates a camelCased string using the label
 */
let i = 0
export function inputId (label) {
  if (!label) label = ++i
  return camelCase(`input-for-${label}`)
}
