<template>
  <div class="flex-spacer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'flex-spacer'
}
</script>

<style lang="scss" scoped>
.flex-spacer {
  flex: 1;
}
</style>
