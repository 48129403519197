export const defaultMeta = {
  title: 'Life Insurance That Guarantees Your Income',
  titleTemplate: '%s | Dayforward',
  meta: [
    {
      vmid: 'description',
      name: 'description',
      content: 'Get life insurance that guarantees your income so your family has financial security after you\'re ' +
        'gone. Buy online and get covered in 10 minutes.'
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: 'life insurance,term life insurance,term insurance,income protection,' +
        'financial security,online application,digital application'
    },
    // twitter
    { vmid: 'twitter:title', name: 'twitter:title', content: 'Life Insurance That Guarantees Your Income | Dayforward' },
    {
      vmid: 'twitter:description',
      name: 'twitter:description',
      content: 'Get life insurance that guarantees your income so your family has financial security after you\'re ' +
        'gone. Buy online and get covered in 10 minutes.'
    },
    { vmid: 'twitter:site', name: 'twitter:site', content: '@dayforward' },
    { vmid: 'twitter:creator', name: 'twitter:creator', content: '@dayforward' },
    { vmid: 'twitter:image', name: 'twitter:image', content: 'https://cdn.dayforward.com/df-og-logo.png' },
    // open graph
    { vmid: 'og:type', property: 'og:type', content: 'website' },
    { vmid: 'og:locale', property: 'og:locale', content: 'en_US' },
    { vmid: 'og:title', property: 'og:title', content: 'Life Insurance That Guarantees Your Income | Dayforward' },
    { vmid: 'og:site_name', property: 'og:site_name', content: 'Dayforward' },
    { vmid: 'og:image', property: 'og:image', content: 'https://cdn.dayforward.com/df-og-logo.png' },
    {
      vmid: 'og:description',
      property: 'og:description',
      content: 'Get life insurance that guarantees your income so your family has financial security after you\'re ' +
        'gone. Buy online and get covered in 10 minutes.'
    },
    { vmid: 'og:url', property: 'og:url', content: 'https://www.dayforward.com' }
  ],
  link: [
    { rel: 'canonical', href: 'https://www.dayforward.com' }
  ]
}
