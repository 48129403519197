import { render, staticRenderFns } from "./InterviewCongrats.vue?vue&type=template&id=5f197ece&scoped=true&"
import script from "./InterviewCongrats.vue?vue&type=script&lang=js&"
export * from "./InterviewCongrats.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f197ece",
  null
  
)

export default component.exports