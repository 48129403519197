<template>
  <div class="main-navigation-header" :style="cssVars">
    <NavigationHeader v-on="listeners" v-bind="attrs">
      <div class="main-navigation__content">
        <a
          class="df-nav-item"
          :href="gotoRedirect(RedirectRouteNames.WHY_DAYFORWARD)"
          >Why Dayforward</a
        >
        <a class="df-nav-item" :href="gotoRedirect(RedirectRouteNames.ABOUT)"
          >About</a
        >
        <a class="df-nav-item" :href="gotoRedirect(RedirectRouteNames.HELP)"
          >Help</a
        >
        <div
          class="
            df-nav-item--big df-nav-item--nudge
            main-navigation-header__signin
          "
          :style="contentStyles"
          v-if="!hideSignIn && !isAuthenticated"
        >
          <router-link to="/signin">Sign in</router-link>
        </div>
        <div
          class="
            df-nav-item--big df-nav-item--nudge
            main-navigation-header__signin
          "
          :style="contentStyles"
          v-else-if="isAuthenticated"
        >
          <router-link to="/signout">Sign out</router-link>
        </div>
      </div>
      <NavigationButtonItem @click="showMenu" v-if="$ltMd">
        <div class="menu-item__mobile">
          <img
            v-if="color === Colors.eggshell"
            src="~@/assets/images/df-hamburger-eggshell.svg"
            aria-label="menu"
            alt="menu"
          />
          <img
            v-else
            src="~@/assets/images/df-hamburger.svg"
            aria-label="menu"
            alt="menu"
          />
        </div>
      </NavigationButtonItem>
    </NavigationHeader>
    <transition name="menu-reveal">
      <MainMenu v-show="isMenuShowing" v-model="isMenuShowing" />
    </transition>
  </div>
</template>

<script>
import Colors from "@/assets/styles/_colors.scss";
import NavigationHeader from "@/components/navigation/NavigationHeader";
import MainMenu from "./MainMenu";
import { mapGetters } from "vuex";
import NavigationButtonItem from "@/components/navigation/items/NavigationButtonItem.vue";
import { gotoRedirect } from "@/utils/router";
import { RedirectRouteNames } from "@/router/routes/constants";

export default {
  name: "main-navigation-header",
  components: { NavigationButtonItem, MainMenu, NavigationHeader },
  props: {
    color: {
      type: String,
      default: Colors.red,
    },
    backgroundColor: {
      type: String,
      default: undefined,
    },
    componentStyles: {
      type: Array,
      default: undefined,
    },
    hidePhoneNumber: {
      type: Boolean,
      default: false,
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    hideSignIn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: null,
      isMenuShowing: false,
      Colors: Colors,
      RedirectRouteNames,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    cssVars() {
      return {
        "--df-main-nav-color": this.color,
      };
    },
    contentStyles() {
      const styles = {};
      if (this.color) {
        styles["color"] = `${this.color} !important`;
      }
      return styles;
    },
    listeners() {
      return this.$listeners;
    },
    attrs() {
      return [this.$attrs, this.$props];
    },
  },
  methods: {
    gotoRedirect,
    async showDashboard() {
      await this.$router.push({ name: "dashboard" });
    },
    async startJoin() {
      await this.$router.push({ name: "interview-initial" });
    },
    showMenu() {
      this.isMenuShowing = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/media-queries";
.main-navigation__content {
  display: none;
  @include md {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    align-items: center;
    -moz-column-gap: 25px;
    column-gap: 25px;
  }
  a {
    color: var(--df-main-nav-color) !important;
    text-decoration: none;
    letter-spacing: -0.6336px;
    font-weight: 600;
    &:hover {
      border-bottom: 1px solid rgba(#e33031, 0.4);
    }
  }
}
.main-navigation-header {
  height: 64px;
}
.menu-item {
  overflow: auto;
  font-size: 0.88em;
  @include md {
    margin-right: 0;
  }

  &__mobile {
    margin-top: 8px;
    margin-right: 12px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.main-navigation-header__phone {
  font-weight: 400;
  font-size: 1.1rem;
  @include md {
    font-size: 1.59rem;
    margin-top: 0;
  }

  a {
    color: var(--df-main-nav-color) !important;
    text-decoration: none;
  }
}

.main-navigation-header__signin {
  font-size: 16px;
  font-weight: 600;
  top: -3px;
  position: relative;
  @include md {
    font-size: 16px;
    margin-top: 0;
    top: 0;
  }
}

.menu-reveal-enter-active {
  transition: left 0.25s cubic-bezier(0.09, 0.11, 0.24, 0.91);
}
.menu-reveal-leave-active {
  transition: left 0.2s ease;
}

.menu-reveal-enter,
.menu-reveal-leave-to {
  left: 100%;
}
</style>
