<template>
  <div class="contentful-inline-video" :class="classes">
    <VideoDisplayWrapper
      :videoId="videoId"
      :playInline="true"
    />
  </div>

</template>

<script>
import VideoDisplayWrapper from '@/components/content/VideoDisplayWrapper.vue'
export default {
  name: 'contentful-inline-video',
  components: { VideoDisplayWrapper },
  props: {
    videoId: {
      type: String,
      required: true
    },
    classes: undefined,
    styles: undefined
  }
}
</script>

<style scoped>

</style>
