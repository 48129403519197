<template>
  <div>
    <FlowWrapper
      :questionKeys="questionKeys"
      :on-back="goBack"
      :on-next="goNext"
      :can-move-to-next="hasValidFormData"
      :question-keys="activeKeys"
      :answer-values="values"
    >
      <template v-slot:content-header>
        <h2 class="df-content-header-text">{{ title }}</h2>
      </template>
      <template v-slot:content>
        <v-form v-if="!isLoading && questions !== {}">
          <div class="mq-grid">
            <div class="cols-10">
              <Question
                v-model="values[keyForConcern(FC.DIAGNOSIS, [FC.OTHER])]"
                :question="questions[keyForConcern(FC.DIAGNOSIS, [FC.OTHER])]"
                :error="errors[keyForConcern(FC.DIAGNOSIS, [FC.OTHER])]"
                hide-details
              />
            </div>
            <template v-if="diagnosisOther">
              <div class="cols-10">
                <Question
                  v-model="values[keyForConcern(FC.OTHER)]"
                  :question="questions[keyForConcern(FC.OTHER)]"
                  :error="errors[keyForConcern(FC.OTHER)]"
                  hide-details
                />
              </div>
            </template>
          </div>
        </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper'
import Question from '@/components/interview/Question'
import { QuestionCategory as QC, FollowupConcerns as FC, Conditions } from '@/api/Interview/constants'
import { RouteName } from '@/router/routes/constants.js'

export default {
  name: 'InterviewHealth',
  components: { FlowWrapper, Question },

  data() {
    return {
      STEP_NAME: 'health',
      questionKeys: [],
      isLoading: true,
      error: null,
      parent: null,
      questions: {},
      values: {},
      errors: {},
      diagnosisConcern: null,
      FC: FC,
      followupIndex: undefined,
      followupValue: undefined
    }
  },

  computed: {
    ...mapGetters('interview', [
      'interviewID',
      'interview',
      'mapToAnswers',
      'answers',
      'allHealthFollowups',
      'validateKeys',
      'hasHealthConditions',
      'hasIdentityFollowup'
    ]),
    title() {
      if (this.isLoading) return ''
      return this.parent ? `Your ${Conditions[this.parent.key]}` : 'Tell us about your condition.'
    },

    hasValidFormData() {
      return this.validateKeys(this.activeKeys, this.values)
    },

    diagnosisOther() {
      const diagnosis = this.values[this.keyForConcern(FC.DIAGNOSIS, [FC.OTHER])]
      if (diagnosis) {
        return this.values[this.keyForConcern(FC.DIAGNOSIS, [FC.OTHER])].indexOf('OTHER') > -1
      }
      return false
    },

    activeKeys() {
      let activeKeys = [this.keyForConcern(FC.DIAGNOSIS, [FC.OTHER])]
      if (this.diagnosisOther) {
        activeKeys = activeKeys.concat(this.keyForConcern(FC.OTHER))
      }
      return activeKeys
    }
  },

  methods: {
    ...mapActions('interview', [
      'interviewReflexives',
      'updateInterviewAnswers',
      'setHealthDetails',
      'interviewQuestions',
      'interviewQuestionAnswerMaps',
      'findDiagnosesWithCodes'
    ]),

    keyForConcern(concern, exclude = []) {
      // exclude is a hack unless/until we refine the concerns more on the back end
      let key
      Object.entries(this.questions).forEach(([questionKey, question]) => {
        const intersection = exclude.filter((item) => question.concerns.includes(item))
        if (!intersection.length && question.concerns.includes(concern)) {
          key = questionKey
        }
      })
      return key
    },

    async handleHealthDetails() {
      // maps diagnosis value to a more user-friendly diagnosis name:
      const diagnosisMap = {}
      const values = this.values[this.keyForConcern(FC.DIAGNOSIS, [FC.OTHER])]
      let diagnosisOther
      if (values.indexOf('OTHER') !== -1) {
        const code = this.values[this.keyForConcern(FC.OTHER)]
        const { text } = await this.findDiagnosesWithCodes(code)
        diagnosisOther = text
      }
      values.forEach((diag) => {
        if (diag === 'OTHER' && diagnosisOther !== undefined) {
          diagnosisMap[diag] = diagnosisOther
        } else {
          const question = this.questions[this.keyForConcern(FC.DIAGNOSIS, [FC.OTHER])]
          const option = question.options.find((opt) => opt.value === diag)
          diagnosisMap[diag] = option.text
        }
      })
      const healthDetails = {
        followupValue: this.followupValue,
        sourceKey: this.diagnosisConcern,
        answerValues: this.values[this.diagnosisConcern],
        diagnosisMap
      }
      await this.setHealthDetails({
        index: this.followupIndex,
        healthDetails
      })
    },

    async save() {
      const result = await this.updateInterviewAnswers({
        id: this.interviewID,
        answers: this.mapToAnswers(this.values, this.activeKeys)
      })
      if (result.errors) {
        this.errors = result.errors
        return false
      }
      await this.handleHealthDetails()
      return result
    },

    async goBack() {
      // don't await save when navigating back
      this.save()

      if (this.hasHealthConditions) {
        if (this.followupIndex === 0) {
          await this.$router.push({
            name: RouteName.INTERVIEW_HEALTH_CONDITIONS
          })
        } else {
          const previousFollowup = this.allHealthFollowups[this.followupIndex - 1]
          const previousFollowupAnswerValues = previousFollowup.answerDetails.answerValues
          await this.$router.push({
            name: RouteName.FINALIZE_HEALTH_DIAGNOSIS,
            params: {
              followupIndex: this.followupIndex - 1,
              detailIndex: previousFollowupAnswerValues.length - 1
            }
          })
        }
      } else {
        await this.$router.push({
          name: RouteName.INTERVIEW_HEALTH_CONDITIONS
        })
      }
      return { navigate: false }
    },

    async goNext() {
      const success = await this.save()
      if (!success) {
        return { navigate: false }
      }
      if (!this.allHealthFollowups.length || !this.allHealthFollowups[0].hasOwnProperty('value')) {
        // push to final quote and let it get caught by the route guard
        await this.$router.push({ name: RouteName.INTERVIEW_FINAL_QUOTE })
      } else {
        // do navigation to next step
        await this.$router.push({
          name: RouteName.FINALIZE_HEALTH_DIAGNOSIS,
          params: {
            followupIndex: this.followupIndex,
            detailIndex: 0
          }
        })
      }
      return { navigate: false }
    },

    targetOfReflexives(key, val) {
      return {
        sourceKey: key,
        condition: { operator: 'EQUAL', value: val },
        category: QC.HEALTH_FOLLOWUP
      }
    },

    async fetchExistingInterviewAnswers() {
      this.isLoading = true
      this.followupValue = this.allHealthFollowups[this.followupIndex].value
      const { questions, answers } = await this.interviewQuestionAnswerMaps({
        id: this.interviewID,
        concerns: [this.followupValue],
        targetOfReflexives: {
          category: QC.HEALTH_FOLLOWUP
        }
      })

      this.questions = questions
      this.values = answers
      this.questionKeys = Object.keys(this.questions)
      this.diagnosisConcern = this.questionKeys[0]
      this.parent = this.questions[this.diagnosisConcern]

      this.isLoading = false
    }
  },
  async mounted() {
    if (this.interview) {
      this.followupIndex = parseInt(this.$route.params.index)
      await this.fetchExistingInterviewAnswers()
    }
  },

  watch: {
    '$route.path': function (val, oldVal) {
      this.followupIndex = this.$route.params.index
      this.fetchExistingInterviewAnswers()
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/media-queries';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/transitions';

.fields {
  row-gap: 15px;
}
</style>
