import BackendAnalyticsAPI from '@/api/BackendAnalytics'
import { v4 as uuidv4 } from 'uuid'

const analyticsModule = {
  namespaced: true,
  name: 'AnalyticsModule',
  state: {
    userID: undefined
  },
  getters: {
    userID: (state) => state.userID
  },
  mutations: {
    SET_USER_ID (state, userID) {
      state.userID = userID
      // put in a cookie so we can access it across domains and to make more accessible to segment/impact
      let domain = 'dayforward.com'
      let extraFlags = '; secure'
      if (process.env.VUE_APP_RELEASE_STAGE === 'local') {
        domain = 'localhost'
        extraFlags = ''
      }
      document.cookie = `analytics:userid=${userID}; expires=Fri, 31 Dec 9999 23:59:59 GMT; domain=${domain}; samesite=strict${extraFlags}`
    }
  },
  actions: {
    async track ({ rootState, dispatch }, payload) {
      const userId = await dispatch('getOrCreateUserId')
      const trackingEvent = {
        userID: userId,
        event: payload.event,
        source: 'website',
        url: `${window.location.href}`,
        properties: { ...payload.properties }
      }

      await rootState.apolloClient.mutate({
        ...BackendAnalyticsAPI.track(trackingEvent)
      })
    },

    async page ({ rootState, dispatch }, payload) {
      const userId = await dispatch('getOrCreateUserId')
      const pageView = {
        userID: userId,
        source: 'website',
        url: `${window.location.href}`,
        properties: { ...payload.properties }
      }

      await rootState.apolloClient.mutate({
        ...BackendAnalyticsAPI.page(pageView)
      })
    },

    async identify ({ rootState, dispatch }, payload) {
      const userId = await dispatch('getOrCreateUserId')
      const { traits } = payload
      const identity = {
        userID: userId,
        traits: { ...traits }
      }
      await rootState.apolloClient.mutate({
        ...BackendAnalyticsAPI.identify(identity)
      })
    },

    getOrCreateUserId ({ getters, commit, rootGetters }) {
      let userID
      // check to see if there is an interview and whether the interview has an
      // associated user id
      const interview = rootGetters['interview/interview']
      const cioMetadata = interview?.metadata?.find(m => m.key === 'CustomerIOID')
      const cioIdFromMetadata = cioMetadata?.value
      if (cioIdFromMetadata) {
        userID = cioIdFromMetadata
      } else if (getters.userID !== null && getters.userID !== undefined) {
        userID = getters.userID
      } else {
        const cookie = document.cookie
        if (cookie) {
          const cookieSplit = cookie.split(`;`)
          const found = cookieSplit.filter((u) => u.trim().startsWith('analytics:userid'))
          if (found.length > 0) {
            const foundSplit = found[0]?.trim().split('=')
            if (foundSplit && foundSplit.length === 2) {
              userID = foundSplit[1]
            }
          }
        }
      }
      if (!userID) {
        userID = uuidv4()
      }
      // always commit the user id so that there isn't any mismatch across domains
      commit('SET_USER_ID', userID)
      return userID
    }
  }
}

export default analyticsModule
