<template>
  <div class="pricing-calculator">
    <div class="pricing-calculator__explainers">
      <div class="pricing-calculator__explainer">
        <div class="pricing-calculator__explainer-title">Your Health</div>
        <div class="pricing-calculator__explainer-content">
          <img src="@/assets/images/df-icon-updown.svg"/>
          <div class="pricing-calculator__explainer-description">
            <div>Average</div>
            <div>Good</div>
            <div>Excellent</div>
          </div>
        </div>
      </div>
      <div class="pricing-calculator__explainer-separator">
        <div class="pricing-calculator__explainer-separator-line"></div>
      </div>
      <div class="pricing-calculator__explainer">
        <div class="pricing-calculator__explainer-title">Youngest child's age</div>

        <div class="pricing-calculator__explainer-content">
          <img src="@/assets/images/df-icon-updown.svg"/>
          <div class="pricing-calculator__explainer-description">
            <div>Younger</div>
            <div>1 year old</div>
            <div>Older</div>
          </div>
        </div>
      </div>
      <div class="pricing-calculator__explainer-separator">
        <div class="pricing-calculator__explainer-separator-line"></div>
      </div>
      <div class="pricing-calculator__explainer">
        <div class="pricing-calculator__explainer-title">Length of policy</div>
        <div class="pricing-calculator__explainer-content">
          <img src="@/assets/images/df-icon-updown.svg"/>
          <div class="pricing-calculator__explainer-description">
            <div>Longer</div>
            <div>20 years</div>
            <div>Shorter</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pricing-calculator__legend">
      <div class="pricing-calculator__legend-item">
        <img src="@/assets/images/df-icon-cost.svg"/>
        <div>Price may vary based on factors specific to you</div>
      </div>
      <div class="pricing-calculator__legend-item">
        <img src="@/assets/images/df-icon-plus.svg"/>
        <div>Price goes up</div>
      </div>
      <div class="pricing-calculator__legend-item">
        <img src="@/assets/images/df-icon-minus.svg"/>
        <div>Price goes down</div>
      </div>
    </div>
    <div class="pricing-calculator__table">
      <table>
        <thead>
          <tr>
            <th class="pricing-calculator__table-fit-header">AGE</th>
            <th class="pricing-calculator__table-fit-header">GENDER</th>
            <th class="pricing-calculator__table-center-header" colspan="7">ANNUAL INCOME</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            <th>50K</th>
            <th>75K</th>
            <th>100K</th>
            <th>125K</th>
            <th>150K</th>
            <th>175K</th>
            <th>200K</th>
        </tr>
        </thead>
        <tbody>
        <tr class="pricing-calculator__table-highlight">
          <td>25</td>
          <td>F</td>
          <td>$21</td>
          <td>$29</td>
          <td>$38</td>
          <td>$46</td>
          <td>$54</td>
          <td>$62</td>
          <td>$70</td>
        </tr>
        <tr class="pricing-calculator__table-highlight">
          <td></td>
          <td>M</td>
          <td>$26</td>
          <td>$36</td>
          <td>$47</td>
          <td>$57</td>
          <td>$68</td>
          <td>$78</td>
          <td>$88</td>
        </tr>
        <tr>
          <td>30</td>
          <td>F</td>
          <td>$21</td>
          <td>$29</td>
          <td>$38</td>
          <td>$46</td>
          <td>$54</td>
          <td>$62</td>
          <td>$70</td>
        </tr>
        <tr>
          <td></td>
          <td>M</td>
          <td>$26</td>
          <td>$36</td>
          <td>$47</td>
          <td>$57</td>
          <td>$68</td>
          <td>$78</td>
          <td>$88</td>
        </tr>
        <tr class="pricing-calculator__table-highlight">
          <td>35</td>
          <td>F</td>
          <td>$24</td>
          <td>$34</td>
          <td>$44</td>
          <td>$53</td>
          <td>$63</td>
          <td>$73</td>
          <td>$82</td>
        </tr>
        <tr class="pricing-calculator__table-highlight">
          <td></td>
          <td>M</td>
          <td>$29</td>
          <td>$41</td>
          <td>$53</td>
          <td>$64</td>
          <td>$76</td>
          <td>$88</td>
          <td>$100</td>
        </tr>
        <tr>
          <td>40</td>
          <td>F</td>
          <td>$34</td>
          <td>$48</td>
          <td>$63</td>
          <td>$77</td>
          <td>$92</td>
          <td>$106</td>
          <td>$121</td>
        </tr>
        <tr>
          <td></td>
          <td>M</td>
          <td>$42</td>
          <td>$61</td>
          <td>$80</td>
          <td>$98</td>
          <td>$117</td>
          <td>$136</td>
          <td>$154</td>
        </tr>
        <tr class="pricing-calculator__table-highlight">
          <td>45</td>
          <td>F</td>
          <td>$51</td>
          <td>$74</td>
          <td>$97</td>
          <td>$120</td>
          <td>$143</td>
          <td>$166</td>
          <td>$189</td>
        </tr>
        <tr class="pricing-calculator__table-highlight">
          <td></td>
          <td>M</td>
          <td>$64</td>
          <td>$93</td>
          <td>$123</td>
          <td>$152</td>
          <td>$182</td>
          <td>$211</td>
          <td>$241</td>
        </tr>
        </tbody>
      </table>
      <div class="mt-5">
        All prices are per month and based on coverage until a child turns 21 years old.
      </div>
      <div class="mt-1">
        Dayforward can also protect people younger than 25 and older than 45, and income below $50k and above $200k.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contentful-pricing-calculator'
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/media-queries";
@import "src/assets/styles/colors";

.pricing-calculator__explainers {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-content: space-evenly;

  max-width: 750px;
  margin-bottom: 1rem;
  margin-left: -15px;
  margin-right: -15px;

  @include md {
    margin-left: 0;
    margin-right: 0;
  }

  .pricing-calculator__explainer-separator {
    flex: 1;
    display: none;

    &-line {
      width: 1px;
      border-left: 1px solid $c-red;
      content: "&nbsp;";
    }

    @include md {
      display: flex;
      justify-content: center;
    }
  }

  .pricing-calculator__explainer {
    font-size: 0.75rem;
    margin-left: 1rem;

    &:nth-child(1) {
      margin-left: 0;
    }

    @include md {
      margin-left: 0;
      margin-bottom: 0;
    }

    &-title {
      font-size: 1rem;
      font-weight: bold;
    }

    &-content {
      display: flex;
      align-items: center;

      img {
        width: 30px;
        margin-right: 0.5rem;
      }
    }

    .pricing-calculator__explainer-description div:nth-child(2) {
      color: $c-red;
    }
  }
}

.pricing-calculator__legend {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 750px;
  font-size: 0.75rem;

  @include md {
    font-size: 1rem;
  }

  @include md {
    flex-direction: row;
  }

  margin-bottom: 2rem;

  &-item {
    display: flex;
    align-items: flex-start;
    margin-right: 1rem;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-right: 0
    }

    img {
      width: 20px;
      margin-right: 0.5rem;
    }

    div {
      min-width: 8rem;
    }
  }
}

.pricing-calculator__table {
  margin-left: -25px;
  margin-right: -25px;
  font-size: 0.65rem;

  @include md {
    margin: 0;
    font-size: 1rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    text-align: left;
    padding: 0.25rem;
  }

  td {
    border: none;
    padding: 0.25rem;
  }

  &-highlight {
    background-color: #F7F4EC;
  }

  &-fit-header {
    width: 1px;
    white-space: nowrap;
  }
  &-center-header {
    text-align: center !important;
  }
}
</style>
