import FinalizePriceHealthFollowup from '@/views/finalize/FinalizePriceHealthFollowup'
import FinalizePriceHealthDiagnosis from '@/views/finalize/FinalizePriceHealthDiagnosis'
import { RouteCategory, RouteName } from '@/router/routes/constants.js'
import { noNativeRouting, withDefaultMeta } from '@/router/routes/meta'
import Interview from '@/views/Interview'

export default [
  {
    path: '/finalize',
    component: Interview,
    children: withDefaultMeta(noNativeRouting, [
      {
        path: '/finalize/health/:index',
        name: RouteName.FINALIZE_HEALTH_FOLLOWUP,
        component: FinalizePriceHealthFollowup,
        meta: {
          category: RouteCategory.FINALIZE,
          header: {},
          requiresAuth: false
        }
      },
      {
        path: '/finalize/diagnosis/:followupIndex/:detailIndex',
        name: RouteName.FINALIZE_HEALTH_DIAGNOSIS,
        component: FinalizePriceHealthDiagnosis,
        meta: {
          category: RouteCategory.FINALIZE,
          header: {},
          requiresAuth: false
        }
      }
    ])
  }
]
