<template>
  <div>
    <div class="not-found mq-container">
      <header class="df-header df-header__p-top">
        <MainNavigationHeader/>
        <div class="df-header-text">
          Not Found
        </div>
      </header>
      <div class="df-content-header-text">
        Let's start at the beginning.
      </div>
      <div class="not-found__content">
        It seems like the page you are looking for does not exist.
      </div>
      <DFButton class="not-found__button" @click.stop="goHome">Go Home</DFButton>
    </div>
    <MainFooter/>
  </div>
</template>

<script>
import DFButton from '@/components/DFButton.vue'
import MainFooter from '@/components/navigation/MainFooter'
import MainNavigationHeader from '@/components/navigation/MainNavigationHeader'
import { gotoRedirect } from '@/utils/router'
export default {
  name: 'not-found',
  components: { DFButton, MainFooter, MainNavigationHeader },
  methods: {
    gotoRedirect,
    async goHome () {
      location.href = gotoRedirect('')
    }
  }
}
</script>

<style scoped lang="scss">
.not-found {
  min-height: 100vh;
}

.df-header-text {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.not-found__button {
  margin-top: 3rem;
}

::v-deep footer {
  margin-top: 0;
}

</style>
