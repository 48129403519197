<template>
  <InterviewPayment
    :headerText="headerText"
    title="We could not process the credit card you submitted."
    subtitle="Please enter a new credit card."
    :forceCapture="true"
    :onNext="doNext"
    :showInterviewFlowElements="!policy">

    <template v-slot:footer>
      <div class="cols-10">Still having trouble? <a href="#">Contact Us</a></div>
    </template>

  </InterviewPayment>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InterviewPayment from '@/views/interview/InterviewPayment'
import PolicyAPI from '@/api/Policy'
import { apolloClient } from '@/config/apollo.config'

export default {
  name: 'InterviewPaymentUpdate',
  components: { InterviewPayment },

  data () {
    return {
      invoiceID: null,
      error: null
    }
  },

  props: {
    interview: {
      type: Object
    },
    policy: {
      type: Object
    },
    headerText: {
      type: String
    }
  },

  computed: {
    ...mapGetters('interview', ['interviewID'])
  },

  methods: {
    ...mapActions('policy', ['getPolicyByInterviewID']),

    async doNext () {
      const res = await apolloClient.mutate({
        ...PolicyAPI.payInvoice(this.invoiceID)
      })
      if (res && res.data) {
        const invoice = res.data.payInvoice
        if (invoice.paid) {
          this.$router.replace({ name: 'interview-congrats' })
        }
      }
      return false
    }
  },

  async created () {
    const policy = this.policy || await this.getPolicyByInterviewID(this.interviewID)
    if (policy) {
      const unpaidInvoices = policy.invoices.filter(i => !i.paid)
      if (unpaidInvoices.length === 1) {
        this.invoiceID = unpaidInvoices[0].id
      } else {
        this.error = 'Policy state invalid, please contact customer support'
      }
    } else {
      this.error = 'Unknown policy error, please contact customer support'
    }
  }
}
</script>

<style scoped lang="scss"></style>
