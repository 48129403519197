<template>
  <div class="contentful__legal">
    <ContentfulContentRenderer :content="processedContent"/>
  </div>
</template>

<script>
import { processRichText } from '@/api/Content'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'contentful-legal',
  components: { ContentfulContentRenderer },
  props: {
    content: undefined
  },

  computed: {
    processedContent () {
      return processRichText(this, this.content)
    }
  }
}
</script>

<style scoped lang="scss">
.contentful__legal {
  margin-top: 2rem
}

::v-deep {
  p {
    font-size: smaller;
    margin: 0 !important;
  }
}
</style>
