import NotFound from '@/views/NotFound.vue'
import OffRampIneligible from '@/views/offramp/OffRampIneligible'
import OffRampWaitlistThanks from '@/views/offramp/OffRampWaitlistThanks'
import OffRampReplacementPolicy from '@/views/offramp/OffRampReplacementPolicy'
import Dashboard from '@/views/user/Dashboard'
import Profile from '@/views/user/Profile'
import Docusign from '@/views/webhook/Docusign'
import PrivacyPolicy from '@/views/legal/PrivacyPolicy'
import TermsAndConditions from '@/views/legal/TermsAndConditions'
import IncomingFeed from '@/views/content/incoming/IncomingFeed'
import IncomingArticle from '@/views/content/incoming/IncomingArticle'
import PreLaunch from '@/views/kill/PreLaunch'
import ErrorPage from '@/views/ErrorPage'
import { RouteName } from '@/router/routes/constants'
import Redirect from '@/components/navigation/Redirect'

export default [
  {
    path: '*',
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: RouteName.HOME,
    component: Redirect,
    meta: {
      requiresAuth: false,
      doNotTrack: true
    }
  },
  {
    path: '/or/sorry',
    name: 'offramp-ineligible',
    component: OffRampIneligible,
    meta: {
      header: {
        title: 'Checkout'
      },
      requiresAuth: false
    }
  },
  {
    path: '/or/waitlist',
    name: 'offramp-waitlist',
    component: OffRampWaitlistThanks,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/or/replacement-policy',
    name: 'offramp-replacement',
    component: OffRampReplacementPolicy,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/me',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/continue',
    name: 'dashboard',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below
      window.location.replace("https://www.dayforward.com/signin");
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/webhook/docusign',
    name: 'docusignWebhook',
    component: Docusign,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/contact',
    name: 'error',
    component: ErrorPage,
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/privacy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    beforeEnter (to, from, next) {
      location.href = 'https://dayforward.com/privacy'
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsAndConditions,
    beforeEnter (to, from, next) {
      location.href = 'https://dayforward.com/terms'
    },
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/incoming',
    name: 'incoming',
    component: IncomingFeed,
    meta: {
      requiresAuth: false
    },
    beforeEnter (to, from, next) {
      location.href = 'https://blog.dayforward.com'
    },
  },
  {
    path: '/incoming/c/:category',
    name: 'incoming-category',
    component: IncomingFeed,
    meta: {
      requiresAuth: false
    },
    beforeEnter (to, from, next) {
      location.href = 'https://blog.dayforward.com'
    },
  },
  {
    path: '/a/:slug',
    name: 'article',
    component: IncomingArticle,
    meta: {
      requiresAuth: false
    },
    beforeEnter (to, from, next) {
      location.href = 'https://blog.dayforward.com'
    },
  },
  {
    path: '/incoming/a/:slug',
    name: 'incoming-article',
    component: IncomingArticle,
    meta: {
      requiresAuth: false
    },
    beforeEnter (to, from, next) {
      location.href = 'https://blog.dayforward.com'
    },
  },
  {
    path: '/launch',
    name: 'launch',
    component: PreLaunch,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/quick-price',
    name: 'quick-quote',
    component: Redirect,
    meta: {
      requiresAuth: false,
      doNotTrack: true
    }
  }
]
