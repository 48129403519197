<template>
  <IncomingContainer :category="category">
    <template v-slot:content>
      <template v-if="articles">
        <router-link :to="routerLink(article)"
                     v-for="article in articles"
                     :key="article.key"
                     class="incoming__feed-item">
          <div class="incoming__feed-image">
            <img :src="article.data.feedImage.fields.file.url"/>
          </div>
          <IncomingCategories :categories="article.data.categories" class="incoming__categories--small"/>
          <div class="incoming__feed-item-content">
            <div class="incoming__feed-title">{{ article.data.title }}</div>
            <div class="incoming__feed-byline">{{ article.data.feedByline }}</div>
          </div>
        </router-link>
        <div v-if="hasMoreArticles" class="incoming__feed-pagination">
          <DFButton class="incoming__feed-pagination-next" :disabled="isLoading" @click="loadPage(page + 1)">Load More
            Articles
          </DFButton>
        </div>
      </template>
      <v-progress-circular
        v-else
        height="100"
        :color="progressColor"
        indeterminate/>
    </template>
  </IncomingContainer>
</template>

<script>
import { mapActions } from 'vuex'
import Colors from '@/assets/styles/_colors.scss'
import { processContentfulObject } from '@/api/Content'
import IncomingContainer from '@/views/content/incoming/IncomingContainer'
import IncomingCategories from '@/views/content/incoming/IncomingCategories'
import DFButton from '@/components/DFButton'

export default {
  name: 'incoming-feed',
  data () {
    return {
      page: 0,
      limit: 10,
      total: 0,
      category: null,
      articles: null,
      isLoading: false,
      eggshell: Colors.eggshell,
      progressColor: Colors.red
    }
  },

  watch: {
    async $route (to) {
      this.category = to.params.category
      this.page = 0
      this.articles = null
      await this.getArticles()
    }
  },

  components: {
    IncomingContainer,
    IncomingCategories,
    DFButton
  },

  computed: {
    hasMoreArticles () {
      return !(this.total - this.skip <= this.limit)
    },

    skip () {
      return this.page * this.limit
    }
  },

  methods: {
    ...mapActions('content', ['fetchContentfulEntries']),

    async loadPage (page) {
      this.page = page
      await this.getArticles()
    },

    routerLink: function (article) {
      const route = { name: 'incoming-article', params: { slug: article.data.slug } }
      if (window.location.search.includes('draft')) {
        return {
          ...route,
          query: { draft: true }
        }
      } else {
        return route
      }
    },

    getArticles: async function () {
      this.isLoading = true
      const pageQuery = {
        order: '-fields.date',
        skip: this.skip,
        limit: this.limit
      }
      const categoryQuery = this.category ? { 'fields.categories': this.category } : {}
      const response = (await this.fetchContentfulEntries({
        type: 'article',
        query: {
          'fields.template[ne]': 'taboola',
          ...categoryQuery,
          ...pageQuery
        }
      }))

      this.articles = (this.articles || []).concat(response.items.map(processContentfulObject))
      this.total = response.total
      this.isLoading = false
    }
  },

  metaInfo: {
    title: 'Incoming Magazine',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'We created Incoming magazine to help families navigate the complicated aspects of financial fitness.'
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Incoming Magazine | Dayforward'
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content: 'We created Incoming magazine to help families navigate the complicated aspects of financial fitness.'
      },
      { vmid: 'og:url', property: 'og:url', content: 'https://www.dayforward.com/incoming' },
      {
        vmid: 'twitter:title',
        name: 'twitter:title',
        content: 'Incoming Magazine | Dayforward'
      },
      {
        vmid: 'twitter:description',
        name: 'twitter:description',
        content: 'We created Incoming magazine to help families navigate the complicated aspects of financial fitness.'
      }
    ],
    link: [
      { rel: 'canonical', href: 'https://www.dayforward.com/incoming' }
    ]
  },

  async created () {
    this.category = this.$route.params.category
    await this.getArticles()
  }
}
</script>

<style scoped lang="scss">

@import 'src/assets/styles/colors';
@import 'src/assets/styles/flow';
@import 'src/assets/styles/global';
@import 'src/assets/styles/metrics';
@import 'src/assets/styles/media-queries';

.incoming__feed-title {
  font-size: 1.6rem;
  line-height: 1.2em;
  font-weight: bolder;
  margin-bottom: 0.35rem;
  letter-spacing: -0.04em;
  @include md {
    font-size: 2rem;
  }
}

.incoming__feed-byline {
  font-size: 1rem;
}

.incoming__read-more {
  color: $c-red;
}

.incoming__feed-item {
  margin-bottom: 2rem;
  display: block;
  height: auto;
  color: $c-primary !important;
  text-decoration: none;

  &:hover {
    .incoming__feed-title {
      text-decoration: underline;
    }
  }

  > .incoming__categories {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  .incoming__feed-image {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;

    > img {
      width: 100%;
    }

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }

  .incoming__feed-item-content {
    cursor: pointer;
    padding: 0.5rem;
  }
}

.incoming__feed-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
