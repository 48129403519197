<template>
  <div class="title-and-body" v-observe-visibility="onVisible">
    <template v-if="animated">
      <transition name="title-fade">
        <div v-show="isVisible"  class="title-and-body__title">{{title}}</div>
      </transition>
      <transition name="body-fade">
        <div v-show="isVisible" v-if="isBodyHTML" class="title-and-body__body">
          <ContentfulContentRenderer :content="body"/>
        </div>
        <div v-show="isVisible" v-else class="title-and-body__body">{{body}}</div>
      </transition>
      <template v-if="bodyLinkText">
      <transition name="body-fade">
        <div v-show="isVisible" class="title-and-body__body-url">
          <router-link :to="bodyLinkUrl">{{ bodyLinkText }}</router-link>
        </div>
      </transition>
      </template>
    </template>
    <template v-else>
      <div class="title-and-body__title">{{title}}</div>
      <div v-if="isBodyHTML" class="title-and-body__body">
        <ContentfulContentRenderer :content="body"/>
      </div>
      <div v-else class="title-and-body__body">{{body}}</div>
      <div class="title-and-body__body-url" v-if="bodyLinkText">
        <router-link :to="bodyLinkUrl">{{ bodyLinkText }}</router-link>
      </div>
    </template>
  </div>
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility'
import ContentfulContentRenderer from '@/components/contentful/ContentfulContentRenderer.js'

export default {
  name: 'title-and-body',
  components: { ContentfulContentRenderer },
  directives: {
    'observe-visibility': ObserveVisibility
  },
  props: {
    title: undefined,
    body: undefined,
    bodyLinkText: undefined,
    bodyLinkUrl: undefined,
    animated: {
      type: Boolean,
      default: false
    },
    isBodyHTML: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      isVisible: !this.animated
    }
  },
  methods: {
    onVisible (isVisible) {
      if (this.animated && !this.isVisible && isVisible) {
        this.isVisible = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/styles/global";
@import "src/assets/styles/colors";
@import "src/assets/styles/media-queries";
.title-and-body {
  &__title {
    font-size: 1.8em;
    line-height: 1.1em;
    font-weight: 600;
    letter-spacing: -0.03em;
    color: $c-red;
    @include md {
      font-size: 2em;
    }
    @include lg {
      font-size: 2.8rem;
      line-height: 1em;
    }
  }
  &__body {
    color: $c-primary;
    margin-top: 20px;
    font-size: 1.1em;
    line-height: 1.4em;
    @include md {
      margin-top: 30px;
      font-size: 1.2em;
      line-height: 1.5em;
    }
  }
}

.title-fade-enter-active, .title-fade-leave-active {
  transition: all .7s cubic-bezier(0.32, 1, 0.36, 1);
}

.title-fade-enter-active {
  transition-delay: .12s;
}

.title-fade-enter, .title-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.body-fade-enter-active, .body-fade-leave-active {
  transition: all .7s cubic-bezier(0.32, 1, 0.36, 1);
}

.body-fade-enter-active {
  transition-delay: .42s;
}

.body-fade-enter, .body-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

.title-and-body__body-url {
  margin-top: 25px;
  a {
    font-size: 1.25em;
    text-decoration: none;
    &:hover {
      text-decoration: underline !important;
    }
  }
}

</style>
