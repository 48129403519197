<template>
  <div class="profile">
    <header class="profile__header">
      <NavigationHeader />
    </header>

    <div class="profile__content">
      # profile
    </div>
  </div>
</template>

<script>

import NavigationHeader from '@/components/navigation/NavigationHeader'

export default {
  name: 'Profile',
  components: { NavigationHeader }
}
</script>

<style scoped lang="scss">

.profile__content {
  margin: 2rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

</style>
