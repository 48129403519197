const PATTERN_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
const PATTERN_PHONE = /^\d{3}-\d{3}-\d{4}$/
const PATTERN_SSN = /^\d{3}-\d{2}-\d{4}$/

const isRequired = () => (value) => {
  if (Array.isArray(value)) {
    return value.length > 0 || 'Please enter a value'
  }
  return !!value || 'Please enter a value'
}

const isInRange = (min, max) => (value) => {
  if (!min) {
    return value <= max || `Please enter a value <= ${max}`
  } else if (!max) {
    return value >= min || `Please enter a value >= ${min}`
  }
  return (value >= min && value <= max) || `Please enter a value >= ${min} and <= ${max}`
}

const isInList = (list) => (value) => {
  if (!value) {
    return true
  }
  const listValues = list.map((o) => o.value)
  if (Array.isArray(value)) {
    return value.every((v) => listValues.indexOf(v) !== -1)
  } else {
    if (typeof value === 'object' && value.hasOwnProperty('value')) {
      value = value.value
    }
    return listValues.indexOf(value) !== -1
  }
}

const isEmail = () => (value) => {
  return !value || PATTERN_EMAIL.test(value) || 'Please enter a valid email address'
}

const isPhoneNumber = () => (value) => {
  return !value || PATTERN_PHONE.test(value) || 'Please enter a valid phone number'
}

const isSSN = () => (value) => {
  return !value || PATTERN_SSN.test(value) || 'Please enter a valid SSN'
}

const isTrue = () => (value) => {
  if (!value) return false
  if (typeof value === 'string') {
    const re = RegExp(/true/i)
    return re.test(value)
  }
  if (typeof value === 'number') {
    return value !== 0
  }
  if (typeof value === 'boolean') {
    return value
  }
  return false
}

export {
  PATTERN_EMAIL,
  PATTERN_PHONE,
  PATTERN_SSN,
  isRequired,
  isInRange,
  isInList,
  isEmail,
  isPhoneNumber,
  isSSN,
  isTrue
}
