<template>
    <div class="interview-quote__card df-card">
        <p class="interview-quote__card-eyebrow">
         {{ eyebrow }}
        </p>
        <div class="interview-quote__card-body">
            <div class="interview-quote__card-price">
                <p>
                    <span>${{ quoteResult.premium }}</span>/mo
                </p>
            </div>
            <div class="interview-quote__card-info">
                <p class="interview-quote__card-copy">{{ termReasonText }}</p>
                <p class="interview-quote__card-amount">${{ calculatePayout() }}</p>
                <span class="interview-quote__card-finish">Total money to your family if you die</span>
                    <v-select
                        variant="small"
                        class="interview-quote__card-select"
                        :items="dropValues"
                        v-model="selectedYears"
                        @input="onYearItemSelected"
                    >
                        <template v-slot:buttonValue>{{ selectedYears.text }}</template>
                        <template v-slot:item="{ item }">{{ item.text }}</template>
                    </v-select>
                <span class="interview-quote__issued">once policy is issued</span>
            </div>
            <div class="interview-quote__benefits">
                <div class="interview-quote__benefit-item">
                <p>👏</p>
                <p>Benefits mostly tax-free &amp; paid 2x/month</p>
                </div>
                <div class="interview-quote__benefit-item">
                <p>💰</p>
                <p>Includes $10,000 for funeral expenses</p>
                </div>
                <div class="interview-quote__benefit-item">
                <p>👼</p>
                <p>Policy can change as your life changes</p>
                </div>
            </div>
            <p>
                <a class="quote-form__link-bigger df-link--inverted" rel="noopener"
                   href="#" @click.prevent="showModal('family_protection_multipage')">
            View all details
                </a>
            </p>
        </div>
        <div class="interview-quote__card-button-wrap">
            <DFButton class="interview-quote__btn" @click.stop="selectQuote">Select</DFButton>
        </div>
        <template>
        <FamilyProtectionMultipageModal
          :show="isShowingModal('family_protection_multipage')"
          @close="hideModal('family_protection_multipage')"
          :quote="quoteResult"
        />
        </template>
    </div>
</template>

<script>
import DFButton from '@/components/DFButton.vue'
import FamilyProtectionMultipageModal from '@/components/modals/FamilyProtectionMultipageModal.vue'
import { mapActions, mapGetters } from 'vuex'
import { RouteName } from '@/router/routes/constants'

export default {
  name: 'interview-quote-card',
  components: { DFButton, FamilyProtectionMultipageModal },
  props: {
    eyebrow: {
      type: String,
      default: undefined
    },
    quoteResult: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number
    }
  },
  data () {
    return {
      quote: undefined,
      selectedYears: undefined,
      amount: undefined,
      visibleModalKey: undefined
    }
  },
  computed: {
    ...mapGetters('interview', ['interviewID']),
    termReasonText () {
      let termReasonText = ''
      const benefit = this.addCommas(this.quoteResult.annualBenefit)
      const years = Math.round(this.quoteResult.termRecommended / 12)
      if (this.quoteResult.termReason.code === 'CHILD_AGE') {
        termReasonText = `$${benefit} a year until your youngest child turns ${this.quoteResult.termReason.untilChildAge}`
      } else if (this.quoteResult.termReason.code === 'INSURED_AGE') {
        termReasonText = `$${benefit} a year until you plan to retire at ${this.quoteResult.termReason.untilInsuredAge}`
      } else {
        termReasonText = `$${benefit}  a year for the next  ${years} years`
      }
      return termReasonText
    },
    dropValues () {
      const years = this.quoteResult.termRecommended / 12
      return this.yearValues.slice(0, years)
    }
  },
  methods: {
    ...mapActions('interview', ['updateQuote', 'updateInterviewAnswers']),
    calculatePayout () {
      this.calculateCopy()
      let amount
      if (this.cardTerm !== 2) {
        amount =
          (this.quoteResult.termRecommended - this.selectedYears * 12) * 2 * this.quoteResult.benefitAmount
      } else {
        amount = this.quoteResult.annualBenefit * 3
      }
      amount = this.addCommas(amount += 10000)
      return amount
    },
    async selectQuote () {
      this.$analytics.track({
        event: 'app_quote_quote_selected',
        premium: this.quoteResult.premium,
        policyType: this.eyebrow,
        url: this.$route.name
      })
      await this.updateQuote({ id: this.interviewID,
        parameter: {
          premium: this.quoteResult.premium,
          termMonths: this.quoteResult.termRecommended
        }
      })
      await this.$router.push({ name: RouteName.INTERVIEW_CONFIRM_IDENTITY })
    },

    isShowingModal (key) {
      return this.visibleModalKey === key
    },

    showModal (key) {
      this.visibleModalKey = key
    },

    hideModal (key) {
      if (this.visibleModalKey === key) {
        this.visibleModalKey = undefined
      }
    },
    addCommas (num) {
      if (num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return ''
    },
    onYearItemSelected () {
      this.calculatePayout()
      this.$analytics.track({
        event: 'app_quote_result_change_policytiming',
        selectedYears: this.selectedYears.value
      })
    },
    inRange (x, min, max) {
      return (x - min) * (x - max) <= 0
    },
    calculateCopy () {
      const length = this.quoteResult.termRecommended / 12
      const mid = Math.floor(length / 2)
      if (this.inRange(this.selectedYears, 0, mid - 1)) {
        this.cardTerm = 0
      } else if (this.inRange(this.selectedYears, mid, length - 3)) {
        this.cardTerm = 1
      } else {
        this.cardTerm = 2
      }
    },
    setDropValues () {
      let maxYears = 26
      let yearValues = [
        { text: 'right away', value: 0 },
        { text: '1 year', value: 1 }
      ]
      for (let i = 2; i <= maxYears; i++) {
        yearValues.push({ text: `${i} years`, value: i })
      }
      this.yearValues = yearValues
      this.selectedYears = this.yearValues[0].value
    }
  },
  created () {
    this.setDropValues()
  }

}
</script>

<style scoped lang="scss">
@import "src/assets/styles/colors";
@import "src/assets/styles/titles";
@import "src/assets/styles/media-queries";

.df-card {
  border: 1px solid rgba($c-primary, 0.75);
  background: #F0EEE5;
  box-shadow: 0 3px 3px 1px rgba($c-primary, 0.15);
  border-radius: 6px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.interview-quote {
  &__button-next {
    min-width: 100%;
    @include md {
      min-width: 160px;
    }
  }
  &__price {
    h2 {
      margin-bottom: 30px;
    }
  }
  &__card-eyebrow {
    font-size: 12px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 14px;
  }
  &__card-price {
    margin-bottom: 14px;
    span {
      font-weight: bold;
    }
    p {
      font-weight: bold;
      span {
        font-size: 30px;
        line-height: 28px;
        color: rgba($c-red, 1);
      }
    }
  }
  &__card-button-wrap {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    }
    &__card-copy {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 14px;
      font-weight: bold;
      max-width: 80%;
      min-height: 40px;
      @include md {
          max-width: 100%;
      }
      @include lg {
          max-width: 80%;
      }
    }

  &__card-amount {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 9px;
    font-weight: bold;
  }

  &__card-finish {
    display: inline-flex;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 7px;
    margin-right: 8px;
  }

  &__issued {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
  }

  &__card-select {
    max-width: 125px;
    margin-right: 8px;
    display: inline-block;
    font-size: 14px;
    ::v-deep .v-text-field__details {
      position: absolute !important;
    }
  }

  &__benefits {
    margin-top: 1.5rem;
    display: grid;
    grid-row-gap: 0.75rem;
  }

  &__benefit-item {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 0.5rem;
  }
}
a {
  margin-top: 1rem;
}
</style>
