import Vue from 'vue'
import theme from '../../config/theme.config'

const breakpoints = {
  md: {
    min: theme.breakpoints.md
  },
  lg: {
    min: theme.breakpoints.lg
  },
  xl: {
    min: theme.breakpoints.xl
  }
}
const mqMixin = Vue.mixin({
  data () {
    return {
      windowWidth: 0,
      windowHeight: 0,
      isSm: false,
      isMd: false,
      isLg: false,
      isXl: false
    }
  },
  computed: {
    $gtSm () {
      return this.windowWidth >= breakpoints.md.min
    },
    $gtMd () {
      return this.windowWidth >= breakpoints.lg.min
    },
    $ltMd () {
      return this.windowWidth < breakpoints.md.min
    },
    $gtLg () {
      return this.windowWidth >= breakpoints.xl.min
    },
    $ltLg () {
      return this.windowWidth < breakpoints.lg.min
    },
    $isSm () {
      return this.isSm
    },
    $isMd () {
      return this.isMd
    },
    $isLg () {
      return this.isLg
    },
    $isXl () {
      return this.isXl
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      window.addEventListener('resize', this.getWindowHeight)
      this.getWindowWidth()
      this.getWindowHeight()
    })
  },
  methods: {
    getWindowWidth () {
      this.windowWidth = window.innerWidth
      this.isSm = this.windowWidth < breakpoints.md.min
      this.isMd = this.windowWidth >= breakpoints.md.min && this.windowWidth < breakpoints.lg.min
      this.isLg = this.windowWidth >= breakpoints.lg.min && this.windowWidth < breakpoints.xl.min
      this.isXl = this.windowWidth >= breakpoints.xl.min
    },
    getWindowHeight () {
      this.windowHeight = document.documentElement.clientHeight
    },
    smElse (smVal, elseVal) {
      return this.isSm ? smVal : elseVal
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
    window.removeEventListener('resize', this.getWindowHeight)
  }
})

export default mqMixin
