<template>
  <div class="interview-driving">
    <FlowWrapper
      :progress="4/8"
      :can-move-to-next="hasValidFormData"
      :question-keys="questionKeys"
      :answer-values="answerValues"
      :on-next="onNext"
    >
      <template v-slot:header-text>
        {{ $route.meta.header.title }}
      </template>
      <template v-slot:content-header>
        <div class="df-content-header-text">
          Let’s learn about your driving.
        </div>
      </template>
      <template v-slot:content>
      <v-form ref="infoForm">
        <div class="mq-grid">
          <div class="cols-10">
            <Question
              v-model="values[QK.HAS_DRIVERS_LICENSE]"
              :question="questionsMap[QK.HAS_DRIVERS_LICENSE]"
              :error="errors[QK.HAS_DRIVERS_LICENSE]"
              hide-details
              type="yesno"
              @input="onDriversLicenseChanged"
            />
          </div>
          <template v-if="!hasDriversLicense">
            <div class="cols-10">
              <Question
                v-model="values[QK.NO_DRIVERS_LICENSE_REASON]"
                :question="questionsMap[QK.NO_DRIVERS_LICENSE_REASON]"
                :error="errors[QK.NO_DRIVERS_LICENSE_REASON]"
                hide-details
                @input="onNoLicenseReasonsChanged"
              />
            </div>
          </template>

          <template v-if="hasLicenseOrRecentlyExpired">
            <div class="cols-10">
              <Question
                v-model="values[QK.TRAFFIC_TICKET_COUNT]"
                :question="questionsMap[QK.TRAFFIC_TICKET_COUNT]"
                :error="errors[QK.TRAFFIC_TICKET_COUNT]"
                hide-details
                type="select"
                :input-width="smElse('100%', '180px')"
                @input="onTrafficTicketCountChanged"
              />
            </div>

            <template v-if="hasTrafficTickets">
              <div class="cols-10">
                <Question
                  v-model="values[QK.TRAFFIC_TICKET_REASONS]"
                  :question="questionsMap[QK.TRAFFIC_TICKET_REASONS]"
                  :error="errors[QK.TRAFFIC_TICKET_REASONS]"
                  :multiple="values[QK.TRAFFIC_TICKET_COUNT] !== 'ONE'"
                  hide-details
                />
              </div>
            </template>
          </template>
          <template v-if="showDUIQuestion">
            <div class="cols-10">
              <Question
                v-model="values[QK.HAD_DUI]"
                :question="questionsMap[QK.HAD_DUI]"
                :error="errors[QK.HAD_DUI]"
                type="yesno"
                hide-details
              />
            </div>
          </template>
        </div>
      </v-form>
      </template>
    </FlowWrapper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlowWrapper from '@/views/flow/FlowWrapper.vue'
import Question from '@/components/interview/Question'
import { QuestionKey as QK } from '@/api/Interview'

export default {
  name: 'InterviewDrivingHistory',
  components: { FlowWrapper, Question },

  data () {
    return {
      STEP_NAME: 'driving-history',
      QK: QK,
      values: {},
      errors: {}
    }
  },

  computed: {
    ...mapGetters('interview', [
      'answers',
      'questionsMap',
      'interview',
      'interviewID',
      'parseBool',
      'validateKeys'
    ]),

    questionKeys () {
      return [
        QK.HAS_DRIVERS_LICENSE,
        QK.NO_DRIVERS_LICENSE_REASON,
        QK.TRAFFIC_TICKET_COUNT,
        QK.TRAFFIC_TICKET_REASONS,
        QK.HAD_DUI
      ]
    },

    answerValues () {
      return this.values
    },

    hasDriversLicense () {
      return this.parseBool(this.values[QK.HAS_DRIVERS_LICENSE])
    },

    hasExpiredDriversLicense () {
      return this.values[QK.NO_DRIVERS_LICENSE_REASON] === 'EXPIRED'
    },

    hasTrafficTickets () {
      if (this.values[QK.TRAFFIC_TICKET_COUNT]) {
        return this.values[QK.TRAFFIC_TICKET_COUNT] !== 'ZERO'
      }
      return false
    },

    hasLicenseOrRecentlyExpired () {
      return this.hasDriversLicense || this.hasExpiredDriversLicense
    },

    hasSuspendedLicense () {
      return this.values[QK.NO_DRIVERS_LICENSE_REASON] === 'SUSPENDED'
    },

    showDUIQuestion () {
      return this.hasLicenseOrRecentlyExpired || this.hasSuspendedLicense
    },

    activeKeys () {
      let activeKeys = [QK.HAS_DRIVERS_LICENSE]

      if (!this.hasDriversLicense) {
        activeKeys = activeKeys.concat(QK.NO_DRIVERS_LICENSE_REASON)
      }

      if (this.hasDriversLicense || this.hasExpiredDriversLicense) {
        activeKeys = activeKeys.concat([QK.TRAFFIC_TICKET_COUNT, QK.HAD_DUI])

        if (this.hasTrafficTickets) {
          activeKeys = activeKeys.concat(QK.TRAFFIC_TICKET_REASONS)
        }
      }

      return activeKeys
    },

    hasValidFormData () {
      return this.validateKeys(this.activeKeys, this.values)
    }
  },

  methods: {
    ...mapActions('interview', [
      'checkFollowupSectionsActivated'
    ]),

    onNext () {
      this.checkFollowupSectionsActivated({ id: this.interviewID })
    },

    onDriversLicenseChanged () {
      this.$delete(this.values, QK.NO_DRIVERS_LICENSE_REASON)
      this.$delete(this.values, QK.TRAFFIC_TICKET_COUNT)
      this.$delete(this.values, QK.TRAFFIC_TICKET_REASONS)
      this.$delete(this.values, QK.HAD_DUI)
    },

    onNoLicenseReasonsChanged () {
      this.$delete(this.values, QK.TRAFFIC_TICKET_COUNT)
      this.$delete(this.values, QK.TRAFFIC_TICKET_REASONS)
      this.$delete(this.values, QK.HAD_DUI)
    },

    onTrafficTicketCountChanged () {
      this.$delete(this.values, QK.TRAFFIC_TICKET_REASONS)
    }
  },

  mounted () {
    this.values = this.answers || {}
  }
}
</script>
